import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateRange } from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker";
import { Button, Grid, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import { clampRange } from "components/ui/range/lib";
import { addDays, startOfDay, subSeconds } from "date-fns/fp";
import { sequenceS, sequenceT } from "fp-ts/Apply";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { noop } from "lib/util";
import * as React from "react";
import { useState } from "react";
import PreDateSelectors from "./PreDateSelectors";

const InternalDateRangePicker: React.FC<{
  nowDate: Date;
  series: SeriesRequest;
  onCancel: () => void;
  onApply: (newDates: SeriesRequest) => void;
  maxDate?: Date;
  minDate?: Date;
}> = ({ nowDate, onCancel, onApply, minDate, maxDate, series }) => {
  const [start, setStart] = useState(O.some(series.start));
  const [end, setEnd] = useState(O.some(series.end));
  const isApplyButtonEnabled = () =>
    pipe(sequenceT(O.Applicative)(start, end), O.isSome);

  const onSelectorChange = (newRange: DateRange<Date>) => {
    pipe(newRange[0], O.fromNullable, setStart);
    pipe(
      newRange[1],
      O.fromNullable,
      O.map(flow(addDays(1), startOfDay)),
      setEnd
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={0} data-testid="date-range-popover-container">
        <Grid item xs={3}>
          <PreDateSelectors
            now={nowDate}
            onSelectorChange={onApply}
            onReset={() => {
              setStart(O.none);
              setEnd(O.none);
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <StaticDateRangePicker
            displayStaticWrapperAs="desktop"
            value={[
              O.toNullable(start),
              pipe(end, O.map(subSeconds(1)), O.toNullable),
            ]}
            maxDate={maxDate}
            minDate={minDate}
            onChange={onSelectorChange}
            renderInput={() => <div />}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" square>
            <Stack direction="row" spacing={2} justifyContent="end" padding={2}>
              <Button variant="contained" onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button
                onClick={() =>
                  pipe(
                    sequenceS(O.Applicative)({ start, end }),
                    O.fold(noop, onApply)
                  )
                }
                variant="contained"
                color="secondary"
                disabled={!isApplyButtonEnabled()}
              >
                Apply
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default InternalDateRangePicker;
