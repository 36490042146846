import { Lens } from "monocle-ts";
import * as Eq from "fp-ts/Eq";
import * as AD from "lib/at-data/AsyncData";
import { Bookings } from "lib/at-api/assets/BookingsModel";
import * as AD2 from "lib/at-data/AsyncData2";

export type AssetsBookingsState = {
  bookings: AD2.AsyncData2<Array<Bookings>>;
};

export const AssetsBookingsStateEq = Eq.struct<AssetsBookingsState>({
  bookings: AD2.getEq(Eq.eqStrict),
});

export const BookingsL = Lens.fromProp<AssetsBookingsState>()("bookings");
