import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Icon from "components/ui/Icon";
import { AssetContext } from "contexts/AssetContext";
import { AssetsContext } from "contexts/AssetsContext";
import { ActivityMapFloorDiagramControllerComponent } from "controllers/ActivityMapFloorDiagramController/ActivityMapFloorDiagramController";
import { AppController } from "controllers/AppController/AppController";
import { AssetsAnalyzeDataControllerComponent } from "controllers/AssetsAnalayzeDataController/AssetsAnalyzeDataController";
import { AssetsBookingsControllerComponent } from "controllers/AssetsBookingsController/AssetsBookingsController";
import {
  addAssetTags,
  deleteAssetTags,
  LegacyAssetsModificationsController,
  modifyAssetCapacity,
} from "controllers/AssetsController/AssetsController";
import { AssetsInsightsControllerComponent } from "controllers/AssetsInsightsController/AssetsInsightsController";
import {
  AssetsLiveOccupancyController,
  AssetsLiveOccupancyControllerComponent,
} from "controllers/AssetsLiveOccupancyController/AssetsLiveOccupancyController";
import {
  LastOccupancyEstimatesL,
  LastOccupancyTimestampL,
} from "controllers/AssetsLiveOccupancyController/state";
import {
  AssetsMeanOccupancyController,
  AssetsMeanOccupancyControllerComponent,
} from "controllers/AssetsMeanOccupancyController/AssetsMeanOccupancyController";
import {
  SelectedAssetsController,
  SelectedAssetsL,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { isManagedAssetTag } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import {
  ActivityPageUIStateL,
  SelectedRangeL,
} from "controllers/UIStateCollector/lens/activityPage";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { ByAllAssetIdsOnly } from "controllers/UIStateCollector/utils";
import {
  PreferenceTypes,
  setPreferences,
  UserPreferencesController,
} from "controllers/UserPreferencesController/UserPreferencesController";
import convert from "convert-units";
import * as A from "fp-ts/Array";
import * as b from "fp-ts/boolean";
import { warn } from "fp-ts/Console";
import * as d from "fp-ts/Date";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/Predicate";
import * as RT from "fp-ts/ReaderTask";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as Se from "fp-ts/Set";
import * as TE from "fp-ts/TaskEither";
import {
  addAssetTag,
  AssetTag,
  AssetTagCaseInsensitiveEq,
  AssetTagCaseInsensitiveOrd,
  AssetTagEq,
  AssetTags,
} from "lib/at-api/asset-tags/assetTags";
import { Asset } from "lib/at-data/assets/Asset";
import { getByParent } from "lib/at-data/assets/assets";
import {
  ByTag,
  filterAssets,
  getParentAssetOf,
} from "lib/at-data/assets/filters";
import { getArea, getId, getOperatingHours } from "lib/at-data/assets/getters";
import { getZoneCode } from "lib/at-data/assets/getZoneCode";
import { AssetTagsL, CapacityL } from "lib/at-data/assets/lens";
import { updateAsset } from "lib/at-data/assets/modify";
import { isFloor, isRegion } from "lib/at-data/assets/predicates";
import * as AD from "lib/at-data/AsyncData";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { toPairArray } from "lib/at-data/Slice";
import { fromSqM, isoSqFt, isoSqM } from "lib/at-data/units/area";
import { eqUUID, ordUUID, UUID } from "lib/at-data/UUID";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { useCollector } from "lib/at-react/hooks";
import {
  CommaNumber,
  formatNumber,
  UINumber,
} from "lib/formatters/formatNumber";
import { noop } from "lib/util";
import * as L from "monocle-ts/Lens";
import * as T from "monocle-ts/Traversal";
import React, { useCallback, useState } from "react";
import { RiAddCircleFill, RiHeart3Fill } from "react-icons/ri";
import palette from "theme/palette";
import {
  TenantL,
  toMinimalAppState,
} from "views/authenticated/app/ReadyAppState";
import {
  AssetDetailPreviewKpiIcon,
  Now,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetDetailPreviewKpiIcon";
import { AssetOperatingHours } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetOperatingHours";
import { AssetsLiveOccupancies2 } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetsLiveOccupancies";
import { BSLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/BSLogo";
import {
  DefaultCapacityRatioTags,
  DefaultCapacityRatioType,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/DefaultCapacityRatioType";
import { EditAssetPropertiesTab } from "views/authenticated/assets/page/components/AssetsDetailPreview/EditAssetPropertiesTab";
import { FidelityLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/FidelityLogo";
import { ETSULogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/ETSULogo";
import { LionsLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/LionsLogo";
import { PurdueLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/PurdueLogo";
import { UTKLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/UTKLogo";
import { WMLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/WMLogo";
import { UTCLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/UTCLogo";
import { DowJonesLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/DowJonesLogo";
import { GMULogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/GMULogo";
import { UNTLogo } from "views/authenticated/assets/page/components/AssetsDetailPreview/UNTLogo";
import { AssetFilterInput } from "views/authenticated/assets/page/components/AssetsTable/AssetsTableHeaderFilter";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { AssetsL, TagsL } from "views/authenticated/root/controller/state";
import { FavoriteAssetsController } from "views/authenticated/root/FavoriteAssetsController";
import theme from "../../../../../../theme/theme";
import { AssetPreviewMap } from "../AssetPreviewMap/AssetPreviewMap";
import { AssetBookings } from "./AssetBookings";
import { AssetInsights } from "./AssetInsights";
import { AssetTagsList } from "./AssetTags";
import { AssetUtilization } from "./AssetUtilization";
import { AssetWeather } from "./AssetWeather";
import { NovaLogo } from "./NovaLogo";

// import { AssetInfo } from "./AssetInfo";
import { PinHead } from "./PinHead";
import { format } from "date-fns/fp";
import * as AD2 from "lib/at-data/AsyncData2";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import { getOccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { FloorOccupancy } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetContextFloorPickerMenu";
import { SelectedSliceCollector } from "controllers/SelectedSliceCollector/SelectedSliceCollector";

const AssetDetailPreviewKpi: React.FC<{
  label: string;
  value: string | number;
  pinHead?: boolean;
}> = ({ label, value, pinHead }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <Now>{label}</Now>
      {pinHead ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <PinHead />
          <Typography
            component={"span"}
            variant="body1"
            color={palette.neutral[100]}
            sx={{ lineHeight: "24px" }}
          >
            {value}
          </Typography>
        </Stack>
      ) : (
        <Typography
          component={"span"}
          variant="body1"
          color={palette.neutral[100]}
          sx={{ lineHeight: "24px" }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export const AssetScheduleTab: React.FC<{
  onClose: () => void;
  asset: Asset;
  onBookingClick: (range: SeriesRequest) => void;
  selectedRange: SeriesRequest;
}> = (props) => {
  return (
    <AssetsBookingsControllerComponent context={AssetContext(props.asset)}>
      <AssetBookings
        selectedRange={props.selectedRange}
        asset={props.asset}
        onBookingClick={props.onBookingClick}
      />
    </AssetsBookingsControllerComponent>
  );
};

export const AssetWeatherTab: React.FC<{ onClose: () => void }> = (props) => {
  return <AssetWeather />;
};

export const AssetInsightsTab: React.FC<{ onClose: () => void; asset: Asset }> =
  (props) => {
    return (
      <AssetsInsightsControllerComponent context={AssetContext(props.asset)}>
        <AssetInsights />
      </AssetsInsightsControllerComponent>
    );
  };

export const AssetMultipleSelectionTab: React.FC<{}> = (props) => {
  const [addTagDialogOpen, setAddTagDialogOpen] = useState(false);
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const selectedAssets = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetsL)
  );

  const tags = useCollectorOld(RawAssetsController, L.composeLens(TagsL));
  const [selectedTags, setSelectedTags] = useState<Array<AssetTag>>([]);

  const handleChange = useCallback(
    (ev, t: Array<string>) => setSelectedTags(t),
    []
  );

  const handleAddNewTag = useCallback(
    (tag: string) => {
      pipe(TagsL, L.modify(addAssetTag(tag)), rootDispatch);
      setAddTagDialogOpen(false);
    },
    [rootDispatch]
  );

  const handleSetMultipleTags = useCallback(() => {
    pipe(
      selectedAssets,
      A.map((asset) =>
        assetModificationsDispatch(addAssetTags(selectedTags)(asset))
      )
    );

    rootDispatch(
      pipe(
        selectedAssets,
        A.map(getId),
        ByAllAssetIdsOnly,
        updateAsset,
        T.composeLens(AssetTagsL),
        T.modify((existingTags) =>
          pipe(existingTags, A.union(AssetTagEq)(selectedTags))
        )
      )
    );
    setSelectedTags([]);
  }, [selectedTags, rootDispatch, selectedAssets, assetModificationsDispatch]);

  const handleDeleteMultipleTags = useCallback(() => {
    pipe(
      selectedAssets,
      A.map((asset) =>
        assetModificationsDispatch(deleteAssetTags(selectedTags)(asset))
      )
    );

    rootDispatch(
      pipe(
        selectedAssets,
        A.map(getId),
        ByAllAssetIdsOnly,
        updateAsset,
        T.composeLens(AssetTagsL),
        T.modify((existingTags) =>
          pipe(existingTags, A.difference(AssetTagEq)(selectedTags))
        )
      )
    );
    setSelectedTags([]);
  }, [selectedTags, rootDispatch, selectedAssets, assetModificationsDispatch]);

  const handleCloseDialog = useCallback(() => {
    setAddTagDialogOpen(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setAddTagDialogOpen(true);
  }, []);

  // TODO: make this pretty
  return (
    <span>
      <Stack p={2} spacing={2}>
        <Typography
          component={"span"}
          variant="h4"
          color={palette.neutral[100]}
        >
          Multiple Asset Selection
        </Typography>
        <AssetFilterInput sx={{ width: "100%" }} $mode={false}>
          {/*<InputLabel id="multiple-chip-label">Shared Tags</InputLabel>*/}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControl sx={{ p: 0, m: 0, width: "100%" }}>
              <Autocomplete
                multiple
                options={pipe(tags, A.sort(AssetTagCaseInsensitiveOrd))}
                getOptionLabel={(option) => option}
                value={selectedTags}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant={"outlined"}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
            <IconButton onClick={handleOpenDialog} color={"success"}>
              <Icon icon={RiAddCircleFill} />
            </IconButton>
          </Stack>

          <AddNewAssetDialog
            tags={tags}
            onAdd={handleAddNewTag}
            onClose={handleCloseDialog}
            open={addTagDialogOpen}
          />

          <Button
            color="primary"
            disabled={selectedTags.length < 1}
            onClick={handleSetMultipleTags}
            sx={{ marginTop: "12px" }}
          >
            Assign Tags to {selectedAssets.length} Assets
          </Button>
          <Button
            color="error"
            disabled={selectedTags.length < 1}
            onClick={handleDeleteMultipleTags}
            sx={{ marginTop: "12px" }}
          >
            Remove Tags from {selectedAssets.length} Assets
          </Button>
        </AssetFilterInput>
      </Stack>
    </span>
  );
};

export const AssetDefaultCapacityTab: React.FC<{}> = (props) => {
  const [addTagDialogOpen, setAddTagDialogOpen] = useState(false);
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const selectedAssets = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetsL)
  );

  const [selectedTags, setSelectedTags] = useState<Array<AssetTag>>([]);

  const handleChange = useCallback(
    (ev, t: Array<string>) => setSelectedTags(t),
    []
  );

  const handleSetDefaultCapacityTag = useCallback(() => {
    pipe(
      selectedAssets,
      A.map((asset) =>
        pipe(
          asset,
          getArea,
          O.map(isoSqM.unwrap),
          O.map((x) => convert(x).from("m2").to("ft2")),
          O.getOrElseW(() => 0)
        ) === 0
          ? noop()
          : assetModificationsDispatch(addAssetTags(selectedTags)(asset))
      )
    );

    rootDispatch(
      pipe(
        selectedAssets,
        A.map(getId),
        ByAllAssetIdsOnly,
        updateAsset,
        T.composeLens(AssetTagsL),
        T.modify((existingTags) =>
          pipe(
            existingTags,
            A.union(AssetTagEq)([
              DefaultCapacityRatioTags[DefaultCapacityRatioType.DEFAULT],
            ])
          )
        )
      )
    );
    setSelectedTags([]);
  }, [selectedTags, rootDispatch, selectedAssets, assetModificationsDispatch]);

  const handleSetDefaultCapacity = useCallback(() => {
    pipe(
      selectedAssets,
      A.map((asset) =>
        pipe(
          asset,
          getArea,
          O.map(isoSqM.unwrap),
          O.map((x) => convert(x).from("m2").to("ft2")),
          O.getOrElseW(() => 0)
        ) === 0
          ? noop()
          : assetModificationsDispatch(
              modifyAssetCapacity(
                asset,
                O.some(
                  Math.floor(
                    pipe(
                      asset,
                      getArea,
                      O.map(isoSqM.unwrap),
                      O.map((x) => convert(x).from("m2").to("ft2")),
                      O.getOrElseW(() => 0)
                    ) / DefaultCapacityRatioType.DEFAULT
                  )
                )
              )
            )
      )
    );
    pipe(
      selectedAssets,
      A.map((a2) =>
        pipe(
          a2,
          getArea,
          O.map(isoSqM.unwrap),
          O.map((x) => convert(x).from("m2").to("ft2")),
          O.getOrElseW(() => 0)
        ) === 0
          ? noop()
          : rootDispatch(
              pipe(
                a2,
                getId,
                A.of,
                ByAllAssetIdsOnly,
                updateAsset,
                T.composeLens(CapacityL),
                T.set(
                  pipe(
                    O.some(
                      Math.floor(
                        pipe(
                          a2,
                          getArea,
                          O.map(isoSqM.unwrap),
                          O.map((x2) => convert(x2).from("m2").to("ft2")),
                          O.getOrElseW(() => 0)
                        ) / DefaultCapacityRatioType.DEFAULT
                      )
                    )
                  )
                )
              )
            )
      )
    );
  }, [selectedTags, rootDispatch, selectedAssets, assetModificationsDispatch]);

  return (
    <span>
      <Stack p={2} spacing={2}>
        <Typography
          component={"span"}
          variant="h4"
          color={palette.neutral[100]}
        >
          Default Capacity Selection
        </Typography>
        <AssetFilterInput sx={{ width: "100%" }} $mode={false}>
          {/*<InputLabel id="multiple-chip-label">Shared Tags</InputLabel>*/}
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <FormControl sx={{ p: 0, m: 0, width: "100%" }}>
              <Autocomplete
                multiple
                options={[
                  DefaultCapacityRatioTags[DefaultCapacityRatioType.DEFAULT],
                ]}
                // options={pipe(tags, A.sort(AssetTagCaseInsensitiveOrd))}
                getOptionLabel={(option) => option}
                value={selectedTags}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant={"outlined"}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          </Stack>

          <Button
            color="primary"
            disabled={selectedTags.length < 1}
            onClick={() => {
              handleSetDefaultCapacityTag();
              handleSetDefaultCapacity();
            }}
            sx={{ marginTop: "12px" }}
          >
            Assign Default Capacity to {selectedAssets.length} Assets
          </Button>
        </AssetFilterInput>
      </Stack>
    </span>
  );
};

export const validateNewTagIsNotADuplicate =
  (newTag: AssetTag) =>
  (tags: AssetTags): E.Either<string, AssetTag> =>
    pipe(
      newTag,
      E.fromPredicate(
        not((nt) => pipe(tags, A.elem(AssetTagCaseInsensitiveEq)(nt))),
        () => "This tag already exists"
      )
    );
export const validateNewTagIsNotADuplicateIgnoringCase =
  (newTag: AssetTag) =>
  (tags: AssetTags): E.Either<string, AssetTag> =>
    pipe(
      newTag,
      E.fromPredicate(
        not((nt) => pipe(tags, A.elem(AssetTagEq)(nt))),
        () => "This tag already exists"
      )
    );

export const AddNewAssetDialog: React.FC<{
  tags: AssetTags;
  open: boolean;
  onClose: () => void;
  onAdd: (tag: string) => void;
}> = (props) => {
  const [tag, setTag] = useState("");
  const validTag = pipe(props.tags, validateNewTagIsNotADuplicate(tag));
  const isValid = E.isRight(validTag);

  return (
    <Dialog disableEscapeKeyDown open={props.open} onClose={props.onClose}>
      <DialogTitle>Add Brand New Tag</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <TextField
              error={!isValid}
              helperText={pipe(
                validTag,
                E.swap,
                E.getOrElseW(() => null)
              )}
              autoFocus
              variant="outlined"
              value={tag}
              onChange={(ev) => {
                setTag(ev.target.value);
              }}
              onKeyPress={(event) => {
                event.stopPropagation();

                if (event.key === "Enter") {
                  event.preventDefault();
                  if (tag.length && isValid) {
                    setTag("");
                    props.onAdd(tag);
                  }
                }
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          disabled={tag.length < 1 || !isValid}
          onClick={() => {
            props.onAdd(tag);
          }}
        >
          Add Tag
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TagChip = styled(Chip)`
  &:first-of-type {
    margin-left: -48px;
  }

  position: relative;
  margin: 0 6px 0 0;
  cursor: pointer;
  user-select: none;
  font-family: Fira Sans, sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 16px;
  white-space: nowrap;
  outline: 0;
  text-decoration: none;
  border: 0;
  vertical-align: middle;
  box-sizing: border-box;

  color: ${palette.primary[800]};
  background-color: ${palette.primary[200]};
  height: 24px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0 12px 0 12px;
`;

export const AssetUtilizationTab: React.FC<{
  onClose: () => void;
  asset: Asset;
  range: SeriesRequest;
}> = (props) => {
  const [allAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2)
  );
  return (
    <AssetsAnalyzeDataControllerComponent
      context={{ visibleAssets: A.of(props.asset), series: props.range }}
    >
      <AssetUtilization range={props.range} asset={props.asset} />
    </AssetsAnalyzeDataControllerComponent>
  );
};

export const AssetAdminTab: React.FC<{
  onClose: () => void;
  asset: Asset;
}> = (props) => {
  return <EditAssetPropertiesTab asset={props.asset} />;
};

export const tempLogos = {
  "18cf713e-f9d9-4300-b440-b0f02c0bbc20": <FidelityLogo />,
  "b05395a5-65f7-4669-be8c-572f59d39311": <BSLogo />,
  "88bae34f-f09a-4e2c-b89c-d2f7fe6bb576": <UTKLogo />,
  "b055c1db-9106-4880-a604-c629e9142a5d": <BSLogo />,
  "3e314942-4685-4f8b-8050-572faa3569d3": <LionsLogo />,
  "ccb683f0-99f7-4995-9027-5c682b3ac750": <PurdueLogo />,
  "d63b22fd-2dc8-4409-b18b-0855bd69d199": <WMLogo />,
  "d8fc3bfa-93af-4154-ad67-cadf68f5ea2b": <ETSULogo />,
  "733738f8-339e-4930-a28f-a4e47e297d01": <NovaLogo />,
  "ca44ac47-84b5-4aa2-aa2d-3dc410d9b2cc": <UTCLogo />,
  "808a7021-d73e-4d72-8eee-4deb02b729d2": <DowJonesLogo />,
  "fc1b78a3-0cf2-40f8-b02b-bc56465e8d30": <GMULogo />,
  "9e577514-654f-4db1-bf9f-3c41d51dd70a": <UNTLogo />,
};

export const RegionAssetDetails: React.FC<{
  selectedAsset: Asset;
  onAssetClick: (asset: Asset) => void;
}> = (props) => {
  const [childAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2, getByParent(props.selectedAsset))
  );
  const [lastOccupancyEstimates] = useController(
    AssetsLiveOccupancyController,
    LastOccupancyEstimatesL.get
  );

  const [lastOccupancyEstimateTimestamp] = useController(
    AssetsLiveOccupancyController,
    flow(
      LastOccupancyTimestampL.get,
      AD.map(
        flow(
          toPairArray(ordUUID),
          A.map((_) => _[1]),
          A.sort(O.getOrd(d.Ord)),
          A.last,
          O.flatten
        )
      )
    )
  );

  const [tenantO] = useController(
    AppController,
    flow(toMinimalAppState, O.map(TenantL.get))
  );

  const [meanOccupancies] = useController(
    AssetsMeanOccupancyController,
    (_) => _.meanOccupancies
  );

  const selectedSlice = useCollector(SelectedSliceCollector);

  return (
    <Stack spacing={1} px={0} py={2}>
      <Stack
        direction="row"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        p={2}
        sx={{ minHeight: "150px", width: "100%" }}
      >
        {pipe(
          tenantO,
          O.chain((t) => ROR.lookup(t, tempLogos)),
          O.getOrElseW(() => null)
        )}
      </Stack>
      <Divider
        sx={{
          backgroundColor: `${palette.neutral[500]}`,
        }}
      />
      <Stack spacing={1} px={0} pt={2}>
        <Stack
          direction="column"
          alignItems="flex-start"
          alignContent={"center"}
          pb={2}
          px={3}
          spacing={1}
        >
          <Typography
            component={"span"}
            variant="h4"
            color={palette.neutral[100]}
          >
            Mean Occupancy
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            alignContent={"center"}
            // pb={2}
            // px={3}
            spacing={1}
          >
            <PinHead />
            <Now>
              {pipe(selectedSlice.start, format("MMM dd, h:mm a"))} -{" "}
              {pipe(selectedSlice.end, format("MMM dd, h:mm a"))}:{" "}
              {pipe(
                O.some(props.selectedAsset),
                O.map(getId),
                O.map((assetId) =>
                  pipe(
                    meanOccupancies,
                    AD2.map(getSliceByUUID(assetId)),
                    AD2.fold(
                      () => "0",
                      () => "Pending",
                      flow(
                        O.flatten,
                        O.fold(
                          () => "0",
                          flow(getOccupancyEstimate, formatNumber(UINumber))
                        )
                      ),
                      () => "Error"
                    )
                  )
                ),
                O.getOrElseW(() => null)
              )}
            </Now>
          </Stack>
        </Stack>
        <Stack spacing={1} px={0} py={0}>
          <AssetsLiveOccupancies2
            onAssetClick={props.onAssetClick}
            assets={pipe(childAssets, filterAssets(ByTag("Managed Assets")))}
            occupancies={lastOccupancyEstimates}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const updateFavorites = Se.toggle(eqUUID);

export const AssetDetails: React.FC<{
  selectedAsset: Asset;
  onAssetClick: (asset: Asset) => void;
}> = (props) => {
  const [childAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2, getByParent(props.selectedAsset))
  );
  const [preferences, preferencesDispatch] = useController(
    UserPreferencesController,
    (_) => _
  );

  const [lastOccupancyEstimates] = useController(
    AssetsLiveOccupancyController,
    LastOccupancyEstimatesL.get
  );
  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );

  const favoriteAssets = useCollector(
    FavoriteAssetsController,
    L.prop("favoriteAssets")
  );

  const isFavorited = pipe(
    favoriteAssets,
    Se.elem(eqUUID)(props.selectedAsset.id)
  );

  const [appState] = useController(AppController, (_) => _);

  const handleSaveToFavorites = (assetId: UUID) => {
    return (ev: any) => {
      pipe(
        appState,
        toMinimalAppState,
        O.foldW(
          () => TE.of(noop),
          (appContext) =>
            pipe(
              favoriteAssets,
              updateFavorites(assetId),
              Se.toArray(ordUUID),
              setPreferences(PreferenceTypes.FAVORITES),
              RTE.foldW(flow(warn, RT.fromIO), (updatedFavorites) =>
                pipe(
                  { ...preferences, ...updatedFavorites },
                  preferencesDispatch,
                  IO.of,
                  RTE.fromIO
                )
              )
            )({ appContext })
        )
      )();
    };
  };

  return (
    <StyledContainer>
      <StyledPanelContent>
        <Stack spacing={1} px={3} py={2}>
          <ActivityMapFloorDiagramControllerComponent
            context={{
              asset: pipe(
                props.selectedAsset,
                getParentAssetOf(isFloor)
              )(allAssets),
            }}
          >
            <AssetPreviewMap asset={props.selectedAsset} />
          </ActivityMapFloorDiagramControllerComponent>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          px={3}
        >
          <Tooltip
            disableFocusListener
            // enterDelay={3000}
            // enterTouchDelay={3000}
            enterNextDelay={3000}
            title={isFavorited ? "Remove from Favorites" : "Add to Favorites"}
          >
            <HeartIconButton
              onClick={handleSaveToFavorites(props.selectedAsset.id)}
            >
              <HeartIcon
                icon={RiHeart3Fill}
                sx={{
                  color: isFavorited
                    ? `${palette.logo[500]}`
                    : `${palette.neutral[450]}`,
                }}
                size={"30"}
              />
            </HeartIconButton>
          </Tooltip>
        </Stack>

        <Stack spacing={1} px={3} py={2}>
          <AssetTagsList
            tags={pipe(
              props.selectedAsset.tags,
              A.filter(not(isManagedAssetTag))
            )}
          />
        </Stack>

        {/*<AssetContextFloorPickerMenu />*/}

        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          px={3}
          py={2}
          sx={{ "&:empty": { display: "none" } }}
        >
          {pipe(
            props.selectedAsset.capacity,
            O.fold(
              () => null,
              (maxCapacity) => (
                <AssetDetailPreviewKpi
                  label="Capacity"
                  value={pipe(maxCapacity, formatNumber(UINumber))}
                />
              )
            )
          )}
          {pipe(
            props.selectedAsset,
            getArea,
            O.map(flow(fromSqM, isoSqFt.modify(Math.round))),
            O.fold(
              () => null,
              (area) => (
                <AssetDetailPreviewKpiIcon
                  label="Square Footage"
                  // value={area.toString()}
                  value={pipe(Number(area), formatNumber(CommaNumber))}
                />
              )
            )
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          px={3}
          py={2}
          sx={{ "&:empty": { display: "none" } }}
        >
          {pipe(
            props.selectedAsset,
            getZoneCode,
            O.fold(
              () => null,
              (shortCode) => (
                <AssetDetailPreviewKpi label="Short Code" value={shortCode} />
              )
            )
          )}
        </Stack>
        <AssetOperatingHours
          hours={pipe(props.selectedAsset, getOperatingHours)}
        />
        {/*<AssetsLiveOccupancies*/}
        {/*  onAssetClick={props.onAssetClick}*/}
        {/*  assets={pipe(childAssets, filterAssets(ByTag("Managed Assets")))}*/}
        {/*  occupancies={lastOccupancyEstimates}*/}
        {/*/>*/}
        <AssetsLiveOccupancies2
          onAssetClick={props.onAssetClick}
          assets={pipe(childAssets, filterAssets(ByTag("Managed Assets")))}
          occupancies={lastOccupancyEstimates}
        />
      </StyledPanelContent>
    </StyledContainer>
  );
};

const AssetsDetailPreviewTab: React.FC<{
  onClose: () => void;
  onAssetClick: (asset: Asset) => void;
  selectedAsset: Asset;
}> = (props) => {
  const [childAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2, getByParent(props.selectedAsset))
  );
  const [selectedRange] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(SelectedRangeL)).get
  );

  const activeRange = useCollector(SelectedSliceCollector);

  //Add filter here:
  //let newarr = selectedAsset.tags.filter(a => a !== 'Managed Assets');
  return (
    <AssetsMeanOccupancyControllerComponent
      context={{
        ...AssetsContext(pipe(childAssets, A.append(props.selectedAsset))),
        series: activeRange,
      }}
    >
      <AssetsLiveOccupancyControllerComponent
        context={{
          ...AssetsContext(
            pipe(childAssets, filterAssets(ByTag("Managed Assets")))
          ),
        }}
      >
        {pipe(
          props.selectedAsset,
          isRegion,
          b.fold(
            () => (
              <AssetDetails
                selectedAsset={props.selectedAsset}
                onAssetClick={props.onAssetClick}
              />
            ),
            () => (
              <RegionAssetDetails
                onAssetClick={props.onAssetClick}
                selectedAsset={props.selectedAsset}
              />
            )
          )
        )}
      </AssetsLiveOccupancyControllerComponent>
    </AssetsMeanOccupancyControllerComponent>
  );
};

export default AssetsDetailPreviewTab;

const StyledContainer = styled("div")`
  margin-right: 0;
  display: flex;
  flex-direction: column;
  // min-height: 100%;
  height: calc(100vh - 56px);
  position: relative;
`;

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
export const Time = styled(Typography)`
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${palette.neutral[100]};
`;

export const HeartIcon = styled(Icon)`
  color: ${palette.neutral[450]};
  size: 24px;

  &:hover {
    color: ${palette.logo[500]};
  }

  &:active {
    color: ${palette.logo[500]};
  }

  &:click {
    color: ${palette.logo[500]};
  }
`;

export const HeartIconButton = styled(IconButton)`
  color: ${palette.neutral[500]};
  transition: ${theme.transitions.create(["background-color", "color"])};

  &:hover,
  &:focus {
    color: ${palette.neutral[100]};
    background-color: ${palette.neutral[700]};
  }
`;
