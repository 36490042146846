import React from "react";
import {
  Box,
  styled,
  BoxProps,
  Typography,
  IconButton,
  alpha,
} from "@mui/material";
import palette from "theme/palette";
import theme from "theme/theme";
import { RiCloseLine } from "react-icons/ri";
import Icon from "components/ui/Icon";

interface IModalHeader extends BoxProps {
  /**
   * Header title text
   */
  label: string;
  /**
   * Header subtitle text or React Element
   */
  subtitle?: string;
  /**
   * Header description text
   */
  description?: string;
  /**
   * Optional function for having a close icon in the top-right corner and the action that is taken once it is clicked on.
   */
  onClose?: any;
}

/**
 * Pass in a `label` which will display as the modal title, along with an optional `onClose` which determines whether or not there will be a close indicator and the action that will be taken when clicked.
 */

const ModalHeader: React.FC<IModalHeader> = (props) => {
  return (
    <StyledBox>
      <Typography variant="h3">{props.label}</Typography>
      {props.subtitle ? (
        <Typography variant="body1">{props.subtitle}</Typography>
      ) : null}
      {props.children || null}
      {props.onClose ? (
        <StyledIconButton onClick={props.onClose}>
          <Icon icon={RiCloseLine} size="16px" />
        </StyledIconButton>
      ) : null}
    </StyledBox>
  );
};

export default ModalHeader;

const StyledBox = styled(Box)`
  position: relative;
  padding: ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(3)};
  background-color: ${palette.neutral[100]};
  box-shadow: 0 1px ${alpha(palette.neutral[200], 1)};
  z-index: 1;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${theme.spacing(2)};
  right: ${theme.spacing(2)};
`;
