import { AssetsContext } from "contexts/AssetsContext";
import { SeriesContext } from "contexts/SeriesContext";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as RTE from "fp-ts/ReaderTaskEither";
import { Assets, eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import * as AD2 from "lib/at-data/AsyncData2";
import * as D from "lib/at-data/DataSeries";
import {
  OccupancyEstimate,
  OccupancyEstimateEq,
} from "lib/at-data/OccupancyEstimate";
import {
  calculateResolution,
  calculateResolutionByNumberOfBuckets,
} from "lib/at-data/requests/temporal/FromNumberOfBuckets";
import * as SR from "lib/at-data/requests/temporal/SeriesRequest";
import * as Sl from "lib/at-data/Slice";
import { eqUUID } from "lib/at-data/UUID";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { defineAppController } from "lib/at-react/defineAppController";
import { loggingAsyncDataEffect } from "lib/logging";
import * as L from "monocle-ts/Lens";
import React from "react";
import { getAssetsMultipleOccupancyCountsSeries } from "views/authenticated/assets/actions/workerActions";

export type OccupancySpreadControllerState = {
  occupancySpread: AD2.AsyncData2<UUIDSlice<D.DataSeries<OccupancyEstimate>>>;
};

export const OccupancySpreadControllerStateEq: Eq.Eq<OccupancySpreadControllerState> =
  Eq.struct({
    occupancySpread: AD2.getEq(Sl.getEq(eqUUID, D.getEq(OccupancyEstimateEq))),
  });

export const initialState: OccupancySpreadControllerState = {
  occupancySpread: AD2.none,
};

export const OccupancySpreadL = pipe(
  L.id<OccupancySpreadControllerState>(),
  L.prop("occupancySpread")
);

export const setOccupancySpreadIO =
  (
    dispatch: React.Dispatch<
      React.SetStateAction<OccupancySpreadControllerState>
    >
  ) =>
  (
    occupancySpread: AD2.AsyncData2<UUIDSlice<D.DataSeries<OccupancyEstimate>>>
  ) =>
    pipe(occupancySpread, OccupancySpreadL.set, dispatch, IO.of);

export const [ControllerComponent, Controller] = defineAppController<
  AssetsContext & SeriesContext,
  OccupancySpreadControllerState
>(
  initialState,
  OccupancySpreadControllerStateEq,
  Eq.struct({ assets: eqAssetsByUUIDOnly, series: SR.SeriesRequestEq }),
  (dispatch) =>
    pipe(
      RTE.ask<AssetsContext & SeriesContext>(),
      RTE.chainW(({ series, assets }) =>
        getAssetsMultipleOccupancyCountsSeries(
          assets,
          calculateResolutionByNumberOfBuckets(60)(series)
        )
      ),
      loggingAsyncDataEffect("Occupancy Spread", setOccupancySpreadIO(dispatch))
    )
);

export const OccupancySpreadController = Controller;
export const OccupancySpreadControllerComponent = ControllerComponent;
