import { TabList } from "@mui/lab";
import { Tab, Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";

const tabs: Array<{ label: string; count: number }> = [
  { label: "All Spaces", count: 2 },
  { label: "Booked vs. Actual", count: 2 },
  // { label: "Drafts", count: 4 },
];

interface IReportsTableTabs {
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
}

const ReportsTableTabs: React.FC<IReportsTableTabs> = ({ handleChange }) => {
  return (
    <Stack p={0} spacing={0}>
      <Typography
        variant="h4"
        sx={{ paddingLeft: "32px", paddingBottom: "16px" }}
      >
        Archived Reports
      </Typography>
      <Box sx={{ width: "100%", paddingLeft: "16px" }}>
        <TabList onChange={handleChange} aria-label="reports table tabs">
          {tabs.map((tab, i) => (
            <Tab
              value={`${i + 1}`}
              key={`${tab.label}-${i}`}
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {tab.label}
                  </Typography>
                  <Chip
                    label={tab.count}
                    size="small"
                    variant="filled"
                    color="secondary"
                    sx={{ px: 0, minWidth: 30 }}
                  />
                </Stack>
              }
            />
          ))}
        </TabList>
      </Box>
    </Stack>
  );
};

export default ReportsTableTabs;
