import { flow, pipe } from "fp-ts/function";
import { toCoord } from "views/authenticated/activity/page/components/spatial";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import { getLayer, hideLayer } from "lib/fp-mapbox/layers";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import {
  calculateAffineTransform,
  GeoReference,
  reprojectCoord,
} from "lib/at-data/assets/models/GeoReferenceModel";
import { UUID } from "lib/at-data/UUID";
import * as t from "io-ts";
import * as TE from "fp-ts/TaskEither";
import * as E from "fp-ts/Either";
import { handleResponse } from "lib/at-api/assets/getAssets";
import { FloorPlanForMap } from "controllers/ActivityMapFloorDiagramController/FloorPlanForMap";
import { chainFirstIO } from "views/authenticated/activity/page/components/ReaderIO";
import { addLayerToMap } from "components/spatial/base/layers/assetPolygon";
import { getSource } from "lib/fp-mapbox/sources";
import * as R from "fp-ts/Reader";
import { noop } from "lib/util";
import { ArtifactTypes } from "lib/at-data/Artifact";

export const FLOOR_DIAGRAM_SOURCE_ID = "floor_diagram";
export const FLOOR_DIAGRAM_LAYER_ID = "floor_diagram_layer";

export const hideDiagramLayer = hideLayer(FLOOR_DIAGRAM_LAYER_ID);

export const setBuildingDiagramSource =
  (url: string, coordinates: number[][]) => (ctx: MapboxContext) => () => {
    pipe(
      ctx.map.getSource(FLOOR_DIAGRAM_SOURCE_ID),
      O.fromNullable,
      O.fold(
        () => {
          ctx.map.addSource(FLOOR_DIAGRAM_SOURCE_ID, {
            type: "image",
            url,
            coordinates,
          });
        },
        (as) => {
          if (as.type === "image") {
            // bad types in lib
            // @ts-ignore
            if (as.url !== url) {
              // @ts-ignore
              as.updateImage({
                url,
                coordinates,
              });
            }
          }
        }
      )
    );
  };

export const setupFloorDiagramLayer = addLayerToMap(
  FLOOR_DIAGRAM_LAYER_ID,
  {
    id: FLOOR_DIAGRAM_LAYER_ID,
    type: "raster",
    source: FLOOR_DIAGRAM_SOURCE_ID,
    paint: {
      "raster-fade-duration": 0,
    },
  },
  "all_floor_assets_fill_layer"
);

export const FloorPlanMetaModel = t.type({
  svgWidth: t.number,
  svgHeight: t.number,
  url: t.string,
  name: t.string,
});

export type BackgroundImageMeta = t.TypeOf<typeof FloorPlanMetaModel>;

export const fetchFloorPlanArtifactMeta = (
  assetId: UUID,
  token: string,
  signal: AbortSignal,
  artifactType: ArtifactTypes
) =>
  TE.tryCatch(
    () =>
      //https://at-otter-artifacts-dev.azurewebsites.net/api/assets/%7Bassetid%7D/background
      fetch(
        `${process.env.REACT_APP_API_URL}/artifacts/assets/${assetId}/${artifactType}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          signal,
        }
      ).then(handleResponse("Accessing Background Image API")),
    E.toError
  );

export const getImageProjectionTpl =
  (geoRef: GeoReference) =>
  (bm: BackgroundImageMeta): FloorPlanForMap =>
    [
      bm.url,
      pipe(
        [
          [0, 0],
          [Number(bm.svgWidth), 0],
          [Number(bm.svgWidth), Number(bm.svgHeight)],
          [0, Number(bm.svgHeight)],
        ],
        A.map(flow(toCoord, reprojectCoord(calculateAffineTransform(geoRef))))
      ),
    ];
