import React from "react";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import * as S from "fp-ts/Set";
import { eqUUID } from "lib/at-data/UUID";
import {
  SelectedAssetL,
  SelectedAssetsController,
  SelectedAssetsControllerComponent,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { BuildingEditor } from "views/authenticated/lucifer/BuildingEditor";
import { LuciferPageSidepanel } from "views/authenticated/assets/page/components/LuciferPageSidepanel/LuciferPageSidepanel";
import { OSMBuildingsCollector } from "controllers/OSMBuildingCollector/OSMBuildingsCollector";
import { SelectedAssetHierarchyCollector } from "controllers/SelectedAssetHierarchyCollector/SelectedAssetHierarchyCollector";

export const LuciferPage: React.FC<{}> = (props) => {
  const selectedAssetIds = useCollectorOld(
    UIStateController,
    flow(L.prop("state"), L.prop("luciferPage"), L.prop("selectedAssetIds"))
  );

  return (
    <SelectedAssetsControllerComponent
      context={{
        selectedAssetIds: pipe(selectedAssetIds, S.fromArray(eqUUID)),
      }}
    >
      <LuciferPageContent />
    </SelectedAssetsControllerComponent>
  );
};

export const LuciferPageContent: React.FC<{}> = (props) => {
  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );

  return (
    <SelectedAssetHierarchyCollector.Component
      context={{ asset: selectedAsset }}
    >
      <LuciferPageSidepanel />
      <OSMBuildingsCollector.Component context={{}}>
        <BuildingEditor />
      </OSMBuildingsCollector.Component>
    </SelectedAssetHierarchyCollector.Component>
  );
};
