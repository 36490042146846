import * as DataSeries from "./DataSeries";
import { pipe } from "fp-ts/function";
import { upsertTimestamp } from "lib/at-data/ChartDataSlice";
import { Magma } from "fp-ts/Magma";
import * as O from "fp-ts/Option";
import { isNumber } from "fp-ts/number";

export type NumberSeries = DataSeries.DataSeries<number>;

export const indexWith: (
  key: string
) => (value: number) => Readonly<Record<string, number>> =
  (key) => (value) => ({ [key]: value });

export const indexNumbersByKeyWithTimestamp: (
  key: string
) => (
  series: NumberSeries
) => Array<Readonly<Record<string, number> & { timestamp: number }>> =
  (key) => (series) =>
    pipe(
      series,
      DataSeries.mapWithIndex((timestamp, n) =>
        pipe(n, indexWith(key), upsertTimestamp(timestamp))
      ),
      DataSeries.toArray
    );

export const sum: (series: NumberSeries) => number = (series) =>
  pipe(
    series,
    DataSeries.reduce(0, (acc, v) => acc + v)
  );

export const max: (series: NumberSeries) => number = (series) =>
  pipe(series, DataSeries.reduce(0, Math.max));

export const min: (series: NumberSeries) => number = (series) =>
  pipe(series, DataSeries.reduce(Infinity, Math.min));

export const mean =
  (lengthFn: (series: NumberSeries) => number) =>
  (series: NumberSeries): O.Option<number> =>
    pipe(
      series,
      sum,
      (s) => s / lengthFn(series),
      O.fromPredicate(Number.isFinite)
    );

export const magmaAvg: Magma<number> = { concat: (x, y) => x + y / 2 };
