import * as R from "fp-ts/Reader";
import * as IO from "fp-ts/IO";
import * as E from "fp-ts/Either";
import * as IOEither from "fp-ts/IOEither";
import * as O from "fp-ts/Option";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { flow, pipe } from "fp-ts/function";
import { clog, noop } from "lib/util";
import { log } from "fp-ts/es6/Console";

export const runMapEffect =
  <T>(effect: R.Reader<MapboxContext, IO.IO<T>>) =>
  (spatialCommandContext: O.Option<MapboxContext>): IO.IO<void> =>
    pipe(
      spatialCommandContext,
      IOEither.fromOption(() => new Error("Map not Ready")),
      IOEither.fold(flow(noop, IO.of), (ctx) =>
        pipe(IOEither.tryCatch(effect(ctx), E.toError))
      )
    );
