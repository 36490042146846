import React from "react";
import { Form } from "../styles/Styles";

export const DeployFormArtifactsOrganizations: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <div>
          <label htmlFor={"artifact"}>Organization Logo (svg)</label>
          <input type={"file"} name={"artifact"} />
        </div>
      </fieldset>
    </Form>
  );
};

export const DeployFormArtifactsRegions: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <div>
          <label htmlFor={"photos"}>Region Photos</label>
          <input type={"file"} name={"photos"} />
        </div>
      </fieldset>
    </Form>
  );
};

export const DeployFormArtifactsBuildings: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <div>
          <label htmlFor={"photos"}>Building Photos</label>
          <input type={"file"} name={"photos"} />
        </div>
      </fieldset>
    </Form>
  );
};

export const DeployFormArtifactsFloors: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <div>
          <label htmlFor={"photos"}>Floor Photos</label>
          <input type={"file"} name={"photos"} />
        </div>
      </fieldset>
    </Form>
  );
};
