import React from "react";
import { Content, Main, Page } from "./Styles";
import { ButtonGuide } from "./ui/ButtonGuide";
import { Inputs } from "./ui/Inputs";

export const DesignPage: React.FC<{}> = (props) => {
  return (
    <Page>
      <Main>
        <Content>
          <ButtonGuide />
          <Inputs />
        </Content>
      </Main>
    </Page>
  );
};

export default DesignPage;
