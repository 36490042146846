import { pipe } from "fp-ts/function";
import { Coord, toPoint } from "lib/at-data/assets/models/Coord";
import { eqGeoCoord, GeoCoordModel } from "lib/at-data/GeoCoord";
import * as C from "io-ts/Codec";
import * as A from "fp-ts/Array";

export const GeometryModel = C.struct({
  geometryType: C.literal("Polygon"),
  coordinates: C.array(GeoCoordModel),
});

export type Geometry = C.TypeOf<typeof GeometryModel>;

export const eqGeometry = A.getEq(eqGeoCoord);
