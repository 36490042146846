import { Stack, styled, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import React from "react";

interface IPageTableCellText {
  /**
   * The string for the default text field (no-wrap).
   */
  text: string;
  /**
   * The string for the subtext field (no-wrap). Does **NOT** work with multiline.
   */
  subtext?: string;
  /**
   * Converts the text field into a multi-line field that truncates after the second line.
   */
  multiline?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles to the text field.
   */
  textStyles?: SxProps<Theme>;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles to the subtext field.
   */
  subtextStyles?: SxProps<Theme>;
}

const PageTableCellText: React.FC<IPageTableCellText> = ({
  text,
  subtext,
  multiline,
  textStyles,
  subtextStyles,
}) =>
  multiline ? (
    <StyledTypography
      variant="body2"
      title={text}
      color="textSecondary"
      sx={textStyles}
    >
      {text}
    </StyledTypography>
  ) : (
    <Stack>
      <Typography variant="body1" sx={textStyles} title={text} noWrap>
        {text}
      </Typography>
      {subtext ? (
        <Typography
          variant="body2"
          color="textSecondary"
          noWrap
          sx={subtextStyles}
          title={subtext}
        >
          {subtext}
        </Typography>
      ) : null}
    </Stack>
  );

export default PageTableCellText;

const StyledTypography = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
