import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Menu,
  RadioGroup,
  Stack,
  Typography,
  ToggleButton,
  ButtonGroup,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { BsFillPinAngleFill, BsCheck2 } from "react-icons/bs";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import Icon from "components/ui/Icon";
import LabeledSwitch from "components/ui/LabeledSwitch";
import LabeledCheckbox from "components/ui/LabeledCheckbox";
import LabeledRadio from "components/ui/LabeledRadio";
import styled from "@emotion/styled/macro";
import {
  SeriesFilter,
  SeriesFilterEnabledL,
  SeriesFilterNotL,
  WhenBookedL,
  WhenOccupiedL,
  WithinOperationHoursL,
} from "controllers/AssetsTableController/models/AssetsFormulaColumn";
import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { ap } from "fp-ts/lib/Identity";
import palette from "theme/palette";

const MenuListSwitch: React.FC<{
  label: string;
  onChange: () => void;
  disabled?: boolean;
  checked?: boolean;
}> = ({ label, onChange, disabled, checked }) => {
  return (
    <LabeledSwitch
      label={label}
      onChange={onChange}
      labelPlacement="start"
      mode="light"
      disabled={disabled}
      checked={checked}
      sx={{
        py: 1,
        width: "100%",
        justifyContent: "space-between",
        ".MuiSwitch-root": {
          marginRight: -1.5,
        },
        ".MuiFormControlLabel-label": {
          fontSize: "1.4rem",
        },
      }}
    />
  );
};

const MenuListCheckbox: React.FC<{
  label: string;
  onChange: () => void;
  disabled?: boolean;
  checked?: boolean;
}> = ({ label, onChange, disabled, checked }) => (
  <LabeledCheckbox
    label={label}
    mode="light"
    disabled={disabled}
    checked={checked}
    onChange={onChange}
    sx={{
      width: "100%",
      whiteSpace: "nowrap",
    }}
  />
);

const StandaloneToggleButton: React.FC<{
  selected: boolean;
  onChange: () => void;
  disabled?: boolean;
}> = (props) => (
  <ToggleButton
    value="not"
    disabled={props.disabled}
    selected={props.selected}
    onChange={props.onChange}
    size="small"
    sx={{
      fontSize: "1.2rem",
      padding: "0px",
      marginRight: "8px",
      textTransform: "capitalize",
      borderRadius: "3px",
      "&.Mui-selected": {
        color: "white",
        backgroundColor: `${palette.status.error.dark}`,
      },
      "&.Mui-selected:hover": {
        color: "white",
        backgroundColor: `${palette.status.error.dark}`,
      },
    }}
    aria-label="Toggle"
  >
    Not
  </ToggleButton>
);

export const ColumnSortingFilter: React.FC<{
  onAscClick?: () => void;
  onDescClick?: () => void;
}> = ({ onAscClick, onDescClick }) => (
  <Stack
    direction="column"
    alignItems="flex-start"
    justifyContent="space-between"
    sx={{ width: "100%" }}
    pb={1}
    pt={1}
  >
    <FilterButtons
      key="one"
      startIcon={<BsFillPinAngleFill />}
      aria-label="Pin Column"
    >
      Pin Column
    </FilterButtons>
    <FilterButtons
      key="two"
      onClick={onAscClick}
      startIcon={<BiSortUp />}
      aria-label="Sort Ascending"
    >
      Sort Ascending
    </FilterButtons>
    <FilterButtons
      key="three"
      onClick={onDescClick}
      startIcon={<BiSortDown />}
      aria-label="Sort Descending"
    >
      Sort Descending
    </FilterButtons>
  </Stack>
);

export const ColumFormulaConfiguration: React.FC<{
  checked: boolean;
  onChange: () => void;
}> = (props) => (
  <div>
    <MenuListSwitch
      label="Compared with"
      checked={props.checked}
      onChange={props.onChange}
    />
    <Collapse in={props.checked}>
      <Box pb={2}>
        <RadioGroup
          aria-label="compared with"
          defaultValue="maximum"
          name="compared-with-options-group"
        >
          <StyledLabeledRadio
            label="Maximum Occupancy"
            value="maximum"
            mode="light"
          />
          <StyledLabeledRadio
            label="Legal Occupancy"
            value="legal"
            mode="light"
          />
          <StyledLabeledRadio
            label="Booked Seats"
            value="booked"
            mode="light"
          />
        </RadioGroup>
      </Box>
    </Collapse>
  </div>
);

export const ColumnFormulaSeriesFilter: React.FC<{
  seriesFilter: SeriesFilter;
  onChange: (seriesFilter: SeriesFilter) => void;
}> = ({ seriesFilter, onChange }) => (
  <div>
    <MenuListSwitch label="Only" onChange={() => {}} checked={true} />
    <Collapse in={true}>
      <Stack pb={1}>
        <StyledStack>
          <StandaloneToggleButton
            selected={seriesFilter.withinOperationHours.not}
            onChange={() =>
              pipe(
                pipe(WithinOperationHoursL, L.compose(SeriesFilterNotL)).set,
                ap(!seriesFilter.withinOperationHours.not),
                ap(seriesFilter),
                onChange
              )
            }
            aria-label="Toggle"
          />
          <StyledMenuListCheckbox
            checked={seriesFilter.withinOperationHours.enabled}
            label="During Business Hours"
            onChange={() =>
              pipe(
                pipe(WithinOperationHoursL, L.compose(SeriesFilterEnabledL))
                  .set,
                ap(!seriesFilter.withinOperationHours.enabled),
                ap(seriesFilter),
                onChange
              )
            }
          />
        </StyledStack>
        <StyledStack sx={{ ml: "42px" }}>
          <StyledMenuListCheckbox
            label="When Occupied"
            checked={seriesFilter.whenOccupied.enabled}
            onChange={() =>
              pipe(
                pipe(WhenOccupiedL, L.compose(SeriesFilterEnabledL)).set,
                ap(!seriesFilter.whenOccupied.enabled),
                ap(seriesFilter),
                onChange
              )
            }
          />
        </StyledStack>
        <StyledStack>
          <StandaloneToggleButton
            disabled={true}
            selected={seriesFilter.whenBooked.not}
            onChange={() =>
              pipe(
                pipe(WhenBookedL, L.compose(SeriesFilterNotL)).set,
                ap(!seriesFilter.whenBooked.not),
                ap(seriesFilter),
                onChange
              )
            }
          />
          <StyledMenuListCheckbox
            disabled={true}
            label="When Booked"
            onChange={() => {}}
          />
        </StyledStack>
      </Stack>
    </Collapse>
  </div>
);

export const AssetsTableHeaderContextMenu: React.FC<{
  anchorEl: null | HTMLElement;
  onClose: () => void;
  onApply: () => void;
  title: string;
}> = ({ anchorEl, onClose, onApply, children, title }) => (
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
    disableScrollLock={true}
    mode="light"
    transformOrigin={{ horizontal: "left", vertical: "top" }}
    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    sx={{
      ".MuiList-root": {
        overflowX: "hidden",
        overflowY: "auto",
        width: 350,
        borderRadius: 0,
        maxHeight: "85vh",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    }}
    variant="selectedMenu"
    arai-label="Context Menu"
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      pt={1}
      pb={1}
    >
      <Typography variant="subtitle1" color="textTertiary">
        {title}
      </Typography>
      <IconButton onClick={onClose} aria-label="Close">
        <Icon icon={RiCloseFill} />
      </IconButton>
    </Stack>
    <Divider />

    {children}

    <Divider />

    <MenuControlButtonStack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      pt={1}
      pb={1}
    >
      <MenuControlButton onClick={onClose} aria-label="Close">
        Close
      </MenuControlButton>
      <MenuControlButton
        startIcon={<BsCheck2 />}
        onClick={onApply}
        aria-label="Apply"
      >
        Apply
      </MenuControlButton>
    </MenuControlButtonStack>
  </Menu>
);

export default AssetsTableHeaderContextMenu;

const StyledStack = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;

  & > *:first-of-type {
    width: 44px;
  }
`;

const FilterButtons = styled(Button)`
  border-radius: 3px;
  justify-content: flex-start;
  padding: 8px 12px;
  width: 100%;
  background-color: transparent;
  color: #0a0e1f;
  font-size: 1.2rem;
  font-weight: 400;

  &:hover {
    color: #400099;
    background-color: #f1f3ff !important;
  }
`;

const MenuControlButtonStack = styled(Stack)`
  & button:last-child {
    color: #400099;
  }
`;

const MenuControlButton = styled(Button)`
  border-radius: 3px;
  padding: 8px 12px;
  width: auto;
  background-color: transparent;
  color: #0a0e1f;
  font-size: 1.4rem;
  font-weight: 500;

  &:hover {
    color: #400099;
    background-color: #f1f3ff;
  }

  & span:first-of-type {
    margin-right: 2px;
  }
`;

const StyledLabeledRadio = styled(LabeledRadio)`
  margin: 0;
  width: 100%;
  border-radius: 2px;
`;

const StyledMenuListCheckbox = styled(MenuListCheckbox)`
  margin: 0;
  width: 100%;
  border-radius: 2px;
  white-space: nowrap;
`;
