import React, { useEffect, useState } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import {
  ActivityMapFloorDiagramDataController,
  floorPlanImageL,
} from "controllers/ActivityMapFloorDiagramController/ActivityMapFloorDiagramController";
import { pipe } from "fp-ts/function";
import * as AD from "lib/at-data/AsyncData";
import * as O from "fp-ts/Option";
import { ImageArtifactsController } from "controllers/FloorBackgroundImagesController/FloorBackgroundImagesController";
import * as L from "monocle-ts/Lens";
import { BackgroundArtifactsL } from "controllers/FloorBackgroundImagesController/lens";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import { noop } from "lib/util";

export const LevelDiagram: React.FC<{ level: Asset; inverted?: boolean }> = ({
  level,
  inverted,
}) => {
  const backgroundImageArtifacts = useCollectorOld(
    ImageArtifactsController,
    L.composeLens(BackgroundArtifactsL)
  );
  const image = pipe(backgroundImageArtifacts, getSliceByUUID(level.id));

  return (
    <div>
      {pipe(
        image,
        AD.DoneData,
        AD.fold(
          () => <span>loading</span>,
          O.foldW(
            () => null,
            (fp) => (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                src={fp.data}
                width={fp.planeWidth}
                height={fp.planeHeight}
                alt={""}
                style={{ filter: inverted ? "invert(1)" : "" }}
              />
            )
          )
        )
      )}
    </div>
  );
};
