import * as C from "io-ts/Codec";
import { pipe } from "fp-ts/function";
import * as D from "io-ts/Decoder";
import * as En from "io-ts/Encoder";

export const numberFromString = C.make(
  pipe(D.string, D.map(Number.parseFloat)),
  pipe(
    En.id<string>(),
    En.contramap((_) => `${_}`)
  )
);
