import React, { useCallback, useLayoutEffect, useRef } from "react";
import {
  closestSnap,
  extrudeEdge,
  findNearestPointOnEdge,
  fromPoints,
  getBBox,
  getEdge,
  getPolygonDimensions,
  getPolygonSnaps,
  SimplePolygon,
  simplifyEdges,
  snapToHorizontalGuidesFrom,
  snapToPointFrom,
  snapToVerticalGuidesFrom,
  toEdges,
  toLine,
  toPoints,
  toSVGDef,
  toSVGRect,
  updatePoint,
} from "lib/at-data/Edge";
import { Coord, eqCoord, getX, getY } from "lib/at-data/assets/models/Coord";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import * as n from "fp-ts/number";
import * as Ord from "fp-ts/Ord";
import * as O from "fp-ts/Option";
import palette from "theme/palette";
import { applyToPoint, applyToPoints, translate } from "transformation-matrix";
import { lineInterpolate } from "geometric";
import { noop } from "lib/util";
import { sequenceT } from "fp-ts/Apply";
import * as R from "fp-ts/Reader";
import * as b from "fp-ts/boolean";

export const EditablePolygon: React.FC<{
  points: Array<Coord>;
  snaps: Array<Coord>;
  onChanged: (points: Array<Coord>) => void;
  onDuplicate: (points: Array<Coord>) => void;
}> = (props) => {
  return pipe(
    props.points,
    A.uniq(eqCoord),
    fromPoints,
    O.fold(
      () => <div>Invalid Polygon</div>,
      (polygon) => (
        <DraggableEdges
          onDuplicate={props.onDuplicate}
          polygon={polygon}
          snaps={props.snaps}
          onChanged={props.onChanged}
        />
      )
    )
  );
};
export const distanceToLine = (
  point1: Coord,
  point2: Coord,
  { x, y }: Coord
) => {
  return (
    ((point2.y - point1.y) * x -
      (point2.x - point1.x) * y +
      point2.x * point1.y -
      point2.y * point1.x) /
    // eslint-disable-next-line no-restricted-properties
    Math.pow(
      // eslint-disable-next-line no-restricted-properties
      Math.pow(point2.y - point1.y, 2) + Math.pow(point2.x - point1.x, 2),
      0.5
    )
  );
};

export const editPolygonSnappingStrategy = (snaps: Array<Coord>) =>
  pipe(
    sequenceT(R.Applicative)(
      snapToPointFrom(snaps),
      snapToHorizontalGuidesFrom(snaps),
      snapToVerticalGuidesFrom(snaps)
    )
  );

export const DraggableEdges: React.FC<{
  polygon: SimplePolygon;
  snaps: Array<Coord>;
  onChanged: (points: Array<Coord>) => void;
  onDuplicate: (points: Array<Coord>) => void;
}> = (props) => {
  const edges = pipe(props.polygon, toEdges);
  const verticalGuides = pipe(props.snaps, A.map(getX));
  const horizontalGuides = pipe(props.snaps, A.map(getY));

  const elRef = useRef(null);
  const polygonRef = useRef(null);
  const copyPolygonRef = useRef(null);
  const cutPointRef = useRef(null);

  const handleDeletePoint = useCallback(
    (id: number) => (ev: any) => {
      if (ev.button === 2) {
        pipe(
          props.polygon,
          toPoints,
          A.deleteAt(id),
          O.fold(noop, props.onChanged)
        );
      }
    },
    [props.onChanged, props.polygon]
  );

  useLayoutEffect(() => {
    const polygonDimensions = pipe(props.polygon, getPolygonDimensions);

    const ctx = gsap.context(() => {
      gsap.to(polygonRef.current, 0, {
        clearProps: "transform",
      });
      gsap.to(cutPointRef.current, 0, {
        clearProps: "transform",
      });
      gsap.to([".Edge", ".Handle"], 0, {
        clearProps: "transform",
      });
      Draggable.create(polygonRef.current, {
        onDragStart() {
          gsap.to([".Handle", ".Edge"], { attr: { opacity: 0 }, duration: 0 });
        },
        onDragEnd(ev) {
          gsap.to([".Handle", ".Edge"], { attr: { opacity: 1 }, duration: 0 });

          pipe(
            applyToPoints(
              translate(this.x, this.y),
              pipe(props.polygon, toPoints)
            ),
            ev.altKey ? props.onDuplicate : props.onChanged
          );
        },
        onDrag(ev) {
          pipe(
            ev.altKey,
            b.fold(
              () => {
                gsap.to(copyPolygonRef.current, {
                  fillOpacity: 0,
                });
              },
              () => {
                gsap.to(copyPolygonRef.current, {
                  fillOpacity: 0.5,
                });
              }
            )
          );
        },
        snap: {
          points(cursorOffset) {
            const polygonTopLeftPosition = {
              x: polygonDimensions.leftEdge + cursorOffset.x,
              y: polygonDimensions.topEdge + cursorOffset.y,
            };

            return pipe(
              polygonTopLeftPosition,
              // @ts-ignore
              O.fromPredicate(() => !this.pointerEvent.shiftKey),
              O.map(
                flow(
                  editPolygonSnappingStrategy(
                    pipe(props.snaps, getPolygonSnaps(polygonDimensions))
                  ),
                  closestSnap(polygonTopLeftPosition),
                  (snapPoint) =>
                    applyToPoint(
                      translate(
                        -polygonDimensions.leftEdge,
                        -polygonDimensions.topEdge
                      ),
                      snapPoint
                    )
                )
              ),
              O.getOrElse(() => cursorOffset)
            );
          },
        },
        liveSnap: true,
      });
      edges.forEach((edge) => {
        Draggable.create(`#extruder${edge.a.id}`, {
          liveSnap: true,
          snap: {
            points(cursorOffset) {
              const cursorPosition = {
                x: edge.a.coord.x + cursorOffset.x,
                y: edge.a.coord.y + cursorOffset.y,
              };

              return pipe(
                cursorPosition,
                // @ts-ignore
                O.fromPredicate(() => !this.pointerEvent.shiftKey),
                O.map(
                  flow(
                    editPolygonSnappingStrategy(props.snaps),
                    closestSnap(cursorPosition)
                  )
                ),
                O.getOrElse(() => cursorPosition)
              );
            },
          },
          onDragParams: [edge.a.id],
          onDragEndParams: [edge.a.id],
          onDragStart() {
            gsap.to([".Handle", ".Edge"], { attr: { opacity: 0 } });
          },
          onDrag(id, maxId) {
            const x = gsap.getProperty(this, "x") as number;
            const y = gsap.getProperty(this, "y") as number;

            const extrudeDistance = distanceToLine(edge.b.coord, edge.a.coord, {
              x,
              y,
            });

            const newSimplePolygon = pipe(
              props.polygon,
              extrudeEdge(id, extrudeDistance),
              toSVGDef
            );

            gsap.to(polygonRef.current, {
              attr: { points: newSimplePolygon },
              duration: 0,
            });
          },
          onDragEnd(id) {
            gsap.to([".Handle", ".Edge"], { attr: { opacity: 1 } });
            const newX = this.pointerEvent.offsetX;
            const newY = this.pointerEvent.offsetY;

            const extrudeDistance = distanceToLine(edge.b.coord, edge.a.coord, {
              x: newX,
              y: newY,
            });

            pipe(
              props.polygon,
              extrudeEdge(id, extrudeDistance),
              simplifyEdges,
              toPoints,
              props.onChanged
            );
          },
        });
        Draggable.create(`#point${edge.a.id}`, {
          onClick: handleDeletePoint(edge.a.id),
          liveSnap: true,
          snap: {
            points: (cursorOffset) => {
              const cursorPosition = {
                x: edge.a.coord.x + cursorOffset.x,
                y: edge.a.coord.y + cursorOffset.y,
              };
              const final = pipe(
                cursorPosition,
                O.some,
                // O.fromPredicate(() => !this.pointerEvent.shiftKey),
                O.map(
                  flow(
                    editPolygonSnappingStrategy(props.snaps),
                    closestSnap(cursorPosition)
                  )
                ),
                O.getOrElse(() => cursorOffset)
              );
              return final;
            },
          },
          onDragStart() {
            gsap.to([".Handle", ".Edge"], { attr: { opacity: 0 } });
          },
          onDragParams: [edge.a.id, edges.length],
          onDragEndParams: [edge.a.id, edges.length],
          onDragEnd(id) {
            gsap.to([".Handle", ".Edge"], { attr: { opacity: 1 } });
            const newX = gsap.getProperty(this, "x") as number;
            const newY = gsap.getProperty(this, "y") as number;
            pipe(
              props.polygon,
              updatePoint(id, { x: newX, y: newY }),
              toPoints,
              props.onChanged
            );
          },
          onDrag(id) {
            const newX = this.x;
            const newY = this.y;

            const newPolygon = pipe(
              props.polygon,
              updatePoint(id, { x: newX, y: newY })
            );

            gsap.to(polygonRef.current, {
              attr: { points: pipe(newPolygon, toSVGDef) },
              duration: 0,
            });
          },
        });
      });
    }, elRef);
    return () => ctx.revert();
  }, [props.onChanged, props.snaps, props.polygon]);
  const handleDicerMove = useCallback(
    (id: number) => (ev: any) => {
      const edge = pipe(props.polygon, getEdge(id));
      const [minX, minY, maxX, maxY] = pipe(edge, getBBox);

      const segmentPoint = pipe(
        edge,
        findNearestPointOnEdge({
          x: ev.nativeEvent.offsetX,
          y: ev.nativeEvent.offsetY,
        })
      );

      gsap.to(cutPointRef.current, 0.2, {
        attr: {
          cx: segmentPoint.x,
          cy: segmentPoint.y,
        },
      });
    },
    [props.polygon]
  );

  const handleDice = useCallback(
    (id: number) => (ev: any) => {
      const x = gsap.getProperty(cutPointRef.current, "cx") as number;
      const y = gsap.getProperty(cutPointRef.current, "cy") as number;
      pipe(
        props.polygon,
        toPoints,
        A.insertAt(id + 1, { x, y }),
        O.fold(noop, props.onChanged)
      );
    },
    [props.onChanged]
  );

  const handleMouseOver = useCallback((ev) => {
    gsap.to(cutPointRef.current, 0.25, {
      opacity: 1,
    });
  }, []);
  const handleMouseOut = useCallback(() => {
    gsap.to(cutPointRef.current, 0.25, {
      opacity: 0,
    });
  }, []);

  return (
    <g ref={elRef}>
      <defs>
        <pattern
          id="diagonalHatch"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
        >
          <path
            d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
            stroke={palette.status.warning.dark}
            strokeWidth={1}
          />
        </pattern>
        <pattern
          id="scissors"
          x="0"
          y="0"
          patternUnits="userSpaceOnUse"
          width="6"
          height="6"
          viewBox="0 0 30.556 30.556"
        >
          <path
            stroke={"white"}
            d="M26.311,23.224c-0.812-1.416-2.072-2.375-3.402-2.736c-1.051-0.287-2.141-0.199-3.084,0.334l-2.805-4.904 c1.736-3.463,5.633-11.227,6.332-12.451C24.258,1.884,22.637,0,22.637,0l-7.36,12.872L7.919,0c0,0-1.62,1.884-0.715,3.466 c0.7,1.225,4.598,8.988,6.332,12.451l-2.804,4.904c-0.943-0.533-2.035-0.621-3.084-0.334c-1.332,0.361-2.591,1.32-3.403,2.736 c-1.458,2.547-0.901,5.602,1.239,6.827c0.949,0.545,2.048,0.632,3.107,0.345c1.329-0.363,2.591-1.322,3.402-2.735 c0.355-0.624,0.59-1.277,0.71-1.926v0.001c0.001-0.005,0.001-0.01,0.006-0.015c0.007-0.054,0.017-0.108,0.022-0.167 c0.602-4.039,1.74-6.102,2.545-7.104c0.807,1.002,1.946,3.064,2.547,7.104c0.006,0.059,0.016,0.113,0.021,0.167 c0.004,0.005,0.004,0.01,0.006,0.015v-0.001c0.121,0.648,0.355,1.302,0.709,1.926c0.812,1.413,2.074,2.372,3.404,2.735 c1.059,0.287,2.158,0.2,3.109-0.345C27.213,28.825,27.768,25.771,26.311,23.224z M9.911,26.468 c-0.46,0.803-1.189,1.408-1.948,1.615c-0.338,0.092-0.834,0.148-1.289-0.113c-0.97-0.555-1.129-2.186-0.346-3.556 c0.468-0.812,1.177-1.403,1.95-1.614c0.335-0.091,0.831-0.146,1.288,0.113C10.537,23.47,10.695,25.097,9.911,26.468z M23.881,27.97 c-0.455,0.262-0.949,0.205-1.287,0.113c-0.76-0.207-1.488-0.812-1.949-1.615c-0.783-1.371-0.625-2.998,0.346-3.555 c0.457-0.26,0.953-0.204,1.289-0.113c0.771,0.211,1.482,0.802,1.947,1.614C25.01,25.784,24.852,27.415,23.881,27.97z"
          />
        </pattern>
      </defs>

      <polygon
        pointerEvents={"none"}
        ref={copyPolygonRef}
        points={pipe(edges, toSVGRect)}
        fill="url(#diagonalHatch)"
        strokeWidth={0}
        fillOpacity={0}
        // fill={palette.primary["300"]}
      />
      <polygon
        ref={polygonRef}
        points={pipe(edges, toSVGRect)}
        fill="url(#diagonalHatch)"
        strokeWidth={0}
        fillOpacity={0.5}
        // fill={palette.primary["300"]}
      />

      {pipe(
        verticalGuides,
        A.mapWithIndex((i, guide) => (
          <line
            key={i}
            className={"vertGuide"}
            id={`snapGuide${i}`}
            x1={guide}
            y1={"0%"}
            x2={guide}
            y2={"100%"}
            opacity={0}
            strokeWidth={0.25}
            strokeDasharray={"2 2"}
            stroke={"red"}
          />
        ))
      )}
      {pipe(
        edges,
        A.mapWithIndex((i, edge) => {
          const interpolator = pipe(edge, toLine, lineInterpolate);

          const start = interpolator(0.05);
          const middle = interpolator(0.5);
          const end = interpolator(0.95);

          return (
            <g className={"Edge"} key={i}>
              <line
                data-id={i}
                id={`edge${i}`}
                x1={edge.a.coord.x}
                y1={edge.a.coord.y}
                x2={edge.b.coord.x}
                y2={edge.b.coord.y}
                strokeLinecap="square"
                strokeWidth={"2px"}
                strokeOpacity={1}
                stroke={palette.status.success.dark}
                pointerEvents={"none"}
              />
              <line
                x1={start[0]}
                y1={start[1]}
                x2={end[0]}
                y2={end[1]}
                strokeLinecap="round"
                strokeWidth={"32"}
                stroke={"silver"}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onMouseMove={handleDicerMove(i)}
                onClick={handleDice(i)}
                strokeOpacity={0}
              />
              <circle
                cx={middle[0]}
                cy={middle[1]}
                r={8}
                className={"Handle"}
                fill={"white"}
                id={`extruder${i}`}
              />
            </g>
          );
        })
      )}
      <circle
        pointerEvents={"none"}
        ref={cutPointRef}
        cx={0}
        cy={0}
        r={8}
        fill={palette.status.error.dark}
        stroke={"white"}
        strokeWidth={"2"}
      />

      {pipe(
        edges,
        A.mapWithIndex((i, edge) => (
          <circle
            key={`edge${i}`}
            className={"Handle"}
            id={`point${edge.a.id}`}
            cx={edge.a.coord.x}
            cy={edge.a.coord.y}
            r={8}
            fill={palette.status.success.dark}
            stroke={"white"}
            strokeWidth={"2"}
          />
        ))
      )}
    </g>
  );
};
