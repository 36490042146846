import { styled } from "@mui/material";
import React from "react";

export interface ReportType {
  /**
   * The type of report
   */
  reportType:
    | "bar"
    | "horizontal-stacked-bar"
    | "line"
    | "heatmap"
    | "scatter"
    | "waterfall"
    | "stacked-area"
    | string;
  /**
   * Alt text for the image (will default to whatever report type is selected)
   */
  alt?: string;
}

const ReportPlaceholderImage: React.FC<ReportType> = ({ reportType, alt }) => {
  switch (reportType) {
    case "bar":
      return (
        <Placeholder
          alt={alt || "Placeholder Bar Chart"}
          src="/images/bar-chart.svg"
        />
      );
    case "line":
      return (
        <Placeholder
          alt={alt || "Placeholder Line Graph"}
          src="/images/line-chart.svg"
        />
      );
    case "scatter":
      return (
        <Placeholder
          alt={alt || "Placeholder Scatter Plot"}
          src="./images/scatter-plot.svg"
        />
      );
    case "waterfall":
      return (
        <Placeholder
          alt={alt || "Placeholder Waterfall Chart"}
          src="./images/waterfall-chart.svg"
        />
      );
    case "stacked-area":
      return (
        <Placeholder
          alt={alt || "Placeholder Stacked Area Chart"}
          src="./images/stacked-area-chart.svg"
        />
      );
    case "heatmap":
      return (
        <Placeholder
          alt={alt || "Placeholder Heatmap"}
          src="/images/heatmap.svg"
        />
      );
    case "horizontal-stacked-bar":
      return (
        <Placeholder
          alt={alt || "Placeholder Horizontal Stacked Bar Chart"}
          src="/images/horizontal-bar.svg"
        />
      );

    default:
      return null;
  }
};

export default ReportPlaceholderImage;

const Placeholder = styled("img")`
  object-fit: cover;
  width: 100%;
`;
