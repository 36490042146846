import { Grid, Menu, Typography, Button } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import Icon, { AtCopyToClipboard } from "components/ui/Icon";
import copyToClipboard from "copy-to-clipboard";
import {
  ShareLinkController,
  ShareLinkModalEl,
} from "controllers/AppController/ShareLinkController";
import { useController } from "lib/at-react/defineController";
import mixpanel from "mixpanel-browser";

export const ShareLinkMenu: React.FC<{
  link: string;
}> = ({ link }) => {
  const [anchorEl, dispatch] = useController(
    ShareLinkController,
    ShareLinkModalEl.get
  );

  const handleClose = useCallback(() => {
    pipe(O.none, ShareLinkModalEl.set, dispatch);
  }, [dispatch]);

  const handleLinkCopy = useCallback(() => {
    copyToClipboard(link);
    mixpanel.track("Share Link Copied", { share_link: link });
  }, [link]);

  return (
    <Menu
      anchorEl={O.toNullable(anchorEl)}
      open={O.isSome(anchorEl)}
      onClose={handleClose}
      mode="dark"
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      disableScrollLock={true}
    >
      <Typography pb={1} pt={1}>
        Share this view with your teammates
      </Typography>
      <Typography variant="subtitle1" color="textTertiary">
        Shareable Link:
      </Typography>
      <Grid
        container
        alignItems="flex-start"
        gap={0}
        pt={1}
        pb={1}
        direction="row"
      >
        <p
          style={{
            color: "white",
            maxWidth: "500px",
            textOverflow: "ellipsis",
            overflowWrap: "anywhere",
            fontSize: "12px",
            backgroundColor: "#46495E",
            borderRadius: "4px",
            display: "flex",
            padding: "10px",
            marginTop: "0",
            marginRight: "12px",
          }}
        >
          {link}
        </p>
        <Button
          size="small"
          color="primary"
          endIcon={<Icon icon={AtCopyToClipboard} size="16px" />}
          onClick={handleLinkCopy}
        >
          Copy Link
        </Button>
      </Grid>
    </Menu>
  );
};
