import { AppController } from "controllers/AppController/AppController";
import { initialState } from "controllers/AssetsTableController/initialState";
import {
  PreferenceTypes,
  setPreferences,
  UserPreferencesController,
} from "controllers/UserPreferencesController/UserPreferencesController";
import { warn } from "fp-ts/Console";
import * as Eq from "fp-ts/Eq";
import * as IO from "fp-ts/IO";
import * as O from "fp-ts/Option";
import * as RT from "fp-ts/ReaderTask";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import * as C from "io-ts/Codec";
import { makeMemo, propToState } from "lib/at-react/collector";
import { useController } from "lib/at-react/defineController";
import { useCollectorWithDispatch } from "lib/at-react/hooks";
import { reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";
import * as React from "react";
import {
  Menu,
  IconButton,
  Checkbox,
  Button,
  Typography,
  styled,
  Box,
  LinearProgress,
} from "@mui/material";
import Icon, { AtConfigure, AtClose, AtExport } from "components/ui/Icon";
import palette from "theme/palette";
import theme from "theme/theme";
import {
  AssetTableColumnInfoModel,
  ByColumnName,
  toggleColumn,
} from "controllers/AssetsTableController/AssetsTableController";
import { ColumnsL } from "controllers/AssetsTableController/lens";
import { clog, noop } from "lib/util";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import {
  AssetsTableState,
  AssetsTableStateEq,
  TableColumnInfo,
  TableDataViews,
} from "controllers/AssetsTableController/state";
import * as b from "fp-ts/boolean";
import * as Se from "fp-ts/Set";
import * as s from "fp-ts/string";
import { toMinimalAppState } from "views/authenticated/app/ReadyAppState";
import * as AD from "lib/at-data/AsyncData";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { size } from "fp-ts/lib/Record";

export const CCCheckbox: React.FC<{
  title: string;
  mode: TableDataViews;
  columnName: string;
}> = ({ title, mode, columnName }) => {
  const [columns, dispatch] = useCollectorWithDispatch(
    AssetTableConfigurationController,
    L.composeLens(ColumnsL)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    pipe(ColumnsL, L.modify(pipe(columnName, toggleColumn(mode))), dispatch);
  };

  console.log(title, mode, columnName);

  const determineChecked = pipe(
    columns,
    A.map((column) =>
      pipe(
        column,
        ByColumnName(columnName),
        b.fold(
          () => false,
          () =>
            pipe(
              column.views,
              Se.elem(s.Eq)(mode),
              b.fold(
                () => false,
                () => true
              ),
              clog("BOOLEAN")
            )
        )
      )
    ),
    A.reduce(false, (acc, val) => acc || val) // reduce the array to a single boolean value
  );

  console.log(determineChecked);

  return (
    <Checkbox
      checked={determineChecked}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
};

export const CCRow: React.FC<{
  column: string;
  description: string;
  columnName: string;
}> = ({ column, description, columnName }) => {
  return (
    <Row>
      <Space>{column}</Space>
      <CheckTable>
        <CCCheckbox
          title={column}
          columnName={columnName}
          mode={TableDataViews.BROWSER}
        />
      </CheckTable>
      <CheckExport>
        <CCCheckbox
          title={column}
          columnName={columnName}
          mode={TableDataViews.CSV}
        />
      </CheckExport>
      <Description>{description}</Description>
    </Row>
  );
};

export const AssetTableConfigurationController = pipe(
  reactState<AssetsTableState>(initialState, AssetsTableStateEq),
  propToState(AssetsTableStateEq),
  makeMemo(Eq.struct({ state: AssetsTableStateEq }))
);

export const AssetsTableConfigurationMenu: React.FC<{
  columns: Array<TableColumnInfo>;
}> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <ColumnConfigIconButton
        onClick={handleClick}

        // aria-controls={open ? 'account-menu' : undefined}
        // aria-haspopup="true"
        // aria-expanded={open ? 'true' : undefined}
      >
        <ColumnConfigIcon icon={AtConfigure} size={"30"} />
      </ColumnConfigIconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // unmounting ensures controller gets reset when model is closed
        keepMounted={false}
        // onClick={handleClose}

        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{ style: { width: "100vw" } }}
      >
        <AssetTableConfigurationController.Component
          context={{ state: { columns: props.columns } }}
        >
          <ColumnListContainer>
            <CCHeading>
              <Typography>Table Column Configuration</Typography>
              <Button
                size={"small"}
                onClick={handleClose}
                sx={{ minWidth: "36px" }}
              >
                <Icon icon={AtClose} size={"18"} />
              </Button>
            </CCHeading>
            <CCHeader>
              <Space>Column</Space>
              <CheckTable>Table</CheckTable>
              <CheckExport>Export</CheckExport>
              <Description>Description</Description>
            </CCHeader>
            <div
              style={{
                height: "calc(100vh - 200px)",
                overflowY: "scroll",
              }}
            >
              {/*<CCRow*/}
              {/*  column="Assets"*/}
              {/*  description="Name of the physical space within the organization."*/}
              {/*  columnName={"asset"}*/}
              {/*/>*/}
              <CCRow
                column="Utilization"
                description="Average number of occupants divided by capacity for the time period selected, filtered to only include specified operating hours."
                columnName={"assetMeanUtilization"}
              />
              <CCRow
                column="Occupancy"
                description="Average number of people in the space for the time period selected."
                columnName={"assetOccupancyChart"}
              />
              <CCRow
                column="Mean Occupancy"
                description="Average number of occupants in the space for the time period selected, filtered to only include specified operating hours."
                columnName={"assetMeanOccupancy"}
              />
              <CCRow
                column="Peak Occupancy"
                description="Highest observed occupancy in the space for the time period selected."
                columnName={"assetPeakOccupancy"}
              />
              <CCRow
                column="Peak Utilization"
                description="Peak occupancy divided by capacity for the time period selected."
                columnName={"assetPeakUtilization"}
              />
              <CCRow
                column="Size"
                description="Estimated square footage of the defined space."
                columnName={"assetArea"}
              />
              <CCRow
                column="Price"
                description="Price per sq. ft. for the space, as specified by the organization."
                columnName={"assetPricePerArea"}
              />
              <CCRow
                column="Underutilization Cost"
                description="The annualized cost of the space utilized less than its specified target. It is calculated as Size multiplied by the Price multiplied by the (Target Utilization - Actual Utilization)."
                columnName={"assetUnderutilizationCost"}
              />
              <CCRow
                column="Custom Tags"
                description="User defined descriptors that help identify and group space characteristics."
                columnName={"assetTags"}
              />
              <CCRow
                column="Target Utilization"
                description="Organization-specific utilization goal, which can be set at a space level."
                columnName={"assetTargetUtilization"}
              />
              <CCRow
                column="Capacity"
                description="The organization-defined maximum occupancy of the space. Can be based on size, expected registration, or type of space. Driver of utilization calculation."
                columnName={"assetCapacity"}
              />
              <CCRow
                column="Cost"
                description="The organization-defined maximum occupancy of the space. Can be based on size, expected registration, or type of space. Driver of utilization calculation."
                columnName={"cost"}
              />
              <CCRow
                column="Short Code"
                description="A short alphanumeric representation of the asset."
                columnName={"assetShortCode"}
              />
              <CCRow
                column="Parent Building"
                description="Building in which the asset is located."
                columnName={"assetParentBuilding"}
              />
              {/*<CCRow*/}
              {/*    column="Legacy ID"*/}
              {/*    description="System ID of the asset."*/}
              {/*/>*/}
              {/*<CCRow*/}
              {/*    column="Issues"*/}
              {/*    description="Number of issues with asset in system."*/}
              {/*/>*/}
            </div>
            <CCFooter>
              <Button onClick={handleClose}>Cancel</Button>
              <div>&nbsp;&nbsp;&nbsp;</div>
              <SaveTableConfigurationButton onSuccess={handleClose} />
            </CCFooter>
          </ColumnListContainer>
        </AssetTableConfigurationController.Component>
      </Menu>
    </>
  );
};

export const SaveTableConfigurationButton: React.FC<{ onSuccess: () => void }> =
  (props) => {
    const [columns, dispatch] = useCollectorWithDispatch(
      AssetTableConfigurationController,
      L.composeLens(ColumnsL)
    );
    const [preferences, preferencesDispatch] = useController(
      UserPreferencesController,
      (_) => _
    );

    const [isLoading, setIsLoading] = useState(false);

    const [appState] = useController(AppController, (_) => _);

    const handleSaveConfiguration = () => {
      pipe(
        appState,
        toMinimalAppState,
        O.foldW(
          () => TE.of(noop),
          (appContext) =>
            pipe(
              columns,
              C.array(AssetTableColumnInfoModel).encode,
              setPreferences(PreferenceTypes.COLUMNS),
              RTE.foldW(
                flow(
                  warn,
                  IO.apFirst(pipe(setIsLoading(false), IO.of)),
                  RT.fromIO
                ),
                (updatedColumns) =>
                  pipe(
                    { ...preferences, ...updatedColumns },
                    preferencesDispatch,
                    IO.of,
                    IO.apFirst(pipe(setIsLoading(false), IO.of)),
                    RTE.fromIO,
                    RTE.chainFirstIOK(IO.of(props.onSuccess))
                  )
              )
            )({ appContext })
        )
      )();
    };

    return (
      <>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={handleSaveConfiguration}
          startIcon={
            <Icon icon={AtExport} size="16px" sx={{ marginLeft: "6px" }} />
          }
        >
          Apply
        </LoadingButton>
      </>
    );
  };

const ColumnListContainer = styled("div")`
  padding: 10px;
`;
const CCHeading = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  & > p {
    font-size: 22px;
    font-weight: 600;
  }
`;
const CCHeader = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > span {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
  }
`;
const Row = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #eee;
  & > span {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const Space = styled("span")`
  max-width: 180px;
  width: 100%;
  padding: 8px 10px;
`;
const CheckTable = styled("span")`
  max-width: 80px;
  min-width: 80px;
  text-align: center;
  padding: 2px 10px;
  & > * {
    margin: 0 auto;
  }
`;
const CheckExport = styled("span")`
  max-width: 80px;
  min-width: 80px;
  text-align: center;
  padding: 2px 10px;
  & > * {
    margin: 0 auto;
  }
`;
const Description = styled("span")`
  max-width: 760px;
  width: 100%;
  padding: 8px 10px;
`;
const CCFooter = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: solid 1px #eeeeee;
  padding-top: 12px;
`;

export const ColumnConfigIcon = styled(Icon)`
  color: ${palette.neutral[450]};
  size: 24px;
  &:hover {
    color: ${palette.primary[500]};
  }
  &:active {
    color: ${palette.primary[500]};
  }
  &:click {
    color: ${palette.primary[500]};
  }
`;

export const ColumnConfigIconButton = styled(IconButton)`
  color: ${palette.neutral[100]};
  height: 40px;
  width: 40px;
  margin: 0;
  transition: ${theme.transitions.create(["background-color", "color"])};

  &:hover,
  &:focus {
    color: ${palette.neutral[100]};
    background-color: ${palette.neutral[100]};
  }
`;
