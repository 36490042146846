import React from "react";
import { ActivityPageSidepanel } from "views/authenticated/assets/page/components/ActivityPageSidepanel/ActivityPageSidepanel";
import ActivityContent from "views/authenticated/activity/page/components/ActivityContent";
import * as L from "monocle-ts/Lens";
import { useCollectorOld } from "lib/at-react/defineController";
import {
  SelectedAssetL,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { SelectedAssetHierarchyCollector } from "controllers/SelectedAssetHierarchyCollector/SelectedAssetHierarchyCollector";

const ActivityPageContent: React.FC<{}> = () => {
  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );

  return (
    <SelectedAssetHierarchyCollector.Component
      context={{ asset: selectedAsset }}
    >
      <ActivityPageSidepanel />
      <ActivityContent />
    </SelectedAssetHierarchyCollector.Component>
  );
};

export default ActivityPageContent;
