import LabeledCheckbox from "components/ui/LabeledCheckbox";
import * as R from "fp-ts/Reader";
import { Assets } from "lib/at-data/assets/assets";
import numbro from "numbro";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import copyToClipboard from "copy-to-clipboard";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import Icon, { AtCopyToClipboard } from "components/ui/Icon";
import * as Eq from "fp-ts/Eq";
import * as b from "fp-ts/boolean";
import { RiCloseFill, RiSave3Fill } from "react-icons/ri";
import palette from "theme/palette";
import {
  isoUSDPerOneSqF,
  isoUSDPerOneSqM,
  toUSDPerOneSqF,
  USDPerOneSqM,
} from "lib/at-data/units/currency";
import { useCollectorOld } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import * as L from "monocle-ts/Lens";
import {
  getAllChildren,
  getAssetById,
  getParentLine,
} from "lib/at-data/assets/filters";
import * as A from "fp-ts/Array";
import { Asset } from "lib/at-data/assets/Asset";
import { FloorMeta } from "lib/at-data/assets/models/FloorMetaModel";
import { getFloorLabel } from "views/authenticated/activity/page/components/activityControls/ActivityControlsFloorPickerMenu";
import { formatNumber, OneDecimal } from "lib/formatters/formatNumber";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import * as E from "fp-ts/Either";
import validate = numbro.validate;

export interface PropertyProps<T> {
  value: T;
}

export const Property = <T,>(props: PropertyProps<T>) => (
  <span>{props.value}</span>
);

export const PercentProperty: React.FC<{ value: number }> = (props) => (
  <span>{pipe(props.value, toPercentage, formatNumber(OneDecimal))}</span>
);

export interface OptionalPropertyProps<T> {
  value: O.Option<T>;
  component: React.FC<{ value: T }>;
  required?: boolean;
}

export const OptionalProperty = <T,>({
  value,
  component,
  required,
}: OptionalPropertyProps<T>) => (
  <>
    {pipe(
      value,
      O.foldW(
        () => (
          <span style={{ color: required ? "red" : "inherit" }}>Not Set</span>
        ),
        (p) => React.createElement(component, { value: p })
      )
    )}
  </>
);

export interface ClearablePropertyProps<T> {
  onClear: () => void;
  component: React.ReactNode;
}

export const ClearableProperty = <T,>(props: ClearablePropertyProps<T>) => (
  <Stack
    direction={"row"}
    style={{ justifyContent: "flex-end", alignItems: "center" }}
  >
    {props.component}
    <Link
      style={{
        textDecoration: "none",
        paddingLeft: "1em",
      }}
      component={"button"}
      variant="body2"
      onClick={props.onClear}
    >
      Clear
    </Link>
  </Stack>
);

export const ClipboardCopy: React.FC<{ value: string }> = (props) => {
  const handleLinkCopy = useCallback(() => {
    copyToClipboard(`${props.value}`);
  }, [props.value]);
  return (
    <IconButton onClick={handleLinkCopy}>
      <Icon icon={AtCopyToClipboard} size="16px" />
    </IconButton>
  );
};

export const LevelProperty: React.FC<{ value: FloorMeta }> = (props) => (
  <span>{pipe(props.value, getFloorLabel)}</span>
);

export const USDPerSquareMeterProperty: React.FC<{ value: USDPerOneSqM }> = (
  props
) => <span>{pipe(props.value, isoUSDPerOneSqM.unwrap)} USD/m&sup2;</span>;

export const USDPerSquareFtProperty: React.FC<{ value: USDPerOneSqM }> = (
  props
) => (
  <span>
    {pipe(props.value, toUSDPerOneSqF, isoUSDPerOneSqF.unwrap)} USD/ft&sup2;
  </span>
);

export interface EditableProps<T> {
  onChange: (value: T, applyToChildren: boolean) => void;
  editor: React.FC<{
    value: T;
    onChange: (value: T) => void;
    onDone: () => void;
    onCancel: () => void;
    pending: boolean;
    error?: boolean;
    helperText?: ReactNode;
  }>;
  validationFn?: (value: T) => E.Either<Array<string>, T>;
  value: T;
  eq: Eq.Eq<T>;
  children: React.ReactElement;
  inheritanceAsset?: Asset;
}

export const hasSameValue =
  <T,>(eq: Eq.Eq<O.Option<T>>) =>
  (value: O.Option<T>) =>
  (v: O.Option<T>) =>
    eq.equals(v, value);

export const InheritanceIndicator: React.FC<{
  asset: Asset;
  value: boolean;
  onChange: (newValue: boolean) => void;
}> = (props) => {
  const numOfChildrenAssets = pipe(
    useCollectorOld(AssetsController, flow(L.prop("assets"))),
    fromAD2,
    (assets) =>
      pipe(
        props.asset,
        getAllChildren,
        R.map((children) => pipe(children, A.size))
      )({ assets })
  );

  return (
    <Stack>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value}
            onChange={(ev, checked) => props.onChange(checked)}
          />
        }
        label={
          <span style={{ fontSize: "0.9em", overflow: "hidden" }}>
            Apply to <strong>{numOfChildrenAssets}</strong> children
          </span>
        }
      />
    </Stack>
  );
};

export const Editable = <T,>({
  validationFn = (v) => E.right(v),
  ...props
}: EditableProps<T>) => {
  const [editMode, setEditMode] = useState(false);
  const [inheritanceEnabled, setInheritanceEnabled] = useState(false);
  const [localValue, setLocalValue] = useState(props.value);

  const pending = !props.eq.equals(localValue, props.value);
  const validatedValue = pipe(localValue, validationFn);
  const isInvalid = E.isLeft(validatedValue);

  const handleEditChange = useCallback((value: T) => {
    setLocalValue(value);
  }, []);
  const handleStartEdit = useCallback(() => {
    setEditMode(true);
  }, []);
  const handleEditComplete = useCallback(() => {
    props.onChange(localValue, inheritanceEnabled);
  }, [localValue, inheritanceEnabled]);
  const handleEditCancel = useCallback(() => {
    setEditMode(false);
    setLocalValue(props.value);
  }, [localValue]);

  useEffect(() => {
    setEditMode(false);
    setLocalValue(props.value);
  }, [props.children, props.value]);

  return pipe(
    editMode,
    b.fold(
      () => (
        <Link
          style={{
            textDecoration: "none",
            cursor: "text",
          }}
          component={"button"}
          variant="body2"
          onClick={() => {
            setEditMode(true);
          }}
        >
          {props.children}
        </Link>
      ),
      () => (
        <Stack direction={"column"}>
          <Stack direction={"row"}>
            {React.createElement(props.editor, {
              value: localValue,
              onChange: handleEditChange,
              onDone: handleEditComplete,
              onCancel: handleEditCancel,
              pending,
              error: isInvalid,
              helperText: pipe(
                validatedValue,
                E.swap,
                O.fromEither,
                O.chain(A.head),
                O.toUndefined
              ),
            })}
            <IconButton
              onClick={handleEditComplete}
              aria-label="Save"
              disabled={!pending || isInvalid}
            >
              <Icon
                icon={RiSave3Fill}
                color={
                  !pending || !isInvalid
                    ? palette.status.success.default
                    : palette.neutral["200"]
                }
              />
            </IconButton>
            <IconButton onClick={handleEditCancel} aria-label="Cancel">
              <Icon icon={RiCloseFill} />
            </IconButton>
          </Stack>
          {props.inheritanceAsset && (
            <InheritanceIndicator
              asset={props.inheritanceAsset}
              value={inheritanceEnabled}
              onChange={setInheritanceEnabled}
            />
          )}
        </Stack>
      )
    )
  );
};

export interface ModalEditableProps<T> {
  onChange: (value: T, applyToChildren: boolean) => void;
  editor: React.FC<{
    value: T;
    onDone: (value: T, applyToChildren: boolean) => void;
    onCancel: () => void;
    inheritanceAsset: Asset;
  }>;
  value: T;
  children: React.ReactElement;
  inheritanceAsset: Asset;
}
export const ModalEditable = <T,>({ ...props }: ModalEditableProps<T>) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(false);
  }, [props.children, props.value]);

  return pipe(
    editMode,
    b.fold(
      () => (
        <Link
          style={{
            textDecoration: "none",
            cursor: "text",
          }}
          component={"button"}
          variant="body2"
          onClick={() => {
            setEditMode(true);
          }}
        >
          {props.children}
        </Link>
      ),
      () => (
        <Stack direction={"column"}>
          <Stack direction={"row"}>
            {props.children}
            {React.createElement(props.editor, {
              value: props.value,
              onDone: props.onChange,
              onCancel: () => {
                setEditMode(false);
              },
              inheritanceAsset: props.inheritanceAsset,
            })}
          </Stack>
        </Stack>
      )
    )
  );
};
