import { addSeconds, differenceInSeconds, subSeconds } from "date-fns/fp";
import * as D from "fp-ts/Date";
import * as E from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as n from "fp-ts/number";
import * as C from "io-ts/Codec";
import {
  changeEnd,
  changeStart,
  lengthInSeconds,
  SeriesRequest,
  SeriesRequestModel,
} from "lib/at-data/requests/temporal/SeriesRequest";
import { RESOLUTION, ResolutionModel, roundDown } from "lib/at-data/Resolution";
import * as L from "monocle-ts/Lens";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";

export const GranulatedSeriesRequestModel = pipe(
  SeriesRequestModel,
  C.intersect(
    C.struct({
      resolution: pipe(ResolutionModel, C.fromDecoder),
    })
  )
);
export type GranulatedSeriesRequest = C.TypeOf<
  typeof GranulatedSeriesRequestModel
>;

export const GranulatedSeriesRequest = (
  start: Date,
  end: Date,
  resolution: RESOLUTION
): GranulatedSeriesRequest => ({
  start,
  end,
  resolution,
});

export const fromSeriesRequest =
  (resolution: RESOLUTION) =>
  ({ start, end }: SeriesRequest): GranulatedSeriesRequest => ({
    start,
    end,
    resolution,
  });

export const GranulatedSeriesRequestEq = E.struct<GranulatedSeriesRequest>({
  start: D.Eq,
  end: D.Eq,
  resolution: n.Eq,
});

export const getLastSlice = ({
  end,
  resolution,
}: GranulatedSeriesRequest): SeriesRequest => ({
  start: pipe(end, subSeconds(resolution)),
  end,
});

export const getFirstSlice = ({
  start,
  resolution,
}: GranulatedSeriesRequest): SeriesRequest => ({
  start,
  end: pipe(start, addSeconds(resolution)),
});

export const numOfTimestamps = (
  start: Date,
  end: Date,
  resolution: RESOLUTION
) => differenceInSeconds(start, end) / resolution;

export const makeTimestamps: (
  r: GranulatedSeriesRequest
) => { timestamp: Date }[] = ({ start, end, resolution }) =>
  A.makeBy(numOfTimestamps(start, end, resolution), (i) => ({
    timestamp: pipe(start, addSeconds(i * resolution)),
  }));

export const getActiveBucket =
  (now: Date) =>
  (seriesRequest: GranulatedSeriesRequest): SeriesRequest => {
    return pipe(
      seriesRequest,
      makeTimestamps,
      A.findFirst(
        (a) =>
          pipe(
            now,
            differenceInSeconds(
              pipe(a.timestamp, addSeconds(seriesRequest.resolution))
            )
          ) < seriesRequest.resolution
      ),
      O.map((t) => ({
        start: t.timestamp,
        end: pipe(t.timestamp, addSeconds(seriesRequest.resolution)),
      })),
      O.getOrElse(() => ({
        start: pipe(seriesRequest.end, subSeconds(seriesRequest.resolution)),
        end: seriesRequest.end,
      }))
    );
  };

export const changeResolution = (fn: (resolution: RESOLUTION) => RESOLUTION) =>
  pipe(L.id<GranulatedSeriesRequest>(), L.prop("resolution"), L.modify(fn));

export const numberOfBuckets = (series: GranulatedSeriesRequest) =>
  Math.floor(pipe(series, lengthInSeconds) / series.resolution);

export const MIN_RESOLUTION = 300;
// change the data, keeping the same number of data points
export const scaleAroundOffset =
  (ratio: number, resolution: number, snapFn: (n: number) => number) =>
  (offset: number) =>
  (series: GranulatedSeriesRequest): GranulatedSeriesRequest => {
    const newResolution = pipe(series.resolution * ratio, snapFn, (_) =>
      _ < MIN_RESOLUTION ? MIN_RESOLUTION : _
    );
    const newStart = pipe(
      series.start,
      addSeconds(series.resolution * offset),
      subSeconds(newResolution * offset),
      roundDown(newResolution)
    );
    const newEnd = pipe(
      newStart,
      addSeconds(newResolution * numberOfBuckets(series))
    );

    const newSeries = {
      start: newStart,
      end: newEnd,
      resolution: newResolution,
    };
    return newSeries;
  };

export const scaleAround =
  (offset: number, resolution: number, direction: number) =>
  (series: SeriesRequest): SeriesRequest => {
    const newStart = pipe(series.start, addSeconds(resolution * direction));
    const newEnd = pipe(series.end, subSeconds(resolution * direction));
    const newSeries = {
      start: newStart,
      end: newEnd,
    };
    const newDuration = pipe(newEnd, differenceInSeconds(newStart));

    return newDuration < 86399 / 2 || newDuration > 86400 * 367
      ? series
      : newSeries;
  };
export const slide =
  (offset: number) =>
  ({
    start,
    end,
    resolution,
  }: GranulatedSeriesRequest): GranulatedSeriesRequest => ({
    start: pipe(start, addSeconds(offset * resolution)),
    end: pipe(end, addSeconds(offset * resolution)),
    resolution,
  });

export const tripleRange = <T extends GranulatedSeriesRequest>(series: T) => {
  const length = pipe(series, numberOfBuckets);
  return pipe(
    series,
    changeStart(subSeconds(length * series.resolution)),
    changeEnd(addSeconds(length * series.resolution))
  );
};
