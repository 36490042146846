import React from "react";

export const NovaLogo = () => (
  <div>
    <svg
      width="135"
      height="77"
      viewBox="0 0 135 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.0655 34.1706C64.8874 34.1706 59.392 32.6549 54.5922 28.9917L46.6973 38.4029C52.3178 43.329 60.4028 45.8555 69.3717 45.8555C81.2461 45.8555 88.8881 40.1715 88.8881 30.8863V30.7603C88.8881 21.8554 81.3087 18.5711 70.0035 16.485C65.329 15.6031 64.1295 14.8452 64.1295 13.6429V13.5189C64.1295 12.444 65.1394 11.6863 67.351 11.6863C71.4559 11.6863 76.4454 13.0125 80.6151 16.044L87.8147 6.06438C82.6991 2.02345 76.3826 0.00148613 67.8561 0.00148613C55.6654 0.00148613 49.0968 6.50726 49.0968 14.9071V15.0346C49.0968 24.3825 57.8129 27.2223 67.7293 29.2445C72.4665 30.1918 73.8569 30.8863 73.8569 32.1506V32.276C73.8569 33.4768 72.7195 34.1706 70.0655 34.1706Z"
        fill="white"
      />
      <path
        d="M113.021 45.793C126.221 45.793 135.001 39.8555 135.001 24.7603V0.694654H119.969V25.2013C119.969 30.5703 117.126 32.7178 113.147 32.7178C109.168 32.7178 106.325 30.3808 106.325 24.8862V0.694654H91.293V25.0759C91.293 39.7301 99.8195 45.793 113.021 45.793Z"
        fill="white"
      />
      <path
        d="M13.9247 45.0352C16.1266 39.3912 14.7611 32.5442 13.6195 28.4724C13.5196 28.1142 13.8937 27.8027 14.2519 27.944C23.739 31.6727 29.416 40.4742 31.8233 45.0352H37.1187H44.213V0.82084H29.5594V20.3392L13.8317 0.82084H0V45.0352H7.51178H13.9247Z"
        fill="white"
      />
      <path
        d="M2.44633 66.4963H14.1833V64.2657H2.44633V56.5244H15.5769V54.2634H0V75.9414H2.44633V66.4963Z"
        fill="white"
      />
      <path d="M20.0068 54.2599H17.627V75.9844H20.0068V54.2599Z" fill="white" />
      <path
        d="M24.3503 67.7727C24.3503 64.397 26.7353 61.5776 30.2049 61.5776C33.5813 61.5776 36.1527 64.397 36.1527 67.8351V67.8974C36.1527 71.2741 33.7358 74.0616 30.267 74.0616C26.8906 74.0616 24.3503 71.2741 24.3503 67.8351V67.7727ZM30.2049 76.1992C35.037 76.1992 38.5998 72.3577 38.5998 67.8351V67.7727C38.5998 63.251 35.0681 59.471 30.267 59.471C25.4341 59.471 21.9023 63.3124 21.9023 67.8351V67.8974C21.9023 72.4201 25.403 76.1992 30.2049 76.1992Z"
        fill="white"
      />
      <path
        d="M42.9359 69.555C42.9359 64.6953 45.8024 62.1708 49.3229 62.1708H49.5093V59.5855C46.4556 59.4607 44.1198 61.3921 42.9359 64.0718V59.8662H40.5371V75.9727H42.9359V69.555Z"
        fill="white"
      />
      <path d="M50.9922 75.9766H53.372V59.6646H50.9922V75.9766Z" fill="white" />
      <path
        d="M53.4874 54.3062H50.7676V56.9336H53.4874V54.3062Z"
        fill="white"
      />
      <path
        d="M57.734 67.9021C57.734 64.0394 60.2683 61.7524 63.3582 61.7524C66.3257 61.7524 69.1378 64.2247 69.1378 67.9021V67.9644C69.1378 71.6418 66.3257 74.1454 63.3582 74.1454C60.3296 74.1454 57.734 71.7344 57.734 67.9644V67.9021ZM62.9259 76.2773C65.8623 76.2773 67.7468 74.6392 69.0446 72.7237V75.9376H71.4244V54.2326H69.0446V63.0197C67.8081 61.2266 65.8925 59.6198 62.9259 59.6198C59.0939 59.6198 55.293 62.7097 55.293 67.9334V67.9948C55.293 73.2489 59.0939 76.2773 62.9259 76.2773Z"
        fill="white"
      />
      <path
        d="M75.5607 71.0748C75.5607 69.1435 77.2744 67.8347 80.266 67.8347C82.2281 67.8347 83.7856 68.1778 84.9695 68.5199V70.0154C84.9695 72.5697 82.5707 74.346 79.611 74.346C77.4306 74.346 75.5607 73.1619 75.5607 71.1372V71.0748ZM79.1442 76.2773C81.9788 76.2773 83.8167 74.9685 84.9384 73.536V75.9342H87.3061V66.0897C87.3061 64.0343 86.7453 62.4764 85.6244 61.3547C84.4716 60.202 82.6949 59.5784 80.4213 59.5784C78.0536 59.5784 76.3399 60.1396 74.5641 60.9502L75.2811 62.9119C76.7756 62.227 78.271 61.7591 80.1728 61.7591C83.1946 61.7591 84.9384 63.255 84.9384 66.1207V66.6196C83.506 66.2144 82.0728 65.9347 80.0166 65.9347C75.9343 65.9347 73.1309 67.7413 73.1309 71.1995V71.2619C73.1309 74.5641 76.1216 76.2773 79.1442 76.2773Z"
        fill="white"
      />
    </svg>
  </div>
);
