import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetNameCell } from "views/authenticated/assets/page/components/AssetsTable/cells/AssetNameCell";
import { AssetContext } from "contexts/AssetContext";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { AssetRowContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowContext";
import { ordAssetByName } from "lib/at-data/assets/models/AssetModel";
import { UserContext } from "contexts/UserContext";
import { isFeatureFlagAvailable } from "controllers/AppController/IsFeatureFlagAvailable";
import { EDIT_FEATURE } from "constants/featureFlags";

export const AssetsNameColumn = defineColumn(
  "asset",
  R.asks<AssetRowDataContext, AssetContext>(({ asset }) => ({ asset })),
  pipe(
    R.ask<AssetRowContext>(),
    R.map((_) => AssetNameCell(_.allAssets, _.onAssetNameClick))
  ),
  //@ts-ignore
  flow(readColumnData, A.size, String, O.some, (value) => (
    <TableHeaderKpi
      label="Assets"
      hint="Name of the physical space within the organization."
      value={value}
    />
  )),
  pipe(
    R.asks<UserContext, boolean>((_) =>
      pipe(_.user, isFeatureFlagAvailable(EDIT_FEATURE))
    ),
    R.map((editable) => ({
      defaultFlex: 1,
      minWidth: 350,
      sort: ordAssetByName.compare,
      editable,
      rendersInlineEditor: true,
    }))
  )
);
