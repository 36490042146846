import { iso, Newtype, prism, getEq } from "newtype-ts";
import { pipe } from "fp-ts/function";
import convert from "convert-units";
import * as C from "io-ts/Codec";
import { isPositive } from "newtype-ts/lib/Positive";
import * as D from "io-ts/Decoder";
import * as En from "io-ts/Encoder";
import * as O from "fp-ts/Option";
import * as n from "fp-ts/number";

export interface SqM extends Newtype<{ readonly SqM: unique symbol }, number> {}

export interface SqFt
  extends Newtype<{ readonly SqFt: unique symbol }, number> {}

export const isoSqM = iso<SqM>();

export const prismSqM = prism<SqM>(isPositive);

export const isoSqFt = iso<SqFt>();

export const eqSqM = getEq(n.Eq);

export const SqMModel = C.make(
  pipe(
    D.number,
    D.parse((_) =>
      pipe(
        prismSqM.getOption(_),
        O.fold(
          () => D.failure(_, "Area in Sq Meters Must Be a Positive Number"),
          D.success
        )
      )
    )
  ),
  pipe(En.id<SqM>())
);

export const fromSqM = (a: SqM): SqFt =>
  pipe(convert(isoSqM.unwrap(a)).from("m2").to("ft2"), isoSqFt.wrap);

export const fromSqF = (a: SqFt): SqM =>
  pipe(convert(isoSqFt.unwrap(a)).from("ft2").to("m2"), isoSqM.wrap);
