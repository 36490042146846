// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import * as GeoJSON from "geojson";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import concaveman from "concaveman";
import { GeoCoord } from "lib/at-data/GeoCoord";

export const toCoord = (a: GeoJSON.Position) => ({
  x: a[0],
  y: a[1],
});

export interface IAffineTransform {
  a: number;
  b: number;
  tx: number;
  d: number;
  e: number;
  ty: number;
}
export const rad2deg = (rad: number) => rad * (180 / Math.PI);

export const concavem = (concavity?: number) => (pnts: number[][]) =>
  concaveman(pnts, concavity) as [number, number][];

export const polysOutline = (polys: Array<Array<GeoCoord>>) => {
  return pipe(polys, A.flatten, concavem(1));
};
