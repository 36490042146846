import { Box, debounce, Fab, styled } from "@mui/material";
import Icon, { AtArrowCaretDoubleRight } from "components/ui/Icon";
import React, { useCallback, useMemo } from "react";
import { vars } from "theme/helpers";
import theme from "theme/theme";
import ActivityDrawer from "./activityDrawer/ActivityDrawer";
import ActivityTimebarContainer from "views/authenticated/activity/page/components/navigator/ActivityTimebarContainer";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { ActivityMap } from "views/authenticated/activity/page/components/ActivityMap";
import ActivityControls from "views/authenticated/activity/page/components/activityControls/ActivityControls";
import { Asset } from "lib/at-data/assets/Asset";
import { filterAssetsState } from "controllers/UIStateCollector/utils";
import {
  ActivityPageUIStateL,
  AssetsFilterL,
  getActivitySetSelectedAssetLens,
  SelectedRangeL,
  SelectedSideItemL,
  SidebarOpenL,
  TimebarOpenL,
} from "controllers/UIStateCollector/lens/activityPage";
import * as L from "monocle-ts/Lens";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { getCampuses, getParentAssetOf } from "lib/at-data/assets/filters";
import { ActivityMapFloorDiagramControllerComponent } from "controllers/ActivityMapFloorDiagramController/ActivityMapFloorDiagramController";
import { ActivityMapDataControllerComponent } from "controllers/ActivityMapDataController/ActivityMapDataController";
import { assetsToShow } from "views/authenticated/activity/controller/assetsToShow";
import { isBuilding, isFloor } from "lib/at-data/assets/predicates";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { AssetPageTab } from "controllers/UIStateCollector/models/AssetsPageTab";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import {
  VisibleAssetsController,
  VisibleAssetsL,
} from "controllers/VisibleAssetsController/VisibleAssetsController";
import {
  SelectedAssetL,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { AssetsAnalyzeDataControllerComponent } from "controllers/AssetsAnalayzeDataController/AssetsAnalyzeDataController";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const ActivityContent: React.FC<{}> = (props) => {
  const uiStateDispatch = useHistoryControllerDispatch(UIStateController);

  const [sidebarOpen] = useController(
    UIStateController,
    flow(pipe(ActivityPageUIStateL, L.composeLens(SidebarOpenL)).get)
  );

  const [timebarOpen] = useController(
    UIStateController,
    flow(pipe(ActivityPageUIStateL, L.composeLens(TimebarOpenL)).get)
  );

  const [assetsFilter] = useController(
    UIStateController,
    flow(pipe(ActivityPageUIStateL, L.composeLens(AssetsFilterL)).get)
  );

  const [selectedRange] = useController(
    UIStateController,
    flow(pipe(ActivityPageUIStateL, L.composeLens(SelectedRangeL)).get)
  );

  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );

  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );
  const visualizedAssets = pipe(allAssets, assetsToShow(selectedAsset));

  const handleDrawerOpen = useCallback(() => {
    pipe(
      true,
      pipe(ActivityPageUIStateL, L.composeLens(SidebarOpenL)).set,
      uiStateDispatch
    );
  }, []);

  const handleDrawerClose = useCallback(() => {
    pipe(
      false,
      pipe(ActivityPageUIStateL, L.composeLens(SidebarOpenL)).set,
      uiStateDispatch
    );
  }, []);

  const handleTimebarOpen = useCallback(() => {
    pipe(
      true,
      pipe(ActivityPageUIStateL, L.composeLens(TimebarOpenL)).set,
      uiStateDispatch
    );
  }, []);

  const handleTimebarClose = useCallback(() => {
    pipe(
      false,
      pipe(ActivityPageUIStateL, L.composeLens(TimebarOpenL)).set,
      uiStateDispatch
    );
  }, []);

  const handleSelectAsset = useCallback(
    (assetO: O.Option<Asset>) => {
      pipe(
        flow(
          getActivitySetSelectedAssetLens(
            assetO,
            pipe(
              assetO,
              O.chain((a) => getParentAssetOf(isBuilding)(a)(allAssets))
            ),
            allAssets
          ),
          pipe(
            ActivityPageUIStateL,
            L.composeLens(SelectedSideItemL),
            L.modify((d) =>
              O.alt(() => O.some(AssetPageTab(ASSET_CONTEXT_TABS.ASSET)))(d)
            )
          )
        ),

        uiStateDispatch
      );
    },
    [uiStateDispatch, allAssets]
  );

  const handleSelectedTimestampChange = useCallback<(date: Date) => void>(
    () => {},
    // flow(O.some, SelectedTimestampL.set, uiStateDispatch),
    []
  );

  const handleSelectedRangeChange = useCallback(
    (range: SeriesRequest) => {
      pipe(
        range,
        pipe(ActivityPageUIStateL, L.composeLens(SelectedRangeL)).set,
        uiStateDispatch
      );
    },
    [uiStateDispatch]
  );

  const [filteredAssets] = useController(
    VisibleAssetsController,
    flow(VisibleAssetsL.get, filterAssetsState(assetsFilter))
  );

  return (
    <Box
      sx={{
        display: "flex",
        height: `calc(100vh - ${vars.navBar})`,
        position: "relative",
      }}
    >
      <ActivityDrawer
        variant="persistent"
        anchor="left"
        assets={filteredAssets}
        open={sidebarOpen}
        selectedAssets={pipe(selectedAsset, A.fromOption)}
        onSelectAsset={handleSelectAsset}
        onDrawerClose={handleDrawerClose}
      />
      <StyledMain open={sidebarOpen}>
        <ActivityTimebarContainer
          open={timebarOpen}
          selectedRange={selectedRange}
          selectedAsset={selectedAsset}
          handleTimebarOpen={handleTimebarOpen}
          handleTimebarClose={handleTimebarClose}
          onSelectedTimestampChange={handleSelectedTimestampChange}
          onSelectedRangeChange={handleSelectedRangeChange}
        />
        <ActivityControls leftOpen={sidebarOpen} bottomOpen={timebarOpen} />
      </StyledMain>

      <AssetsAnalyzeDataControllerComponent
        context={{ visibleAssets: visualizedAssets, series: selectedRange }}
      >
        <ActivityMapDataControllerComponent
          context={{
            visibleAssets: visualizedAssets,
          }}
        >
          <ActivityMapFloorDiagramControllerComponent
            context={{
              asset: pipe(
                selectedAsset,
                O.chain((a) => getParentAssetOf(isFloor)(a)(allAssets))
              ),
            }}
          >
            <ActivityMap />
          </ActivityMapFloorDiagramControllerComponent>
        </ActivityMapDataControllerComponent>
      </AssetsAnalyzeDataControllerComponent>
      <StyledFab aria-label="open drawer" onClick={handleDrawerOpen}>
        <Icon icon={AtArrowCaretDoubleRight} size="16px" />
      </StyledFab>
    </Box>
  );
};

export default ActivityContent;

const StyledMain = styled(Main)`
  display: flex;
  height: calc(100vh - ${vars.navBar});
  overflow: hidden;
  padding: ${theme.spacing(2)} ${theme.spacing(2)} 0;
  position: relative;
`;

const StyledFab = styled(Fab)`
  position: fixed;
  top: calc(${theme.spacing(2)} + ${vars.navBar});
  left: 0;
  width: 56px;
  height: 56px;
  border-radius: 0 50% 50% 0;
  transform: translateX(-8px);
  transition: ${theme.transitions.create(["background-color", "transform"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  })};

  &:hover {
    transform: translateX(0);
  }
`;
