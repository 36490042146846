import * as t from "io-ts";
import * as Eq from "fp-ts/Eq";
import * as s from "fp-ts/string";
import { AssetsMetricColumnTypeModel } from "controllers/AssetsTableController/models/AssetsMetricColumnTypes";
import * as C from "io-ts/Codec";
import { option } from "lib/codecs/option";
import * as O from "fp-ts/Option";

export const TableColumnModel = C.struct({
  name: C.string,
});

export type TableColumn = C.TypeOf<typeof TableColumnModel>;

export const TableColumnEq = Eq.struct<TableColumn>({
  name: s.Eq,
});
