import styled from "@emotion/styled/macro";
import { TreeItem, TreeView } from "@mui/lab";
import { alpha, Badge, Box, Button, Tooltip, Typography } from "@mui/material";
import Icon from "components/ui/Icon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  RiArrowDownSFill,
  RiArrowRightSFill,
  RiErrorWarningFill,
} from "react-icons/ri";
import palette from "theme/palette";
import { Assets, eqAssetsByUUIDOnly, toUUID } from "lib/at-data/assets/assets";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import { AssetIcons } from "controllers/UIStateCollector/utils";
import {
  ByTag,
  filterAssets,
  getAssetById,
  getParentLine,
} from "lib/at-data/assets/filters";
import { Asset } from "lib/at-data/assets/Asset";
import {
  AssetForest,
  AssetTree,
  forestSize,
  toForest,
} from "lib/at-data/assets/AssetForest";
import { ordAssetByName } from "lib/at-data/assets/models/AssetModel";
import * as Ord from "fp-ts/Ord";
import { useController } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { AssetsL } from "views/authenticated/root/controller/state";
import * as n from "fp-ts/number";
import { getMonoid, tuple } from "fp-ts/Ord";
import { concatAll } from "fp-ts/Monoid";
import { clog, noop } from "../../../../../../lib/util";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as b from "fp-ts/boolean";
import { not } from "fp-ts/Predicate";
import * as E from "fp-ts/Either";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import {
  ActivityPageUIStateL,
  AssetsFilterL,
} from "controllers/UIStateCollector/lens/activityPage";
import * as L from "monocle-ts/Lens";
import { FilterByNameL } from "controllers/UIStateCollector/lens/assetsPage";
import {
  validateBuilding,
  validateGeometry,
  validateLevel,
} from "lib/at-data/assets/validations/validateLevel";
import { validateAssetAnalytics } from "lib/at-data/assets/models/AnalyzableAsset";
import { AssetType, AssetTypes } from "lib/at-data/assets/AssetTypes";
import Highlighter from "react-highlight-words";
import { useStableMemo } from "fp-ts-react-stable-hooks";
import * as Eq from "fp-ts/Eq";
import { getId } from "lib/at-data/assets/getters";

interface IDrawerList {
  assets: Assets;
  selected: Array<Asset>;
  onSelectAsset: (asset: O.Option<Asset>) => void;
}

export const ordByLevel = pipe(
  O.getOrd(n.Ord),
  Ord.contramap((a: Asset) =>
    pipe(
      a.floorMeta,
      O.map((_) => _.order)
    )
  )
);

export const ordByNavOrder = concatAll(getMonoid<Asset>())([
  ordByLevel,
  ordAssetByName,
]);

export const getAssetValidator = (assetType: AssetType) => {
  switch (assetType) {
    case AssetTypes.ZONE:
      return flow(
        validateAssetAnalytics,
        E.chain((a) =>
          pipe(
            a.geometry,
            validateGeometry,
            E.map(() => a)
          )
        )
      );
    case AssetTypes.FLOOR:
      return validateLevel;
    case AssetTypes.BUILDING:
      return validateBuilding;
    default:
      return (a: Asset) => E.right(a);
  }
};

export const IncompleteAssetIndicator: React.FC<{ asset: Asset }> = (props) => {
  return pipe(
    props.asset,
    getAssetValidator(props.asset.type),
    E.foldW(
      (e) => (
        <Icon
          icon={RiErrorWarningFill}
          size={"14px"}
          color={palette.status.warning.default}
        />
      ),
      () => null
    )
  );
};

export const HighlightingLabel: React.FC<{
  label: string;
}> = (props) => {
  const [searchText] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(AssetsFilterL)).get
  );

  const subArray =
    searchText.byName.length === 0 ? [] : searchText.byName.split(" ");

  return (
    <Highlighter
      highlightClassName="highlight"
      searchWords={subArray}
      autoEscape={true}
      textToHighlight={props.label}
      highlightStyle={{ backgroundColor: palette.primary[300] }}
    />
  );
};

const renderAssetForest = (nodes: AssetForest) =>
  pipe(
    nodes,
    A.sort(
      pipe(
        ordByNavOrder,
        Ord.contramap<Asset, AssetTree>((_) => _.value)
      )
    ),
    A.map(({ value, forest }) => (
      <StyledTreeItem
        key={value.id}
        nodeId={value.id}
        label={
          <span>
            <Tooltip
              disableFocusListener
              enterDelay={1000}
              enterNextDelay={1000}
              disableHoverListener={false}
              title={value.name}
            >
              <span>
                <HighlightingLabel label={value.name} />
              </span>
            </Tooltip>
          </span>
        }
        // collapseIcon={<Icon icon={AssetIcons[value.type]} />}
        icon={<Icon icon={AssetIcons[value.type]} />}
        TransitionProps={{ timeout: 0 }}
      >
        {renderAssetForest(forest)}
      </StyledTreeItem>
    ))
  );

export const shouldBeExpanded =
  (selectedNodes: Array<Asset>, selectedNodeId: UUID) =>
  (expandedNodes: Array<UUID>) =>
    pipe(expandedNodes, A.elem(eqUUID)(selectedNodeId)) &&
    pipe(selectedNodes, A.map(getId), A.elem(eqUUID)(selectedNodeId));

const ActivityDrawerList: React.FC<IDrawerList> = (props) => {
  const [allAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2)
  );

  const [expandedNodes, setExpandedNodes] = useState<Array<UUID>>([]);
  useEffect(
    () =>
      pipe(
        props.selected,
        A.chain((selectedAssets) =>
          pipe(
            allAssets,
            filterAssets(ByTag("Managed Assets")),
            getParentLine(selectedAssets)
          )
        ),
        setExpandedNodes
      ),
    [props.selected]
  );

  const handleSelect = useCallback(
    (event: React.SyntheticEvent, nodeIds: string[] | string) =>
      pipe(
        nodeIds,
        UUID.decode,
        E.fold(noop, (selectedNodeId) =>
          pipe(
            expandedNodes,
            shouldBeExpanded(props.selected, selectedNodeId),
            b.fold(
              () => {
                pipe(
                  props.assets,
                  filterAssets(ByTag("Managed Assets")),
                  getAssetById(selectedNodeId),
                  props.onSelectAsset
                );
              },
              () => {
                pipe(
                  expandedNodes,
                  A.filter(not((_) => eqUUID.equals(_, selectedNodeId))),
                  setExpandedNodes
                );
              }
            )
          )
        )
      ),
    [props.selected]
  );

  const assetsForest = pipe(
    props.assets,
    filterAssets(ByTag("Managed Assets")),
    toForest
  );

  return (
    <NavList>
      <Typography
        variant="h4"
        color={palette.neutral[100]}
        sx={{ mb: 1.5 }}
        px={2}
      >
        All Assets{" "}
        <Typography variant="h4" component="span" color="textTertiary">
          {pipe(assetsForest, forestSize)}
        </Typography>
      </Typography>
      {/*<Button onClick={handleExpandClick}>*/}
      {/*  {expanded.length === 0 ? "Expand all" : "Collapse all"}*/}
      {/*</Button>*/}
      <TreeView
        aria-label="activity asset list"
        defaultCollapseIcon={<RiArrowDownSFill />}
        defaultExpandIcon={<RiArrowRightSFill />}
        expanded={expandedNodes}
        selected={pipe(props.selected, A.map(getId))}
        // onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        // sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
      >
        {renderAssetForest(assetsForest)}
      </TreeView>
    </NavList>
  );
};

export default ActivityDrawerList;

const highlight = styled(Typography)`
  color: ${palette.primary[300]};
`;

const StyledButton = styled(Button)`
  color: ${palette.primary[300]};
`;

const StyledTreeItem = styled(TreeItem)`
  color: white;
  padding: 0;
  width: 100%;

  & .MuiTreeItem-label {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .MuiTreeItem-content {
    padding: 8px 16px;
    transition: all 0;

    &:hover,
    &:focus,
    &.Mui-focused {
      background-color: ${alpha(palette.neutral[600], 0.5)};
      box-shadow: -100px 0 0 ${alpha(palette.neutral[600], 0.5)};
    }

    &.Mui-selected {
      background-color: ${palette.neutral[600]};
      box-shadow: -100px 0 0 ${palette.neutral[600]};
    }

    &.Mui-selected.Mui-focused,
    &.Mui-selected:hover {
      background-color: ${alpha(palette.neutral[600], 0.75)};
      box-shadow: -100px 0 0 ${alpha(palette.neutral[600], 0.75)};
    }
  }
`;

const NavList = styled(Box)`
  overflow-y: auto;
`;
