import { AppContext } from "contexts/AppContext";
import * as E from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import { handleResponse } from "lib/at-api/reports/reportMetadata";
import { UUID } from "lib/at-data/UUID";

export const AT_TENANT_KEY = "atOrgId";
export const updateAuth0OrganizationTenantId = (
  managementToken: string,
  tenantId: UUID
) =>
  pipe(
    RTE.ask<AppContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/organizations/${ctx.appContext.authOrganization}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${managementToken}`,
              },
              body: JSON.stringify({ metadata: { [AT_TENANT_KEY]: tenantId } }),
            }
          ).then(handleResponse("Update Organization MetaData")),
        E.toError
      )
    )
  );
