import * as t from "io-ts";
import { flow, pipe } from "fp-ts/function";
import { getItem, setItem, removeItem } from "fp-ts-local-storage";
import * as I from "fp-ts/IO";
import * as Eq from "fp-ts/Eq";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";
import * as b from "fp-ts/boolean";
import * as L from "monocle-ts/Lens";
import * as C from "io-ts/Codec";
import * as D from "io-ts/Decoder";
import { optionFromNullable } from "lib/codecs/optionFromNullable";

export const SETTINGS_STORAGE_KEY = "dashboardSettings";

export const DashboardSettingsModel = C.struct({
  authOrganization: optionFromNullable(C.string),
});
export type DashboardSettings = C.TypeOf<typeof DashboardSettingsModel>;

export const DashboardSettingsEq = Eq.struct<DashboardSettings>({
  authOrganization: O.getEq(s.Eq),
});

const DashboardSettingsL = L.id<DashboardSettings>();

export const AuthOrganizationL = pipe(
  DashboardSettingsL,
  L.prop("authOrganization")
);

export const loadDashboardSettingsFromLocalStorageIO = (settingsKey: string) =>
  pipe(
    getItem(settingsKey),
    I.map(E.fromOption(() => Error("Settings Not Found")))
  );

export const saveDashboardSettingsToLocalStorageIO = (
  settings: DashboardSettings
) =>
  pipe(settings, DashboardSettingsModel.encode, JSON.stringify, (r) =>
    setItem(SETTINGS_STORAGE_KEY, r)
  );

export const clearDashboardSettingsIO = removeItem(SETTINGS_STORAGE_KEY);

export const decodeDashboardSettings =
  (model: D.Decoder<object, DashboardSettings>) => (settings: string) =>
    pipe(
      settings,
      JSON.parse,
      model.decode,
      E.mapLeft((f) => Error("Settings Invalid"))
    );
