import { pipe } from "fp-ts/function";
import { reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";
import * as E from "fp-ts/Either";
import {
  DefaultReportDescriptions,
  ReportType,
} from "views/authenticated/reports2/componenets/reportsTable/ReportType";
import { toISOString } from "fp-ts-std/Date";
import * as Eq from "fp-ts/Eq";
import * as s from "fp-ts/string";
import { CreateReportRequest } from "lib/at-api/reports/reportMetadata";
import * as ROR from "fp-ts/ReadonlyRecord";
import { makeMemo } from "lib/at-react/collector";

export type ReportUploadState = {
  content: E.Either<Error, string>;
  contentType: E.Either<Error, string>;
  title: E.Either<Error, string>;
  description: E.Either<Error, string>;
  department: E.Either<Error, string>;
  reportType: E.Either<Error, string>;
  reportDate: E.Either<Error, string>;
};
export const toReportRequest = (
  state: ReportUploadState
): E.Either<Error, CreateReportRequest> =>
  pipe(state, ROR.sequence(E.Applicative));

const DEFAULT_REPORT_TYPE = ReportType.RVO_REPORT;

export const initialState: ReportUploadState = {
  content: E.left(new Error("No File Selected")),
  contentType: E.left(new Error("No content type")),
  title: E.right("New report"),
  description: E.right(DefaultReportDescriptions[DEFAULT_REPORT_TYPE]),
  department: E.right("Student Union"),
  reportType: E.right(DEFAULT_REPORT_TYPE),
  reportDate: E.right(pipe(new Date(), toISOString)),
};
export const FileContentL = pipe(L.id<ReportUploadState>(), L.prop("content"));
export const ReportTitleL = pipe(L.id<ReportUploadState>(), L.prop("title"));
export const ReportDepartmentL = pipe(
  L.id<ReportUploadState>(),
  L.prop("department")
);
export const ReportDateL = pipe(
  L.id<ReportUploadState>(),
  L.prop("reportDate")
);
export const ReportTypeL = pipe(
  L.id<ReportUploadState>(),
  L.prop("reportType")
);
export const ReportDescriptionL = pipe(
  L.id<ReportUploadState>(),
  L.prop("description")
);
export const FileContentTypeL = pipe(
  L.id<ReportUploadState>(),
  L.prop("contentType")
);
export const fieldEq = E.getEq(Eq.eqStrict, s.Eq);

export const ReportUploadController = pipe(
  reactState<ReportUploadState>(
    initialState,
    Eq.struct({
      content: fieldEq,
      title: fieldEq,
      description: fieldEq,
      reportDate: fieldEq,
      reportType: fieldEq,
      department: fieldEq,
      contentType: fieldEq,
    })
  ),
  makeMemo(Eq.struct({}))
);
