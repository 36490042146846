import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import palette from "theme/palette";
import Icon, { AtBuilding } from "components/ui/Icon";
import AssetSelectorMenu from "./AssetSelectorMenu";
import AssetSelectorMenuB from "./AssetSelectorMenuB";
import AssetExperimentMenu from "./AssetExperimentMenu";

const CompareHeatmap: React.FC<{}> = () => {
  return (
    <>
      <SectionHeader>Scenario Analysis</SectionHeader>
      <Heatmap>
        <Pane>
          <MenuContainer>
            <AssetSelectorMenu />
          </MenuContainer>
          <img
            src={"/images/su1.png"}
            alt={"timebar placeholder"}
            width={"620px"}
            style={{ marginTop: "32px" }}
          />
        </Pane>
        <Pane>
          <MenuContainer>
            <AssetSelectorMenuB />
            <AssetExperimentMenu />
          </MenuContainer>
          <img
            src={"/images/su2.png"}
            alt={"timebar placeholder"}
            width={"620px"}
            style={{ marginTop: "32px" }}
          />
        </Pane>
      </Heatmap>
    </>
  );
};

export default CompareHeatmap;
const SectionHeader = styled(Typography)`
  background-color: ${palette.neutral[650]};
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 16px 32px;
`;
const MenuContainer = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
`;
const Heatmap = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;

const Pane = styled(Stack)`
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  border-bottom: 1px solid hsla(0, 100%, 100%, 0.15);
  color: #ffffff;
  padding: 32px;
  &:first-of-type {
    border-right: 1px solid hsla(0, 0%, 0%, 0.75);
  }
  &:last-of-type {
    border-left: 1px solid hsla(0, 100%, 100%, 0.15);
  }
`;
