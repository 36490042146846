import React, { Context, Dispatch, SetStateAction } from "react";

export type LegacyControllerState<STATE, REDUCEDSTATE> = [
  REDUCEDSTATE,
  Dispatch<SetStateAction<STATE>>
];

export const contextFromDispatch: <STATE, RSTATE = STATE>(
  state: STATE,
  dispatch: Dispatch<SetStateAction<STATE>>
) => React.Context<LegacyControllerState<STATE, STATE>> = (state, dispatch) =>
  React.createContext([state, dispatch]);

/**
 * @deprecated
 */
export function useControllerLegacy<STATE, RSTATE>(
  context: Context<LegacyControllerState<STATE, STATE>>,
  reducerFn: (state: STATE) => RSTATE
): LegacyControllerState<STATE, RSTATE> {
  const [state, setState] = React.useContext(context);
  return [reducerFn(state), setState];
}

export function useControllerDispatch<STATE, RSTATE>(
  context: Context<LegacyControllerState<STATE, STATE>>
): Dispatch<SetStateAction<STATE>> {
  return useControllerLegacy(context, (s) => s)[1];
}
