import {
  AssetsTableState,
  defaultTableColumn,
  tableColumn,
  TableDataViews,
} from "controllers/AssetsTableController/state";
import { AssetsCompareColumnTypes } from "controllers/AssetsTableController/models/AssetCompareColumn";
import { AssetsMetricColumnTypes } from "controllers/AssetsTableController/models/AssetsMetricColumnTypes";
import { TableColumnTypes } from "controllers/AssetsTableController/tableColumnTypes";
import * as O from "fp-ts/Option";
import { EDIT_FEATURE } from "constants/featureFlags";

export const initialState: AssetsTableState = {
  columns: [
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_NAME,
      name: "asset",
    }),
    tableColumn(
      {
        _type: TableColumnTypes.METRIC,
        type: AssetsMetricColumnTypes.ASSET_SHORT_CODE,
        name: "assetShortCode",
      },
      new Set([TableDataViews.CSV])
    ),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_MEAN_UTILIZATION,
      name: "assetMeanUtilization",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_OCCUPANCY_CHART,
      name: "assetOccupancyChart",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_MEAN_OCCUPANCY,
      name: "assetMeanOccupancy",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_PEAK_UTILIZATION,
      name: "assetPeakUtilization",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_PEAK_OCCUPANCY,
      name: "assetPeakOccupancy",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_AREA,
      name: "assetArea",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_PRICE_PER_AREA,
      name: "assetPricePerArea",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.COMPARE,
      type: AssetsCompareColumnTypes.ASSET_UNDERUTILIZATION_COST,
      name: "assetUnderutilizationCost",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_TAGS,
      name: "assetTags",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_TARGET_UTILIZATION,
      name: "assetTargetUtilization",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_CAPACITY,
      name: "assetCapacity",
    }),
    defaultTableColumn({
      _type: TableColumnTypes.METRIC,
      type: AssetsMetricColumnTypes.ASSET_COST,
      name: "cost",
    }),
    tableColumn(
      {
        _type: TableColumnTypes.METRIC,
        type: AssetsMetricColumnTypes.ASSET_PARENT_BUILDING,
        name: "assetParentBuilding",
      },
      new Set([TableDataViews.CSV])
    ),
    tableColumn(
      {
        _type: TableColumnTypes.METRIC,
        type: AssetsMetricColumnTypes.ASSET_LEGACY_ID,
        name: "assetLegacyId",
      },
      new Set([TableDataViews.CSV])
    ),

    // {
    //   _type: "formula",
    //   type: AssetsFormulaColumnTypes.COVERAGE,
    //   name: "coverage",
    //   seriesFilter: {
    //     whenOccupied: {
    //       enabled: true,
    //       not: false,
    //     },
    //     whenBooked: {
    //       enabled: false,
    //       not: false,
    //     },
    //     withinOperationHours: {
    //       enabled: true,
    //       not: false,
    //     },
    //   },
    // },
    // {
    //   _type: "formula",
    //   type: AssetsFormulaColumnTypes.DENSITY,
    //   name: "density",
    //   seriesFilter: {
    //     whenOccupied: {
    //       enabled: false,
    //       not: false,
    //     },
    //     whenBooked: {
    //       enabled: false,
    //       not: false,
    //     },
    //     withinOperationHours: {
    //       enabled: true,
    //       not: false,
    //     },
    //   },
    // },
  ],
};
