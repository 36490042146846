import { Assets, eqAssets } from "lib/at-data/assets/assets";
import { pipe } from "fp-ts/function";
import * as AD2 from "lib/at-data/AsyncData2";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import {
  composeController,
  defineCalculatedContext,
} from "lib/at-react/defineController";
import * as Eq from "fp-ts/Eq";
import {
  RootAssetsContext,
  RootState,
} from "views/authenticated/root/controller/state";
import {
  FeatureFlagController,
  FeatureFlagEq,
} from "controllers/FeatureFlagController/FeatureFlagController";
import { FeatureFlagControllerState } from "controllers/FeatureFlagController/FeatureFlagControllerState";
import { ByTag, filterAssets } from "lib/at-data/assets/filters";
import { hasFeatureFlag } from "controllers/AppController/IsFeatureFlagAvailable";
import { EDIT_FEATURE } from "constants/featureFlags";
import * as b from "fp-ts/boolean";
import * as L from "monocle-ts/Lens";
import { AssetsContext } from "contexts/AssetsContext";

export type VisibleAssetsContext = {
  visibleAssets: Assets;
};

export const VisibleAssetsL = pipe(
  L.id<VisibleAssetsContext>(),
  L.prop("visibleAssets")
);

export const filterBasedOnEditFeature =
  (hasFlag: boolean) => (assets: Assets) =>
    pipe(
      hasFlag,
      b.fold(
        () => pipe(assets, filterAssets(ByTag("Managed Assets"))),
        () => assets
      )
    );

export const [component, controller] = pipe(
  AssetsController,
  composeController(
    pipe(
      FeatureFlagController,
      composeController(
        defineCalculatedContext(
          { visibleAssets: [] },
          Eq.struct<RootAssetsContext & FeatureFlagControllerState>({
            assets: AD2.getEq(eqAssets),
            featureFlags: FeatureFlagEq,
          }),
          ({ assets, featureFlags }): VisibleAssetsContext => ({
            visibleAssets: pipe(
              assets,
              fromAD2,
              filterBasedOnEditFeature(
                pipe(featureFlags, hasFeatureFlag(EDIT_FEATURE))
              )
            ),
          })
        )
      )
    )
  )
);

export const VisibleAssetsController = controller;
export const VisibleAssetsControllerComponent = component;
