import React from "react";
import { Spaces } from "./Spaces";
import { DataSources } from "./DataSources";
import { Integrations } from "./Integrations";
import { UsersRoles } from "./UsersRoles";
import { Content, Main, Page } from "./components/styles/Styles";
import { DeployHeader } from "./components/DeployHeader";
import { DeployTabBar } from "./components/DeployTabBar";

export const DeployPage: React.FC<{}> = (props) => {
  return (
    <Page>
      <Main>
        <DeployHeader />
        <DeployTabBar />
        <Content>
          <Spaces />
          {/*<DataSources />*/}
          {/*<Integrations />*/}
          {/*<UsersRoles />*/}
        </Content>
      </Main>
    </Page>
  );
};

export default DeployPage;
