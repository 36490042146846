import { Lens } from "monocle-ts";
import { AssetsPageUIState } from "controllers/UIStateCollector/models/AssetsPageUIStateModel";
import {
  AssetFilter,
  FilterByIds,
  FilterByIdType,
  FilterByParentId,
} from "controllers/UIStateCollector/models/assetFilterModel";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { DEFAULT_FILTERS } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import * as A from "fp-ts/Array";
import { PathL } from "controllers/UIStateCollector/models/UIStateModel";
import { UIStateL } from "controllers/UIStateCollector/lens/UIStateL";
import { AssetsPageL } from "controllers/UIStateCollector/lens/assetsPageL";
import { AssetTypes } from "../../../lib/at-data/assets/AssetTypes";
import { getAssetType, getId } from "lib/at-data/assets/getters";

export const SelectedSideItemL =
  Lens.fromProp<AssetsPageUIState>()("selectedSideItem");

export const SelectedRangeL = pipe(
  L.id<AssetsPageUIState>(),
  L.prop("selectedRange")
);

export const AssetsPageSelectedAssetIdL = pipe(
  L.id<AssetsPageUIState>(),
  L.prop("selectedAssetIds")
);

export const AssetsFilterL = pipe(
  L.id<AssetsPageUIState>(),
  L.prop("assetsFilter")
);
export const FilterByTypeL = Lens.fromProp<AssetFilter>()("byTypes");
export const FilterByTagL = Lens.fromProp<AssetFilter>()("byTags");
export const FilterByNameL = Lens.fromProp<AssetFilter>()("byName");
export const FilterByIdsL = Lens.fromProp<AssetFilter>()("byIds");
export const AssetsPageUIStateL = pipe(UIStateL, L.composeLens(AssetsPageL));

export const navigateToAnalyze = ({
  selectedAsset,
  selectedRange,
}: {
  selectedAsset: O.Option<Asset>;
  selectedRange: SeriesRequest;
}) =>
  flow(
    PathL.set("/assets"),
    pipe(AssetsPageUIStateL, L.composeLens(AssetsPageSelectedAssetIdL)).set(
      pipe(selectedAsset, O.map(getId), A.fromOption)
    ),
    // we need to reset filters to ensure our asset appears
    pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).set({
      ...DEFAULT_FILTERS,
      byIds: pipe(selectedAsset, O.map(flow(getId, A.of, FilterByIds))),
      byTypes: pipe(
        selectedAsset,
        O.map(flow(getAssetType, A.of)),
        O.getOrElse(() => DEFAULT_FILTERS.byTypes)
      ),
    }),
    pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).set(selectedRange)
  );

export const navigateToAnalyzeChildren = ({
  selectedAsset,
  selectedRange,
}: {
  selectedAsset: O.Option<Asset>; //needs to be list
  selectedRange: SeriesRequest;
}) =>
  flow(
    PathL.set("/assets"),
    pipe(
      AssetsPageUIStateL,
      L.composeLens(AssetsFilterL),
      L.composeLens(FilterByIdsL)
    ).set(pipe(selectedAsset, O.map(flow(getId, FilterByParentId)))),
    pipe(
      AssetsPageUIStateL,
      L.composeLens(AssetsFilterL),
      L.composeLens(FilterByTypeL)
    ).set([AssetTypes.ZONE, AssetTypes.BUILDING, AssetTypes.FLOOR]),
    pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).set(selectedRange)
  );
