import {
  AssetTableColumn,
  TableColumnInfo,
} from "controllers/AssetsTableController/state";
import { pipe } from "fp-ts/function";
import { match, matchI } from "ts-adt";
import { AssetsMetricColumn } from "controllers/AssetsTableController/models/AssetsMetricColumn";
import { AssetsNameColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetsNameColumn";
import { AssetAreaColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetAreaColumn";
import {
  AssetsFormulaColumn,
  AssetsFormulaColumnTypes,
} from "controllers/AssetsTableController/models/AssetsFormulaColumn";
import { UtilizationColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
// import { CoverageColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/formula/CoverageColumn";
import { PricePerAreaUnitColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/PricePerAreaUnitColumn";
// import { DensityColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/formula/DensityColumn";
import { AssetCapacityColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetCapacityColumn";
import { AssetTagsColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetTagsColumn";
import { AssetTargetUtilizationColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetTargetUtilizationColumn";
import { CostColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/CostColumn";
import {
  AssetsCompareColumn,
  AssetsCompareColumnTypes,
} from "controllers/AssetsTableController/models/AssetCompareColumn";
import { UnderutilizationCostColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/compare/UnderutilizationCostColumn";
import { AssetOccupancyChartColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetOccupancyChartColumn";
import { AssetMeanOccupancyColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetMeanOccupancyColumn";
import { AssetMeanUtilizationColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetMeanUtilizationColumn";
import { AssetPeakOccupancyColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetPeakOccupancyColumn";
import { AssetPeakUtilizationColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetPeakUtilizationColumn";
import { AssetsMetricColumnTypes } from "controllers/AssetsTableController/models/AssetsMetricColumnTypes";
import { AssetLegacyIdColumn } from "../columns/metric/AssetLegacyIdColumn";
import { AssetParentBuildingColumn } from "../columns/metric/AssetParentBuildingColumn";
import { AssetShortCodeColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetShortCodeColumn";

export const assetMetricColumns = {
  [AssetsMetricColumnTypes.ASSET_NAME]: AssetsNameColumn,
  [AssetsMetricColumnTypes.ASSET_AREA]: AssetAreaColumn,
  [AssetsMetricColumnTypes.ASSET_PRICE_PER_AREA]: PricePerAreaUnitColumn,
  [AssetsMetricColumnTypes.ASSET_CAPACITY]: AssetCapacityColumn,
  [AssetsMetricColumnTypes.ASSET_TAGS]: AssetTagsColumn,
  [AssetsMetricColumnTypes.ASSET_TARGET_UTILIZATION]:
    AssetTargetUtilizationColumn,
  [AssetsMetricColumnTypes.ASSET_COST]: CostColumn,
  [AssetsMetricColumnTypes.ASSET_OCCUPANCY_CHART]: AssetOccupancyChartColumn,
  [AssetsMetricColumnTypes.ASSET_MEAN_OCCUPANCY]: AssetMeanOccupancyColumn,
  [AssetsMetricColumnTypes.ASSET_PEAK_OCCUPANCY]: AssetPeakOccupancyColumn,
  [AssetsMetricColumnTypes.ASSET_MEAN_UTILIZATION]: AssetMeanUtilizationColumn,
  [AssetsMetricColumnTypes.ASSET_PEAK_UTILIZATION]: AssetPeakUtilizationColumn,
  [AssetsMetricColumnTypes.ASSET_PARENT_BUILDING]: AssetParentBuildingColumn,
  [AssetsMetricColumnTypes.ASSET_LEGACY_ID]: AssetLegacyIdColumn,
  [AssetsMetricColumnTypes.ASSET_SHORT_CODE]: AssetShortCodeColumn,
};

export const assetFormulaColumns = {
  [AssetsFormulaColumnTypes.UTILIZATION]: UtilizationColumn,
  // [AssetsFormulaColumnTypes.COVERAGE]: CoverageColumn,
  // [AssetsFormulaColumnTypes.DENSITY]: DensityColumn,
};

export const assetCompareColumns = {
  [AssetsCompareColumnTypes.ASSET_UNDERUTILIZATION_COST]:
    UnderutilizationCostColumn,
};

export const createAssetMetricColumn = (amc: AssetsMetricColumn) =>
  assetMetricColumns[amc.type];

export const createAssetFormulaColumn = (afc: AssetsFormulaColumn) =>
  assetFormulaColumns[afc.type](afc);

export const createAssetCompareColumn = (acc: AssetsCompareColumn) =>
  assetCompareColumns[acc.type];

export const createColumn = (column: TableColumnInfo) =>
  pipe(
    column.column,
    match({
      metric: createAssetMetricColumn,
      formula: createAssetFormulaColumn,
      compare: createAssetCompareColumn,
    })
  );
