import { LngLatBounds } from "mapbox-gl";
import { BBox } from "lib/at-data/BBox";
import mixpanel from "mixpanel-browser";

export const removeTrailingSlash = (pathname: string = "") =>
  pathname.endsWith("/") && pathname.length > 1
    ? pathname.slice(0, -1)
    : pathname;

export const removeStartSlash = (pathname: string = "") =>
  pathname.startsWith("/") && pathname.length > 1
    ? pathname.slice(1, pathname.length)
    : pathname;

export const rootPath = (pathname: string) => {
  return pathname.split("/")[1] || "";
};

/**
 * This is only needed because TS needs a little  static typing help
 * @param key
 * @param value
 * @constructor
 */
export const Tuple = <K, V>(key: K, value: V) => [key, value] as [K, V];
export const clog =
  (msg: string) =>
  <A>(v: A) => {
    console.log(`${msg}: `, v);
    return v;
  };

export const track =
  (event: string, params?: Object) =>
  <A>(v: A) => {
    mixpanel.track(event, params);
    return v;
  };

export const noop = () => {};

export const toBBox = (bounds: LngLatBounds): BBox =>
  bounds.toArray().flat(1) as [number, number, number, number];
