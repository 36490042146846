import { Stack, styled } from "@mui/material";
import React from "react";
import {
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import theme from "theme/theme";
import * as CD from "lib/at-data/ChartData";
import palette from "theme/palette";
import { mean, NumberSeries } from "lib/at-data/NumberSeries";
import { pipe } from "fp-ts/function";
import { formatNumber, UINumber } from "lib/formatters/formatNumber";
import { dataLength } from "lib/at-data/DataSeries";
import * as O from "fp-ts/Option";
import * as AD from "lib/at-data/AsyncData";

const AssetsTableCellOccupancy: React.FC<{
  series: NumberSeries;
  // meanOccupancy: O.Option<number>;
}> = ({ series }) => {
  //was series, meanOccupancy
  // TODO: can we use date-fns here?
  const chartData = pipe(
    series,
    CD.fromNumberSeries(() => 0, "value")
  );
  const date = new Date();
  date.setHours(date.getHours() - 5);
  date.setMinutes(30);
  date.setSeconds(0);
  date.setMilliseconds(0);

  const liveTimestamp = date.getTime();
  const chartDataLength = chartData.length;
  const liveTimestampIndex = chartData.findIndex(
    (slice) => slice.timestamp === liveTimestamp
  );
  const hasLiveTimestamp = chartDataLength > 1 && liveTimestampIndex > -1;
  const percentage = hasLiveTimestamp
    ? 100 -
      ((chartDataLength - liveTimestampIndex - 1) / (chartDataLength - 1)) * 100
    : 100;

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <GraphBox>
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient
                id="table-cell-occupancy-gradient"
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
              >
                <stop offset="0%" stopColor={palette.primary[500]} />
                <stop
                  offset={`${percentage}%`}
                  stopColor={palette.primary[500]}
                />
                <stop
                  offset={`${percentage}%`}
                  stopColor={palette.primary[200]}
                />
                <stop offset="100%" stopColor={palette.primary[200]} />
              </linearGradient>
            </defs>

            <Line
              dot={false}
              strokeWidth="1.2"
              isAnimationActive={false}
              type="monotone"
              dataKey="value"
              stroke="url(#table-cell-occupancy-gradient)"
            />
          </LineChart>
        </ResponsiveContainer>
      </GraphBox>
    </Stack>
  );
};

export default AssetsTableCellOccupancy;

const GraphBox = styled("div")`
  width: 100px;
  height: 24px;
  margin-right: ${theme.spacing(1)};
`;
