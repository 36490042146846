import React from "react";
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled,
} from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import Icon, { AtChevronDown, AtMetrics } from "components/ui/Icon";

const CompareKeyMetrics: React.FC<{}> = () => {
  return (
    <Accordion defaultExpanded={true}>
      <SectionHeader
        expandIcon={
          <Icon
            icon={AtChevronDown}
            size="24px"
            color={`${palette.neutral[100]}`}
          />
        }
        aria-controls="panel-content-kpi"
        id="panel-header-kpi"
      >
        <Icon
          icon={AtMetrics}
          size="24px"
          color={`${palette.neutral[100]}`}
          sx={{ marginRight: "12px" }}
        />
        Key Performance Indicators
      </SectionHeader>
      <SectionDetails>
        <Bookings>
          <Pane>
            <MetricKpi>
              <Heading>Utilization</Heading>
              <Metric>23%</Metric>
              <Hint>Average</Hint>
            </MetricKpi>
            <MetricKpi>
              <Heading>Underutilization Cost</Heading>
              <Metric>$7.2M</Metric>
              <Hint>Annually at $22 per sq ft</Hint>
            </MetricKpi>
            <MetricKpi>
              <Heading>Occupancy</Heading>
              <KpiContainer direction="row">
                <Stack>
                  <Metric>76</Metric>
                  <Hint>Peak</Hint>
                </Stack>
                <Stack>
                  <Metric>42</Metric>
                  <Hint>Average</Hint>
                </Stack>
              </KpiContainer>
            </MetricKpi>
          </Pane>
          <Pane>
            <MetricKpi>
              <Heading>Utilization</Heading>
              <Metric>67%</Metric>
              <Hint>Average</Hint>
            </MetricKpi>
            <MetricKpi>
              <Heading>Underutilization Cost</Heading>
              <Metric>$3.3M</Metric>
              <Hint>Annually at $22 per sq ft</Hint>
            </MetricKpi>
            <MetricKpi>
              <Heading>Occupancy</Heading>
              <KpiContainer direction="row">
                <Stack>
                  <Metric>226</Metric>
                  <Hint>Peak</Hint>
                </Stack>
                <Stack>
                  <Metric>112</Metric>
                  <Hint>Average</Hint>
                </Stack>
              </KpiContainer>
            </MetricKpi>
          </Pane>
        </Bookings>
      </SectionDetails>
    </Accordion>
  );
};

export default CompareKeyMetrics;

const SectionHeader = styled(AccordionSummary)`
  background-color: ${palette.neutral[650]};
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 0 32px;
  &:hover {
    background-color: ${palette.neutral[600]};
  }
`;
const SectionDetails = styled(AccordionDetails)`
  background-color: #46495e;
  padding: 0;
`;
const Bookings = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;

const Pane = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  background-color: #323548;
  color: #ffffff;
  padding: 32px;
  &:first-of-type {
    border-right: 1px solid hsla(0, 0%, 0%, 0.75);
  }
  &:last-of-type {
    border-left: 1px solid hsla(0, 100%, 100%, 0.15);
  }
`;
const MetricKpi = styled(Stack)`
  width: auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin: 0;
`;

const KpiContainer = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0 32px;
`;
const Heading = styled(Typography)`
  font-size: 16px;
`;
const Metric = styled(Typography)`
  font-size: 36px;
`;
const Hint = styled(Typography)`
  color: #dddddd;
  font-size: 11px;
  text-transform: uppercase;
`;
