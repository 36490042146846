import { UUID } from "lib/at-data/UUID";
import React, { useCallback, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { flow, pipe } from "fp-ts/function";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import {
  AssetsFilterL,
  AssetsPageSelectedAssetIdL,
  AssetsPageUIStateL,
  FilterByIdsL,
  FilterByNameL,
  FilterByTypeL,
} from "controllers/UIStateCollector/lens/assetsPage";
import * as L from "monocle-ts/Lens";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import * as O from "fp-ts/Option";
import {
  getAssetById,
  getCampuses,
  getParentLine,
  getParentLineById,
} from "lib/at-data/assets/filters";
import * as A from "fp-ts/Array";
import { getId, getName } from "lib/at-data/assets/getters";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { FilterByParentId } from "controllers/UIStateCollector/models/assetFilterModel";
import { AssetsL } from "views/authenticated/root/controller/state";
import palette from "theme/palette";

export const HeaderBreadcrumbs: React.FC<{ assetId: UUID }> = (props) => {
  const allAssets = useCollectorOld(AssetsController, L.prop("assets"));

  const parentLine = pipe(
    props.assetId,
    getParentLineById
  )(pipe(allAssets, fromAD2));

  const dispatch = useHistoryControllerDispatch(UIStateController);

  const handleClickBreadCrumb = useCallback((asset: Asset) => {
    dispatch(
      flow(
        pipe(
          asset,
          getId,
          FilterByParentId,
          O.some,
          pipe(
            AssetsPageUIStateL,
            L.composeLens(AssetsFilterL),
            L.composeLens(FilterByIdsL)
          ).set
        ),
        pipe(AssetsPageUIStateL, L.composeLens(AssetsPageSelectedAssetIdL)).set(
          [asset.id]
        ),
        pipe(
          AssetsPageUIStateL,
          L.composeLens(AssetsFilterL),
          L.composeLens(FilterByTypeL)
        ).set([AssetTypes.ZONE, AssetTypes.BUILDING, AssetTypes.FLOOR])
      )
    );
  }, []);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ marginLeft: "8px" }}>
      {pipe(
        parentLine,
        A.reverse,
        A.tail,
        O.map(
          A.map((breadcrumbAsset) =>
            pipe(
              parentLine,
              A.reverse,
              A.last,
              O.filter((lastAsset) =>
                eqAsset.equals(lastAsset, breadcrumbAsset)
              ),
              O.fold(
                () => (
                  <BreadcrumbButton
                    onClick={() => handleClickBreadCrumb(breadcrumbAsset)}
                  >
                    {breadcrumbAsset.name}
                  </BreadcrumbButton>
                ),
                () => (
                  <Typography
                    sx={{
                      padding: "5px 4px",
                      color: `${palette.neutral[500]}`,
                    }}
                  >
                    {breadcrumbAsset.name}
                  </Typography>
                )
              )
            )
          )
        ),
        O.getOrElseW(() => null)
      )}
    </Breadcrumbs>
  );
};

export const BreadcrumbButton = styled(Button)`
  background-color: transparent;
  padding: 4px 4px;

  &:hover {
    background-color: transparent;
    color: ${palette.primary[400]};
  }
`;
