import { styled } from "@mui/material/styles";
import palette from "theme/palette";

export const ErrorDisplay = styled("div")`
  margin-top: 12px;
  background-color: ${palette.data.sequential[1100]};
  border-radius: 2px;
  display: flex;
  padding: 8px 24px;

  & p {
    margin: 0;
    color: white;
    font-size: 12px;
  }
`;
