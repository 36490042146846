import { ADT, match } from "ts-adt";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as E from "fp-ts/lib/Eq";
import * as Ord from "fp-ts/lib/Ord";
import * as S from "fp-ts/string";
import * as t from "io-ts";
import { option } from "io-ts-types";

export const PendingDataModel = <C extends t.Mixed>(codec: C) =>
  t.strict(
    {
      _type: t.literal("pending"),
      data: option(codec),
    },
    `PendingData<${codec.name}>`
  );

export interface PendingData<T> {
  data: O.Option<T>;
}

export const DoneDataModel = <C extends t.Mixed>(codec: C) =>
  t.strict(
    {
      _type: t.literal("done"),
      data: option(codec),
    },
    `DoneData<${codec.name}>`
  );

export interface DoneData<T> {
  data: O.Option<T>;
}

export const PendingData = <T>(data: O.Option<T>): AsyncData<T> => ({
  data,
  _type: "pending",
});

export const DoneData = <T>(data: O.Option<T>): AsyncData<T> => ({
  data,
  _type: "done",
});

export type AsyncData<T> = ADT<{
  pending: PendingData<T>;
  done: DoneData<T>;
}>;

export const getEq = <T>(eq: E.Eq<T>) =>
  E.struct<AsyncData<T>>({
    data: pipe(eq, O.getEq),
    _type: S.Eq,
  });

export const getOrd = <T>(ord: Ord.Ord<T>) =>
  pipe(
    O.getOrd(ord),
    Ord.contramap((ad: AsyncData<T>) => ad.data)
  );

export const isLoading = <T>(ad: AsyncData<T>) => ad._type === "pending";

export const getData = <T>(ad: AsyncData<T>): O.Option<T> => ad.data;

export const map =
  <A, B>(fa: (a: A) => B) =>
  (ad: AsyncData<A>): AsyncData<B> => ({
    ...ad,
    data: pipe(ad.data, O.map(fa)),
  });

export const flattenData = <A>(ad: AsyncData<O.Option<A>>): AsyncData<A> => ({
  ...ad,
  data: pipe(ad.data, O.flatten),
});

export const fold =
  <T, B>(
    onPending: (data: O.Option<T>) => B,
    onDone: (data: O.Option<T>) => B
  ) =>
  (ad: AsyncData<T>) =>
    pipe(
      ad,
      match({
        pending: (r) => onPending(r.data),
        done: (r) => onDone(r.data),
      })
    );

export interface AsyncDataC<C extends t.Mixed>
  extends t.Type<AsyncData<t.TypeOf<C>>, AsyncData<t.OutputOf<C>>, unknown> {}

export const asyncData = <C extends t.Mixed>(codec: C): AsyncDataC<C> =>
  t.union(
    [PendingDataModel(codec), DoneDataModel(codec)],
    `AsyncData<${codec.name}>`
  );
