import React from "react";
import { flow, pipe } from "fp-ts/function";
import { useCollectorOld } from "lib/at-react/defineController";
import { AppController } from "controllers/AppController/AppController";
import * as L from "monocle-ts/Lens";

import * as b from "fp-ts/boolean";
import {
  hasFeatureFlag,
  isFeatureFlagAvailable,
} from "controllers/AppController/IsFeatureFlagAvailable";
import { FeatureFlagController } from "controllers/FeatureFlagController/FeatureFlagController";
import * as TE from "fp-ts/TaskEither";
import * as E from "fp-ts/Either";
import jwtDecode from "jwt-decode";
import * as C from "io-ts/Codec";
import * as A from "fp-ts/Array";
import * as s from "fp-ts/string";
import { Predicate } from "fp-ts/Predicate";

export const FeatureFlag: React.FC<{ flag: string }> = (props) => {
  const featureFlags = useCollectorOld(
    FeatureFlagController,
    L.prop("featureFlags")
  );

  return pipe(
    featureFlags,
    hasFeatureFlag(props.flag),
    b.fold(
      () => null,
      () => <>{props.children}</>
    )
  );
};

export type LambentAccessToken = C.TypeOf<typeof LambentAccessTokenModel>;

export const LambentAccessTokenModel = C.struct({
  "https://api.armoredthings.com/atTenantId": C.string,
  permissions: C.array(C.string),
});

export const hasPermission =
  (permission: string): Predicate<LambentAccessToken> =>
  (token: LambentAccessToken) =>
    // s.Eq is equality for string. Array typeclass(A) does not assume referential equality, we need to be explicit and tell
    // it how to check for equality
    pipe(token.permissions, A.elem(s.Eq)(permission));

export const decodeJWTToken = (accessToken: string) =>
  E.tryCatch(() => jwtDecode(accessToken), E.toError);

export const decodeToken = flow(
  LambentAccessTokenModel.decode,
  E.mapLeft(() => new Error("Invalid Access Token"))
);

export const NeedsPermission: React.FC<{ permission: string }> = ({
  permission,
  children,
}) => {
  const accessToken = useCollectorOld(
    AppController,
    flow(L.prop("accessToken"))
  );

  return pipe(
    accessToken,
    E.fromOption(() => new Error("No Access Token")),
    E.chainW(decodeJWTToken),
    E.chainW(decodeToken),
    E.chainW(
      E.fromPredicate(
        hasPermission(permission),
        () => new Error(`User Doesn't have required permission ${permission}`)
      )
    ),
    E.fold(
      () => null,
      () => <>{children}</>
    )
  );
};
