import { pipe } from "fp-ts/function";
import * as M from "fp-ts/Map";
import * as O from "fp-ts/Option";
import { Ord } from "fp-ts/Ord";
import * as E from "fp-ts/Eq";
import * as t from "io-ts";
import { Semigroup } from "fp-ts/Semigroup";

export interface Slice<KEY, T> {
  slices: Map<KEY, T>;
}

export const of = <T, KEY>(slices: [KEY, T][]): Slice<KEY, T> => ({
  slices: new Map(slices),
});

export const single =
  <T, KEY>(key: KEY) =>
  (element: T): Slice<KEY, T> => ({
    slices: new Map([[key, element]]),
  });

export const getEq = <KEY, T>(sk: E.Eq<KEY>, sa: E.Eq<T>) =>
  E.contramap((s: Slice<KEY, T>) => s.slices)(M.getEq(sk, sa));

export const empty = <T, KEY>() => of<T, KEY>([]);

export const fromMap = <T, KEY>(slices: Map<KEY, T>) => ({ slices });

export const map: <A, B, KEY>(
  f: (a: A) => B
) => (fa: Slice<KEY, A>) => Slice<KEY, B> = (f) => (fa) => ({
  ...fa,
  slices: pipe(fa.slices, M.map(f)),
});

export const mapWithIndex: <A, B, KEY>(
  f: (k: KEY, a: A) => B
) => (fa: Slice<KEY, A>) => Slice<KEY, B> = (f) => (fa) => ({
  ...fa,
  slices: pipe(fa.slices, M.mapWithIndex(f)),
});

export const filterMap: <A, B, KEY>(
  f: (a: A) => O.Option<B>
) => (fa: Slice<KEY, A>) => Slice<KEY, B> = (f) => (fa) => ({
  ...fa,
  slices: pipe(fa.slices, M.filterMap(f)),
});

export const toPairArray =
  <T, KEY>(o: Ord<KEY>) =>
  (slice: Slice<KEY, T>): Array<[KEY, T]> =>
    pipe(slice.slices, M.toArray(o));

export const getUnionSemiGroup = <KEY, T>(
  keyEq: E.Eq<KEY>,
  s: Semigroup<T>
): Semigroup<Slice<KEY, T>> => ({
  concat: (a, b) => ({
    slices: M.getUnionSemigroup(keyEq, s).concat(a.slices, b.slices),
  }),
});
