import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import { area } from "@turf/turf";
import { Asset } from "lib/at-data/assets/Asset";
import { GeoCoord } from "lib/at-data/GeoCoord";
import { toPolygon } from "lib/at-api/assets/legacy/toPolygon";
import { getGeometry } from "lib/at-data/assets/getters";

export const tryPolygon = (coords: Array<GeoCoord>) =>
  pipe(
    E.tryCatch(() => toPolygon(coords), E.toError),
    O.fromEither
  );

export const assetArea = (a: Asset): O.Option<number> =>
  pipe(
    a,
    // O.fromPredicate((_) => _.type === AssetTypes.AREA),
    getGeometry,
    O.chain(tryPolygon),
    O.map(area)
  );

export const SQUARE_FT_PER_SQ_METER = 10.7639;

export const toSqFt = (areaInSqM: number) => areaInSqM * SQUARE_FT_PER_SQ_METER;

export const perSqFt = (usdCost: number) => usdCost * SQUARE_FT_PER_SQ_METER;

export const toPerSqFt = (usdCostPerMeter: number) =>
  usdCostPerMeter / SQUARE_FT_PER_SQ_METER;
