import React, { useState } from "react";
import Icon, { AtChevronDown } from "components/ui/Icon";

import { palette } from "../styles/Styles";
import styled from "styled-components";

const Accordion: React.FC<{
  title: string;
  content: React.ReactNode;
  active: boolean;
}> = ({ title, content, active }) => {
  const [isActive, setIsActive] = useState(active);

  return (
    <AccordionItem className={"accordion"}>
      <div
        role={"button"}
        tabIndex={0}
        className={"accordion-header"}
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
      >
        <span>
          <Icon icon={AtChevronDown} size={"32"} />
        </span>
        {title}
      </div>

      {isActive && <div className="accordion-content">{content}</div>}
    </AccordionItem>
  );
};

export default Accordion;

export const AccordionItem = styled("div")`
  background-color: ${palette.neutral[100]};
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 0;

  & > .accordion-header {
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    cursor: pointer;
    background-color: hsl(0deg 0% 100% / 100%);
    width: 100%;
    border-radius: 4px;

    &:hover {
      background-color: #fefefe !important;
    }
  }

  & > .accordion-content {
    padding: 24px 0;
    border-radius: 4px;
  }
`;
