import {
  addAssetModification,
  addAssetModifications,
  assetHoursOfOperationModification,
  AssetModification,
  assetPlaneTransformationModification,
  assetSpatialGeometryModification,
  getModificationProperty,
} from "controllers/AssetsController/AssetModification";
import { sequenceT } from "fp-ts/Apply";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";
import * as TE from "fp-ts/TaskEither";
import * as C from "io-ts/Codec";
import { handleResponse } from "lib/at-api/assets/bookings";
import { Asset } from "lib/at-data/assets/Asset";
import { FloorMeta } from "lib/at-data/assets/models/FloorMetaModel";
import { GeoReference } from "lib/at-data/assets/models/GeoReferenceModel";
import { GeoCoord } from "lib/at-data/GeoCoord";
import { HoursOfOperation } from "lib/at-data/HoursOfOperation";
import { USDPerOneSqM } from "lib/at-data/units/currency";
import { UUID } from "lib/at-data/UUID";
import { defineController } from "lib/at-react/defineController";
import { dateFromISOString } from "lib/codecs/dateFromISOString";
import { clog } from "lib/util";
import * as L from "monocle-ts/Lens";

export type AssetsModificationHistory = {
  modifications: Array<AssetModification>;
};

export const modifyAssetName = (asset: Asset, name: string) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map(
              (lzid) =>
                ({
                  property: "PROPERTY_TAG_NAME",
                  value: name,
                  type: "ASSET_TASK_TYPE_PROPERTY_SET",
                  legacyZoneId: lzid,
                } as AssetModification)
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModification(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );

export const modifyAssetShortCode = (
  asset: Asset,
  shortCode: O.Option<string>
) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map(
              (lzid) =>
                ({
                  property: "PROPERTY_TAG_SHORT_CODE",
                  value: shortCode,
                  type: "ASSET_TASK_TYPE_PROPERTY_SET",
                  legacyZoneId: lzid,
                } as AssetModification)
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModification(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );

export const modifyAssetTimezone = (asset: Asset, timezone: O.Option<string>) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map(
              (lzid) =>
                ({
                  property: "PROPERTY_TAG_TIME_ZONE_ID",
                  value: timezone,
                  type: "ASSET_TASK_TYPE_PROPERTY_SET",
                  legacyZoneId: lzid,
                } as AssetModification)
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModification(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );

export const modifyAssetCapacity = (asset: Asset, capacity: O.Option<number>) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map(
              (lzid) =>
                ({
                  property: "PROPERTY_TAG_CAPACITY",
                  value: capacity,
                  type: "ASSET_TASK_TYPE_PROPERTY_SET",
                  legacyZoneId: lzid,
                } as AssetModification)
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModification(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );
export const modifyAssetTargetUtilization = (
  asset: Asset,
  tu: O.Option<number>
) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map(
              (lzid) =>
                ({
                  property: "PROPERTY_TAG_UTILIZATION_GOAL",
                  value: tu,
                  type: "ASSET_TASK_TYPE_PROPERTY_SET",
                  legacyZoneId: lzid,
                } as AssetModification)
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModification(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );

export const modifyCostPerSqM = (
  asset: Asset,
  pricePerSqM: O.Option<USDPerOneSqM>
) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map(
              (lzid) =>
                ({
                  property: "PROPERTY_TAG_NET_PRICE_PER_SQ_MT",
                  value: pricePerSqM,
                  type: "ASSET_TASK_TYPE_PROPERTY_SET",
                  legacyZoneId: lzid,
                } as AssetModification)
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModification(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );
export const modifyGeoreference = (
  asset: Asset,
  georeference: O.Option<GeoReference>
) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify(
      pipe(
        asset.legacyZoneId,
        O.fold(
          () => (_) => _,
          (lzid) =>
            addAssetModification(
              assetPlaneTransformationModification(georeference, lzid)
            )
        )
      )
    )
  );

export const modifyGeography = (
  asset: Asset,
  geography: O.Option<Array<GeoCoord>>
) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify(
      pipe(
        asset.legacyZoneId,
        O.fold(
          () => (_) => _,
          (lzid) =>
            addAssetModification(
              assetSpatialGeometryModification(geography, lzid)
            )
        )
      )
    )
  );

export const modifyHoursOfOperation = (
  asset: Asset,
  ho: O.Option<HoursOfOperation>
) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify(
      pipe(
        asset.legacyZoneId,
        O.fold(
          () => (_) => _,
          (lzid) =>
            addAssetModification(assetHoursOfOperationModification(ho, lzid))
        )
      )
    )
  );

// messy since its a mixed type array
export const getFloorMetaCommands =
  (floorMeta: O.Option<FloorMeta>) => (lzid: UUID) => {
    const level = pipe(
      floorMeta,
      O.map(
        flow((fm) => ({
          property: "PROPERTY_TAG_LEVEL_NUMBER",
          value: O.some(fm.order),
          type: "ASSET_TASK_TYPE_PROPERTY_SET",
          legacyZoneId: lzid,
        }))
      ),
      A.fromOption
    );
    const abbr = pipe(
      floorMeta,
      O.chain(
        flow(
          (_) => _.abbreviation,
          O.map((v) => ({
            property: "PROPERTY_TAG_LEVEL_LABEL",
            value: O.some(v),
            type: "ASSET_TASK_TYPE_PROPERTY_SET",
            legacyZoneId: lzid,
          }))
        )
      ),
      A.fromOption
    );
    return [...level, ...abbr];
  };

export const modifyAssetFloorMeta = (
  asset: Asset,
  floorMeta: O.Option<FloorMeta>
) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(asset.legacyZoneId, O.map(getFloorMetaCommands(floorMeta)))
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModifications(m as any))
        ),
        O.getOrElse(() => _)
      )
    )
  );

export const addAssetTags = (tags: Array<string>) => (asset: Asset) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map((lzid) =>
              pipe(
                tags,
                A.map(
                  (tag) =>
                    ({
                      property: "PROPERTY_TAG_USER_DEFINED_TAG",
                      value: tag,
                      type: "ASSET_TASK_TYPE_PROPERTY_ADD",
                      legacyZoneId: lzid,
                    } as AssetModification)
                )
              )
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModifications(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );
export const deleteAssetTags = (tags: Array<string>) => (asset: Asset) =>
  pipe(
    L.id<AssetsModificationHistory>(),
    L.prop("modifications"),
    L.modify((_) =>
      pipe(
        sequenceT(O.Monad)(
          O.some(_),
          pipe(
            asset.legacyZoneId,
            O.map((lzid) =>
              pipe(
                tags,
                A.map(
                  (tag) =>
                    ({
                      property: "PROPERTY_TAG_USER_DEFINED_TAG",
                      value: tag,
                      type: "ASSET_TASK_TYPE_PROPERTY_REMOVE_BY_VALUE",
                      legacyZoneId: lzid,
                    } as AssetModification)
                )
              )
            )
          )
        ),
        O.map(([modifications, m]) =>
          pipe(modifications, addAssetModifications(m))
        ),
        O.getOrElse(() => _)
      )
    )
  );

export const AssetModificationServiceResponseModel = C.struct({
  assetTaskImport: C.struct({
    status: C.literal("ASSET_TASK_STATUS_COMPLETE_SUCCESS"),
    errorMessages: C.array(C.string),
    nodes: C.array(
      C.struct({
        legacyId: C.struct({ value: UUID }),
        nodeId: C.struct({ value: UUID }),
      })
    ),
    taskCount: C.number,
    taskCountComplete: C.number,
    completedOn: dateFromISOString,
  }),
});

export type AssetModificationServiceResponse = C.TypeOf<
  typeof AssetModificationServiceResponseModel
>;

export const postAssetModificationsToService = (
  serviceUrl: string,
  accessToken: string,
  assetUpdates: string
) =>
  pipe(
    TE.tryCatch(
      () =>
        fetch(serviceUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tenantId: { value: "00000000-0000-0000-0000-000000000000" },
            content: pipe(assetUpdates, btoa),
          }),
        }).then(handleResponse("Assets Modifications Service")),
      E.toError
    )
  );

export const initialState: AssetsModificationHistory = {
  modifications: [],
};

export const AssetsModificationHistoryEq = Eq.struct<AssetsModificationHistory>(
  {
    modifications: pipe(
      A.getEq(pipe(s.Eq, Eq.contramap(getModificationProperty)))
    ),
  }
);

export const [component, controller] = defineController(
  initialState,
  AssetsModificationHistoryEq,
  Eq.struct({}),
  () => () => () => {}
);

export const LegacyAssetsModificationsController = controller;
/**
 * @deprecated
 */
export const LegacyAssetsModificationsControllerComponent = component;

// export const getModifiedPropertyValue =
//   (property: AssetProperty) =>
//   (asset: Asset) =>
//     pipe(asset, AssetPropertyLenses[property]);
