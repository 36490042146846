import { pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import * as O from "fp-ts/Option";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { rad2deg } from "views/authenticated/activity/page/components/spatial";
import {
  EaseToOptions,
  EventData,
  LngLatBounds,
  LngLatBoundsLike,
  Map,
} from "mapbox-gl";
import { BBox } from "lib/at-data/BBox";
import { GeoBounds } from "lib/at-data/GeoBounds";
import { UUID } from "lib/at-data/UUID";

export const changeMapBearing = (angle: number) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      console.log("orienting the map", rad2deg(angle));
      ctx.map.rotateTo(pipe(angle, rad2deg), { duration: 0 });
    })
  );

export const resetMapBearing = pipe(
  R.ask<MapboxContext>(),
  R.map((ctx) => () => {
    ctx.map.resetNorth({
      duration: 0,
    });
  })
);

export const onUserMapMove = (moveEndHandler: (bbox: O.Option<BBox>) => void) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      const handler = () => {
        moveEndHandler(
          O.some(
            ctx.map.getBounds().toArray().flat() as [
              number,
              number,
              number,
              number
            ]
          )
        );
      };
      ctx.map.once("idle", () => {
        ctx.map.on("moveend", handler);
      });
    })
  );

export const onUserMapMove2 = (moveEndHandler: (ev: EventData) => void) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.off("moveend", moveEndHandler);
      ctx.map.on("moveend", moveEndHandler);
    })
  );

// Note that mapbox needs reference to the original function to unbind it as event handler /vz
// whatever uses this needs to keep track of that
export const offUserMapMove2 = (moveEndHandler: () => void) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      console.log("unscubiibg to map changes");
      ctx.map.off("moveend", moveEndHandler);
    })
  );

export const lockMapToCurrentState = pipe(
  R.ask<MapboxContext>(),
  R.map((ctx) => () => {
    ctx.map.setMinZoom(ctx.map.getZoom());
    ctx.map.setMaxBounds(ctx.map.getBounds());
  })
);

export const easeTo = (eto: EaseToOptions) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.easeTo(eto);
    })
  );
