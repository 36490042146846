import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";

export const groupIn =
  <T>(groupSize: number) =>
  (items: Array<T>): Array<Array<T>> =>
    pipe(items, A.splitAt(groupSize), ([a1, a2]) =>
      pipe(
        a1,
        NEA.fromArray,
        O.foldW(
          () => A.of(a2),
          (v) => pipe(A.of(v), A.concat(groupIn(groupSize)(a2) as T[][]))
        )
      )
    );
