import ModalHeader from "components/modal/ModalHeader";
import React from "react";
import styled, { keyframes } from "styled-components";
import { Button, SvgIcon } from "@mui/material";
import Icon, { AtClose } from "components/ui/Icon";

const SpinnerModal: React.FC<{ onClose: () => void }> = (props) => {
  const handleCancel = () => {
    window.history.back();
  };
  return (
    <ModalContainer>
      <Modal>
        <CloseButton onClick={props.onClose}>
          <Icon icon={AtClose} size="24px" />
        </CloseButton>
        <SVGContainer>
          <Svg
            width="49.3284"
            height="46.662"
            viewBox="0 0 37 35"
            fill="none"
            className="spinner"
          >
            <path
              d="M24.2954 0H12.4512V7.10217H24.2954V0Z"
              // fill="#E50695"
              className="c1"
            />
            <path
              d="M36.3733 13.8003L30.4512 3.55469L24.2938 7.10577L30.2159 17.3514L36.3733 13.8003Z"
              fill="#1ECAD3"
              className="c2"
            />
            <path
              d="M30.4275 31.1245L36.3496 20.8789L30.1922 17.3278L24.2701 27.5734L30.4275 31.1245Z"
              fill="#00B2E3"
              className="c3"
            />
            <path
              d="M24.2954 27.5664H12.4512V34.6686H24.2954V27.5664Z"
              fill="#0071CE"
              className="c4"
            />
            <path
              d="M12.4729 27.5737L6.55078 17.3281L0.3934 20.8792L6.31551 31.1248L12.4729 27.5737Z"
              fill="#B14FC5"
              className="c5"
            />
            <path
              d="M6.52906 17.3511L12.4512 7.10547L6.29379 3.55438L0.371679 13.8L6.52906 17.3511Z"
              fill="#EE6FBB"
              className="c6"
            />
          </Svg>
        </SVGContainer>
        <MessageContainer>
          <Delayed>
            <Heading>We're gathering your data</Heading>
            <Message>
              The amount of data you requested is taking longer than normal to
              load. <br /> Thank you for your patience.
            </Message>
          </Delayed>
          <Abort>
            <Heading>It's taking longer than expected</Heading>
            <Message>
              We're still working on your data, but it may take longer than
              expected. You may Cancel this operation, or request this data from
              your Customer Success Manager.
            </Message>
            <Button onClick={handleCancel}>Close and Cancel</Button>
          </Abort>
        </MessageContainer>
      </Modal>
    </ModalContainer>
  );
};

export default SpinnerModal;

const Spin = keyframes`
 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const DelayedAnim = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const AbortAnim = keyframes`
  0% {
    opacity: 0;
    z-index: 10001;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  position: fixed;
  background-color: hsla(237, 24%, 15%, 0);
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const Modal = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 360px;
  width: 720px;
  z-index: 10000;
  // position: fixed;
  padding: 24px 48px;
  background-color: hsla(0, 100%, 100%, 1);
  filter: drop-shadow(0 2px 16px rgba(29, 30, 47, 0.2));
  border-radius: 4px;
  pointer-events: auto;
`;
const CloseButton = styled("button")`
  position: absolute !important;
  right: 12px;
  top: 12px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: white;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.15s ease-in;
  &:hover {
    background-color: #eff1f6;
    transition: 0.1s ease-in;
  }
`;
const SVGContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 140px;
`;
const Svg = styled("svg")`
  position: relative;
  animation: ${Spin} 3s ease-in-out infinite;
  & .c1 {
    fill: #e50695;
  }
`;

const MessageContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 220px;
`;

const Delayed = styled("div")`
  position: absolute !important;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation-duration: 30s;
  animation-name: ${DelayedAnim};
  animation-iteration-count: 1;
  animation-delay: 0;
`;
const Abort = styled("div")`
  position: absolute !important;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation-duration: 30s;
  animation-name: ${AbortAnim};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 30s;
  z-index: -1;
`;

const Heading = styled("h2")`
  font-size: 22px;
  margin: 36px auto 0;
`;

const Message = styled("p")`
  font-size: 16px;
  margin-bottom: 24px;
  text-align: center;
`;
