import { styled } from "@mui/material";
import React from "react";
import ReportsPlaceholderImage from "views/authenticated/reports/componenets/ReportsPlaceholderImage";

interface IPageTableCellThumbnail {
  image: React.ReactElement | string;
  alt?: string;
}

const PageTableCellThumbnail: React.FC<IPageTableCellThumbnail> = ({
  image,
  alt,
}) => (typeof image === "string" ? <Image src={image} alt={alt} /> : image);

export default PageTableCellThumbnail;

const Image = styled("img")`
  object-fit: cover;
  width: 100%;
`;
