import {
  OccupancySpreadController,
  OccupancySpreadControllerState,
} from "controllers/OccupancySpreadController/OccupancySpreadController";
import {
  composeController,
  defineCalculatedContext,
} from "lib/at-react/defineController";
import { AssetsContext } from "contexts/AssetsContext";
import * as Eq from "fp-ts/Eq";
import { eqAssets, eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as AD2 from "lib/at-data/AsyncData2";
import * as D from "lib/at-data/DataSeries";
import {
  OccupancyEstimate,
  OccupancyEstimateEq,
} from "lib/at-data/OccupancyEstimate";
import {
  toDataAggFn,
  toTableDataAggFn,
} from "views/authenticated/assets/page/components/AssetsTable/columns";
import * as A from "fp-ts/Array";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import { createColumn } from "views/authenticated/assets/page/components/AssetsTable/dynamic";
import * as L from "monocle-ts/Lens";
import { VisibleAssetsContext } from "controllers/AssetsTableDataController/contexts/VisibleAssetsContext";
import { AssetTableColumnsContext } from "controllers/AssetsTableDataController/contexts/AssetTableColumnsContext";
import * as O from "fp-ts/Option";
import { sequenceT } from "fp-ts/Apply";
import {
  AssetTableColumnEq,
  eqTableColumnInfo,
} from "controllers/AssetsTableController/state";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as Sl from "lib/at-data/Slice";
import {
  AssetsAnalyzeData2,
  AssetsAnalyzeDataController,
  eqAnalyzeAsyncData,
} from "controllers/AssetsAnalayzeDataController/AssetsAnalyzeDataController";
import { toLegacyAsyncData } from "lib/at-data/AsyncData2";

export type AssetsTableDataState = Array<ROR.ReadonlyRecord<string, unknown>>;

export const DataL = pipe(L.id<AssetsTableDataState>());

const getSlice =
  (o: O.Option<UUID>) => (s: Sl.Slice<UUID, D.DataSeries<OccupancyEstimate>>) =>
    pipe(
      sequenceT(O.Monad)(o, O.some(s)),
      O.chain(([id, slice]) => pipe(slice, getSliceByUUID(id)))
    );

export const calculateTableData = ({
  assets,
  columns,
  visibleAssets,
  analyzeData,
  occupancySpread,
}: AssetsContext &
  VisibleAssetsContext &
  OccupancySpreadControllerState &
  AssetTableColumnsContext &
  AssetsAnalyzeData2) =>
  pipe(
    visibleAssets,
    A.map((asset) => {
      // const occupancySeriesO = pipe(
      //   occupancySpread,
      //   AD.map(getSliceByUUID(asset.id)),
      //   AD.flattenData
      // );
      const tableColumns = pipe(columns, A.map(createColumn));
      const tableDataFn = pipe(tableColumns, toTableDataAggFn);

      const dataAggFn = pipe(
        tableColumns,
        A.map((_) => _.dataAgg),
        toDataAggFn
      );

      const meanOccupancySlice = pipe(
        analyzeData,
        AD2.map((_) => _.meanOccupancySlice),
        toLegacyAsyncData
      );
      const peakOccupancySlice = pipe(
        analyzeData,
        AD2.map((_) => _.peakOccupancySlice),
        toLegacyAsyncData
      );
      const meanUtilizationSlice = pipe(
        analyzeData,
        AD2.map((_) => _.meanUtilizationSlice),
        toLegacyAsyncData
      );

      return pipe(
        tableDataFn({
          asset,
          assets,
          occupancySpread,
          meanOccupancySlice,
          meanUtilizationSlice,
          peakOccupancySlice,
        }),
        dataAggFn,
        (_) => ({
          ..._,
          assetId: asset.id,
        })
      );
    })
  );

export const [component, controller] = pipe(
  OccupancySpreadController,
  composeController(
    pipe(
      AssetsAnalyzeDataController,
      composeController(
        defineCalculatedContext(
          [],
          Eq.struct<
            AssetsContext &
              VisibleAssetsContext &
              AssetTableColumnsContext &
              AssetsAnalyzeData2 &
              OccupancySpreadControllerState
          >({
            assets: Eq.eqStrict,
            columns: A.getEq(eqTableColumnInfo),
            visibleAssets: eqAssets,
            analyzeData: eqAnalyzeAsyncData,
            occupancySpread: AD2.getEq(
              Sl.getEq(eqUUID, D.getEq(OccupancyEstimateEq))
            ),
          }),
          calculateTableData
        )
      )
    )
  )
);

export const AssetsTableDataControllerComponent = component;
export const AssetsTableDataController = controller;
