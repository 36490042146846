import React from "react";
import PageHeader from "components/ui/PageHeader";
import ReportsHeader from "./componenets/ReportsHeader";
import { styled } from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import ReportsTable from "./componenets/reportsTable/ReportsTable";
import { ReportsControllerComponent } from "controllers/ReportsController/ReportsController";
import { NeedsPermission } from "components/featureFlag/FeatureFlag";

const Reports2Page: React.FC<{}> = () => {
  return (
    <NeedsPermission permission={"reports:read"}>
      <ReportsControllerComponent context={{}}>
        <Page>
          <PageHeader header={<ReportsHeader />} />
          {/*<ReportsRecentlyViewed />*/}
          <ReportsTable />
        </Page>
      </ReportsControllerComponent>
    </NeedsPermission>
  );
};

export default Reports2Page;

const Page = styled("main")`
  background-color: ${palette.neutral[200]};
  min-height: calc(100vh - ${vars.navBar});
`;
