import {
  AssetsAnalyzeData2,
  AssetsAnalyzeDataController,
  eqAnalyzeAsyncData,
} from "controllers/AssetsAnalayzeDataController/AssetsAnalyzeDataController";
import { toLegacyAsyncData } from "lib/at-data/AsyncData2";
import {
  composeController,
  defineCalculatedContext,
} from "lib/at-react/defineController";
import { VisibleAssetsContext } from "controllers/AssetsTableDataController/contexts/VisibleAssetsContext";
import * as AD from "lib/at-data/AsyncData";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import * as O from "fp-ts/Option";
import * as Eq from "fp-ts/Eq";
import { eqAssets, eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import * as A from "fp-ts/Array";
import * as L from "monocle-ts/Lens";
import { AssetsContext } from "contexts/AssetsContext";
import {
  AssetFeature,
  GeoJSONAsset,
} from "components/spatial/base/layers/assets";
import { UTILIZATION_THRESHOLDS } from "constants/UTILIZATION_THRESHOLDS";
import * as UT from "lib/at-data/UtilizationThresholds";
import palette from "theme/palette";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import { ByTag, filterAssets } from "lib/at-data/assets/filters";

export interface ActivityMapDataState {
  utilizationGeoJSON: AD.AsyncData<
    Array<AssetFeature<{ utilizationColor: string }>>
  >;
}

export const UtilizationGeoJSONL = pipe(
  L.id<ActivityMapDataState>(),
  L.prop("utilizationGeoJSON")
);

export const initialState: ActivityMapDataState = {
  utilizationGeoJSON: AD.DoneData(O.none),
};

export const toUtilizationHeatMapGeoJSON = pipe(
  R.ask<VisibleAssetsContext & AssetsAnalyzeData2>(),
  R.map((ctx) =>
    pipe(
      ctx.visibleAssets,
      filterAssets(ByTag("Managed Assets")),
      A.filterMap((asset) =>
        GeoJSONAsset(asset, {
          utilizationColor: pipe(
            ctx.analyzeData,
            toLegacyAsyncData,
            AD.map(
              flow(
                (_) => _.meanUtilizationSlice,
                getSliceByUUID(asset.id),
                O.flatten,
                O.map(toPercentage),
                O.chain((percentUtilization) =>
                  pipe(
                    UTILIZATION_THRESHOLDS,
                    UT.getThresholdColor(percentUtilization)
                  )
                ),
                O.getOrElse(() => palette.neutral[500])
              )
            ),
            AD.getData,
            O.getOrElse(() => palette.neutral[500])
          ),
        })
      )
    )
  )
);

export const [component, controller] = pipe(
  AssetsAnalyzeDataController,
  composeController(
    defineCalculatedContext(
      initialState,
      Eq.struct<VisibleAssetsContext & AssetsAnalyzeData2>({
        visibleAssets: eqAssets,
        analyzeData: eqAnalyzeAsyncData,
      }),
      pipe(
        R.Do,
        R.apSW(
          "utilizationGeoJSON",
          pipe(toUtilizationHeatMapGeoJSON, R.map(flow(O.some, AD.DoneData)))
        )
      )
    )
  )
);

export const ActivityMapDataControllerComponent = component;

export const ActivityMapDataController = controller;
