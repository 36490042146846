import { ADT } from "ts-adt";
import * as Ord from "fp-ts/Ord";
import * as s from "fp-ts/string";

export enum ArtifactTypes {
  BACKGROUND = "background",
}

export const ArtifactTypesOrd: Ord.Ord<ArtifactTypes> = s.Ord;

export type LambentArtifact = ADT<{
  [ArtifactTypes.BACKGROUND]: {
    data: string;
    planeWidth: number;
    planeHeight: number;
    timestamp: Date;
  };
}>;

export const BackgroundImageArtifact =
  (width: number, height: number) =>
  (data: string): LambentArtifact => ({
    _type: ArtifactTypes.BACKGROUND,
    data,
    planeWidth: width,
    planeHeight: height,
    timestamp: new Date(),
  });
