import * as React from "react";
import { useCallback } from "react";
import { styled } from "@mui/material/styles";
import Icon from "../../../../../../components/ui/Icon";
import { useController } from "lib/at-react/defineController";
import {
  AssetsTableDataController,
  DataL,
} from "controllers/AssetsTableDataController/AssetsTableDataController";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { flow } from "fp-ts/es6/function";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import {
  AssetsFilterL,
  AssetsPageUIStateL,
  FilterByIdsL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { eqUUID, ordUUID } from "../../../../../../lib/at-data/UUID";
import { RiHeart3Fill } from "react-icons/ri";
import { IconButton, Stack, Tooltip } from "@mui/material";
import palette from "../../../../../../theme/palette";
import theme from "../../../../../../theme/theme";
import {
  FilterByIds,
  FilterByIdType,
} from "../../../../../../controllers/UIStateCollector/models/assetFilterModel";
import { useHistoryControllerDispatch } from "../../../../../../lib/at-react/defineHistoryController";
import { FavoriteAssetsController } from "../../../../root/FavoriteAssetsController";
import * as Se from "fp-ts/Set";
import { matchP } from "ts-adt";
import * as b from "fp-ts/boolean";
import { useCollector, useCollectorWithDispatch } from "lib/at-react/hooks";
import mixpanel from "mixpanel-browser";
// import { Ord } from "fp-ts/string";

export const AssetsTableFavorites: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [assetsFilter] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).get
  );
  const uiStateDispatch = useHistoryControllerDispatch(UIStateController);

  const favoriteAssets = useCollector(
    FavoriteAssetsController,
    L.prop("favoriteAssets")
  );

  const filteredIds = pipe(
    assetsFilter.byIds,
    O.chain(
      matchP(
        {
          [FilterByIdType.ID]: (_) => O.some(_.ids),
        },
        () => O.none
      )
    ),
    O.map((_) => new Set(_))
  );

  const isHeartFilterEnabled = pipe(
    O.getEq(Se.getEq(eqUUID)).equals(O.some(favoriteAssets), filteredIds)
  );

  const handleHeartClick = useCallback(() => {
    pipe(
      isHeartFilterEnabled,
      b.fold(
        () => pipe(favoriteAssets, Se.toArray(ordUUID), FilterByIds, O.some),
        () => O.none
      ),
      pipe(
        AssetsPageUIStateL,
        L.composeLens(AssetsFilterL),
        L.composeLens(FilterByIdsL)
      ).set,
      uiStateDispatch
    );
    mixpanel.track("Analyze Favorites Clicked");
  }, [uiStateDispatch, favoriteAssets, isHeartFilterEnabled]);
  return (
    <Stack justifyContent="center" alignItems="center" px={1}>
      <Tooltip disableFocusListener title={"Show Favorites"}>
        <AnalyzeHeartIconButton onClick={handleHeartClick}>
          <AnalyzeHeartIcon
            icon={RiHeart3Fill}
            sx={{
              color: isHeartFilterEnabled
                ? `${palette.logo[500]}`
                : `${palette.neutral[450]}`,
            }}
            size={"30"}
          />
        </AnalyzeHeartIconButton>
      </Tooltip>
    </Stack>
  );
};

export const AnalyzeHeartIcon = styled(Icon)`
  color: ${palette.neutral[450]};
  size: 24px;
  &:hover {
    color: ${palette.logo[500]};
  }
  &:active {
    color: ${palette.logo[500]};
  }
  &:click {
    color: ${palette.logo[500]};
  }
`;

export const AnalyzeHeartIconButton = styled(IconButton)`
  color: ${palette.neutral[100]};
  transition: ${theme.transitions.create(["background-color", "color"])};

  &:hover,
  &:focus {
    color: ${palette.neutral[100]};
    background-color: ${palette.neutral[100]};
  }
`;
