import {
  UserPreferences,
  UserPreferencesController,
} from "controllers/UserPreferencesController/UserPreferencesController";
import * as A from "fp-ts/Array";
import * as IO from "fp-ts/IO";
import * as RT from "fp-ts/ReaderTask";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as C from "io-ts/Codec";
import * as Eq from "fp-ts/Eq";
import * as S from "fp-ts/Set";
import { flow, pipe } from "fp-ts/function";
import { withUpstreamLegacyController } from "lib/at-react/legacy";
import { localStorageState } from "lib/at-react/state/localStorageState";
import { reactState } from "lib/at-react/state/reactState";
import { clog } from "lib/util";
import { FavoriteAssetsL } from "views/authenticated/root/FavoriteAssetsL";
import {
  FavoriteAssetsControllerState2,
  FavoriteAssetsModel,
  FavoriteAssetsModel2,
} from "./FavoriteAssetsModel";
import { eqUUID, UUID } from "../../../lib/at-data/UUID";
import { effect, makeMemo } from "lib/at-react/collector";

export const UserFavoriteAssetsModel = C.struct({
  favoriteAssets: C.array(UUID),
});

export type UserFavoriteAssets = C.TypeOf<typeof UserFavoriteAssetsModel>;

export const UserFavoriteAssetsEq = Eq.struct<UserFavoriteAssets>({
  favoriteAssets: A.getEq(eqUUID),
});

export const initialState: FavoriteAssetsControllerState2 = {
  favoriteAssets: new Set(), //should be an array but can also be a set since we shouldn't have duplicates anyway?
};

export const FavoriteAssetsEq = S.getEq(eqUUID);

export const FavoriteAssetsControllerStateEq =
  Eq.struct<FavoriteAssetsControllerState2>({
    favoriteAssets: FavoriteAssetsEq,
  });

export const FavoriteAssetsController = pipe(
  reactState<FavoriteAssetsControllerState2>(
    initialState,
    FavoriteAssetsControllerStateEq
  ),
  effect((dispatch) =>
    pipe(
      RT.ask<UserPreferences>(),
      RT.map(UserFavoriteAssetsModel.decode),
      RTE.chainIOK(
        flow(
          (_) => _.favoriteAssets,
          S.fromArray(eqUUID),
          FavoriteAssetsL.set,
          dispatch,
          IO.of
        )
      )
    )
  ),
  withUpstreamLegacyController(UserPreferencesController),
  makeMemo(Eq.fromEquals(() => true))
);
