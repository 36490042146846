import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Map } from "mapbox-gl";
import { mapStyle } from "views/authenticated/activity/page/components/MapStyle";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import * as T from "fp-ts/Task";
import { noop } from "lib/util";
import { BBox } from "lib/at-data/BBox";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { MapLoadingSpinner } from "components/spatial/base/MapLoadingSpinner";

const mapbox = window.mapboxgl;

export const CONTINENTAL_US = [
  -124.848974, 49.384358, -66.885444, 24.396308,
] as BBox;

const initializeMap =
  (setFn: (map: Map) => void, initialBounds: O.Option<BBox>) =>
  (mapEl: HTMLElement): T.Task<MapboxContext> =>
  () =>
    new Promise((resolve, reject) => {
      const map = new mapbox.Map({
        accessToken:
          "pk.eyJ1IjoiZWtmdWhybWFubiIsImEiOiJjamZ3bDc2MHcwY250MzNvOGI1MzE1YmR3In0._q5QuX8Fh26MhMLc_12rKg#14",
        container: mapEl,
        style: mapStyle,
        bounds: pipe(
          initialBounds,
          O.getOrElse(() => CONTINENTAL_US)
        ),
        zoom: 1,
      });
      map.on("load", () => {
        // add loading image so it can be used by map layers
        map.addImage("loading-spinner", MapLoadingSpinner(map, 400), {
          pixelRatio: 2,
        });
        // map.addImage("blueprint-pattern", BackgroundBlueprintTexture(map), {
        //   pixelRatio: 2,
        // });
        setFn(map);
        resolve({ map });
      });
    });

const BaseSpatialMap: React.FC<{
  initalBounds: O.Option<BBox>;
  onMapReady: (map: Map) => void;
  style?: CSSProperties;
}> = React.memo((props) => {
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [ready, setReady] = useState(false);

  // initial map initialization
  useEffect(() => {
    pipe(
      mapContainer.current,
      O.fromNullable,
      O.fold(
        () => noop,
        flow(
          initializeMap((initializedMap) => {
            map.current = initializedMap;
            setReady(true);
            props.onMapReady(initializedMap);
          }, props.initalBounds)
        )
      )
    )();
  }, []);

  return <div ref={mapContainer} style={props.style} />;
});

export default BaseSpatialMap;
