import { Button, IconButton, TextField } from "@mui/material";
import Icon, { AtArrowCaretDoubleLeft } from "components/ui/Icon";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as n from "fp-ts/number";
import * as O from "fp-ts/Option";
import * as RT from "fp-ts/ReaderTask";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as s from "fp-ts/string";
import * as TE from "fp-ts/TaskEither";
import { handleResponse } from "lib/at-api/assets/getAssets";
import {
  effect,
  ask,
  make,
  map,
  fromCollector,
  andAsk,
} from "lib/at-react/collector";
import {
  useCollector,
  useCollectorEffect,
  useCollectorWithDispatch,
} from "lib/at-react/hooks";
import { fromReactState, reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";
import React, { useState } from "react";
import { RiDeleteBackFill, RiRefreshFill } from "react-icons/ri";

export interface TodoCollectorState {
  todos: Array<string>;
}

export const TodosL = pipe(L.id<TodoCollectorState>(), L.prop("todos"));
export const initialToDoState: TodoCollectorState = { todos: [] };
export const TodoCollectorStateEq = Eq.struct<TodoCollectorState>({
  todos: A.getEq(s.Eq),
});

type AppContext = {
  token: O.Option<string>;
};
const initialAppContext: AppContext = {
  token: O.none,
};

const ApplicationCollector = pipe(
  ask<AppContext>(initialAppContext),
  // fromReactState(Eq.struct({ token: O.getEq(s.Eq) })),
  make
);

export const ToDoCollector = pipe(
  ask<TodoCollectorState>(initialToDoState),
  fromReactState(TodoCollectorStateEq),
  make
);

export const reverseString = (st: string) =>
  pipe(st, Array.from, A.reverse).join("");
export const getStringLength = (st: string) => st.length;

export const BackwardsToDoCollector = pipe(
  ask<TodoCollectorState>(initialToDoState),
  fromCollector(ToDoCollector),
  map(pipe(TodosL, L.modify(A.map(reverseString)))),
  make
);
export const fetchGiphyTE = pipe(
  RTE.ask<TodoCollectorState>(),
  RTE.chainTaskEitherK(({ todos }) =>
    pipe(
      todos,
      A.map((t) =>
        TE.tryCatch(
          () =>
            fetch(
              `https://api.giphy.com/v1/gifs/search?api_key=b1kOO9HP02PQEfMpMCsJzQknhy85fvh0&q=${t}&limit=25&offset=0&rating=g&lang=en&bundle=messaging_non_clips`,
              {
                method: "GET",
                headers: { "Content-Type": "application/json" },
              }
            ).then(handleResponse("Giphy API Error")),
          E.toError
        )
      ),
      A.sequence(TE.ApplicativePar)
    )
  ),
  RTE.chainTaskEitherK(
    flow(
      A.map((response) =>
        TE.tryCatch(() => {
          return Promise.resolve(
            response.data[0].images.original.url as string
          );
        }, E.toError)
      ),
      A.sequence(TE.ApplicativePar)
    )
  )
);
export const GiphyCollector = pipe(
  ask<TodoCollectorState>(initialToDoState),
  fromReactState(TodoCollectorStateEq),
  effect((dispatch) =>
    pipe(fetchGiphyTE, RTE.chainIOK(flow(TodosL.set, dispatch, IO.of)))
  ),
  fromCollector(ToDoCollector),
  make
);

export interface NumberOfCharactersContext {
  numberOfCharacters: number;
}

export const NumberOfCharactersContext = (numberOfCharacters: number) => ({
  numberOfCharacters,
});
export const NumberOfCharactersL = pipe(
  L.id<NumberOfCharactersContext>(),
  L.prop("numberOfCharacters")
);
export const TotalNumberOfCharactersCollector = pipe(
  ask<TodoCollectorState>(initialToDoState),
  fromCollector(ToDoCollector),
  map((_) =>
    pipe(
      _.todos,
      A.foldMap(n.MonoidSum)(getStringLength),
      NumberOfCharactersContext
    )
  ),
  make
);
export const ToDoAdder: React.FC<{ onAdd: (todo: string) => void }> = (
  props
) => {
  const [val, setVal] = useState<string>("");
  return (
    <div>
      <TextField
        onChange={(ev) => pipe(ev.target.value, setVal)}
        value={val}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.onAdd(val);
            setVal("");
          }
        }}
      />
      <Button
        onClick={() => {
          val && props.onAdd(val);
          setVal("");
        }}
      >
        Add
      </Button>
    </div>
  );
};

export type FilesContext = {
  files: Array<string>;
};
export const ToDos: React.FC<{}> = (props) => {
  const [todos, todoDispatch] = useCollectorWithDispatch(
    ToDoCollector,
    L.composeLens(TodosL)
  );
  const reversedTodos = useCollector(
    BackwardsToDoCollector,
    L.composeLens(TodosL)
  );
  const todoGiphies = useCollector(GiphyCollector, L.composeLens(TodosL));

  const numberOfCharacters = useCollector(
    TotalNumberOfCharactersCollector,
    L.composeLens(NumberOfCharactersL)
  );

  const addToDoCallback = useCollectorEffect(
    ToDoCollector,
    (todo: string) => (dispatch) =>
      pipe(
        RT.ask<{}>(),
        RT.chainIOK(() =>
          pipe(TodosL, L.modify(A.concat([todo])), dispatch, IO.of)
        )
      )
  );

  return (
    <div>
      <ToDoAdder onAdd={addToDoCallback} />
      <div>
        <h1>Todos:</h1>
        <ul>
          {pipe(
            todos,
            A.mapWithIndex((i, t) => (
              <li key={i}>
                {t}{" "}
                <IconButton
                  onClick={() =>
                    pipe(
                      TodosL,
                      L.modify(
                        flow(
                          A.deleteAt(i),
                          O.getOrElseW(() => [])
                        )
                      ),
                      todoDispatch
                    )
                  }
                >
                  <Icon icon={RiDeleteBackFill} />
                </IconButton>
              </li>
            ))
          )}
        </ul>
      </div>
      <div>
        <strong>Number of Characters:</strong>
        {numberOfCharacters}
      </div>
      <div>
        <h1>Reversed Todos:</h1>
        <ul>
          {pipe(
            reversedTodos,
            A.mapWithIndex((i, t) => <li key={i}>{t}</li>)
          )}
        </ul>
      </div>
      <div>
        <h1>GIFs:</h1>
        <ul>
          {pipe(
            todoGiphies,
            A.mapWithIndex((i, t) => (
              <li>
                <img src={t} key={i} alt={"t"} />
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
};

export const ToDoDemonstration1 = () => (
  <ApplicationCollector.Component context={{ token: O.none }}>
    <ToDoCollector.Component context={{ token: O.none }}>
      <GiphyCollector.Component context={{}}>
        <BackwardsToDoCollector.Component context={{}}>
          <TotalNumberOfCharactersCollector.Component context={{}}>
            <ToDos />
          </TotalNumberOfCharactersCollector.Component>
        </BackwardsToDoCollector.Component>
      </GiphyCollector.Component>
    </ToDoCollector.Component>
  </ApplicationCollector.Component>
);
