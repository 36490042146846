import styled from "@emotion/styled/macro";
import {
  Box,
  debounce,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import Icon, { AtArrowCaretDoubleLeft } from "components/ui/Icon";
import React, { useState } from "react";
import { RiFilter3Fill, RiMoreFill, RiSearchLine } from "react-icons/ri";
import palette from "theme/palette";
import theme from "theme/theme";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import {
  ActivityPageUIStateL,
  AssetsFilterL,
} from "controllers/UIStateCollector/lens/activityPage";
import { useController } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { FilterByNameL } from "controllers/UIStateCollector/lens/assetsPage";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { AssetsBookingsController } from "../../../../../../controllers/AssetsBookingsController/AssetsBookingsController";
import { BookingsL } from "../../../../../../controllers/AssetsBookingsController/state";
import * as b from "fp-ts/boolean";
import { noop } from "lib/util";

interface IDrawerHeader {
  handleDrawerClose: () => void;
}

export const searchQuery = pipe(
  ActivityPageUIStateL,
  L.composeLens(AssetsFilterL),
  L.composeLens(FilterByNameL)
).get;

// const [bookings] = useController(
//     AssetsBookingsController,
//     flow(BookingsL.get)
// );

const ActivityDrawerHeader: React.FC<IDrawerHeader> = (props) => {
  const dispatch = useHistoryControllerDispatch(UIStateController);
  const [assetsFilter] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(AssetsFilterL)).get
  );

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    pipe(
      event.target.value.length === 0 || event.target.value.length > 1,
      b.fold(noop, () =>
        dispatch(
          pipe(
            ActivityPageUIStateL,
            L.composeLens(AssetsFilterL),
            L.composeLens(FilterByNameL)
          ).set(event.target.value)
        )
      )
    );
  };

  return (
    <Box p={2}>
      <Stack direction="column" spacing={1.5}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1" color={palette.neutral[100]}>
              Assets
            </Typography>
          </Stack>
          <IconButton
            onClick={props.handleDrawerClose}
            color="primary"
            size="small"
          >
            <Icon icon={AtArrowCaretDoubleLeft} />
          </IconButton>
        </Stack>
        <Stack spacing={1} direction="row">
          <SearchBox>
            <SearchIcon>
              <Icon icon={RiSearchLine} size="16px" />
            </SearchIcon>
            <SearchInput
              mode="dark"
              placeholder="Search"
              defaultValue={assetsFilter.byName}
              onChange={handleSearchChange}
              inputProps={{ "aria-label": "search" }}
              fullWidth
            />
          </SearchBox>
          {/*<StyledIconButton>*/}
          {/*  <Icon icon={RiFilter3Fill} />*/}
          {/*</StyledIconButton>*/}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ActivityDrawerHeader;

const SearchBox = styled("div")`
  position: relative;
  width: 100%;
`;

const SearchIcon = styled("div")`
  position: absolute;
  left: ${theme.spacing(1)};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 16px;
  color: ${palette.neutral[400]};
  pointer-events: none;
  transition: ${theme.transitions.create(["color"])};

  ${SearchBox}:focus-within & {
    color: ${palette.neutral[100]};
  }
`;

const SearchInput = styled(InputBase)`
  height: 32px;
  padding: 0 ${theme.spacing(1)} 0 calc(16px + ${theme.spacing(2.25)});
  border-radius: ${theme.shape.borderRadius}px;

  &::placeholder {
    color: ${palette.neutral[400]};
  }

  input {
    padding: 0;
  }
`;

const StyledIconButton = styled(IconButton)`
  height: 32px;
  width: 32px;
  background-color: ${palette.neutral[600]};
  color: ${palette.neutral[400]};
  box-shadow: inset 0 0 0 0 ${palette.neutral[400]};
  transition: ${theme.transitions.create(["box-shadow", "color"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};

  &:hover {
    box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
    color: ${palette.neutral[100]};
    background-color: ${palette.neutral[600]};
  }

  &:focus {
    color: ${palette.neutral[100]};
    box-shadow: inset 0 0 0 1.5px ${palette.primary[400]};
    background-color: ${palette.neutral[600]};
  }
`;
