import React, { useCallback } from "react";
import * as NEA from "fp-ts/NonEmptyArray";
import { AssetModification } from "controllers/AssetsController/AssetModification";
import copyToClipboard from "copy-to-clipboard";
import { Button, Stack, TextareaAutosize } from "@mui/material";
import { pipe } from "fp-ts/function";
import { toCSV } from "components/navBar/modifications/lib";
import Icon, { AtCopyToClipboard } from "components/ui/Icon";

export const ModificationRaw: React.FC<{
  modifications: NEA.NonEmptyArray<AssetModification>;
}> = (props) => {
  const handleRawCopy = useCallback(() => {
    copyToClipboard(`${pipe(props.modifications, toCSV)}`);
  }, [props.modifications]);

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <TextareaAutosize
        style={{ width: "100%", padding: "12px", height: 400 }}
        value={pipe(props.modifications, toCSV)}
      />
      <Button
        size="small"
        color="primary"
        onClick={handleRawCopy}
        endIcon={<Icon icon={AtCopyToClipboard} size="16px" />}
      >
        Copy
      </Button>
    </Stack>
  );
};
