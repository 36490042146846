import { Box, styled } from "@mui/material";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Asset } from "lib/at-data/assets/Asset";
import * as D from "lib/at-data/DataSeries";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import React from "react";
import { Timeline } from "views/authenticated/activity/page/components/navigator/FineTimeline";
import { DESIRED_BAR_SIZE } from "views/authenticated/activity/page/components/navigator/TimelineRangeCollector";

const ActivityContentTimebarGraph: React.FC<{
  selectedRange: SeriesRequest;
  onSelectedRangeChange: (range: SeriesRequest) => void;
  selectedAsset: O.Option<Asset>;
}> = (props) => {
  return (
    <TimebarGraphContainer>
      {pipe(
        props.selectedAsset,
        O.foldW(
          () => null,
          (asset) => (
            <Timeline
              asset={asset}
              selectedRange={props.selectedRange}
              onSelectedRangeChange={props.onSelectedRangeChange}
              onSelectedSliceChange={(slice) => {}}
              barSize={DESIRED_BAR_SIZE}
            />
          )
        )
      )}
    </TimebarGraphContainer>
  );
};

export default ActivityContentTimebarGraph;

export const TimeScrubberBox = styled(Box)`
  height: 58px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-position: right;
  position: relative;
`;

export const TimebarGraphContainer = styled(Box)`
  position: relative;
`;

export const LoadingBox = styled(Box)`
  color: white;
  font-size: 36px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  bottom: 0;
  z-index: 100;
  background-color: pink;
  opacity: 0.5;
`;
