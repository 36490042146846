import { formatDateRange } from "components/ui/range/DateRangePicker";
import {
  AnalyzeDataL,
  AssetsAnalyzeDataController,
} from "controllers/AssetsAnalayzeDataController/AssetsAnalyzeDataController";
import { toLegacyAsyncData } from "lib/at-data/AsyncData2";
import * as AD2 from "lib/at-data/AsyncData2";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import React, { useMemo } from "react";
import {
  Divider,
  List,
  ListItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Icon, { AtSchedule } from "components/ui/Icon";
import palette from "theme/palette";
import * as UT from "lib/at-data/UtilizationThresholds";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as Tpl from "fp-ts/Tuple";
import { UTILIZATION_THRESHOLDS } from "constants/UTILIZATION_THRESHOLDS";
import GaugeChart from "react-gauge-chart";
import { useController } from "lib/at-react/defineController";
import { getUnderUtilizationCost } from "controllers/AssetsUtilizationController/AssetsUtilizationController";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { format } from "date-fns/fp";
import * as AD from "lib/at-data/AsyncData";
import {
  UtilizationMetrics,
  UnderuseMetrics,
  OccupancyMetrics,
  TargetUtilization,
  CapacityMetrics,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetKeyMetrics";
import { Asset } from "lib/at-data/assets/Asset";
import { getCapacity } from "lib/at-data/assets/getters";
import { CommaNumber, formatNumber } from "lib/formatters/formatNumber";
import { isDefaultCapacity } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/AssetCapacityColumn";

const UtilizationItem: React.FC<{
  color: string;
  text: string;
  threshold: [number, number | string];
  className?: string;
}> = ({ color, text, threshold, className }) => {
  return (
    <ListItem
      sx={{
        display: "flex",
        alignContent: "start",
        alignItems: "center",
        padding: "6px",
        margin: "2px 0",
        borderRadius: "2px",

        color: `${palette.neutral[450]}`,
        "&.active": {
          backgroundColor: `${palette.neutral[600]}`,

          color: `${palette.neutral[100]}`,
        },
      }}
      className={`${className}`}
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          fontSize: "12px",
          lineHeight: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Swatch sx={{ backgroundColor: `${color}` }} /> {text}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          lineHeight: "100%",
        }}
      >
        {threshold[0]}-{threshold[1]}%
      </Typography>
    </ListItem>
  );
};

const UtilizationPatterns: React.FC<{ thresholds: UT.UtilizationThresholds }> =
  (props) => {
    return (
      <List sx={{ width: "100%", backgroundColor: "transparent" }}>
        {pipe(
          props.thresholds,
          UT.sort,
          A.mapWithIndex((i, t) => (
            <UtilizationItem
              key={i}
              color={t.color}
              text={t.legend}
              threshold={pipe(
                t,
                UT.thresholdRange(pipe(props.thresholds, A.lookup(i + 1))),
                Tpl.bimap(
                  O.getOrElse(() => "100+"),
                  O.getOrElse(() => 0)
                )
              )}
            />
          ))
        )}
      </List>
    );
  };

const UtilizationGaugeChart: React.FC<{
  thresholds: UT.UtilizationThresholds;
  percent: number;
}> = React.memo(({ thresholds, percent }) => {
  const sortedThresholds = useMemo(
    () => pipe(thresholds, UT.sort),
    [thresholds]
  );
  const colors = useMemo(
    () =>
      pipe(
        sortedThresholds,
        A.map((t) => t.color)
      ),
    [sortedThresholds]
  );

  const arcsLength = useMemo(
    () =>
      pipe(
        sortedThresholds,
        A.mapWithIndex((i, t) => {
          const thresholdRange = pipe(
            t,
            UT.thresholdRange(pipe(sortedThresholds, A.lookup(i + 1))),
            Tpl.bimap(
              O.getOrElse(() => 100),
              O.getOrElse(() => 0)
            )
          );
          return thresholdRange[1] - thresholdRange[0];
        })
      ),
    [sortedThresholds]
  );

  return (
    <GaugeChart
      id="utilization-gauge"
      percent={percent}
      arcsLength={arcsLength}
      colors={colors}
      hideText={true}
      needleColor={`${palette.neutral[100]}`}
      needleBaseColor={`${palette.neutral[500]}`}
      arcPadding={0.02}
      arcWidth={0.1}
      cornerRadius={1}
    />
  );
});

export const AssetUtilization: React.FC<{
  range: SeriesRequest;
  asset: Asset;
}> = (props) => {
  const [analyzeData] = useController(
    AssetsAnalyzeDataController,
    AnalyzeDataL.get
  );

  const peak = pipe(
    analyzeData,
    AD2.map(flow((_) => _.peakOccupancySlice, getSliceByUUID(props.asset.id))),
    toLegacyAsyncData
  );

  const avgOccupancy = pipe(
    analyzeData,
    AD2.map(flow((_) => _.meanOccupancySlice, getSliceByUUID(props.asset.id))),
    toLegacyAsyncData
  );

  const utilization = pipe(
    analyzeData,
    AD2.map(
      flow(
        (_) => _.meanUtilizationSlice,
        getSliceByUUID(props.asset.id),
        O.flatten
      )
    ),
    toLegacyAsyncData
  );

  const perSqFt = pipe(
    utilization,
    getUnderUtilizationCost
  )({ asset: props.asset });

  return (
    <StyledPanelContent>
      <Stack spacing={1} px={3} py={2}>
        <Typography variant="h4" color={palette.neutral[100]}>
          Key Metrics
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          alignContent={"center"}
          pb={2}
          spacing={1}
        >
          <Icon
            icon={AtSchedule}
            size="16px"
            color={`${palette.neutral[100]}`}
          />
          <Typography variant="subtitle1" color={palette.neutral[100]}>
            {pipe(props.range, formatDateRange)}
          </Typography>
        </Stack>
        <Stack>
          <UtilizationGaugeChart
            thresholds={UTILIZATION_THRESHOLDS}
            percent={pipe(
              utilization,
              AD.getData,
              O.flatten,
              O.filter(Number.isFinite),
              O.map((_) => Math.min(_, 1)),
              O.getOrElseW(() => 0)
            )}
          />

          <UtilizationMetrics
            target={props.asset.targetUtilization}
            average={utilization}
            trend={O.none}
          />
          <UtilizationPatterns thresholds={UTILIZATION_THRESHOLDS} />
        </Stack>
        <TargetUtilization
          target={props.asset.targetUtilization}
          average={utilization}
          trend={O.none}
        />
        <Divider
          sx={{
            backgroundColor: `${palette.neutral[500]}`,
          }}
        />
        <CapacityMetrics
          capacity={pipe(
            props.asset,
            getCapacity,
            O.map((_) => pipe(_, formatNumber(CommaNumber))),
            O.getOrElseW(() => "N/A")
          )}
          isDefault={isDefaultCapacity(props.asset)}
        />

        <Divider
          sx={{
            backgroundColor: `${palette.neutral[500]}`,
          }}
        />
        <OccupancyMetrics peak={peak} average={avgOccupancy} />

        <Divider
          sx={{
            backgroundColor: `${palette.neutral[500]}`,
          }}
        />
        <UnderuseMetrics
          perSqFM={perSqFt}
          timeSelected={AD.PendingData(O.none)}
        />
      </Stack>

      {/*<Stack spacing={1} px={3} py={2}>*/}
      {/*  <Typography variant="h4" color={palette.neutral[100]}>*/}
      {/*    Utilization Rate*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body1" color="textTertiary">*/}
      {/*    The current utilization rate over the last 6 months is growing at a 5%*/}
      {/*    rate per month.*/}
      {/*  </Typography>*/}
      {/*  <Stack sx={{ height: "160px" }} direction="column">*/}
      {/*    <AssetsUtilizationTrendline chartData={utilizationTrendlineData} />*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
    </StyledPanelContent>
  );
};

export default AssetUtilization;

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
const Swatch = styled("span")`
  width: 12px;
  height: 12px;
  margin: auto 0;
  margin-right: 8px;
  display: inline-flex;
  padding: 0;
`;

const AverageUtilization = styled("span")`
  font-size: 36px;
  color: ${palette.neutral[100]};
  text-align: center;
  margin-top: -12px;
`;

const AverageLegend = styled(Typography)`
  font-size: 12px;
  color: ${palette.neutral[450]};
  margin-bottom: 12px;
`;
