import * as D from "io-ts/Decoder";
import * as C from "io-ts/Codec";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { optionFromNullable } from "lib/codecs/optionFromNullable";

export const fromNullable = <A, O>(
  c: C.Codec<unknown, O, A>,
  df: A
): C.Codec<unknown, O, A> =>
  C.make(pipe(c, optionFromNullable, D.map(O.getOrElse(() => df))), c);
