import { ADT } from "ts-adt";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import * as Eq from "fp-ts/Eq";
import * as s from "fp-ts/string";

export type AssetsPageTab = ADT<{
  [ASSET_CONTEXT_TABS.ADMIN]: {};
  [ASSET_CONTEXT_TABS.ASSET]: {};
  [ASSET_CONTEXT_TABS.UTILIZATION]: {};
  [ASSET_CONTEXT_TABS.INSIGHTS]: {};
  [ASSET_CONTEXT_TABS.SCHEDULE]: {};
  [ASSET_CONTEXT_TABS.WEATHER]: {};
  [ASSET_CONTEXT_TABS.SELECTION]: {};
  [ASSET_CONTEXT_TABS.DEFAULT_CAPACITY]: {};
}>;

export const AssetPageTab = (tab: ASSET_CONTEXT_TABS): AssetsPageTab => ({
  _type: tab,
});
export const AssetsPageTabEq = Eq.contramap((at: AssetsPageTab) => at._type)(
  s.Eq
);
