import { AssetAction } from "controllers/AssetsController/Modification";
import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import { makeMemo } from "lib/at-react/collector";
import { reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";

export type AssetActionsControllerState = {
  actions: Array<AssetAction>;
};

export function addAssetActions(actions: Array<AssetAction>) {
  return pipe(
    L.id<AssetActionsControllerState>(),
    L.prop("actions"),
    L.modify((existingActions) => pipe(existingActions, A.concat(actions)))
  );
}

export const clearAssetActions = pipe(
  L.id<AssetActionsControllerState>(),
  L.prop("actions")
).set([]);

export const initialState: AssetActionsControllerState = {
  actions: [],
};

export const AssetActionsControllerStateEq = Eq.eqStrict;

export const AssetActionsController = pipe(
  reactState<AssetActionsControllerState>(
    initialState,
    AssetActionsControllerStateEq
  ),
  makeMemo(Eq.struct({}))
);
