import { AssetOContext } from "contexts/AssetContext";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as R from "fp-ts/Reader";
import * as RT from "fp-ts/ReaderTask";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import { Assets, eqAssets, getByParent } from "lib/at-data/assets/assets";
import { getParentAssetOf } from "lib/at-data/assets/filters";
import { isBuilding, isFloor, isZone } from "lib/at-data/assets/predicates";
import * as Cl from "lib/at-react/collector";

import { withUpstreamLegacyController } from "lib/at-react/legacy";
import { reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { RootAssetsContext } from "views/authenticated/root/controller/state";

export type SelectedAssetHierarchyState = {
  selectedBuilding: O.Option<Asset>;
  selectedBuildingLevels: O.Option<Assets>;
  selectedLevel: O.Option<Asset>;
  selectedLevelAssets: O.Option<Assets>;
  selectedZone: O.Option<Asset>;
};

export const getHierarchyContext = (asset: O.Option<Asset>) =>
  pipe(
    R.Do,
    R.apS("selectedZone", pipe(asset, O.filter(isZone), R.of)),
    R.apS(
      "selectedBuilding",
      pipe(
        asset,
        O.map(getParentAssetOf(isBuilding)),
        O.sequence(R.Monad),
        R.map(O.flatten)
      )
    ),
    R.bindW("selectedBuildingLevels", ({ selectedBuilding }) =>
      pipe(selectedBuilding, O.map(getByParent), O.sequence(R.Monad))
    ),
    R.apS(
      "selectedLevel",
      pipe(
        asset,
        O.map(getParentAssetOf(isFloor)),
        O.sequence(R.Monad),
        R.map(O.flatten)
      )
    ),
    R.bindW("selectedLevelAssets", ({ selectedLevel }) =>
      pipe(selectedLevel, O.map(getByParent), O.sequence(R.Monad))
    ),
    R.compose(R.asks<RootAssetsContext, Assets>((_) => pipe(_.assets, fromAD2)))
  );

export const initialState: SelectedAssetHierarchyState = {
  selectedBuilding: O.none,
  selectedBuildingLevels: O.none,
  selectedLevel: O.none,
  selectedLevelAssets: O.none,
  selectedZone: O.none,
};

export const SelectedAssetHierarchyCollector = pipe(
  reactState<SelectedAssetHierarchyState>(
    initialState,
    Eq.struct({
      selectedBuilding: O.getEq(eqAsset),
      selectedBuildingLevels: O.getEq(eqAssets),
      selectedLevel: O.getEq(eqAsset),
      selectedLevelAssets: O.getEq(eqAssets),
      selectedZone: O.getEq(eqAsset),
    })
  ),
  Cl.effect((dispatch) =>
    pipe(
      RT.asks<AssetOContext, O.Option<Asset>>((_) => _.asset),
      RT.chainReaderKW(getHierarchyContext),
      RT.chainIOK(
        (result) => () =>
          pipe(result, L.id<SelectedAssetHierarchyState>().set, dispatch)
      )
    )
  ),
  withUpstreamLegacyController(AssetsController),
  Cl.makeMemo(
    Eq.struct<AssetOContext>({
      asset: O.getEq(eqAsset),
    })
  )
);
