import * as O from "fp-ts/Option";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { DashboardUser } from "views/authenticated/app/DashboardUser";
import { DashboardSettings } from "models/DashboardSettings";
import * as E from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { Lens } from "monocle-ts";
import {
  LogMessage,
  OccupancySource,
} from "views/authenticated/app/RunningAppState";

export type AppState = {
  authClient: O.Option<Auth0Client>;
  user: O.Option<DashboardUser>;
  accessToken: O.Option<string>;
  wifiAPI: OccupancySource;
  logger: (msg: LogMessage) => void;
};
export const AccessTokenL = pipe(L.id<AppState>(), L.prop("accessToken"));
export const LoggerL = pipe(L.id<AppState>(), L.prop("logger"));
export const AuthClientL = pipe(L.id<AppState>(), L.prop("authClient"));
export const UserL = pipe(L.id<AppState>(), L.prop("user"));
export const WiFiAPIL = pipe(L.id<AppState>(), L.prop("wifiAPI"));

export const dataProviderL = pipe(
  L.id<DashboardUser>(),
  L.prop("dataProvider")
);
export const authOrganizationL = pipe(
  L.id<DashboardUser>(),
  L.prop("authOrganization")
);
