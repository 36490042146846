import {
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import palette from "theme/palette";
import React from "react";
import Icon, {
  AtCloudRain,
  AtPrecipitation,
  AtSchedule,
} from "components/ui/Icon";
import { PinHead } from "./PinHead";

function createData(day: string, icon: number, temp: number, precip: number) {
  return { day, icon, temp, precip };
}

const rows = [
  createData("3:00 PM", 6.0, 64, 40),
  createData("4:00 PM", 9.0, 62, 80),
  createData("5:00 PM", 16.0, 60, 80),
  createData("6:00 PM", 3.7, 59, 60),
  createData("7:00 PM", 16.0, 58, 30),
  createData("8:00 PM", 16.0, 55, 15),
  createData("9:00 PM", 3.7, 52, 15),
  createData("10:00 PM", 16.0, 49, 10),
];

const WeatherTable: React.FC<{}> = () => {
  return (
    <Table size="small" aria-label="a dense table">
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow
            key={row.day}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <StyledTableCell align="left">{row.day}</StyledTableCell>
            <StyledTableCell align="center">
              <Icon icon={AtCloudRain} size="16px" />
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{ color: `${palette.neutral[100]}` }}
            >
              {row.temp}&deg;
            </StyledTableCell>
            <StyledTableCell align="right">
              <Icon icon={AtPrecipitation} size="12px" color={"#999999"} />
              {row.precip}%
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const AssetWeather: React.FC<{}> = () => {
  return (
    <StyledPanelContent>
      {/*<Stack direction="column" alignItems="center" spacing={1} px={3} py={1}>*/}
      {/*  <Typography variant="h4" color={palette.neutral[100]}>*/}
      {/*    Weather*/}
      {/*  </Typography>*/}
      {/*  <Stack*/}
      {/*    direction="row"*/}
      {/*    alignItems="center"*/}
      {/*    alignContent={"center"}*/}
      {/*    pb={2}*/}
      {/*    spacing={1}*/}
      {/*  >*/}
      {/*    <PinHead />*/}
      {/*    <Typography variant="subtitle1" color="textTertiary">*/}
      {/*      NOW*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="subtitle1" color={palette.neutral[100]}>*/}
      {/*      APR 29, 2022, 5:02 PM*/}
      {/*    </Typography>*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}
      <Stack spacing={1} px={3} py={2}>
        <Typography
          component={"span"}
          variant="h4"
          color={palette.neutral[100]}
        >
          Weather
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          alignContent={"center"}
          pb={2}
          spacing={1}
        >
          <PinHead />
          <Now>NOW</Now>
          <Typography
            component={"span"}
            variant="subtitle1"
            color={palette.neutral[100]}
          >
            July 01, 2022
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={0} px={3}>
        <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
          <Typography
            component={"span"}
            variant="h3"
            color={palette.neutral[100]}
            sx={{ fontSize: "38px" }}
          >
            60&deg;
          </Typography>
          <Icon icon={AtCloudRain} size="32px" color={"#22A437"} />
        </Stack>
        <Typography
          component={"span"}
          variant="body1"
          color={palette.neutral[100]}
        >
          Showers
        </Typography>
      </Stack>

      <Stack spacing={0} px={3} pt={4} pb={0}>
        <Typography
          component={"span"}
          variant="subtitle1"
          color="textTertiary"
          pb={1}
        >
          Today
        </Typography>
        <WeatherTable />
      </Stack>
    </StyledPanelContent>
  );
};

export default AssetWeather;

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
const StyledTableRow = styled(TableRow)`
  &:first-of-type {
    border-top: 1px solid rgb(106 110 128 / 20%);
  }
  &:hover td {
    background-color: rgb(255, 255, 255, 0.1);
    color: #ffffff;
    transition: background-color 0s, color 0s;
  }
  & td:first-of-type {
    border-radius: 3px 0 0 3px;
  }
  & td:last-of-type {
    border-radius: 0 3px 3px 0;
  }
`;

const StyledTableCell = styled(TableCell)`
  color: #8e919f;
  padding: 4px 8px;
  border-bottom: 1px solid rgb(106 110 128 / 20%);
  background-color: transparent;
  transition: background-color 0.25s, color 0.25s;
  vertical-align: middle;
`;
const Now = styled(Typography)`
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${palette.neutral[400]};
`;
