import { GranulatedSeriesContext } from "contexts/GranulatedSeriesContext";
import { addMinutes } from "date-fns/fp";
import { eqAssets } from "lib/at-data/assets/assets";
import * as AD2 from "lib/at-data/AsyncData2";
import {
  GranulatedSeriesRequest,
  GranulatedSeriesRequestEq,
} from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import React from "react";
import { initialState } from "./initialState";
import { TimebarState, TimebarSeriesL, TimebarStateEq } from "./state";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as DS from "lib/at-data/DataSeries";
import {
  changeStart,
  SeriesRequest,
  SeriesRequestEq,
} from "lib/at-data/requests/temporal/SeriesRequest";
import {
  getSingleOccupancyCountsSeriesMT,
  getSingleOccupancyCountsSeriesMT2,
} from "views/authenticated/assets/actions/workerActions";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import * as RTE from "fp-ts/ReaderTaskEither";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { defineAppController } from "lib/at-react/defineAppController";
import { RESOLUTION } from "lib/at-data/Resolution";
import { AssetContext, AssetOContext } from "contexts/AssetContext";
import * as Eq from "fp-ts/Eq";
import { SeriesContext } from "contexts/SeriesContext";
import { AppContext } from "contexts/AppContext";
import * as IO from "fp-ts/lib/IO";
import * as TE from "fp-ts/lib/TaskEither";
import { loggingAsyncDataEffect, loggingAsyncEffect } from "lib/logging";
import { clog, noop } from "lib/util";

export const setActivityTimebarState =
  (dispatch: React.Dispatch<React.SetStateAction<TimebarState>>) =>
  (result: AD2.AsyncData2<DS.DataSeries<OccupancyEstimate>>): IO.IO<void> =>
  () => {
    return pipe(result, TimebarSeriesL.set, dispatch);
  };

export const getStartDTCOffset = (start: Date) => (now: Date) =>
  now.getTimezoneOffset() - start.getTimezoneOffset();

export const getNow = RTE.fromIO(() => new Date());

const [component, controller] = defineAppController<
  AssetContext & GranulatedSeriesContext,
  TimebarState
>(
  initialState,
  TimebarStateEq,
  Eq.struct({ asset: eqAsset, series: GranulatedSeriesRequestEq }),
  (dispatch) =>
    pipe(
      RTE.ask<AssetContext & GranulatedSeriesContext>(),
      RTE.chainW(({ series, asset }) =>
        pipe(
          getNow,
          RTE.map(
            flow(
              getStartDTCOffset(series.start),
              clog("DTC offset"),
              (offset) => pipe(series, changeStart(addMinutes(offset)))
            )
          ),
          RTE.chainW(getSingleOccupancyCountsSeriesMT2(asset))
        )
      ),
      RTE.mapLeft((_) => new Error("Error getting timebar data")),
      loggingAsyncDataEffect(
        "Timebar Controller",
        setActivityTimebarState(dispatch)
      )
    )
);

export const TimebarControllerComponent = component;
export const TimebarController = controller;
