import { UUID } from "lib/at-data/UUID";
import { DashboardSettings } from "models/DashboardSettings";
import * as O from "fp-ts/Option";

export enum OccupancySource {
  LEGACY = "legacy_analytics",
  NEW_PROVIDER = "wifi_analytics_v1",
}

export type RunningAppState = {
  accessToken: string;
  dataProvider: UUID;
  wifiAPI: OccupancySource;
  authOrganization: string;
};

export enum AppDomain {
  INITIALIZATION = "initialization",
  APPLICATION = "application",
  AUTHENTICATION = "authentication",
  CONTROLLER = "controller",
  NETWORK = "network",
}

export const initializationLog = (
  level: LogLevel,
  message: string
): LogMessage => ({
  domain: AppDomain.INITIALIZATION,
  timestamp: new Date(),
  level,
  message,
});
export const authenticationLog = (
  level: LogLevel,
  message: string
): LogMessage => ({
  domain: AppDomain.AUTHENTICATION,
  timestamp: new Date(),
  level,
  message,
});

export const controllerLog = (
  level: LogLevel,
  message: string
): LogMessage => ({
  domain: AppDomain.CONTROLLER,
  timestamp: new Date(),
  level,
  message,
});

export enum LogLevel {
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  FATAL,
}

export interface LogMessage {
  timestamp: Date;
  level: LogLevel;
  domain: AppDomain;
  message: string;
}
