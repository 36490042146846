/**
 * Given a selection of asset Ids, fetch their image artifacts
 */
import { union, UUIDSlice } from "lib/at-data/UUIDSlice";
import { ArtifactTypes, LambentArtifact } from "lib/at-data/Artifact";
import { empty, single } from "lib/at-data/Slice";
import * as Eq from "fp-ts/Eq";
import { defineAppController } from "lib/at-react/defineAppController";
import { VisibleAssetsContext } from "controllers/AssetsTableDataController/contexts/VisibleAssetsContext";
import * as RTE from "fp-ts/ReaderTaskEither";
import { flow, pipe } from "fp-ts/function";
import { fetchFloorPlanMeta2 } from "controllers/ActivityMapFloorDiagramController/ActivityMapFloorDiagramController";
import { Asset } from "lib/at-data/assets/Asset";
import * as s from "fp-ts/string";
import * as A from "fp-ts/Array";
import { Assets, eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import React from "react";
import * as L from "monocle-ts/Lens";
import { BackgroundArtifactsL } from "controllers/FloorBackgroundImagesController/lens";
import {
  ImageArtifactsControllerState,
  ImageArtifactsControllerStateEq,
} from "controllers/FloorBackgroundImagesController/state";

export const initialState: ImageArtifactsControllerState = {
  artifacts: {
    [ArtifactTypes.BACKGROUND]: empty(),
  },
};

export type ImageArtifactTypeContext = {
  artifactType: ArtifactTypes;
};

export const getAssetImageArtifactWithGivenType = (asset: Asset) =>
  pipe(
    RTE.asks<ImageArtifactTypeContext, ArtifactTypes>((_) => _.artifactType),
    RTE.chainW(fetchFloorPlanMeta2(asset)),
    RTE.map(single(asset.id))
  );

export const setAssetArtifact =
  (
    dispatch: React.Dispatch<
      React.SetStateAction<ImageArtifactsControllerState>
    >
  ) =>
  (artifacts: UUIDSlice<LambentArtifact>) =>
  () =>
    pipe(BackgroundArtifactsL, L.modify(union(artifacts)), dispatch);

export const getVisibleAssetsImageArtifacts = (
  dispatch: React.Dispatch<React.SetStateAction<ImageArtifactsControllerState>>
) =>
  pipe(
    RTE.asks<VisibleAssetsContext, Assets>((_) => _.visibleAssets),
    RTE.chainW(
      flow(
        A.map(
          flow(
            getAssetImageArtifactWithGivenType,
            RTE.chainIOK(setAssetArtifact(dispatch))
          )
        ),
        A.sequence(RTE.Monad)
      )
    )
  );

export const [component, controller] = defineAppController(
  initialState,
  ImageArtifactsControllerStateEq,
  Eq.struct<VisibleAssetsContext & ImageArtifactTypeContext>({
    artifactType: s.Eq,
    visibleAssets: eqAssetsByUUIDOnly,
  }),
  (dispatch) => getVisibleAssetsImageArtifacts(dispatch)
);

export const ImageArtifactsControllerComponent = component;
export const ImageArtifactsController = controller;
