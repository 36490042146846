import React from "react";
import { Stack, styled, Typography } from "@mui/material";
import Icon from "components/ui/Icon";
import { MdAccessTimeFilled } from "react-icons/md";
import palette from "theme/palette";

export const AssetDetailPreviewKpiIcon: React.FC<{
  label: string;
  value: string | number;
  icon?: boolean;
}> = ({ label, value, icon }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <Now>{label}</Now>
      {icon ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <Icon icon={MdAccessTimeFilled} size="16px" color={"#22A437"} />
          <Typography
            component={"span"}
            variant="body1"
            color={palette.neutral[100]}
            sx={{ lineHeight: "24px" }}
          >
            {value}
          </Typography>
        </Stack>
      ) : (
        <Typography
          component={"span"}
          variant="body1"
          color={palette.neutral[100]}
          sx={{ lineHeight: "24px" }}
        >
          {value} {" ft²"}
        </Typography>
      )}
    </Stack>
  );
};
export const Now = styled(Typography)`
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${palette.neutral[400]};
`;
