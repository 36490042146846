import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import * as ROR from "fp-ts/ReadonlyRecord";
import {
  capacityCell,
  numberCell,
} from "views/authenticated/assets/page/components/AssetsTable/cells";
import * as A from "fp-ts/Array";
import { option } from "io-ts-types";
import * as t from "io-ts";
import * as E from "fp-ts/Either";
import { range } from "views/authenticated/assets/page/components/AssetsTable/Range";
import { formatRange } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/PricePerAreaUnitColumn";
import { formatNumber, UINumber } from "lib/formatters/formatNumber";
import { Asset } from "lib/at-data/assets/Asset";
import * as AA from "fp-ts-std/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import { getCapacity, getTags } from "lib/at-data/assets/getters";
import * as s from "fp-ts/string";
import {
  DefaultCapacityRatioTags,
  DefaultCapacityRatioType,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/DefaultCapacityRatioType";
import * as AD from "lib/at-data/AsyncData";
import { asyncData } from "lib/at-data/AsyncData";
import { sum } from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
import React from "react";
import { clog } from "lib/util";

const noUnit = (v: number) => formatNumber(UINumber)(v);

export const isDefaultCapacity = (asset: Asset) =>
  pipe(
    asset,
    getTags,
    A.elem(s.Eq)(DefaultCapacityRatioTags[DefaultCapacityRatioType.DEFAULT])
  );

export const getCapacityColumnValue = pipe(
  R.ask<AssetRowDataContext>(),
  R.map(({ asset }) => ({
    capacity: asset.capacity,
    isDefault: isDefaultCapacity(asset),
  }))
);

export const AssetCapacityColumn = defineColumn(
  "assetCapacity",
  pipe(
    getCapacityColumnValue,
    R.map((_) => ROR.singleton("assetCapacity", _))
  ),
  R.of(capacityCell),
  //@ts-ignore because of bad types in grid library
  flow(
    readColumnData,
    A.filterMap((r) => {
      const value = ROR.lookup("assetCapacity")(r) as O.Option<{
        capacity: O.Option<number>;
      }>;
      const num = pipe(
        value,
        O.chain(({ capacity }) => capacity)
      );

      return num;
    }),
    NEA.fromArray,
    O.map(flow(AA.sum, formatNumber(UINumber))),
    (value) => (
      <TableHeaderKpi
        label="Capacity"
        hint="The organization-defined maximum occupancy of the space. Can be based on size, expected registration, or type of space. Driver of utilization calculation."
        value={value}
      />
    )
  ),
  R.of({
    defaultFlex: 0.5,
    sort: O.getOrd(N.Ord).compare,
    editable: true,
    rendersInlineEditor: true,
  })
);
