import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";
import {
  AppState,
  authOrganizationL,
  dataProviderL,
  UserL,
} from "views/authenticated/app/controller/state";
import { eqUUID } from "lib/at-data/UUID";
import * as L from "monocle-ts/Lens";
import { RunningAppState } from "views/authenticated/app/RunningAppState";
import * as Eq from "fp-ts/Eq";

export const toMinimalAppState = (appState: AppState) =>
  pipe(
    O.Do,
    O.apS("accessToken", appState.accessToken),
    O.apS(
      "authOrganization",
      pipe(appState, UserL.get, O.map(authOrganizationL.get))
    ),
    O.apS("dataProvider", pipe(appState, UserL.get, O.map(dataProviderL.get))),
    O.apS("wifiAPI", O.some(appState.wifiAPI)),
    O.apS("logger", O.some(appState.logger))
  );

export const appContextEq = Eq.struct<RunningAppState>({
  accessToken: s.Eq,
  dataProvider: eqUUID,
  wifiAPI: s.Eq,
  authOrganization: s.Eq,
});

export const TokenL = pipe(L.id<RunningAppState>(), L.prop("accessToken"));
export const TenantL = pipe(L.id<RunningAppState>(), L.prop("dataProvider"));
