import React from "react";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import { fromSqM, isoSqFt, SqFt, SqM } from "lib/at-data/units/area";
import * as AD from "lib/at-data/AsyncData";
import { Skeleton, Stack } from "@mui/material";
import {
  CommaNumber,
  formatNumber,
  UINumber,
} from "lib/formatters/formatNumber";
import { clog } from "../../../../../../../lib/util";

export const SqFtSpan = (area: SqFt) => <span>{area} ft&sup2;</span>;

export const AssetAreaCell: React.FC<{ value: O.Option<SqM> }> = ({ value }) =>
  pipe(
    value,
    // O.map(
    //   flow(
    //     fromSqM,
    //     isoSqFt.modify(Math.round),
    //     isoSqFt.unwrap,
    //     formatNumber(CommaNumber),
    //     SqFtSpan
    //   )
    // ),
    O.map((_) =>
      pipe(
        _,
        fromSqM,
        isoSqFt.modify(Math.round),
        isoSqFt.unwrap,
        (f) => <span>{pipe(f, formatNumber(CommaNumber))} ft²</span>
        // SqFtSpan
      )
    ),
    O.getOrElse(() => <></>)
  );

export const Pending = () => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Skeleton
      variant={"text"}
      width={24}
      height={16}
      sx={{ borderRadius: 8 }}
    />
  </Stack>
);

export const AssetMeanOccupancyCell: React.FC<{
  value: AD.AsyncData<O.Option<number>>;
}> = ({ value }) =>
  pipe(
    value,
    AD.map(O.map(formatNumber(UINumber))),
    AD.fold(
      flow(
        O.flatten,
        O.fold(
          () => <Pending />,
          (v) => <span>{v}</span>
        )
      ),
      flow(
        O.flatten,
        O.fold(
          () => null,
          (v) => <span>{v}</span>
        )
      )
    )
  );

export const AssetPeakOccupancyCell: React.FC<{
  value: AD.AsyncData<O.Option<number>>;
}> = ({ value }) =>
  pipe(
    value,
    AD.map(O.map(formatNumber(UINumber))),
    AD.fold(
      flow(
        O.flatten,
        O.fold(
          () => <Pending />,
          (v) => <span>{v}</span>
        )
      ),
      flow(
        O.flatten,
        O.fold(
          () => null,
          (v) => <span>{v}</span>
        )
      )
    )
  );
