import { Drawer, DrawerProps } from "@mui/material";
import React from "react";

const ContextualSidebarDrawer: React.FC<DrawerProps> = (props) => {
  return (
    <Drawer variant="persistent" anchor="right" {...props}>
      {props.children}
    </Drawer>
  );
};
export default ContextualSidebarDrawer;
