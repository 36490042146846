import { FormControl, InputBase, InputBaseProps, styled } from "@mui/material";
import Icon from "components/ui/Icon";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import palette from "theme/palette";
import theme from "theme/theme";

const ReportsTableHeaderSearch: React.FC = () => {
  return (
    <FormControl fullWidth>
      <SearchBox htmlFor="table-search">
        <SearchIcon>
          <Icon icon={RiSearchLine} size="16px" />
        </SearchIcon>
        <SearchInput
          id="table-search"
          placeholder="Search"
          inputProps={{ "aria-label": "search reports" }}
        />
      </SearchBox>
    </FormControl>
  );
};

export default ReportsTableHeaderSearch;

const SearchBox = styled("label")`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-right: ${theme.spacing(2)};
  padding-left: calc(16px + ${theme.spacing(2)});
  color: ${palette.neutral[500]};
  background-color: ${palette.neutral[200]};
  border-radius: ${theme.shape.borderRadius}px;
  transition: ${theme.transitions.create(["box-shadow", "color"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};

  &:hover {
    box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
  }

  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 1.5px ${palette.primary[400]};
  }

  & .MuiInputBase-root {
    background: transparent;
    box-shadow: none;
    padding: 0 ${theme.spacing(1)};

    &:hover {
      box-shadow: none;
    }

    &.Mui-focused {
      box-shadow: none;
    }
  }
`;

const SearchIcon = styled("div")`
  position: absolute;
  left: ${theme.spacing(1.5)};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  pointer-events: none;
`;

const SearchInput = styled(InputBase)<InputBaseProps>`
  width: 100%;
  height: 100%;
  color: inherit;
  border-radius: ${theme.shape.borderRadius}px;
  transition: ${theme.transitions.create(["background", "color"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};

  input {
    padding: 0;
    color: ${palette.neutral[700]};
    font-weight: 400;
    font-size: 1.5rem;

    &::placeholder {
      color: ${palette.neutral[500]};
      opacity: 1;
    }
  }
`;
