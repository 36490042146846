export const vars = {
  nav: 56,
  header: 88,
  tabs: 56,
};
export const palette = {
  neutral: {
    100: "#FFFFFF",
    200: "#EFF1F6",
    300: "#E2E4E9",
    400: "#BBBFC9",
    450: "#8E919F",
    500: "#6A6E80",
    600: "#46495E",
    650: "#3b3e52",
    700: "#323548",
    800: "#1D1E2F",
    900: "#0A0E1F",
  },
  primary: {
    100: "#F1F3FF",
    200: "#DBDBFC",
    300: "#B3A9FD",
    400: "#887CDE",
    500: "#685BC7",
    600: "#5635BB",
    700: "#400099",
    800: "#350080",
    900: "#2A0066",
  },
};
