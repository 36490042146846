import React from "react";
import { Organizations } from "./components/spaces/Organizations";
import { Regions } from "./components/spaces/Regions";
import { Buildings } from "./components/spaces/Buildings";
import { Floors } from "./components/spaces/Floors";
import { UI } from "./components/ui/UI";

export const Spaces: React.FC<{}> = (props) => {
  return (
    <>
      {/*<Organizations />*/}
      {/*<Regions />*/}
      <Buildings />
      {/*<Floors />*/}
      {/*<UI />*/}
    </>
  );
};

export default Spaces;
