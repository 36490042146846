import { SeriesContext } from "contexts/SeriesContext";
import { VisibleAssetsContext } from "controllers/AssetsTableDataController/contexts/VisibleAssetsContext";
import * as Eq from "fp-ts/Eq";
import { eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import * as SR from "lib/at-data/requests/temporal/SeriesRequest";
import { union, UUIDSlice } from "lib/at-data/UUIDSlice";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import * as Sl from "lib/at-data/Slice";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as n from "fp-ts/number";
import * as AD from "lib/at-data/AsyncData";
import { AsyncData } from "lib/at-data/AsyncData";
import * as O from "fp-ts/Option";
import React from "react";
import * as RTE from "fp-ts/ReaderTaskEither";
import { batchPeakOccupancyScheduleRequests } from "controllers/AssetsTableDataController/batchPeakOccupancyScheduleRequests";
import { defineAppController } from "lib/at-react/defineAppController";
import { loggingAsyncDataEffectLegacy } from "lib/logging";
import * as IO from "fp-ts/IO";

export interface PeakOccupancySliceState {
  peakOccupancySlice: AsyncData<UUIDSlice<number>>;
}

export const PeakOccupanciesStateEq = Eq.struct<PeakOccupancySliceState>({
  peakOccupancySlice: AD.getEq(Sl.getEq(eqUUID, n.Eq)),
});

export const initialState: PeakOccupancySliceState = {
  peakOccupancySlice: AD.PendingData(O.some(Sl.empty<number, UUID>())),
};

export const PeakOccupanciesL = pipe(
  L.id<PeakOccupancySliceState>(),
  L.prop("peakOccupancySlice")
);
export const getBatchedPeakOccupancies = (
  dispatch: React.Dispatch<React.SetStateAction<PeakOccupancySliceState>>
) =>
  pipe(
    RTE.ask<VisibleAssetsContext & SeriesContext>(),
    RTE.chainW(({ visibleAssets, series }) =>
      pipe(
        batchPeakOccupancyScheduleRequests(
          (v) => () =>
            pipe(PeakOccupanciesL, L.modify(AD.map(union(v))), dispatch)
        )(visibleAssets, series)
      )
    )
  );

const [component, controller] = defineAppController<
  SeriesContext & VisibleAssetsContext,
  PeakOccupancySliceState
>(
  initialState,
  PeakOccupanciesStateEq,
  Eq.struct<SeriesContext & VisibleAssetsContext>({
    series: SR.SeriesRequestEq,
    visibleAssets: eqAssetsByUUIDOnly,
  }),
  (dispatch) =>
    pipe(
      getBatchedPeakOccupancies(dispatch),
      loggingAsyncDataEffectLegacy(
        "Peak Occupancy Slice",
        flow(PeakOccupanciesL.set, dispatch, IO.of)
      )
    )
);

export const AssetsPeakOccupancySliceControllerComponent = component;
export const AssetsPeakOccupancySliceController = controller;
