import { Button, Paper, TextField } from "@mui/material";
import Page from "components/ui/Page";
import { AppController } from "controllers/AppController/AppController";
import {
  addAssetActions,
  AssetActionsController,
} from "controllers/AssetsController/AssetActionsController";
import {
  createAssetWithModifications,
  generateRandomUUID,
  name,
} from "controllers/AssetsController/Modification";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as n from "fp-ts/number";
import * as O from "fp-ts/Option";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as s from "fp-ts/string";
import * as TE from "fp-ts/TaskEither";
import * as C from "io-ts/Codec";
import { handleResponse } from "lib/at-api/assets/getAssets";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import {
  composeCollector,
  effect,
  ask,
  makeMemo,
  make,
  map,
} from "lib/at-react/collector";
import { useController } from "lib/at-react/defineController";
import {
  useCollector,
  useCollectorDispatch,
  useCollectorWithDispatch,
} from "lib/at-react/hooks";
import { localStorageState } from "lib/at-react/state/localStorageState";
import { reactState } from "lib/at-react/state/reactState";
import { option } from "lib/codecs/option";
import { noop } from "lib/util";
import * as L from "monocle-ts/Lens";
import React, { useCallback, useState } from "react";
import {
  TenantL,
  toMinimalAppState,
} from "views/authenticated/app/ReadyAppState";
import {
  BackwardsToDoCollector,
  GiphyCollector,
  ToDoDemonstration1,
  ToDos,
  TotalNumberOfCharactersCollector,
} from "views/authenticated/debug/ToDoDemonstration1";

interface TestState {
  todos: O.Option<Array<string>>;
}

export const TodosModel = C.struct({
  todos: option(C.array(C.string)),
});

const TestStateCollector = pipe(
  localStorageState<TestState, TestState>(
    { todos: O.none },
    TodosModel,
    Eq.struct({
      todos: O.getEq(A.getEq(s.Eq)),
    })
  ),
  makeMemo(Eq.fromEquals(() => true))
);

const TempCollector = pipe(
  reactState<{ a: number }>({ a: 0 }, Eq.struct({ a: n.Eq })),
  effect((dispatch) =>
    pipe(
      RTE.asks<{ todos: O.Option<Array<string>> }, number>(
        flow(
          (_) => _.todos,
          O.map((_) => _.length),
          O.getOrElseW(() => 0)
        )
      ),
      RTE.chainIOK(
        (length) => () =>
          setTimeout(() => {
            console.log("Dispatching effect");
            dispatch((_) => ({
              a: _.a + 1,
            }));
          }, 2000)
      )
    )
  ),
  // withUpstreamCollector(TestStateCollector),
  make
);

const TestEffectCollector = pipe(
  TestStateCollector,
  composeCollector(TempCollector)
);

export const TestAction = () => {
  const [todos, dispatch] = useCollectorWithDispatch(
    TestStateCollector,
    L.prop("todos")
  );
  console.log("Rendering Action");
  const handleClick = useCallback(
    () =>
      pipe(
        L.id<TestState>(),
        L.prop("todos"),
        L.modify(
          flow(
            O.map(A.concat(["todo"])),
            O.alt(() => O.some(A.of("first")))
          )
        ),
        dispatch
      ),
    [dispatch]
  );

  return <Button onClick={handleClick}>Add</Button>;
};

const PrintEffect: React.FC<{}> = (props) => {
  const v = useCollector(TestEffectCollector, L.prop("a"));
  const [todos] = useCollectorWithDispatch(TestStateCollector, L.prop("todos"));

  return (
    <div>
      Effect ran: {v} times. Todos:{" "}
      {pipe(
        todos,
        O.map(A.size),
        O.getOrElseW(() => 0)
      )}
    </div>
  );
};

export const TestChild: React.FC = (props) => {
  const [todos, dispatch] = useCollectorWithDispatch(
    TestStateCollector,
    L.prop("todos")
  );

  console.log("Rendering Todos", todos);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div>
        {pipe(
          todos,
          O.foldW(
            () => <div>No TODOs</div>,
            (ts) =>
              pipe(
                ts,
                A.mapWithIndex((i, todo) => <div key={i}>{todo}</div>)
              )
          )
        )}
      </div>
      <TestEffectCollector.Component context={{}}>
        <PrintEffect />
      </TestEffectCollector.Component>
    </div>
  );
};

export const DebugModule: React.FC = (props) => {
  return (
    <Page>
      <Paper>
        <ToDoDemonstration1 />
        {/*<TestStateCollector.Component*/}
        {/*  context={{ window, localStorageKey: "todo.2" }}*/}
        {/*>*/}
        {/*  <TestAction />*/}
        {/*  <TestChild />*/}
        {/*</TestStateCollector.Component>*/}
        {/*<AssetCreator />*/}
      </Paper>
    </Page>
  );
};

export const AssetCreator: React.FC<{}> = (props) => {
  const [tenantId] = useController(
    AppController,
    flow(toMinimalAppState, O.map(TenantL.get))
  );
  const assetActionsDispatch = useCollectorDispatch(AssetActionsController);

  const handleAddAsset = useCallback(() => {
    const newId = generateRandomUUID();
    pipe(
      tenantId,
      O.fold(
        () => noop(),
        (tid) =>
          pipe(
            createAssetWithModifications(newId, AssetTypes.CAMPUS, [
              name("Moon Region"),
            ]),
            addAssetActions,
            assetActionsDispatch
          )
      )
    );
  }, [assetActionsDispatch, tenantId]);

  return (
    <Paper>
      <div>
        TenantId:
        {pipe(
          tenantId,
          O.getOrElse(() => "N/A")
        )}
      </div>
      <div>
        <Button onClick={handleAddAsset}>Add Asset</Button>
      </div>
    </Paper>
  );
};
