import { styled } from "@mui/material";
import React from "react";
import palette from "theme/palette";

interface IPageHeader {
  /**
   * Pass in the header component that should render
   */
  header: React.ReactElement;
}

const PageHeader: React.FC<IPageHeader> = (props) => {
  return <Header>{props.header || props.children || null}</Header>;
};

export default PageHeader;

const Header = styled("div")`
  background-color: ${palette.neutral[800]};
`;
