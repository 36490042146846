import React, { useCallback, useEffect, useState } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  InheritanceIndicator,
  ModalEditable,
  OptionalProperty,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import {
  HoursOfOperation,
  HoursOfOperationEq,
  isValidHoursOfOperation,
  NineToFiveGrind,
} from "lib/at-data/HoursOfOperation";
import { OperatingHoursProperty } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/OperatingHoursProperty";
import { HoursOfOperationEditor } from "views/authenticated/deploy/components/spaces/DeployFormOperatingHours";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import {
  useCollectorOld,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  LegacyAssetsModificationsController,
  modifyHoursOfOperation,
} from "controllers/AssetsController/AssetsController";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { ById, getAllChildren } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import { HoursOfOperationL } from "lib/at-data/assets/lens";
import { getOperatingHours } from "lib/at-data/assets/getters";
import palette from "theme/palette";
import * as b from "fp-ts/boolean";
import { noop } from "lib/util";
import * as R from "fp-ts/Reader";
import * as A from "fp-ts/Array";
import LabeledCheckbox from "components/ui/LabeledCheckbox";
import * as L from "monocle-ts/Lens";

export const HoursOfOperationDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onSave: (ho: O.Option<HoursOfOperation>, inheritance: boolean) => void;
  hoursOfOperation: O.Option<HoursOfOperation>;
  inheritanceAsset: Asset;
}> = (props) => {
  const [localHO, setLocalHO] = useState(
    pipe(
      props.hoursOfOperation,
      O.getOrElseW(() => NineToFiveGrind)
    )
  );
  const [inheritance, setInheritance] = useState(false);

  useEffect(
    () =>
      setLocalHO(
        pipe(
          props.hoursOfOperation,
          O.getOrElseW(() => NineToFiveGrind)
        )
      ),
    [props.hoursOfOperation]
  );

  return (
    <Dialog
      disableEscapeKeyDown
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack>Hours of Operation</Stack>
          <InheritanceIndicator
            asset={props.inheritanceAsset}
            value={inheritance}
            onChange={setInheritance}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <HoursOfOperationEditor
          hoursOfOperation={localHO}
          onChange={setLocalHO}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={() => props.onSave(O.some(localHO), inheritance)}
          disabled={!isValidHoursOfOperation(localHO)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const HoursOfOperationEditor2: React.FC<{
  value: O.Option<HoursOfOperation>;
  onDone: (value: O.Option<HoursOfOperation>, inheritance: boolean) => void;
  onCancel: () => void;
  inheritanceAsset: Asset;
}> = (props) => {
  return (
    <>
      <HoursOfOperationDialog
        open={true}
        onSave={props.onDone}
        onClose={props.onCancel}
        // onClose={() => setModalOpen(false)}
        hoursOfOperation={props.value}
        inheritanceAsset={props.inheritanceAsset}
      />
    </>
  );
};

export const HoursOfOperationPropertyRow: React.FC<{
  asset: Asset;
  onChange: (ho: HoursOfOperation) => void;
}> = (props) => {
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const rootDispatch = useControllerDispatch(RawAssetsController);

  const allAssetsAD = pipe(
    useCollectorOld(AssetsController, flow(L.prop("assets"))),
    fromAD2
  );

  const handleChangeHoursOfOperation = useCallback(
    (ho: O.Option<HoursOfOperation>, hooChecked: boolean) => {
      assetModificationsDispatch(modifyHoursOfOperation(props.asset, ho));
      rootDispatch(
        pipe(
          ById(props.asset.id),
          updateAsset,
          T.composeLens(HoursOfOperationL),
          T.set(ho)
        )
      );
      pipe(
        hooChecked,
        b.fold(
          () => {
            noop();
          },
          () => {
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a) =>
                  assetModificationsDispatch(modifyHoursOfOperation(a, ho))
                )
              )
            )({ assets: allAssetsAD });
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a2) =>
                  rootDispatch(
                    pipe(
                      ById(a2.id),
                      updateAsset,
                      T.composeLens(HoursOfOperationL),
                      T.set(ho)
                    )
                  )
                )
              )
            )({ assets: allAssetsAD });
          }
        )
      );
    },
    [
      assetModificationsDispatch,
      rootDispatch,
      props.asset,
      modifyHoursOfOperation,
    ]
  );
  return (
    <>
      <TableRow key={"costPerSqMRow"} sx={{ border: 0 }}>
        <TableCell
          component="th"
          scope="row"
          sx={{ borderBottom: 0, width: "100%" }}
        >
          Hours of Operation
        </TableCell>
        <TableCell align="right" sx={{ borderBottom: 0 }}>
          <ModalEditable
            onChange={handleChangeHoursOfOperation}
            value={pipe(props.asset.operationHours)}
            inheritanceAsset={props.asset}
            editor={HoursOfOperationEditor2}
          >
            <OptionalProperty
              value={props.asset.operationHours}
              component={OperatingHoursProperty}
              required={true}
            />
          </ModalEditable>

          {/*</Inheritable>*/}
        </TableCell>
      </TableRow>
    </>
  );
};

export const SmallButton = styled(Button)`
  background-color: transparent;
  padding: 1px 1px 1px 1px;
  font-size: 12px;
  min-width: 0;
  &:hover {
    background-color: transparent;
    color: ${palette.primary[400]};
  }
`;
