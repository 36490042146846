import { OccupancySpreadControllerComponent } from "controllers/OccupancySpreadController/OccupancySpreadController";
import * as A from "fp-ts/lib/Array";
import { useCollector } from "lib/at-react/hooks";
import React from "react";
import PageHeader from "components/ui/PageHeader";
import { flow, pipe } from "fp-ts/function";
import AssetsHeader from "./components/AssetsHeader";
import { getCampuses } from "lib/at-data/assets/filters";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { AssetsTableController } from "controllers/AssetsTableController/AssetsTableController";
import { AssetsL } from "views/authenticated/root/controller/state";
import AssetsTableHeader from "views/authenticated/assets/page/components/AssetsTable/AssetsTableHeader";
import { AssetsTable } from "views/authenticated/assets/page/components/AssetsTable/AssetsTable";
import PageTable from "components/table/PageTable";
import { filterAssetsState } from "controllers/UIStateCollector/utils";
import { AssetPageSidepanel } from "views/authenticated/assets/page/components/AssetPageSidepanel/AssetPageSidepanel";
import * as O from "fp-ts/Option";
import Page from "components/ui/Page";
import { AssetsTableDataControllerComponent } from "controllers/AssetsTableDataController/AssetsTableDataController";
import { ColumnsL } from "controllers/AssetsTableController/lens";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import {
  AssetsFilterL,
  AssetsPageUIStateL,
  SelectedRangeL,
  SelectedSideItemL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { UIStateContextComponent } from "controllers/UIStateContext/UIStateContext";
import { SelectedAssetsControllerComponent } from "controllers/SelectedAssetsController/SelectedAssetsController";
import * as S from "fp-ts/Set";
import { eqUUID } from "lib/at-data/UUID";
import { AssetsAnalyzeDataControllerComponent } from "controllers/AssetsAnalayzeDataController/AssetsAnalyzeDataController";
import {
  VisibleAssetsController,
  VisibleAssetsL,
} from "controllers/VisibleAssetsController/VisibleAssetsController";

const AssetsPage: React.FC<{}> = () => {
  const [selectedSideItem] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(SelectedSideItemL)).get
  );
  const [rootAssetO] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2, getCampuses, A.head)
  );
  const selectedAssetIds = useCollectorOld(
    UIStateController,
    flow(L.prop("state"), L.prop("assetsPage"), L.prop("selectedAssetIds"))
  );

  return (
    <SelectedAssetsControllerComponent
      context={{
        selectedAssetIds: pipe(selectedAssetIds, S.fromArray(eqUUID)),
      }}
    >
      <UIStateContextComponent
        context={{
          selectedSideItem,
          disableSideTabs: false,
        }}
      >
        <Page contextualSidebarOpen={O.isSome(selectedSideItem)}>
          <AssetPageSidepanel />
          <PageHeader header={<AssetsHeader asset={rootAssetO} />} />

          <AssetsTableController.Component context={{}}>
            <TableContainer />
          </AssetsTableController.Component>
        </Page>
      </UIStateContextComponent>
    </SelectedAssetsControllerComponent>
  );
};

export const TableContainer: React.FC<{}> = (props) => {
  const [allAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2)
  );
  const [assetsFilter] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).get
  );
  const [visibleAssets] = useController(
    VisibleAssetsController,
    flow(VisibleAssetsL.get, filterAssetsState(assetsFilter))
  );

  const [selectedRange] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).get
  );
  const columns = useCollector(AssetsTableController, L.composeLens(ColumnsL));

  return (
    <AssetsAnalyzeDataControllerComponent
      context={{ series: selectedRange, visibleAssets }}
    >
      <OccupancySpreadControllerComponent
        context={{ assets: visibleAssets, series: selectedRange }}
      >
        <AssetsTableDataControllerComponent
          context={{
            assets: allAssets,
            visibleAssets,
            columns,
          }}
        >
          <PageTable>
            <AssetsTableHeader />

            <AssetsTable />
          </PageTable>
        </AssetsTableDataControllerComponent>
      </OccupancySpreadControllerComponent>
    </AssetsAnalyzeDataControllerComponent>
  );
};

export default AssetsPage;
