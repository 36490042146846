import * as C from "io-ts/Codec";
import { UUID } from "lib/at-data/UUID";

export const SelectedAssetIdsUIStateModel = C.struct({
  selectedAssetIds: C.array(UUID),
});

export type SelectedAssetIdsUIState = C.TypeOf<
  typeof SelectedAssetIdsUIStateModel
>;
