import { flow, pipe } from "fp-ts/function";
import { not } from "fp-ts/Predicate";
import { Assets } from "lib/at-data/assets/assets";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import { filterAssets } from "lib/at-data/assets/filters";
import { Do } from "fp-ts-contrib/Do";
import { centroid, getCoord, multiPoint } from "@turf/turf";
import { GeoCoord } from "lib/at-data/GeoCoord";
import {
  calculateAffineTransform,
  GeoReference,
  projectPoly,
} from "lib/at-data/assets/models/GeoReferenceModel";
import { Coord, eqCoord } from "lib/at-data/assets/models/Coord";
import { isFloor, isZone } from "lib/at-data/assets/predicates";
import { assetArea } from "lib/at-data/assets/spatial";
import { noop } from "lib/util";
import { UUID } from "lib/at-data/UUID";
import { SqM } from "lib/at-data/units/area";
import { decomposeTSR } from "transformation-matrix";
import {
  getCapacity,
  getGeometry,
  getGeoReference,
  getParentId,
  getPoly,
} from "lib/at-data/assets/getters";

export const getCenter = (poly: Array<GeoCoord>): GeoCoord =>
  pipe(poly, multiPoint, centroid, getCoord) as GeoCoord;

export const isValidPoly = (poly: Array<Coord>) => {
  const first = pipe(poly, NEA.fromArray, O.map(NEA.head));
  const last = pipe(poly, NEA.fromArray, O.map(NEA.last));
  return O.getEq(eqCoord).equals(first, last);
};

export const geoProjectZoneAssets = (allAssets: Assets): Assets => {
  const lookupMap = new Map(
    pipe(
      allAssets,
      A.filter(isFloor),
      A.filterMap((_) =>
        pipe(
          _,
          getGeoReference,
          O.map((gr) => [_.id, gr])
        )
      )
    )
  );
  return pipe(
    allAssets,
    A.map((zone) => {
      const geoReference = pipe(
        zone,
        O.fromPredicate(isFloor),
        O.chain(getGeoReference),
        O.alt(() =>
          pipe(
            zone,
            getParentId,
            O.chain((pid) => O.fromNullable(lookupMap.get(pid)))
          )
        )
      );
      const geometry = pipe(
        zone,
        O.fromPredicate(not(isZone)),
        O.chain(getGeometry),
        O.alt(() =>
          pipe(
            zone,
            getPoly,
            O.filter(isValidPoly),
            projectZonePoly(geoReference)
          )
        )
      );

      return {
        ...zone,
        geometry,
        center: pipe(geometry, O.map(getCenter)),
        geoReference,
        bearing: pipe(
          geoReference,
          O.map(
            flow(
              calculateAffineTransform,
              decomposeTSR,
              (v) => v.rotation.angle
            )
          )
        ),
      };
    })
  );
};

// this assumes zones are the only types of assets with area and that all zones are direct children of floors
export const calculateAssetFieldsFastAndUgly = (allAssets: Assets): Assets => {
  const zoneValues = new Map<UUID, { area: number }>();
  const levelValues = new Map<UUID, { area: number }>();
  const buildingValues = new Map<UUID, { area: number }>();

  allAssets.filter(isZone).forEach((asset) => {
    const zoneValue = zoneValues.get(asset.id) || {
      area: pipe(
        asset,
        assetArea,
        O.getOrElse(() => 0)
      ),
    };

    pipe(
      asset.parent,
      O.fold(noop, (parentId) => {
        const levelValue = levelValues.get(parentId) || {
          capacity: 0,
          area: 0,
        };
        levelValue.area += zoneValue.area;
        levelValues.set(parentId, levelValue);
      })
    );

    zoneValues.set(asset.id, zoneValue);
  });

  pipe(allAssets, filterAssets(isFloor)).forEach((floorAsset) => {
    pipe(
      floorAsset.parent,
      O.fold(noop, (parentId) => {
        const buildingValue = buildingValues.get(parentId) || {
          capacity: 0,
          area: 0,
        };
        const levelValue = levelValues.get(floorAsset.id) || {
          capacity: 0,
          area: 0,
        };
        buildingValue.area += levelValue.area;

        buildingValues.set(parentId, buildingValue);
      })
    );
  });

  // :vomit: /VZ
  const allLookups = new Map(
    (function* () {
      // @ts-ignore
      yield* zoneValues;
      // @ts-ignore
      yield* levelValues;
      // @ts-ignore
      yield* buildingValues;
    })()
  );

  const assetsWithAreas = pipe(
    allAssets,
    A.map((a) => ({
      ...a,
      area: pipe(
        allLookups.get(a.id) as { capacity: number; area: SqM } | undefined,
        O.fromNullable,
        O.map((el) => el.area)
      ),
    }))
  );
  return assetsWithAreas;
};

export const projectZonePoly =
  (geoReference: O.Option<GeoReference>) => (polyO: O.Option<Array<Coord>>) =>
    pipe(
      Do(O.Monad)
        .bind("poly", polyO)
        .bind("gr", geoReference)
        .return(({ poly, gr }) => pipe(poly, projectPoly(gr)))
    );
