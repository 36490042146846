import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { gsap } from "gsap";
import * as O from "fp-ts/Option";
import * as NEA from "fp-ts/NonEmptyArray";
import { Coord, getX, getY } from "lib/at-data/assets/models/Coord";
import * as E from "fp-ts/Either";
import {
  SimplePolygon,
  pointsToEdges,
  toSVG,
  toSVGRect,
  snapToPointFrom,
  snapToHorizontalGuidesFrom,
  snapToVerticalGuidesFrom,
  closestSnap,
} from "lib/at-data/Edge";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import palette from "theme/palette";
import { noop } from "lib/util";
import { aperture } from "fp-ts-std/Array";
import { sequenceT } from "fp-ts/Apply";
import * as R from "fp-ts/Reader";

export const hasTwoPoints = (points: Array<Coord>) => points.length === 2;
export const hasThreePoints = (points: Array<Coord>) => points.length > 2;

export const newPolygonSnappingStrategy = (snaps: Array<Coord>) =>
  pipe(
    sequenceT(R.Applicative)(
      snapToPointFrom(snaps),
      snapToHorizontalGuidesFrom(snaps),
      snapToVerticalGuidesFrom(snaps)
    )
  );

export const AddNewPolygon: React.FC<{
  snaps: Array<Coord>;
  onDone: (points: Array<Coord>) => void;
}> = (props) => {
  const elRef = useRef(null);
  const polygonRef = useRef(null);
  const originRef = useRef(null);

  const [points, setPoints] = useState<Array<Coord>>([]);

  const snaps = pipe(props.snaps, A.concat(points));

  const handleCancel = useCallback(
    (ev) => {
      if (ev.keyCode === 27) {
        setPoints([]);
      }
    },
    [setPoints]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleCancel);

    return () => {
      window.removeEventListener("keydown", handleCancel);
    };
  }, []);
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {}, elRef);
    return () => ctx.revert();
  }, []);

  const handlePointerMove = useCallback(
    (ev: React.MouseEvent<SVGRectElement>) => {
      const cursorPosition = {
        x: ev.nativeEvent.offsetX,
        y: ev.nativeEvent.offsetY,
      };

      const { x, y } = !ev.shiftKey
        ? pipe(
            cursorPosition,
            newPolygonSnappingStrategy(snaps),
            closestSnap(cursorPosition)
          )
        : cursorPosition;

      gsap.to("#newPolygonPointer", 0, {
        x,
        y,
      });

      gsap.to(polygonRef.current, {
        attr: {
          points: pipe(points, A.append({ x, y }), toSVG),
        },
        duration: 0,
      });
      pipe(
        points,
        A.last,
        O.foldW(
          () => {
            gsap.to("#wipEdge", {
              attr: {
                opacity: 1,
              },
              duration: 0,
            });
          },
          (lastPoint) => {
            gsap.to("#wipEdge", {
              duration: 0,
              attr: {
                opacity: 1,
                x1: lastPoint.x,
                y1: lastPoint.y,
                x2: x,
                y2: y,
              },
            });
          }
        )
      );
    },
    [points]
  );

  const handleAddPoint = useCallback(
    (ev: React.MouseEvent<SVGRectElement>) => {
      pipe(
        points,
        O.fromPredicate(hasTwoPoints),
        O.fold(
          () => {
            gsap.to(originRef.current, {
              fill: palette.status.warning.dark,
            });
          },
          () => {
            gsap.to(originRef.current, {
              scale: 1.25,
              duration: 0.5,
              repeat: -1,
              yoyo: true,
            });
          }
        )
      );
      const x = gsap.getProperty("#newPolygonPointer", "x") as number;
      const y = gsap.getProperty("#newPolygonPointer", "y") as number;
      pipe(A.append({ x, y }), setPoints);
    },
    [setPoints, points]
  );
  const handleRemovePoint = useCallback(
    (ev: React.MouseEvent<SVGRectElement>) => {
      ev.preventDefault();
      setPoints(
        flow(
          A.init,
          O.getOrElseW(() => [])
        )
      );
    },
    [setPoints]
  );

  const handleOriginMouseOver = useCallback(
    (ev: React.MouseEvent<SVGCircleElement>) => {
      ev.stopPropagation();
      gsap.to(originRef.current, {
        fill: palette.status.success.dark,
      });
      pipe(
        sequenceT(O.Monad)(pipe(points, A.head), pipe(points, A.last)),
        O.foldW(
          () => {
            gsap.to("#wipEdge", {
              attr: {
                opacity: 1,
              },
              duration: 0,
            });
          },
          ([firstPoint, lastPoint]) => {
            gsap.to("#newPolygonPointer", 0, {
              x: firstPoint.x,
              y: firstPoint.y,
            });
            gsap.to(polygonRef.current, {
              attr: {
                points: pipe(
                  points,
                  A.append({ x: firstPoint.x, y: firstPoint.y }),
                  toSVG
                ),
              },
              duration: 0,
            });
            gsap.to("#wipEdge", {
              duration: 0,
              attr: {
                opacity: 1,
                x1: lastPoint.x,
                y1: lastPoint.y,
                x2: firstPoint.x,
                y2: firstPoint.y,
              },
            });
          }
        )
      );
    },
    [points]
  );
  const handleOriginMouseOut = useCallback(() => {
    gsap.to(originRef.current, {
      fill: palette.status.warning.dark,
    });
  }, []);

  const handleFinalizePolygon = useCallback(() => {
    const x = gsap.getProperty("#newPolygonPointer", "x") as number;
    const y = gsap.getProperty("#newPolygonPointer", "y") as number;
    pipe(
      sequenceT(O.Monad)(
        pipe(points, A.append({ x, y }), O.fromPredicate(hasThreePoints)),
        pipe(points, A.head)
      ),
      O.fold(noop, ([polygonPoints, first]) =>
        pipe(polygonPoints, A.append(first), props.onDone)
      )
    );
  }, [props.onDone, points]);

  return (
    <g ref={elRef}>
      <defs>
        <pattern
          id="diagonalHatch"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
        >
          <path
            d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
            stroke={palette.status.warning.dark}
            strokeWidth={1}
          />
        </pattern>
      </defs>

      <polygon
        ref={polygonRef}
        points={pipe(points, toSVG)}
        fill="url(#diagonalHatch)"
        strokeWidth={0}
        fillOpacity={0.5}
        // fill={palette.primary["300"]}
      />
      {pipe(
        points,
        aperture(2),
        A.mapWithIndex((i, [a, b]) => (
          <line
            id={`newPolygonedge${i}`}
            x1={a.x}
            y1={a.y}
            x2={b.x}
            y2={b.y}
            strokeLinecap="square"
            strokeWidth={"2px"}
            strokeOpacity={1}
            stroke={palette.status.success.dark}
            pointerEvents={"none"}
          />
        ))
      )}
      <line
        id={`wipEdge`}
        opacity={0}
        x1={0}
        y1={0}
        x2={0}
        y2={0}
        strokeLinecap="square"
        strokeWidth={"2px"}
        stroke={palette.status.warning.dark}
      />

      <g id={"newPolygonPointer"}>
        <rect x={-2} y={-2} width={4} height={4} fill={"red"} />
      </g>
      <rect
        cursor={"crosshair"}
        x={0}
        y={0}
        width={"100%"}
        height={"100%"}
        opacity={0}
        id={"surface"}
        onMouseMove={handlePointerMove}
        onClick={handleAddPoint}
        onContextMenu={handleRemovePoint}
      />
      {pipe(
        points,
        A.head,
        O.foldW(
          () => null,
          (origin) => (
            <circle
              onClick={handleFinalizePolygon}
              onMouseMove={handleOriginMouseOver}
              onMouseOut={handleOriginMouseOut}
              ref={originRef}
              cx={origin.x}
              cy={origin.y}
              r={8}
              fill={palette.status.warning.dark}
              stroke={"white"}
              strokeWidth={"2"}
            />
          )
        )
      )}
    </g>
  );
};
