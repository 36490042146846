import * as Cl from "lib/at-react/collector";
import { defineController } from "lib/at-react/defineController";
import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  AuthOrganizationL,
  DashboardSettings,
  saveDashboardSettingsToLocalStorageIO,
} from "models/DashboardSettings";
import {
  AccessTokenL,
  AppState,
  AuthClientL,
  LoggerL,
  UserL,
} from "views/authenticated/app/controller/state";
import { initialState } from "views/authenticated/app/controller/initialState";
import * as Eq from "fp-ts/Eq";
import { eqStrict } from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as E from "fp-ts/Either";
import {
  DashboardUser,
  DashboardUserEq,
  fromAuth0Profile,
} from "views/authenticated/app/DashboardUser";
import * as TE from "fp-ts/TaskEither";
import { normalizeAuth0Profile } from "lib/auth0/Auth0Profile";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";
import { LogMessage } from "views/authenticated/app/RunningAppState";
import { clog, noop } from "lib/util";
import mixpanel from "mixpanel-browser";
import * as IO from "fp-ts/IO";

export const AppStateEq = Eq.struct<AppState>({
  authClient: eqStrict,
  user: O.getEq(DashboardUserEq),
  accessToken: O.getEq(s.Eq),
  wifiAPI: s.Eq,
  logger: Eq.eqStrict,
});

export type Auth0ClientContext = {
  authClient: Auth0Client;
};

export type DashboardSettingsContext = {
  settings: DashboardSettings;
};

export const getUserProfileTE = (authClient: Auth0Client) =>
  pipe(
    TE.tryCatch(() => authClient.getUser(), E.toError),
    TE.chainEitherK(flow(normalizeAuth0Profile, fromAuth0Profile))
  );

const getAccessTokenTE = (authClient: Auth0Client) =>
  pipe(TE.tryCatch(() => authClient.getTokenSilently(), E.toError));

export const getUserProfile = pipe(
  RTE.asks<Auth0ClientContext, Auth0Client>((_) => _.authClient),
  RTE.chainTaskEitherK(getUserProfileTE)
);

export const getAccessToken = pipe(
  RTE.asks<Auth0ClientContext, Auth0Client>((_) => _.authClient),
  RTE.chainTaskEitherK(getAccessTokenTE)
);

export const getLogger = pipe(
  RTE.ask<LoggerContext>(),
  RTE.map((_) => _.logger)
);

export const saveUserOrganizationToLocalStorage = (user: DashboardUser) =>
  pipe(
    RTE.asks<DashboardSettingsContext, DashboardSettings>((_) => _.settings),
    RTE.map(flow(AuthOrganizationL.set(O.some(user.authOrganization)))),
    RTE.chainIOK(saveDashboardSettingsToLocalStorageIO)
  );

export type LoggerContext = { logger: (msg: LogMessage) => void };

export const identifyInMixpanel = (
  user: Pick<DashboardUser, "email" | "authOrganization">
) =>
  pipe(
    user.email,
    O.foldW(noop, (email) => {
      mixpanel.identify(email);
      mixpanel.add_group("organization", user.authOrganization);
    })
  );

const [component, controller] = defineController<
  Auth0ClientContext & DashboardSettingsContext & LoggerContext,
  AppState
>(
  initialState,
  AppStateEq,
  Eq.struct({
    authClient: eqStrict,
    settings: eqStrict,
    logger: eqStrict,
  }),
  (dispatch) =>
    pipe(
      pipe(
        getAccessToken,
        RTE.chainIOK(
          (token) => () => pipe(token, O.some, AccessTokenL.set, dispatch)
        )
      ),
      RTE.apFirstW(
        pipe(
          getLogger,
          RTE.chainIOK((logger) => () => pipe(logger, LoggerL.set, dispatch))
        )
      ),
      RTE.apFirstW(
        pipe(
          getUserProfile,
          RTE.chainFirstIOK((dashboardUser) => () => {
            identifyInMixpanel(dashboardUser);
            pipe(dashboardUser, O.some, UserL.set, dispatch);
          }),
          RTE.chainW(saveUserOrganizationToLocalStorage)
        )
      ),
      RTE.chainW(() =>
        pipe(
          RTE.asks<Auth0ClientContext, Auth0Client>((_) => _.authClient),
          RTE.chainIOK(
            (authClient) => () =>
              pipe(authClient, O.some, AuthClientL.set, dispatch)
          )
        )
      ),
      RTE.chainIOK(
        IO.of(() => {
          mixpanel.track("Dashboard Application Started");
        })
      )
    )
);

// For now this just mirrors the App Controller,
// but once all controllers become collectors, state should be moved here
export const AppCollector = pipe(Cl.ask<AppState>(initialState), Cl.make);

export const AppController = controller;
export const AppControllerComponent = component;
