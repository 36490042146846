const palette = {
  neutral: {
    100: "#FFFFFF",
    200: "#EFF1F6",
    300: "#E2E4E9",
    400: "#BBBFC9",
    450: "#8E919F",
    500: "#6A6E80",
    600: "#46495E",
    650: "#3b3e52",
    700: "#323548",
    800: "#1D1E2F",
    900: "#0A0E1F",
  },
  primary: {
    100: "#F1F3FF",
    200: "#DBDBFC",
    300: "#B3A9FD",
    400: "#887CDE",
    500: "#685BC7",
    600: "#5635BB",
    700: "#400099",
    800: "#350080",
    900: "#2A0066",
  },
  text: {
    primary: "#0A0E1F",
    secondary: "#6A6E80",
    tertiary: "#6A6E80",
  },
  status: {
    error: {
      light: "#FCE8E7",
      default: "#ED3B3B",
      dark: "#EC1824",
    },
    warning: {
      light: "#FFF3E1",
      default: "#F59B00",
      dark: "#E18B00",
    },
    success: {
      light: "#E5F9ED",
      default: "#22A437",
      dark: "#068923",
    },
  },
  data: {
    meter: {
      first: "#4a96ec",
      second: "#02bbd3",
      third: "#79c22a",
      fourth: "#f59b00",
      fifth: "#ec1824",
    },
    sequential: {
      0: "rgba(33,102,172,0)",
      200: "rgb(103,169,207)",
      400: "rgb(209,229,240)",
      600: "rgb(253,219,199)",
      800: "rgb(239,138,98)",
      1000: "rgb(178,24,43)",
      1100: "rgba(178,24,43, 0.5)",
    },
    categorical: {
      1: {
        light: "#FF86F3",
        dark: "#8F3786",
      },
      2: {
        light: "#76EE73",
        dark: "#3B653A",
      },
      3: {
        light: "#FF9457",
        dark: "#98542E",
      },
      4: {
        light: "#94C5FF",
        dark: "#3E6DA5",
      },
      5: {
        light: "#26CEB0",
        dark: "#098879",
      },
      6: {
        light: "#C4D655",
        dark: "#717D26",
      },
      7: {
        light: "#FF7880",
        dark: "#9E5A5E",
      },
      8: {
        light: "#FFDA56",
        dark: "#947814",
      },
    },
  },
  logo: {
    100: "rgba(27,202,211,255)",
    200: "rgba(0,178,227,255)",
    300: "rgba(1,113,206,255)",
    400: "rgb(182, 78, 193)",
    500: "rgb(235,107,187)",
    600: "rgba(229,3,149,255)",
  },
};

export default palette;
