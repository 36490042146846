import { Box, styled } from "@mui/material";
import React from "react";
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";
import palette from "theme/palette";
import { ChartSeries } from "lib/at-data/ChartData";

interface IAssetsHeaderChart {
  /**
   * data of the chart.
   */
  data: ChartSeries;
}

interface ICustomTooltip {
  active: boolean;
  payload: { value: number }[];
  label: string;
}

const dateFormatter = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

const CustomTooltip: React.FC<ICustomTooltip> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        {dateFormatter.format(new Date(label))}
        <br />
        {`Occupancy: ${Math.floor(payload[0].value)}`}
      </StyledTooltip>
    );
  }

  return null;
};
const AssetsHeaderChart: React.FC<IAssetsHeaderChart> = (props) => {
  return (
    <ResponsiveContainer height={100}>
      <AreaChart
        data={props.data}
        margin={{
          top: 60,
          right: 350,
          left: 0,
          bottom: 0,
        }}
      >
        <YAxis dataKey="value" type="number" hide={true} />
        <XAxis dataKey="timestamp" hide={true} />
        <defs>
          <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
            <stop stopColor="#887CDE" offset="0%" />
            <stop stopColor="#440099" offset="100%" />
          </linearGradient>
        </defs>
        {/* NOTE: Original design did not include a tooltip so not sure we will want to include this or not */}
        {/* <Tooltip content={CustomTooltip} />*/}
        <Area
          isAnimationActive={false}
          type="natural"
          dataKey="value"
          stroke="#8884d8"
          fill="url(#gradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AssetsHeaderChart;

const StyledTooltip = styled(Box)`
  background-color: ${palette.primary[100]};
  padding: 3px;
  z-index: 10;
  position: relative;
`;
