import React from "react";
import { Button } from "../../../../components/lsui/Button";
import Icon, { AtAdd } from "components/ui/Icon";
import styled from "styled-components";

export const ButtonGuide: React.FC<{}> = (props) => {
  return (
    <UiDisplay>
      <h1>Design Style Guide</h1>
      {/*<h2>Lambent Spaces</h2>*/}
      <h2>Buttons</h2>
      {/*<div></div>*/}
      <div>
        <div>
          <h3>Primary: Light</h3>
          <h5>Button Basic</h5>
          <Button buttonClass={"primary"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <span>Button</span>
          </Button>
          <h5>Button Icon Left</h5>
          <Button buttonClass={"primary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <h5>Button Icon Right</h5>
          <Button buttonClass={"primary"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Both</h5>
          <Button buttonClass={"primary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Rounded</h5>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <h5>Button Rounded</h5>
          <Button buttonClass={"primary rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"primary rounded"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
        </div>
        <div>
          <h3>Secondary: Light</h3>
          <h5>Button Basic</h5>
          <Button buttonClass={"secondary"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <span>Button</span>
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <span>Button</span>
          </Button>
          <h5>Button Icon Left</h5>
          <Button buttonClass={"secondary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <h5>Button Icon Right</h5>
          <Button buttonClass={"secondary"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Both</h5>
          <Button buttonClass={"secondary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon</h5>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <h5>Button Icon Rounded</h5>
          <Button
            buttonClass={"secondary rounded"}
            containerClass={"contained"}
          >
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button
            buttonClass={"secondary rounded"}
            containerClass={"contained"}
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"secondary rounded"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
        </div>
        <div>
          <h3>Text: Light</h3>
          <h5>Button Basic</h5>
          <Button buttonClass={"text"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <span>Button</span>
          </Button>
          <h5>Button Icon Left</h5>
          <Button buttonClass={"text"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <h5>Button Icon Right</h5>
          <Button buttonClass={"text"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Both</h5>
          <Button buttonClass={"text"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon</h5>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <h5>Button Icon Rounded</h5>
          <Button buttonClass={"text rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"text rounded"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
        </div>
      </div>
      <div style={{ background: "#0A0E1F" }} className={"darkmode"}>
        <div>
          <h3>Primary: Light</h3>
          <h5>Button Basic</h5>
          <Button buttonClass={"primary"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <span>Button</span>
          </Button>
          <h5>Button Icon Left</h5>
          <Button buttonClass={"primary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <h5>Button Icon Right</h5>
          <Button buttonClass={"primary"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Both</h5>
          <Button buttonClass={"primary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Rounded</h5>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button buttonClass={"primary"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <h5>Button Rounded</h5>
          <Button buttonClass={"primary rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"primary rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"primary rounded"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
        </div>
        <div>
          <h3>Secondary: Light</h3>
          <h5>Button Basic</h5>
          <Button buttonClass={"secondary"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <span>Button</span>
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <span>Button</span>
          </Button>
          <h5>Button Icon Left</h5>
          <Button buttonClass={"secondary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <h5>Button Icon Right</h5>
          <Button buttonClass={"secondary"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Both</h5>
          <Button buttonClass={"secondary"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon</h5>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"secondary"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"secondary"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <h5>Button Icon Rounded</h5>
          <Button
            buttonClass={"secondary rounded"}
            containerClass={"contained"}
          >
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button
            buttonClass={"secondary rounded"}
            containerClass={"contained"}
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"secondary rounded"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
        </div>
        <div>
          <h3>Text: Light</h3>
          <h5>Button Basic</h5>
          <Button buttonClass={"text"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <span>Button</span>
          </Button>
          <h5>Button Icon Left</h5>
          <Button buttonClass={"text"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
          </Button>
          <h5>Button Icon Right</h5>
          <Button buttonClass={"text"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon Both</h5>
          <Button buttonClass={"text"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"16"} />
            <span>Button</span>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <h5>Button Icon</h5>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button buttonClass={"text"} containerClass={"contained"} disabled>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <h5>Button Icon Rounded</h5>
          <Button buttonClass={"text rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"16"} />
          </Button>
          <Button buttonClass={"text rounded"} containerClass={"contained"}>
            <Icon icon={AtAdd} size={"24"} />
          </Button>
          <Button
            buttonClass={"text rounded"}
            containerClass={"contained"}
            disabled
          >
            <Icon icon={AtAdd} size={"24"} />
          </Button>
        </div>
      </div>
    </UiDisplay>
  );
};

export default ButtonGuide;

export const UiDisplay = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-content: center;
  padding: 24px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px 0 48px;
    align-content: center;
    justify-content: space-around;
    & > div {
      width: 20%;
      min-width: 200px;
      & h3 {
        text-align: center;
      }
      & h5 {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
  & > h2 {
    width: 100%;
    height: 50px;
    line-height: 100%;
    margin: 0 auto 50px;
    text-align: center;
    font-size: 80px;
  }
  & .darkmode {
    & h3,
    & h5 {
      color: white;
    }
  }
`;

const Code = styled.pre`
  background-color: #1d1e30;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`;
