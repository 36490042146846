import { defineController } from "lib/at-react/defineController";
import * as Eq from "fp-ts/Eq";
import { eqStrict } from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as L from "monocle-ts/Lens";

export type State = {
  shareLinkModalEl: O.Option<HTMLElement>;
};

export const ShareLinkModalEl = pipe(L.id<State>(), L.prop("shareLinkModalEl"));

const [component, controller] = defineController<{}, State>(
  { shareLinkModalEl: O.none },
  Eq.struct({
    shareLinkModalEl: eqStrict,
  }),
  Eq.struct({}),
  (dispatch) => () => () => {}
);

export const ShareLinkControllerComponent = component;
export const ShareLinkController = controller;
