import { Lens } from "monocle-ts";
import * as Eq from "fp-ts/Eq";
import * as AD from "lib/at-data/AsyncData";
import { ChartDataSlice } from "lib/at-data/ChartDataSlice";
import { eqStrict } from "fp-ts/Eq";

export type ActivityLiveOccupancyState = {
  lastOccupancyEstimate: AD.AsyncData<ChartDataSlice>;
};

export const ActivityLiveOccupancyStateEq =
  Eq.struct<ActivityLiveOccupancyState>({
    lastOccupancyEstimate: AD.getEq(eqStrict),
  });

export const LastOccupancyEstimateL =
  Lens.fromProp<ActivityLiveOccupancyState>()("lastOccupancyEstimate");
