import React from "react";
import { Assets } from "lib/at-data/assets/assets";
import * as O from "fp-ts/Option";
import {
  calculateAffineTransform,
  GeoReference,
} from "lib/at-data/assets/models/GeoReferenceModel";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { sequenceT } from "fp-ts/Apply";
import {
  compose,
  decomposeTSR,
  inverse,
  scale,
  toCSS,
  translate,
} from "transformation-matrix";
import { Asset } from "lib/at-data/assets/Asset";
import { LevelDiagram } from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/LevelDiagram";
import {
  WORKSPACE_HEIGHT,
  WORKSPACE_WIDTH,
} from "views/authenticated/lucifer/consts";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import { useCollectorOld } from "lib/at-react/defineController";
import { ImageArtifactsController } from "controllers/FloorBackgroundImagesController/FloorBackgroundImagesController";
import * as L from "monocle-ts/Lens";
import { BackgroundArtifactsL } from "controllers/FloorBackgroundImagesController/lens";
import { ById } from "lib/at-data/assets/filters";
import { not } from "fp-ts/Predicate";
import { getGeoReference } from "lib/at-data/assets/getters";

export const ReferenceLayers: React.FC<{
  level: Asset;
  layers: Assets;
  geoReference: O.Option<GeoReference>;
}> = (props) => {
  const backgroundImageArtifacts = useCollectorOld(
    ImageArtifactsController,
    L.composeLens(BackgroundArtifactsL)
  );

  const image = pipe(backgroundImageArtifacts, getSliceByUUID(props.level.id));
  return (
    <div style={{ opacity: 0.25, position: "relative" }}>
      {pipe(
        props.layers,
        A.filter(flow(getGeoReference, O.isSome)),
        A.filter(not(ById(props.level.id))),
        A.map((layer) => (
          <div
            key={layer.id}
            style={{
              // border: "1px dashed silver",
              // very important to transform regarding 0,0 since our affine transforms assume that
              // but CSS defaults to center
              transformOrigin: "0 0",
              transform: pipe(
                sequenceT(O.Monad)(
                  pipe(props.geoReference, O.map(calculateAffineTransform)),
                  pipe(layer, getGeoReference, O.map(calculateAffineTransform)),
                  image
                ),
                O.map(
                  ([
                    referenceLocalToGeoTransform,
                    layerLocalToGeoTransform,
                    fp,
                  ]) =>
                    compose(
                      translate(
                        WORKSPACE_WIDTH,
                        (WORKSPACE_WIDTH * 3 - WORKSPACE_HEIGHT) / 2
                      ),
                      scale(WORKSPACE_WIDTH / fp.planeWidth),
                      inverse(referenceLocalToGeoTransform),
                      layerLocalToGeoTransform
                    )
                ),
                O.map(toCSS),
                O.getOrElseW(() => "")
              ),
            }}
          >
            <LevelDiagram level={layer} />
          </div>
        ))
      )}
    </div>
  );
};
