export enum AssetTypes {
  CAMPUS = "campus",
  BUILDING = "building",
  FLOOR = "floor",
  ZONE = "zone",
  AREA = "area",
  UNKNOWN = "unknown",
}

export type AssetType = `${AssetTypes}`;
