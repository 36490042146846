import { TableColumnTypes } from "controllers/AssetsTableController/tableColumnTypes";
import * as L from "monocle-ts/Lens";
import { pipe } from "fp-ts/function";
import * as Eq from "fp-ts/Eq";
import * as b from "fp-ts/boolean";
import * as s from "fp-ts/string";
import { TableColumnModel } from "controllers/AssetsTableController/models/TableColumn";
import * as C from "io-ts/Codec";
import * as O from "fp-ts/Option";
import * as D from "io-ts/Decoder";

export enum AssetsFormulaColumnTypes {
  UTILIZATION = "utilization",
}

export const AssetsFormulaColumnTypeModel = D.union(
  D.literal(AssetsFormulaColumnTypes.UTILIZATION)
);

export type AssetsFormulaColumnType = `${AssetsFormulaColumnTypes}`;

export const SeriesFilterPModel = C.struct({
  enabled: C.boolean,
  not: C.boolean,
});

export type SeriesFilterP = C.TypeOf<typeof SeriesFilterPModel>;

export const SeriesFilterPEq = Eq.struct<SeriesFilterP>({
  enabled: b.Eq,
  not: b.Eq,
});

export const SeriesFilterEnabledL = pipe(
  L.id<SeriesFilterP>(),
  L.prop("enabled")
);
export const SeriesFilterNotL = pipe(L.id<SeriesFilterP>(), L.prop("not"));

export const SeriesFilterModel = C.struct({
  whenBooked: SeriesFilterPModel,
  whenOccupied: SeriesFilterPModel,
  withinOperationHours: SeriesFilterPModel,
});

export type SeriesFilter = C.TypeOf<typeof SeriesFilterModel>;

export const SeriesFilterEq = Eq.struct<SeriesFilter>({
  withinOperationHours: SeriesFilterPEq,
  whenOccupied: SeriesFilterPEq,
  whenBooked: SeriesFilterPEq,
});

const SeriesFilterId = L.id<SeriesFilter>();

export const WhenBookedL = pipe(SeriesFilterId, L.prop("whenBooked"));
export const WhenOccupiedL = pipe(SeriesFilterId, L.prop("whenOccupied"));
export const WithinOperationHoursL = pipe(
  SeriesFilterId,
  L.prop("withinOperationHours")
);

export const AssetsFormulaColumnModel = pipe(
  TableColumnModel,
  C.intersect(
    C.struct({
      seriesFilter: SeriesFilterModel,
      sortBy: C.string,
      type: C.fromDecoder(AssetsFormulaColumnTypeModel),
      _type: C.fromDecoder(D.literal(TableColumnTypes.FORMULA)),
    })
  )
);

export type AssetsFormulaColumn = C.TypeOf<typeof AssetsFormulaColumnModel>;

export const AssetsFormulaColumnEq = Eq.struct<AssetsFormulaColumn>({
  name: s.Eq,
  seriesFilter: SeriesFilterEq,
  sortBy: s.Eq,
  type: s.Eq,
  _type: s.Eq,
});

export const SeriesFilterL = pipe(
  L.id<AssetsFormulaColumn>(),
  L.prop("seriesFilter")
);

export const SortByL = pipe(L.id<AssetsFormulaColumn>(), L.prop("sortBy"));
