import * as C from "io-ts/Codec";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import { getDay, getHours, getMinutes } from "date-fns/fp";
import { HasTimestampDate } from "lib/at-data/DataSlice";
import * as Eq from "fp-ts/Eq";
import * as n from "fp-ts/number";
import * as s from "fp-ts/string";
import * as A from "fp-ts/Array";
import { makeMatchers } from "ts-adt/MakeADT";
import * as L from "monocle-ts/Lens";
import * as b from "fp-ts/boolean";
import { clog } from "../util";
import { not } from "fp-ts/Predicate";

export const BasicDayScheduleModel = C.struct({
  open: C.number,
  close: C.number,
});

export type BasicDaySchedule = C.TypeOf<typeof BasicDayScheduleModel>;

export const isClosed = (bd: BasicDaySchedule) => bd.close <= bd.open;

export const OtterDaysSchedule = pipe(
  BasicDayScheduleModel,
  optionFromNullable,
  C.imap(
    O.filter(not(isClosed)),
    O.alt(() => O.some({ open: 0, close: 0 }))
  )
);

export const BasicDayScheduleEq = Eq.struct<BasicDaySchedule>({
  open: n.Eq,
  close: n.Eq,
});

export const openHourL = pipe(L.id<BasicDaySchedule>(), L.prop("open"));
export const closeHourL = pipe(L.id<BasicDaySchedule>(), L.prop("close"));

export const BasicScheduleModel = C.struct({
  monday: OtterDaysSchedule,
  tuesday: OtterDaysSchedule,
  wednesday: OtterDaysSchedule,
  thursday: OtterDaysSchedule,
  friday: OtterDaysSchedule,
  saturday: OtterDaysSchedule,
  sunday: OtterDaysSchedule,
});
export type BasicSchedule = C.TypeOf<typeof BasicScheduleModel>;

export const HoursOfOperationModel = C.sum("scheduleType")({
  Basic: C.struct({
    scheduleType: C.literal("Basic"),
    schedule: BasicScheduleModel,
  }),
});

export type HoursOfOperation = C.TypeOf<typeof HoursOfOperationModel>;

const [match] = makeMatchers("scheduleType");

export const toBasicSchedule = (ho: HoursOfOperation) =>
  pipe(
    ho,
    match({
      Basic: (_) =>
        pipe(
          _.schedule,
          // (sch) => ({
          //   monday: getHoursOnly(sch.monday),
          //   tuesday: getHoursOnly(sch.tuesday),
          //   wednesday: getHoursOnly(sch.wednesday),
          //   thursday: getHoursOnly(sch.thursday),
          //   friday: getHoursOnly(sch.friday),
          //   saturday: getHoursOnly(sch.saturday),
          //   sunday: getHoursOnly(sch.sunday),
          // }),
          BasicScheduleModel.encode
        ),
    })
  );

export const HoursOfOperationEq = Eq.struct<HoursOfOperation>({
  schedule: Eq.struct({
    sunday: O.getEq(BasicDayScheduleEq),
    monday: O.getEq(BasicDayScheduleEq),
    tuesday: O.getEq(BasicDayScheduleEq),
    wednesday: O.getEq(BasicDayScheduleEq),
    thursday: O.getEq(BasicDayScheduleEq),
    friday: O.getEq(BasicDayScheduleEq),
    saturday: O.getEq(BasicDayScheduleEq),
  }),
  scheduleType: s.Eq,
});

export const getDaySchedules = (ha: HoursOfOperation) => [
  ha.schedule.monday,
  ha.schedule.tuesday,
  ha.schedule.wednesday,
  ha.schedule.thursday,
  ha.schedule.friday,
  ha.schedule.saturday,
  ha.schedule.sunday,
];

/**
 * Given hours of operation, see if we can the schedule is same across days and can be simplified
 * @param ha
 */
export const simplify = (
  ha: HoursOfOperation
): O.Option<Array<BasicDaySchedule>> =>
  pipe(
    ha,
    getDaySchedules,
    A.filter(O.isSome),
    A.uniq(O.getEq(BasicDayScheduleEq)),
    A.sequence(O.Monad)
  );

export const foldToSinglePeriod = (ha: HoursOfOperation) =>
  pipe(
    ha,
    simplify,
    O.filter((_) => _.length === 1),
    O.chain(A.head)
  );

export const NineToFiveGrind: HoursOfOperation = {
  scheduleType: "Basic",
  schedule: {
    sunday: O.some({
      open: 900,
      close: 1700,
    }),
    monday: O.some({
      open: 900,
      close: 1700,
    }),
    tuesday: O.some({
      open: 900,
      close: 1700,
    }),
    wednesday: O.some({
      open: 900,
      close: 1700,
    }),
    thursday: O.some({
      open: 900,
      close: 1700,
    }),
    friday: O.some({
      open: 900,
      close: 1700,
    }),
    saturday: O.some({
      open: 900,
      close: 1700,
    }),
  },
};
export const Purdue: HoursOfOperation = {
  scheduleType: "Basic",
  schedule: {
    sunday: O.some({
      open: 700,
      close: 1845,
    }),
    monday: O.some({
      open: 715,
      close: 815,
    }),
    tuesday: O.some({
      open: 700,
      close: 1800,
    }),
    wednesday: O.some({
      open: 700,
      close: 1800,
    }),
    thursday: O.some({
      open: 700,
      close: 1845,
    }),
    friday: O.some({
      open: 700,
      close: 1800,
    }),
    saturday: O.none,
  },
};

export const getDaySchedule =
  (timestamp: Date) => (hoursOfOperation: HoursOfOperation) =>
    pipe(timestamp, getDay, (_) => {
      switch (_) {
        case 0:
          return hoursOfOperation.schedule.sunday;
        case 1:
          return hoursOfOperation.schedule.monday;
        case 2:
          return hoursOfOperation.schedule.tuesday;
        case 3:
          return hoursOfOperation.schedule.wednesday;
        case 4:
          return hoursOfOperation.schedule.thursday;
        case 5:
          return hoursOfOperation.schedule.friday;
        case 6:
          return hoursOfOperation.schedule.saturday;
        default:
          return hoursOfOperation.schedule.monday;
      }
    });

export const dateToHourMinute = (d: Date) => {
  const hours = pipe(d, getHours);
  const minutes = pipe(d, getMinutes);

  return hours * 100 + minutes;
};

export const getHourOnly = (hm: number) => Math.floor(hm / 100);

export const setHour = (hour: number) => (hm: number) => {
  const minutes = pipe(hm, getMinuteOnly);
  return (
    hour * 100 +
    pipe(
      minutes,
      O.getOrElse(() => 0)
    )
  );
};
export const setMinute = (minutes: number) => (hm: number) => {
  const hours = pipe(hm, getHourOnly);
  return hours * 100 + minutes;
};

const isMinute = (m: number) => m >= 0 && m < 60;

export const getMinuteOnly = (hm: number): O.Option<number> => {
  const hour = pipe(hm, getHourOnly);
  return pipe(hm - hour * 100, O.of, O.filter(isMinute));
};

export const getAMPMHour = (hm: number) => hm % 12;

export const hourMinuteToDate = (hourMinute: number) => {
  const hour = Math.floor(hourMinute / 100);
  const minute = hourMinute - hour * 100;
  return new Date(0, 0, 0, hour, minute);
};

export const setPM = (timeOfDay: boolean) => (hm: number) => {
  return pipe(
    timeOfDay,
    b.fold(
      () => (hm >= 1200 ? hm - 1200 : hm),
      () => (hm >= 1200 ? hm : hm + 1200)
    )
  );
};

export const isValidTimeRange = (daySchedule: O.Option<BasicDaySchedule>) => {
  return pipe(
    daySchedule,
    O.map(({ open, close }) => open < close),
    O.getOrElse(() => true)
  );
};

export const isValidHoursOfOperation = (basicSchedule: HoursOfOperation) => {
  return (
    isValidTimeRange(basicSchedule.schedule.sunday) &&
    isValidTimeRange(basicSchedule.schedule.monday) &&
    isValidTimeRange(basicSchedule.schedule.tuesday) &&
    isValidTimeRange(basicSchedule.schedule.wednesday) &&
    isValidTimeRange(basicSchedule.schedule.thursday) &&
    isValidTimeRange(basicSchedule.schedule.friday) &&
    isValidTimeRange(basicSchedule.schedule.saturday) &&
    isValidTimeRange(basicSchedule.schedule.sunday)
  );
};

export const setToClosed = (daySchedule: O.Option<BasicDaySchedule>) => O.none;

/**
 * note that open close are values like 1830 1030, etc
 * @param hoursOfOperation
 */

export const withinOperationHours =
  <T extends HasTimestampDate>(hoursOfOperation: HoursOfOperation) =>
  ({ timestamp }: T): boolean =>
    pipe(timestamp, dateToHourMinute, (hourAndMinute) =>
      pipe(
        hoursOfOperation,
        getDaySchedule(timestamp),
        O.filter(
          (daySchedule) =>
            hourAndMinute >= daySchedule.open &&
            hourAndMinute < daySchedule.close
        ),
        O.isSome
      )
    );

export const getHoursOnly = (
  bd: O.Option<BasicDaySchedule>
): O.Option<BasicDaySchedule> =>
  pipe(
    bd,
    O.map((_) => ({
      open: getHourOnly(_.open),
      close: getHourOnly(_.close),
    }))
  );

export const getStartHour = (bd: O.Option<BasicDaySchedule>) =>
  pipe(
    bd,
    O.map(flow((_) => _.open, getHourOnly)),
    O.getOrElse(() => 0)
  );
export const getEndHour = (bd: O.Option<BasicDaySchedule>) =>
  pipe(
    bd,
    O.map(flow((_) => _.close, getHourOnly)),
    O.getOrElse(() => 0)
  );

export const toAPIShape = (ho: HoursOfOperation) => ({
  startHour: 0,
  endHour: 0,
  mondayStartHour: getStartHour(ho.schedule.monday),
  mondayEndHour: getEndHour(ho.schedule.monday),
  tuesdayStartHour: getStartHour(ho.schedule.tuesday),
  tuesdayEndHour: getEndHour(ho.schedule.tuesday),
  wednesdayStartHour: getStartHour(ho.schedule.wednesday),
  wednesdayEndHour: getEndHour(ho.schedule.wednesday),
  thursdayStartHour: getStartHour(ho.schedule.thursday),
  thursdayEndHour: getEndHour(ho.schedule.thursday),
  fridayStartHour: getStartHour(ho.schedule.friday),
  fridayEndHour: getEndHour(ho.schedule.friday),
  saturdayStartHour: getStartHour(ho.schedule.saturday),
  saturdayEndHour: getEndHour(ho.schedule.saturday),
  sundayStartHour: getStartHour(ho.schedule.sunday),
  sundayEndHour: getEndHour(ho.schedule.sunday),
});
