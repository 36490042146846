import { User } from "@auth0/auth0-spa-js";

export const AUTH0_NAMESPACE = "https://api.armoredthings.com/";
export const LAMBENT_NAMESPACE = "https://lambent.app/";

export interface Auth0Profile {
  dataProvider: unknown;
  authOrganization: unknown;
  featureFlags: unknown;
}

export const normalizeAuth0Profile = (
  profile: User | undefined
): Auth0Profile & User => ({
  dataProvider: profile?.[`${AUTH0_NAMESPACE}atDataProvider`],
  featureFlags: profile?.[`${LAMBENT_NAMESPACE}featureFlags`],
  authOrganization: profile?.org_id,
  ...profile,
});
