import { styled } from "@mui/material";
import React from "react";
import { ReportType } from "./reportsTable/ReportType";

// Need to add cases for each report type:
const ReportPlaceholderImage: React.FC<{ reportType: ReportType }> = ({
  reportType,
}) => {
  switch (reportType) {
    case ReportType.RVO_REPORT:
      return (
        <Placeholder
          alt={"Placeholder Bar Chart"}
          src="/images/bar-chart.svg"
        />
      );
    case ReportType.OTHER_REPORT:
      return (
        <Placeholder
          alt={"Placeholder Waterfall Chart"}
          src="./images/waterfall-chart.svg"
        />
      );
    case ReportType.HOURLY_EXPORT:
      return (
        <Placeholder
          alt={"Placeholder Line Graph"}
          src="/images/line-chart.svg"
        />
      );
    case ReportType.GRAPH_PACKS:
      return (
        <Placeholder
          alt={"Placeholder Scatter Plot"}
          src="./images/scatter-plot.svg"
        />
      );

    // case "stacked-area":
    //   return (
    //     <Placeholder
    //       alt={alt || "Placeholder Stacked Area Chart"}
    //       src="./images/stacked-area-chart.svg"
    //     />
    //   );
    // case "heatmap":
    //   return (
    //     <Placeholder
    //       alt={alt || "Placeholder Heatmap"}
    //       src="/images/heatmap.svg"
    //     />
    //   );
    // case "horizontal-stacked-bar":
    //   return (
    //     <Placeholder
    //       alt={alt || "Placeholder Horizontal Stacked Bar Chart"}
    //       src="/images/horizontal-bar.svg"
    //     />
    //   );

    default:
      return null;
  }
};

export default ReportPlaceholderImage;

const Placeholder = styled("img")`
  object-fit: cover;
  width: 100%;
`;
