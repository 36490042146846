import React from "react";
import { styled } from "@mui/material/styles";

interface ILogo {
  wordmark?: boolean | null;
  fill?: React.CSSProperties["color"];
  height?: React.CSSProperties["height"];
}

const Logo: React.FC<ILogo> = ({
  wordmark = false,
  fill = "currentColor",
  height = "30px",
}) => {
  if (wordmark) {
    return (
      <LogoBox height={height} fill={fill}>
        <svg
          width="120"
          height="30"
          viewBox="0 0 120 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4429_489181)">
            <path
              d="M20.3855 0.238281H10.2324V6.38414H20.3855V0.238281Z"
              fill="#E50695"
            />
            <path
              d="M20.3855 24.0938H10.2324V30.2396H20.3855V24.0938Z"
              fill="#0071CE"
            />
            <path
              d="M30.7387 12.1785L25.6621 3.3125L20.3839 6.38543L25.4604 15.2515L30.7387 12.1785Z"
              fill="#1ECAD3"
            />
            <path
              d="M25.6442 27.1707L30.7207 18.3047L25.4425 15.2318L20.3659 24.0978L25.6442 27.1707Z"
              fill="#00B2E3"
            />
            <path
              d="M5.15782 15.2528L10.2344 6.38672L4.95615 3.31379L-0.120406 12.1798L5.15782 15.2528Z"
              fill="#EE6FBB"
            />
            <path
              d="M10.2543 24.0965L5.17773 15.2305L-0.100495 18.3034L4.97606 27.1694L10.2543 24.0965Z"
              fill="#B14FC5"
            />
            <path
              d="M39.5547 17.2885V2.87109H42.2589V14.8718H47.8919V17.2885H39.5547Z"
              fill="white"
            />
            <path
              d="M49.6348 14.3572C49.6348 12.1465 51.2939 11.0531 54.2036 11.0531H56.0266V10.2687C56.0266 9.06999 55.4328 8.36822 54.0397 8.36822C52.8105 8.36822 52.1145 8.98736 51.6027 9.73224L50.0457 8.3275C50.8236 7.08803 52.073 6.26172 54.2238 6.26172C57.1121 6.26172 58.6477 7.6449 58.6477 10.1047V15.1859H59.713V17.2924H58.2594C57.1323 17.2924 56.4364 16.5487 56.2713 15.4338H56.149C55.801 16.817 54.6323 17.5403 53.035 17.5403C50.864 17.5379 49.6348 16.2781 49.6348 14.3572ZM56.0254 14.0686V12.6435H54.3449C52.9732 12.6435 52.2974 13.1177 52.2974 13.9656V14.3165C52.2974 15.1835 52.8913 15.617 53.915 15.617C55.0836 15.617 56.0254 15.0805 56.0254 14.0686Z"
              fill="white"
            />
            <path
              d="M61.6406 17.2885V6.50571H64.2629V8.30324H64.3651C64.776 7.16676 65.6358 6.25781 67.2332 6.25781C68.6869 6.25781 69.8139 6.98114 70.3258 8.42779H70.3674C70.7557 7.22903 71.9446 6.25781 73.6441 6.25781C75.7331 6.25781 76.9219 7.78709 76.9219 10.4708V17.2873H74.2996V10.7403C74.2996 9.19183 73.7462 8.42779 72.5788 8.42779C71.5538 8.42779 70.5907 9.00621 70.5907 10.163V17.2897H67.9695V10.7403C67.9695 9.19183 67.4173 8.42779 66.2487 8.42779C65.2439 8.42779 64.2617 9.00621 64.2617 10.163V17.2897H61.6406V17.2885Z"
              fill="white"
            />
            <path
              d="M79.3398 2.00391H81.9621V8.2839H82.0441C82.4325 7.04442 83.5797 6.26002 84.9942 6.26002C87.6984 6.26002 89.1937 8.28389 89.1937 11.8778C89.1937 15.492 87.6984 17.5374 84.9942 17.5374C83.5809 17.5374 82.4538 16.7123 82.0441 15.492H81.9621V17.2895H79.3398V2.00391ZM86.4467 12.8286V10.97C86.4467 9.44073 85.5049 8.43 84.1533 8.43C82.9039 8.43 81.961 9.11141 81.961 10.1653V13.5939C81.961 14.7088 82.9028 15.3495 84.1533 15.3495C85.5049 15.3483 86.4467 14.3567 86.4467 12.8286Z"
              fill="white"
            />
            <path
              d="M90.709 11.8795C90.709 8.47121 92.532 6.26172 95.6662 6.26172C99.0058 6.26172 100.541 8.74066 100.541 11.695V12.5621H93.4334V12.8303C93.4334 14.3788 94.3146 15.4326 96.0343 15.4326C97.3241 15.4326 98.1032 14.8135 98.7588 13.9668L100.172 15.5572C99.2908 16.7763 97.7339 17.5391 95.7469 17.5391C92.5938 17.5391 90.709 15.3284 90.709 11.8795ZM93.4346 10.7645V10.9298H97.8182V10.7442C97.8182 9.25681 97.0593 8.24487 95.7077 8.24487C94.3348 8.24487 93.4346 9.27717 93.4346 10.7645Z"
              fill="white"
            />
            <path
              d="M102.652 17.2885V6.50571H105.275V8.30324H105.377C105.808 7.1464 106.708 6.25781 108.367 6.25781C110.56 6.25781 111.789 7.78709 111.789 10.4708V17.2873H109.167V10.7403C109.167 9.21219 108.655 8.42779 107.405 8.42779C106.32 8.42779 105.276 9.00621 105.276 10.163V17.2897H102.652V17.2885Z"
              fill="white"
            />
            <path
              d="M115.065 14.5435V8.61556H113.488V6.50786H114.308C115.086 6.50786 115.331 6.13662 115.331 5.39294V3.55469H117.688V6.50786H119.88V8.61437H117.688V15.183H119.716V17.2895H117.831C116.029 17.2907 115.065 16.2991 115.065 14.5435Z"
              fill="white"
            />
            <path
              d="M39.4434 28.1781L40.3958 27.25C40.9089 27.8535 41.5395 28.1781 42.3103 28.1781C43.1773 28.1781 43.6262 27.7565 43.6262 27.1206C43.6262 26.6141 43.3911 26.3015 42.6108 26.1602L41.9053 26.0524C40.397 25.8153 39.6702 25.092 39.6702 23.8633C39.6702 22.5148 40.707 21.6406 42.3768 21.6406C43.4991 21.6406 44.334 22.0286 44.9326 22.752L43.9694 23.6693C43.606 23.2046 43.093 22.8933 42.3008 22.8933C41.4992 22.8933 41.0811 23.2166 41.0811 23.7771C41.0811 24.3483 41.4018 24.5854 42.1191 24.716L42.8139 24.8453C44.3222 25.1147 45.0276 25.7938 45.0276 27.0344C45.0276 28.4583 44.011 29.4283 42.2248 29.4283C40.9517 29.4295 40.074 28.9218 39.4434 28.1781Z"
              fill="white"
            />
            <path
              d="M48.6836 29.2974V21.7695H52.0422C53.4223 21.7695 54.256 22.7084 54.256 24.0988C54.256 25.5011 53.4211 26.428 52.0422 26.428H50.0957V29.2962H48.6836V29.2974ZM50.0957 25.1993H51.914C52.4591 25.1993 52.7904 24.8976 52.7904 24.3479V23.8521C52.7904 23.3024 52.4591 23.0102 51.914 23.0102H50.0957V25.1993Z"
              fill="white"
            />
            <path
              d="M61.7133 29.2974L61.1148 27.3777H58.4628L57.8749 29.2974H56.4414L58.9438 21.7695H60.6979L63.1789 29.2974H61.7133V29.2974ZM59.8095 23.0641H59.7561L58.8048 26.1586H60.7727L59.8095 23.0641Z"
              fill="white"
            />
            <path
              d="M65.9277 25.5878C65.9277 23.0537 67.1795 21.6406 69.1474 21.6406C70.4847 21.6406 71.3505 22.2119 71.8742 23.3771L70.6652 24.025C70.4514 23.3555 69.9811 22.9028 69.1462 22.9028C68.0975 22.9028 67.4242 23.6681 67.4242 24.9519V26.1386C67.4242 27.4332 68.0975 28.1673 69.1462 28.1673C69.9906 28.1673 70.5156 27.6488 70.7614 26.9698L71.9063 27.65C71.3719 28.7721 70.4835 29.4295 69.1474 29.4295C67.1795 29.4283 65.9277 28.123 65.9277 25.5878Z"
              fill="white"
            />
            <path
              d="M75.3613 29.2974V21.7695H80.2817V23.0306H76.7734V24.8532H79.8755V26.1143H76.7734V28.034H80.2817V29.295H75.3613V29.2974Z"
              fill="white"
            />
            <path
              d="M83.5664 28.1781L84.5177 27.25C85.0308 27.8535 85.6614 28.1781 86.4322 28.1781C87.2991 28.1781 87.7481 27.7565 87.7481 27.1206C87.7481 26.6141 87.5129 26.3015 86.7326 26.1602L86.026 26.0524C84.5177 25.8153 83.7909 25.092 83.7909 23.8633C83.7909 22.5148 84.8277 21.6406 86.4963 21.6406C87.6186 21.6406 88.4535 22.0286 89.0533 22.752L88.0901 23.6693C87.7267 23.2046 87.2124 22.8933 86.4215 22.8933C85.6198 22.8933 85.203 23.2166 85.203 23.7771C85.203 24.3483 85.5236 24.5854 86.2398 24.716L86.9357 24.8453C88.444 25.1147 89.1495 25.7938 89.1495 27.0344C89.1495 28.4583 88.1341 29.4283 86.3478 29.4283C85.0747 29.4295 84.1982 28.9218 83.5664 28.1781Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_4429_489181">
              <rect width="120" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </LogoBox>
    );
  }
  return (
    <LogoBox height={"310px"} fill={fill}>
      <svg
        width="380"
        height="310"
        viewBox="0 0 380 310"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4431_489207)">
          <path d="M214.384 0H166.352V28.8017H214.384V0Z" fill="#E50695" />
          <path
            d="M214.384 111.789H166.352V140.591H214.384V111.789Z"
            fill="#0071CE"
          />
          <path
            d="M263.364 55.9596L239.348 14.4102L214.377 28.811L238.394 70.3604L263.364 55.9596Z"
            fill="#1ECAD3"
          />
          <path
            d="M239.257 126.217L263.273 84.668L238.303 70.2671L214.287 111.817L239.257 126.217Z"
            fill="#00B2E3"
          />
          <path
            d="M142.341 70.3697L166.357 28.8203L141.387 14.4195L117.371 55.9689L142.341 70.3697Z"
            fill="#EE6FBB"
          />
          <path
            d="M166.454 111.811L142.438 70.2617L117.467 84.6626L141.483 126.212L166.454 111.811Z"
            fill="#B14FC5"
          />
          <path
            d="M0 248.526V180.961H12.7933V237.201H39.4417V248.526H0Z"
            fill="white"
          />
          <path
            d="M47.6777 234.782C47.6777 224.422 55.5267 219.298 69.292 219.298H77.9164V215.622C77.9164 210.004 75.1071 206.716 68.5167 206.716C62.7015 206.716 59.4091 209.617 56.9875 213.108L49.6217 206.525C53.3018 200.716 59.2125 196.844 69.3875 196.844C83.0517 196.844 90.3163 203.326 90.3163 214.853V238.666H95.3561V248.538H88.4791C83.1472 248.538 79.8547 245.052 79.0738 239.827H78.4951C76.8489 246.31 71.3203 249.699 63.7634 249.699C53.4929 249.688 47.6777 243.784 47.6777 234.782ZM77.9164 233.43V226.751H69.9662C63.4769 226.751 60.28 228.973 60.28 232.947V234.591C60.28 238.654 63.0892 240.686 67.9323 240.686C73.4609 240.686 77.9164 238.172 77.9164 233.43Z"
            fill="white"
          />
          <path
            d="M104.48 248.526V197.994H116.886V206.418H117.369C119.313 201.092 123.381 196.832 130.938 196.832C137.815 196.832 143.147 200.222 145.568 207.001H145.765C147.602 201.384 153.226 196.832 161.266 196.832C171.149 196.832 176.773 203.999 176.773 216.576V248.52H164.368V217.833C164.368 210.576 161.75 206.996 156.227 206.996C151.378 206.996 146.821 209.706 146.821 215.128V248.526H134.416V217.838C134.416 210.582 131.803 207.001 126.275 207.001C121.521 207.001 116.875 209.712 116.875 215.133V248.531H104.48V248.526Z"
            fill="white"
          />
          <path
            d="M188.213 176.891H200.618V206.321H201.006C202.843 200.512 208.271 196.836 214.962 196.836C227.756 196.836 234.829 206.321 234.829 223.163C234.829 240.101 227.756 249.686 214.962 249.686C208.276 249.686 202.945 245.819 201.006 240.101H200.618V248.524H188.213V176.891ZM221.834 227.619V218.909C221.834 211.742 217.378 207.006 210.985 207.006C205.074 207.006 200.613 210.199 200.613 215.138V231.205C200.613 236.43 205.068 239.433 210.985 239.433C217.378 239.427 221.834 234.78 221.834 227.619Z"
            fill="white"
          />
          <path
            d="M241.998 223.163C241.998 207.19 250.622 196.836 265.45 196.836C281.249 196.836 288.513 208.453 288.513 222.298V226.362H254.887V227.619C254.887 234.875 259.056 239.814 267.191 239.814C273.293 239.814 276.979 236.913 280.08 232.945L286.766 240.398C282.597 246.111 275.231 249.686 265.832 249.686C250.915 249.686 241.998 239.331 241.998 223.163ZM254.887 217.938V218.712H275.625V217.842C275.625 210.872 272.034 206.13 265.641 206.13C259.151 206.13 254.887 210.967 254.887 217.938Z"
            fill="white"
          />
          <path
            d="M298.498 248.526V197.994H310.904V206.418H311.387C313.426 200.996 317.685 196.832 325.534 196.832C335.906 196.832 341.721 203.999 341.721 216.576V248.52H329.315V217.833C329.315 210.672 326.894 206.996 320.983 206.996C315.848 206.996 310.909 209.706 310.909 215.128V248.526H298.498Z"
            fill="white"
          />
          <path
            d="M357.229 235.652V207.872H349.768V198H353.644C357.324 198 358.487 196.26 358.487 192.775V184.16H369.634V198H380.006V207.872H369.634V238.654H379.231V248.526H370.314C361.785 248.526 357.229 243.879 357.229 235.652Z"
            fill="white"
          />
          <path
            d="M69.3047 304.139L73.8107 299.79C76.2379 302.618 79.2213 304.139 82.8677 304.139C86.9692 304.139 89.093 302.169 89.093 299.184C89.093 296.81 87.9805 295.345 84.2835 294.683L80.9462 294.177C73.8107 293.066 70.3722 289.676 70.3722 283.918C70.3722 277.599 75.2827 273.508 83.1767 273.508C88.4862 273.508 92.436 275.326 95.2677 278.716L90.7111 283.009C88.9918 280.837 86.5646 279.373 82.8171 279.373C79.0246 279.373 77.0526 280.888 77.0526 283.514C77.0526 286.191 78.5696 287.303 81.9575 287.914L85.2443 288.52C92.3798 289.783 95.7171 292.965 95.7171 298.779C95.7171 305.452 90.9133 309.998 82.4575 309.998C76.4401 309.998 72.2881 307.624 69.3047 304.139Z"
            fill="white"
          />
          <path
            d="M114.141 309.391V274.113H130.03C136.558 274.113 140.503 278.508 140.503 285.029C140.503 291.601 136.553 295.945 130.03 295.945H120.821V309.386H114.141V309.391ZM120.815 290.187H129.417C131.996 290.187 133.564 288.772 133.564 286.196V283.873C133.564 281.297 131.996 279.928 129.417 279.928H120.815V290.187Z"
            fill="white"
          />
          <path
            d="M176.882 309.391L174.05 300.395H161.504L158.723 309.391H151.941L163.78 274.113H172.078L183.815 309.391H176.882ZM167.881 280.18H167.628L163.128 294.688H172.438L167.881 280.18Z"
            fill="white"
          />
          <path
            d="M197.939 292.006C197.939 280.13 203.861 273.508 213.171 273.508C219.498 273.508 223.593 276.185 226.071 281.645L220.352 284.676C219.34 281.544 217.115 279.417 213.166 279.417C208.21 279.417 205.019 283.004 205.019 289.02V294.582C205.019 300.648 208.204 304.083 213.166 304.083C217.166 304.083 219.644 301.653 220.807 298.471L226.223 301.653C223.695 306.912 219.492 309.993 213.166 309.993C203.856 309.998 197.939 303.887 197.939 292.006Z"
            fill="white"
          />
          <path
            d="M243.686 309.391V274.113H266.963V280.023H250.36V288.565H265.036V294.48H250.36V303.476H266.957V309.391H243.686V309.391Z"
            fill="white"
          />
          <path
            d="M283.609 304.139L288.115 299.79C290.543 302.618 293.526 304.139 297.172 304.139C301.274 304.139 303.398 302.169 303.398 299.184C303.398 296.81 302.285 295.345 298.588 294.683L295.251 294.177C288.115 293.066 284.677 289.676 284.677 283.918C284.677 277.599 289.587 273.508 297.481 273.508C302.791 273.508 306.741 275.326 309.572 278.716L305.016 283.009C303.297 280.837 300.869 279.373 297.122 279.373C293.329 279.373 291.357 280.888 291.357 283.514C291.357 286.191 292.874 287.303 296.262 287.914L299.549 288.52C306.684 289.783 310.022 292.965 310.022 298.779C310.022 305.452 305.218 309.998 296.762 309.998C290.745 309.998 286.593 307.624 283.609 304.139Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_4431_489207">
            <rect width="380" height="310" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </LogoBox>
  );
};

export default Logo;

const LogoBox = styled("div")<ILogo>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: auto;
    height: ${(props) => props.height};
    fill: ${(props) => props.fill};
  }
`;
