import React, { useCallback, useEffect, useState } from "react";
import * as O from "fp-ts/Option";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import BaseSpatialMap from "components/spatial/base/BaseSpatialMap";
import { Asset } from "lib/at-data/assets/Asset";
import { runMapEffect } from "lib/fp-mapbox/effects/runeMapEffect";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { BBox } from "lib/at-data/BBox";
import { onUserMapMove2 } from "lib/fp-mapbox/stage";
import { EventData } from "mapbox-gl";
import { Geometry } from "@turf/turf";
import { OSMBuildingsCollector } from "controllers/OSMBuildingCollector/OSMBuildingsCollector";
import {
  useCollectorOld,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import {
  BBoxL,
  LuciferPagePageUIStateL,
} from "controllers/UIStateCollector/lens/luciferPageL";
import * as L from "monocle-ts/Lens";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { setupBuildingLayer } from "views/authenticated/activity/page/components/ActivityMap";
import { getOrAddGeoJSONSource } from "lib/fp-mapbox/sources/geojson";
import { noop } from "lib/util";
import * as AD from "lib/at-data/AsyncData";
import * as NEA from "fp-ts/NonEmptyArray";
import { chainFirstIO } from "views/authenticated/activity/page/components/ReaderIO";
import * as A from "fp-ts/Array";
import { GeoCoord } from "lib/at-data/GeoCoord";
import {
  LegacyAssetsModificationsController,
  modifyGeography,
} from "controllers/AssetsController/AssetsController";
import { RawAssetsController } from "views/authenticated/root/AssetsController";
import { ById } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import { GeographyL } from "lib/at-data/assets/lens";
import { useCollectorWithDispatch } from "lib/at-react/hooks";
import { getGeometry } from "lib/at-data/assets/getters";

export const addGeocodingControl = pipe(
  R.ask<MapboxContext>(),
  R.map(({ map }) => () => {
    // @ts-ignore
    map.addControl(
      // @ts-ignore
      new MapboxGeocoder({
        // @ts-ignore
        accessToken:
          "pk.eyJ1Ijoidml0bzIwMTkiLCJhIjoiY2xicjFmanIyMThwYjNwcGprM3g4dm4zaCJ9.H0XIlcgp8iP_L87tMDqkNA",
        // @ts-ignore
        mapboxgl: window.mapboxgl,
      })
    );
  })
);

export const onPolygonClick = (buildingClickHandler: (id: string) => void) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map(
      (ctx) => () =>
        ctx.map.on("click", "building_polygon_layer", (e) =>
          pipe(
            ctx.map.queryRenderedFeatures(e.point),
            NEA.fromArray,
            O.map(NEA.head),
            O.chain((_: any) => O.fromNullable(_.id)),
            O.foldW(() => noop, buildingClickHandler)
          )
        )
    )
  );

export const GeographicalWorkspace: React.FC<{ building: Asset }> = ({
  building,
}) => {
  const [spatialCommandContext, setSpatialCommandContext] = useState<
    O.Option<MapboxContext>
  >(O.none);
  const [osmBuildings, dispatch] = useCollectorWithDispatch(
    OSMBuildingsCollector,
    flow(L.prop("osmBuildings"))
  );
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const rawAssetsDispatch = useControllerDispatch(RawAssetsController);

  const uiStateDispatch = useHistoryControllerDispatch(UIStateController);
  const bbox = useCollectorOld(
    UIStateController,
    flow(
      L.prop("state"),
      L.prop("luciferPage"),
      L.prop("geographicalWorkspace"),
      L.prop("bbox")
    )
  );

  const handleMapMove = useCallback(
    (ev: EventData) => {
      ev.target.once("idle", () => {
        if (!ev.target.isMoving()) {
          uiStateDispatch(
            pipe(
              ev.target.getBounds().toArray().flat() as BBox,
              O.some,
              pipe(LuciferPagePageUIStateL, L.composeLens(BBoxL)).set
            ),
            true
          );
        }
      });
    },
    [uiStateDispatch]
  );

  const handlePolygonSelect = useCallback(
    (id: string) => {
      pipe(
        osmBuildings,
        AD.map((fc) =>
          pipe(
            fc.features,
            A.findFirst((_) => _.properties?.id === id),
            O.map((_) => _.geometry as Geometry),
            O.map((_) => _.coordinates as Array<Array<GeoCoord>>),
            O.chain(A.head),
            O.foldW(
              () => noop,
              (polygon) => {
                assetModificationsDispatch(
                  flow(modifyGeography(building, O.some(polygon)))
                );
                rawAssetsDispatch(
                  flow(
                    pipe(
                      ById(building.id),
                      updateAsset,
                      T.composeLens(GeographyL),
                      T.set(O.some(polygon))
                    )
                  )
                );
              }
            )
          )
        )
      );
    },
    [building, osmBuildings, rawAssetsDispatch, assetModificationsDispatch]
  );

  useEffect(pipe(spatialCommandContext, runMapEffect(addGeocodingControl)), [
    spatialCommandContext,
  ]);

  useEffect(
    pipe(spatialCommandContext, runMapEffect(onUserMapMove2(handleMapMove))),
    [spatialCommandContext, handleMapMove]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        pipe(
          setupBuildingLayer("id"),
          chainFirstIO(onPolygonClick(handlePolygonSelect))
        )
      )
    ),
    [spatialCommandContext, handlePolygonSelect]
  );

  useEffect(
    pipe(
      spatialCommandContext,
      runMapEffect(
        pipe(
          R.of(osmBuildings),
          R.chain(
            AD.fold(
              () => () => noop,
              O.foldW(
                () => () => noop,
                getOrAddGeoJSONSource("building_polygon_source")
              )
            )
          )
        )
      )
    ),
    [spatialCommandContext, osmBuildings]
  );

  return (
    <div style={{}}>
      <BaseSpatialMap
        style={{
          width: "1500px",
          height: "650px",
          border: "none",
        }}
        initalBounds={bbox}
        onMapReady={(map) => {
          setSpatialCommandContext(
            O.some({
              map,
            })
          );
        }}
      />
    </div>
  );
};
