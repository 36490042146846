import * as React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import palette from "theme/palette";
import Icon, {
  AtFoodBev,
  AtArchitecture,
  AtChevronDown,
  AtSecurity,
  AtFacilities,
  AtGreenSpace,
  AtRetail,
  AtTransit,
} from "components/ui/Icon";

const AssetExperimentMenu: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="button-experiment"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={
          <Icon
            icon={AtChevronDown}
            size="24px"
            color={`${palette.neutral[100]}`}
          />
        }
        sx={{
          width: "auto",
          whiteSpace: "nowrap",
          backgroundColor: "transparent",
        }}
      >
        What Changed?
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        elevation={24}
      >
        <MenuItem onClick={handleClose}>
          <Icon
            icon={AtArchitecture}
            size="16px"
            color={`${palette.neutral[600]}`}
          />
          Physical Change
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Icon
            icon={AtFoodBev}
            size="16px"
            color={`${palette.neutral[600]}`}
          />
          Food or Dining Change
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Icon
            icon={AtSecurity}
            size="16px"
            color={`${palette.neutral[600]}`}
          />
          Security Change
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Icon
            icon={AtFacilities}
            size="16px"
            color={`${palette.neutral[600]}`}
          />
          Facilities Redesign
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Icon
            icon={AtGreenSpace}
            size="16px"
            color={`${palette.neutral[600]}`}
          />
          Added Green Space
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Icon icon={AtRetail} size="16px" color={`${palette.neutral[600]}`} />
          Retail Partner
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Icon
            icon={AtTransit}
            size="16px"
            color={`${palette.neutral[600]}`}
          />
          Transit Change
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AssetExperimentMenu;
