import React from "react";
import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "theme/theme";
import palette from "../../../theme/palette";

interface IError {
  error: Error;
}

const DashboardApplicationError: React.FC<IError> = ({ error }) => (
  <StyledContainer>
    <Content>
      <Title variant="h1">Oops!</Title>
      <Subtitle variant="h2">
        You seem to have
        <br />
        run into an error.
      </Subtitle>
      <ErrorMessage>{error.message}</ErrorMessage>
    </Content>
  </StyledContainer>
);

export default DashboardApplicationError;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Content = styled("div")`
  width: 100%;
  max-width: 600px;
  padding: 10vh 0;
  color: ${palette.neutral[100]};
`;

const Title = styled(Typography)`
  font-size: 5em;
`;

const Subtitle = styled(Typography)`
  margin-bottom: ${theme.spacing(4)};
  font-size: 3em;
`;

const ErrorMessage = styled("pre")`
  width: 100%;
  padding: ${theme.spacing(2)};
  color: ${palette.status.error.default};
  white-space: initial;
  background-color: ${palette.neutral[700]};
  border-radius: 8px;
`;
