import * as Eq from "fp-ts/Eq";
import * as C from "io-ts/Codec";
import * as D from "io-ts/Decoder";
import { eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { AssetOContext } from "contexts/AssetContext";
import {
  fetchFloorPlanArtifactMeta,
  FloorPlanMetaModel,
  getImageProjectionTpl,
} from "components/spatial/base/layers/floorDiagram";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as RT from "fp-ts/ReaderTask";
import { AppContext } from "contexts/AppContext";
import * as E from "fp-ts/Either";
import { sequenceT } from "fp-ts/Apply";
import * as AD2 from "lib/at-data/AsyncData2";
import { of } from "lib/at-data/UUID";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import * as L from "monocle-ts/Lens";
import {
  FloorPlanForMap,
  FloorPlanForMapEq,
} from "controllers/ActivityMapFloorDiagramController/FloorPlanForMap";
import React from "react";
import { error } from "fp-ts/es6/Console";
import * as TE from "fp-ts/TaskEither";
import * as AD from "lib/at-data/AsyncData";
import { AsyncData } from "lib/at-data/AsyncData";
import { defineAppController } from "lib/at-react/defineAppController";
import { AssetsController } from "views/authenticated/root/AssetsController";
import { composeController } from "lib/at-react/defineController";
import { ArtifactTypes, BackgroundImageArtifact } from "lib/at-data/Artifact";
import { AssetsContext } from "contexts/AssetsContext";
import { getGeoReference } from "lib/at-data/assets/getters";
import { RootAssetsContext } from "views/authenticated/root/controller/state";

export interface ActivityMapFloorDiagramState {
  floorPlanDiagram: AsyncData<FloorPlanForMap>;
}

export const initialState: ActivityMapFloorDiagramState = {
  floorPlanDiagram: AD.PendingData(O.none),
};

export const floorPlanImageL = pipe(
  L.id<ActivityMapFloorDiagramState>(),
  L.prop("floorPlanDiagram")
);

export const setFloorPlanDiagram =
  (
    dispatch: React.Dispatch<React.SetStateAction<ActivityMapFloorDiagramState>>
  ) =>
  (data: AsyncData<FloorPlanForMap>) =>
  () => {
    return pipe(data, floorPlanImageL.set, dispatch);
  };

export const ActivityMapFloorDiagramStateEq =
  Eq.struct<ActivityMapFloorDiagramState>({
    floorPlanDiagram: AD.getEq(FloorPlanForMapEq),
  });

export const fetchFloorPlanMeta =
  (floor: Asset) => (artifactType: ArtifactTypes) =>
    pipe(
      sequenceT(RTE.Monad)(
        pipe(
          RTE.ask<AppContext & AbortingControllerContext>(),
          RTE.chainTaskEitherK((ctx) =>
            pipe(
              fetchFloorPlanArtifactMeta(
                floor.id,
                ctx.appContext.accessToken,
                ctx.abortingController.signal,
                artifactType
              )
            )
          ),
          RTE.chainEitherK(
            flow(
              FloorPlanMetaModel.decode,
              E.mapLeft(
                (e) => new Error("error parsing image service response")
              )
            )
          )
        ),
        pipe(
          floor,
          getGeoReference,
          RTE.fromOption(() => new Error("The floor is not georeferenced"))
        )
      ),
      RTE.map((_) => getImageProjectionTpl(_[1])(_[0])),
      RTE.chainTaskEitherKW(([url, coords]) =>
        pipe(
          fetchFloorPlanImage(url),
          TE.map((_) => [_, coords] as FloorPlanForMap)
        )
      )
    );

export const FloorDataModel = C.struct({
  url: C.string,
  svgWidth: C.number,
  svgHeight: C.number,
});

export const toBase64UrlString = (blob: Blob): TE.TaskEither<Error, string> =>
  TE.tryCatch(
    () =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          // @ts-ignore
          const b64 = reader.result;
          resolve(b64 as string);
        });
        reader.readAsDataURL(blob);
      }),
    E.toError
  );

export const fetchFloorPlanMeta2 =
  (floor: Asset) => (artifactType: ArtifactTypes) =>
    pipe(
      RTE.ask<AppContext & AbortingControllerContext>(),
      RTE.chainTaskEitherK((ctx) =>
        fetchFloorPlanArtifactMeta(
          floor.id,
          ctx.appContext.accessToken,
          ctx.abortingController.signal,
          artifactType
        )
      ),
      RTE.chainEitherK(
        flow(
          FloorDataModel.decode,
          E.mapLeft((e) => {
            return new Error("error parsing image service response");
          })
        )
      ),
      RTE.chainTaskEitherKW(({ url, svgWidth, svgHeight }) =>
        pipe(
          fetchFloorPlanImageBLob(url),
          TE.chainW(toBase64UrlString),
          TE.map(BackgroundImageArtifact(svgWidth, svgHeight))
        )
      )
    );

export const readToFileReader = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      // @ts-ignore
      const b64 = reader.result;
      resolve(b64 as string);
    });
    reader.readAsDataURL(blob);
  });
};

export const fetchFloorPlanImage = (imageUrl: string) =>
  TE.tryCatch(
    () =>
      fetch(imageUrl, {
        method: "GET",
      })
        .then((response) => {
          return response.blob();
        })
        .then(readToFileReader),
    E.toError
  );
export const fetchFloorPlanImageBLob = (imageUrl: string) =>
  TE.tryCatch(
    () =>
      fetch(imageUrl, {
        method: "GET",
      }).then((response) => {
        return response.blob();
      }),

    E.toError
  );

export const getParentFloorAsset = pipe(
  RTE.asks<AssetOContext, O.Option<Asset>>((_) => _.asset)
);

const [component, controller] = pipe(
  AssetsController,
  composeController(
    defineAppController<
      RootAssetsContext & AssetOContext,
      ActivityMapFloorDiagramState
    >(
      initialState,
      ActivityMapFloorDiagramStateEq,
      Eq.struct({
        assets: AD2.getEq(eqAssetsByUUIDOnly),
        asset: O.getEq(eqAsset),
      }),
      (dispatch) =>
        pipe(
          pipe(
            getParentFloorAsset,
            RTE.apFirst(
              RTE.fromIO(setFloorPlanDiagram(dispatch)(AD.PendingData(O.none)))
            ),
            RTE.chainW(
              flow(
                O.map((a) => fetchFloorPlanMeta(a)(ArtifactTypes.BACKGROUND)),
                O.sequence(RTE.Monad)
              )
            )
          ),
          RTE.foldW(
            flow(
              error,
              RT.fromIO,
              RT.apFirst(
                pipe(
                  O.none,
                  AD.DoneData,
                  setFloorPlanDiagram(dispatch),
                  RT.fromIO
                )
              )
            ),
            flow(AD.DoneData, setFloorPlanDiagram(dispatch), RT.fromIO)
          )
        )
    )
  )
);

export const ActivityMapFloorDiagramControllerComponent = component;
export const ActivityMapFloorDiagramDataController = controller;
