import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import * as ROR from "fp-ts/ReadonlyRecord";
import { AssetMeanOccupancyCell } from "views/authenticated/assets/page/components/AssetsTable/cells/AssetAreaCell";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as O from "fp-ts/Option";
import * as N from "fp-ts/number";
import React from "react";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import * as AD from "lib/at-data/AsyncData";
import * as A from "fp-ts/Array";
import { asyncData } from "lib/at-data/AsyncData";
import { option } from "io-ts-types";
import * as t from "io-ts";
import { sum } from "../formula/UtilizationColumn";
import { formatNumber, UINumber } from "lib/formatters/formatNumber";

export const getAssetMeanOccupancy = pipe(
  R.ask<AssetRowDataContext>(),
  R.map(({ asset, meanOccupancySlice }) =>
    pipe(meanOccupancySlice, AD.map(getSliceByUUID(asset.id)))
  )
);

export const AssetMeanOccupancyColumn = defineColumn(
  "assetMeanOccupancy",
  pipe(
    getAssetMeanOccupancy,
    R.map((_) => ROR.singleton("assetMeanOccupancy", _))
  ),
  R.of(AssetMeanOccupancyCell),
  pipe(
    readColumnData,
    R.map(
      flow(
        A.filterMap(
          flow(
            ROR.lookup("assetMeanOccupancy"),
            O.chain(
              flow(
                asyncData(option(t.number)).decode,
                O.fromEither,
                O.chain(flow(AD.getData, O.flatten))
              )
            )
          )
        ),
        sum,
        O.map(formatNumber(UINumber))
      )
    ),
    R.map((value) => (
      <TableHeaderKpi
        label="Mean Occupancy"
        hint="Average number of occupants in the space for the time period selected, filtered to only include specified operating hours."
        value={value}
        icon={true}
      />
    ))
  ),
  R.of({
    defaultFlex: 0.5,
    sort: AD.getOrd(O.getOrd(N.Ord)).compare,
  })
);
