import { UIState } from "controllers/UIStateCollector/models/UIStateModel";
import { AssetsPageUIStateFilter } from "controllers/UIStateCollector/models/AssetsPageUIStateModel";
import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { AssetsPageL } from "controllers/UIStateCollector/lens/assetsPageL";

export const assetsStateFromPathParams =
  (uiState: UIState) => (stateFilter: AssetsPageUIStateFilter) =>
    pipe(
      uiState,
      pipe(AssetsPageL, L.props("selectedRange", "assetsFilter")).set(
        stateFilter
      )
    );
