import * as D from "io-ts/Decoder";
import * as En from "io-ts/Encoder";
import * as C from "io-ts/Codec";
import * as O from "fp-ts/Option";
import * as t from "io-ts";
import * as R from "fp-ts/Record";
import { pipe } from "fp-ts/function";
import { UUID } from "lib/at-data/UUID";

export const option = <A, O>(
  d: D.Decoder<unknown, A>
): C.Codec<unknown, O.Option<A>, O.Option<A>> =>
  C.fromDecoder(
    D.sum("_tag")({
      Some: D.struct({
        _tag: D.literal("Some"),
        value: d,
      }),
      None: D.struct({
        _tag: D.literal("None"),
      }),
    })
  );

/**
 * @deprecated
 *
 */
export const toLegacyIoTs = <I, O, A>(c: C.Codec<I, O, A>): t.Type<A, O, I> =>
  c as t.Type<A, O, I>;

export const encodeAs =
  <A, BO>(f: (b: A) => BO) =>
  <I, O>(c: C.Codec<I, O, A>): C.Codec<I, BO, A> =>
    C.make(c, pipe(En.id<BO>(), En.contramap(f)));

export const decodeAsProp =
  <A, BO>(fa: (b: A) => BO, fd: (b: BO) => A) =>
  <I, O>(c: C.Codec<I, O, A>) =>
    pipe(c, C.imap(fa, fd));
// C.make(pipe(c, D.map(fd)), pipe(En.id<BO>(), En.contramap(fa)));
