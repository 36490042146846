import * as O from "fp-ts/Option";
import { Asset } from "lib/at-data/assets/Asset";
import { flow, pipe } from "fp-ts/function";
import { getAssetsByType, getParentAssetOf } from "lib/at-data/assets/filters";
import { isFloor } from "lib/at-data/assets/predicates";
import * as R from "fp-ts/Reader";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { Assets, getByParent } from "lib/at-data/assets/assets";
import { clog } from "../../../../lib/util";

export const getChildren = (selectedAssetO: O.Option<Asset>) =>
  pipe(
    selectedAssetO,
    O.fold(() => R.of([]), getByParent)
  );
export const assetsToShow = (selectedAsset: O.Option<Asset>) =>
  pipe(
    selectedAsset,
    O.map(flow(getParentAssetOf(isFloor), R.chain(getChildren))),
    O.sequence(R.Monad),
    R.map(O.getOrElse(() => [] as Assets))
  );
