import { pipe } from "fp-ts/function";
import { Coord } from "lib/at-data/assets/models/Coord";
import { UUID } from "lib/at-data/UUID";
import { GeometryModel } from "lib/at-data/Geometry";
import { OtterGeoReferenceModel } from "lib/at-data/assets/models/GeoReferenceModel";
import * as C from "io-ts/Codec";
import {
  optionFromNullable,
  optionFromUndefined,
} from "lib/codecs/optionFromNullable";
import * as D from "io-ts/Decoder";
import * as En from "io-ts/Encoder";
import { fromNullable } from "lib/codecs/fromNullable";
import { HoursOfOperationModel } from "lib/at-data/HoursOfOperation";
import { USDPeSqMModel } from "lib/at-data/units/currency";

export enum OtterAssetClasses {
  CAMPUS = "Campus",
  BUILDING = "Building",
  LEVEL = "Level",
  ZONE = "Zone",
  AGENT = "Agent",
  SEAT = "Seat",
  AREA = "Area",
  SPACE = "Space",
  ASSET = "Asset",
  THING = "Thing",
  REGION = "Region",
}

export type OtterAssetClass = `${OtterAssetClasses}`;

export const LevelPropertiesModel = C.struct({
  levelNumber: optionFromNullable(C.number),
  levelLabel: optionFromNullable(C.string),
});

export const SubclassPropertiesModel = C.struct({
  shortCode: optionFromNullable(C.string),
});

export type LevelProperties = C.TypeOf<typeof LevelPropertiesModel>;
export type SubclassProperties = C.TypeOf<typeof SubclassPropertiesModel>;

export const OtterLocalGeometryProperty = optionFromUndefined(GeometryModel);

export const OtterAssetModel = C.struct({
  id: UUID,
  classes: C.array(
    C.fromDecoder(
      D.union(
        D.literal(OtterAssetClasses.CAMPUS),
        D.literal(OtterAssetClasses.BUILDING),
        D.literal(OtterAssetClasses.LEVEL),
        D.literal(OtterAssetClasses.ZONE),
        D.literal(OtterAssetClasses.AGENT),
        D.literal(OtterAssetClasses.SEAT),
        D.literal(OtterAssetClasses.AREA),
        D.literal(OtterAssetClasses.SPACE),
        D.literal(OtterAssetClasses.ASSET),
        D.literal(OtterAssetClasses.THING),
        D.literal(OtterAssetClasses.REGION)
      )
    )
  ),
  properties: C.struct({
    name: optionFromNullable(C.string),
    locatedIn: optionFromNullable(UUID),
    legacyZoneId: optionFromNullable(UUID),
    localGeometry: OtterLocalGeometryProperty,
    geography: optionFromNullable(GeometryModel),
    utilizationGoal: optionFromNullable(C.number),
    userDefinedTags: fromNullable(C.array(C.string), []),
    georeference: optionFromNullable(OtterGeoReferenceModel),
    capacity: optionFromNullable(C.number),
    netPricePerSqMt: optionFromNullable(C.number),
    hoursOfOperation: optionFromNullable(HoursOfOperationModel),
    timeZoneId: optionFromNullable(C.string),
  }),
  levelProperties: optionFromNullable(LevelPropertiesModel),
  subclassProperties: optionFromNullable(SubclassPropertiesModel),
});

export type OtterAsset = C.TypeOf<typeof OtterAssetModel>;
