import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Button,
  Link,
} from "@mui/material";
import Icon, { AtArrowRight } from "components/ui/Icon";
import React from "react";
import ReportsPlaceholderImage, { ReportType } from "./ReportsPlaceholderImage";
import * as O from "fp-ts/Option";
import { useController } from "lib/at-react/defineController";
import { dataProviderL, UserL } from "views/authenticated/app/controller/state";
import { AppController } from "controllers/AppController/AppController";
import { flow } from "fp-ts/function";

interface IRecentReport extends ReportType {
  title: string;
  description: string;
  favorite?: boolean;
  pdfLink?: string;
}

const RecentReport: React.FC<IRecentReport> = ({
  title,
  description,
  reportType,
  favorite,
  pdfLink,
}) => {
  const [isUTK] = useController(
    AppController,
    flow(
      UserL.get,
      O.map(dataProviderL.get),
      O.filter((_) => _ === "88bae34f-f09a-4e2c-b89c-d2f7fe6bb576"),
      O.isSome
    )
  );

  const reportLinkAll = isUTK
    ? "/reports/utk/UTK_10032022_All_Spaces.pdf"
    : "/reports/bosci/BosSci_10032022_All_Spaces.pdf";
  const reportLinkEA = isUTK
    ? "/reports/utk/UTK_10032022_Expected-Actual.pdf"
    : "/reports/bosci/BosSci_10032022_Expected-Actual.pdf";
  return (
    <Card sx={{ padding: 3, height: "100%" }}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Stack sx={{ width: "67%", paddingRight: "24px" }}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="textTertiary" sx={{ mt: 1 }}>
            {description}
          </Typography>

          <Button
            variant="contained"
            size="small"
            color="primary"
            sx={{ mt: 2, width: "130px" }}
            component={Link}
            href={title === "Monthly All Spaces" ? reportLinkAll : reportLinkEA}
            download
          >
            Download
            <Icon icon={AtArrowRight} size="16" sx={{ marginLeft: "8px" }} />
          </Button>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ maxWidth: "156px" }}>
          <Box mt={3}>
            <ReportsPlaceholderImage reportType={reportType} />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

const recentReports = [
  {
    title: "Monthly All Spaces",
    description:
      "Review the key utilization stats from last month, including top 5 spaces, times of day and more.",
    type: "bar",
    // favorite: true,
    pdfLink: "",
  },
  {
    title: "Booked vs Actual",
    description:
      "See the overview of what people you are expecting and how many of them actually showed up.",
    type: "heatmap",
    pdfLink: "",
  },
];

const ReportsRecentlyViewed: React.FC = () => (
  <Stack p={4} pt={5} pb={6} spacing={2}>
    <Typography variant="h4">This Month's Reports</Typography>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        {recentReports.map((report, i) => (
          <Grid item xs={12} sm={6} lg={6} key={`${report.title}-${i}`}>
            <RecentReport
              title={report.title}
              description={report.description}
              reportType={report.type}
              pdfLink={report.pdfLink}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  </Stack>
);

export default ReportsRecentlyViewed;
