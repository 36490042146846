import { Box, Button, Stack, Typography } from "@mui/material";
import Icon, { AtActivityMap } from "components/ui/Icon";
import React from "react";
import { RiAddCircleFill, RiSettings3Fill } from "react-icons/ri";
import palette from "theme/palette";
import * as O from "fp-ts/lib/Option";
import { flow, pipe } from "fp-ts/function";
import AssetsHeaderChartContainer from "./AssetsCharts/AssetsHeaderChartContainer";
import { Link } from "react-router-dom";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetsHeaderChartControllerComponent } from "controllers/AssetsHeaderChartController/AssetsHeaderChartController";
import { AssetOContext } from "contexts/AssetContext";

const AssetsHeader: React.FC<{
  asset: O.Option<Asset>;
}> = (props) => (
  <>
    <Box p={3} pt={6} sx={{ position: "relative", zIndex: 1 }}>
      <Stack direction="row" spacing={4} alignItems="center" mb={2}>
        <Typography variant="h3" color={palette.neutral[100]}>
          {pipe(
            props.asset,
            O.map((a) => a.name as string),
            O.getOrElse(() => "Loading")
          )}
        </Typography>
      </Stack>
      {/*<Stack direction="row" spacing={4} alignItems="center"></Stack>*/}
    </Box>
    <Box mt={-4}>
      <AssetsHeaderChartControllerComponent
        context={AssetOContext(props.asset)}
      >
        <AssetsHeaderChartContainer />
      </AssetsHeaderChartControllerComponent>
    </Box>
  </>
);

export default AssetsHeader;
