export enum ASSET_CONTEXT_TABS {
  ADMIN = "admin",
  ASSET = "asset",
  UTILIZATION = "utilization",
  INSIGHTS = "insights",
  SCHEDULE = "schedule",
  WEATHER = "weather",
  SELECTION = "selection",
  DEFAULT_CAPACITY = "default-capacity",
}
