import { pipe, flow } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import { initialState } from "./initialState";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as Eq from "fp-ts/Eq";
import {
  ReportsState,
  ReportsStateEq,
  ReportsL,
  ReportLinksL,
  ReportLinksState,
} from "./state";
import * as AD from "lib/at-data/AsyncData";
import {
  fetchReports,
  OrdReportsByDate,
  ReportLinkResponse,
  ReportMetadata,
} from "lib/at-api/reports/reportMetadata";
import * as A from "fp-ts/Array";
import React from "react";
import { defineAppController } from "lib/at-react/defineAppController";
import { loggingAsyncDataEffectLegacy } from "lib/logging";

//change to reports for a tenant instead of an asset:
// export const getReports = RTE.of(fetchReports)

export const setReportsIO =
  (dispatch: React.Dispatch<React.SetStateAction<ReportsState>>) =>
  (reports: AD.AsyncData<Array<ReportMetadata>>) =>
  () =>
    pipe(reports, ReportsL.set, dispatch);

const [component, controller] = defineAppController<{}, ReportsState>(
  initialState,
  ReportsStateEq,
  Eq.struct({}),
  (dispatch) =>
    pipe(
      RTE.fromIO(pipe(AD.PendingData(O.none), setReportsIO(dispatch))),
      RTE.chainW(() => fetchReports),
      loggingAsyncDataEffectLegacy("Reports", flow(setReportsIO(dispatch)))
    )
);

export const ReportsControllerComponent = component;
export const ReportsController = controller;
