import * as A from "fp-ts/Array";
import * as S from "fp-ts/Set";
import * as s from "fp-ts/string";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { ordUUIDSet } from "lib/at-data/UUIDSlice";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import { parentIs } from "lib/at-data/assets/predicates";
import * as Ord from "fp-ts/Ord";
import { getId, getLegacyZoneId, getName } from "lib/at-data/assets/getters";

export const toUUID = (u: string): UUID => u as UUID;

export type Assets = Array<Asset>;
export const eqAssets = A.getEq(eqAsset);

export const eqAssetsByUUIDOnly = pipe(
  A.getEq(pipe(eqUUID, Eq.contramap(getId)))
);

export const getByParent =
  (a: Asset) =>
  (assets: Assets): Assets =>
    pipe(assets, A.filter(parentIs(a)));

export const getBuildingFloors =
  (building: Asset) =>
  (assets: Assets): Assets =>
    pipe(assets, getByParent(building));

export const ordName = Ord.contramap(getName)(s.Ord);

export const acumulateAllChilds = (
  acumulator: Assets,
  assets: Assets,
  parentAsset: Asset
): Assets => {
  const children = pipe(assets, getByParent(parentAsset));
  const grandChildren = pipe(
    children,
    A.map((childAsset) => acumulateAllChilds(acumulator, assets, childAsset)),
    A.reduce([], (childrenAcumulator: Assets, childAsset: Assets) => {
      return childrenAcumulator.concat(childAsset);
    })
  );

  return acumulator.concat(children).concat(grandChildren);
};

export const getAllChildsWithParent = (
  parentAsset: Asset,
  assets: Assets
): Assets => {
  return acumulateAllChilds([], assets, parentAsset).concat([parentAsset]);
};
