import * as Eq from "fp-ts/Eq";
import * as n from "fp-ts/number";
import * as C from "io-ts/Codec";

export const CoordModel = C.struct({
  x: C.number,
  y: C.number,
});

export type Coord = C.TypeOf<typeof CoordModel>;

export const eqCoord = Eq.struct({
  x: n.Eq,
  y: n.Eq,
});

export const getX = (coord: Coord) => coord.x;
export const getY = (coord: Coord) => coord.y;

export const toPoint = ({ x, y }: Coord) => [x, y] as [number, number];
