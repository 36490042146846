import { assetsPageInitialState } from "controllers/UIStateCollector/initialState/assetsPage";
import { activityPageInitialState } from "controllers/UIStateCollector/initialState/activityPage";
import { UIState } from "controllers/UIStateCollector/models/UIStateModel";
import { of } from "lib/at-data/UUID";
import * as O from "fp-ts/Option";
import {
  LocalWorkspaceMode,
  Workspaces,
} from "controllers/UIStateCollector/models/LuciferPageUIStateModel";

export const uiState: UIState = {
  assetsPage: assetsPageInitialState,
  activityPage: activityPageInitialState,
  luciferPage: {
    // selectedAssetIds: [of("515c93e1-353d-4453-97b6-73bf9d747517")], //su
    // selectedAssetIds: [of("fa5ab550-8f3f-4756-af9e-c1fb85bef2f4")], //ford
    // selectedAssetIds: [of("f4b499c2-dff4-4853-8d40-0272e7c03420")], //Claxton
    // selectedAssetIds: [of("cbcdcf5d-5ac6-4cdb-bf87-d552433cdd84")], //hodges floor1
    selectedAssetIds: [of("8c272477-f191-4d11-a705-3b70d8e854ab")], //lily
    selectedWorkspace: Workspaces.LOCAL,
    geographicalWorkspace: {
      bbox: O.none,
    },
    localWorkSpace: {
      mode: LocalWorkspaceMode.EDIT,
      initialGeoReference: O.none,
    },
    // selectedAssetIds: [of("6fc67904-2f01-4a2c-9de8-380fc0a9209d")], //Fords field
  },
};
