import { Box, Stack, Typography } from "@mui/material";
import Icon, { AtBuilding } from "components/ui/Icon";
import {
  AssetsMeanOccupancyController,
  MeanOccupanciesL,
} from "controllers/AssetsMeanOccupancyController/AssetsMeanOccupancyController";
import * as AD2 from "lib/at-data/AsyncData2";
import { useController } from "lib/at-react/defineController";
import {
  CommaNumber,
  CommaNumberNoDecimal,
  formatNumber,
  TwoDecimal,
  UINumber,
} from "lib/formatters/formatNumber";
import palette from "theme/palette";
import React, { useCallback } from "react";
import { PinHead } from "./PinHead";
import { Assets, ordName } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as AD from "lib/at-data/AsyncData";
import * as S from "lib/at-data/Slice";
import { UUID } from "lib/at-data/UUID";
import * as O from "fp-ts/Option";
import {
  FloorButton,
  FloorItem,
  FloorLevel,
  FloorList,
  FloorOccupancy,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetContextFloorPickerMenu";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import {
  getOccupancyEstimate,
  OccupancyEstimate,
} from "lib/at-data/OccupancyEstimate";
import { numerable } from "lib/formatters/numerable";
import { Asset } from "lib/at-data/assets/Asset";
import { ordByNavOrder } from "views/authenticated/activity/page/components/activityDrawer/ActivityDrawerList";
import { clog } from "lib/util";
import { format } from "date-fns/fp";
import { useCollector } from "lib/at-react/hooks";
import { Now } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetDetailPreviewKpiIcon";
import { SelectedSliceCollector } from "controllers/SelectedSliceCollector/SelectedSliceCollector";

const OccupancyList: React.FC<{
  name: string;
  occupancy: number;
  color: string;
}> = ({ name, occupancy, color }) => {
  return (
    <Stack>
      <Typography sx={{ paddingTop: "16px", color: `${palette.neutral[100]}` }}>
        <Icon
          icon={AtBuilding}
          color={palette.neutral[400]}
          size="16px"
          sx={{ marginRight: "12px", marginBottom: "-2px" }}
        />
        {name}
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="flex-start"
        alignItems="flex-start"
        spacing={0}
        pt={1}
        sx={{ position: "relative", width: "300px" }}
      >
        <Box
          sx={{
            width: "265px",
            backgroundColor: `${palette.neutral[500]}`,
            height: "12px",
            borderRadius: "6px",
          }}
        />
        <Box
          sx={{
            width: `${occupancy * 2.65}px`,
            height: "12px",
            borderRadius: "6px",
            position: "absolute",
            backgroundColor: `${color}`,
          }}
        />
        <Typography
          sx={{ color: `${palette.neutral[100]}`, marginTop: "-6px" }}
        >
          {occupancy}%
        </Typography>
      </Stack>
    </Stack>
  );
};

export const AssetsLiveOccupancies2: React.FC<{
  assets: Assets;
  onAssetClick: (asset: Asset) => void;
  occupancies: AD.AsyncData<S.Slice<UUID, O.Option<OccupancyEstimate>>>;
}> = (props) => {
  const [meanOccupancies] = useController(
    AssetsMeanOccupancyController,
    MeanOccupanciesL.get
  );

  const handleAssetClick = useCallback(
    (asset: Asset) => () => props.onAssetClick(asset),
    [props.onAssetClick]
  );

  console.log("mean occ:", meanOccupancies);

  const selectedSlice = useCollector(SelectedSliceCollector);

  return (
    <Stack spacing={1}>
      {/*<nav aria-label="floor menu">*/}
      <div>
        <FloorList>
          {pipe(
            props.assets,
            A.sort(ordByNavOrder),
            A.mapWithIndex((i, asset) => (
              <FloorItem disablePadding key={i}>
                <FloorButton onClick={handleAssetClick(asset)}>
                  <Stack sx={{ width: "230px" }}>
                    <FloorLevel primary={asset.name} />
                    <FloorOccupancy>
                      Mean Occupancy:{" "}
                      {pipe(
                        meanOccupancies,
                        AD2.fold(
                          () => "0", //TODO: what to show if failed or no Data
                          () => "Pending", // TODO: UX for loading (shimmer/skeleton)
                          (val) =>
                            pipe(
                              val,
                              getSliceByUUID(asset.id),
                              O.flatten,
                              O.fold(
                                () => "0",
                                flow(
                                  getOccupancyEstimate,
                                  formatNumber(CommaNumberNoDecimal)
                                )
                              )
                            ),
                          () => "error"
                        )
                      )}
                    </FloorOccupancy>
                  </Stack>
                </FloorButton>
              </FloorItem>
            ))
          )}
        </FloorList>
      </div>
    </Stack>
  );
};

export const AssetsLiveOccupancies: React.FC<{
  assets: Assets;
  onAssetClick: (asset: Asset) => void;
  occupancies: AD.AsyncData<S.Slice<UUID, O.Option<OccupancyEstimate>>>;
}> = (props) => {
  const handleAssetClick = useCallback(
    (asset: Asset) => () => props.onAssetClick(asset),
    [props.onAssetClick]
  );

  return (
    <Stack spacing={1}>
      {/*<nav aria-label="floor menu">*/}
      <div>
        <FloorList>
          {pipe(
            props.assets,
            A.sort(ordByNavOrder),
            A.mapWithIndex((i, asset) => (
              <FloorItem disablePadding key={i}>
                <FloorButton onClick={handleAssetClick(asset)}>
                  <Stack sx={{ width: "230px" }}>
                    <FloorLevel primary={asset.name} />
                    <FloorOccupancy>
                      Est. Occupancy:{" "}
                      {pipe(
                        props.occupancies,
                        AD.fold(
                          // TODO: Troy, this should return a shimmering skeleton
                          () => "Loading",
                          flow(
                            O.bindTo("slice"),
                            O.apS("assetId", O.some(asset.id)),
                            O.chain(({ assetId, slice }) =>
                              pipe(slice, getSliceByUUID(assetId), O.flatten)
                            ),
                            O.fold(
                              () => "N/A",
                              (cd) =>
                                `${pipe(
                                  cd,
                                  getOccupancyEstimate,
                                  numerable("0,0a")
                                )}`
                            )
                          )
                        )
                      )}
                    </FloorOccupancy>
                  </Stack>
                </FloorButton>
              </FloorItem>
            ))
          )}
        </FloorList>
        {/*</nav>*/}
      </div>
    </Stack>
  );
};

export const AssetsUtilizations: React.FC<{ assets: Assets }> = (props) => {
  return (
    <Stack spacing={1} px={3} py={2}>
      <Typography variant="h4" color={palette.neutral[100]}>
        Live Occupancy
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        alignContent={"center"}
        pb={2}
        spacing={1}
      >
        <PinHead />
        <Typography variant="subtitle1" color="textTertiary">
          Now
        </Typography>
        <Typography variant="subtitle1" color={palette.neutral[100]}>
          {new Date().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        alignContent={"center"}
        pb={2}
        spacing={1}
      >
        {pipe(
          props.assets,
          A.map((asset) => (
            <OccupancyList
              name={asset.name}
              occupancy={85}
              color={`${palette.data.meter.third}`}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};
