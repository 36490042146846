import { pipe } from "fp-ts/function";
import { hourMinuteToDate } from "lib/at-data/HoursOfOperation";
import { enGB, enUS } from "date-fns/locale";
import { format } from "date-fns";
import * as b from "fp-ts/boolean";

export const formatOperatingHour = (twentyHour: boolean) => (h: number) =>
  format(pipe(h, hourMinuteToDate), "p", {
    locale: pipe(
      twentyHour,
      b.fold(
        () => enUS,
        () => enGB
      )
    ),
  });
