import React, { useCallback, useState } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import {
  useCollectorOld,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  LegacyAssetsModificationsController,
  modifyAssetShortCode,
  modifyCostPerSqM,
} from "controllers/AssetsController/AssetsController";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { flow, pipe } from "fp-ts/function";
import { ById, getAllChildren } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import { AssetShortCodeL, CostPerSqML } from "lib/at-data/assets/lens";
import { TableCell, TableRow } from "@mui/material";
import * as s from "fp-ts/string";
import {
  Editable,
  OptionalProperty,
  Property,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import {
  TextEditor,
  TextEditorProps,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/editors";
import * as O from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types";
import { isNonEmptyString } from "newtype-ts/es6/NonEmptyString";
import * as E from "fp-ts/Either";
import * as S from "fp-ts/Semigroup";
import { isAlphaNum } from "fp-ts-std/String";
import { sequenceT } from "fp-ts/Apply";
import * as NEA from "fp-ts/NonEmptyArray";
import * as A from "fp-ts/Array";
import { toUpperCase } from "fp-ts/string";
import LabeledCheckbox from "components/ui/LabeledCheckbox";
import * as L from "monocle-ts/Lens";
import * as R from "fp-ts/Reader";
import * as b from "fp-ts/boolean";
import { noop } from "lib/util";

export const PROPERTY_SHORT_CODE_MIN_LENGTH = 2;
export const PROPERTY_SHORT_CODE_MAX_LENGTH = 5;

export const isValidLength = (shortCode: String) =>
  shortCode.length >= PROPERTY_SHORT_CODE_MIN_LENGTH &&
  shortCode.length <= PROPERTY_SHORT_CODE_MAX_LENGTH;

export const validateShortCodeLength = (shortCode: string) =>
  pipe(
    shortCode,
    E.fromPredicate(isValidLength, () => [
      `Must be between ${PROPERTY_SHORT_CODE_MIN_LENGTH} and ${PROPERTY_SHORT_CODE_MAX_LENGTH} characters`,
    ])
  );

export const validateAlphaNumeric = (shortCode: string) =>
  pipe(
    shortCode,
    E.fromPredicate(isAlphaNum, () => [`Must be alphanumeric`])
  );

export const validateShortCode = (shortCode: string) =>
  pipe(
    sequenceT(E.getApplicativeValidation(A.getSemigroup<string>()))(
      validateShortCodeLength(shortCode),
      validateAlphaNumeric(shortCode)
    ),
    E.map(() => shortCode)
  );

export const ShortCodeEditor: React.FC<TextEditorProps> = (props) => (
  <TextEditor
    {...props}
    inputProps={{
      style: {
        textTransform: "uppercase",
      },
    }}
  />
);

export const AssetShortCodePropertyRow: React.FC<{ asset: Asset }> = (
  props
) => {
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const rootDispatch = useControllerDispatch(RawAssetsController);

  const allAssetsAD = pipe(
    useCollectorOld(AssetsController, flow(L.prop("assets"))),
    fromAD2
  );

  const handleSaveAssetShortCode = useCallback(
    (shortCode: string, descendantsChecked: boolean) => {
      const code = pipe(
        shortCode,
        O.fromPredicate(isNonEmptyString),
        O.map(toUpperCase)
      );
      assetModificationsDispatch(modifyAssetShortCode(props.asset, code));
      rootDispatch(
        pipe(
          ById(props.asset.id),
          updateAsset,
          T.composeLens(AssetShortCodeL),
          T.set(code)
        )
      );
      pipe(
        descendantsChecked,
        b.fold(
          () => {
            noop();
          },
          () => {
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a) =>
                  assetModificationsDispatch(modifyAssetShortCode(a, code))
                )
              )
            )({ assets: allAssetsAD });
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a2) =>
                  rootDispatch(
                    pipe(
                      ById(a2.id),
                      updateAsset,
                      T.composeLens(AssetShortCodeL),
                      T.set(code)
                    )
                  )
                )
              )
            )({ assets: allAssetsAD });
          }
        )
      );
    },
    [props.asset, assetModificationsDispatch, rootDispatch]
  );

  return (
    <TableRow
      key={"assetName"}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        Short Code
      </TableCell>
      <TableCell align="right">
        <Editable
          onChange={handleSaveAssetShortCode}
          value={pipe(
            props.asset.shortCode,
            O.getOrElseW(() => "")
          )}
          eq={s.Eq}
          editor={ShortCodeEditor}
          validationFn={validateShortCode}
          inheritanceAsset={props.asset}
        >
          <OptionalProperty
            value={props.asset.shortCode}
            component={Property}
            required={false}
          />
        </Editable>
      </TableCell>
    </TableRow>
  );
};
