import React, { MouseEventHandler } from "react";
import {
  styled,
  Paper,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Assets } from "lib/at-data/assets/assets";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as N from "fp-ts/number";
import { pipe } from "fp-ts/function";
import { contramap } from "fp-ts/Ord";
import { Asset } from "lib/at-data/assets/Asset";
import palette from "theme/palette";
import { eqUUID } from "lib/at-data/UUID";
import { FloorMeta } from "lib/at-data/assets/models/FloorMetaModel";
import { getId } from "lib/at-data/assets/getters";

const byFloorOrder = pipe(
  O.getOrd(N.Ord),
  contramap((f: Asset) =>
    pipe(
      f.floorMeta,
      O.map((_) => _.order)
    )
  )
);

const FloorItem: React.FC<{
  floorMeta: FloorMeta;
  onClick: MouseEventHandler;
}> = ({ floorMeta, onClick }) => (
  <Floor>
    <FloorButton onClick={onClick}>
      {pipe(
        floorMeta.abbreviation,
        O.getOrElse(() => `${floorMeta.order}F`)
      )}
    </FloorButton>
  </Floor>
);

const eqU = O.getEq(eqUUID);

export const getFloorLabel = (fm: FloorMeta) =>
  pipe(
    fm.abbreviation,
    O.getOrElse(() => `${fm.order}F`)
  );

const ActivityControlsFloorPickerMenu: React.FC<{
  floors: Assets;
  selectedFloor: O.Option<Asset>;
  onFloorClick: (floor: Asset) => void;
}> = (props) => {
  return (
    <FloorButtonGroup orientation="vertical" exclusive={true}>
      {pipe(
        props.floors,
        A.sort(byFloorOrder),
        A.filter((_) => O.isSome(_.geoReference)),
        A.filterMapWithIndex((i, f) =>
          pipe(
            f.floorMeta,
            O.map((fm) => (
              <ToggleButton
                key={i}
                value={fm.order}
                selected={eqU.equals(
                  pipe(props.selectedFloor, O.map(getId)),
                  O.some(f.id)
                )}
                onClick={() => {
                  props.onFloorClick(f);
                }}
                sx={{
                  backgroundColor: "transparent",
                  borderRadius: "50% !important",
                  color: "#46495e",
                  fontWeight: "normal",
                  border: "none",
                  width: "24px",
                  height: "24px",
                  transition: "background .5s, color: .25s",
                  lineHeight: "100%",
                  "&:hover": {
                    backgroundColor: "white",
                    transition: "backgroundColor 0s, color: 0s",
                    boxShadow: " 0 1px 2px rgba(0, 0, 0, .1)",
                  },
                  "&.Mui-selected, &.Mui-selected:hover, &.Mui-focusVisible": {
                    backgroundColor: `${palette.primary[400]}`,
                    boxShadow: "inset -1px 1px 2px rgba(0, 0, 0, .35)",
                    color: "white",
                    fontWeight: "bold",
                    transition: "backgroundColor .15s, color: .15s",
                  },
                  "&.MuiToggleButton-root, &.MuiButtonBase-root": {
                    mt: 1.5,
                    mb: 1.5,
                  },
                }}
              >
                {pipe(fm, getFloorLabel)}
              </ToggleButton>
            ))
          )
        )
      )}
    </FloorButtonGroup>
  );
};
export default ActivityControlsFloorPickerMenu;

const Floor = styled("div")`
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width: 36px;
  width: 36px;
`;

const FloorButtonGroup = styled(ToggleButtonGroup)`
  m: 0.5;
  padding: 4px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 36px;
  min-width: 36px;
  background-color: #eff1f6;
  border-radius: 5px;

  &:empty {
    display: none;
  }
`;

const FloorButton = styled(Button)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50%;
  background-color: transparent;
  max-width: 30px;
  max-height: 30px;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 12px 0;

  &:hover {
    background-color: #f1f3ff;
  }

  background-color: transparent;
  border-radius: 50% !important;
  color: #46495e;
  font-weight: normal;
  border: none;
  width: 24px;
  height: 24px;
  transition: background-color .25s,
  color: .25s;
  line-height: 100%;

  &:hover: {
    background-color: white;
    transition: background-color 0s,
    color: 0s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  }

  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-focusVisible: {
    background-color: #887CDE;
    box-shadow: inset -1px 1px 2px rgba(0, 0, 0, .35);
    color: white;
    font-weight: bold;
  }

  &.MuiToggleButton-root,
  &.MuiButtonBase-root {
    margin: 0.75 auto;
  }
`;
