import { GranulatedSeriesRequest } from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import React from "react";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/lib/Reader";
import { initialState } from "./initialState";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as IO from "fp-ts/lib/IO";
import * as Eq from "fp-ts/Eq";
import { getAssetsMultipleOccupancyCountsSeries } from "views/authenticated/assets/actions/workerActions";
import { AsyncData } from "lib/at-data/AsyncData";
import {
  getOccupancyEstimate,
  OccupancyEstimate,
} from "lib/at-data/OccupancyEstimate";
import { AppContext } from "contexts/AppContext";
import {
  AssetsLiveOccupancyState,
  AssetsLiveOccupancyStateEq,
  LastOccupancyEstimatesL,
  LastOccupancyTimestampL,
} from "./state";
import { subHours } from "date-fns/fp";
import * as AD from "lib/at-data/AsyncData";
import * as O from "fp-ts/Option";
import * as D from "lib/at-data/DataSeries";
import { AssetsContext } from "contexts/AssetsContext";
import { getLiveTime } from "controllers/AssetLiveOccupancyController/ActivityLiveOccupancyController";
import { Assets, eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import { UUID } from "lib/at-data/UUID";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { RESOLUTION } from "lib/at-data/Resolution";
import { normalizeToResolution } from "lib/at-data/DataSeries";
import * as S from "lib/at-data/Slice";
import { defineAppController } from "lib/at-react/defineAppController";

export const setLastOcuppancyEstimates =
  (dispatch: React.Dispatch<React.SetStateAction<AssetsLiveOccupancyState>>) =>
  (
    data: AsyncData<S.Slice<UUID, D.DataSeries<OccupancyEstimate>>>
  ): IO.IO<void> =>
  () => {
    pipe(
      data,
      AD.map(
        S.map(D.findLastSliceBy(flow(getOccupancyEstimate, (_) => _ > 0)))
      ),
      LastOccupancyEstimatesL.set,
      dispatch
    );
    pipe(
      data,
      AD.map(
        S.map(D.findLastTimestampBy(flow(getOccupancyEstimate, (_) => _ > 0)))
      ),
      LastOccupancyTimestampL.set,
      dispatch
    );
  };

const getLastHour = (liveTime: Date): GranulatedSeriesRequest =>
  pipe(
    {
      start: pipe(liveTime, subHours(1)),
      end: liveTime,
      resolution: RESOLUTION.FIVE_MINUTES,
    },
    normalizeToResolution
  );

export const [ControllerComponent, Controller] = defineAppController<
  AssetsContext,
  AssetsLiveOccupancyState
>(
  initialState,
  AssetsLiveOccupancyStateEq,
  Eq.struct({ assets: eqAssetsByUUIDOnly }),
  (dispatch) =>
    pipe(
      RTE.asks<AssetsContext & AppContext, Assets>((_) => _.assets),
      RTE.bindTo("assets"),
      RTE.apS("liveTime", RTE.fromIO(getLiveTime)),
      RTE.chainW(({ liveTime, assets }) =>
        pipe(
          R.ask<AppContext>(),
          R.map((ctx) =>
            getAssetsMultipleOccupancyCountsSeries(
              assets,
              getLastHour(liveTime)
            )(ctx)
          )
        )
      ),
      RTE.chainFirstIOK(
        flow(O.some, AD.DoneData, setLastOcuppancyEstimates(dispatch))
      )
    )
);

export const AssetsLiveOccupancyController = Controller;
export const AssetsLiveOccupancyControllerComponent = ControllerComponent;
