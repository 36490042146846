import * as O from "fp-ts/Option";
import { fetchAssetsAnalyzeTE } from "lib/at-api/otter/analyze";
import * as Sl from "lib/at-data/Slice";
import { Assets } from "../../at-data/assets/assets";
import { SeriesRequest } from "../../at-data/requests/temporal/SeriesRequest";
import { flow, pipe } from "fp-ts/function";
import * as RTE from "fp-ts/ReaderTaskEither";

export const fetchPeakTE = (assets: Assets, series: SeriesRequest) =>
  pipe(
    fetchAssetsAnalyzeTE(series, assets),
    RTE.map(
      Sl.map(
        flow(
          (_) => _.occupancyMax,
          O.getOrElse(() => 0)
        )
      )
    )
  );
