import React from "react";
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled,
} from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import CompareHeatmap from "./components/CompareHeatmap";
import CompareTimebar from "./components/CompareTimebar";
import CompareKeyMetrics from "./components/CompareKeyMetrics";
import CompareBookings from "./components/CompareBookings";
import CompareAssetInfo from "./components/CompareAssetInfo";

const ComparePage: React.FC<{}> = () => {
  return (
    <Page>
      <CompareHeatmap />
      <CompareTimebar />
      <CompareKeyMetrics />
      <CompareBookings />
      <CompareAssetInfo />
    </Page>
  );
};

export default ComparePage;

const Page = styled("main")`
  background-color: ${palette.neutral[800]};
  min-height: calc(100vh - ${vars.navBar});
  color: ${palette.neutral[100]};
`;
const ActivityMap = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;
const TimeBar = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;

const Pane = styled(Stack)`
  width: 100%;
`;
