import {
  Avatar,
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Icon from "components/ui/Icon";
import { AppController } from "controllers/AppController/AppController";
import { generateRandomUUID } from "controllers/AssetsController/Modification";
import { sequenceT } from "fp-ts/Apply";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import { updateAuth0OrganizationTenantId } from "lib/at-auth0";
import * as AD2 from "lib/at-data/AsyncData2";
import { UUID } from "lib/at-data/UUID";
import { noop } from "lib/util";
import { clearDashboardSettingsIO } from "models/DashboardSettings";
import { isNonEmptyString } from "newtype-ts/es6/NonEmptyString";
import React, { useCallback, useEffect, useState } from "react";
import { RiMoreFill, RiRefreshFill } from "react-icons/ri";
import palette from "theme/palette";
import { BaseStylingProvider } from "theme/theme";
import { toMinimalAppState } from "views/authenticated/app/ReadyAppState";
import DashboardApplicationError from "views/public/error/DashboardApplicationError";
import { setConfig } from "react-hot-loader";
import MainView from "views/authenticated/MainView";
import { ErrorBoundary } from "react-error-boundary";
import { useController } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { AssetsL } from "views/authenticated/root/controller/state";
import { flow, pipe } from "fp-ts/function";
import Spinner, { ApplicationError, Backdrop } from "components/ui/Spinner";
import { ApplicationToast } from "ApplicationToast";

// Removes react-hot-loader console warning
setConfig({
  showReactDomPatchNotification: false,
});

/**
 * App Component Represents an authenticated application ready to run(but possibly still doing some initializations
 * @param props
 * @constructor
 */
const LambentDashboard: React.FC<{}> = (props) => {
  const [assets] = useController(AssetsController, flow(AssetsL.get));

  return (
    <ErrorBoundary fallbackRender={DashboardApplicationError}>
      {pipe(
        assets,
        AD2.fold(
          () => null,
          () => <Spinner />,
          () => null,
          (e) => <ApplicationError error={e} />
        )
      )}
      <MainView />
      <ApplicationToast />
    </ErrorBoundary>
  );
};

export default LambentDashboard;

export const TenantInitialization: React.FC<{}> = (props) => {
  return (
    <Backdrop>
      <Modal
        sx={{ zIndex: 10000 }}
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: palette.neutral[800],
            position: "absolute" as "absolute",
            top: "20%",
            left: "20%",
            transform: "translate(-10%, -10%)",
            width: "80%",
            p: 4,
          }}
        >
          <h2>Initialize Organization</h2>
          <NewOrganizationForm />
        </Box>
      </Modal>
    </Backdrop>
  );
};

export const NewOrganizationForm: React.FC<{}> = (props) => {
  const [appState] = useController(AppController, (_) => _);
  const [organizationId, setOrganizationId] = useState<UUID>(
    generateRandomUUID()
  );
  const [managementTokenO, setManagementTokenO] = useState<O.Option<string>>(
    O.none
  );
  const handleGenerateNewOrganizationUUID = useCallback(
    () => pipe(generateRandomUUID(), setOrganizationId),
    []
  );

  const handleUpdateAuth0 = useCallback(() => {
    pipe(
      sequenceT(O.Applicative)(
        managementTokenO,
        pipe(appState, toMinimalAppState)
      ),
      O.fold(
        () => noop,
        ([mt, appContext]) =>
          updateAuth0OrganizationTenantId(mt, organizationId)({ appContext })
      )
    )();
  }, [appState, organizationId, managementTokenO]);

  const handleLogout = () =>
    pipe(
      appState.authClient,
      O.fold(
        () => {},
        (a) => {
          clearDashboardSettingsIO();
          window.history.replaceState(null, "");
          a.logout({ returnTo: window.location.origin });
        }
      )
    );

  return (
    <Stack>
      <Stack direction={"row"}>
        <TextField
          label="Organization Id"
          sx={{ m: 1, width: "25ch" }}
          value={organizationId}
        />
        <IconButton onClick={handleGenerateNewOrganizationUUID}>
          <Icon icon={RiRefreshFill} />
        </IconButton>
      </Stack>
      <Stack direction={"row"}>
        <TextField
          label="token"
          sx={{ m: 1, width: "25ch" }}
          onChange={(ev) =>
            pipe(
              ev.target.value,
              O.fromPredicate(isNonEmptyString),
              setManagementTokenO
            )
          }
          value={pipe(managementTokenO, O.toUndefined)}
        />
      </Stack>

      <Stack>
        <Button onClick={handleUpdateAuth0}>
          Create Organization Asset and Update Tenant
        </Button>
        <Button onClick={handleLogout}>Logout</Button>
      </Stack>
    </Stack>
  );
};
