import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import * as ROR from "fp-ts/ReadonlyRecord";
import { NumberPeakUtilCell } from "views/authenticated/assets/page/components/AssetsTable/cells";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as AD from "lib/at-data/AsyncData";
import { asyncData } from "lib/at-data/AsyncData";
import { option } from "io-ts-types";
import * as t from "io-ts";
import {
  formatPercent,
  mean,
  max,
} from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import React from "react";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import { actualUtil } from "../../../../../../root/controller/formulaUtils";
import { VisibleAssetsContext } from "../../../../../../../../controllers/AssetsTableDataController/contexts/VisibleAssetsContext";
import { PeakOccupancySliceState } from "../../../../../../../../controllers/AssetsOccupanciesControllers/AssetsPeakOccupancySliceController";
import * as Sl from "../../../../../../../../lib/at-data/Slice";
import { getAssetById } from "../../../../../../../../lib/at-data/assets/filters";
import { sequenceT } from "fp-ts/Apply";
import { getCapacity } from "lib/at-data/assets/getters";

export const calculatePeakUtilization =
  (capacityO: O.Option<number>) => (peakOccupancyO: O.Option<number>) =>
    pipe(
      sequenceT(O.Monad)(capacityO, peakOccupancyO),
      O.map(([capacity, peakOccupancy]) => peakOccupancy / capacity)
    );

export const getAssetPeakUtilization = pipe(
  R.ask<AssetRowDataContext>(),
  R.map(({ asset, peakOccupancySlice }) =>
    pipe(
      peakOccupancySlice,
      AD.map(
        flow(getSliceByUUID(asset.id), calculatePeakUtilization(asset.capacity))
      )
    )
  )
);

//Returning for slice instead of for single option:
export const getAssetPeakUtilization2 = pipe(
  R.ask<VisibleAssetsContext & PeakOccupancySliceState>(),
  R.map((ctx) =>
    pipe(
      ctx.peakOccupancySlice,
      AD.map(
        Sl.mapWithIndex((assetId, peakOccupancy) =>
          pipe(
            ctx.visibleAssets,
            getAssetById(assetId),
            O.chain(getCapacity),
            O.map(actualUtil(peakOccupancy))
          )
        )
      )
    )
  )
);

export const AssetPeakUtilizationColumn = defineColumn(
  "assetPeakUtilization",
  pipe(
    getAssetPeakUtilization,
    R.map((_) => ROR.singleton("assetPeakUtilization", _))
  ),
  R.of(NumberPeakUtilCell),
  pipe(
    readColumnData,
    R.map(
      flow(
        A.filterMap(
          flow(
            ROR.lookup("assetPeakUtilization"),
            O.chain(
              flow(
                asyncData(option(t.number)).decode,
                O.fromEither,
                O.chain(flow(AD.getData, O.flatten))
              )
            )
          )
        ),
        mean,
        O.chain(O.fromPredicate(Number.isFinite)),
        O.map(flow(toPercentage, Math.round, formatPercent))
      )
    ),
    R.map((value) => (
      <TableHeaderKpi
        label="Peak Utilization"
        hint="Peak occupancy divided by capacity for the time period selected."
        value={value}
        icon={true}
      />
    ))
  ),
  R.of({
    defaultFlex: 0.5,
    sort: AD.getOrd(O.getOrd(N.Ord)).compare,
  })
);
