import * as C from "io-ts/Codec";
import { dateFromISOString } from "lib/codecs/dateFromISOString";
import { numberFromString } from "lib/codecs/numberFromString";
import { fromNullable } from "lib/codecs/fromNullable";
import { searchParamArray } from "lib/tubular-fp-routing/codecs/searchParamArray";
import { DEFAULT_FILTERS } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import { pipe } from "fp-ts/function";
import { AssetTypeModel } from "lib/at-data/assets/AssetTypeModel";
import { UUID } from "lib/at-data/UUID";
import {
  optionFromNullable,
  optionFromUndefined,
} from "lib/codecs/optionFromNullable";
import { BBoxModel } from "lib/at-data/BBox";

export const UIStatePathParamsModel = C.struct({
  start: dateFromISOString,
  end: dateFromISOString,
  byTags: fromNullable(searchParamArray(C.string), DEFAULT_FILTERS.byTags),
  byName: fromNullable(C.string, DEFAULT_FILTERS.byName),
  byTypes: searchParamArray(C.fromDecoder(pipe(AssetTypeModel))),
});

export type UIStatePathParams = C.TypeOf<typeof UIStatePathParamsModel>;

export const UIStateSpatialPathParamsModel = C.struct({
  bbox: optionFromUndefined(searchParamArray(numberFromString)),
  bearing: optionFromUndefined(numberFromString),
});

export type UIStateSpatialPathParams = C.TypeOf<
  typeof UIStateSpatialPathParamsModel
>;

export const LuciferStatePathParamsModel = C.struct({
  selectedAsset: UUID,
});
