import React from "react";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import AssetsTableCellChip from "views/authenticated/assets/page/components/AssetsTable/AssetsTableCellChip";
import AssetsTableCellDwell from "views/authenticated/assets/page/components/AssetsTable/AssetsTableCellDwell";
import { not } from "fp-ts/Predicate";
import AssetsTableCellView from "views/authenticated/assets/page/components/AssetsTable/AssetsTableCellView";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import * as AD from "lib/at-data/AsyncData";
import { AsyncData } from "lib/at-data/AsyncData";
import { Skeleton, Stack, TextField, Tooltip } from "@mui/material";
import { numerable } from "lib/formatters/numerable";
import { isoUSD, USD } from "lib/at-data/units/currency";
import * as b from "fp-ts/boolean";
import {
  CommaNumber,
  formatNumber,
} from "../../../../../../../lib/formatters/formatNumber";
import * as Sl from "lib/at-data/Slice";
import { actualUtil } from "../../../../../root/controller/formulaUtils";
import { clog } from "../../../../../../../lib/util";
import { Pending } from "./AssetAreaCell";
import palette from "theme/palette";

export const dwellCell = () => {
  function randomTrend(num: number) {
    switch (num) {
      case 1:
        return "up";
      case 2:
        return "down";
      default:
        return undefined;
    }
  }

  return (
    <AssetsTableCellDwell
      time="10min"
      trend={randomTrend(2)}
      graph={
        <svg viewBox="0 0 66 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 19H16.7808L21.3836 15.2642L25.1096 19H36.0685L40.2329 1L44.8356 9.83019L50.3151 15.2642L57.3288 19H65"
            stroke="#887CDE"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    />
  );
};
export const UtilizationCell: React.FC<{ value: AsyncData<O.Option<number>> }> =
  ({ value }) =>
    pipe(
      value,
      AD.fold(
        flow(
          O.flatten,
          O.fold(
            () => (
              <Stack direction="row" spacing={1} alignItems="center">
                <Skeleton
                  variant={"text"}
                  width={64}
                  height={16}
                  sx={{ borderRadius: 8 }}
                />
                <Skeleton
                  variant={"text"}
                  width={24}
                  height={16}
                  sx={{ borderRadius: 8 }}
                />
              </Stack>
            ),
            (utilization) => (
              <AssetsTableCellView
                percent={pipe(utilization, toPercentage, Math.round)}
              />
            )
          )
        ),
        flow(
          O.flatten,
          O.map((utilization) => (
            <AssetsTableCellView
              percent={pipe(utilization, toPercentage, Math.round)}
            />
          )),
          O.getOrElseW(() => null)
        )
      )
    );
export const PeakUtilizationCell: React.FC<{
  value: AsyncData<O.Option<number>>;
}> = ({ value }) =>
  pipe(
    value,
    AD.fold(
      flow(
        O.flatten,
        O.fold(
          () => (
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton
                variant={"text"}
                width={64}
                height={16}
                sx={{ borderRadius: 8 }}
              />
              <Skeleton
                variant={"text"}
                width={24}
                height={16}
                sx={{ borderRadius: 8 }}
              />
            </Stack>
          ),
          (utilization) => (
            <AssetsTableCellView
              // percent={pipe(utilization, toPercentage, Math.round)}
              percent={pipe(utilization, actualUtil(30))}
            />
          )
        )
      ),
      flow(
        O.flatten,
        O.map((utilization) => (
          <AssetsTableCellView
            // percent={pipe(utilization, toPercentage, Math.round)}
            percent={pipe(utilization, actualUtil(30))}
          />
        )),
        O.getOrElseW(() => null)
      )
    )
  );

export const PercentageCell: React.FC<{
  value: O.Option<number>;
}> = ({ value }) =>
  pipe(
    value,
    O.map(flow(toPercentage, Math.round)),
    O.foldW(
      () => null,
      (to) => <span>{to}%</span>
    )
  );

export const NumberPeakUtilCell: React.FC<{
  value: AsyncData<O.Option<number>>;
}> = ({ value }) =>
  pipe(
    value,
    AD.fold(
      () => <Pending />,
      flow(O.flatten, (_) => <PercentageCell value={_} />)
    )
  );

export const UsdCell: React.FC<{ value: O.Option<USD> }> = ({ value }) =>
  pipe(
    value,
    O.foldW(
      () => <Pending />,
      (cellValue) =>
        pipe(cellValue, isoUSD.unwrap) === 0 ? (
          <span>
            {pipe(
              cellValue,
              isoUSD.unwrap,
              numerable("$0,0a", { currency: "$" })
            )}
          </span>
        ) : (
          <span>
            {pipe(
              cellValue,
              isoUSD.unwrap,
              numerable("$0,0.00a", { currency: "$" })
            )}
          </span>
        )
    )
  );

export const numberCell: React.FC<{ value: O.Option<number> }> = ({ value }) =>
  pipe(
    value,
    O.chain(O.fromPredicate(not(Number.isNaN))),
    O.foldW(
      () => null,
      (v) => <span>{pipe(v, numerable("0,0.0a"))}</span>
    )
  );

export const capacityCell: React.FC<{
  value: { capacity: O.Option<number>; isDefault: boolean };
}> = ({ value }, { cellProps }) =>
  pipe(
    cellProps.editProps.inEdit,
    b.fold(
      () =>
        pipe(
          value.capacity,
          O.chain(O.fromPredicate(not(Number.isNaN))),
          O.foldW(
            () => null,
            (v) =>
              pipe(
                value.isDefault,
                b.fold(
                  () => <span>{pipe(v, formatNumber(CommaNumber))}</span>,
                  () => (
                    <Tooltip
                      title="Value is autogenerated based on default density ratios."
                      sx={{ cursor: "default" }}
                    >
                      <span style={{ color: `${palette.neutral[450]}` }}>
                        {pipe(v, formatNumber(CommaNumber))}*
                      </span>
                    </Tooltip>
                  )
                )
              )
          )
        ),
      () => (
        <span>
          <TextField
            type="number"
            defaultValue={pipe(
              value.capacity,
              O.getOrElse(() => 0)
            )}
            onBlur={(e) => {
              cellProps.editProps.onComplete();
            }}
            onChange={cellProps.editProps.onChange}
            onFocus={() => cellProps.editProps.startEdit()}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                cellProps.editProps.onCancel(e);
              }
              if (e.key === "Enter") {
                cellProps.editProps.onComplete(e);
              }
              if (e.key === "Tab") {
                e.preventDefault();
                cellProps.editProps.onTabNavigation(true, e.shiftKey ? -1 : 1);
              }
            }}
          />
        </span>
      )
    )
  );

const formattedAsyncNumberCell = (
  pattern: string,
  value: AsyncData<O.Option<number>>
) => {
  return pipe(
    value,
    AD.fold(
      () => (
        <Skeleton
          variant={"text"}
          width={32}
          height={16}
          sx={{ borderRadius: 50 }}
        />
      ),
      flow(
        O.flatten,
        O.chain(O.fromPredicate(not(Number.isNaN))),
        O.foldW(
          () => null,
          (v) => <span>{pipe(v, numerable(pattern))}</span>
        )
      )
    )
  );
};

export const AsyncCell: React.FC<{ value: AsyncData<string> }> = (props) =>
  pipe(
    props.value,
    AD.fold(
      () => (
        <Skeleton
          variant={"text"}
          width={32}
          height={16}
          sx={{ borderRadius: 50 }}
        />
      ),
      flow(
        O.foldW(
          () => null,
          (_) => <span>{_}</span>
        )
      )
    )
  );

export const customFormatAsyncNumberCell =
  (pattern: string) =>
  ({
    value,
  }: React.PropsWithChildren<{ value: AD.AsyncData<O.Option<number>> }>) =>
    formattedAsyncNumberCell(pattern, value);

export const asyncNumberCell: React.FC<{ value: AsyncData<O.Option<number>> }> =
  ({ value }) => formattedAsyncNumberCell("0,0.00a", value);

export const badgeTestCell = () => {
  function randomTrend(num: number) {
    switch (num) {
      case 1:
        return "success";
      case 2:
        return "warning";
      case 3:
        return "error";
      default:
        return undefined;
    }
  }

  return <AssetsTableCellChip label="Building" status={randomTrend(1)} />;
};
