import * as A from "fp-ts/Array";
import * as b from "fp-ts/boolean";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";
import { AssetTags } from "lib/at-api/asset-tags/assetTags";
import { Asset } from "lib/at-data/assets/Asset";
import { FloorMeta } from "lib/at-data/assets/models/FloorMetaModel";
import {
  converToOtter,
  GeoReference,
} from "lib/at-data/assets/models/GeoReferenceModel";
import { isBuilding } from "lib/at-data/assets/predicates";
import { GeoCoord } from "lib/at-data/GeoCoord";
import {
  HoursOfOperation,
  toBasicSchedule,
} from "lib/at-data/HoursOfOperation";
import { isoUSDPerOneSqM, USDPerOneSqM } from "lib/at-data/units/currency";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { MakeADT, makeMatchers, makeMatchP } from "ts-adt/MakeADT";

const [match] = makeMatchers("property");
export type AssetProperty =
  | "PROPERTY_TAG_NAME"
  | "PROPERTY_TAG_CAPACITY"
  | "PROPERTY_TAG_NET_PRICE_PER_SQ_MT"
  | "PROPERTY_TAG_UTILIZATION_GOAL"
  | "PROPERTY_TAG_USER_DEFINED_TAG"
  | "PROPERTY_TAG_LEVEL_LABEL"
  | "PROPERTY_TAG_LEVEL_NUMBER"
  | "PROPERTY_TAG_GEOMETRY_EPSG_4979"
  | "PROPERTY_TAG_GEOREFERENCE_EPSG_3857"
  | "PROPERTY_TAG_HOURS_OF_OPERATION"
  | "PROPERTY_TAG_SHORT_CODE"
  | "PROPERTY_TAG_TIME_ZONE_ID";

export type ValueType =
  | "string"
  | "int"
  | "double"
  | "geometry"
  | "plane_transformation"
  | "basic_schedule"
  | "string";

export const AssetPropertyValueTypes: Record<AssetProperty, ValueType> = {
  PROPERTY_TAG_NAME: "string",
  PROPERTY_TAG_CAPACITY: "int",
  PROPERTY_TAG_NET_PRICE_PER_SQ_MT: "double",
  PROPERTY_TAG_UTILIZATION_GOAL: "double",
  PROPERTY_TAG_USER_DEFINED_TAG: "string",
  PROPERTY_TAG_LEVEL_NUMBER: "int",
  PROPERTY_TAG_LEVEL_LABEL: "string",
  PROPERTY_TAG_GEOMETRY_EPSG_4979: "geometry",
  PROPERTY_TAG_GEOREFERENCE_EPSG_3857: "plane_transformation",
  PROPERTY_TAG_HOURS_OF_OPERATION: "basic_schedule",
  PROPERTY_TAG_SHORT_CODE: "string",
  PROPERTY_TAG_TIME_ZONE_ID: "string",
};
export const toAssetModifications = (asset: Asset): Array<AssetModification> =>
  pipe(
    asset.legacyZoneId,
    O.map((lzid) => [
      assetNameModification(asset.name, lzid),
      assetCapacityModification(asset.capacity, lzid),
      assetPricePerSqM(asset.costPerSqM, lzid),
      assetUtilizationGoal(asset.targetUtilization, lzid),
      assetSpatialGeometryModification(asset.geometry, lzid),
      assetPlaneTransformationModification(asset.geoReference, lzid),
      assetHoursOfOperationModification(asset.operationHours, lzid),
      assetTimezoneModification(asset.timezone, lzid),

      ...pipe(
        asset,
        isBuilding,
        b.fold(
          () => [],
          () => [assetShortCodeModification(asset.shortCode, lzid)]
        )
      ),
      ...assetLevelMetaModification(asset.floorMeta, lzid),
      ...assetTagsAddition(asset.tags, lzid),
    ]),
    O.getOrElseW(() => [])
  );

export type AssetModification = MakeADT<
  "property",
  {
    PROPERTY_TAG_NAME: {
      value: string;
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_SHORT_CODE: {
      value: O.Option<string>;
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_CAPACITY: {
      value: O.Option<number>;
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_LEVEL_NUMBER: {
      value: O.Option<number>;
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_LEVEL_LABEL: {
      value: O.Option<string>;
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_NET_PRICE_PER_SQ_MT: {
      value: O.Option<USDPerOneSqM>;
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_UTILIZATION_GOAL: {
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      value: O.Option<number>;
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_USER_DEFINED_TAG: {
      type:
        | "ASSET_TASK_TYPE_PROPERTY_ADD"
        | "ASSET_TASK_TYPE_PROPERTY_REMOVE_BY_VALUE";
      value: string;
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_GEOMETRY_EPSG_4979: {
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      value: O.Option<Array<GeoCoord>>;
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_GEOREFERENCE_EPSG_3857: {
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      value: O.Option<GeoReference>;
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_HOURS_OF_OPERATION: {
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      value: O.Option<HoursOfOperation>;
      legacyZoneId: UUID;
    };
    PROPERTY_TAG_TIME_ZONE_ID: {
      type: "ASSET_TASK_TYPE_PROPERTY_SET";
      value: O.Option<string>;
      legacyZoneId: UUID;
    };
  }
>;

export const assetNameModification = (
  value: string,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_NAME",
  value,
});

export const assetShortCodeModification = (
  value: O.Option<string>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_SHORT_CODE",
  value,
});

export const assetCapacityModification = (
  value: O.Option<number>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_CAPACITY",
  value,
});
export const assetLevelNumberModification = (
  value: O.Option<FloorMeta>,
  legacyZoneId: UUID
): O.Option<AssetModification> =>
  pipe(
    value,
    O.map((v) => ({
      legacyZoneId,
      type: "ASSET_TASK_TYPE_PROPERTY_SET",
      property: "PROPERTY_TAG_LEVEL_NUMBER",
      value: O.some(v.order),
    }))
  );

export const assetLevelLabelModification = (
  value: O.Option<FloorMeta>,
  legacyZoneId: UUID
): O.Option<AssetModification> =>
  pipe(
    value,
    O.chain((_) => _.abbreviation),
    O.map((v) => ({
      legacyZoneId,
      type: "ASSET_TASK_TYPE_PROPERTY_SET",
      property: "PROPERTY_TAG_LEVEL_LABEL",
      value: O.some(v),
    }))
  );

export const assetLevelMetaModification = (
  value: O.Option<FloorMeta>,
  legacyZoneId: UUID
): Array<AssetModification> => [
  ...pipe(assetLevelNumberModification(value, legacyZoneId), A.fromOption),
  ...pipe(assetLevelLabelModification(value, legacyZoneId), A.fromOption),
];

export const assetPricePerSqM = (
  value: O.Option<USDPerOneSqM>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_NET_PRICE_PER_SQ_MT",
  value,
});
export const assetUtilizationGoal = (
  value: O.Option<number>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_UTILIZATION_GOAL",
  value,
});

export const assetTagAddition =
  (legacyZoneId: UUID) =>
  (value: string): AssetModification => ({
    legacyZoneId,
    type: "ASSET_TASK_TYPE_PROPERTY_ADD",
    property: "PROPERTY_TAG_USER_DEFINED_TAG",
    value,
  });

export const assetTagsAddition = (value: AssetTags, legacyZoneId: UUID) =>
  pipe(value, A.map(assetTagAddition(legacyZoneId)));

export const assetSpatialGeometryModification = (
  value: O.Option<Array<GeoCoord>>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_GEOMETRY_EPSG_4979",
  value,
});

export const assetHoursOfOperationModification = (
  value: O.Option<HoursOfOperation>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_HOURS_OF_OPERATION",
  value,
});

export const assetPlaneTransformationModification = (
  value: O.Option<GeoReference>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_GEOREFERENCE_EPSG_3857",
  value,
});

export const assetTimezoneModification = (
  value: O.Option<string>,
  legacyZoneId: UUID
): AssetModification => ({
  legacyZoneId,
  type: "ASSET_TASK_TYPE_PROPERTY_SET",
  property: "PROPERTY_TAG_TIME_ZONE_ID",
  value,
});

export const getAddTask = (am: AssetModification) =>
  pipe(
    am,
    match({
      PROPERTY_TAG_NAME: (_) => O.some(_.value),
      PROPERTY_TAG_SHORT_CODE: (_) => _.value,
      PROPERTY_TAG_CAPACITY: (_) =>
        pipe(
          _.value,
          O.alt(() => O.some(0)),
          O.map((v) => v.toString())
        ),
      PROPERTY_TAG_NET_PRICE_PER_SQ_MT: (_) =>
        pipe(
          _.value,
          O.map(isoUSDPerOneSqM.unwrap),
          O.map((v) => v.toString())
        ),
      PROPERTY_TAG_UTILIZATION_GOAL: (_) =>
        pipe(
          _.value,
          O.map((v) => v.toString())
        ),
      PROPERTY_TAG_USER_DEFINED_TAG: (_) => O.some(_.value),
      PROPERTY_TAG_LEVEL_NUMBER: (_) =>
        pipe(
          _.value,
          O.map((v) => v.toString())
        ),
      PROPERTY_TAG_LEVEL_LABEL: (_) => _.value,
      PROPERTY_TAG_GEOMETRY_EPSG_4979: (_) =>
        pipe(_.value, O.map(flow(A.of, JSON.stringify, btoa))),
      PROPERTY_TAG_GEOREFERENCE_EPSG_3857: (_) =>
        pipe(
          _.value,
          O.chain(converToOtter),
          O.map(flow(JSON.stringify, btoa))
        ),
      PROPERTY_TAG_HOURS_OF_OPERATION: (_) =>
        pipe(_.value, O.map(flow(toBasicSchedule, JSON.stringify, btoa))),
      PROPERTY_TAG_TIME_ZONE_ID: (_) => _.value,
    }),
    O.map((v) => [
      am.legacyZoneId,
      am.type,
      am.property,
      v,
      AssetPropertyValueTypes[am.property],
    ])
  );

export const addAssetModification =
  (am: AssetModification) => (modifications: Array<AssetModification>) =>
    pipe(modifications, A.append(am));

export const addAssetModifications =
  (am: Array<AssetModification>) => (modifications: Array<AssetModification>) =>
    pipe(modifications, A.concat(am));

const eqBySamePropChange = pipe(
  Eq.struct({
    legacyZoneId: eqUUID,
    property: s.Eq,
  }),
  Eq.contramap(({ legacyZoneId, property }: AssetModification) => ({
    legacyZoneId,
    property,
  }))
);

export const getModificationProperty = (am: AssetModification) => am.property;
