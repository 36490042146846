export const MAPBOX_DEFAULT_LAYERS = [
  "land",
  "national-park",
  "landuse",
  "pitch-outline",
  "waterway",
  "water",
  "land-structure-polygon",
  "land-structure-line",
  "aeroway-polygon",
  "aeroway-line",
  "building-outline",
  "building",
  "tunnel-path",
  "tunnel-steps",
  "tunnel-pedestrian",
  "tunnel-simple",
  "road-path",
  "road-steps",
  "road-pedestrian",
  "road-simple",
  "bridge-path",
  "bridge-steps",
  "bridge-pedestrian",
  "bridge-case-simple",
  "bridge-simple",
  "admin-1-boundary-bg",
  "admin-0-boundary-bg",
  "admin-1-boundary",
  "admin-0-boundary",
  "admin-0-boundary-disputed",
  "road-label-simple",
  "path-pedestrian-label",
  "waterway-label",
  "natural-line-label",
  "natural-point-label",
  "water-line-label",
  "water-point-label",
  "poi-label",
  "airport-label",
  "settlement-subdivision-label",
  "settlement-minor-label",
  "settlement-major-label",
  "state-label",
  "country-label",
];
