export type AuthContext = {
  token: string;
};

export const AuthContext = (token: string): AuthContext => ({ token });

export const withAuthContext =
  <T>(token: string) =>
  (ctx: T) => ({
    ...ctx,
    token,
  });
