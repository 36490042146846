import React from "react";
import { Chip, Divider, Stack, styled, Typography } from "@mui/material";
import palette from "theme/palette";
import AssetBookingsList from "./AssetBookingsList";
import Icon, { AtSchedule } from "components/ui/Icon";
import { AssetsBookingsController } from "controllers/AssetsBookingsController/AssetsBookingsController";
import { BookingsL } from "controllers/AssetsBookingsController/state";
import { flow, pipe } from "fp-ts/function";
import { useController } from "lib/at-react/defineController";
import * as AD from "lib/at-data/AsyncData";
import * as O from "fp-ts/lib/Option";
import { Asset } from "lib/at-data/assets/Asset";
import {
  Bookings,
  groupBookingsByDate,
  OrdBookingsByDate,
} from "lib/at-api/assets/bookings";
import { NoDataFoundBookings, NoDataPlaceholderImage } from "./NoDataFound";
import * as A from "fp-ts/Array";
import * as Ord from "fp-ts/Ord";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { isFuture } from "date-fns/esm";
import { not } from "fp-ts/Predicate";
import { isAfter, isBefore, isEqual } from "date-fns/fp";
import { and, or } from "fp-ts-std/Boolean";
import * as NEA from "fp-ts/NonEmptyArray";
import { clog } from "lib/util";
import * as AD2 from "lib/at-data/AsyncData2";

const EventCount: React.FC<{
  label: string;
}> = ({ label }) => {
  return <EventTotal label={label} />;
};

const monthDateFormatter = new Intl.DateTimeFormat("en", {
  month: "long",
});

const dateFormatter = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

export const OrdGroupedBookingsByMonth = pipe(
  O.getOrd(OrdBookingsByDate),
  Ord.contramap((groupedBookings: Array<Bookings>) =>
    pipe(groupedBookings, A.head)
  )
);

// const getItemList: Function = (groups: any[]): React.ReactElement[] =>
//   groups.map((item: any, i: number) => {
//     const itemCardElemProps = { key: item.id, handleEvents: () => {}, ...item };
//     return <Item {...itemCardElemProps} />;
//   });

export const sortGroupedBookingsByMonth = (
  bookings: Array<Array<Bookings>>
): Array<Array<Bookings>> => pipe(bookings, A.sort(OrdGroupedBookingsByMonth));

export const bookingIsInFuture = (booking: Bookings) =>
  pipe(booking.schedule.start, isFuture);

export const isOnOrAfter = (dateb: Date) => (dateA: Date) =>
  pipe(dateA, isAfter(dateb), or(pipe(dateA, isEqual(dateb))));

export const bookingIsInRange = (range: SeriesRequest) => (booking: Bookings) =>
  pipe(
    booking.schedule.start,
    isOnOrAfter(range.start),
    and(pipe(booking.schedule.start, isBefore(range.end)))
  );

export const ListBookings: React.FC<{
  bookings: Array<Bookings>;
  asset: Asset;
  onBookingClick: (range: SeriesRequest) => void;
  selectedRange: SeriesRequest;
}> = (props) => (
  <>
    {pipe(
      props.bookings,
      A.filter(bookingIsInRange(props.selectedRange)),
      (_) =>
        groupBookingsByDate(
          _,
          (date) => `${date.getFullYear()}-${date.getMonth()}}`
        ),
      sortGroupedBookingsByMonth,
      A.filterMap((bookingsByMonth: Array<Bookings>) =>
        pipe(
          bookingsByMonth,
          A.head,
          O.map((_) => _.schedule.start),
          O.map((monthDate) => (
            <div>
              <Stack spacing={1} px={0} py={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  alignContent={"center"}
                  pb={2}
                  spacing={1}
                >
                  <Icon icon={AtSchedule} size="16px" color={"#FFFFFF"} />
                  <Typography variant="subtitle1" color={palette.neutral[100]}>
                    {dateFormatter.format(
                      new Date(monthDate.getFullYear(), monthDate.getMonth(), 1)
                    )}
                    -
                    {dateFormatter.format(
                      new Date(
                        monthDate.getFullYear(),
                        monthDate.getMonth() + 1,
                        0
                      )
                    )}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle1" color="textTertiary">
                    {monthDateFormatter.format(monthDate)}
                  </Typography>
                  <EventCount
                    label={
                      bookingsByMonth.length === 1
                        ? `${bookingsByMonth.length} Event`
                        : `${bookingsByMonth.length} Events`
                    }
                  />
                </Stack>
                <AssetBookingsList
                  onBookingClick={props.onBookingClick}
                  bookings={bookingsByMonth}
                  asset={props.asset}
                />
              </Stack>
            </div>
          ))
        )
      ),
      NEA.fromArray,
      O.getOrElseW(() => <NoBookingsFound />)
    )}
  </>
);

export const AssetBookings: React.FC<{
  asset: Asset;
  onBookingClick: (range: SeriesRequest) => void;
  selectedRange: SeriesRequest;
}> = ({ asset, onBookingClick, ...props }) => {
  const [bookings] = useController(
    AssetsBookingsController,
    flow(BookingsL.get)
  );

  return (
    <>
      <StyledPanelContent>
        <Stack spacing={1} px={3} py={2}>
          <Typography
            component={"span"}
            variant="h4"
            color={palette.neutral[100]}
          >
            Bookings
          </Typography>
        </Stack>
        <Divider color={palette.neutral[500]} />
        <Stack spacing={1} px={3} py={2}>
          {pipe(
            bookings,
            AD2.fold(
              () => <NoBookingsFound />,
              () => <NoDataPlaceholderImage />,
              (b) => (
                <ListBookings
                  bookings={b}
                  asset={asset}
                  selectedRange={props.selectedRange}
                  onBookingClick={onBookingClick}
                />
              ),
              () => <NoBookingsFound />
            )
          )}
        </Stack>
      </StyledPanelContent>
    </>
  );
};

export const NoBookingsFound: React.FC<{}> = () => {
  return (
    <Stack direction="column">
      <Stack direction="row" mb={3}>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: "12px" }}
        >
          <path
            d="M5.2725 3.15L33.9105 31.788L31.788 33.9105L28.767 30.888C27.7256 31.2936 26.6176 31.5011 25.5 31.5H10.5C8.37484 31.5013 6.31777 30.7506 4.69303 29.3808C3.06829 28.0109 1.98071 26.1103 1.62286 24.0155C1.26501 21.9207 1.65999 19.7668 2.73785 17.9353C3.81571 16.1037 5.50692 14.7127 7.512 14.0085C7.44781 12.7052 7.62738 11.4014 8.0415 10.164L3.15 5.271L5.2725 3.15ZM25.5 13.5C27.0779 13.501 28.6278 13.9165 29.9945 14.7051C31.3612 15.4936 32.4967 16.6275 33.2873 17.993C34.0778 19.3586 34.4957 20.9079 34.499 22.4858C34.5023 24.0636 34.0909 25.6147 33.306 26.9835L21.015 14.694C22.335 13.935 23.865 13.5 25.5 13.5ZM18 3C20.3134 3.00019 22.5621 3.76409 24.397 5.17312C26.2318 6.58215 27.5502 8.55748 28.1475 10.7925C26.5598 10.4361 24.9163 10.4052 23.3163 10.7017C21.7164 10.9981 20.1931 11.6159 18.8385 12.5175L11.541 5.22C13.3848 3.7778 15.6592 2.99607 18 3Z"
            fill="#46495E"
          />
        </svg>
        <div>
          <Typography component={"span"} color={palette.neutral[100]}>
            No Booking for the selected ranges
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export default AssetBookings;

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
const EventTotal = styled(Chip)`
  background-color: ${palette.neutral[600]};
  font-weight: 400;
  text-transform: capitalize;
  padding: 0 8px;
  font-size: 11px;
  height: 20px;
  color: ${palette.primary[300]};
  margin-right: 12px;
  pointer-events: none;
`;
