import React from "react";
import * as O from "fp-ts/Option";
import {
  isoUSDPerOneSqF,
  toUSDPerOneSqF,
  USDPerOneSqF,
  USDPerOneSqM,
} from "lib/at-data/units/currency";
import { flow, pipe } from "fp-ts/function";
import { formatNumber, OneDecimal, USD } from "lib/formatters/formatNumber";

export const USDSqFtSpan = (area: USDPerOneSqF) => (
  <span title={"USD per Square Foot"}>
    {pipe(area, isoUSDPerOneSqF.unwrap, formatNumber(USD))}
  </span>
);

export const AssetSqMPriceCell: React.FC<{ value: O.Option<USDPerOneSqM> }> = ({
  value,
}) =>
  pipe(
    value,
    O.map(flow(toUSDPerOneSqF, USDSqFtSpan)),
    O.getOrElseW(() => null)
  );
