import React, { useCallback } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { flow, pipe } from "fp-ts/function";
import {
  eqUSDPerOneSqM,
  isoUSDPerOneSqF,
  isoUSDPerOneSqM,
  toUSDPerOneSqF,
  toUSDPerOneSqM,
  USDPerOneSqM,
} from "lib/at-data/units/currency";
import * as O from "fp-ts/Option";
import { Stack, TableCell, TableRow } from "@mui/material";
import {
  Editable,
  OptionalProperty,
  USDPerSquareFtProperty,
  USDPerSquareMeterProperty,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import * as n from "fp-ts/number";
import { NumberEditor } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/editors";
import { getAssetCostPerSqM } from "lib/at-data/assets/getters";

export const ZonePricePerAreaPropertyRow: React.FC<{
  asset: Asset;
  onChange: (pricePerSqM: O.Option<USDPerOneSqM>, inheritance: boolean) => void;
}> = (props) => {
  const handleSaveAssetPricePerSqFt = useCallback(
    (pricePerSqFt: number, inheritance: boolean) =>
      props.onChange(
        pipe(pricePerSqFt, isoUSDPerOneSqF.wrap, O.some, O.map(toUSDPerOneSqM)),
        inheritance
      ),
    [props.onChange]
  );
  const handleSaveAssetPricePerSqM = useCallback(
    (pricePerSqM: number, inheritance: boolean) =>
      props.onChange(
        pipe(pricePerSqM, isoUSDPerOneSqM.wrap, O.some),
        inheritance
      ),
    [props.onChange]
  );

  const { costPerSqM } = props.asset;
  return (
    <>
      <TableRow key={"costPerSqMRow"} sx={{ border: 0 }}>
        <TableCell component="th" scope="row" sx={{ borderBottom: 0 }}>
          Price
        </TableCell>
        <TableCell align="right" sx={{ borderBottom: 0 }}>
          {/*<Inheritable*/}
          {/*  asset={props.asset}*/}
          {/*  value={props.asset.costPerSqM}*/}
          {/*  eq={eqUSDPerOneSqM}*/}
          {/*  lookup={getAssetCostPerSqM}*/}
          {/*>*/}
          <Stack direction={"column"} alignItems={"flex-end"}>
            <Editable
              onChange={handleSaveAssetPricePerSqM}
              value={pipe(
                props.asset.costPerSqM,
                O.map(isoUSDPerOneSqM.unwrap),
                O.getOrElseW(() => 0)
              )}
              eq={n.Eq}
              editor={NumberEditor}
              inheritanceAsset={props.asset}
            >
              <OptionalProperty
                value={props.asset.costPerSqM}
                component={USDPerSquareMeterProperty}
                required={true}
              />
            </Editable>
            <Editable
              onChange={handleSaveAssetPricePerSqFt}
              value={pipe(
                props.asset.costPerSqM,

                O.map(flow(toUSDPerOneSqF, isoUSDPerOneSqF.unwrap)),
                O.getOrElseW(() => 0)
              )}
              eq={n.Eq}
              editor={NumberEditor}
              inheritanceAsset={props.asset}
            >
              <OptionalProperty
                value={props.asset.costPerSqM}
                component={USDPerSquareFtProperty}
                required={true}
              />
            </Editable>
          </Stack>
          {/*</Inheritable>*/}
        </TableCell>
      </TableRow>
    </>
  );
};
