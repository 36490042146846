import * as React from "react";
import { useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import * as TE from "fp-ts/TaskEither";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as RT from "fp-ts/ReaderTask";
import { createReport } from "lib/at-api/reports/reportMetadata";
import { useController } from "../../../../../lib/at-react/defineController";
import { AppController } from "../../../../../controllers/AppController/AppController";
import { toMinimalAppState } from "../../../app/ReadyAppState";
import * as O from "fp-ts/Option";
import {
  useCollectorEffect,
  useCollectorWithDispatch,
} from "lib/at-react/hooks";
import * as L from "monocle-ts/Lens";
import { ReportsController } from "controllers/ReportsController/ReportsController";
import { ReportsL } from "controllers/ReportsController/state";
import * as AD from "lib/at-data/AsyncData";
import * as A from "fp-ts/Array";
import { noop } from "lib/util";
import * as C from "io-ts/Codec";
import { isNonEmptyString } from "newtype-ts/es6/NonEmptyString";
import {
  DefaultReportDescriptions,
  ReportType,
} from "views/authenticated/reports2/componenets/reportsTable/ReportType";
import * as IO from "fp-ts/IO";
import { LoadingButton } from "@mui/lab";
import Icon, { AtUpload } from "../../../../../components/ui/Icon";
import {
  FileContentL,
  FileContentTypeL,
  initialState,
  ReportDateL,
  ReportDepartmentL,
  ReportDescriptionL,
  ReportTitleL,
  ReportTypeL,
  ReportUploadController,
  ReportUploadState,
  toReportRequest,
} from "controllers/ReportUploadController/ReportUploadController";
import {
  SelectReportDate,
  SelectReportType,
} from "views/authenticated/reports2/componenets/reportsTable/UploadReportFields";
import {
  getFile,
  toBase64,
} from "views/authenticated/reports2/componenets/reportsTable/util";
import { NeedsPermission } from "components/featureFlag/FeatureFlag";
import {
  errorNotification,
  NotificationsController,
  successNotification,
} from "controllers/NotificationsController/NotificationsController";

const ErrorMsg = styled("span")`
  color: red;
  &:empty {
    display: none;
  }
`;

export const ReportsUploadDialog: React.FC<{}> = () => {
  const [uploadState, dispatch] = useCollectorWithDispatch(
    ReportUploadController,
    L.composeLens(L.id<ReportUploadState>())
  );
  const [reports, reportsDispatch] = useController(
    ReportsController,
    ReportsL.get
  );
  const [notifications, notificationsDispatch] = useCollectorWithDispatch(
    NotificationsController,
    L.prop("notifications")
  );

  const [open, setOpen] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [appState] = useController(AppController, (_) => _);

  const handleUploadReport = useCallback(
    pipe(
      appState,
      toMinimalAppState,
      O.foldW(
        () => TE.of(noop),
        (appContext) =>
          pipe(
            uploadState,
            toReportRequest,
            RTE.fromEither,
            RTE.chainW(createReport),
            RTE.apFirstW(pipe(() => setIsUploading(true), RTE.fromIO)),
            RTE.chainIOK((rep) => () => {
              pipe(ReportsL.modify(AD.map(A.append(rep))), reportsDispatch);
            }),
            RTE.foldW(
              // TODO add error handling if upload fails
              () =>
                pipe(
                  () => setIsUploading(false),
                  RT.fromIO,
                  RT.apSecond(
                    RT.fromIO(() =>
                      pipe(
                        errorNotification("Report uploading Error"),
                        notificationsDispatch
                      )
                    )
                  )
                ),
              () =>
                pipe(
                  () => setOpen(false),
                  RT.fromIO,
                  RT.chainIOK(() =>
                    pipe(
                      () => dispatch(initialState),
                      IO.apFirst(() => setIsUploading(false)),
                      IO.apFirst(() =>
                        pipe(
                          successNotification("Report sucessfully uploaded"),
                          notificationsDispatch
                        )
                      )
                    )
                  )
                )
            )
          )({ appContext })
      )
    ),
    [
      appState,
      uploadState,
      reportsDispatch,
      setOpen,
      reports,
      notificationsDispatch,
    ]
  );

  const isValid = pipe(uploadState, toReportRequest, E.isRight);

  const handleDateChange = useCallback(
    (e: E.Either<Error, string>) => pipe(e, ReportDateL.set, dispatch),
    [dispatch]
  );

  const handleDescriptionChange = useCallback(
    (e) =>
      pipe(
        e.target.value,
        C.string.decode,
        E.mapLeft(() => new Error("Description not a string")),
        E.filterOrElse(
          isNonEmptyString,
          () => new Error("Report Description is required")
        ),
        ReportDescriptionL.set,
        dispatch
      ),
    [dispatch]
  );

  const handleReportTypeChange = useCallback(
    (reportType: string) =>
      dispatch(
        flow(
          pipe(reportType, E.right, ReportTypeL.set),
          pipe(
            DefaultReportDescriptions[reportType as ReportType],
            E.right,
            ReportDescriptionL.set
          )
        )
      ),
    [dispatch]
  );
  const handleFileChange = useCallback(
    (e) => {
      pipe(
        e,
        getFile,
        TE.chainW(toBase64),
        TE.chainW(({ content, contentType }) =>
          pipe(
            dispatch(
              flow(
                pipe(content, E.right, FileContentL.set),
                pipe(contentType, E.right, FileContentTypeL.set)
              )
            ),
            TE.of
          )
        )
      )();
    },
    [dispatch]
  );

  const handleTitleChange = useCallback(
    (e) =>
      pipe(
        e.target.value,
        C.string.decode,
        E.mapLeft(() => new Error("Title not a string")),
        E.filterOrElse(
          isNonEmptyString,
          () => new Error("Report Title is required")
        ),
        ReportTitleL.set,
        dispatch
      ),
    [dispatch]
  );
  const handleDepartmentChange = useCallback(
    (e) =>
      pipe(
        e.target.value,
        C.string.decode,
        E.mapLeft(() => new Error("Department not a string")),
        ReportDepartmentL.set,
        dispatch
      ),
    [dispatch]
  );

  return (
    <div>
      <NeedsPermission permission={"reports:update"}>
        <Button
          onClick={handleClickOpen}
          color="primary"
          size="medium"
          variant="contained"
        >
          <Icon icon={AtUpload} size="24" sx={{ marginRight: "8px" }} />
          Upload Report
        </Button>
      </NeedsPermission>
      <Dialog open={open} onClose={handleClose} sx={{ padding: "24px" }}>
        <DialogTitle>Upload Report</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ paddingTop: "12px" }}>
            <SelectReportType
              reportType={pipe(
                uploadState,
                ReportTypeL.get,
                E.getOrElseW(() => ReportType.RVO_REPORT)
              )}
              onChange={handleReportTypeChange}
            />
            <Stack direction="row" spacing={1}>
              <SelectReportDate
                value={pipe(uploadState, ReportDateL.get)}
                onChange={handleDateChange}
              />
            </Stack>
            <TextField
              error={pipe(uploadState, ReportTitleL.get, E.isLeft)}
              helperText={pipe(
                uploadState,
                ReportTitleL.get,
                E.swap,
                E.map((_) => _.message),
                E.getOrElse(() => "")
              )}
              onChange={handleTitleChange}
              value={pipe(
                uploadState,
                ReportTitleL.get,
                E.getOrElse(() => "")
              )}
              required
              autoFocus
              margin="dense"
              id="name"
              label="Report Title"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue=""
            />
            <TextField
              onChange={handleDepartmentChange}
              value={pipe(
                uploadState,
                ReportDepartmentL.get,
                E.getOrElse(() => "")
              )}
              autoFocus
              margin="dense"
              id="name"
              label="Department"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue=""
            />
            <TextField
              required
              value={pipe(
                uploadState,
                ReportDescriptionL.get,
                E.getOrElse(() => "")
              )}
              error={pipe(uploadState, ReportDescriptionL.get, E.isLeft)}
              helperText={pipe(
                uploadState,
                ReportDescriptionL.get,
                E.swap,
                E.map((_) => _.message),
                E.getOrElse(() => "")
              )}
              onChange={handleDescriptionChange}
              autoFocus
              margin="dense"
              id="name"
              label="Report Description"
              type="textarea"
              fullWidth
              variant="outlined"
              multiline
              maxRows={3}
            />
            <input
              className="custom-file-input"
              type="file"
              onChange={handleFileChange}
            />
          </Stack>
        </DialogContent>
        {/*<FileUpload*/}
        {/*  title={""}*/}
        {/*  // header={""}*/}
        {/*  multiFile={false}*/}
        {/*  onFilesChange={handleFilesChange}*/}
        {/*  // onContextReady={(context) => {}}*/}
        {/*  showPlaceholderImage={false}*/}
        {/*/>*/}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            onClick={handleUploadReport}
            disabled={!isValid}
            loading={isUploading}
            color="primary"
            size="medium"
            variant="contained"
            sx={{ padding: "10px 16px" }}
          >
            <Icon icon={AtUpload} size="24" sx={{ marginRight: "8px" }} />{" "}
            Upload Report
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
