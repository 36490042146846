import React from "react";
import {
  Divider,
  ListItemIcon,
  Menu,
  ListItemText,
  MenuItem,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import Icon, { AtActivityMap, AtAssets, AtDotsMenu } from "components/ui/Icon";
import palette from "theme/palette";
import mixpanel from "mixpanel-browser";

export const AssetAction: React.FC<{
  onClick: () => void;
  icon: React.ElementType;
}> = (props) => (
  <MenuItem>
    <Icon size="16px" icon={props.icon} />

    <ListItemText onClick={props.onClick}>{props.children}</ListItemText>
  </MenuItem>
);

const AssetActionsMenu: React.FC<{}> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    mixpanel.track("More Actions Clicked");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledIconButton onClick={handleClick}>
        <Icon icon={AtDotsMenu} size="16px" />
      </StyledIconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock={true}
        mode="dark"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginLeft: "-40px", marginTop: "-16px", paddingBottom: "32px" }}
      >
        <Typography
          color={palette.neutral[100]}
          sx={{ paddingTop: "8px", paddingBottom: "16px", fontWeight: "500" }}
        >
          More Actions
        </Typography>
        <Divider sx={{ marginBottom: "8px" }} />
        {props.children}
      </Menu>
    </div>
  );
};

export default AssetActionsMenu;

const StyledIconButton = styled(IconButton)`
  color: ${palette.neutral[100]};
  border-radius: 50%;

  &:hover {
    background-color: ${palette.neutral[600]};
  }

  &.Mui-selected {
    color: ${palette.neutral[100]};
  }

  & svg {
    pointer-events: none;
  }
`;
