import * as D from "io-ts/Decoder";

export enum AssetsMetricColumnTypes {
  ASSET_NAME = "assetName",
  ASSET_AREA = "assetArea",
  ASSET_PRICE_PER_AREA = "asset_cost_per_area",
  ASSET_CAPACITY = "asset_capacity",
  ASSET_TARGET_UTILIZATION = "asset_target_utilization",
  ASSET_COST = "asset_cost",
  ASSET_OCCUPANCY_CHART = "asset_occupancy_chart",
  ASSET_MEAN_OCCUPANCY = "asset_mean_occupancy",
  ASSET_MEAN_UTILIZATION = "asset_mean_utilization",
  ASSET_TAGS = "asset_tags",
  ASSET_PEAK_OCCUPANCY = "asset_peak_occupancy",
  ASSET_PEAK_UTILIZATION = "asset_peak_utilization",
  ASSET_PARENT_BUILDING = "asset_parent_building",
  ASSET_LEGACY_ID = "asset_legacy_id",
  ASSET_SHORT_CODE = "asset_short_code",
}

export const AssetsMetricColumnTypeModel = D.union(
  D.literal(AssetsMetricColumnTypes.ASSET_NAME),
  D.literal(AssetsMetricColumnTypes.ASSET_AREA),
  D.literal(AssetsMetricColumnTypes.ASSET_PRICE_PER_AREA),
  D.literal(AssetsMetricColumnTypes.ASSET_CAPACITY),
  D.literal(AssetsMetricColumnTypes.ASSET_TARGET_UTILIZATION),
  D.literal(AssetsMetricColumnTypes.ASSET_COST),
  D.literal(AssetsMetricColumnTypes.ASSET_OCCUPANCY_CHART),
  D.literal(AssetsMetricColumnTypes.ASSET_TAGS),
  D.literal(AssetsMetricColumnTypes.ASSET_MEAN_OCCUPANCY),
  D.literal(AssetsMetricColumnTypes.ASSET_MEAN_UTILIZATION),
  D.literal(AssetsMetricColumnTypes.ASSET_PEAK_OCCUPANCY),
  D.literal(AssetsMetricColumnTypes.ASSET_PEAK_UTILIZATION),
  D.literal(AssetsMetricColumnTypes.ASSET_PARENT_BUILDING),
  D.literal(AssetsMetricColumnTypes.ASSET_LEGACY_ID),
  D.literal(AssetsMetricColumnTypes.ASSET_SHORT_CODE)
);
