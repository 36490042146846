import BaseSpatialMap, {
  CONTINENTAL_US,
} from "components/spatial/base/BaseSpatialMap";
import * as O from "fp-ts/Option";
import React, { useEffect, useState } from "react";
import { Assets } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import { StyledBox } from "components/ui/ImageThumbnail";
import { useController } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import * as R from "fp-ts/lib/Reader";
import { clog, noop } from "lib/util";
import {
  focusOnArea,
  focusOnBuilding,
  scaleBBox,
  updateFloorPlanDiagram,
} from "views/authenticated/assets/page/components/AssetPreviewMap/actions/asset";
import {
  hideAssetFilledPolygonLayer,
  showAssetOutline,
  showAssetPolygon,
} from "components/spatial/base/layers/assetPolygon";
import * as IO from "fp-ts/IO";
import {
  hideDiagramLayer,
  setupFloorDiagramLayer,
} from "components/spatial/base/layers/floorDiagram";
import { getParentAssetOf } from "lib/at-data/assets/filters";
import {
  apFirstIO,
  apSecondIO,
  chainFirstIO,
} from "views/authenticated/activity/page/components/ReaderIO";
import {
  withAssets,
  withFloorDiagramContext,
} from "views/authenticated/assets/page/components/AssetPreviewMap/util";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { Asset } from "lib/at-data/assets/Asset";
import { isBuilding, isFloor } from "lib/at-data/assets/predicates";
import { AssetsContext } from "contexts/AssetsContext";
import { withAuthContext } from "contexts/AuthContext";
import {
  TokenL,
  toMinimalAppState,
} from "views/authenticated/app/ReadyAppState";
import { sequenceT } from "fp-ts/Apply";
import {
  ActivityMapFloorDiagramDataController,
  floorPlanImageL,
} from "controllers/ActivityMapFloorDiagramController/ActivityMapFloorDiagramController";
import { AppController } from "controllers/AppController/AppController";

export const addOrUpdateFloorDiagramLayer = (asset: Asset) =>
  pipe(
    R.asks<AssetsContext & MapboxContext, Assets>((_) => _.assets),
    R.map(getParentAssetOf(isBuilding)(asset)),
    R.chainW(O.fold(() => R.of(noop), flow(updateFloorPlanDiagram)))
  );

const shouldBuildingOutline = (asset: Asset) =>
  pipe(
    R.asks<AssetsContext & MapboxContext, Assets>((_) => _.assets),
    R.map(getParentAssetOf(isBuilding)(asset)),
    R.chain(O.fold(() => R.of(noop), showAssetOutline))
  );

export const showArea = (area: Asset) =>
  pipe(
    focusOnArea(area),
    apSecondIO(
      pipe(
        showAssetPolygon(area),
        apFirstIO(addOrUpdateFloorDiagramLayer(area))
      )
    )
  );

export const showBuilding = (area: Asset) =>
  pipe(
    hideDiagramLayer,
    apFirstIO(focusOnBuilding(area)),
    apSecondIO(showAssetPolygon(area))
  );

export const showFloor = (floor: Asset) =>
  pipe(
    R.Do,
    R.apSW("hideFilled", hideAssetFilledPolygonLayer),
    R.apSW("showDiagram", addOrUpdateFloorDiagramLayer(floor)),
    R.apSW("showOutline", shouldBuildingOutline(floor)),
    R.apSW(
      "focus",
      pipe(
        R.asks<AssetsContext, Assets>((_) => _.assets),
        R.map(getParentAssetOf(isBuilding)(floor)),
        R.chainW(O.fold(() => R.of(noop), focusOnArea))
      )
    ),
    R.map(({ hideFilled, showOutline, focus, showDiagram }) =>
      pipe(
        hideFilled,
        IO.apFirst(showOutline),
        IO.apFirst(showDiagram),
        IO.apFirst(focus)
      )
    )
  );

const AssetDisplayStrategy = {
  [AssetTypes.AREA]: showBuilding,
  [AssetTypes.BUILDING]: showBuilding,
  [AssetTypes.ZONE]: showBuilding,
  [AssetTypes.FLOOR]: showFloor,
  [AssetTypes.UNKNOWN]: showBuilding,
  [AssetTypes.CAMPUS]: showBuilding,
};

const getAssetDisplayIO = (asset: Asset) =>
  AssetDisplayStrategy[asset.type](asset);

export const AssetPreviewMap: React.FC<{ asset: Asset }> = (props) => {
  const [spatialCommandContext, setSpatialCommandContext] = useState<
    O.Option<MapboxContext>
  >(O.none);
  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );
  const [tokenO] = useController(
    AppController,
    flow(toMinimalAppState, O.map(TokenL.get))
  );
  const [floorPlanImage] = useController(
    ActivityMapFloorDiagramDataController,
    floorPlanImageL.get
  );

  useEffect(
    pipe(
      sequenceT(O.Monad)(spatialCommandContext, tokenO),
      O.fold(
        () => noop,
        ([ctx, t]) =>
          pipe(
            ctx,
            withAssets(allAssets),
            withAuthContext(t),
            withFloorDiagramContext(floorPlanImage),
            getAssetDisplayIO(props.asset)
          )
      )
    ),
    [props.asset, allAssets, spatialCommandContext]
  );

  return (
    <StyledBox $fullWidth $aspectRatio={1.5}>
      <BaseSpatialMap
        style={{
          width: "100%",
          border: "none",
          height: "100%",
        }}
        initalBounds={O.some(CONTINENTAL_US)}
        onMapReady={(map) => {
          setSpatialCommandContext(
            O.some({
              map,
            })
          );
        }}
      />
    </StyledBox>
  );
};
