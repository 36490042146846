import * as D from "io-ts/Decoder";
import * as E from "fp-ts/Either";
import * as C from "io-ts/Codec";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import * as En from "io-ts/Encoder";
import * as Eq from "fp-ts/Eq";
import * as S from "fp-ts/Set";

// version to work with io-ts experimental Codecs
export const optionFromNullable = <A, O>(
  c: C.Codec<unknown, O, A>
): C.Codec<unknown, O | null, O.Option<A>> =>
  C.make(
    pipe(
      D.id<unknown>(),
      D.parse((_) =>
        pipe(
          _,
          O.fromNullable,
          O.foldW(() => D.success(O.none), flow(c.decode, E.map(O.some)))
        )
      )
    ),
    pipe(En.id<O | null>(), En.contramap(flow(O.map(c.encode), O.toNullable)))
  );

export const optionFromUndefined = <A, O>(
  c: C.Codec<unknown, O, A>
): C.Codec<unknown, O | undefined, O.Option<A>> =>
  C.make(
    pipe(
      D.id<unknown>(),
      D.parse((_) =>
        pipe(
          _,
          O.fromNullable,
          O.foldW(() => D.success(O.none), flow(c.decode, E.map(O.some)))
        )
      )
    ),
    pipe(
      En.id<O | undefined>(),
      En.contramap(flow(O.map(c.encode), O.toUndefined))
    )
  );

export const set =
  <A, O>(eq: Eq.Eq<A>) =>
  (c: C.Codec<unknown, O, A>): C.Codec<unknown, Array<O>, Set<A>> =>
    C.make(
      pipe(D.array(c), D.map(S.fromArray(eq))),
      pipe(
        En.array(c),
        En.contramap((_) => Array.from(_))
      )
    );
