import React, { useCallback, useState } from "react";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { clog } from "../../../../../../../lib/util";
import { getStringId, UUID } from "../../../../../../../lib/at-data/UUID";
import { Assets } from "../../../../../../../lib/at-data/assets/assets";
import { Asset } from "../../../../../../../lib/at-data/assets/Asset";
import * as b from "fp-ts/boolean";
import { TextField } from "@mui/material";
import { AssetBreadcrumbs } from "./AssetNameCell";
import { getAssetById } from "../../../../../../../lib/at-data/assets/filters";
import palette from "../../../../../../../theme/palette";
import { getParentId } from "lib/at-data/assets/getters";

export const AssetParentBuildingCell: React.FC<{
  value: O.Option<Asset>;
}> = ({ value }) => {
  // console.log(value);
  return (
    <span>
      {pipe(
        value,
        O.fold(
          () => "",
          (_) => _.name
        )
      )}
    </span>
  );
};
