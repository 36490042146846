import React from "react";

export const DowJonesLogo = () => (
  <div>
    <svg
      width="120"
      height="121"
      viewBox="0 0 120 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1222 105.304C20.2831 105.322 19.4446 105.424 18.9168 105.603C17.8208 105.976 16.6894 106.916 16.1719 107.884C15.8197 108.544 15.7789 108.905 15.7255 111.85C15.6869 113.98 15.7371 115.323 15.8716 115.772C16.3226 117.278 17.4918 118.447 19.0539 118.955C19.5483 119.115 20.3897 119.183 21.4752 119.149C24.0856 119.069 25.5662 118.223 26.4019 116.336C26.7429 115.566 26.8089 115.067 26.8695 112.796C26.9506 109.756 26.7608 108.533 26.0407 107.461C25.4708 106.612 24.3596 105.812 23.3304 105.508C22.8009 105.351 21.9613 105.286 21.1222 105.304ZM21.3541 107.921C22.1021 107.942 22.84 108.203 23.3338 108.718L23.8394 109.245L23.889 112.08C23.9452 115.291 23.8151 115.733 22.6515 116.286C21.3876 116.885 19.7157 116.517 18.9482 115.47C18.6179 115.02 18.5857 114.731 18.5857 112.231C18.5857 109.827 18.6267 109.425 18.9168 108.997C19.4141 108.263 20.3924 107.894 21.3541 107.921Z"
        fill="white"
      />
      <path
        d="M57.3343 119.032C55.9043 118.601 55.0022 117.558 54.7657 116.062L54.626 115.178H56.0671C57.5073 115.178 57.5084 115.179 57.7287 115.71C58.113 116.638 59.2232 116.88 60.0639 116.218L60.5227 115.857L60.5691 110.658L60.6154 105.459H62.0164H63.4174L63.4639 110.615C63.5183 116.646 63.4289 117.218 62.2792 118.202C61.8953 118.531 61.276 118.891 60.9029 119.003C60.1361 119.233 58.0573 119.249 57.3343 119.032Z"
        fill="white"
      />
      <path
        d="M72.436 105.297C70.9849 105.295 70.638 105.357 69.7219 105.781C68.4244 106.381 67.976 106.791 67.3896 107.91C66.9567 108.736 66.9269 108.955 66.8642 111.791C66.8109 114.204 66.8542 114.99 67.0845 115.782C67.5368 117.338 68.9346 118.635 70.5972 119.041C71.6025 119.287 73.7685 119.215 74.7362 118.903C76.0741 118.472 77.3869 117.164 77.7609 115.89C78.1392 114.601 78.1523 109.841 77.781 108.591C77.4165 107.363 76.5311 106.405 75.2051 105.801C74.2371 105.361 73.8927 105.298 72.436 105.297ZM72.4757 107.932C73.0636 107.943 73.653 108.072 74.0453 108.314C74.9654 108.883 75.0629 109.27 75.0629 112.36C75.0629 114.937 75.0363 115.164 74.6832 115.613C74.4744 115.879 74.0433 116.204 73.7255 116.337C72.424 116.881 70.7704 116.49 70.1635 115.494C69.8501 114.98 69.8098 114.613 69.8098 112.228C69.8098 109.253 69.9239 108.841 70.9163 108.255C71.3017 108.027 71.8878 107.921 72.4757 107.932Z"
        fill="white"
      />
      <path
        d="M111.519 118.945C110.51 118.63 109.514 117.921 108.977 117.134C108.502 116.436 108.081 115.098 108.251 114.823C108.31 114.729 108.969 114.652 109.716 114.652C111.074 114.652 111.076 114.653 111.326 115.209C111.75 116.151 112.395 116.491 113.76 116.491C115.175 116.491 115.692 116.163 115.692 115.265C115.692 114.444 115.241 114.153 113.121 113.607C110.681 112.979 110.422 112.869 109.573 112.101C108.066 110.74 107.947 108.292 109.313 106.78C111.335 104.542 116.088 104.821 117.711 107.274C117.957 107.645 118.204 108.235 118.261 108.586L118.364 109.223H116.955C115.648 109.223 115.522 109.191 115.222 108.779C114.225 107.41 111.314 107.737 111.314 109.217C111.314 109.968 111.928 110.34 114.044 110.871C116.176 111.405 116.773 111.669 117.579 112.434C118.177 113.001 118.67 114.278 118.668 115.253C118.665 116.943 117.533 118.431 115.877 118.923C114.735 119.262 112.57 119.273 111.519 118.945Z"
        fill="white"
      />
      <path
        d="M1.4237 105.459L1.37786 112.245L1.33203 119.031H4.69358C8.71155 119.031 9.66925 118.835 10.8899 117.76C12.3031 116.516 12.4454 116.064 12.5136 112.596C12.5614 110.165 12.5178 109.41 12.2899 108.724C11.8826 107.498 11.0102 106.569 9.70923 105.975C8.6074 105.473 8.59151 105.471 5.01373 105.465L1.4237 105.459ZM4.31323 108.173H6.17495C7.72041 108.173 8.13183 108.232 8.59418 108.518C9.53502 109.099 9.6545 109.526 9.6545 112.304C9.6545 115.108 9.52078 115.489 8.32842 116.097C7.87846 116.327 7.30013 116.404 6.02069 116.404L4.31323 116.404V112.289V108.173Z"
        fill="white"
      />
      <path
        d="M32.0706 118.637C31.7828 117.432 29.5312 105.948 29.5312 105.684C29.5312 105.41 29.7021 105.371 30.9177 105.371H32.3042L32.475 106.203C32.5689 106.66 32.9447 108.668 33.31 110.665C33.6754 112.662 33.9981 114.272 34.0272 114.243C34.0562 114.214 34.4804 112.856 34.9699 111.225C35.4593 109.595 36.0573 107.606 36.2988 106.807L36.7378 105.354L37.6346 105.406L38.5313 105.458L39.8395 109.88C40.559 112.312 41.1702 114.28 41.1978 114.252C41.2254 114.225 41.6068 112.285 42.0454 109.943C42.484 107.601 42.8864 105.614 42.9397 105.527C43.0563 105.339 45.5881 105.316 45.7022 105.502C45.7464 105.574 45.1807 108.648 44.4452 112.332L43.1079 119.031H41.5252H39.9425L38.8255 115.44C38.2112 113.466 37.6615 111.852 37.6039 111.854C37.5464 111.856 37.2494 112.663 36.9439 113.649C36.6384 114.634 36.1353 116.248 35.8259 117.236L35.2635 119.031H33.7141C32.252 119.031 32.1595 119.008 32.0706 118.637Z"
        fill="white"
      />
      <path
        d="M81.4091 112.243L81.455 105.457L82.856 105.409L84.2569 105.361L86.9714 109.698L89.6858 114.035L89.7326 109.702L89.7794 105.37H91.1774H92.5753V112.199V119.029L91.1305 119.029L89.6858 119.028L87.0589 114.834L84.4321 110.64L84.3852 114.835L84.3383 119.029H82.8508H81.3633L81.4091 112.243Z"
        fill="white"
      />
      <path
        d="M96.2529 112.198V105.365L100.762 105.412L105.272 105.459V106.772V108.086L102.251 108.133L99.23 108.181V109.486V110.791L101.901 110.839L104.571 110.888L104.623 112.157L104.674 113.427H101.952H99.23V114.915V116.404H102.295H105.359V117.717V119.031H100.806H96.2529V112.198Z"
        fill="white"
      />
      <path
        d="M27.4297 40.3951V1.33789L48.9261 1.3917L70.4225 1.4455L72.2613 1.90605C81.1769 4.13905 87.7161 9.83361 90.916 18.1512C91.6863 20.1535 92.3929 23.3975 92.5226 25.5279L92.6285 27.2655H86.1332H79.6379L79.5369 26.4336C79.2011 23.6694 78.3292 21.4673 76.7668 19.4367C75.204 17.4058 72.8527 15.7592 70.2474 14.8713L68.8464 14.3939L54.7052 14.3442L40.5639 14.2945V40.3938V66.4931H47.0435H53.5231V72.9727V79.4523H40.4764H27.4297V40.3951Z"
        fill="#13A3D7"
      />
      <path
        d="M63.3301 73.0243V66.5463L65.6505 66.4283C66.9267 66.3635 68.4698 66.1933 69.0797 66.0502C72.8232 65.1715 76.4485 62.3089 78.1368 58.8985C79.5489 56.0457 79.498 56.4955 79.5713 46.2225L79.6365 37.0723H86.1166H92.5966L92.5305 47.0105L92.4644 56.9488L92.0114 58.7876C90.7382 63.9553 88.5285 67.9093 84.8682 71.5696C81.8974 74.5404 78.6146 76.6083 74.7408 77.9491C71.8479 78.9503 69.9437 79.2829 66.4385 79.3992L63.3301 79.5024V73.0243Z"
        fill="#13A3D7"
      />
    </svg>
  </div>
);
