import React from "react";
import { createTheme } from "@mui/material";
import { alpha, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import typography, { FontStyles } from "theme/typography";
import palette from "./palette";
import { vars, breakpoints, responsiveSize } from "./helpers";
import Icon from "components/ui/Icon";
import { RiCloseFill } from "react-icons/ri";

declare module "@mui/material/Snackbar" {
  interface SnackbarProps {
    /**
     * Color Theme Context
     */
    mode: "light" | "dark";
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxProps {
    /**
     * Color Theme Context
     */
    mode?: "light" | "dark";
  }
}

declare module "@mui/material/Radio" {
  interface RadioProps {
    /**
     * Color Theme Context
     */
    mode?: "light" | "dark";
  }
}

declare module "@mui/material/Switch" {
  interface SwitchProps {
    /**
     * Color Theme Context
     */
    mode?: "light" | "dark";
  }
}

declare module "@mui/material/FormControlLabel" {
  interface FormControlLabelProps {
    /**
     * Color Theme Context
     */
    mode?: "light" | "dark";
  }
}

declare module "@mui/material/TextField" {
  interface BaseTextFieldProps {
    /**
     * Color Theme Context
     */
    mode?: "light" | "dark";
  }
}

declare module "@mui/material/Menu" {
  interface MenuProps {
    /**
     * Color Theme Context
     */
    mode?: "light" | "dark";
    /**
     * Controls whether or not there is an arrow pointing to the selection associated to the menu list.
     */
    hasArrow?: boolean;
  }
}

declare module "@mui/material/InputBase" {
  interface InputBaseProps {
    /**
     * Color Theme Context
     */
    mode?: "light" | "neutral" | "dark";
    inlineLabel?: boolean;
  }
}

declare module "@mui/material/Select" {
  interface SelectProps {
    inlineLabel?: boolean;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    light?: true;
  }
}

const baseTheme = createTheme({
  palette,
  typography,
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          min-width: 320px;
          font-size: 62.5%; // 1rem = 10px
        }

        body {
          background-color: ${palette.neutral[800]};
          background-color: ${palette.neutral[800]};
          width: 100%;
          max-width: 100vw;
          min-height: 100vh;
          padding-top: ${vars.navBar};
          font-size: 1.6rem;
          font-family: "Fira Sans", sans-serif;
          font-variant-ligatures: no-common-ligatures;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        * {
          &::-moz-focus-inner {
            border: 0;
          }
          &:focus {
            outline-offset: 2px;
          }
        }
      `,
    },
    MuiCheckbox: {
      defaultProps: {
        mode: "light",
        size: "small",
        checkedIcon: <span className="checkbox checkbox--checked" />,
        icon: <span className="checkbox" />,
      },
      variants: [
        {
          props: { mode: "light" },
          style: {
            color: palette.neutral[400],
            "&.Mui-disabled .checkbox": {
              color: palette.neutral[400],
              backgroundColor: palette.neutral[200],
            },
            "&:hover .checkbox, &.Mui-focused .checkbox": {
              backgroundColor: palette.primary[100],
              color: palette.primary[600],
            },
            "&.Mui-checked": {
              color: palette.primary[600],
              "&.Mui-disabled .checkbox": {
                backgroundColor: palette.primary[300],
                color: palette.primary[300],
              },
              "& .checkbox": {
                backgroundColor: palette.primary[600],
              },
            },
          },
        },
        {
          props: { mode: "dark" },
          style: {
            color: palette.neutral[500],
            "&.Mui-disabled .checkbox": {
              color: palette.neutral[500],
              backgroundColor: palette.neutral[600],
            },
            "&:hover .checkbox, &.Mui-focused .checkbox": {
              color: palette.primary[300],
              backgroundColor: palette.neutral[900],
            },
            "&.Mui-checked": {
              color: palette.primary[300],
              "&.Mui-disabled .checkbox": {
                backgroundColor: palette.primary[300],
                color: palette.primary[200],
              },
              "& .checkbox": {
                backgroundColor: palette.primary[400],
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          padding: 8,
          borderRadius: 0,
          display: "flex",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "& .checkbox": {
            boxShadow: "inset 0 0 0 1px",
            height: 16,
            width: 16,
            borderRadius: 2,
            transition:
              "box-shadow ease-out 150ms, color ease-out 150ms, background-color ease-out 150ms",
          },
          "& .checkbox--checked": {
            background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M8.33329 2.91675L3.74996 7.50008L1.66663 5.41675' stroke='white' stroke-width='2' stroke-linecap='square'/%3E %3C/svg%3E ") center/50% no-repeat`,
          },
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        mode: "light",
      },
      variants: [
        {
          props: { mode: "light" },
          style: {
            color: palette.neutral[900],
            "&:hover .checkbox, &.Mui-focused .checkbox": {
              backgroundColor: palette.primary[100],
              color: palette.primary[600],
            },
            "&.Mui-checked": {
              color: palette.primary[600],
              "&.Mui-disabled .checkbox": {
                backgroundColor: palette.primary[300],
                color: palette.primary[300],
              },
              "& .checkbox": {
                backgroundColor: palette.primary[600],
              },
            },
          },
        },
        {
          props: { mode: "dark" },
          style: {
            color: palette.neutral[100],
            "&& .Mui-disabled": {
              color: alpha(palette.neutral[100], 0.3),
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          marginLeft: -8,
          "&.MuiFormControlLabel-labelPlacementStart": {
            marginLeft: 0,
          },
        },
        label: {
          fontSize: "1.2rem",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "subtitle1" },
          style: {
            textTransform: "uppercase",
          },
        },
        {
          props: { color: "textTertiary" },
          style: {
            color: palette.text.tertiary,
          },
        },
      ],
    },
    MuiModal: {
      defaultProps: {
        disableScrollLock: false,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: responsiveSize(1.4, 1.2),
          fontWeight: 500,
          color: palette.neutral[500],
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
      variants: [
        {
          props: { mode: "dark" },
          style: {
            "&.MuiRadio-root.Mui-checked": {
              color: palette.primary[300],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          height: 32,
          width: 32,
        },
      },
    },
    MuiSwitch: {
      variants: [
        {
          props: { mode: "light" },
          style: {
            "& .MuiSwitch-track": {
              background: palette.neutral[300],
            },
            "& .MuiSwitch-thumb": {
              boxShadow: "0 1px 3px 0 rgb(0 0 0 /0.2)",
              backgroundColor: palette.neutral[100],
            },
          },
        },
        {
          props: { mode: "dark" },
          style: {
            "& .MuiSwitch-track": {
              background: palette.neutral[500],
            },
            ".Mui-disabled &.MuiSwitch-root": {
              opacity: 0.4,
            },
            ".Mui-disabled && .MuiSwitch-track": {
              opacity: 1,
            },
            "&& .Mui-checked+.MuiSwitch-track": {
              background: palette.primary[300],
            },
            "& .MuiSwitch-thumb": {
              boxShadow: "0 1px 3px 0 rgb(0 0 0 /0.2)",
              backgroundColor: palette.neutral[100],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          width: 64,
        },
        input: {
          top: 2,
          left: 6,
          "&.Mui-checked": {
            transform: "translateX(17px)",
            color: palette.neutral[100],
          },
        },
        thumb: {
          height: 16,
          width: 16,
          boxShadow: "0 1px 2px 0 rgb(0 0 0 /0.2)",
          backgroundColor: palette.neutral[300],
        },
        track: {
          background: palette.neutral[100],
          opacity: 1,
          height: 24,
          borderRadius: 12,
          alignSelf: "center",
          ".Mui-checked+&&": {
            opacity: 1,
            background: palette.primary[600],
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
          alignItems: "center",
          width: "auto",
          padding: 0,
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
      variants: [
        {
          props: { mode: "light" },
          style: {
            "& .MuiInputBase-root": {
              background: palette.neutral[200],

              "&.Mui-disabled, &.Mui-disabled:hover": {
                background: palette.neutral[300],
              },
            },
          },
        },
        {
          props: { mode: "dark" },
          style: {
            label: {
              color: palette.neutral[300],

              "&.Mui-focused": {
                color: palette.primary[300],
              },
            },

            "& .MuiInputBase-root": {
              background: palette.neutral[700],
              color: palette.neutral[400],
              boxShadow: `inset 0 0 0 0px ${palette.neutral[400]}`,

              "&.Mui-disabled, &.Mui-disabled:hover": {
                background: palette.neutral[200],
                boxShadow: `inset 0 0 0 0px ${palette.neutral[400]}`,
              },

              "&:hover": {
                boxShadow: `inset 0 0 0 1px ${palette.neutral[500]}`,
              },

              "&.Mui-focused": {
                boxShadow: `inset 0 0 0 1.5px ${palette.primary[400]}`,
              },
            },

            "& .MuiSvgIcon-root": {
              pointerEvents: "none",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },

            "& .MuiFormHelperText-root": {
              marginLeft: 0,
              color: palette.neutral[500],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          display: "inline-flex",
          flexDirection: "column",

          label: {
            position: "relative",
            transform: "none",
            fontSize: responsiveSize(1.4, 1.2),
            fontWeight: 500,
            marginBottom: vars.spacing * 0.5,
            color: palette.neutral[500],
          },

          "& .MuiInputBase-multiline": {
            padding: "2px 2px 2px 0",
            "& textarea": {
              padding: `${vars.spacing}px ${vars.spacing * 1.5}px`,
            },
          },

          input: {
            fontSize: responsiveSize(1.6, 1.4),
            padding: 0,
          },

          "& :not(.MuiSelect-root) svg": {
            height: 16,
            width: 16,
          },

          "& .MuiSvgIcon-root": {
            pointerEvents: "none",
          },

          "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
          },

          "& .MuiFormHelperText-root": {
            marginLeft: 0,
          },

          "& .MuiSelect-root": {
            padding: 0,

            svg: {
              color: "inherit",
            },
          },

          "&& svg + .MuiSelect-select": {
            paddingLeft: `${vars.spacing}px`,
          },

          "&& .MuiSelect-select": {
            gap: vars.spacing,
            width: "100%",
            minWidth: 100,
            padding: `${vars.spacing}px ${vars.spacing * 4}px ${
              vars.spacing
            }px ${vars.spacing * 1.5 + 2}px `,
          },
        },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: { mode: "light" },
          style: {
            background: palette.neutral[200],
            "&.Mui-disabled, &.Mui-disabled:hover": {
              background: palette.neutral[300],
            },
          },
        },
        {
          props: { mode: "dark" },
          style: {
            background: palette.neutral[600],
            color: palette.neutral[400],
            boxShadow: `inset 0 0 0 0px ${palette.neutral[400]}`,

            "&.Mui-disabled, &.Mui-disabled:hover": {
              background: palette.neutral[200],
              boxShadow: `inset 0 0 0 0px ${palette.neutral[400]}`,
            },

            "&:hover": {
              boxShadow: `inset 0 0 0 1px ${palette.neutral[500]}`,
            },

            "&.Mui-focused": {
              boxShadow: `inset 0 0 0 1.5px ${palette.primary[400]}`,
            },

            "& .MuiSvgIcon-root": {
              pointerEvents: "none",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },

            "& .MuiFormHelperText-root": {
              marginLeft: 0,
              color: palette.neutral[500],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          background: palette.neutral[100],
          padding: `${vars.spacing}px ${vars.spacing * 1.5}px`,
          boxShadow: `inset 0 0 0 1px ${palette.neutral[400]}`,
          display: "inline-flex",
          gap: vars.spacing,
          transition: "box-shadow ease-out 150ms, color ease-out 150ms",

          "&.Mui-disabled, &.Mui-disabled:hover": {
            background: palette.neutral[200],
            boxShadow: `inset 0 0 0 1px ${palette.neutral[400]}`,
          },

          "&:hover": {
            boxShadow: `inset 0 0 0 1px ${palette.neutral[500]}`,
          },

          "&.Mui-focused": {
            boxShadow: `inset 0 0 0 1.5px ${palette.primary[400]}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&[data-shrink='false']": {
            transform: `translate(16px, 12.5px) scale(1)`,
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 0,
        mode: "light",
      },
      variants: [
        {
          props: { mode: "light" },
          style: {
            ".MuiList-root": {
              backgroundColor: palette.neutral[100],
              color: palette.neutral[900],
              filter: `drop-shadow(0 2px 16px ${alpha(
                palette.neutral[800],
                0.2
              )}) `,
            },
            ".MuiDivider-root": {
              backgroundColor: palette.neutral[300],
            },
            ".MuiPaper-root::before": {
              backgroundColor: "red",
            },
          },
        },
        {
          props: { mode: "dark" },
          style: {
            "& .MuiList-root": {
              backgroundColor: palette.neutral[700],
              color: palette.neutral[100],
              filter: `drop-shadow(0 2px 16px ${alpha(
                palette.neutral[900],
                0.4
              )}) `,
            },
            "& .MuiDivider-root": {
              backgroundColor: palette.neutral[500],
            },
            ".MuiPaper-root::before": {
              backgroundColor: palette.neutral[700],
            },
            ".MuiMenuItem-root": {
              "&:hover, &:focus": {
                background: palette.neutral[600],
                color: palette.primary[300],
              },
            },
          },
        },
        {
          props: { hasArrow: true },
          style: {
            ".MuiPaper-root::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      ],
      styleOverrides: {
        list: {
          backgroundColor: palette.neutral[700],
          color: palette.neutral[100],
          padding: `${vars.spacing}px ${vars.spacing * 2}px`,

          "& .MuiListItemIcon-root": {
            color: "inherit",
            "&": {
              minWidth: vars.spacing * 4,
            },
          },
          "& .MuiDivider-root": {
            width: `calc(100% + ${vars.spacing * 4}px)`,
            transform: `translateX(-${vars.spacing * 2}px)`,
          },
        },
        paper: {
          marginTop: `${vars.spacing}px`,
          "&": {
            padding: 0,
            backgroundColor: "transparent",
            overflow: "visible",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: vars.spacing,
          margin: "auto",
          borderRadius: 2,
          gap: vars.spacing,
          transition: "background ease-out 150ms, color ease-out 150ms",
          "[role='listbox'] &": {},
          "&.Mui-selected": {
            fontWeight: 800,
            backgroundColor: "initial",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            "&:focus": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            "& .MuiListItemText-primary": {
              fontWeight: 800,
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        variant: "contained",
        color: "inherit",
        size: "medium",
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            "--buttonBg": palette.primary[600],
            "--buttonLabelColor": palette.neutral[100],
            "--buttonHoverBg": palette.primary[500],
            "--buttonFocusOutline": palette.primary[600],
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            "--buttonBg": palette.neutral[700],
            "--buttonLabelColor": palette.neutral[100],
            "--buttonHoverBg": palette.neutral[500],
            "--buttonFocusOutline": palette.neutral[700],
          },
        },
        {
          props: { variant: "text", color: "primary" },
          style: {
            "--buttonLabelColor": palette.primary[700],
            "--buttonHoverBg": palette.neutral[300],
          },
        },
        {
          props: { variant: "text", color: "secondary" },
          style: {
            "--buttonLabelColor": palette.neutral[300],
            "--buttonHoverBg": palette.neutral[600],
            "& svg": {
              fill: palette.neutral[400],
            },
          },
        },
        {
          props: { variant: "text", size: "small" },
          style: {
            fontSize: "1.2rem",
          },
        },
        {
          props: { variant: "contained", size: "small" },
          style: {
            padding: `${vars.spacing}px ${vars.spacing * 2}px`,
          },
        },
        {
          props: { variant: "contained", size: "medium" },
          style: {
            padding: `${vars.spacing * 1.5}px ${vars.spacing * 3}px`,
          },
        },
        {
          props: { variant: "contained", size: "large" },
          style: {
            padding: `${vars.spacing * 2}px ${vars.spacing * 3.5}px`,
          },
        },
      ],
      styleOverrides: {
        root: {
          color: "var(--buttonLabelColor)",
          fontSize: responsiveSize(1.6, 1.4),
          textTransform: "none",
          fontWeight: 600,
          lineHeight: "initial",
          borderRadius: 4,
        },
        contained: {
          "--buttonBg": palette.neutral[200],
          "--buttonLabelColor": palette.primary[700],
          "--buttonHoverBg": palette.neutral[300],
          "--buttonFocusOutline": palette.neutral[400],
          padding: `${vars.spacing * 2}px ${vars.spacing * 3}px`,
          boxShadow: "none",
          backgroundColor: "var(--buttonBg)",
          "&:hover, &:focus": {
            boxShadow: "none",
            backgroundColor: "var(--buttonHoverBg)",
          },
          "&:focus": {
            boxShadow: `inset 0 0 0 1.5px var(--buttonFocusOutline)`,
          },
        },
        text: {
          "--buttonLabelColor": palette.neutral[500],
          "--buttonHoverBg": palette.neutral[300],
          minWidth: 0,
          padding: `${vars.spacing * 0.5}px ${vars.spacing * 0.75}px`,
          "&:hover, &:focus": {
            backgroundColor: "var(--buttonHoverBg)",
          },
          "& .MuiButton-startIcon": {
            marginRight: vars.spacing,
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
      variants: [
        {
          props: { color: "primary" },
          style: {
            backgroundColor: palette.neutral[700],
            color: palette.neutral[300],

            "&:hover, &:focus": {
              backgroundColor: palette.neutral[600],
            },
          },
        },
        {
          props: { color: "secondary" },
          style: {
            color: palette.neutral[500],
            backgroundColor: palette.neutral[300],

            "&:hover, &:focus": {
              backgroundColor: palette.neutral[300],
              color: palette.neutral[700],
            },
          },
        },
        {
          props: { size: "small" },
          style: {
            height: 24,
            width: 24,

            svg: {
              height: 16,
              width: 16,
            },
          },
        },
        {
          props: { size: "medium" },
          style: {
            height: 32,
            width: 32,

            svg: {
              height: 16,
              width: 16,
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            height: 40,
            width: 40,

            svg: {
              height: 24,
              width: 24,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 4,

          "&:hover, &:focus": {
            backgroundColor: palette.neutral[200],
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: palette.primary[500],
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          position: "relative",
          zIndex: 1,
        },
        paper: {
          overflow: "auto",
          backgroundColor: palette.neutral[700],
          width: vars.contextualSidebarDrawer,
          paddingRight: 56,
          paddingTop: vars.navBar,
          boxShadow: `-6px 0 12px ${alpha(palette.neutral[800], 0.14)}`,
          borderRadius: 0,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <Icon icon={RiCloseFill} />,
      },
      variants: [
        {
          props: { variant: "light" },
          style: {
            backgroundColor: palette.primary[100],
            color: palette.primary[700],
          },
        },
        {
          props: { color: "primary", variant: "filled" },
          style: {
            backgroundColor: palette.neutral[700],
            color: palette.neutral[100],
          },
        },
        {
          props: { color: "primary", variant: "outlined" },
          style: {
            color: palette.neutral[700],
            border: `1px solid ${palette.neutral[700]}`,
          },
        },
        {
          props: { color: "secondary", variant: "filled" },
          style: {
            backgroundColor: palette.primary[200],
            color: palette.primary[600],
          },
        },
        {
          props: { color: "secondary", variant: "outlined" },
          style: {
            color: palette.neutral[900],
            border: `1px solid ${palette.neutral[400]}`,
          },
        },
        {
          props: { color: "success", variant: "filled" },
          style: {
            backgroundColor: palette.status.success.default,
          },
        },
        {
          props: { color: "success", variant: "light" },
          style: {
            backgroundColor: palette.status.success.light,
            color: palette.status.success.dark,
          },
        },
        {
          props: { color: "success", variant: "outlined" },
          style: {
            color: palette.status.success.dark,
            border: `1px solid ${palette.status.success.default}`,
          },
        },
        {
          props: { color: "warning", variant: "filled" },
          style: {
            backgroundColor: palette.status.warning.default,
          },
        },
        {
          props: { color: "warning", variant: "light" },
          style: {
            backgroundColor: palette.status.warning.light,
            color: palette.status.warning.dark,
          },
        },
        {
          props: { color: "warning", variant: "outlined" },
          style: {
            color: palette.status.warning.dark,
            border: `1px solid ${palette.status.warning.default}`,
          },
        },
        {
          props: { color: "error", variant: "filled" },
          style: {
            backgroundColor: palette.status.error.default,
          },
        },
        {
          props: { color: "error", variant: "light" },
          style: {
            backgroundColor: palette.status.error.light,
            color: palette.status.error.dark,
          },
        },
        {
          props: { color: "error", variant: "outlined" },
          style: {
            color: palette.status.error.dark,
            border: `1px solid ${palette.status.error.default}`,
          },
        },
        {
          props: { size: "small" },
          style: {
            padding: `0 ${vars.spacing * 1.5}px`,
            height: 25,
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.75,
            textTransform: "uppercase",
            fontSize: "1.3rem",
          },
        },
        {
          props: { size: "medium" },
          style: {
            padding: `0 ${vars.spacing * 2.5}px`,
            height: 33,
            fontSize: "1.5rem",
          },
        },
      ],
      styleOverrides: {
        root: {
          gap: vars.spacing,
          backgroundColor: palette.neutral[200],

          "& .MuiChip-deleteIcon": {
            margin: 0,
            borderRadius: "50%",
            padding: "1px",
            boxSizing: "content-box",

            "&:hover": {
              backgroundColor: palette.neutral[300],
            },

            "& path:nth-of-type(2)": {
              strokeWidth: 1,
              color: palette.neutral[700],
            },
          },
        },
        icon: {
          margin: 0,
        },
        label: {
          padding: 0,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.neutral[100],
          color: palette.text.primary,
          boxShadow: `0 5px 20px 0 ${alpha(
            palette.neutral[900],
            0.25
          )}, 0 0 0 1px ${palette.neutral[100]}`,
          borderRadius: 4,
          padding: `${vars.spacing / 2}px ${vars.spacing * 1.5}px`,
          fontSize: "1.4rem",
          fontWeight: 400,

          "& .MuiTooltip-arrow::before": {
            backgroundColor: palette.neutral[100],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          backgroundColor: palette.neutral[500],
        },
      },
    },
    MuiSnackbar: {
      variants: [
        {
          props: { mode: "light" },
          style: {
            ".MuiPaper-root": {
              backgroundColor: palette.neutral[100],
              color: palette.neutral[900],
            },
            ".MuiDivider-root": {
              backgroundColor: palette.neutral[300],
            },
            ".MuiButton-text": {
              "--buttonLabelColor": palette.primary[600],
              "--buttonHoverBg": palette.neutral[300],
            },
            ".MuiIconButton-root": {
              color: palette.neutral[500],

              "&:hover, &:focus": {
                backgroundColor: palette.neutral[300],
              },
            },
          },
        },
        {
          props: { mode: "dark" },
          style: {
            ".MuiPaper-root": {
              backgroundColor: palette.neutral[700],
              color: palette.neutral[100],
            },
            ".MuiDivider-root": {
              backgroundColor: palette.neutral[500],
            },
            ".MuiButton-text": {
              "--buttonLabelColor": palette.neutral[300],
              "--buttonHoverBg": palette.neutral[600],
            },
            ".MuiIconButton-root": {
              backgroundColor: palette.neutral[700],
              color: palette.neutral[300],

              "&:hover, &:focus": {
                backgroundColor: palette.neutral[600],
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          maxWidth: "70%",
          borderRadius: 2,
          ".MuiPaper-root": {
            boxShadow: `-0 4px 20px ${alpha(palette.neutral[900], 0.25)}`,
            padding: 0,
          },
        },
      },
    },
  },
});

export default baseTheme;

export const BaseStylingProvider: React.FC = (props) => (
  <ThemeProvider theme={baseTheme}>
    <CssBaseline />
    <FontStyles />
    {props.children}
  </ThemeProvider>
);
