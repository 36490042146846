import { Route, Switch } from "react-router";
import React from "react";
import ManageAssets from "./page/manageAssets/ManageAssets";
import SettingsSidebar from "./page/components/SettingsSidebar";
import { Box, styled } from "@mui/material";
import { vars } from "theme/helpers";
import palette from "theme/palette";
import theme from "theme/theme";
import SettingsPage from "./page/SettingsPage";

const SettingsRouter: React.FC = () => (
  <Main>
    {/*<SettingsSidebar />*/}
    <Content>
      <SettingsPage />
    </Content>
  </Main>
);

export default SettingsRouter;

const Main = styled(Box)`
  // display: grid;
  // grid-template-columns: 300px 1fr;
  display: flex;
  min-height: calc(100vh - ${vars.navBar});
  background-color: ${palette.neutral[200]};
`;

const Content = styled(Box)`
  width: 100%;
  padding: ${theme.spacing(8)} ${theme.spacing(10)};
  height: 100%;
  position: relative;
  overflow: hidden;
`;
