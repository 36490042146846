import React, { useCallback, useRef, useState } from "react";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import {
  eqGeoReference,
  GeoReference,
  mapGeoPoints,
} from "lib/at-data/assets/models/GeoReferenceModel";
import { pipe } from "fp-ts/function";
import { sequenceT } from "fp-ts/Apply";
import { gsap } from "gsap";
import { toMatrix } from "views/authenticated/lucifer/lib";
import {
  applyToPoints,
  compose,
  fromTriangles,
  identity,
  inverse,
  Matrix,
  rotateDEG,
  scale,
  translate,
} from "transformation-matrix";
import palette from "theme/palette";
import { Position, Positionable, TransformBox } from "re-position";
import { Asset } from "lib/at-data/assets/Asset";
import {
  useCollectorOld,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { ImageArtifactsController } from "controllers/FloorBackgroundImagesController/FloorBackgroundImagesController";
import * as L from "monocle-ts/Lens";
import { BackgroundArtifactsL } from "controllers/FloorBackgroundImagesController/lens";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import * as AD from "lib/at-data/AsyncData";
import { RawAssetsController } from "views/authenticated/root/AssetsController";
import { ById } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import { AssetGeoReferenceL } from "lib/at-data/assets/lens";
import { LambentArtifact } from "lib/at-data/Artifact";
import { useStableEffect } from "fp-ts-react-stable-hooks";
import * as Eq from "fp-ts/Eq";
import { Coord } from "lib/at-data/assets/models/Coord";
import { pointsToEdges, toSVGRect } from "lib/at-data/Edge";
import {
  LegacyAssetsModificationsController,
  modifyGeoreference,
} from "controllers/AssetsController/AssetsController";
import {
  WORKSPACE_HEIGHT,
  WORKSPACE_WIDTH,
} from "views/authenticated/lucifer/consts";
import { lineInterpolate, lineLength } from "geometric";
import { Stack } from "@mui/material";
import { BsFileLockFill } from "react-icons/all";
import Icon from "components/ui/Icon";
import { getPoly } from "lib/at-data/assets/getters";

export const getGeoChange = (end: GeoReference) => (start: GeoReference) =>
  fromTriangles(
    pipe(
      start,
      A.map((_) => _.geo)
    ),
    pipe(
      end,
      A.map((_) => _.geo)
    )
  );

export const removePx = (position: string): number =>
  Number(position.substr(0, Math.max(position.length - 2, 0)));
export const removeDeg = (rotation: string): number =>
  Number(rotation.substr(0, Math.max(rotation.length - 3, 0)));

export const LocalPlaneControls: React.FC<{ fp: LambentArtifact }> = ({
  fp,
}) => (
  <div
    style={{
      position: "absolute",
      top: "-1.5em",
      right: 0,
      color: palette.neutral["300"],
    }}
  >
    <Stack direction={"row"} alignItems={"center"} alignContent="center">
      <Icon icon={BsFileLockFill} size={"32px"} />
      <div>
        {fp.planeWidth}px x {fp.planeHeight}px
      </div>
    </Stack>
  </div>
);

const LocalPlane: React.FC<{
  fp: LambentArtifact;
  disabled: boolean;
  localDiff: Matrix;
  georeference: O.Option<GeoReference>;
  onChange: (diff: Matrix) => void;
  render: (
    fp: LambentArtifact,
    localTransform: Matrix
  ) => React.ReactElement | null;
}> = ({ fp, onChange, disabled, georeference, render }) => {
  const heightWidthRatio = fp.planeHeight / fp.planeWidth;
  const screenWidth = WORKSPACE_WIDTH;
  const screenHeight = WORKSPACE_WIDTH * heightWidthRatio;
  const screenLeft = WORKSPACE_WIDTH;
  const screenTop = (WORKSPACE_WIDTH * 3 - WORKSPACE_HEIGHT) / 2;

  const [localOffset, setLocalOffset] = useState(0);
  const handleLayerReposition = useCallback(
    (position: Position) => {
      const rotation = pipe(position.rotation, removeDeg);
      const left = removePx(position.left);
      const top = removePx(position.top);
      const width = removePx(position.width);
      const height = removePx(position.height);

      const scaleX = width / fp.planeWidth;
      const scaleY = height / fp.planeHeight;

      const centerX = fp.planeWidth / 2;
      const centerY = fp.planeHeight / 2;

      const scaleDiffX = width / screenWidth;
      const scaleDiffY = height / screenHeight;

      onChange(
        compose(
          scale(scaleDiffX, scaleDiffY, 0, 0),
          translate((left - screenLeft) / scaleX, (top - screenTop) / scaleY),

          rotateDEG(rotation, centerX, centerY)
        )
      );
      // setLocalOffset(position);
    },
    [fp, onChange]
  );

  useStableEffect(
    () => {
      setLocalOffset(localOffset + 1);
    },
    [georeference],
    Eq.tuple(O.getEq(eqGeoReference))
  );

  return (
    <Positionable
      disabled={disabled}
      onUpdate={handleLayerReposition}
      movable
      resizable
      rotatable
      position={{
        left: `${screenLeft}px`,
        top: `${screenTop}px`,
        rotation: `${0}deg`,
        width: `${screenWidth}px`,
        height: `${screenHeight}px`,
      }}
      render={({ renderedPosition, refHandlers }) => {
        return (
          <>
            <TransformBox
              position={renderedPosition}
              refHandlers={refHandlers}
              resizable={["se"]}
              rotatable={true}
            />

            <div
              ref={refHandlers.container}
              style={{
                zIndex: 1000,
                position: "absolute",
                height: `${renderedPosition.height}`,
                left: `${renderedPosition.left}`,
                top: `${renderedPosition.top}`,
                width: `${renderedPosition.width}`,
                transform: `rotate(${renderedPosition.rotation})`,
              }}
            >
              <LocalPlaneControls fp={fp} />
              <div
                ref={refHandlers.dnd}
                style={{
                  position: "relative",
                  cursor: "move",
                  userSelect: "none",
                  // backgroundColor: "rgba(80, 80, 80, 0.5)",
                  border: !disabled
                    ? `1px dashed ${palette.neutral["300"]}`
                    : `1px dashed ${palette.status.warning.dark}`,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={renderedPosition.width}
                  height={renderedPosition.height}
                  // viewBox={`0 0 ${fp.planeWidth} ${fp.planeHeight}`}
                >
                  <image
                    href={fp.data}
                    width={renderedPosition.width}
                    height={renderedPosition.height}
                  />
                  {render(fp, scale(WORKSPACE_WIDTH / fp.planeWidth))}
                </svg>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export const WorkingLayer: React.FC<{
  disabled: boolean;
  referenceGeoReference: O.Option<GeoReference>;
  level: Asset;
  render: (
    fp: LambentArtifact,
    localMatrix: Matrix
  ) => React.ReactElement | null;
}> = ({ level, referenceGeoReference, render, disabled }) => {
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );

  const isGeoreferenced = pipe(level.geoReference, O.isSome);
  const backgroundImageArtifacts = useCollectorOld(
    ImageArtifactsController,
    L.composeLens(BackgroundArtifactsL)
  );
  const image = pipe(backgroundImageArtifacts, getSliceByUUID(level.id));
  console.log("iamge", backgroundImageArtifacts);

  const localDiff = pipe(
    sequenceT(O.Monad)(
      pipe(referenceGeoReference),
      pipe(
        level.geoReference,
        O.alt(() => referenceGeoReference)
      )
    ),
    O.map(([start, end]) => {
      const diff = pipe(start, getGeoChange(end));
      return compose(inverse(toMatrix(start)), compose(diff, toMatrix(start)));
    }),
    O.getOrElseW(() => identity())
  );

  const handleLayerReposition = useCallback(
    (transformMatrix: Matrix) => {
      const georef = pipe(
        referenceGeoReference,
        O.map((geoRef) =>
          pipe(
            geoRef,
            mapGeoPoints((points) => {
              return applyToPoints(
                compose(
                  toMatrix(geoRef),
                  compose(transformMatrix, inverse(toMatrix(geoRef)))
                ),
                points
              );
            })
          )
        )
      );
      rootDispatch(
        pipe(
          ById(level.id),
          updateAsset,
          T.composeLens(AssetGeoReferenceL),
          T.set(georef)
        )
      );
      assetModificationsDispatch(modifyGeoreference(level, georef));
    },
    [level, referenceGeoReference, rootDispatch, assetModificationsDispatch]
  );

  return pipe(
    image,
    AD.DoneData,
    AD.fold(
      () => <span>loading</span>,
      O.foldW(
        () => null,
        (fp) => (
          <LocalPlane
            localDiff={localDiff}
            disabled={!isGeoreferenced || disabled}
            onChange={handleLayerReposition}
            georeference={referenceGeoReference}
            fp={fp}
            render={render}
          />
        )
      )
    )
  );
};

export const ZonePolygon: React.FC<{
  localMatrix: Matrix;
  onClick: (zone: Asset) => void;
  zone: Asset;
}> = (props) => {
  const handleSelectZone = useCallback(
    () => props.onClick(props.zone),
    [props.zone]
  );

  return pipe(
    props.zone,
    getPoly,
    O.map((poly) => applyToPoints(props.localMatrix, poly)),
    O.foldW(
      () => null,
      (poly) => <NonEditablePolygon points={poly} onClick={handleSelectZone} />
    )
  );
};

export const NonEditablePolygon: React.FC<{
  points: Array<Coord>;
  onClick: () => void;
}> = (props) => {
  const elRef = useRef(null);

  const vectors = pipe(props.points, pointsToEdges);

  const handleMouseOver = useCallback(() => {
    gsap.to(elRef.current, 0.25, {
      fillOpacity: 0.5,
    });
  }, []);
  const handleMouseOut = useCallback(() => {
    gsap.to(elRef.current, 0.25, {
      fillOpacity: 0,
    });
  }, []);
  const handleClick = useCallback(() => props.onClick(), [props.onClick]);

  return (
    <polygon
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      ref={elRef}
      // style={{ zIndex: 2000 }}
      points={pipe(vectors, toSVGRect)}
      stroke={palette.status.warning.dark}
      fill={palette.status.warning.dark}
      strokeWidth={"0.25%"}
      strokeOpacity={0.5}
      fillOpacity={0}
    />
  );
};
