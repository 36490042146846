import { AssetActionsController } from "controllers/AssetsController/AssetActionsController";
import {
  UserPreferencesController,
  UserPreferencesControllerComponent,
} from "controllers/UserPreferencesController/UserPreferencesController";
import { flow, pipe } from "fp-ts/function";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import {
  useCollector,
  useCollectorDispatch,
  useCollectorWithDispatch,
} from "lib/at-react/hooks";
import * as L from "monocle-ts/Lens";
import { isNonEmptyString } from "newtype-ts/es6/NonEmptyString";
import ReactDOM from "react-dom";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { History } from "history";
import { DashboardSettings } from "models/DashboardSettings";
import { Router, StaticRouter } from "react-router";
import LambentDashboard, { TenantInitialization } from "LambentDashboard";
import * as O from "fp-ts/Option";
import * as IO from "fp-ts/IO";
import { Foyer } from "Foyer";
import React from "react";
import { UIStateControllerComponent } from "controllers/UIStateCollector/UIStateController";
import { RouteContext } from "contexts/RouteContext";
import { BaseStylingProvider } from "theme/theme";
import { dataProviderL } from "views/authenticated/app/controller/state";
import {
  AssetsControllerComponent,
  RawAssetsControllerComponent,
} from "views/authenticated/root/AssetsController";
import { ShareLinkControllerComponent } from "controllers/AppController/ShareLinkController";
import {
  AppCollector,
  AppController,
  AppControllerComponent,
} from "controllers/AppController/AppController";
import { LegacyAssetsModificationsControllerComponent } from "controllers/AssetsController/AssetsController";
import { FeatureFlagControllerComponent } from "controllers/FeatureFlagController/FeatureFlagController";
import { VisibleAssetsControllerComponent } from "controllers/VisibleAssetsController/VisibleAssetsController";
import { ToggleMenuControllerComponent } from "./controllers/AppController/ToggleMenuController";
import { LocalArtifactsControllerComponent } from "controllers/LocalArtifactsController/LocalArtifactsController";
import { HistoryContext } from "lib/at-react/defineHistoryController";
import { LogMessage } from "views/authenticated/app/RunningAppState";
import { FavoriteAssetsController } from "views/authenticated/root/FavoriteAssetsController";
import { NotificationsController } from "controllers/NotificationsController/NotificationsController";

export const renderInitializationError =
  (e: Error): IO.IO<void> =>
  () => {
    ReactDOM.render(
      <div>
        Error starting application: <strong>{e.message}</strong>
      </div>,
      document.getElementById("root")
    );
  };

export const TempShim = () => {
  const actions = useCollector(AssetActionsController, L.prop("actions"));

  return (
    <AssetsControllerComponent context={{ actions }}>
      <VisibleAssetsControllerComponent context={{}}>
        <LegacyAssetsModificationsControllerComponent context={{}}>
          <LocalArtifactsControllerComponent context={{}}>
            <LambentDashboard />
          </LocalArtifactsControllerComponent>
        </LegacyAssetsModificationsControllerComponent>
      </VisibleAssetsControllerComponent>
    </AssetsControllerComponent>
  );
};

export const App: React.FC<{}> = (props) => {
  const [appState] = useController(AppController, (_) => _);

  return pipe(
    appState.user,
    O.map(dataProviderL.get),
    O.filter(isNonEmptyString),
    O.fold(
      () => <TenantInitialization />,
      () => (
        <AppCollector.Component context={appState}>
          <UserPreferencesControllerComponent context={{}}>
            <FeatureFlagControllerComponent context={{}}>
              <NotificationsController.Component context={{}}>
                <ShareLinkControllerComponent context={{}}>
                  <ToggleMenuControllerComponent context={{}}>
                    <RawAssetsControllerComponent context={{}}>
                      <FavoriteAssetsController.Component
                        context={{ window, localStorageKey: "favoriteAssets" }}
                      >
                        <AssetActionsController.Component context={{}}>
                          <TempShim />
                        </AssetActionsController.Component>
                      </FavoriteAssetsController.Component>
                    </RawAssetsControllerComponent>
                  </ToggleMenuControllerComponent>
                </ShareLinkControllerComponent>
              </NotificationsController.Component>
            </FeatureFlagControllerComponent>
          </UserPreferencesControllerComponent>
        </AppCollector.Component>
      )
    )
  );
};

export const renderAuthenticatedApplication =
  (
    authClient: Auth0Client,
    routerHistory: History,
    settings: DashboardSettings,
    logger: (msg: LogMessage) => void
  ): IO.IO<void> =>
  () => {
    ReactDOM.render(
      <BaseStylingProvider>
        <UIStateControllerComponent
          context={{
            ...HistoryContext<any>(routerHistory),
            ...RouteContext(""),
          }}
        >
          <AppControllerComponent
            context={{
              settings,
              authClient,
              logger,
            }}
          >
            <App />
          </AppControllerComponent>
        </UIStateControllerComponent>
      </BaseStylingProvider>,
      document.getElementById("root")
    );
  };

export const renderFoyer =
  (
    authClient: Auth0Client,
    location: Location,
    authOrganization: O.Option<string>
  ): IO.IO<void> =>
  () => {
    ReactDOM.render(
      <Foyer
        authClient={authClient}
        authOrganization={authOrganization}
        location={location}
      />,
      document.getElementById("root")
    );
  };
