import { flow, pipe } from "fp-ts/function";
import * as RT from "fp-ts/ReaderTask";
import * as T from "fp-ts/Task";
import * as R from "fp-ts/Reader";
import { LoggerContext } from "controllers/AppController/AppController";
import * as TE from "fp-ts/TaskEither";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import { noop } from "lib/util";
import { AppState } from "views/authenticated/app/controller/state";
import { toMinimalAppState } from "views/authenticated/app/ReadyAppState";
import {
  controllerLog,
  LogLevel,
  LogMessage,
} from "views/authenticated/app/RunningAppState";
import * as IO from "fp-ts/IO";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as STE from "fp-ts/StateReaderTaskEither";
import * as E from "fp-ts/Either";
import { AppContext } from "contexts/AppContext";
import { Dispatch, SetStateAction } from "react";
import { AsyncData } from "lib/at-data/AsyncData";
import * as O from "fp-ts/Option";
import * as AD from "lib/at-data/AsyncData";
import * as AD2 from "lib/at-data/AsyncData2";

export enum AsyncEffectStates {
  PENDING = "pending",
  DONE = "done",
  ERROR = "error",
}
export type logEffect = (msg: LogMessage) => void;

export const logAsyncEffectState = (
  controllerName: string,
  state: AsyncEffectStates
) =>
  pipe(
    RT.ask<LoggerContext>(),
    RT.chainIOK((ctx) => () => {
      ctx.logger(controllerLog(LogLevel.INFO, `[${controllerName}] ${state}`));
    })
  );
export const logAsyncEffectError =
  <E>(controllerName: string) =>
  (e: E) =>
    pipe(
      RT.ask<LoggerContext>(),
      RT.chainIOK((ctx) => () => {
        ctx.logger(controllerLog(LogLevel.ERROR, `[${controllerName}] ${e}`));
      })
    );

export const callIfDelayed =
  <R, E, A>(delayedTimeout: number, delayedIO: IO.IO<void>) =>
  (eff: RTE.ReaderTaskEither<R, E, A>) =>
    pipe(
      eff,
      RTE.apFirstW(
        pipe(
          RTE.ask<AbortingControllerContext>(),
          RTE.chainW(({ abortingController }) =>
            pipe(
              RTE.fromIO(() =>
                setTimeout(() => {
                  if (!abortingController.signal.aborted) {
                    delayedIO();
                  }
                }, delayedTimeout)
              )
            )
          )
        )
      )
    );

export const loggingAsyncEffect =
  <R, E, A>(
    controllerName: string,
    pendingIO: () => IO.IO<void>,
    doneIO: (data: A) => IO.IO<void>,
    errorIO: (error: E) => IO.IO<void>
  ) =>
  (
    eff: RTE.ReaderTaskEither<R, E, A>
  ): RT.ReaderTask<
    R & LoggerContext & AppContext & AbortingControllerContext,
    void
  > =>
    pipe(
      logAsyncEffectState(controllerName, AsyncEffectStates.PENDING),
      RT.apFirst(RT.fromIO(pendingIO())),
      RT.map(E.right),
      RTE.apSecondW(eff),
      RTE.foldW(
        (e) =>
          pipe(
            e,
            logAsyncEffectError(controllerName),
            RT.apFirst(RT.fromIO(errorIO(e)))
          ),
        (data) =>
          pipe(
            logAsyncEffectState(controllerName, AsyncEffectStates.DONE),
            RT.apFirst(RT.fromIO(doneIO(data)))
          )
      )
    );

/**
 * @deprecated
 */
export const loggingAsyncDataEffectLegacy =
  <R, E, A>(
    controllerName: string,
    stateIO: (r: AsyncData<A>) => IO.IO<void>
  ) =>
  (eff: RTE.ReaderTaskEither<R, E, A>) =>
    pipe(
      eff,
      loggingAsyncEffect(
        controllerName,
        () => pipe(O.none, AD.PendingData, stateIO),
        (data) => pipe(data, O.some, AD.DoneData, stateIO),
        (e) => pipe(O.none, AD.PendingData, stateIO)
      )
    );

export const loggingAsyncDataEffect =
  <R, A>(
    controllerName: string,
    stateIO: (r: AD2.AsyncData2<A>) => IO.IO<void>
  ) =>
  (eff: RTE.ReaderTaskEither<R, Error, A>) =>
    pipe(
      eff,
      loggingAsyncEffect(
        controllerName,
        () => pipe(O.none, AD2.pending, stateIO),
        (data) => pipe(data, AD2.done, stateIO),
        (e) => pipe(e, AD2.error, stateIO)
      )
    );

// export const appEffect = <R extends AppContext, R2, E, A>(
//   eff: RT.ReaderTask<R, void>
// ) =>
//   pipe(
//     RT.ask<AppState & Omit<R, keyof AppContext>>(),
//     RT.chainW(({ logger, user, accessToken, authClient, wifiAPI, ...rest }) =>
//       pipe(
//         { logger, user, accessToken, authClient, wifiAPI },
//         toMinimalAppState,
//         O.fold(
//           () => pipe(noop, RT.fromIO),
//           (ras) => pipe(eff({ appContext: ras, ...rest }), RT.fromTask)
//         )
//       )
//     )
//   );
