import React from "react";

export const PurdueLogo = () => (
  <div>
    <svg
      width="135"
      height="73"
      viewBox="0 0 135 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.753906 71.8896L11.9175 45.6044H26.3287L34.3462 26.8292H19.935L31.0987 0.543945H110.36C117.363 0.543945 127.106 1.35584 131.774 8.35848C136.443 15.3611 132.891 23.5816 130.759 28.5545C125.076 41.8493 116.856 50.6787 95.3402 50.6787H80.4215L71.4906 71.8896H0.753906Z"
        fill="black"
      />
      <path
        d="M127.715 11.0984C125.178 7.24189 119.697 5.5166 110.462 5.5166H34.3464L27.3437 22.0591H41.6534L29.5764 50.4755H15.1652L8.1626 67.018H68.2432L75.2458 50.4755H60.7331L62.6613 45.9086H95.3403C116.348 45.9086 122.031 36.7747 126.294 26.7275C128.628 21.2472 130.658 15.5639 127.715 11.0984V11.0984ZM72.8101 22.0591H96.8626C98.5879 22.0591 99.3998 22.465 99.6028 22.7695C99.8057 22.9724 99.7043 23.6829 99.2983 24.7992C98.0805 27.6409 95.1373 29.4676 91.5853 29.4676H69.664L72.8101 22.0591Z"
        fill="#9D9795"
      />
      <path
        d="M110.462 8.05371H36.0716L31.2002 19.5218H45.5099L31.2002 53.0126H16.8904L12.019 64.4807H66.6193L71.4907 53.0126H56.8765L61.0375 43.3713H95.4417C115.13 43.3713 120.103 35.0494 124.061 25.7125C128.019 16.3757 130.15 8.05371 110.462 8.05371V8.05371ZM91.5852 31.9033H65.9089L71.1862 19.5218H96.8625C101.429 19.5218 103.256 21.856 101.632 25.7125C99.9072 29.569 96.1521 31.9033 91.5852 31.9033V31.9033Z"
        fill="#CFB991"
      />
    </svg>
  </div>
);
