import React from "react";
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled,
  Box,
} from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import Icon, { AtChevronDown, AtSchedule } from "components/ui/Icon";

const CompareBookings: React.FC<{}> = () => {
  return (
    <Accordion defaultExpanded={false}>
      <SectionHeader
        expandIcon={
          <Icon
            icon={AtChevronDown}
            size="24px"
            color={`${palette.neutral[100]}`}
          />
        }
        aria-controls="panel-content-bookings"
        id="panel-header-bookings"
      >
        <Icon
          icon={AtSchedule}
          size="24px"
          color={`${palette.neutral[100]}`}
          sx={{ marginRight: "12px" }}
        />
        Bookings
      </SectionHeader>
      <SectionDetails>
        <Bookings>
          <Pane>
            <Stack spacing={2}>
              <Typography>
                Overall Expected (from Bookings) vs Actual for Space
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                pt={1}
              >
                <Typography
                  variant="body2"
                  sx={{ width: "60px" }}
                  color={palette.neutral[100]}
                >
                  Expected:
                </Typography>
                <Box
                  sx={{
                    width: `390px`,
                    backgroundColor: `${palette.primary[400]}`,
                    height: "20px",
                  }}
                />
                <Typography color={palette.neutral[100]}>
                  {" "}
                  60% (3,239)
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography
                  variant="body2"
                  sx={{ width: "60px" }}
                  color={palette.neutral[100]}
                >
                  Actual:
                </Typography>
                <Box
                  sx={{
                    width: `135px`,
                    backgroundColor: `${palette.data.sequential[800]}`,
                    height: "20px",
                  }}
                />
                <Typography color={palette.neutral[100]}>
                  {" "}
                  21% (1,244)
                </Typography>
              </Stack>
            </Stack>
          </Pane>
          <Pane>
            <Stack spacing={2}>
              <Typography>
                Overall Expected (from Bookings) vs Actual for Space
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                pt={1}
              >
                <Typography
                  variant="body2"
                  sx={{ width: "60px" }}
                  color={palette.neutral[100]}
                >
                  Expected:
                </Typography>
                <Box
                  sx={{
                    width: `390px`,
                    backgroundColor: `${palette.primary[400]}`,
                    height: "20px",
                  }}
                />
                <Typography color={palette.neutral[100]}>
                  {" "}
                  80% (4,239)
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Typography
                  variant="body2"
                  sx={{ width: "60px" }}
                  color={palette.neutral[100]}
                >
                  Actual:
                </Typography>
                <Box
                  sx={{
                    width: `270px`,
                    backgroundColor: `${palette.data.sequential[800]}`,
                    height: "20px",
                  }}
                />
                <Typography color={palette.neutral[100]}>
                  {" "}
                  67% (2,498)
                </Typography>
              </Stack>
            </Stack>
          </Pane>
        </Bookings>
      </SectionDetails>
    </Accordion>
  );
};

export default CompareBookings;

const SectionHeader = styled(AccordionSummary)`
  background-color: ${palette.neutral[650]};
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 0 32px;
  &:hover {
    background-color: ${palette.neutral[600]};
  }
`;
const SectionDetails = styled(AccordionDetails)`
  background-color: #46495e;
  padding: 0;
`;
const Bookings = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;

const Pane = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: #323548;
  color: #ffffff;
  padding: 32px;
  &:first-of-type {
    border-right: 1px solid hsla(0, 0%, 0%, 0.75);
  }
  &:last-of-type {
    border-left: 1px solid hsla(0, 100%, 100%, 0.15);
  }
`;
const MetricKpi = styled(Stack)`
  width: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 0;
`;

const KpiContainer = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0 32px;
`;
const Heading = styled(Typography)`
  color: #999999;
  font-size: 18px;
`;
const Metric = styled(Typography)`
  font-size: 36px;
`;
const Hint = styled(Typography)`
  font-size: 12px;
`;
