import { Divider, Drawer, DrawerProps, styled } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { vars } from "theme/helpers";
import ActivityDrawerHeader from "./ActivityDrawerHeader";
import ActivityDrawerList from "./ActivityDrawerList";
import { Assets } from "lib/at-data/assets/assets";
import * as O from "fp-ts/Option";
import { Asset } from "lib/at-data/assets/Asset";
import palette from "../../../../../../theme/palette";

interface IDrawer extends DrawerProps {
  onDrawerClose: () => void;
  onSelectAsset: (asset: O.Option<Asset>) => void;
  assets: Assets;
  selectedAssets: Array<Asset>;
}

const ActivityDrawer: React.FC<IDrawer> = React.memo(
  ({ onDrawerClose, onSelectAsset, selectedAssets, assets, ...props }) => {
    return (
      <StyledDrawer {...props}>
        <ActivityDrawerHeader handleDrawerClose={onDrawerClose} />
        <Divider light sx={{ my: 1 }} />
        <ActivityDrawerList
          selected={selectedAssets}
          assets={assets}
          onSelectAsset={onSelectAsset}
        />
      </StyledDrawer>
    );
  }
);

export default ActivityDrawer;

const drawerWidth = 300;

const StyledDrawer = styled(Drawer)`
  position: relative;
  width: ${drawerWidth}px;
  height: 0;
  flex-shrink: 0;
  z-index: 3;

  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    box-sizing: border-box;
    margin: ${vars.navBar} 0 0;
    height: calc(100vh - ${vars.navBar});
    border-radius: 0;
    overflow: hidden;
    padding: 0;
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.neutral[500]};
    outline: 1px solid ${palette.neutral[600]};
    border-radius: 5px;
  }
`;
