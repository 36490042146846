import React from "react";
import styled from "styled-components";

export const vars = {
  nav: 56,
  header: 88,
  tabs: 56,
};
export const palette = {
  neutral: {
    100: "#FFFFFF",
    200: "#EFF1F6",
    300: "#E2E4E9",
    400: "#BBBFC9",
    450: "#8E919F",
    500: "#6A6E80",
    600: "#46495E",
    650: "#3b3e52",
    700: "#323548",
    800: "#1D1E2F",
    900: "#0A0E1F",
  },
  primary: {
    100: "#F1F3FF",
    200: "#DBDBFC",
    300: "#B3A9FD",
    400: "#887CDE",
    500: "#685BC7",
    600: "#5635BB",
    700: "#400099",
    800: "#350080",
    900: "#2A0066",
  },
};

export const Page = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${palette.neutral[200]};

  & button {
    outline: none;
    border: 0;
    padding: 8px 12px;
    border-radius: 4px;
    font-family: Fira Sans, sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-position: center;
    transition: background 0.65s;
    // margin: 24px 18px 12px;
  }
  & button.primary {
    background-color: ${palette.primary[600]};
    color: ${palette.neutral[100]};

    // Hover
    &:hover {
      background: ${palette.primary[500]}
        radial-gradient(circle, transparent 1%, ${palette.primary[500]} 1%)
        center/15000%;
    }

    // Click
    &:active {
      background-color: ${palette.primary[600]};
      background-size: 100%;
      transition: background 0s;
    }
  }
`;
export const Main = styled("main")`
  display: flex;
  flex-direction: column;
  // height: calc(100vh - ${vars.nav}px);
  width: 100%;
`;

export const Content = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${palette.neutral[200]};
  padding: 24px;
  height: 100%;
  min-height: calc(
    100vh - calc(${vars.nav}px + ${vars.header}px + ${vars.tabs}px)
  );
  position: relative;

  @media (max-width: 768px) {
  }
`;
export const ColRight = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  // position: absolute;
  // top: 24px;
  // transform: translateX(300px);
  padding: 0;
  margin-left: 24px;

  @media (max-width: 767px) {
    // transform: translateX(-320px);
    transition: transform 0.5s ease-out;
    width: 100%;
    margin-left: 0;
  }

  // & > .accordion {
  //   background-color: ${palette.neutral[100]};
  //   border-radius: 4px;
  //   margin-bottom: 12px;
  //   padding: 0;
  //
  //   & > .accordion-header {
  //     padding: 12px 24px;
  //     font-size: 18px;
  //     font-weight: 700;
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: flex-start;
  //     align-content: center;
  //     align-items: center;
  //     cursor: pointer;
  //
  //     &:hover {
  //       background-color: ${palette.neutral[200]};
  //     }
  //   }
  //
  //   & > .accordion-content {
  //     padding: 24px 0;
  //   }
`;

export const FloorList = styled("ul")`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    margin: 0;
    padding: 8px 18px;
    display: flex;
    flex-direction: row;

    align-items: center;
    align-content: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background-color: ${palette.neutral[200]};
    }

    &[data-state="selected"] {
      background-color: #ddd;
    }
    & > .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 50%;
      background-color: ${palette.primary[600]};
      aspect-ratio: 1 / 1;
      color: ${palette.neutral[100]};
      font-weight: 600;
      font-size: 18px;
      padding: 4px;
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
    & > .text {
      display: flex;
      flex-direction: column;
      width: 100%;
      & strong {
        margin: 0;
        white-space: nowrap;
      }
      & small {
        margin: 0;
        white-space: nowrap;
      }
    }
    & > button {
      aspect-ratio: 1 / 1;
      cursor: pointer;
      border-radius: 50%;
      border: none;
      padding: 4px;
      width: 32px;
      height: 32px;
      margin-left: 8px;
      background-color: transparent;

      &:hover {
        background-color: ${palette.neutral[300]};
      }
    }
  }
`;
export const Form = styled("form")`
  padding: 0;
  border: 0;
  & fieldset {
    padding: 0 0 32px 0;
    border: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 500;

    legend {
      width: 100%;
      padding: 16px 24px 24px;
      border-top: 1px solid ${palette.neutral[200]};
    }
  }

  & div {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    width: 50%;
    padding: 4px 24px;
    & label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      color: ${palette.neutral[500]};
    }
    & input[type="text"],
    & select {
      padding: 12px;
      font-size: 16px;
      border: 0.5px solid ${palette.neutral[400]};
      border-radius: 2px;
      outline: none;
    }
  }

  @media (max-width: 1023px) {
    & div {
      width: 100%;
    }
  }
`;
