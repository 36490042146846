import { not, Predicate } from "fp-ts/Predicate";
import * as AD2 from "lib/at-data/AsyncData2";
import * as P from "monocle-ts/Prism";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { AssetsL, RootStateL } from "views/authenticated/root/controller/state";
import * as L from "monocle-ts/Lens";
import * as T from "monocle-ts/Traversal";
import { Assets } from "lib/at-data/assets/assets";
import { Asset } from "lib/at-data/assets/Asset";
import * as AD from "lib/at-data/AsyncData";
import { Coord } from "lib/at-data/assets/models/Coord";
import { of, UUID } from "../UUID";
import * as O from "fp-ts/Option";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { ById } from "lib/at-data/assets/filters";
import { ByAllAssetIds } from "controllers/UIStateCollector/utils";
import { getId } from "lib/at-data/assets/getters";

const assetByPredicate = (predicate: Predicate<Asset>) =>
  P.fromPredicate(predicate);

const assetsTraversal = (predicate: Predicate<Asset>) =>
  pipe(P.id<Assets>(), P.traverse(A.Traversable));

export const updateAsset = (predicate: Predicate<Asset>) =>
  pipe(
    pipe(RootStateL, L.prop("assets")),
    L.imap(
      flow(
        AD2.toOption,
        O.getOrElseW(() => [])
      ),
      AD2.done
    ),
    L.composeTraversal(assetsTraversal(predicate)),
    T.composePrism(assetByPredicate(predicate))
  );

export const makeAsset = (newAsset: Asset) =>
  pipe(
    pipe(RootStateL, L.prop("assets")),
    L.modify(AD2.map((assets) => [newAsset, ...assets]))
  );

export const dropAssets = (assets: Array<Asset>) =>
  pipe(
    pipe(RootStateL, L.prop("assets")),
    L.modify(AD2.map(A.filter(not(ByAllAssetIds(pipe(assets, A.map(getId)))))))
  );

function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, "0");
}

export function generateId(len: number) {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

export const addAsset = (
  id: UUID,
  parent: O.Option<UUID>,
  type: AssetTypes
): Asset => ({
  //temporary
  id,
  parent,
  poly: O.none,
  legacyZoneId: O.none,
  geometry: O.none,
  capacity: O.none,
  name: "New Zone",
  operationHours: O.none,
  geoReference: O.none,
  tags: [],
  shortCode: O.none,
  floorMeta: O.none,
  // subclass: O.none,
  targetUtilization: O.none,
  costPerSqM: O.none,
  type,
  area: O.none,
  bearing: O.none,
  center: O.none,
  diagram: O.none,
  timezone: O.none,
});

export const createZone = (parent: Asset, polygon: Array<Coord>): Asset => ({
  //temporary
  id: pipe(generateId(16), of),
  parent: O.some(parent.id),
  poly: O.some(polygon),
  legacyZoneId: O.none,
  geometry: O.none,
  capacity: O.none,
  name: "New Zone",
  operationHours: O.none,
  geoReference: O.none,
  tags: [],
  shortCode: O.none,
  floorMeta: O.none,
  // subclass: O.none,
  targetUtilization: O.none,
  costPerSqM: O.none,
  type: AssetTypes.ZONE,
  area: O.none,
  bearing: O.none,
  center: O.none,
  diagram: O.none,
  timezone: O.none,
});
