import { subHours } from "date-fns/fp";
import * as DS from "lib/at-data/DataSeries";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import { calculateResolutionByNumberOfBuckets } from "lib/at-data/requests/temporal/FromNumberOfBuckets";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { initialState } from "./initialState";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as IO from "fp-ts/lib/IO";
import * as Eq from "fp-ts/Eq";
import { getSingleOccupancyCountsSeriesMT } from "views/authenticated/assets/actions/workerActions";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import {
  AssetsHeaderChartState,
  AssetsHeaderChartStateEq,
  RootSeriesL,
} from "./state";
import { AssetOContext } from "contexts/AssetContext";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import { getLiveTime } from "controllers/AssetLiveOccupancyController/ActivityLiveOccupancyController";
import { defineAppController } from "lib/at-react/defineAppController";
import { loggingAsyncDataEffectLegacy } from "lib/logging";

export const NUMBER_OF_CHART_DATA_POINTS = 50;

export const getChartOccupancySeries = pipe(
  getLiveTime,
  RTE.fromIO,
  RTE.map((end) =>
    pipe(
      SeriesRequest(pipe(end, subHours(24)), end),
      calculateResolutionByNumberOfBuckets(NUMBER_OF_CHART_DATA_POINTS)
    )
  ),
  RTE.chainW((request) =>
    pipe(
      RTE.asks<AssetOContext, O.Option<Asset>>((_) => _.asset),
      RTE.chainW(
        O.fold(
          () => RTE.of(DS.empty<OccupancyEstimate>(request)),
          (rootAsset) => getSingleOccupancyCountsSeriesMT(rootAsset, request)
        )
      )
    )
  )
);

const [component, controller] = defineAppController<
  AssetOContext,
  AssetsHeaderChartState
>(
  initialState,
  AssetsHeaderChartStateEq,
  Eq.struct({ asset: O.getEq(eqAsset) }),
  (dispatch) =>
    pipe(
      getChartOccupancySeries,
      loggingAsyncDataEffectLegacy(
        "Asset Header Chart",
        flow(RootSeriesL.set, dispatch, IO.of)
      )
    )
);

export const AssetsHeaderChartControllerComponent = component;
export const AssetsHeaderChartController = controller;
