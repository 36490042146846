import {
  FormControl,
  IconButton,
  InputBase,
  InputBaseProps,
  Stack,
  styled,
} from "@mui/material";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import React, { useCallback } from "react";
import { RiCloseCircleLine, RiSearchLine } from "react-icons/ri";
import palette from "theme/palette";
import theme from "theme/theme";
import Icon from "../../../../../../components/ui/Icon";
import DateRangePicker from "components/ui/range/DateRangePicker";
import { useController } from "lib/at-react/defineController";
import { AssetsTableHeaderExportView } from "views/authenticated/assets/page/components/AssetsTable/AssetsTableHeaderExportView";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import {
  AssetsFilterL,
  AssetsPageUIStateL,
  FilterByNameL,
  SelectedRangeL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { AssetsTableFavorites } from "./AssetsTableFavorites";
import * as s from "fp-ts/string";
import * as b from "fp-ts/lib/boolean";

const filterOptions = [
  "Lorem ipsum",
  "Dolor sit amet",
  "Laboris proident ad",
  "Aute ipsum",
];

interface ITableHeaderSearch {
  mode?: "light";
  hideFilters?: boolean;
}

const AssetsTableHeaderSearch: React.FC<ITableHeaderSearch> = (props) => {
  const dispatch = useHistoryControllerDispatch(UIStateController);
  const [selectedRange] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).get
  );

  const [assetsFilter] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).get
  );

  const handleDatesChanged = (series: SeriesRequest) => {
    dispatch(
      pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).set(series)
    );
  };

  const handleSearchTextChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        dispatch(
          pipe(
            AssetsPageUIStateL,
            L.composeLens(AssetsFilterL),
            L.composeLens(FilterByNameL)
          ).set(event.target.value)
        );
      },
      [dispatch]
    );

  const handleClearSearch = useCallback(() => {
    dispatch(
      pipe(
        AssetsPageUIStateL,
        L.composeLens(AssetsFilterL),
        L.composeLens(FilterByNameL)
      ).set("")
    );
  }, [dispatch]);

  return (
    <Stack direction="row" sx={{ width: "100%" }} spacing={0.5}>
      <FormControl fullWidth>
        <SearchBox htmlFor="table-search" $mode={props.mode || false}>
          <SearchIcon>
            <Icon icon={RiSearchLine} size="16px" />
          </SearchIcon>
          <SearchInput
            id="table-search"
            value={FilterByNameL.get(assetsFilter)}
            onChange={handleSearchTextChange}
            placeholder="Search Assets"
            inputProps={{ "aria-label": "search assets" }}
          />
          {pipe(
            FilterByNameL.get(assetsFilter),
            s.isEmpty,
            b.fold(
              () => (
                <IconButton onClick={handleClearSearch} aria-label="Clear">
                  <Icon icon={RiCloseCircleLine} />
                </IconButton>
              ),
              () => <span />
            )
          )}

          {!props.hideFilters ? (
            <FormControl sx={{ flexShrink: 0 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <DateRangePicker
                  series={selectedRange}
                  nowDate={new Date()}
                  onChanged={handleDatesChanged}
                  maxDate={new Date()}
                  minDate={new Date(1970, 0, 1)}
                />
                {/*<FilterSelect*/}
                {/*  value={option}*/}
                {/*  onChange={handleOptionChange}*/}
                {/*  displayEmpty*/}
                {/*  inputProps={{ "aria-label": "Without label" }}*/}
                {/*>*/}
                {/*  {filterOptions.map((value) => (*/}
                {/*    <MenuItem key={value} value={value}>*/}
                {/*      {value}*/}
                {/*    </MenuItem>*/}
                {/*  ))}*/}
                {/*</FilterSelect>*/}
              </Stack>
            </FormControl>
          ) : null}
        </SearchBox>
      </FormControl>
      {/*<HeartIconButton onClick={changeColor}>*/}
      {/*<Stack justifyContent="center" alignItems="center" px={1}>*/}
      {/*  <AnalyzeHeartIconButton>*/}
      {/*    <AnalyzeHeartIcon*/}
      {/*      icon={RiHeart3Fill}*/}
      {/*      // className={isHeartClick ? "heart active" : "heart"}*/}
      {/*      size={"24"}*/}
      {/*    />*/}
      {/*  </AnalyzeHeartIconButton>*/}
      {/*</Stack>*/}
      <AssetsTableFavorites />
      <AssetsTableHeaderExportView />
    </Stack>
  );
};

export default AssetsTableHeaderSearch;

const SearchBox = styled("label")<{ htmlFor: string; $mode: "light" | false }>`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding-right: ${theme.spacing(2)};
  padding-left: calc(16px + ${theme.spacing(2)});
  color: ${palette.neutral[500]};
  background: ${(props) =>
    props.$mode === "light" ? palette.neutral[100] : palette.neutral[200]};
  border-radius: ${theme.shape.borderRadius}px;
  transition: ${theme.transitions.create(["box-shadow", "color"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};

  &:hover {
    box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
  }

  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 1.5px ${palette.primary[400]};
  }
`;

const SearchIcon = styled("div")`
  position: absolute;
  left: ${theme.spacing(1.5)};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  pointer-events: none;
`;

const SearchInput = styled(InputBase)<InputBaseProps>`
  width: 100%;
  height: 100%;
  color: inherit;
  border-radius: ${theme.shape.borderRadius}px;
  transition: ${theme.transitions.create(["background", "color"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};

  background: transparent;
  box-shadow: none;
  padding: 0 ${theme.spacing(1)};

  &:hover {
    box-shadow: none;
  }

  &.Mui-focused {
    box-shadow: none;
  }

  input {
    padding: 0;
    color: ${palette.neutral[700]};
    font-weight: 400;
    font-size: 1.5rem;

    &::placeholder {
      color: ${palette.neutral[500]};
      opacity: 1;
    }
  }
`;
