import { EDIT_FEATURE } from "constants/featureFlags";
import { hasFeatureFlag } from "controllers/AppController/IsFeatureFlagAvailable";
import { ColumnsL } from "controllers/AssetsTableController/lens";
import { AssetsCompareColumnTypes } from "controllers/AssetsTableController/models/AssetCompareColumn";
import { AssetsFormulaColumnTypes } from "controllers/AssetsTableController/models/AssetsFormulaColumn";
import { AssetsMetricColumnTypes } from "controllers/AssetsTableController/models/AssetsMetricColumnTypes";
import {
  FeatureFlagController,
  FeatureFlagEq,
} from "controllers/FeatureFlagController/FeatureFlagController";
import { FeatureFlagControllerState } from "controllers/FeatureFlagController/FeatureFlagControllerState";
import {
  UserPreferences,
  UserPreferencesController,
} from "controllers/UserPreferencesController/UserPreferencesController";
import * as A from "fp-ts/Array";
import * as b from "fp-ts/boolean";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as O from "fp-ts/Option";
import { Predicate } from "fp-ts/Predicate";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as Se from "fp-ts/Set";
import * as s from "fp-ts/string";
import * as C from "io-ts/Codec";
import * as D from "io-ts/Decoder";
import { effect, makeMemo } from "lib/at-react/collector";
import { defineAppController } from "lib/at-react/defineAppController";
import { composeController } from "lib/at-react/defineController";
import { withUpstreamLegacyController } from "lib/at-react/legacy";
import { reactState } from "lib/at-react/state/reactState";
import { set } from "lib/codecs/optionFromNullable";
import { initialState } from "./initialState";
import {
  AssetsTableState,
  AssetsTableStateEq,
  AssetTableColumnModel,
  TableColumnInfo,
  TableDataViesEq,
  TableDataViews,
} from "./state";

export const FeatureFlaggedColumns: Array<{
  featureFlag: string;
  columnType: string;
}> = [];

export const filterColumnsByFeatureFlags =
  (featureFlags: Set<string>) => (columns: Array<TableColumnInfo>) =>
    pipe(
      columns,
      A.filter((_) =>
        pipe(
          FeatureFlaggedColumns,
          A.findFirst((ffc) => ffc.columnType === _.column.type),
          O.map((ffc) => pipe(featureFlags, hasFeatureFlag(ffc.featureFlag))),
          O.getOrElseW(() => true)
        )
      )
    );

export const AssetTableColumnInfoModel = C.struct({
  column: AssetTableColumnModel,
  views: set(TableDataViesEq)(
    C.fromDecoder(
      D.union(D.literal(TableDataViews.CSV), D.literal(TableDataViews.BROWSER))
    )
  ),
});

export const UserColumnPreferencesModel = C.struct({
  columnPreferences: C.array(AssetTableColumnInfoModel),
});

export const AssetsTableController = pipe(
  reactState<AssetsTableState>(initialState, AssetsTableStateEq),
  effect((dispatch) =>
    pipe(
      RTE.ask<UserPreferences>(),
      RTE.chainEitherK(
        flow(
          UserColumnPreferencesModel.decode,
          E.mapLeft((e) => {
            console.error(D.draw(e));
            return new Error("Invalid Table Column Preferences");
          })
        )
      ),
      RTE.map((_) => _.columnPreferences),
      RTE.chainW((columns) =>
        pipe(
          RTE.asks<FeatureFlagControllerState, Set<string>>(
            (_) => _.featureFlags
          ),
          RTE.map((flags) => pipe(columns, filterColumnsByFeatureFlags(flags)))
        )
      ),
      RTE.chainIOK(flow(ColumnsL.set, dispatch, IO.of))
    )
  ),
  withUpstreamLegacyController(FeatureFlagController),
  withUpstreamLegacyController(UserPreferencesController),
  makeMemo(Eq.struct({}))
);

export const ByColumnName =
  (columnName: string): Predicate<TableColumnInfo> =>
  (column: TableColumnInfo) =>
    column.column.name === columnName;

export const toggleDataView =
  (view: TableDataViews) => (column: TableColumnInfo) =>
    pipe(column.views, Se.toggle(TableDataViesEq)(view));

export const toggleColumn =
  (view: TableDataViews) =>
  (name: string) =>
  (columns: Array<TableColumnInfo>) =>
    pipe(
      columns,
      A.map((column) =>
        pipe(
          column,
          ByColumnName(name),
          b.fold(
            () => column,
            () => ({
              ...column,
              views: toggleDataView(view)(column),
            })
          )
        )
      )
    );
