import React from "react";
import { Box, Chip, Stack, styled, Typography } from "@mui/material";
import palette from "theme/palette";
import Icon, { AtChevronDown } from "components/ui/Icon";
import { RiMapPin2Fill } from "react-icons/ri";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { groupBookingsByDate } from "lib/at-api/assets/bookings";
import { Asset } from "lib/at-data/assets/Asset";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Bookings } from "lib/at-api/assets/BookingsModel";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import {
  AssetsPeakOccupancySliceController,
  AssetsPeakOccupancySliceControllerComponent,
  PeakOccupanciesL,
} from "controllers/AssetsOccupanciesControllers/AssetsPeakOccupancySliceController";
import { RESOLUTION } from "../../../../../../lib/at-data/Resolution";
import { useController } from "../../../../../../lib/at-react/defineController";
import { getSliceByUUID } from "../../../../../../lib/at-data/UUIDSlice";
import * as AD from "../../../../../../lib/at-data/AsyncData";
import { sequenceT } from "fp-ts/Apply";

const EventSummaryDay: React.FC<{
  id: string;
  date: string;
  eventTotal: string;
}> = ({ id, date, eventTotal }) => {
  return (
    <EventSummary
      aria-controls="panel1d-content"
      id={id}
      expandIcon={
        <Icon
          icon={AtChevronDown}
          size="24px"
          color={`${palette.neutral[100]}`}
        />
      }
    >
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", width: "100%" }}
      >
        <Typography>{date}</Typography>
        <EventNum label={eventTotal} />
      </Stack>
    </EventSummary>
  );
};

const Event: React.FC<{
  name: string;
  time: string;
  location: string;
  expectedPercent?: string;
  expectedNumber: O.Option<number>;
  actualPercent?: string;
  actualNumber: O.Option<number>;
  actual?: string;
  // onClick: () => void;
  asset: Asset;
}> = ({
  name,
  time,
  location,
  expectedPercent,
  expectedNumber,
  actualNumber,
  actualPercent,
  // onClick,
  asset,
}) => {
  // const [meanOccupancy] = useController(
  //   AssetsMeanOccupancySliceController,
  //   flow(MeanOccupanciesL.get, AD.map(getSliceByUUID(asset.id)))
  // );
  // const actualUtilizationFromMean = pipe(
  //   meanOccupancy,
  //   AD.map((_) =>
  //     pipe(
  //       sequenceT(O.Monad)(_, expectedNumber),
  //       O.map(([u, a]) => u / a)
  //     )
  //   )
  // );

  //Getting actual utilization from peak occupancy:
  const [peakOccupancy] = useController(
    AssetsPeakOccupancySliceController,
    flow(PeakOccupanciesL.get, AD.map(getSliceByUUID(asset.id)))
  );
  const actualUtilizationFromPeak = pipe(
    peakOccupancy,
    AD.map((_) =>
      pipe(
        sequenceT(O.Monad)(_, expectedNumber),
        O.map(([u, a]) => (a !== 0 ? (Math.round(u) / a) * 100 : 0))
      )
    )
  );

  return (
    <EventItem>
      <EventName>{name}</EventName>
      <EventTime>{time}</EventTime>
      <EventLocation>
        <Icon
          icon={RiMapPin2Fill}
          color={palette.neutral[400]}
          size="12px"
          sx={{ marginRight: "4px" }}
        />
        {location}
      </EventLocation>
      <Stack direction="column" spacing={1} sx={{ marginTop: "10px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%", height: "20px" }}
        >
          <Stack direction="row">
            <Typography variant="body2" sx={{ float: "left" }}>
              <b>Peak Attendance: </b>
            </Typography>
            <Typography sx={{ width: "5px" }}> </Typography>
            <Typography variant="body2">
              {pipe(
                actualUtilizationFromPeak,
                AD.getData,
                O.flatten,
                O.map((an) => <span>{Math.round(an)}%</span>),
                O.getOrElseW(() => 0)
              )}
            </Typography>
            <Typography sx={{ width: "3px" }}> </Typography>
            <Typography variant="body2">
              (
              {pipe(
                peakOccupancy,
                AD.getData,
                O.flatten,
                O.map((an) => <span>{Math.round(an)}</span>),
                O.getOrElseW(() => 0)
              )}
              )
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body2" sx={{ float: "right" }}>
              <b>Expected: </b>
            </Typography>
            <Typography sx={{ width: "5px" }}> </Typography>
            <Typography variant="body2" sx={{ maxWidth: "130px" }}>
              {pipe(
                expectedNumber,
                O.getOrElseW(() => "(No data)")
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: `257px`,
            backgroundColor: `${palette.neutral[400]}`,
            height: "8px",
            borderRadius: "2px",
            marginTop: "10px",
            marginBottom: "8px",
          }}
        >
          <Box
            sx={{
              // width: `40%`,
              width: `${pipe(
                actualUtilizationFromPeak,
                AD.getData,
                O.flatten,
                O.getOrElseW(() => 0)
              )}%`,
              maxWidth: `257px`,
              backgroundColor: `${palette.primary[500]}`,
              height: "8px",
            }}
          />
        </Stack>
      </Stack>
    </EventItem>
  );
};

const AssetBookingsList: React.FC<{
  bookings: Array<Bookings>;
  asset: Asset;
  onBookingClick: (range: SeriesRequest) => void;
}> = ({ bookings, asset, onBookingClick }) => {
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const groupedBookingsByDay = groupBookingsByDate(
    bookings,
    (date) => `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
  );

  const dateFormatter = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const hourFormatter = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const eventsGrouped = groupedBookingsByDay.map(
    (group: Array<Bookings>, index) => {
      const panelId = `panel${index}`;
      return (
        <EventList
          expanded={expanded === panelId}
          onChange={handleChange(panelId)}
          disableGutters
          elevation={0}
          square
        >
          <EventSummaryDay
            id={"id"}
            date={dateFormatter.format(group[0].schedule.start)}
            eventTotal={
              group.length === 1
                ? `${group.length} Event`
                : `${group.length} Events`
            }
          />
          {expanded === panelId && (
            <EventDetails>
              {group.map((booking) => (
                <AssetsPeakOccupancySliceControllerComponent
                  context={{
                    visibleAssets: [asset],
                    series: {
                      start: booking.schedule.start,
                      end: booking.schedule.end,
                      resolution: RESOLUTION.FIVE_MINUTES,
                    },
                  }}
                >
                  <Event
                    // onClick={() =>
                    //   pipe(
                    //     calculateNavigatorRanges(
                    //       booking.schedule.start,
                    //       booking.schedule.end
                    //     ).fine,
                    //     onBookingClick
                    //   )
                    // }
                    name={booking.title}
                    // TODO: Add proper formatting
                    time={`${hourFormatter.format(
                      booking.schedule.start
                    )} - ${hourFormatter.format(booking.schedule.end)}`}
                    location={asset.name}
                    expectedNumber={pipe(
                      booking.occupancy,
                      O.chain((_) => _.expected),
                      O.chain((_) => _.physical)
                      // O.getOrElseW(() => 0)
                    )}
                    expectedPercent={"0"}
                    actualNumber={O.none}
                    actualPercent={"0"}
                    asset={asset}
                  />
                </AssetsPeakOccupancySliceControllerComponent>
              ))}
            </EventDetails>
          )}
        </EventList>
      );
    }
  );

  return <div>{eventsGrouped}</div>;
};

export default AssetBookingsList;

const EventList = styled(MuiAccordion)`
  background-color: ${palette.neutral[700]};
  border-top: 0.5px solid ${palette.neutral[500]};
  border-radius: 0;
  margin: 0;
  "& .MuiButtonBase-root.MuiAccordionSummary-root": {
    margin: 0;
    min-height: 32px;
    max-height: 32px;
  },
  "& .MuiAccordionSummary-content": {
    margin: 0;
  },
  "&:not(:last-child)": {
    border-bottom: 0;
  },
  "&:before": {
    display: none;
  },
`;
const EventSummary = styled(MuiAccordionSummary)`
  padding: 0;
  color: ${palette.neutral[100]};
  background-color: ${palette.neutral[700]};
  flex-direction: "row-reverse";
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)";
  },
  "& .MuiAccordionSummary-content": {
    margin: 0;
  },
`;
const EventDetails = styled(MuiAccordionDetails)`
  padding: 0;
  border-top: none;
  background-color: ${palette.neutral[700]};
  color: ${palette.neutral[100]};
  // max-height: 200px;
  // overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
const EventItem = styled(Box)`
  margin-top: 4px;
  padding: 12px 16px;
  background-color: ${palette.neutral[600]};
  border-radius: 4px;
`;
const EventName = styled(Typography)`
  font-weight: 500;
  color: ${palette.neutral[100]};
`;
const EventTime = styled(Typography)`
  color: ${palette.neutral[100]};
`;
const EventLocation = styled(Typography)`
  font-size: 12px;
  color: ${palette.neutral[400]};
`;
const EventNum = styled(Chip)`
  background-color: #46495e;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0 8px;
  font-size: 12px;
  height: 20px;
  color: white;
  margin-right: 12px;
  pointer-events: none;
`;
const BookingsBarChart = styled(Box)`
  &::before {
    width: 130px;
    background-color: black;
  }
`;
