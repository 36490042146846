import { FormControlLabel, Switch } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import React from "react";

interface ILabeledSwitch {
  /**
   * If `true`, the component appears selected.
   */
  checked?: boolean;
  /**
   * The text to be used in an enclosing label element.
   */
  label: string;
  /**
   * Color Theme Context
   */
  mode?: "light" | "dark";
  /**
   * Property to determine whether or not the checkbox is checked by default.
   */
  defaultChecked?: boolean;
  /**
   * 	A control element. For instance, it can be a `Radio`, a `Switch` or a `Checkbox`. (By default it uses a `Checkbox`)
   */
  control?: React.ReactElement<any, any>;
  /**
   * If `true`, the control is disabled.
   */
  disabled?: boolean;
  /**
   * The position of the label.
   */
  labelPlacement?: "bottom" | "end" | "start" | "top";
  /**
   * The value of the component.
   */
  value?: any;
  /**
   * Callback fired when the state is changed.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange?: (event: React.SyntheticEvent, checked: boolean) => void;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles. See the [`sx` page](https://mui.com/system/the-sx-prop/) for more details.
   */
  sx?: SxProps<Theme>;
}

const LabeledSwitch: React.FC<ILabeledSwitch> = (props) => {
  return (
    <FormControlLabel
      {...props}
      control={
        props.control ? (
          props.control
        ) : (
          <Switch
            defaultChecked={props.defaultChecked}
            mode={props.mode || undefined}
          />
        )
      }
    />
  );
};

export default LabeledSwitch;
