import { Breadcrumbs, Button, Stack, Typography } from "@mui/material";
import { AssetsTableController } from "controllers/AssetsTableController/AssetsTableController";
import { ColumnsL } from "controllers/AssetsTableController/lens";
import { DEFAULT_FILTERS } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import {
  AssetsFilterL,
  AssetsPageSelectedAssetIdL,
  AssetsPageUIStateL,
  SelectedSideItemL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as A from "fp-ts/Array";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { getCampuses } from "lib/at-data/assets/filters";
import { useController } from "lib/at-react/defineController";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { useCollector } from "lib/at-react/hooks";
import * as L from "monocle-ts/Lens";
import React, { useCallback } from "react";
import { match } from "ts-adt";
import {
  BreadcrumbButton,
  HeaderBreadcrumbs,
} from "views/authenticated/assets/page/components/AssetsTable/HeaderBreadcrumbs";
import { ClearAssetTableSelection } from "views/authenticated/assets/page/components/AssetsTable/ClearAssetTableSelection";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { AssetsL } from "views/authenticated/root/controller/state";
import AssetsTableColumnsSelectorController from "../AssetsTableColumnSelector/AssetsTableColumnsSelectorController";
import AssetsTableHeaderFilter from "./AssetsTableHeaderFilter";
import AssetsTableHeaderSearch from "./AssetsTableHeaderSearch";
import palette from "theme/palette";
import {
  AssetsTableConfigurationMenu,
  AssetTableConfigurationController,
} from "./AssetsTableConfigurationMenu";
import { UUID } from "lib/at-data/UUID";

const AssetsTableHeader: React.FC<{}> = (props) => {
  const [assetsFilter] = useController(
    UIStateController,
    flow(pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).get)
  );
  const [rootAssetO] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2, getCampuses, A.head)
  );
  const columns = useCollector(AssetsTableController, L.composeLens(ColumnsL));

  const dispatch = useHistoryControllerDispatch(UIStateController);
  const handleClearSelection = useCallback(() => {
    dispatch(
      pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).set(
        DEFAULT_FILTERS
      )
    );
    dispatch(
      pipe(AssetsPageUIStateL, L.composeLens(AssetsPageSelectedAssetIdL)).set([
        pipe(
          rootAssetO,
          O.map((r) => r.id),
          O.getOrElseW(() => null)
        ) as UUID,
      ])
    );
    dispatch(
      pipe(AssetsPageUIStateL, L.composeLens(SelectedSideItemL)).set(O.none)
    );
  }, [dispatch]);

  return (
    <>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="stretch"
        justifyContent={"space-between"}
        pt={4}
        px={3}
      >
        <AssetsTableHeaderFilter />
        <AssetsTableHeaderSearch />
        <AssetsTableColumnsSelectorController />
      </Stack>
      {/*<Breadcrumbs />*/}
      <Stack
        direction="row"
        alignItems="stretch"
        py={0}
        px={2.5}
        sx={{ justifyContent: "space-between" }}
      >
        <Stack direction="row" alignItems="center" py={0} px={1}>
          {pipe(
            rootAssetO,
            O.map((rootAsset) => (
              <Stack direction="row" alignItems="center">
                <Breadcrumbs>
                  <BreadcrumbButton onClick={handleClearSelection}>
                    {rootAsset.name}
                  </BreadcrumbButton>
                </Breadcrumbs>
                <Typography
                  sx={{ color: `${palette.neutral[500]}`, marginLeft: "10px" }}
                >
                  /
                </Typography>
              </Stack>
            )),
            O.getOrElseW(() => null)
          )}
          {pipe(
            assetsFilter.byIds,
            O.map(
              match({
                byId: () => <span>&nbsp;</span>,
                // byId: () => <ClearAssetTableSelection />,
                byParentId: (a) => <HeaderBreadcrumbs assetId={a.parentId} />,
              })
            ),
            O.getOrElseW(() => null)
          )}
        </Stack>

        <AssetsTableConfigurationMenu columns={columns} />
      </Stack>
    </>
  );
};

export default AssetsTableHeader;
