import {
  Card,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { GeneralAssetProperties } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/GeneralAssetProperties";
import { SpecificAssetProperties } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/SpecificAssetProperties";
import { AnalyticsAssetProperties } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/AnalyticsAssetProperties";

export const EditAssetPropertiesTab: React.FC<{ asset: Asset }> = (props) => {
  return (
    <StyledPanelContent>
      <Stack spacing={1} px={3} py={2}>
        <Stack spacing={2}>
          <TableContainer component={Card}>
            <Table sx={{ minWidth: 255 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <GeneralAssetProperties asset={props.asset} />
                <SpecificAssetProperties asset={props.asset} />
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Card}>
            <Table sx={{ minWidth: 255 }} aria-label="simple table">
              <TableBody>
                <AnalyticsAssetProperties asset={props.asset} />
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </StyledPanelContent>
  );
};

export default EditAssetPropertiesTab;

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;

const InputField = styled("input")`
  padding: 8px 12px;
  margin-top: 6px;
  outline: none;
  border: none;
  border-radius: 2px;
  font-size: 18px;
`;
