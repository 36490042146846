import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { TableCell, TableRow } from "@mui/material";
import { ZoneAssetCapacityPropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/ZoneAssetCapacityPropertyRow";
import { flow, pipe } from "fp-ts/function";
import { validateLevel } from "lib/at-data/assets/validations/validateLevel";
import { validateZone } from "lib/at-data/assets/validations/validateZone";
import { InvalidAssetIndicator } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/InvalidAssetIndicator";
import {
  ClipboardCopy,
  OptionalProperty,
  Property,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import { Coord } from "lib/at-data/assets/models/Coord";
import * as O from "fp-ts/Option";
import { getGeometry, getPoly } from "lib/at-data/assets/getters";

// TODO: Remove?
const websterPolygon = (points: Array<Coord>) => ({
  polygonValue: {
    lines: [
      {
        points,
      },
    ],
  },
});

export const ZoneAssetProperties: React.FC<{ asset: Asset }> = (props) => {
  const validZone = pipe(props.asset, validateZone);
  return (
    <>
      <TableRow
        key={"assetClass"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          Asset Class
        </TableCell>
        <TableCell align="right">
          <strong>Zone</strong>
          <InvalidAssetIndicator validation={validZone} />
        </TableCell>
      </TableRow>
      <TableRow
        key={"assetClass"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Property value={"Polygon"} />
          <ClipboardCopy
            value={pipe(
              props.asset,
              getPoly,
              O.map(flow(websterPolygon, JSON.stringify)),
              O.getOrElse(() => "")
            )}
          />
        </TableCell>
        <TableCell align="right">polygon</TableCell>
      </TableRow>
    </>
  );
};
