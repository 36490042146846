import { Stack, styled, Typography } from "@mui/material";
import Icon from "components/ui/Icon";
import React from "react";
import { RiArrowRightDownLine, RiArrowRightUpLine } from "react-icons/ri";
import theme from "theme/theme";
import * as O from "fp-ts/lib/Option";

interface IAssetsTableCellDwell {
  /**
   * Amount of time to be listed
   */
  time: string;
  /**
   * Graph Element
   */
  graph: React.ReactElement;
  /**
   * **Optional** field that will render an arrow up/down if included
   */
  trend?: "up" | "down" | undefined;
}

const trendIcons = {
  up: <Icon icon={RiArrowRightUpLine} size="16px" />,
  down: <Icon icon={RiArrowRightDownLine} size="16px" />,
};

const AssetsTableCellDwell: React.FC<IAssetsTableCellDwell> = (props) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <GraphBox>{props.graph}</GraphBox>
      <Typography variant="body1" style={{ marginRight: props.trend ? 0 : 20 }}>
        {props.time}
      </Typography>
      {props.trend ? trendIcons[props.trend] : null}
    </Stack>
  );
};

export default AssetsTableCellDwell;

const GraphBox = styled("div")`
  width: 66px;
  height: 20px;
  margin-right: ${theme.spacing(1)};
`;
