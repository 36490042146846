import React from "react";
import * as O from "fp-ts/Option";
import {
  foldToSinglePeriod,
  HoursOfOperation,
} from "lib/at-data/HoursOfOperation";
import { AssetOperatingHours } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetOperatingHours";
import { pipe } from "fp-ts/function";
import { formatOperatingHour } from "views/authenticated/assets/page/components/AssetsDetailPreview/formatOperatingHour";

export const OperatingHoursProperty: React.FC<{
  value: HoursOfOperation;
}> = (props) => (
  <span>
    {pipe(
      props.value,
      foldToSinglePeriod,
      O.fold(
        () => "varies",
        ({ open, close }) =>
          `${formatOperatingHour(navigator.language === "en_US")(
            open
          )} - ${formatOperatingHour(navigator.language === "en_US")(close)}`
      )
    )}
  </span>
);
