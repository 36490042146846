import { AssetTagModel, AssetTagsEq } from "lib/at-api/asset-tags/assetTags";
import * as C from "io-ts/Codec";
import { AssetTypeModel } from "lib/at-data/assets/AssetTypeModel";
import { getSemigroup, pipe } from "fp-ts/function";
import { Asset } from "lib/at-data/assets/Asset";
import * as B from "fp-ts/boolean";
import * as Eq from "fp-ts/Eq";
import * as s from "fp-ts/string";
import * as A from "fp-ts/Array";
import { eqUUID, of, UUID } from "lib/at-data/UUID";
import * as O from "fp-ts/Option";
import { option } from "lib/codecs/option";
import * as D from "io-ts/Decoder";

export enum FilterByIdType {
  ID = "byId",
  PARENT_ID = "byParentId",
}

export const FilterByIdModel = C.fromDecoder(
  D.union(
    D.struct({
      _type: C.literal(FilterByIdType.ID),
      ids: C.array(UUID),
    }),
    D.struct({
      _type: C.literal(FilterByIdType.PARENT_ID),
      parentId: UUID,
    })
  )
);

export type FilterById = C.TypeOf<typeof FilterByIdModel>;

export const FilterByIds = (ids: Array<UUID>): FilterById => ({
  _type: FilterByIdType.ID,
  ids,
});

export const FilterByParentId = (parentId: UUID): FilterById => ({
  _type: FilterByIdType.PARENT_ID,
  parentId,
});

export const FilterByIdEq = Eq.fromEquals<FilterById>((a, b) => {
  if (a._type === FilterByIdType.ID && a._type === b._type)
    return Eq.struct({
      ids: A.getEq(eqUUID),
    }).equals(a, b);
  if (a._type === FilterByIdType.PARENT_ID && a._type === b._type)
    return Eq.struct({
      parentId: eqUUID,
    }).equals(a, b);

  return false;
});

export const AssetFilterModel = C.struct({
  byTypes: pipe(AssetTypeModel, C.fromDecoder, C.array),
  byName: C.string,
  byTags: pipe(C.array(AssetTagModel), C.fromDecoder),
  byIds: option(FilterByIdModel),
});

export const UIAssetsFilterModel = C.struct({
  assetsFilter: AssetFilterModel,
});

export const AssetFilterModelEq = Eq.struct<AssetFilter>({
  byName: s.Eq,
  byTypes: A.getEq(s.Eq),
  byTags: AssetTagsEq,
  byIds: pipe(FilterByIdEq, O.getEq),
});

export type AssetFilter = C.TypeOf<typeof AssetFilterModel>;

export const AssetsFilter = getSemigroup(B.SemigroupAll)<Asset>();
