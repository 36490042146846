import * as n from "fp-ts/number";
import * as Ord from "fp-ts/Ord";
import { pipe } from "fp-ts/function";
import * as Eq from "fp-ts/Eq";

export type OccupancyEstimate = {
  occupancyEstimate: number;
};

export const OccupancyEstimateEq = pipe(
  n.Eq,
  Eq.contramap((oe: OccupancyEstimate) => oe.occupancyEstimate)
);

export const roundUpEstimate = (oe: OccupancyEstimate): OccupancyEstimate => ({
  occupancyEstimate: Math.ceil(oe.occupancyEstimate),
});

export const getOccupancyEstimate: <T extends OccupancyEstimate>(
  o: T
) => number = (o) => o.occupancyEstimate;

export const OcupancyEstimate = (value: number): OccupancyEstimate => ({
  occupancyEstimate: value,
});
