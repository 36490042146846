import { defineAppController } from "lib/at-react/defineAppController";
import * as Eq from "fp-ts/Eq";
import * as S from "fp-ts/Set";
import * as s from "fp-ts/string";
import { flow, pipe } from "fp-ts/function";
import { getItem } from "fp-ts-local-storage";
import * as IO from "fp-ts/IO";
import * as O from "fp-ts/Option";
import { FeatureFlagL } from "controllers/FeatureFlagController/FeatureFlagL";
import { FeatureFlagsModel } from "controllers/FeatureFlagController/FeatureFlagsModel";
import { FeatureFlagControllerState } from "controllers/FeatureFlagController/FeatureFlagControllerState";
import { clog } from "lib/util";

export const initialState: FeatureFlagControllerState = {
  featureFlags: new Set(),
};

export const FeatureFlagEq = S.getEq(s.Eq);

export const FeatureFlagControllerStateEq =
  Eq.struct<FeatureFlagControllerState>({
    featureFlags: FeatureFlagEq,
  });

export const [component, controller] = defineAppController<
  {},
  FeatureFlagControllerState
>(
  initialState,
  FeatureFlagControllerStateEq,
  Eq.struct({}),
  (dispatch) => () =>
    pipe(
      getItem("featureFlags"),
      IO.map(
        flow(
          O.chain(
            flow(
              JSON.parse,
              FeatureFlagsModel.decode,
              O.fromEither,
              O.map(S.fromArray(s.Eq))
            )
          ),
          O.getOrElse(() => initialState.featureFlags)
        )
      ),
      IO.chain(flow(FeatureFlagL.set, (v) => () => dispatch(v)))
    )
);

export const FeatureFlagControllerComponent = component;
export const FeatureFlagController = controller;
