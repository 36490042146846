import * as C from "io-ts/Codec";
import { pipe } from "fp-ts/function";
import * as D from "io-ts/Decoder";
import * as O from "fp-ts/Option";
import * as En from "io-ts/Encoder";
import { getEq, iso, Newtype, prism } from "newtype-ts";
import { isNonNegative } from "newtype-ts/lib/NonNegative";
import { isPositive } from "newtype-ts/lib/Positive";
import { fromSqF, fromSqM, isoSqFt, isoSqM } from "lib/at-data/units/area";
import * as Eq from "fp-ts/Eq";
import * as n from "fp-ts/number";
import * as Ord from "fp-ts/Ord";

export interface USD extends Newtype<{ readonly USD: unique symbol }, number> {}

export interface USDPerOneSqM
  extends Newtype<{ readonly USDPerOneSqM: unique symbol }, number> {}

export const eqUSDPerOneSqM = getEq<USDPerOneSqM>(n.Eq);

export interface USDPerOneSqF
  extends Newtype<{ readonly USDPerOneSqF: unique symbol }, number> {}

export const toUSDPerOneSqF = (v: USDPerOneSqM): USDPerOneSqF =>
  pipe(
    v,
    isoUSDPerOneSqM.unwrap,
    (_) => _ / isoSqFt.unwrap(pipe(1, isoSqM.wrap, fromSqM)),
    isoUSDPerOneSqF.wrap
  );
export const toUSDPerOneSqM = (v: USDPerOneSqF): USDPerOneSqM =>
  pipe(
    v,
    isoUSDPerOneSqF.unwrap,
    (_) => _ / isoSqM.unwrap(pipe(1, isoSqFt.wrap, fromSqF)),
    isoUSDPerOneSqM.wrap
  );

export const isoUSD = iso<USD>();
export const isoUSDPerOneSqM = iso<USDPerOneSqM>();
export const isoUSDPerOneSqF = iso<USDPerOneSqF>();
export const prismUSD = prism<USD>(isNonNegative);
export const prismUSDPerOneSqM = prism<USDPerOneSqM>(isPositive);

export const USDModel = C.make(
  pipe(
    D.number,
    D.parse((_) =>
      pipe(
        prismUSD.getOption(_),
        O.fold(
          () => D.failure(_, "Cost Per Sq Meter Must Be a Positive Number"),
          D.success
        )
      )
    )
  ),
  pipe(En.id<USD>())
);

export const USDPeSqMModel = C.make(
  pipe(
    D.number,
    D.parse((_) =>
      pipe(
        prismUSDPerOneSqM.getOption(_),
        O.fold(
          () => D.failure(_, "Cost Per Sq Meter Must Be a Positive Number"),
          D.success
        )
      )
    )
  ),
  pipe(En.id<USDPerOneSqM>())
);
