import { IconButton, Stack, styled, Typography } from "@mui/material";
import { alpha } from "@mui/system";
import AssetActionsMenu, {
  AssetAction,
} from "components/contextualSidebar/AssetActionsMenu";
import ContextualSidebar from "components/contextualSidebar/ContextualSidebar";
import ContextualSidebarDrawer from "components/contextualSidebar/ContextualSidebarDrawer";
import { FeatureFlag } from "components/featureFlag/FeatureFlag";
import Icon, {
  AtActivityMap,
  AtAdmin,
  AtArrowCaretDoubleRight,
  AtInfo,
  AtInsights,
  AtSchedule,
  AtTagNames,
  AtUsers,
  AtUtilization,
} from "components/ui/Icon";
import { EDIT_FEATURE, FLASH_INSIGHTS } from "constants/featureFlags";
import {
  SelectedAssetL,
  SelectedAssetsController,
  SelectedAssetsL,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { navigateToActivity } from "controllers/UIStateCollector/lens/activityPage";
import {
  AssetsFilterL,
  AssetsPageUIStateL,
  FilterByIdsL,
  FilterByTypeL,
  navigateToAnalyze,
  SelectedRangeL,
  SelectedSideItemL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { navigateToEditBuilding } from "controllers/UIStateCollector/lens/luciferPageL";
import {
  FilterByIds,
  FilterByParentId,
} from "controllers/UIStateCollector/models/assetFilterModel";
import { AssetPageTab } from "controllers/UIStateCollector/models/AssetsPageTab";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { UIStateContext } from "controllers/UIStateContext/UIStateContext";
import { sequenceT } from "fp-ts/Apply";
import * as A from "fp-ts/Array";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as R from "fp-ts/Reader";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import {
  getAllChildren,
  getCampuses,
  getParent,
} from "lib/at-data/assets/filters";
import { getAssetType, getId, getName } from "lib/at-data/assets/getters";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import * as L from "monocle-ts/Lens";
import React, { useCallback } from "react";
import palette from "theme/palette";
import { match } from "ts-adt";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import AssetsDetailPreviewTab, {
  AssetAdminTab,
  AssetDefaultCapacityTab,
  AssetInsightsTab,
  AssetMultipleSelectionTab,
  AssetScheduleTab,
  AssetUtilizationTab,
  AssetWeatherTab,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetsDetailPreviewTab";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import mixpanel from "mixpanel-browser";

export const AssetPageSidepanel: React.FC<{}> = () => {
  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );
  const dispatch = useHistoryControllerDispatch(UIStateController);
  const [selectedSideItem] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(SelectedSideItemL)).get
  );

  const selectedAssetO = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );

  const selectedAssets = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetsL)
  );

  const [selectedRange] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).get
  );
  const [showDrawer] = useController(UIStateContext, (_) => _.showDrawer);

  const handleClose = useCallback(
    () =>
      dispatch(
        pipe(
          O.none,
          pipe(AssetsPageUIStateL, L.composeLens(SelectedSideItemL)).set
        )
      ),
    [dispatch]
  );

  const handleAssetClick = useCallback(
    (asset: Asset) =>
      dispatch(
        navigateToAnalyze({
          selectedAsset: O.some(asset),
          selectedRange,
        })
      ),
    [selectedRange]
  );

  const handleTabClick = useCallback(
    (selectedTab) => {
      mixpanel.track("Tab Selected from Analyze Table", {
        tab: selectedTab,
      });
      dispatch(
        pipe(
          pipe(AssetsPageUIStateL, L.composeLens(SelectedSideItemL)).set(
            pipe(
              selectedTab as ASSET_CONTEXT_TABS | null,
              O.fromNullable,
              O.map(AssetPageTab)
            )
          )
        )
      );
    },
    [dispatch]
  );

  const startTime = performance.now();

  window.addEventListener("beforeunload", () => {
    const endTime = performance.now();
    const timeSpentSeconds = Math.round((endTime - startTime) / 1000);

    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "asset"
    ) {
      mixpanel.track("Info (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "admin"
    ) {
      mixpanel.track("Admin (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "utilization"
    ) {
      mixpanel.track("KPI (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "insights"
    ) {
      mixpanel.track("Insights (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "schedule"
    ) {
      mixpanel.track("Bookings (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "weather"
    ) {
      mixpanel.track("Weather (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "selection"
    ) {
      mixpanel.track("Tags (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "default-capacity"
    ) {
      mixpanel.track("Default Capacity (Analyze)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
  });

  const handleViewAssetInMap = useCallback(
    () =>
      dispatch(
        navigateToActivity({ selectedAsset: selectedAssetO, selectedRange })(
          allAssets
        )
      ),
    [selectedAssetO, selectedRange]
  );

  const handleFilterByParent = useCallback(
    (asset: Asset) => {
      return () => {
        dispatch(
          flow(
            pipe(
              asset,
              getId,
              FilterByParentId,
              O.some,
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsFilterL),
                L.composeLens(FilterByIdsL)
              ).set
            ),
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByTypeL)
            ).set([AssetTypes.ZONE, AssetTypes.BUILDING, AssetTypes.FLOOR])
          )
        );
      };
    },
    [dispatch]
  );

  const handleViewAllZones = useCallback(
    (asset: Asset) => {
      return () => {
        dispatch(
          flow(
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByTypeL)
            ).set([AssetTypes.ZONE]),
            pipe(
              asset,
              getAllChildren,
              R.map(
                flow(
                  A.map(getId),
                  FilterByIds,
                  O.some,
                  pipe(
                    AssetsPageUIStateL,
                    L.composeLens(AssetsFilterL),
                    L.composeLens(FilterByIdsL)
                  ).set
                )
              )
            )({ assets: allAssets })
          )
        );
      };
    },
    [dispatch, allAssets]
  );

  const handleGetParentFromChild = useCallback(
    (asset: Asset) => {
      return () => {
        dispatch(
          flow(
            pipe(
              allAssets,
              getParent(asset),
              O.map(flow(getId, A.of, FilterByIds)),
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsFilterL),
                L.composeLens(FilterByIdsL)
              ).set
            ),
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByTypeL)
            ).set([AssetTypes.CAMPUS, AssetTypes.BUILDING, AssetTypes.FLOOR])
          )
        );
      };
    },
    [dispatch, allAssets]
  );

  const handleEditBuilding = useCallback(
    () => dispatch(navigateToEditBuilding({ selectedAsset: selectedAssetO })),
    [selectedAssetO, selectedRange]
  );

  const handleBookingClick = useCallback(
    flow(pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).set, dispatch),
    [dispatch]
  );

  return (
    <ContextualSidebar
      selectedItem={pipe(
        selectedSideItem,
        O.map((_) => _._type)
      )}
      open={O.isSome(selectedSideItem)}
      onTabClick={handleTabClick}
      tabs={
        pipe(
          selectedAssetO,
          O.map((a) => getAssetType(a)),
          O.getOrElseW(() => AssetTypes.UNKNOWN)
        ) === AssetTypes.ZONE
          ? [
              {
                id: ASSET_CONTEXT_TABS.ASSET,
                label: "Info",
                icon: AtInfo,
                tooltipTitle: "View Asset Info",
              },
              {
                id: ASSET_CONTEXT_TABS.UTILIZATION,
                label: "Metrics",
                icon: AtUtilization,
                tooltipTitle: "View Asset KPIs",
              },
              {
                id: ASSET_CONTEXT_TABS.INSIGHTS,
                label: "Insights",
                icon: AtInsights,
                featureFlag: FLASH_INSIGHTS,
                tooltipTitle: "View Asset Insights",
              },
              {
                id: ASSET_CONTEXT_TABS.SCHEDULE,
                label: "Schedule",
                icon: AtSchedule,
                tooltipTitle: "View Asset's Scheduled Events",
              },
              {
                id: ASSET_CONTEXT_TABS.ADMIN,
                label: "Admin",
                icon: AtAdmin,
                featureFlag: EDIT_FEATURE,
                tooltipTitle: "Configure the Asset",
              },
              {
                id: ASSET_CONTEXT_TABS.SELECTION,
                label: "Asset Selection",
                featureFlag: EDIT_FEATURE,
                hidden: selectedAssets.length < 1,
                icon: AtTagNames,
                tooltipTitle: "Add Tags",
              },
              {
                id: ASSET_CONTEXT_TABS.DEFAULT_CAPACITY,
                label: "Asset Default Capacity",
                featureFlag: EDIT_FEATURE,
                hidden: selectedAssets.length < 1,
                icon: AtUsers,
                tooltipTitle: "Add Default Capacity",
              },
            ]
          : [
              {
                id: ASSET_CONTEXT_TABS.ASSET,
                label: "Info",
                icon: AtInfo,
                tooltipTitle: "View Asset Info",
              },
              {
                id: ASSET_CONTEXT_TABS.UTILIZATION,
                label: "Metrics",
                icon: AtUtilization,
                tooltipTitle: "View Asset KPIs",
              },
              {
                id: ASSET_CONTEXT_TABS.INSIGHTS,
                label: "Insights",
                icon: AtInsights,
                featureFlag: FLASH_INSIGHTS,
                tooltipTitle: "View Asset Insights",
              },
              {
                id: ASSET_CONTEXT_TABS.SCHEDULE,
                label: "Schedule",
                icon: AtSchedule,
                tooltipTitle: "View Asset's Scheduled Events",
              },
              {
                id: ASSET_CONTEXT_TABS.ADMIN,
                label: "Admin",
                icon: AtAdmin,
                featureFlag: EDIT_FEATURE,
                tooltipTitle: "Configure the Asset",
              },
              {
                id: ASSET_CONTEXT_TABS.SELECTION,
                label: "Asset Selection",
                featureFlag: EDIT_FEATURE,
                hidden: selectedAssets.length < 1,
                icon: AtTagNames,
                tooltipTitle: "Add Tags",
              },
            ]
      }
    >
      {
        <ContextualSidebarDrawer open={showDrawer} hideBackdrop={true}>
          <StyledPanelHeader
            py={2}
            px={3}
            spacing={0}
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={1}
              >
                <AssetActionsMenu>
                  <AssetAction
                    onClick={handleViewAssetInMap}
                    icon={AtActivityMap}
                  >
                    View Asset In Explore Map
                  </AssetAction>

                  {pipe(
                    selectedAssetO,
                    O.foldW(
                      () => null,
                      (sa) =>
                        pipe(sa, getAssetType) !== AssetTypes.ZONE ? (
                          <div>
                            <AssetAction
                              onClick={handleViewAllZones(sa)}
                              icon={AtActivityMap}
                            >
                              View All Descendants
                            </AssetAction>
                            <AssetAction
                              onClick={handleFilterByParent(sa)}
                              icon={AtActivityMap}
                            >
                              View Direct Descendants
                            </AssetAction>
                          </div>
                        ) : (
                          <span />
                        )
                    )
                  )}
                  {pipe(
                    selectedAssetO,
                    O.foldW(
                      () => null,
                      (sa) =>
                        pipe(sa, getAssetType) !== AssetTypes.CAMPUS ? (
                          <div>
                            <AssetAction
                              onClick={handleGetParentFromChild(sa)}
                              icon={AtActivityMap}
                            >
                              View Parent
                            </AssetAction>
                          </div>
                        ) : (
                          <span />
                        )
                    )
                  )}

                  <FeatureFlag flag={EDIT_FEATURE}>
                    <AssetAction
                      onClick={handleEditBuilding}
                      icon={AtActivityMap}
                    >
                      Edit Building
                    </AssetAction>
                  </FeatureFlag>
                </AssetActionsMenu>
                <Typography
                  variant="h3"
                  color={palette.neutral[100]}
                  sx={{ marginTop: "2px" }}
                >
                  {pipe(
                    selectedAssetO,
                    O.map(getName),
                    O.getOrElseW(() => null)
                  )}
                </Typography>
              </Stack>
              <IconButton
                color="primary"
                onClick={handleClose}
                aria-label="Close Side Panel"
              >
                <Icon icon={AtArrowCaretDoubleRight} size="16px" />
              </IconButton>
            </Stack>
          </StyledPanelHeader>
          {pipe(
            sequenceT(O.Monad)(selectedSideItem),
            O.map(([sideitem]) =>
              pipe(
                sideitem,
                match({
                  [ASSET_CONTEXT_TABS.ASSET]: () =>
                    pipe(
                      selectedAssetO,
                      O.map((sa) => (
                        <AssetsDetailPreviewTab
                          onAssetClick={handleAssetClick}
                          onClose={handleClose}
                          selectedAsset={sa}
                        />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.UTILIZATION]: () =>
                    pipe(
                      selectedAssetO,
                      O.map((sa) => (
                        <AssetUtilizationTab
                          onClose={handleClose}
                          asset={sa}
                          range={selectedRange}
                        />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.INSIGHTS]: () =>
                    pipe(
                      selectedAssetO,
                      O.map((sa) => (
                        <AssetInsightsTab onClose={handleClose} asset={sa} />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.SCHEDULE]: () =>
                    pipe(
                      selectedAssetO,
                      O.map((sa) => (
                        <AssetScheduleTab
                          onClose={handleClose}
                          asset={sa}
                          onBookingClick={handleBookingClick}
                          selectedRange={selectedRange}
                        />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.WEATHER]: () => (
                    <AssetWeatherTab onClose={handleClose} />
                  ),
                  [ASSET_CONTEXT_TABS.ADMIN]: () =>
                    pipe(
                      selectedAssetO,
                      O.map((sa) => (
                        <AssetAdminTab onClose={handleClose} asset={sa} />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.SELECTION]: () => (
                    <AssetMultipleSelectionTab />
                  ),
                  [ASSET_CONTEXT_TABS.DEFAULT_CAPACITY]: () => (
                    <AssetDefaultCapacityTab />
                  ),
                })
              )
            ),
            O.getOrElseW(() => null)
          )}
        </ContextualSidebarDrawer>
      }
    </ContextualSidebar>
  );
};

const StyledPanelHeader = styled(Stack)`
  z-index: 5;
  // width: 299px;
  box-shadow: 0 2px 24px ${alpha(palette.neutral[800], 0.5)};
`;
