import React from "react";
import Welcome from "views/public/welcome/Welcome";
import { BaseStylingProvider } from "theme/theme";
import { Auth0Client } from "@auth0/auth0-spa-js";

import * as O from "fp-ts/Option";

export const Foyer: React.FC<{
  authClient: Auth0Client;
  location: Location;
  authOrganization: O.Option<string>;
}> = (props) => {
  const handleSignIn = () => {
    props.authClient.loginWithRedirect({
      organization: O.toUndefined(props.authOrganization),
      appState: {
        redirectAfterLogin: props.location.pathname + props.location.search,
      },
    });
  };

  return (
    <BaseStylingProvider>
      <Welcome onSignIn={handleSignIn} />
    </BaseStylingProvider>
  );
};
