import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { Utilization } from "lib/at-data/Utilization";
import { AsyncData } from "lib/at-data/AsyncData";
import { AssetFeature } from "components/spatial/base/layers/assets";
import * as AD from "lib/at-data/AsyncData";

export type OccupancyEstimateContext = {
  slice: AD.AsyncData<Array<AssetFeature<{ occupancyEstimate: number }>>>;
  maxOccupancy: AD.AsyncData<number>;
};

export type UtilizationContext = {
  utilizationSlice: AsyncData<
    Array<AssetFeature<{ utilizationColor: string }>>
  >;
};

export const withUtilizationContext =
  <T>(
    utilizationSlice: AsyncData<
      Array<AssetFeature<{ utilizationColor: string }>>
    >
  ) =>
  (ctx: T) => ({
    ...ctx,
    utilizationSlice,
  });
