import * as Eq from "fp-ts/Eq";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import React, { Dispatch, SetStateAction } from "react";
import * as R from "fp-ts/Reader";
import { AppContext } from "contexts/AppContext";
import * as IO from "fp-ts/IO";
import {
  appContextEq,
  toMinimalAppState,
} from "views/authenticated/app/ReadyAppState";
import {
  AppController,
  LoggerContext,
} from "controllers/AppController/AppController";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import {
  composeController,
  ControllerProps,
  ControllerReactContext,
  defineCalculatedContext,
  defineController,
  useController,
} from "lib/at-react/defineController";
import { AppState } from "views/authenticated/app/controller/state";

/**
 * App Controller will rerun its effects if main application context changes
 * @param initialState
 * @param stateEq
 * @param contextEq
 * @param effectIO
 */
export const defineAppController = <CONTEXT extends Object, STATE>(
  initialState: STATE,
  stateEq: Eq.Eq<STATE>,
  contextEq: Eq.Eq<CONTEXT>,
  effectIO: (
    dispatch: Dispatch<SetStateAction<STATE>>
  ) => R.Reader<
    CONTEXT & AppContext & AbortingControllerContext & LoggerContext,
    IO.IO<void>
  >
): [
  React.FC<ControllerProps<CONTEXT>>,
  React.Context<
    ControllerReactContext<STATE, CONTEXT & AppContext & LoggerContext>
  >
] => {
  const [component, controller] = defineController<
    CONTEXT & AppContext & LoggerContext,
    STATE
  >(
    initialState,
    stateEq,
    Eq.fromEquals(
      (a, b) =>
        contextEq.equals(a, b) &&
        appContextEq.equals(a.appContext, b.appContext)
    ),
    effectIO
  );

  return [
    (p) => {
      const [appState] = useController(AppController, (_) => _);

      return pipe(
        appState,
        toMinimalAppState,
        O.foldW(
          () => null,
          ({
            accessToken,
            wifiAPI,
            dataProvider,
            authOrganization,
            logger,
          }) => {
            return React.createElement(
              component,
              {
                context: {
                  ...p.context,
                  appContext: {
                    accessToken,
                    wifiAPI,
                    dataProvider,
                    authOrganization,
                  },
                  logger,
                },
              },
              p.children
            );
          }
        )
      );
    },
    controller,
  ];
};
