import React from "react";
import styled from "styled-components";
import { palette, vars } from "./styles/Styles";
import Icon, {
  AtCampus,
  // AtSpaces,
  AtDevices,
  AtIntegrations,
  AtUsers,
} from "components/ui/Icon";

// const Tab: React.FC<{
//   // href: string;
//   icon: React.ReactElement;
//   label: string;
//   selected?: string;
// }> = ({ label, icon, selected }) => {
//   return (
//     <SettingsTab className={selected}>
//       <a>
//         <Icon icon={icon} size={"16px"} />
//         {label}
//       </a>
//     </SettingsTab>
//   );
// };
//
// export const DeployTabBar: React.FC<{}> = (props) => {
//   return (
//     <TabBar>
//       <ul>
//         <Tab label={"Spaces"} icon={<AtCampus />} />
//         <Tab label={"Data Sources"} icon={<AtDevices />} />
//         <Tab label={"Integrations"} icon={<AtIntegrations />} />
//         <Tab label={"Users & Roles"} icon={<AtUsers />} />
//       </ul>
//     </TabBar>
//   );
// };

export const DeployTabBar: React.FC<{}> = (props) => {
  return (
    <TabBar>
      <ul>
        <SettingsTab className={"selected"}>
          <a>
            <Icon icon={AtCampus} size={"16px"} />
            Spaces
          </a>
        </SettingsTab>
        <SettingsTab>
          <a>
            <Icon icon={AtDevices} size={"16px"} />
            Data Sources
          </a>
        </SettingsTab>
        <SettingsTab>
          <a>
            <Icon icon={AtIntegrations} size={"16px"} />
            Integrations
          </a>
        </SettingsTab>
        <SettingsTab>
          <a>
            <Icon icon={AtUsers} size={"16px"} />
            User & Roles
          </a>
        </SettingsTab>
      </ul>
    </TabBar>
  );
};

export default DeployTabBar;

export const TabBar = styled("nav")`
  display: flex;
  width: 100%;
  // height: ${vars.header}px;
  background-color: ${palette.neutral[800]};
  padding: 0;
  width: 100%;

  & ul {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 0;
    padding: 0 0 0 8px;
    list-style: none;
  }
`;
export const SettingsTab = styled("li")`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  & > a {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 16px 16px;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 5px;

    color: ${palette.neutral[400]};
    & > svg {
      margin-right: 8px;
      & > * {
        fill: ${palette.neutral[400]};
      }
    }
  }
  &:hover > a {
    color: ${palette.neutral[200]};
  }

  &.selected {
    // background-color: gray;

    a {
      color: ${palette.neutral[100]};
      border-bottom: 3px solid ${palette.primary[400]};

      & > svg {
        & > * {
          fill: ${palette.neutral[100]};
        }
      }
    }
  }
`;
