import * as C from "io-ts/Codec";
import { SeriesRequestModel } from "lib/at-data/requests/temporal/SeriesRequest";
import * as Eq from "fp-ts/Eq";
import * as D from "fp-ts/Date";
import * as N from "fp-ts/number";
import { option } from "lib/codecs/option";

export const UISelectedSliceModel = C.struct({
  selectedSlice: option(SeriesRequestModel),
});
export const SelectedSliceModelEq = Eq.struct({
  start: D.Eq,
  end: D.Eq,
});
