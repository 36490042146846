import * as C from "io-ts/Codec";
import { option } from "lib/codecs/option";
import { CoordModel } from "lib/at-data/assets/models/Coord";
import { GeoCoordModel } from "lib/at-data/GeoCoord";
import { GeoReferenceModel } from "lib/at-data/assets/models/GeoReferenceModel";
import { FloorDiagramModel } from "lib/at-data/assets/models/FloorDiagramModel";
import { SqMModel } from "lib/at-data/units/area";

export const AssetSpatialModel = C.struct({
  poly: option(C.array(CoordModel)),
  geometry: option(C.array(GeoCoordModel)),
  center: option(GeoCoordModel),
  geoReference: option(GeoReferenceModel),
  diagram: option(FloorDiagramModel),
  area: option(SqMModel),
  bearing: option(C.number),
});

export type AssetSpatial = C.TypeOf<typeof AssetSpatialModel>;
