import * as R from "fp-ts/Reader";
import * as IO from "fp-ts/IO";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { clog, noop } from "lib/util";
import {
  cameraForBounds,
  fitBounds,
  fitBoundsSmooth,
  toBounds,
} from "views/authenticated/assets/page/components/AssetPreviewMap/actions/map";
import {
  FLOOR_DIAGRAM_LAYER_ID,
  setBuildingDiagramSource,
  setupFloorDiagramLayer,
} from "components/spatial/base/layers/floorDiagram";
import {
  bbox,
  bboxPolygon,
  featureCollection,
  polygon,
  transformRotate,
  transformScale,
} from "@turf/turf";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { Asset } from "lib/at-data/assets/Asset";
import { Assets } from "lib/at-data/assets/assets";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import { FloorDiagramContext } from "views/authenticated/assets/page/components/AssetPreviewMap/util";
import * as AD from "lib/at-data/AsyncData";
import {
  hideLoading,
  updateLoadingLayer,
} from "components/spatial/base/layers/loading";
import { isBuilding, isFloor } from "lib/at-data/assets/predicates";
import { hideLayer, showLayer } from "lib/fp-mapbox/layers";
import { BBox } from "lib/at-data/BBox";
import {
  apFirstIO,
  chainFirstIO,
} from "views/authenticated/activity/page/components/ReaderIO";
import { getFeatureBBox } from "views/authenticated/assets/page/components/AssetPreviewMap/actions/asset/getFeatureBBox";
import { getGeometry, getPolygon } from "lib/at-data/assets/getters";

export const getAllAssetsBounds = (assets: Assets) =>
  pipe(
    assets,
    A.filterMap(flow(getGeometry, O.map(flow(A.of, polygon)))),
    NEA.fromArray,
    O.map(flow(featureCollection, bbox, (_) => _ as BBox))
  );
export const getAllAssetsBoundsAtBearing =
  (bearing: number) => (assets: Assets) =>
    pipe(
      assets,
      A.filterMap(flow(getGeometry, O.map(flow(A.of, polygon)))),
      NEA.fromArray,
      O.map(
        flow(
          featureCollection,
          (_) => transformRotate(_, bearing),
          clog("children rotated bbox json1"),
          bbox,
          (_) => _ as BBox
        )
      )
    );

export const focusOnAllAssets = (assets: Assets) =>
  pipe(
    assets,
    getAllAssetsBounds,
    O.fold(
      () => R.of(noop),
      flow(R.of, R.chain(flow(bbox, bboxPolygon, toBounds, fitBoundsSmooth)))
    )
  );

export const getAssetsBBox = (assets: Assets) =>
  pipe(assets, getAllAssetsBounds);

export const scaleBBox = (scale: number) => (bb: BBox) =>
  pipe(transformScale(bboxPolygon(bb), scale), getFeatureBBox);

export const getAssetBBox = (asset: Asset) => pipe(asset, A.of, getAssetsBBox);

export const getAssetBBoxAtBearing = (bearing: number) => (asset: Asset) =>
  pipe(asset, A.of, getAllAssetsBoundsAtBearing(bearing));

export const assetScaleForBBox = (asset: Asset) => {
  if (isBuilding(asset)) return 1.5;
  if (isFloor(asset)) return 1.1;
  else return 1;
};

export const focusOnArea = (asset: Asset) =>
  pipe(
    asset,
    getPolygon,
    O.fold(
      () => R.of(noop),
      flow(R.of, R.chain(flow(bbox, bboxPolygon, toBounds, fitBounds)))
    )
  );

// TODO: clean this up and break it up
export const focusOnBuilding = (
  building: Asset
): R.Reader<MapboxContext, IO.IO<void>> =>
  pipe(
    building,
    getPolygon,
    O.fold(
      () => R.of(noop),
      flow(
        R.of,
        R.chain(
          flow(bbox, (bb) =>
            cameraForBounds(bb as BBox, {
              padding: {
                left: 75,
                top: 75,
                right: 75,
                bottom: 75,
              },
            })
          )
        )
      )
    )
  );

export const updateFloorPlanDiagram = (building: Asset) =>
  pipe(
    R.ask<FloorDiagramContext>(),
    R.map((_) => _.floorPlan),
    R.chainW(
      AD.fold(
        () =>
          pipe(
            hideLayer(FLOOR_DIAGRAM_LAYER_ID),
            chainFirstIO(updateLoadingLayer(building)),
            apFirstIO(showLayer("loading_layer"))
          ),
        O.fold(
          () => pipe(hideLoading, apFirstIO(hideLayer(FLOOR_DIAGRAM_LAYER_ID))),
          (floorDiagram) =>
            pipe(
              setBuildingDiagramSource(floorDiagram[0], floorDiagram[1]),
              chainFirstIO(setupFloorDiagramLayer),
              apFirstIO(hideLoading),
              apFirstIO(showLayer(FLOOR_DIAGRAM_LAYER_ID))
            )
        )
      )
    )
  );
