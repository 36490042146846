import { TabPanel } from "@mui/lab";
import { Button, Link } from "@mui/material";
import Icon, { AtDownloadPDF } from "components/ui/Icon";
import React, { useCallback } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import PageTableCellText from "components/table/cells/PageTableCellText";
import ReportsPlaceholderImage from "../ReportsPlaceholderImage";
import PageTableCellThumbnail from "components/table/cells/PageTableCellThumbnail";
import { useHistory } from "react-router-dom";
import { dataProviderL, UserL } from "views/authenticated/app/controller/state";
import * as O from "fp-ts/Option";
import { useController } from "lib/at-react/defineController";
import { AppController } from "controllers/AppController/AppController";
import { flow } from "fp-ts/lib/function";

interface IDownload {
  href?: string;
}

const Download: React.FC<IDownload> = ({ href }) => {
  const [isUTK] = useController(
    AppController,
    flow(
      UserL.get,
      O.map(dataProviderL.get),
      O.filter((_) => _ === "88bae34f-f09a-4e2c-b89c-d2f7fe6bb576"),
      O.isSome
    )
  );
  const reportLink = isUTK
    ? "/reports/utk/UTK_10032022_All_Spaces.pdf"
    : "/reports/bosci/BosSci_10032022_All_Spaces.pdf";

  return (
    <Button
      size="small"
      variant="text"
      color="primary"
      component={Link}
      href={reportLink}
      download
    >
      <Icon icon={AtDownloadPDF} size="16" sx={{ marginRight: "8px" }} />
      Download
    </Button>
  );
};

const currDate = new Date();
const nextMonth = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1);

const columns = [
  {
    name: "thumbnail",
    sortable: false,
    draggable: false,
    header: "",
    width: 100,
  },
  {
    name: "title",
    draggable: false,
    defaultFlex: 1,
    header: "Title",
    minWidth: 100,
  },
  {
    name: "description",
    draggable: false,
    header: "Description",
    defaultFlex: 2,
    minWidth: 200,
  },
  {
    name: "updated",
    draggable: false,
    header: "Last Updated",
    defaultFlex: 2,
    minWidth: 200,
  },
  {
    name: "more",
    sortable: false,
    draggable: false,
    header: "",
    defaultWidth: 150,
  },
];

const fakeData = [
  {
    thumbnail: (
      <PageTableCellThumbnail
        image={<ReportsPlaceholderImage reportType="bar" />}
      />
    ),
    title: (
      <PageTableCellText
        text="Monthly All Spaces - July"
        textStyles={{ fontWeight: 500 }}
      />
    ),
    description: (
      <PageTableCellText
        text="Executive level view of utilization across all spaces being covered."
        multiline
      />
    ),
    updated: (
      <PageTableCellText
        text="July 31, 2022, 10:48 PM"
        subtext={`Next Scheduled Update: ${nextMonth}, 10:48 am`}
      />
    ),
    more: <Download />,
  },
  {
    thumbnail: (
      <PageTableCellThumbnail
        image={<ReportsPlaceholderImage reportType="heatmap" />}
      />
    ),
    title: (
      <PageTableCellText
        text="Monthly All Spaces - June"
        textStyles={{ fontWeight: 500 }}
      />
    ),
    description: (
      <PageTableCellText
        text="Executive level view of utilization across all spaces being covered."
        multiline
      />
    ),
    updated: (
      <PageTableCellText
        text="June 30, 2022, 10:22 PM"
        subtext={`Next Scheduled Update: ${nextMonth}, 10:48 am`}
      />
    ),
    more: <Download />,
  },
];

const ReportsTableAll: React.FC = () => {
  const history = useHistory();

  const onRowClick = useCallback(() => {
    history.push("/reports/item");
  }, [history]);

  const onRenderRow = useCallback(
    (rowProps) => {
      // save the original handlers to be called later
      const { onClick } = rowProps;

      rowProps.onClick = (event: any) => {
        onRowClick();
        if (onClick) {
          onClick(event);
        }
      };
    },
    [onRowClick]
  );

  return (
    <TabPanel value="1" sx={{ padding: 0, height: "100%" }}>
      <ReactDataGrid
        licenseKey="AppName=ArmoredThingsApp,Company=ArmoredThings,ExpiryDate=2023-03-08,LicenseDeveloperCount=2,LicenseType=single_app,Ref=ArmoredThingsLicenseRef,Z=874177969-88290940832246787443234957-812671145-2050061877"
        treeColumn="assets"
        showColumnMenuTool={false}
        columns={columns}
        dataSource={fakeData}
        showZebraRows={false}
        showCellBorders={false}
        pagination={true}
        showHoverRows={false}
        columnMinWidth={75}
        rowHeight={80}
        // @ts-ignore TODO: The types for this property are broken so we need to ignore it
        onRenderRow={onRenderRow}
      />
    </TabPanel>
  );
};

export default ReportsTableAll;
