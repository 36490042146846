import React, { useState } from "react";
import { Form } from "../styles/Styles";
import Icon, { AtEyeOpen, AtGlobe } from "components/ui/Icon";
import { DayHoursEditor } from "views/authenticated/deploy/components/DayHoursEditor";
import {
  HoursOfOperation,
  NineToFiveGrind,
} from "lib/at-data/HoursOfOperation";
import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";

export const DeployFormOperatingHoursOrganizations: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <div>
          <label htmlFor={"hours"}>Hours of Operation</label>
          <input type={"text"} name={"hours"} />
        </div>
      </fieldset>
    </Form>
  );
};

export const DeployFormOperatingHoursRegions: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <div>
          <label htmlFor={"hours"}>Hours of Operation</label>
          <input type={"text"} name={"hours"} />
        </div>
      </fieldset>
    </Form>
  );
};

export const DeployFormOperatingHoursBuildings: React.FC<{}> = (props) => {
  return (
    <HoursOfOperationEditor
      hoursOfOperation={NineToFiveGrind}
      onChange={() => {}}
    />
  );
};

export const HoursOfOperationEditor: React.FC<{
  hoursOfOperation: HoursOfOperation;
  onChange: (hourOfOperation: HoursOfOperation) => void;
}> = (props) => {
  return (
    <Form>
      <fieldset>
        <legend>Weekdays</legend>
        <DayHoursEditor
          day={"MON"}
          onChange={(dh) => {
            console.log("New monday", dh);
            pipe(
              props.hoursOfOperation,
              pipe(
                L.id<HoursOfOperation>(),
                L.prop("schedule"),
                L.prop("monday")
              ).set(dh),
              props.onChange
            );
          }}
          daySchedule={props.hoursOfOperation.schedule.monday}
        />
        <DayHoursEditor
          day={"TUES"}
          daySchedule={props.hoursOfOperation.schedule.tuesday}
          onChange={(dh) =>
            pipe(
              props.hoursOfOperation,
              pipe(
                L.id<HoursOfOperation>(),
                L.prop("schedule"),
                L.prop("tuesday")
              ).set(dh),
              props.onChange
            )
          }
        />
        <DayHoursEditor
          day={"WED"}
          daySchedule={props.hoursOfOperation.schedule.wednesday}
          onChange={(dh) =>
            pipe(
              props.hoursOfOperation,
              pipe(
                L.id<HoursOfOperation>(),
                L.prop("schedule"),
                L.prop("wednesday")
              ).set(dh),
              props.onChange
            )
          }
        />
        <DayHoursEditor
          day={"THURS"}
          daySchedule={props.hoursOfOperation.schedule.thursday}
          onChange={(dh) =>
            pipe(
              props.hoursOfOperation,
              pipe(
                L.id<HoursOfOperation>(),
                L.prop("schedule"),
                L.prop("thursday")
              ).set(dh),
              props.onChange
            )
          }
        />
        <DayHoursEditor
          day={"FRI"}
          daySchedule={props.hoursOfOperation.schedule.friday}
          onChange={(dh) =>
            pipe(
              props.hoursOfOperation,
              pipe(
                L.id<HoursOfOperation>(),
                L.prop("schedule"),
                L.prop("friday")
              ).set(dh),
              props.onChange
            )
          }
        />
      </fieldset>
      <fieldset>
        <legend>Weekends</legend>
        <DayHoursEditor
          day={"SAT"}
          daySchedule={props.hoursOfOperation.schedule.saturday}
          onChange={(dh) =>
            pipe(
              props.hoursOfOperation,
              pipe(
                L.id<HoursOfOperation>(),
                L.prop("schedule"),
                L.prop("saturday")
              ).set(dh),
              props.onChange
            )
          }
        />
        <DayHoursEditor
          day={"SUN"}
          daySchedule={props.hoursOfOperation.schedule.sunday}
          onChange={(dh) =>
            pipe(
              props.hoursOfOperation,
              pipe(
                L.id<HoursOfOperation>(),
                L.prop("schedule"),
                L.prop("sunday")
              ).set(dh),
              props.onChange
            )
          }
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormOperatingHoursFloors: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <div>
          <label htmlFor={"hours"}>Hours of Operation</label>
          <input type={"text"} name={"hours"} />
        </div>
      </fieldset>
    </Form>
  );
};
