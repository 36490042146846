import * as C from "io-ts/Codec";
import { pipe } from "fp-ts/function";
import * as D from "io-ts/Decoder";
import * as G from "io-ts/Guard";
import * as En from "io-ts/Encoder";

export const isDate: G.Guard<unknown, Date> = {
  is: (u: unknown): u is Date => u instanceof Date,
};

export const dateFromISOString = C.make(
  C.fromDecoder(
    pipe(
      D.string,
      D.map(decodeURIComponent),
      D.parse((s) => {
        const d = new Date(s);
        return !Number.isNaN(d.getTime())
          ? D.success(d)
          : D.failure(s, "Date encoded as ISO8601 string");
      })
    )
  ),
  pipe(
    En.id<string>(),
    En.contramap((_: Date) => _.toISOString())
  )
);

export const date = D.fromGuard(isDate, "Date Object");
