import React, { Suspense, useMemo } from "react";
import NavBar from "components/navBar/NavBar";
import { RootRouter } from "views/authenticated/root/RootRouter";

const MainView: React.FC<{}> = (props) => {
  return (
    <>
      <NavBar />
      <RootRouter />
    </>
  );
};

export default MainView;
