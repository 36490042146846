import React, { useCallback } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { TableCell, TableRow } from "@mui/material";
import {
  Editable,
  LevelProperty,
  OptionalProperty,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import {
  FloorMetaEditor,
  NumberEditor,
  TextEditor,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/editors";
import {
  FloorMeta,
  FloorMetaEq,
  fromOrder,
} from "lib/at-data/assets/models/FloorMetaModel";
import { isPositive } from "newtype-ts/lib/Positive";
import {
  LegacyAssetsModificationsController,
  modifyAssetCapacity,
  modifyAssetFloorMeta,
} from "controllers/AssetsController/AssetsController";
import { ById } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import { CapacityL, FloorMetaL } from "lib/at-data/assets/lens";
import { useControllerDispatch } from "lib/at-react/defineController";
import {
  AssetsController,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";

export const LevelFloorMetaPropertiesRow: React.FC<{ asset: Asset }> = (
  props
) => {
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const handleSaveFloorMeta = useCallback(
    (floorMeta: FloorMeta) => {
      assetModificationsDispatch(
        flow(modifyAssetFloorMeta(props.asset, O.some(floorMeta)))
      );
      rootDispatch(
        flow(
          pipe(
            ById(props.asset.id),
            updateAsset,
            T.composeLens(FloorMetaL),
            T.set(O.some(floorMeta))
          )
        )
      );
    },
    [props.asset]
  );

  return (
    <>
      <TableRow
        key={"levelOrder"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          Level
        </TableCell>
        <TableCell align="right">
          <Editable
            onChange={handleSaveFloorMeta}
            value={pipe(
              props.asset.floorMeta,
              O.getOrElseW(() => fromOrder(0))
            )}
            eq={FloorMetaEq}
            editor={FloorMetaEditor}
          >
            <OptionalProperty
              required={true}
              component={LevelProperty}
              value={props.asset.floorMeta}
            />
          </Editable>
        </TableCell>
      </TableRow>
    </>
  );
};
