import { pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as TE from "fp-ts/TaskEither";

export const getFile = (e: unknown) =>
  pipe(
    E.tryCatch(() => {
      // @ts-ignore
      const file = e.target.files[0];
      return file as File;
    }, E.toError),
    TE.fromEither
  );

export const toBase64 = (file: File) =>
  TE.tryCatch<Error, { content: string; contentType: string }>(() => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        // @ts-ignore
        resolve({
          // @ts-ignore
          content: reader.result.split(",")[1],
          contentType: file.type,
        });
      };
    });
  }, E.toError);
