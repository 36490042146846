import React from "react";
import { palette, vars } from "./styles/Styles";
import Icon, { AtSpaceManager } from "components/ui/Icon";
import styled from "styled-components";

export const DeployHeader: React.FC<{}> = (props) => {
  return (
    <Header>
      <h1>
        <Icon icon={AtSpaceManager} />
        Spaces
      </h1>
    </Header>
  );
};

export const Header = styled("header")`
  display: flex;
  width: 100%;
  height: ${vars.header}px;
  color: ${palette.neutral[100]};
  padding: 8px 24px;
  background-color: #1d1e30;
  & h1 {
    margin: 24px 0 0 0;
    font-size: clamp(
      1.6rem,
      calc(0.9037974683544305rem + 1.2658227848101267vw),
      2.2rem
    );
    font-weight: 500;
    line-height: 1.25;
    font-family: Fira Sans, sans-serif;
    color: #ffffff;
  }
`;
