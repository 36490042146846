import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { vars } from "theme/helpers";
import theme from "theme/theme";
import { useController } from "lib/at-react/defineController";
import { UIStateContext } from "controllers/UIStateContext/UIStateContext";

interface IPage extends BoxProps {
  /**
   * Resizes the main content based on whether or not the sidebar is expanded/collapsed
   */
  contextualSidebarOpen: boolean;
}

/**
 * The Page component is necessary for resizing the core content of the site based on the context of the sidebar(s).
 */

const Page: React.FC<Partial<IPage>> = ({
  contextualSidebarOpen = false,
  ...props
}) => {
  const [showTabs] = useController(UIStateContext, (_) => _.showTabs);
  const [showDrawer] = useController(UIStateContext, (_) => _.showDrawer);
  const [width] = useController(UIStateContext, (_) => _.sideWidth);

  return (
    <Main
      showTabs={showTabs}
      component="main"
      contextualSidebarOpen={contextualSidebarOpen}
      {...props}
    >
      {props.children}
    </Main>
  );
};

export default Page;

const Main = styled(Box)<IPage & { showTabs: boolean }>`
  min-height: calc(100vh - ${vars.navBar});
  // width: ${(props) => (props.showTabs ? "80%" : "100%")}
  margin-right: ${(props) =>
    props.contextualSidebarOpen
      ? vars.contextualSidebarDrawer
      : vars.contextualSidebar};
  transition: ${(props) =>
    props.contextualSidebarOpen
      ? theme.transitions.create(["margin-right"], { duration: "195ms" })
      : theme.transitions.create(["margin-right"], { duration: "225ms" })};
`;
