import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import React, {
  Context,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
} from "react";
import * as IO from "fp-ts/IO";
import * as R from "fp-ts/Reader";
import { pipe } from "fp-ts/function";
import {
  useStable,
  useStableEffect,
  useStableMemo,
} from "fp-ts-react-stable-hooks";
import * as Eq from "fp-ts/Eq";
import * as L from "monocle-ts/Lens";

export interface ControllerProps<C> {
  context: C;
}

export type ControllerDispatchContext<STATE> = {
  dispatch: Dispatch<SetStateAction<STATE>>;
};

export type ControllerStateContext<STATE> = {
  state: STATE;
};

export const ControllerStateContext = <STATE>(state: STATE) => ({ state });

export const eqShallow = Eq.fromEquals<object | string | number>(
  (x, y) => x === y
);

export const ControllerDispatchContext = <STATE>(
  dispatch: Dispatch<SetStateAction<STATE>>
): ControllerDispatchContext<STATE> => ({ dispatch });

export type ControllerReactContext<STATE, CONTEXT> = [
  STATE,
  Dispatch<SetStateAction<STATE>>
];

// Controller is something that manages state and can run side effects changing that state, potentially with some context
export const defineController = <CONTEXT extends object, STATE>(
  initialState: STATE,
  stateEq: Eq.Eq<STATE>,
  contextEq: Eq.Eq<CONTEXT>,
  effectIO: (
    dispatch: Dispatch<SetStateAction<STATE>>
  ) => R.Reader<CONTEXT & AbortingControllerContext, IO.IO<void>>
): [
  React.FC<ControllerProps<CONTEXT>>,
  React.Context<
    ControllerReactContext<STATE, CONTEXT & AbortingControllerContext>
  >
] => {
  const context = React.createContext<ControllerReactContext<STATE, CONTEXT>>([
    initialState,
    (d) => d,
  ]);

  return [
    (p) => {
      const [state, dispatch] = useStable<STATE>(initialState, stateEq);
      const lastEffectId = useRef<number>(0);
      const abortControllerRef = useRef(new AbortController());

      const abortingDispatch: (
        requestId: number
      ) => Dispatch<SetStateAction<STATE>> = useMemo(
        () => (requestId) => {
          return (reducer) => {
            if (requestId !== lastEffectId.current) {
              console.log(
                "controller: not dispatching from stale effect",
                state
              );
            } else dispatch(reducer);
          };
        },
        [lastEffectId, dispatch]
      );

      useStableEffect(
        () => {
          abortControllerRef.current.abort();
          abortControllerRef.current = new AbortController();
          lastEffectId.current += 1;
          effectIO(abortingDispatch(lastEffectId.current))({
            ...p.context,
            ...{ abortingController: abortControllerRef.current },
          })();
        },
        [p.context],
        Eq.tuple(contextEq)
      );

      return React.createElement(
        context.Provider,
        {
          value: [state, dispatch] as ControllerReactContext<STATE, CONTEXT>,
        },
        p.children
      );
    },
    context,
  ];
};

// TODO refactor ControllerReactContext to support being read only and not include dispatch to solve this
const fakeDispatch = (d: any) => d;

export const defineCalculatedContext = <CONTEXT extends object, VALUES>(
  initialState: VALUES,
  contextEq: Eq.Eq<CONTEXT>,
  fn: (c: CONTEXT) => VALUES
): [
  component: React.FC<ControllerProps<CONTEXT>>,
  controller: React.Context<ControllerReactContext<VALUES, CONTEXT>>
] => {
  const context = React.createContext<ControllerReactContext<VALUES, CONTEXT>>([
    initialState,
    fakeDispatch,
  ]);

  return [
    (p) => {
      const values = useStableMemo(
        () => fn(p.context),
        [p.context],
        Eq.tuple(contextEq)
      );
      return React.createElement(
        context.Provider,
        {
          value: [values, fakeDispatch] as ControllerReactContext<
            VALUES,
            CONTEXT
          >,
        },
        p.children
      );
    },
    context,
  ];
};

export const composeController =
  <STATEA, STATEB, CONTEXTA extends STATEB, CONTEXTB>(
    a: [
      React.FC<ControllerProps<CONTEXTA>>,
      React.Context<ControllerReactContext<STATEA, CONTEXTA>>
    ]
  ) =>
  (
    controllerBContext: React.Context<ControllerReactContext<STATEB, CONTEXTB>>
  ): [
    React.FC<ControllerProps<Omit<CONTEXTA, keyof STATEB>>>,
    React.Context<ControllerReactContext<STATEA, CONTEXTA>>
  ] =>
    [
      (p) => {
        const [stateB] = useController(controllerBContext, (_) => _);
        return React.createElement(
          a[0],
          { context: { ...stateB, ...p.context } as CONTEXTA },
          p.children
        );
      },
      a[1],
    ];

/**
 * @param context
 * @param reducerFn
 */
export function useController<STATE, RSTATE, CONTEXT>(
  context: Context<ControllerReactContext<STATE, CONTEXT>>,
  reducerFn: (state: STATE) => RSTATE
): [RSTATE, Dispatch<SetStateAction<STATE>>] {
  const [state, dispatch] = React.useContext(context);
  return [reducerFn(state), dispatch];
}

export function useCollectorOld<STATE, RSTATE, CONTEXT>(
  context: Context<ControllerReactContext<STATE, CONTEXT>>,
  lensFn: (l: L.Lens<STATE, STATE>) => L.Lens<STATE, RSTATE>
): RSTATE {
  const [state] = React.useContext(context);
  return pipe(state, lensFn(L.id<STATE>()).get);
}

// export function useCollector<STATE, CONTEXT>(
//   context: Context<ControllerReactContext<STATE, CONTEXT>>
// ): STATE {
//   const [state] = React.useContext(context);
//   return pipe(state, L.id<STATE>().get);
// }

export function useControllerDispatch<STATE, CONTEXT>(
  context: Context<ControllerReactContext<STATE, CONTEXT>>
): Dispatch<SetStateAction<STATE>> {
  return useController(context, (s) => s)[1];
}

export type SetControllerStateAction<S> = (prevState: S) => S;
