import { pipe } from "fp-ts/function";
import { LevelProperties } from "lib/at-api/assets/otter";
import * as C from "io-ts/Codec";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import * as Eq from "fp-ts/Eq";
import * as n from "fp-ts/number";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";

export const FloorMetaModel = C.struct({
  abbreviation: optionFromNullable(C.string),
  order: C.number,
});
export type FloorMeta = C.TypeOf<typeof FloorMetaModel>;

export const fromLevelProperties = (lp: LevelProperties): O.Option<FloorMeta> =>
  pipe(
    lp.levelNumber,
    O.map((ln) => ({
      order: ln,
      abbreviation: lp.levelLabel,
    }))
  );

export const FloorMetaEq = Eq.struct<FloorMeta>({
  order: n.Eq,
  abbreviation: O.getEq(s.Eq),
});

export const fromOrder = (order: number): FloorMeta => ({
  order,
  abbreviation: O.none,
});

export const withAbbreviation =
  (abbreviation: O.Option<string>) => (fm: FloorMeta) => ({
    ...fm,
    abbreviation,
  });
