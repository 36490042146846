import React from "react";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import { initialState } from "./initialState";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as IO from "fp-ts/lib/IO";
import * as Eq from "fp-ts/Eq";
import { getRootSeries } from "views/authenticated/assets/actions/workerActions";
import { AsyncData } from "lib/at-data/AsyncData";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { AppContext } from "contexts/AppContext";
import {
  ActivityLiveOccupancyState,
  ActivityLiveOccupancyStateEq,
  LastOccupancyEstimateL,
} from "./state";
import { AssetContext, AssetOContext } from "contexts/AssetContext";
import { eqAsset } from "lib/at-data/assets/Asset";
import { subMinutes } from "date-fns/fp";
import * as AD from "lib/at-data/AsyncData";
import * as D from "lib/at-data/DataSeries";
import * as CD from "lib/at-data/ChartData";
import { ChartDataSlice } from "lib/at-data/ChartDataSlice";
import { defineAppController } from "lib/at-react/defineAppController";
import { clog, noop } from "../../lib/util";
import { loggingAsyncDataEffectLegacy, loggingAsyncEffect } from "lib/logging";

//TODO: call backend
export const getLiveTime: IO.IO<Date> = () => pipe(new Date(), subMinutes(5));

export const setLastOcuppancyEstimate =
  (
    dispatch: React.Dispatch<React.SetStateAction<ActivityLiveOccupancyState>>
  ) =>
  (data: AsyncData<D.DataSeries<OccupancyEstimate>>): IO.IO<void> =>
  () => {
    return pipe(
      data,
      AD.getData,
      CD.lastSliceWithValueOccupancyEstimate,
      O.map((el) => AD.DoneData(O.of(el))),
      O.getOrElse(() => AD.DoneData(O.none as O.Option<ChartDataSlice>)),
      LastOccupancyEstimateL.set,
      dispatch
    );
  };

const [component, controller] = defineAppController<
  AssetOContext,
  ActivityLiveOccupancyState
>(
  initialState,
  ActivityLiveOccupancyStateEq,
  Eq.struct({ asset: O.getEq(eqAsset) }),
  (dispatch) =>
    pipe(
      RTE.ask<AssetOContext & AppContext>(),
      RTE.map((_) => _.asset),
      RTE.bindTo("rootAssetO"),
      RTE.apS("liveTime", RTE.fromIO(getLiveTime)),
      RTE.chainW(({ liveTime, rootAssetO }) =>
        pipe(getRootSeries(rootAssetO, O.some(new Date())))
      ),
      loggingAsyncDataEffectLegacy(
        "Activity Live Occupancy",
        setLastOcuppancyEstimate(dispatch)
      )
    )
);
export const ActivityLiveOccupancyControllerComponent = component;
export const ActivityLiveOccupancyController = controller;
