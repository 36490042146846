import * as O from "fp-ts/lib/Option";
import * as NEA from "fp-ts/NonEmptyArray";
import React from "react";
import Page from "components/ui/Page";
import { AssetWeatherTab } from "../../assets/page/components/AssetsDetailPreview/AssetsDetailPreviewTab";
import { flow, pipe } from "fp-ts/function";
import { Stack, styled } from "@mui/material";
import palette from "../../../../theme/palette";
import { alpha } from "@mui/system";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import {
  ActivityPageUIStateL,
  SelectedSideItemL,
  SidePanelOpenL,
} from "controllers/UIStateCollector/lens/activityPage";
import ActivityPageContent from "views/authenticated/activity/controller/ActivityPageContent";
import {
  ActivityPageUICalculationsControllerComponent,
  UIStateContextComponent,
} from "controllers/UIStateContext/UIStateContext";
import { sequenceS, sequenceT } from "fp-ts/Apply";
import { ActivityPageL } from "controllers/UIStateCollector/lens/ActivityPageL";
import * as S from "fp-ts/Set";
import { eqUUID } from "lib/at-data/UUID";
import {
  SelectedAssetL,
  SelectedAssetsController,
  SelectedAssetsControllerComponent,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import * as A from "fp-ts/Array";

export const activityTabs = {
  // [ASSET_CONTEXT_TABS.ASSET]: (onClose: () => void) => (
  //   <AssetsDetailPreviewTab onClose={onClose} />
  // ),
  // [ASSET_CONTEXT_TABS.UTILIZATION]: (onClose: () => void) => (
  //   <AssetUtilizationTab onClose={onClose} />
  // ),
  // [ASSET_CONTEXT_TABS.INSIGHTS]: (onClose: () => void) => (
  //   <AssetInsightsTab onClose={onClose} />
  // ),
  // [ASSET_CONTEXT_TABS.SCHEDULE]: (onClose: () => void) => (
  //   <AssetScheduleTab onClose={onClose} />
  // ),
  [ASSET_CONTEXT_TABS.WEATHER]: (onClose: () => void) => (
    <AssetWeatherTab onClose={onClose} />
  ),
};

const ActivityPage: React.FC<{}> = React.memo(() => {
  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );
  const [selectedSideItem] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(SelectedSideItemL)).get
  );

  const sidebarOpen = pipe(
    sequenceT(O.Applicative)(selectedAsset, selectedSideItem),
    O.isSome
  );

  const uiProps = useCollectorOld(
    UIStateController,
    L.composeLens(
      pipe(
        ActivityPageUIStateL,
        L.props("selectedSideItem", "sidebarOpen", "timebarOpen")
      )
    )
  );

  return (
    <UIStateContextComponent
      context={{
        selectedSideItem,
        disableSideTabs: true,
      }}
    >
      <ActivityPageUICalculationsControllerComponent
        context={{
          rightBarOpen: sidebarOpen,
          sidebarOpen: uiProps.sidebarOpen,
          timebarOpen: uiProps.timebarOpen,
        }}
      >
        <Page contextualSidebarOpen={sidebarOpen}>
          <ActivityPageContent />
        </Page>
      </ActivityPageUICalculationsControllerComponent>
    </UIStateContextComponent>
  );
});

export default ActivityPage;

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
export const StyledPanelFooter = styled(Stack)`
  z-index: 5;
  background-color: ${palette.neutral[700]};
  box-shadow: 0 -2px 24px ${alpha(palette.neutral[800], 0.5)};
`;
