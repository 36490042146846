import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import React from "react";
import * as ROR from "fp-ts/ReadonlyRecord";
import { PercentageCell } from "views/authenticated/assets/page/components/AssetsTable/cells";
import * as A from "fp-ts/Array";
import {
  formatPercent,
  mean,
} from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
import * as t from "io-ts";
import { option } from "io-ts-types";
import * as E from "fp-ts/Either";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import {
  getAssetCostPerSqM,
  getTargetUtilization,
} from "lib/at-data/assets/getters";

export const AssetTargetUtilizationColumn = defineColumn(
  "assetTargetUtilization",
  pipe(
    R.asks<AssetRowDataContext, Asset>((_) => _.asset),
    R.map(getTargetUtilization),
    R.map(flow((_) => ROR.singleton("assetTargetUtilization", _)))
  ),
  R.of(PercentageCell),
  flow(
    readColumnData,
    A.filterMap(
      flow(
        ROR.lookup("assetTargetUtilization"),
        O.chain(
          flow(
            option(t.number).decode,
            E.map(flow(O.chain(O.fromPredicate(Number.isFinite)))),
            O.fromEither,
            O.flatten
          )
        )
      )
    ),
    mean,
    O.map(flow(toPercentage, Math.round, formatPercent)),
    (value) => (
      <TableHeaderKpi
        label="Target"
        hint="Organization-specific utilization goal, which can be set at a space level."
        value={value}
      />
    )
  ),
  R.of({
    defaultFlex: 0.5,
    sort: O.getOrd(N.Ord).compare,
  })
);
