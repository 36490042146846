import React from "react";
import { useCollectorWithDispatch } from "lib/at-react/hooks";
import {
  LambentDashboardNotificationEq,
  NotificationsController,
} from "controllers/NotificationsController/NotificationsController";
import * as L from "monocle-ts/Lens";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { useStableEffect } from "fp-ts-react-stable-hooks";
import * as Eq from "fp-ts/Eq";
import * as O from "fp-ts/Option";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ApplicationToast: React.FC<{}> = (props) => {
  const [notifications] = useCollectorWithDispatch(
    NotificationsController,
    L.prop("notifications")
  );
  const latestNotification = pipe(notifications, A.last);

  useStableEffect(
    () => setOpen(true),
    [latestNotification],
    Eq.tuple(O.getEq(LambentDashboardNotificationEq))
  );

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      mode={"dark"}
    >
      {pipe(
        latestNotification,
        O.foldW(
          () => <div />,
          (notification) => (
            <Alert
              onClose={handleClose}
              severity={notification.type}
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          )
        )
      )}
    </Snackbar>
  );
};
