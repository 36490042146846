import { Box, styled } from "@mui/material";
import React from "react";
import palette from "theme/palette";
import theme from "theme/theme";
import Icon, { AtMultipleImages } from "./Icon";

interface IImageThumbnail {
  /**
   * Path to thumbnail image
   */
  image: string;
  /**
   * In case of multiple images, count of total number of images
   */
  count?: number;
  /**
   * In case you want the image to span the full width of the container
   */
  fullWidth?: boolean;
  /**
   * Define the image aspect ratio. Default is set to `1`
   */
  aspectRatio?: number;
}

const ImageThumbnail: React.FC<IImageThumbnail> = (props) => {
  return (
    <StyledBox $fullWidth={props.fullWidth} $aspectRatio={props.aspectRatio}>
      <Image src={props.image} />
      {props.count && props.count > 0 ? (
        <StyledIcon
          icon={AtMultipleImages}
          count={props.count}
          color={palette.neutral[100]}
          secondaryColor={palette.neutral[900]}
          size="24px"
        />
      ) : null}
    </StyledBox>
  );
};

export default ImageThumbnail;

export const StyledBox = styled(Box)<{
  $fullWidth?: boolean;
  $aspectRatio?: number;
}>`
  position: relative;
  flex-shrink: 0;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "150px")};
  overflow: hidden;
  border-radius: 8px;
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || "1"};
`;

const Image = styled("img")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: ${theme.spacing(1)};
  right: ${theme.spacing(1)};
`;
