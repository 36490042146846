import { Lens } from "monocle-ts";
import * as Eq from "fp-ts/Eq";
import * as AD from "lib/at-data/AsyncData";
import {
  ReportLinkResponse,
  ReportMetadata,
} from "lib/at-api/reports/reportMetadata";

export type ReportsState = {
  reports: AD.AsyncData<Array<ReportMetadata>>;
};

export type ReportLinksState = {
  reportLinks: AD.AsyncData<Array<ReportLinkResponse>>;
};

export const ReportsStateEq = Eq.struct<ReportsState>({
  reports: AD.getEq(Eq.eqStrict),
});

export const ReportLinksStateEq = Eq.struct<ReportLinksState>({
  reportLinks: AD.getEq(Eq.eqStrict),
});

export const ReportsL = Lens.fromProp<ReportsState>()("reports");
export const ReportLinksL = Lens.fromProp<ReportLinksState>()("reportLinks");
