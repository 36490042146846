import {
  AssetsCompareColumn,
  AssetsCompareColumnEq,
  AssetsCompareColumnModel,
} from "controllers/AssetsTableController/models/AssetCompareColumn";
import {
  AssetsFormulaColumn,
  AssetsFormulaColumnEq,
  AssetsFormulaColumnModel,
} from "controllers/AssetsTableController/models/AssetsFormulaColumn";
import {
  AssetsMetricColumn,
  AssetsMetricColumnEq,
  AssetsMetricColumnModel,
} from "controllers/AssetsTableController/models/AssetsMetricColumn";
import { TableColumnTypes } from "controllers/AssetsTableController/tableColumnTypes";
import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import { eqStrict } from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as S from "fp-ts/Set";
import * as s from "fp-ts/string";
import * as C from "io-ts/Codec";
import * as L from "monocle-ts/Lens";
import { match } from "ts-adt";

// export type AssetTableColumn2 = ADT<{
//   metric: AssetsMetricColumn;
//   formula: AssetsFormulaColumn;
//   compare: AssetsCompareColumn;
// }>;

export const AssetTableColumnModel = C.sum("_type")({
  [TableColumnTypes.METRIC]: AssetsMetricColumnModel,
  [TableColumnTypes.FORMULA]: AssetsFormulaColumnModel,
  [TableColumnTypes.COMPARE]: AssetsCompareColumnModel,
});

export type AssetTableColumn = C.TypeOf<typeof AssetTableColumnModel>;

export const AssetTableColumnEq = Eq.fromEquals<AssetTableColumn>((x, y) =>
  x._type === y._type
    ? pipe(
        x,
        match({
          metric: () =>
            AssetsMetricColumnEq.equals(
              x as AssetsMetricColumn,
              y as AssetsMetricColumn
            ),
          formula: () =>
            AssetsFormulaColumnEq.equals(
              x as AssetsFormulaColumn,
              y as AssetsFormulaColumn
            ),
          compare: () =>
            AssetsCompareColumnEq.equals(
              x as AssetsCompareColumn,
              y as AssetsCompareColumn
            ),
        })
      )
    : false
);

export enum TableDataViews {
  BROWSER = "browser",
  CSV = "csv",
}

export const TableDataViesEq: Eq.Eq<TableDataViews> = s.Eq;

export interface TableColumnInfo {
  column: AssetTableColumn;
  views: Set<TableDataViews>;
}

export const TableColumnViewL = pipe(L.id<TableColumnInfo>(), L.prop("views"));

export const isShownFor = (view: TableDataViews) => (column: TableColumnInfo) =>
  pipe(column.views, S.elem(s.Eq)(view));

export const tableColumn = (
  column: AssetTableColumn,
  views: Set<TableDataViews>
): TableColumnInfo => ({
  column,
  views,
});

export const defaultTableColumn = (
  column: AssetTableColumn
): TableColumnInfo => ({
  column,
  views: new Set([TableDataViews.CSV, TableDataViews.BROWSER]),
});

export const eqTableColumnInfo = Eq.struct<TableColumnInfo>({
  column: AssetTableColumnEq,
  views: S.getEq(s.Eq),
});

export interface AssetsTableState {
  columns: Array<TableColumnInfo>;
}

export const AssetsTableStateEq = Eq.struct<AssetsTableState>({
  columns: A.getEq(eqTableColumnInfo),
});

export const ColumnsL = pipe(L.id<AssetsTableState>(), L.prop("columns"));
