import * as R from "fp-ts/Reader";
import * as RT from "fp-ts/ReaderTask";
import * as L from "monocle-ts/Lens";
import React, { Dispatch, SetStateAction } from "react";
import { pipe } from "fp-ts/function";
import {
  Collector,
  CollectorOutputDispatch,
  CollectorOutputState,
} from "lib/at-react/collector";

type CollectorInput<INTAKE, STATE> =
  | Collector<
      INTAKE,
      CollectorOutputState<STATE> & CollectorOutputDispatch<STATE>
    >
  | Collector<INTAKE, CollectorOutputState<STATE>>;

export function useCollector<STATE, INTAKE>(
  collector: CollectorInput<INTAKE, STATE>
): STATE;
export function useCollector<STATE, RSTATE, INTAKE>(
  collector: CollectorInput<INTAKE, STATE>,
  lensFn: (l: L.Lens<STATE, STATE>) => L.Lens<STATE, RSTATE>
): RSTATE;

export function useCollector<STATE, RSTATE = STATE, INTAKE = any>(
  collector: CollectorInput<INTAKE, STATE>,
  lensFn?: (l: L.Lens<STATE, STATE>) => L.Lens<STATE, RSTATE>
): STATE | RSTATE {
  const state = React.useContext(
    collector.Context as React.Context<CollectorOutputState<STATE>>
  );
  return lensFn ? pipe(state.state, lensFn(L.id<STATE>()).get) : state.state;
}

export function useCollectorWithDispatch<ASTATE, INTAKE>(
  collector: Collector<
    INTAKE,
    CollectorOutputState<ASTATE> & CollectorOutputDispatch<ASTATE>
  >
): [ASTATE, Dispatch<SetStateAction<ASTATE>>];

export function useCollectorWithDispatch<ASTATE, BSTATE, INTAKE>(
  collector: Collector<
    INTAKE,
    CollectorOutputState<ASTATE> & CollectorOutputDispatch<ASTATE>
  >,
  lensFn: (l: L.Lens<ASTATE, ASTATE>) => L.Lens<ASTATE, BSTATE>
): [BSTATE, Dispatch<SetStateAction<ASTATE>>];

export function useCollectorWithDispatch<
  ASTATE,
  BSTATE = ASTATE,
  INTAKE = unknown
>(
  collector: Collector<
    INTAKE,
    CollectorOutputState<ASTATE> & CollectorOutputDispatch<ASTATE>
  >,
  lensFn?: (l: L.Lens<ASTATE, ASTATE>) => L.Lens<ASTATE, BSTATE>
): [BSTATE | ASTATE, Dispatch<SetStateAction<ASTATE>>] {
  const { state, dispatch } = React.useContext(collector.Context);
  const transformedState = lensFn
    ? pipe(state, lensFn(L.id<ASTATE>()).get)
    : state;
  return [transformedState, dispatch];
}

export function useCollectorDispatch<ASTATE, BSTATE, INTAKE>(
  collector: Collector<
    INTAKE,
    CollectorOutputState<ASTATE> & CollectorOutputDispatch<ASTATE>
  >
): Dispatch<SetStateAction<ASTATE>> {
  const { dispatch } = React.useContext(collector.Context);
  return dispatch;
}

export function useCollectorEffect<INTAKE, MUTABLE_STATE, FNARGS>(
  collector: Collector<
    INTAKE,
    CollectorOutputState<INTAKE & MUTABLE_STATE> &
      CollectorOutputDispatch<MUTABLE_STATE>
  >,
  callbackFn: R.Reader<
    FNARGS,
    (
      dispatch: React.Dispatch<React.SetStateAction<MUTABLE_STATE>>
    ) => RT.ReaderTask<INTAKE, void>
  >
): R.Reader<FNARGS, void> {
  const { dispatch, state } = React.useContext(collector.Context);
  return (args) => {
    callbackFn(args)(dispatch)(state)();
  };
}
