import React, { HTMLAttributeAnchorTarget, useCallback } from "react";
import {
  Avatar,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Icon from "components/ui/Icon";
import {
  RiEqualizerFill,
  RiLogoutBoxLine,
  RiQuestionLine,
} from "react-icons/ri";
import theme from "theme/theme";
import NavIconButton from "./NavIconButton";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/function";
import { clearDashboardSettingsIO } from "models/DashboardSettings";
import {
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { AppController } from "controllers/AppController/AppController";
import { Link } from "react-router-dom";
import { NewLink, NewLink2 } from "./NavTabs";
import { UIStateController } from "../../controllers/UIStateCollector/UIStateController";
import { PathL } from "../../controllers/UIStateCollector/models/UIStateModel";

// export const MenuLink = React.forwardRef<
//     HTMLAnchorElement,
//     { to: string; target?: HTMLAttributeAnchorTarget; }
//     >(function LinkWithRef({ to, target, ...rest }, ref) {
//   const dispatch = useControllerDispatch(UIStateController);
//   const href = "";
//   const handleClick = useCallback(
//       (ev: any) => {
//         pipe(to, PathL.set, dispatch);
//         ev.stopPropagation();
//       },
//       [dispatch]
//   );
//   return (
//         {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
//         <a {...rest} onClick={handleClick} ref={ref} target={target} />
//   );
// });

const NavAccount: React.FC = () => {
  const [appState] = useController(AppController, ({ authClient, user }) => ({
    authClient,
    user,
  }));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () =>
    pipe(
      appState.authClient,
      O.fold(
        () => {},
        (a) => {
          clearDashboardSettingsIO();
          window.history.replaceState(null, "");
          a.logout({ returnTo: window.location.origin });
        }
      )
    );

  return (
    <div>
      {/*<Typography>Hello, {u?.nickname}!</Typography>*/}

      <NavIconButton
        label="Account"
        onClick={handleClick}
        sx={{ borderRadius: 50, height: 36, width: 36 }}
      >
        <Avatar
          alt={pipe(
            appState.user,
            O.chain((_) => _.nickname),
            O.toUndefined
          )}
          src={pipe(
            appState.user,
            O.chain((_) => _.picture),
            O.toUndefined
          )}
          sx={{ width: 24, height: 24 }}
        />
      </NavIconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock={true}
        mode="dark"
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid
          container
          alignItems="center"
          gap={2}
          pt={2}
          pb={1}
          sx={{ minWidth: "160px" }}
        >
          <Grid item>
            <Avatar
              alt={pipe(
                appState.user,
                O.chain((_) => _.nickname),
                O.toUndefined
              )}
              src={pipe(
                appState.user,
                O.chain((_) => _.picture),
                O.toUndefined
              )}
              sx={{ width: 40, height: 40 }}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body2" sx={{ fontWeight: 600 }} noWrap>
              {pipe(
                appState.user,
                O.chain((_) => _.nickname),
                O.toUndefined
              )}
            </Typography>
            <Typography variant="body2" noWrap>
              {pipe(
                appState.user,
                O.chain((_) => _.email),
                O.toUndefined
              )}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ margin: `${theme.spacing(1)} 0` }} />
        <MenuItem component={NewLink2} to="/settings">
          <ListItemIcon>
            <Icon icon={RiEqualizerFill} size="20px" />
          </ListItemIcon>
          Feature Settings
        </MenuItem>
        {/*<MenuItem>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Icon icon={RiEqualizerFill} size="20px" />*/}
        {/*  </ListItemIcon>*/}
        {/*  Account Preferences*/}
        {/*</MenuItem>*/}
        {/*<MenuItem>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Icon icon={RiQuestionLine} size="20px" />*/}
        {/*  </ListItemIcon>*/}
        {/*  Support*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Icon icon={RiLogoutBoxLine} size="20px" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
};

export default NavAccount;
