import React from "react";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";

export const OptionalTextCell: React.FC<{ value: O.Option<string> }> = ({
  value,
}) => {
  return (
    <span>
      {pipe(
        value,
        O.getOrElse(() => "")
      )}
    </span>
  );
};
