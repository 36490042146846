import * as A from "fp-ts/Array";
import * as o from "fp-ts/Ord";
import React from "react";
import { Autocomplete, FormControl, Stack, TextField } from "@mui/material";
import { pipe } from "fp-ts/function";
import { numberFromString } from "lib/codecs/numberFromString";
import * as E from "fp-ts/Either";
import { noop } from "lib/util";
import {
  FloorMeta,
  fromOrder,
  withAbbreviation,
} from "lib/at-data/assets/models/FloorMetaModel";
import * as O from "fp-ts/Option";
import { GeoReference } from "lib/at-data/assets/models/GeoReferenceModel";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import timezones from "timezones-list";

export type TextEditorProps = {
  value: string;
  pending: boolean;
  onChange: (value: string) => void;
  onDone: () => void;
  onCancel: () => void;
};

export const TextEditor: React.FC<
  Partial<Omit<TextFieldProps, "onChange">> & TextEditorProps
> = ({ onCancel, onDone, onChange, value, pending, ...props }) => (
  <TextField
    autoFocus
    {...props}
    onKeyDown={(e) => {
      if (e.key === "Escape") {
        onCancel();
      }
      if (e.key === "Enter") {
        onDone();
      }
    }}
    onChange={(ev) => {
      onChange(ev.target.value);
    }}
    value={value}
    variant={pending ? "standard" : "filled"}
    size="small"
    style={{ minWidth: "100px" }}
  />
);
export const NumberEditor: React.FC<{
  value: number;
  pending: boolean;
  onChange: (value: number) => void;
  onDone: () => void;
  onCancel: () => void;
}> = (props) => (
  <TextField
    type={"number"}
    autoFocus
    onKeyDown={(e) => {
      if (e.key === "Escape") {
        props.onCancel();
      }
      if (e.key === "Enter") {
        props.onDone();
      }
    }}
    onChange={(ev) => {
      pipe(
        ev.target.value,
        numberFromString.decode,
        E.fold(noop, props.onChange)
      );
    }}
    value={props.value}
    variant={props.pending ? "standard" : "filled"}
    size="small"
    style={{ minWidth: "100px" }}
  />
);

const tzCodes: string[] = pipe(
  timezones,
  A.map((data) => data.tzCode)
);

export const DEFAULT_TIMEZONE = "America/New_York";

export const TimeZoneEditor: React.FC<{
  value: string;
  pending: boolean;
  onChange: (value: string) => void;
  onDone: () => void;
  onCancel: () => void;
}> = (props) => (
  <Autocomplete
    disableClearable={true}
    multiple={false}
    options={pipe(tzCodes, A.sort(o.ordString))}
    getOptionLabel={(option) => option}
    value={props.value}
    onChange={(ev, tz) => tz && props.onChange(tz)}
    renderInput={(params) => (
      <TextField {...params} variant="standard" size={"small"} />
    )}
    onKeyDown={(e) => {
      if (e.key === "Escape") {
        props.onCancel();
      }
      if (e.key === "Enter") {
        props.onDone();
      }
    }}
    size={"small"}
    style={{ minWidth: "100px" }}
  />
);

export const GeoReferenceEditor: React.FC<{
  value: O.Option<GeoReference>;
  pending: boolean;
  onChange: (value: O.Option<GeoReference>) => void;
  onDone: () => void;
  onCancel: () => void;
}> = (props) => <Stack spacing={1}>hi</Stack>;

export const FloorMetaEditor: React.FC<{
  value: FloorMeta;
  pending: boolean;
  onChange: (value: FloorMeta) => void;
  onDone: () => void;
  onCancel: () => void;
}> = (props) => (
  <Stack spacing={1}>
    <TextField
      type={"number"}
      autoFocus
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          props.onCancel();
        }
        if (e.key === "Enter") {
          props.onDone();
        }
      }}
      onChange={(ev) => {
        pipe(
          ev.target.value,
          numberFromString.decode,
          E.map(fromOrder),
          E.fold(noop, props.onChange)
        );
      }}
      value={props.value.order}
      variant={props.pending ? "standard" : "filled"}
      size="small"
    />
    <TextField
      onChange={(ev) => {
        pipe(
          props.value,
          withAbbreviation(
            pipe(
              ev.target.value,
              O.fromPredicate((_) => _.length > 0)
            )
          ),
          props.onChange
        );
      }}
      value={pipe(
        props.value.abbreviation,
        O.getOrElse(() => "")
      )}
      variant={
        props.pending && O.isSome(props.value.abbreviation)
          ? "standard"
          : "filled"
      }
      size="small"
    />
  </Stack>
);
