import {
  defineColumn,
  readColumnData,
  withDataAgg,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import * as AA from "fp-ts-std/Array";
import React from "react";
import { UsdCell } from "views/authenticated/assets/page/components/AssetsTable/cells";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as t from "io-ts";
import * as E from "fp-ts/Either";
import { sequenceS, sequenceT } from "fp-ts/Apply";
import * as AD from "lib/at-data/AsyncData";
import { asyncData } from "lib/at-data/AsyncData";
import {
  formatNumber,
  UINumber,
  TwoDecimal,
} from "lib/formatters/formatNumber";
import { option as option2 } from "lib/codecs/option";
import * as C from "io-ts/Codec";
import { option } from "io-ts-types";
import { isoUSD, USD, USDModel } from "lib/at-data/units/currency";
import { AssetModel } from "lib/at-data/assets/models/AssetModel";
import { getTargetUtilization } from "lib/at-data/assets/getters";

export const calculateUnderUtilization = ({
  utilization,
  cost,
  targetUtilization,
}: {
  utilization: number;
  cost: USD;
  targetUtilization: number;
}) => Math.max((targetUtilization - utilization) * isoUSD.unwrap(cost), 0);

export const UnderutilizationCostColumn = pipe(
  defineColumn(
    "assetUnderutilizationCost",
    R.of(ROR.empty),
    R.of(UsdCell),
    flow(
      readColumnData,
      A.filterMap(
        flow(
          ROR.lookup("assetUnderutilizationCost"),
          O.chain(
            flow(
              option2(C.number).decode,
              E.map(flow(O.chain(O.fromPredicate(Number.isFinite)))),
              O.fromEither,
              O.flatten
            )
          )
        )
      ),
      flow(NEA.fromArray),
      O.map(flow(AA.sum, formatNumber(UINumber))),
      O.map((value) => `$${value}`),
      (value) => (
        <TableHeaderKpi
          label="Underutilization Cost"
          hint="The annualized cost of the space utilized less than its specified target. It is calculated as Size multiplied by the Price multiplied by the (Target Utilization - Actual Utilization)."
          value={value}
        />
      )
    ),
    R.of({
      defaultFlex: 1,
      sort: O.getOrd(N.Ord).compare,
    })
  ),
  withDataAgg((data) =>
    pipe(
      data,
      ROR.upsertAt(
        "assetUnderutilizationCost",
        pipe(
          sequenceS(O.Monad)({
            utilization: pipe(
              data,
              ROR.lookup("assetMeanUtilization"),
              O.chain(
                flow(
                  asyncData(option(t.number)).decode,
                  O.fromEither,
                  O.chain(flow(AD.getData, O.flatten))
                )
              )
            ),
            cost: pipe(
              data,
              ROR.lookup("cost"),
              O.chain(flow(option2(USDModel).decode, O.fromEither, O.flatten))
            ),
            targetUtilization: pipe(
              data,
              ROR.lookup("assetTargetUtilization"),
              O.chain(flow(option2(C.number).decode, O.fromEither, O.flatten))
            ),
          }),
          O.map(flow(calculateUnderUtilization))
        ) as unknown
      )
    )
  )
);
