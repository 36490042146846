import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import * as AA from "fp-ts-std/Array";
import * as O from "fp-ts/Option";
import * as n from "fp-ts/number";

export const range = (data: Array<number>) =>
  pipe(
    data,
    NEA.fromArray,
    O.map((d) =>
      pipe(
        [pipe(d, AA.minimum(n.Ord)), pipe(d, AA.maximum(n.Ord))],
        A.uniq(n.Eq),
        A.filter((a) => a > 0)
      )
    )
  );
