import { Drawer, DrawerProps, Fab, styled } from "@mui/material";
import { keyframes } from "@mui/system";
import Icon, { AtArrowCaretDoubleUp } from "components/ui/Icon";
import { SeriesL } from "contexts/SeriesContext";
import { useStableEffect } from "fp-ts-react-stable-hooks";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { gsap } from "gsap";
import { Asset } from "lib/at-data/assets/Asset";
import {
  SeriesRequest,
  SeriesRequestEq,
} from "lib/at-data/requests/temporal/SeriesRequest";
import { useCollectorWithDispatch } from "lib/at-react/hooks";
import * as L from "monocle-ts/Lens";
import React, { useEffect } from "react";
import palette from "theme/palette";
import theme from "theme/theme";
import ActivityContentTimebarControls from "views/authenticated/activity/page/components/navigator/ActivityContentTimebarControls";
import ActivityContentTimebarGraph from "views/authenticated/activity/page/components/navigator/ActivityContentTimebarGraph";
import ActivityContentTimebarHeader from "views/authenticated/activity/page/components/navigator/ActivityContentTimebarHeader";
import {
  TimelineGranulatedRangeCollector,
  TimelineRangeCollector,
  TimelineWidthCollector,
  WidthL,
} from "views/authenticated/activity/page/components/navigator/TimelineRangeCollector";

interface IDrawer extends DrawerProps {
  handleTimebarOpen: () => void;
  handleTimebarClose: () => void;
  handleSelectedTimestampChange: (date: Date) => void;
  onSelectedRangeChange: (range: SeriesRequest) => void;
  selectedFineRange: SeriesRequest;
  selectedAsset: O.Option<Asset>;
}

const ActivityContentTimebar: React.FC<IDrawer> = React.memo(
  ({
    handleTimebarClose,
    handleTimebarOpen,
    handleSelectedTimestampChange,
    onSelectedRangeChange,
    selectedFineRange,
    selectedAsset,
    ...props
  }) => {
    const [width, widthDispatch] = useCollectorWithDispatch(
      TimelineWidthCollector,
      L.composeLens(WidthL)
    );
    const [series, seriesDispatch] = useCollectorWithDispatch(
      TimelineRangeCollector,
      L.composeLens(SeriesL)
    );

    return (
      <TimelineGranulatedRangeCollector.Component context={{ width, series }}>
        <ExpandButton
          onClick={handleTimebarOpen}
          aria-label="open timebar"
          type="button"
        >
          <Icon icon={AtArrowCaretDoubleUp} size="16px" />
        </ExpandButton>{" "}
        <StyledDrawer {...props}>
          <ActivityContentTimebarHeader
            selectedAsset={selectedAsset}
            onSelectedRangeChange={onSelectedRangeChange}
          />
          <ActivityContentTimebarGraph
            selectedAsset={selectedAsset}
            onSelectedRangeChange={onSelectedRangeChange}
            selectedRange={selectedFineRange}
          />
          <ActivityContentTimebarControls
            handleTimebarClose={handleTimebarClose}
          />
        </StyledDrawer>
      </TimelineGranulatedRangeCollector.Component>
    );
  }
);

export default ActivityContentTimebar;

const StyledDrawer = styled(Drawer)`
  width: 100%;
  align-self: flex-end;
  z-index: 3;
  overflow: visible;
  transform: translateY(${(props) => (!props.open ? "calc(100% - 50px)" : 0)});
  transition: ${theme.transitions.create("transform", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })};

  .MuiPaper-root {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    background-color: ${palette.neutral[800]};
    position: relative;
    display: flex;
    padding: 0;
    border-radius: 0;
  }
`;

const bounce = keyframes`
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const ExpandButton = styled(Fab)`
  position: absolute;
  bottom: 50px;
  right: ${theme.spacing(2)};
  height: 36px;
  width: 36px;
  background-color: ${palette.neutral[700]};
  border: none;
  cursor: pointer;
  transform: translateY(4px);
  z-index: 3;
  color: ${palette.neutral[100]};
  padding: 2px 0 0;
  border-radius: 50% 50% 0 0;
  transition: ${theme.transitions.create(["background-color", "transform"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  })};

  &:hover {
    background-color: ${palette.neutral[600]};
    transform: translateY(0);
  }

  .MuiSvgIcon-root {
    animation: ${bounce} 1s ease-in-out infinite;
  }
`;
