import { defineController } from "lib/at-react/defineController";
import * as Eq from "fp-ts/Eq";
import { eqStrict } from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as L from "monocle-ts/Lens";

export type State = {
  toggleMenuModalEl: O.Option<HTMLElement>;
};

export const ToggleMenuModalEl = pipe(
  L.id<State>(),
  L.prop("toggleMenuModalEl")
);

const [component, controller] = defineController<{}, State>(
  { toggleMenuModalEl: O.none },
  Eq.struct({
    toggleMenuModalEl: eqStrict,
  }),
  Eq.struct({}),
  (dispatch) => () => () => {}
);

export const ToggleMenuControllerComponent = component;
export const ToggleMenuController = controller;
