import Icon, { AtAlertWarning } from "components/ui/Icon";
import { AppController } from "controllers/AppController/AppController";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { useController } from "lib/at-react/defineController";
import { clearDashboardSettingsIO } from "models/DashboardSettings";
import React from "react";
import styled, { keyframes } from "styled-components";
import { Button, Stack, Typography } from "@mui/material";
import { ErrorDisplay } from "views/public/welcome/ErrorDisplay";

const Spinner: React.FC = () => {
  return (
    <Backdrop>
      <Svg
        width="148"
        height="140"
        viewBox="0 0 37 35"
        fill="none"
        className="spinner"
      >
        <path
          d="M24.2954 0H12.4512V7.10217H24.2954V0Z"
          // fill="#E50695"
          className="c1"
        />
        <path
          d="M36.3733 13.8003L30.4512 3.55469L24.2938 7.10577L30.2159 17.3514L36.3733 13.8003Z"
          fill="#1ECAD3"
          className="c2"
        />
        <path
          d="M30.4275 31.1245L36.3496 20.8789L30.1922 17.3278L24.2701 27.5734L30.4275 31.1245Z"
          fill="#00B2E3"
          className="c3"
        />
        <path
          d="M24.2954 27.5664H12.4512V34.6686H24.2954V27.5664Z"
          fill="#0071CE"
          className="c4"
        />
        <path
          d="M12.4729 27.5737L6.55078 17.3281L0.3934 20.8792L6.31551 31.1248L12.4729 27.5737Z"
          fill="#B14FC5"
          className="c5"
        />
        <path
          d="M6.52906 17.3511L12.4512 7.10547L6.29379 3.55438L0.371679 13.8L6.52906 17.3511Z"
          fill="#EE6FBB"
          className="c6"
        />
      </Svg>
      <Loading>Loading</Loading>
    </Backdrop>
  );
};
export const ApplicationError: React.FC<{ error: Error }> = (props) => {
  const [appState] = useController(AppController, (_) => _);
  const handleLogout = () =>
    pipe(
      appState.authClient,
      O.fold(
        () => {},
        (a) => {
          clearDashboardSettingsIO();
          window.history.replaceState(null, "");
          a.logout({ returnTo: window.location.origin });
        }
      )
    );

  return (
    <Backdrop>
      <svg width="148" height="140" viewBox="0 0 37 35" fill="none">
        <path
          d="M24.2954 0H12.4512V7.10217H24.2954V0Z"
          fill="#E50695"
          className="c1"
        />
        <path
          d="M36.3733 13.8003L30.4512 3.55469L24.2938 7.10577L30.2159 17.3514L36.3733 13.8003Z"
          fill="#1ECAD3"
          className="c2"
        />
        <path
          d="M30.4275 31.1245L36.3496 20.8789L30.1922 17.3278L24.2701 27.5734L30.4275 31.1245Z"
          fill="#00B2E3"
          className="c3"
        />
        <path
          d="M24.2954 27.5664H12.4512V34.6686H24.2954V27.5664Z"
          fill="#0071CE"
          className="c4"
        />
        <path
          d="M12.4729 27.5737L6.55078 17.3281L0.3934 20.8792L6.31551 31.1248L12.4729 27.5737Z"
          fill="#B14FC5"
          className="c5"
        />
        <path
          d="M6.52906 17.3511L12.4512 7.10547L6.29379 3.55438L0.371679 13.8L6.52906 17.3511Z"
          fill="#EE6FBB"
          className="c6"
        />
      </svg>
      <ErrorDisplay>
        <Stack direction={"row"} spacing={1}>
          <Icon icon={AtAlertWarning} color={"white"} size={"16px"} />
          <p>
            <strong>Error:&nbsp;</strong>
            {props.error.message}
          </p>
        </Stack>
      </ErrorDisplay>
      <div style={{ margin: "1em" }}>
        <Button onClick={handleLogout} color="primary">
          Log Out
        </Button>
      </div>
    </Backdrop>
  );
};

export default Spinner;

const Spin = keyframes`
 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Svg = styled("svg")`
  animation: ${Spin} 3s ease-in-out infinite;
  & .c1 {
    fill: #e50695;
    // opacity: 0.5;
  }
`;
export const Backdrop = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 9999;
  position: fixed;
  background-color: hsla(237, 24%, 15%, 0.85);
  backdrop-filter: blur(5px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const Loading = styled(Typography)`
  color: white;
  font-size: 18px;
  padding-top: 24px;
`;
