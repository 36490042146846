import React from "react";
import {
  Stack,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled,
} from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import Icon, { AtChevronDown, AtInfo } from "components/ui/Icon";
import { AssetTags } from "./AssetTags";

const CompareAssetInfo: React.FC<{}> = () => {
  return (
    <Accordion>
      <SectionHeader
        expandIcon={
          <Icon
            icon={AtChevronDown}
            size="24px"
            color={`${palette.neutral[100]}`}
          />
        }
        aria-controls="panel-content-info"
        id="panel-header-info"
      >
        <Icon
          icon={AtInfo}
          size="24px"
          color={`${palette.neutral[100]}`}
          sx={{ marginRight: "12px" }}
        />
        Space Information
      </SectionHeader>
      <SectionDetails>
        <Bookings>
          <Pane>
            <PaneSection>
              <Stack direction="row">
                <Image>
                  <img src="/images/su.png" alt="img" width="75" height="75" />
                </Image>
                <Stack direction="column">
                  <AssetName>SU - Student Union - Floor 2</AssetName>
                  <Stack>
                    <AssetTags />
                  </Stack>
                </Stack>
              </Stack>
            </PaneSection>
            <PaneSection>
              <GroupHeading>General Information</GroupHeading>
              <KpiContainer>
                <MetricKpi>
                  <Heading>CAPACITY</Heading>
                  <Metric>168</Metric>
                </MetricKpi>
                <MetricKpi>
                  <Heading>SIZE</Heading>
                  <Metric>19,364 ft²</Metric>
                </MetricKpi>
                {/*<MetricKpi>*/}
                {/*  <Heading>COVERAGE</Heading>*/}
                {/*  <Metric>150</Metric>*/}
                {/*</MetricKpi>*/}
                <Stack>
                  <Heading>OPERATING HOURS</Heading>
                  <Metric>7am-10pm</Metric>
                </Stack>
              </KpiContainer>
            </PaneSection>
            <PaneSection>
              <GroupHeading>Utilization</GroupHeading>
              <KpiContainer>
                <MetricKpi>
                  <Heading>Target GOAL</Heading>
                  <Metric>50%</Metric>
                </MetricKpi>
                <MetricKpi>
                  <Heading>THRESHOLD MAX</Heading>
                  <Metric>90%</Metric>
                </MetricKpi>
                <MetricKpi>
                  <Heading>THRESHOLD MIN</Heading>
                  <Metric>10%</Metric>
                </MetricKpi>
              </KpiContainer>
            </PaneSection>
          </Pane>
          <Pane>
            <PaneSection>
              <Stack direction="row">
                <Image>
                  <img src="/images/su.png" alt="img" width="75" height="75" />
                </Image>
                <Stack direction="column">
                  <AssetName>SU - Student Union - Floor 2</AssetName>
                  <Stack>
                    <AssetTags />
                  </Stack>
                </Stack>
              </Stack>
            </PaneSection>
            <PaneSection>
              <GroupHeading>General Information</GroupHeading>
              <KpiContainer>
                <MetricKpi>
                  <Heading>CAPACITY</Heading>
                  <Metric>168</Metric>
                </MetricKpi>
                <MetricKpi>
                  <Heading>SIZE</Heading>
                  <Metric>19,364 ft²</Metric>
                </MetricKpi>
                {/*<MetricKpi>*/}
                {/*  <Heading>COVERAGE</Heading>*/}
                {/*  <Metric>150</Metric>*/}
                {/*</MetricKpi>*/}
                <Stack>
                  <Heading>OPERATING HOURS</Heading>
                  <Metric>7am-10pm</Metric>
                </Stack>
              </KpiContainer>
            </PaneSection>
            <PaneSection>
              <GroupHeading>Utilization</GroupHeading>
              <KpiContainer>
                <MetricKpi>
                  <Heading>Target GOAL</Heading>
                  <Metric>50%</Metric>
                </MetricKpi>
                <MetricKpi>
                  <Heading>THRESHOLD MAX</Heading>
                  <Metric>90%</Metric>
                </MetricKpi>
                <MetricKpi>
                  <Heading>THRESHOLD MIN</Heading>
                  <Metric>10%</Metric>
                </MetricKpi>
              </KpiContainer>
            </PaneSection>
          </Pane>
        </Bookings>
      </SectionDetails>
    </Accordion>
  );
};

export default CompareAssetInfo;
const SectionHeader = styled(AccordionSummary)`
  background-color: ${palette.neutral[650]};
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 0 32px;
  &:hover {
    background-color: ${palette.neutral[600]};
  }
`;
const SectionDetails = styled(AccordionDetails)`
  background-color: #46495e;
  padding: 0;
`;
const Bookings = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;
const Image = styled(Box)`
  margin-right: 16px;
`;
const Pane = styled(Stack)`
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  background-color: #323548;
  color: #ffffff;
  padding: 32px;
  &:first-of-type {
    border-right: 1px solid hsla(0, 0%, 0%, 0.75);
  }
  &:last-of-type {
    border-left: 1px solid hsla(0, 100%, 100%, 0.15);
  }
`;
const PaneSection = styled(Stack)`
  width: 100%;
  flex-direction: column;
  // justify-content: flex-start;
  // align-items: center;
  // align-content: center;
  // text-align: center;
  margin: 8px 0;
`;
const KpiContainer = styled(Stack)`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-align: center;
  margin: 0;
`;
const MetricKpi = styled(Stack)`
  width: 25%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0;
`;
const AssetName = styled(Typography)`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
`;
const Heading = styled(Typography)`
  color: #dddddd;
  font-size: 11px;
  text-transform: uppercase;
`;
const Metric = styled(Typography)`
  font-size: 18px;
`;
const GroupHeading = styled(Typography)`
  margin-bottom: 12px;
  display: block;
`;
