import * as T from "fp-ts/Task";
import { pipe } from "fp-ts/function";
import * as TE from "fp-ts/TaskEither";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as b from "fp-ts/boolean";
import { Auth0Client, RedirectLoginResult } from "@auth0/auth0-spa-js";
import { ApplicationEnv } from "applicationEnv";
import { logEffect } from "lib/logging";

export const configureAuthClient = (
  redirectUri: string,
  { clientId, audience, domain }: ApplicationEnv
): Auth0Client =>
  new Auth0Client({
    domain,
    client_id: clientId,
    audience,
    redirect_uri: redirectUri,
    useRefreshTokens: true,
    cacheLocation: "localstorage",
  });

export const redirectToInviteFlow = (url: string, authClient: Auth0Client) => {
  const inviteMatches = url.match(/invitation=([^&]+)/);
  const orgMatches = url.match(/organization=([^&]+)/);
  if (inviteMatches && orgMatches) {
    authClient.loginWithRedirect({
      organization: orgMatches[1],
      invitation: inviteMatches[1],
    });
  }
};

export interface AuthenticationSuccess {
  redirectAfterLogin: O.Option<string>;
}

export const AuthenticationSuccess = (): AuthenticationSuccess => ({
  redirectAfterLogin: O.none,
});

export const AuthenticationSuccessAndRedirect = (
  redirectURL: O.Option<string>
): AuthenticationSuccess => ({
  redirectAfterLogin: redirectURL,
});

export const isAuthenticatedTE = (authClient: Auth0Client) =>
  pipe(
    TE.tryCatch(() => {
      return authClient.isAuthenticated();
    }, E.toError),
    T.map(
      E.chain((isAuthenticated) =>
        isAuthenticated
          ? E.right(AuthenticationSuccess())
          : E.left(new Error("Not Authenticated"))
      )
    )
  );

export const isRedirectUrl = (url: string) =>
  url.includes("code=") && url.includes("state=");

export const isInviteUrl = (url: string) =>
  url.includes("invitation=") && url.includes("organization=");

export const checkIfAuthenticated =
  (url: string) => (authClient: Auth0Client) =>
    pipe(
      url,
      isRedirectUrl,
      b.fold(
        () => isAuthenticatedTE(authClient),
        () =>
          pipe(
            TE.tryCatch(
              () => authClient.handleRedirectCallback(url),
              E.toError
            ),
            TE.map((r) =>
              AuthenticationSuccessAndRedirect(
                O.fromNullable(r?.appState?.redirectAfterLogin)
              )
            )
          )
      )
    );
