/* eslint-disable react/no-did-update-set-state */
import React from "react";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { Popover, Typography, styled, Stack } from "@mui/material";
import palette from "theme/palette";
import { isManagedAssetTag } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import { not } from "fp-ts/Predicate";

type AssetTagsCellProps = {
  value: Array<string>;
};

type AssetTagsCellState = {
  visibleTags: Array<string>;
  popoverTags: Array<string>;
  anchorEl: HTMLElement | null;
};

class AssetTagsCellClass extends React.Component<
  AssetTagsCellProps,
  AssetTagsCellState
> {
  constructor(props: AssetTagsCellProps | Readonly<AssetTagsCellProps>) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      visibleTags: this.props.value.slice(0, 1),
      popoverTags: this.props.value.slice(1),
      anchorEl: null,
    };
  };

  handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidUpdate = (prevProps: AssetTagsCellProps) => {
    if (prevProps.value !== this.props.value) {
      this.setState(this.getInitialState());
    }
  };

  render() {
    const { visibleTags, popoverTags, anchorEl } = this.state;
    const { handlePopoverOpen, handlePopoverClose } = this;

    const open = Boolean(anchorEl);

    return (
      <div>
        {pipe(
          O.fromNullable(visibleTags.length > 0 ? visibleTags : null),
          O.map((tags) => (
            <Stack direction="row">
              {pipe(
                tags,
                A.mapWithIndex((i, tag) => <Tag key={i}>{tag}</Tag>)
              )}
              {popoverTags.length > 0 ? (
                <div>
                  <Tag
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    +{popoverTags.length}
                  </Tag>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    disableScrollLock={true}
                  >
                    <Stack p={1}>
                      {pipe(
                        popoverTags,
                        A.mapWithIndex((i, tag) => <Tag key={i}>{tag}</Tag>)
                      )}
                    </Stack>
                  </Popover>
                </div>
              ) : null}
            </Stack>
          )),
          O.getOrElseW(() => <span>--</span>)
        )}
      </div>
    );
  }
}

export const AssetTagsCell: React.FC<{ value: string[] }> = ({ value }) => {
  return (
    <AssetTagsCellClass value={pipe(value, A.filter(not(isManagedAssetTag)))} />
  );
};

const Tag = styled("span")`
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 12px;
  background-color: ${palette.primary[100]};
  color: ${palette.primary[700]};
  width: auto;
  padding: 4px 12px;
  height: 24px;
  font-size: 12px;
  margin: 4px 2px 4px 0;
  &:first-of-type {
    margin-left: 0;
  }
`;
