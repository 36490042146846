import * as Eq from "fp-ts/Eq";
import { AssetsUIStateEq } from "controllers/UIStateCollector/models/AssetsPageUIStateModel";
import { ActivityPageUIStateEq } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import { defineHistoryController } from "lib/at-react/defineHistoryController";
import { UIState } from "controllers/UIStateCollector/models/UIStateModel";
import { uiState } from "controllers/UIStateCollector/initialState/uiState";
import { LuciferPageUIStateEq } from "controllers/UIStateCollector/models/LuciferPageUIStateModel";

export const [UIStateControllerComponent, UIStateController] =
  defineHistoryController<UIState>(
    {
      pathname: "/",
      state: uiState,
    },
    Eq.struct({
      assetsPage: AssetsUIStateEq,
      activityPage: ActivityPageUIStateEq,
      luciferPage: LuciferPageUIStateEq,
    })
  );
