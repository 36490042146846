import React, { useCallback } from "react";
import { styled } from "@mui/material";
import palette from "theme/palette";
import Icon, { AtShare } from "components/ui/Icon";
import NavIconButton from "./NavIconButton";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/function";
import { useController } from "lib/at-react/defineController";
import {
  ShareLinkController,
  ShareLinkModalEl,
} from "controllers/AppController/ShareLinkController";
import mixpanel from "mixpanel-browser";

const NavShare: React.FC = () => {
  const [shareLinkModalEl, dispatch] = useController(
    ShareLinkController,
    ShareLinkModalEl.get
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      pipe(event.currentTarget, O.fromNullable, ShareLinkModalEl.set, dispatch);
      mixpanel.track("Share Link Clicked");
    },
    [dispatch]
  );

  return (
    <NavIconButton
      label="Share Link"
      onClick={handleClick}
      sx={{ borderRadius: 50, height: 36, width: 36 }}
    >
      <ShareIcon icon={AtShare} size={"24"} />
    </NavIconButton>
  );
};

export default NavShare;

const ShareIcon = styled(Icon)`
  & *,
  &:hover * {
    transition: fill 0.5s ease;
  }
  & circle {
    fill: ${palette.primary[500]};
  }
  & path {
    fill: ${palette.neutral[100]};
  }
  &:hover circle {
    fill: ${palette.neutral[100]};
  }
  &:hover path {
    fill: ${palette.primary[700]};
  }
`;
