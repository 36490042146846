import {
  Assets,
  eqAssets,
  eqAssetsByUUIDOnly,
} from "lib/at-data/assets/assets";
import * as AD2 from "lib/at-data/AsyncData2";
import * as L from "monocle-ts/Lens";
import * as Eq from "fp-ts/Eq";
import { AssetTags, AssetTagsEq } from "lib/at-api/asset-tags/assetTags";
import { pipe } from "fp-ts/function";
import { AssetsContext } from "contexts/AssetsContext";

export type RootAssetsContext = {
  assets: AD2.AsyncData2<Assets>;
};

export type RootState = {
  assetTags: AssetTags;
} & RootAssetsContext;

export const RootStateEq = Eq.struct<RootState>({
  assets: AD2.getEq(eqAssets),
  assetTags: AssetTagsEq,
});

export const RootStateL = L.id<RootState>();

export const AssetsL = pipe(L.id<RootAssetsContext>(), L.prop("assets"));
export const AssetsL2 = pipe(L.id<RootState>(), L.prop("assets"));
export const TagsL = pipe(RootStateL, L.prop("assetTags"));
