import { UIState } from "controllers/UIStateCollector/models/UIStateModel";
import { UUID } from "lib/at-data/UUID";
import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import * as A from "fp-ts/Array";
import { LuciferPageL } from "controllers/UIStateCollector/lens/luciferPageL2";

export const luciferStateFromPathParams =
  (uiState: UIState) => (stateFilter: { selectedAsset: UUID }) =>
    pipe(
      uiState,
      pipe(LuciferPageL, L.prop("selectedAssetIds")).set(
        A.of(stateFilter.selectedAsset)
      )
    );
