import { Button, Chip, Paper, styled } from "@mui/material";
import * as b from "fp-ts/boolean";
import * as Tpl from "fp-ts/Tuple";
import React, { useState } from "react";
import palette from "theme/palette";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { not } from "fp-ts/Predicate";
import { isManagedAssetTag } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import { clog } from "lib/util";

interface ChipData {
  id: number;
  name: string;
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const AssetTagsList: React.FC<{ tags: Array<string> }> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = (bb: boolean) => {
    setExpanded(bb);
  };

  const collapsedLength = 4;

  return pipe(
    props.tags,
    A.splitAt(collapsedLength),
    ([alwaysVisibleTags, expandableTags]) => (
      <AssetTagList>
        {pipe(
          alwaysVisibleTags,
          A.mapWithIndex((i, data) => (
            <ListItem key={i}>
              <AssetTag
                color="primary"
                size="small"
                label={data}
                // onDelete={() => {}}
                sx={{
                  backgroundColor: "#46495E",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  padding: "0 12px 0 12px",
                }}
              />
            </ListItem>
          ))
        )}
        {pipe(
          expandableTags,
          A.isNonEmpty,
          b.foldW(
            () => null,
            () => (
              <>
                <AssetTag
                  color="primary"
                  size="small"
                  label={
                    expanded
                      ? `-${expandableTags.length}`
                      : `+${expandableTags.length}`
                  }
                  // onDelete={() => {}}
                  sx={{
                    backgroundColor: expanded
                      ? `${palette.primary[600]}`
                      : "#46495E",
                    fontWeight: 400,
                    textTransform: "capitalize",
                    padding: "0 12px 0 12px",
                    marginTop: "5px",
                  }}
                  onClick={() => handleExpand(!expanded)}
                />
                {pipe(
                  expanded,
                  b.fold(
                    () => null,
                    () =>
                      pipe(
                        expandableTags,
                        A.mapWithIndex((i, data) => {
                          return (
                            <ListItem key={i}>
                              <AssetTag
                                color="primary"
                                size="small"
                                label={data}
                                // onDelete={() => {}}
                                sx={{
                                  backgroundColor: "#46495E",
                                  fontWeight: 400,
                                  textTransform: "capitalize",
                                  padding: "0 12px 0 12px",
                                }}
                              />
                            </ListItem>
                          );
                        })
                      )
                  )
                )}
              </>
            )
          )
        )}
      </AssetTagList>
    )
  );
};

const AssetTagList = styled("ul")`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
`;
const AssetTag = styled(Chip)``;
