import { AnySourceData, AnySourceImpl } from "mapbox-gl";
import * as IO from "fp-ts/IO";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as R from "fp-ts/Reader";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";

export const addSource = (id: string, source: AnySourceData) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.addSource(id, source);
      return ctx.map.getSource(id);
    })
  );

export const getSource = (id: string) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => {
      return pipe(ctx.map.getSource(id), O.fromNullable);
    })
  );

export const getOrAddSource = (
  id: string,
  source: AnySourceData
): R.Reader<MapboxContext, IO.IO<AnySourceImpl>> =>
  pipe(
    id,
    getSource,
    R.chain(O.fold(() => addSource(id, source), flow(IO.of, R.of)))
  );
