import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import React from "react";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { AssetLegacyIdCell } from "../../cells/AssetLegacyIdCell";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as N from "fp-ts/number";
import { AssetParentBuildingCell } from "../../cells/AssetParentBuildingCell";
import { AssetNameCell } from "../../cells/AssetNameCell";
import { AssetContext } from "../../../../../../../../contexts/AssetContext";
import { AssetRowContext } from "../../contexts/AssetRowContext";
import * as A from "fp-ts/Array";
import { AssetsContext } from "../../../../../../../../contexts/AssetsContext";
import { getParentAssetOf } from "../../../../../../../../lib/at-data/assets/filters";
import { isBuilding } from "../../../../../../../../lib/at-data/assets/predicates";

export const AssetParentBuildingColumn = defineColumn(
  "assetParentBuilding",
  pipe(
    R.ask<AssetRowDataContext>(),
    R.map(({ asset, assets }) => getParentAssetOf(isBuilding)(asset)(assets)),
    R.map((_) => ROR.singleton("assetParentBuilding", _))
  ),
  R.of(AssetParentBuildingCell),
  //@ts-ignore
  flow(readColumnData, A.size, String, O.some, (value) => (
    <TableHeaderKpi
      label="Building"
      hint="Building in which the space is located in"
      value={O.of("")}
    />
  )),
  R.of({
    defaultFlex: 1,
    sort: O.getOrd(N.Ord).compare,
  })
);
