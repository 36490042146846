import React from "react";
import AppBar from "@mui/material/AppBar";
import palette from "theme/palette";
import theme from "theme/theme";
import styled from "@emotion/styled/macro";
import { vars } from "theme/helpers";
import Logo from "../ui/Logo";
import NavTabs, { NewLink } from "./NavTabs";
import NavShare from "./NavShare";
import NavToggle from "./NavToggle";
import NavAccount from "./NavAccount";
import { NavViewModifications } from "components/navBar/NavViewModifications";
import Icon, { AtAssets } from "../ui/Icon";
import { alpha, Tab } from "@mui/material";
import { WIFI_OCCUPANCY } from "../../constants/featureFlags";
import { FeatureFlag } from "../featureFlag/FeatureFlag";

const NavBar: React.FC = () => {
  return (
    <StyledAppBar position="fixed">
      <NavControlsLeft>
        <LogoBox>
          <Logo wordmark />
        </LogoBox>
        <NavTabs />
      </NavControlsLeft>
      <NavControlsRight>
        <NavViewModifications />
        <FeatureFlag flag={WIFI_OCCUPANCY}>
          <NavToggle />
        </FeatureFlag>
        <NavShare />
        <NavAccount />
      </NavControlsRight>
    </StyledAppBar>
  );
};

export default NavBar;

const StyledAppBar = styled(AppBar)`
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${vars.navBar};
  background-color: ${palette.neutral[900]};

  .MuiTabs-indicator {
    height: 100%;
    background-color: ${palette.neutral[700]};
  }
`;

const NavControlsLeft = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavControlsRight = styled("div")`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(2)};
  align-items: center;
  padding: 0 ${theme.spacing(2)} 0 0;
`;

const LogoBox = styled("div")`
  display: flex;
  padding: 0 ${theme.spacing(4)} 0 24px;
`;
