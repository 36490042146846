import { Box, Stack, styled, Typography } from "@mui/material";
import { ReportUploadController } from "controllers/ReportUploadController/ReportUploadController";
import React from "react";
import palette from "theme/palette";
import { ReportsUploadDialog } from "./reportsTable/ReportsUploadDialog";

const ReportsHeader: React.FC = () => (
  <Box p={3}>
    <Stack
      direction={{ xs: "column", lg: "row" }}
      spacing={2}
      justifyContent="space-between"
      alignItems={{ xs: "initial", lg: "flex-start" }}
    >
      <PageHeader direction="row" spacing={2}>
        <Typography variant="h3" color={palette.neutral[100]}>
          Reports
        </Typography>
        <ReportUploadController.Component context={{}}>
          <ReportsUploadDialog />
        </ReportUploadController.Component>
      </PageHeader>
    </Stack>
  </Box>
);

export default ReportsHeader;

const PageHeader = styled(Stack)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 24px 12px 0;
`;
