import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import * as AA from "fp-ts-std/Array";
import { AssetAreaCell } from "views/authenticated/assets/page/components/AssetsTable/cells/AssetAreaCell";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import React from "react";
import { Assets } from "lib/at-data/assets/assets";
import { filterChildren, getAllChildren } from "lib/at-data/assets/filters";
import * as ROR from "fp-ts/ReadonlyRecord";
import { formatNumber, UINumber } from "lib/formatters/formatNumber";
import { fromSqM, isoSqFt, isoSqM } from "lib/at-data/units/area";
import { getArea } from "lib/at-data/assets/getters";

export const getChildrenAssetsArea = (asset: Asset) =>
  pipe(asset, getAllChildren);

export const AssetAreaColumn = defineColumn(
  "assetArea",
  pipe(
    R.asks<AssetRowDataContext, Asset>((_) => _.asset),
    R.map(flow(getArea, (_) => ROR.singleton("assetArea", _)))
  ),
  R.of(AssetAreaCell),
  flow(
    readColumnData,
    A.filterMap(ROR.lookup("asset")),
    (a) => pipe(a as Assets, filterChildren, A.filterMap(getArea)),
    A.map(flow(fromSqM, isoSqFt.unwrap)),
    AA.sum,
    formatNumber(UINumber),
    (v) => (
      <TableHeaderKpi
        label="Size"
        hint="Estimated square footage of the defined space."
        value={O.some(v)}
      />
    )

    // R.chainW((a: Asset[]) => pipe(a, A.map(getArea))),
    // R.chainW(
    //   flow(
    //     onlyNonContained,
    //     R.map(
    //       flow(AA.sum, formatNumber(UINumber), (v) => (
    //         <TableHeaderKpi
    //           label="Size"
    //           hint="Measure of the area assigned to, or available for assignment to, an occupant or specific use."
    //           value={O.some(v)}
    //         />
    //       ))
    //     )
    //   )
    // )
  ),
  R.of({
    defaultFlex: 0.5,
    sort: O.getOrd(N.Ord).compare,
  })
);
