import * as DataSeries from "./DataSeries";
import { RESOLUTION, roundDown } from "./Resolution";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as NumberSeries from "./NumberSeries";
import { ChartDataSlice } from "lib/at-data/ChartDataSlice";
import { addSeconds, differenceInSeconds } from "date-fns/fp";
import { OccupancyEstimate, getOccupancyEstimate } from "./OccupancyEstimate";
import { clog } from "lib/util";

export type ChartSeries = Array<ChartDataSlice>;

export const makeEmptyChartData = (
  series: NumberSeries.NumberSeries
): ChartSeries => {
  const seriesLength =
    (series.end.valueOf() - series.start.valueOf()) /
    (series.resolution * 1000);

  return A.makeBy(seriesLength, (i) => ({
    timestamp: series.start.valueOf() + i * series.resolution * 1000,
  }));
};

export const fromNumberSeries: (
  zeroVal: () => number,
  key: string
) => (numberSeries: NumberSeries.NumberSeries) => ChartSeries =
  (zeroVal, key) => (numberSeries) =>
    pipe(
      numberSeries,
      makeEmptyChartData,
      A.map((slice) => ({
        ...slice,
        [key]: pipe(
          numberSeries,
          DataSeries.getSlice(new Date(slice.timestamp)),
          O.getOrElse(zeroVal)
        ),
      }))
    );

export const lastSliceWithValueFromNumberSeries = (
  numberSeries: ChartSeries
): O.Option<ChartDataSlice> => {
  return pipe(
    numberSeries,
    A.findLast((e) => e.value && e.value > 0)
  );
};

export const numberSeriesWithLastValue = (
  numberSeries: ChartSeries
): ChartSeries => {
  return pipe(
    numberSeries,
    A.findLastIndex((e) => e.value && e.value > 0),
    O.map((eIndex) => {
      return pipe(numberSeries, A.takeLeft(eIndex + 1));
    }),
    O.getOrElse(() => numberSeries)
  );
};

export const lastSliceWithValueOccupancyEstimate = (
  seriesO: O.Option<DataSeries.DataSeries<OccupancyEstimate>>
): O.Option<ChartDataSlice> => {
  return pipe(
    seriesO,
    O.map(DataSeries.map(getOccupancyEstimate)),
    O.map(fromNumberSeries(() => 0, "value")),
    O.map(lastSliceWithValueFromNumberSeries),
    O.getOrElse(() => O.none as O.Option<ChartDataSlice>)
  );
};

export const lastSliceWithValueOccupancyEstimate2 = (
  series: DataSeries.DataSeries<OccupancyEstimate>
): O.Option<ChartDataSlice> => {
  return pipe(
    series,
    DataSeries.map(getOccupancyEstimate),
    fromNumberSeries(() => 0, "value"),
    lastSliceWithValueFromNumberSeries
  );
};
