import React from "react";

export const FidelityLogo = () => (
  <div>
    <svg
      width="160"
      height="51"
      viewBox="0 0 160 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9223 37.5168H2.06641L10.0147 9.2207H30.283L28.2959 15.8973H17.9631L16.6913 20.7458H27.0242L25.2755 26.9455H14.9427L11.9223 37.5168Z"
        fill="white"
      />
      <path
        d="M34.8136 37.5168H25.0371L30.8394 16.8511H40.6158L34.8136 37.5168ZM41.1722 14.864H31.3958L32.9854 9.2207H42.7619L41.1722 14.864Z"
        fill="white"
      />
      <path
        d="M60.248 37.5162H50.6306L51.3459 35.2112C49.4383 37.0393 46.8948 38.0726 44.2719 37.9931C41.0926 37.9931 39.1055 36.4829 39.1055 33.2241C39.3439 28.8525 40.5362 24.5604 42.6027 20.6657C44.1129 17.9633 46.9743 16.2941 50.0742 16.4531C52.8561 16.4531 54.9227 17.6453 55.7175 19.1555L58.4199 9.14062H68.1964L60.248 37.5162ZM52.8561 22.9707C51.8228 22.8912 50.9485 23.5271 50.6306 24.4809C49.8357 26.0706 49.3588 27.8192 49.2793 29.6473C49.2793 30.4422 49.9152 31.078 50.71 31.1575H50.7895C51.3459 31.1575 51.9818 30.9985 52.4587 30.6806L54.4458 23.9245C54.0483 23.2887 53.492 22.9707 52.8561 22.9707Z"
        fill="white"
      />
      <path
        d="M87.9087 28.4559H74.3966C74.0786 29.3302 73.9197 30.284 73.8402 31.1583C73.7607 31.8737 74.2376 32.5095 74.953 32.6685C75.0324 32.6685 75.1914 32.6685 75.2709 32.6685C76.6221 32.6685 77.258 32.1121 77.8144 30.125H87.4318C86.2396 36.0068 79.2451 37.9939 74.953 37.9939C69.1507 37.9939 64.6201 37.199 64.6201 31.5557C64.8586 27.661 66.0508 23.9253 68.1969 20.746C70.6609 17.4872 74.953 16.2949 78.9271 16.2949C84.9679 16.2949 88.942 17.5667 88.942 22.7331C88.7831 24.6407 88.4651 26.6278 87.9087 28.4559ZM78.1323 21.5408C76.94 21.5408 76.0657 22.3357 75.5888 24.4817H79.6425C79.8014 24.0048 79.8809 23.5279 79.8809 23.051C79.8014 21.8588 79.1656 21.5408 78.1323 21.5408Z"
        fill="white"
      />
      <path
        d="M97.7641 37.5168H87.9082L95.8565 9.2207H105.633L97.7641 37.5168Z"
        fill="white"
      />
      <path
        d="M110.641 37.5168H100.864L106.667 16.8511H116.443L110.641 37.5168ZM116.999 14.864H107.143L108.733 9.2207H118.51L116.999 14.864Z"
        fill="white"
      />
      <path
        d="M134.566 16.7708L135.44 21.4603H127.968L125.743 29.3292C125.584 29.965 125.425 30.5214 125.425 31.1573C125.425 32.1111 125.822 32.3495 127.094 32.3495H129.161L127.73 37.4365H118.987C116.125 37.4365 115.013 36.0058 115.013 33.9392C115.013 32.9854 115.172 31.9521 115.41 31.0778L120.735 12.2402H130.512L129.24 16.8503H134.566V16.7708Z"
        fill="white"
      />
      <path
        d="M150.939 35.3701C150.939 34.9727 150.542 34.9727 150.224 34.9727H149.826V35.7675H150.303C150.542 35.7675 150.939 35.7675 150.939 35.3701Z"
        fill="white"
      />
      <path
        d="M150.224 33.7812C149.031 33.7812 147.998 34.735 147.998 36.0068C147.998 37.199 148.952 38.2323 150.224 38.2323C151.416 38.2323 152.449 37.2785 152.449 36.0068C152.449 34.8145 151.416 33.7812 150.224 33.7812ZM150.939 37.517L150.224 36.2452H149.826V37.517H149.27V34.4966H150.462C151.177 34.4966 151.575 34.8145 151.575 35.4504C151.575 35.8478 151.257 36.2452 150.859 36.2452L151.654 37.517H150.939Z"
        fill="white"
      />
      <path
        d="M150.224 33.3027C148.713 33.3027 147.601 34.495 147.601 36.0052C147.601 37.5154 148.793 38.6281 150.303 38.6281C151.813 38.6281 152.926 37.4359 152.926 35.9257C152.846 34.495 151.654 33.3027 150.224 33.3027ZM150.224 38.1512C149.031 38.1512 147.998 37.1974 147.998 36.0052C147.998 34.8129 148.952 33.7796 150.144 33.7796C151.336 33.7796 152.37 34.7334 152.37 35.9257C152.449 37.1974 151.416 38.1512 150.224 38.1512Z"
        fill="white"
      />
      <path
        d="M151.336 35.4491C151.336 34.8927 151.018 34.6543 150.382 34.6543H149.349V37.3567H149.826V36.244H150.223L150.938 37.3567H151.495L150.779 36.1645C151.018 36.1645 151.336 35.8465 151.336 35.4491ZM149.826 35.7671V34.9722H150.223C150.541 34.9722 150.859 34.9722 150.859 35.3696C150.859 35.7671 150.541 35.7671 150.223 35.7671H149.826Z"
        fill="white"
      />
      <path
        d="M147.839 16.7715L142.513 28.2171L142.672 16.7715H132.737L134.645 37.4372C134.406 38.6294 134.009 39.3448 133.214 39.6627C132.101 39.9806 130.988 40.0601 129.876 39.9011H129.717L128.127 45.5445H134.804C138.778 45.5445 140.685 43.5574 143.467 39.1858L157.695 16.7715H147.839Z"
        fill="white"
      />
    </svg>
  </div>
);
