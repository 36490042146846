import {
  Box,
  Collapse,
  IconButton,
  Menu,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Icon from "components/ui/Icon";
import LabeledCheckbox from "components/ui/LabeledCheckbox";
import LabeledSwitch from "components/ui/LabeledSwitch";
import { activityPageInitialState } from "controllers/UIStateCollector/initialState/activityPage";
import { ActivityPageUIStateL } from "controllers/UIStateCollector/lens/activityPage";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as b from "fp-ts/boolean";
import { flow, pipe } from "fp-ts/function";
import { not } from "fp-ts/Predicate";
import * as S from "fp-ts/Set";
import { useCollectorOld } from "lib/at-react/defineController";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import * as L from "monocle-ts/Lens";
import React from "react";
import { RiCloseFill } from "react-icons/ri";
import {
  ACTIVITY_MAP_LAYER,
  mapLayersEq,
} from "views/authenticated/activity/controller/state";

const MenuListSwitch: React.FC<{
  label: string;
  onChange: () => void;
  disabled?: boolean;
  checked?: boolean;
}> = ({ label, onChange, disabled, checked }) => (
  <LabeledSwitch
    label={label}
    onChange={onChange}
    labelPlacement="start"
    mode="dark"
    disabled={disabled}
    checked={checked}
    sx={{
      py: 1,
      width: "100%",
      justifyContent: "space-between",
      ".MuiSwitch-root": {
        marginRight: -1.5,
      },
      ".MuiFormControlLabel-label": {
        fontSize: "1.4rem",
      },
    }}
  />
);

const MenuListCheckbox: React.FC<{
  label: string;
  onChange: () => void;
  disabled?: boolean;
  checked?: boolean;
}> = ({ label, onChange, disabled, checked }) => (
  <LabeledCheckbox
    label={label}
    mode="dark"
    disabled={disabled}
    checked={checked}
    onChange={onChange}
    sx={{
      width: "100%",
    }}
  />
);

const dataVisualizationLayers = new Set([
  ACTIVITY_MAP_LAYER.OCCUPANCY_HEATMAP,
  ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION,
]);
const isDataVisualizationEnabled = (layers: Set<ACTIVITY_MAP_LAYER>) =>
  pipe(
    S.intersection(mapLayersEq)(layers, dataVisualizationLayers),
    not(S.isEmpty)
  );
const exceptDataVisualizationLayers = (
  layers: Set<ACTIVITY_MAP_LAYER>
): Set<ACTIVITY_MAP_LAYER> =>
  S.difference(mapLayersEq)(layers, dataVisualizationLayers);

const toggleLayer =
  (layer: ACTIVITY_MAP_LAYER) =>
  (layers: Set<ACTIVITY_MAP_LAYER>): Set<ACTIVITY_MAP_LAYER> =>
    pipe(
      layers,
      S.elem(mapLayersEq)(layer),
      b.fold(
        () => pipe(layers, S.insert(mapLayersEq)(layer)),
        () => pipe(layers, S.remove(mapLayersEq)(layer))
      )
    );

const exceptLayers =
  (except: Set<ACTIVITY_MAP_LAYER>) =>
  (layers: Set<ACTIVITY_MAP_LAYER>): Set<ACTIVITY_MAP_LAYER> =>
    pipe(layers, S.difference(mapLayersEq)(except));

const exceptLabels = (
  layers: Set<ACTIVITY_MAP_LAYER>
): Set<ACTIVITY_MAP_LAYER> =>
  S.difference(mapLayersEq)(layers, new Set([ACTIVITY_MAP_LAYER.LABELS]));

const ZONE_OUTLINES_LAYERS = new Set([
  ACTIVITY_MAP_LAYER.ZONE_OUTLINES,
  ACTIVITY_MAP_LAYER.ZONE_FILL,
  ACTIVITY_MAP_LAYER.ZONE_LABELS,
]);
const ActivityControlsLayersMenu: React.FC<{
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  heatmap: boolean;
  setHeatmap: React.Dispatch<React.SetStateAction<boolean>>;
  alerts: boolean;
  setAlerts: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ anchorEl, handleClose, heatmap, setHeatmap, alerts, setAlerts }) => {
  const dispatch = useHistoryControllerDispatch(UIStateController);
  const layers = useCollectorOld(
    UIStateController,
    flow(L.composeLens(ActivityPageUIStateL), L.prop("layers"))
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      mode="dark"
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      sx={{
        ".MuiList-root": {
          overflowX: "hidden",
          overflowY: "auto",
          width: 250,
          borderRadius: 0,
          maxHeight: "85vh",
          paddingBottom: 0,
        },
      }}
      variant="selectedMenu"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
      >
        <Typography variant="subtitle1" color="textTertiary">
          Layers
        </Typography>
        <IconButton color="primary" onClick={handleClose}>
          <Icon icon={RiCloseFill} />
        </IconButton>
      </Stack>
      <MenuListSwitch
        label="Visualization"
        checked={pipe(layers, isDataVisualizationEnabled)}
        onChange={() =>
          pipe(
            layers,
            isDataVisualizationEnabled,
            b.fold(
              () =>
                pipe(
                  layers,
                  exceptLayers(ZONE_OUTLINES_LAYERS),
                  exceptDataVisualizationLayers,
                  S.insert(mapLayersEq)(
                    ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION
                  )
                ),
              () => pipe(layers, exceptDataVisualizationLayers)
            ),
            pipe(ActivityPageUIStateL, L.prop("layers")).set,
            dispatch
          )
        }
      />
      <Collapse in={pipe(layers, isDataVisualizationEnabled)}>
        <Box pb={2}>
          <RadioGroup aria-label="heatmap options" name="heatmap-options-group">
            {/*<LabeledCheckbox*/}
            {/*  label="Occupancy Heatmap"*/}
            {/*  checked={pipe(*/}
            {/*    layers,*/}
            {/*    A.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.OCCUPANCY_HEATMAP)*/}
            {/*  )}*/}
            {/*  onChange={pipe(*/}
            {/*    layers,*/}
            {/*    toggleLayer(ACTIVITY_MAP_LAYER.OCCUPANCY_HEATMAP),*/}
            {/*    setLayersIO(dispatch)*/}
            {/*  )}*/}
            {/*  mode="dark"*/}
            {/*/>*/}
            <LabeledCheckbox
              label="Room by Room Utilization"
              checked={pipe(
                layers,
                S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION)
              )}
              onChange={() =>
                pipe(
                  layers,
                  toggleLayer(ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION),

                  pipe(ActivityPageUIStateL, L.prop("layers")).set,
                  dispatch
                )
              }
              value={ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION}
              mode="dark"
            />
          </RadioGroup>
        </Box>
      </Collapse>
      {/*<Divider />*/}
      {/*<MenuListSwitch label="Certainty" onChange={() => {}} />*/}
      {/*<Divider />*/}
      {/*<MenuListSwitch label="Coverage" onChange={() => {}} />*/}
      {/*<Divider />*/}
      {/*<MenuListSwitch*/}
      {/*  label="Alerts"*/}
      {/*  checked={alerts}*/}
      {/*  onChange={() => setAlerts(!alerts)}*/}
      {/*/>*/}
      {/*<Collapse in={alerts}>*/}
      {/*  <Box pb={2}>*/}
      {/*    <MenuListCheckbox label="In Progress" onChange={() => {}} checked />*/}
      {/*    <MenuListCheckbox label="Resolved" onChange={() => {}} />*/}
      {/*    <MenuListCheckbox label="Occupancy" onChange={() => {}} checked />*/}
      {/*    <MenuListCheckbox label="Density" onChange={() => {}} checked />*/}
      {/*    <MenuListCheckbox label="Coverage" onChange={() => {}} checked />*/}
      {/*    <MenuListCheckbox label="Insights" onChange={() => {}} />*/}
      {/*  </Box>*/}
      {/*</Collapse>*/}
      {/*<Divider />*/}
      <MenuListSwitch
        label="Labels - Progressive"
        checked={pipe(layers, S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.LABELS))}
        onChange={() =>
          pipe(
            layers,
            toggleLayer(ACTIVITY_MAP_LAYER.LABELS),
            exceptLayers(ZONE_OUTLINES_LAYERS),
            exceptLayers(new Set([ACTIVITY_MAP_LAYER.LABELS_DETAILED])),
            pipe(ActivityPageUIStateL, L.prop("layers")).set,
            dispatch
          )
        }
      />
      <MenuListSwitch
        label="Labels - All"
        checked={pipe(
          layers,
          S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.LABELS_DETAILED)
        )}
        onChange={() =>
          pipe(
            layers,
            toggleLayer(ACTIVITY_MAP_LAYER.LABELS_DETAILED),
            exceptLayers(ZONE_OUTLINES_LAYERS),
            exceptLayers(new Set([ACTIVITY_MAP_LAYER.LABELS])),
            pipe(ActivityPageUIStateL, L.prop("layers")).set,
            dispatch
          )
        }
      />

      <MenuListSwitch
        label="Zone Outlines"
        checked={pipe(
          layers,
          S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.ZONE_OUTLINES)
        )}
        onChange={() =>
          pipe(
            layers,
            S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.ZONE_OUTLINES),
            b.fold(
              () => ZONE_OUTLINES_LAYERS,
              () => activityPageInitialState.layers
            ),
            pipe(ActivityPageUIStateL, L.prop("layers")).set,
            dispatch
          )
        }
      />
      <Collapse
        in={pipe(layers, S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.ZONE_OUTLINES))}
      >
        <Box pb={2}>
          <Tooltip
            title={
              "Colors are randomly generated and do not represent utilization"
            }
          >
            <RadioGroup
              aria-label="Zone Outlines Options"
              name="zone-outline-options-group"
            >
              <LabeledCheckbox
                label="Fill Zones"
                checked={pipe(
                  layers,
                  S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.ZONE_FILL)
                )}
                onChange={() =>
                  pipe(
                    layers,
                    toggleLayer(ACTIVITY_MAP_LAYER.ZONE_FILL),
                    pipe(ActivityPageUIStateL, L.prop("layers")).set,
                    dispatch
                  )
                }
                value={ACTIVITY_MAP_LAYER.ZONE_FILL}
                mode="dark"
              />
            </RadioGroup>
          </Tooltip>
        </Box>
      </Collapse>
    </Menu>
  );
};

export default ActivityControlsLayersMenu;
