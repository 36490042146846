import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import React from "react";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { AssetLegacyIdCell } from "../../cells/AssetLegacyIdCell";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as N from "fp-ts/number";

export const AssetLegacyIdColumn = defineColumn(
  "assetLegacyId",
  pipe(
    R.ask<AssetRowDataContext>(),
    R.map((_) => ROR.singleton("assetLegacyId", _.asset.legacyZoneId))
  ),
  //@ts-ignore
  R.of(AssetLegacyIdCell),
  R.of(
    <TableHeaderKpi
      label="Legacy Zone ID"
      hint="Legacy Zone ID of the asset"
      value={O.of("")}
      icon={true}
    />
  ),
  R.of({
    defaultFlex: 1,
    sort: O.getOrd(N.Ord).compare,
  })
);
