import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import * as AD2 from "lib/at-data/AsyncData2";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import {
  composeController,
  defineCalculatedContext,
} from "lib/at-react/defineController";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as S from "fp-ts/Set";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import {
  RootAssetsContext,
  RootState,
} from "views/authenticated/root/controller/state";
import {
  Assets,
  eqAssets,
  eqAssetsByUUIDOnly,
} from "lib/at-data/assets/assets";
import {
  getAssetsByIds,
  getAssetsByType,
  getCampuses,
} from "lib/at-data/assets/filters";
import * as L from "monocle-ts/Lens";
import * as A from "fp-ts/Array";
import { AssetsContext } from "contexts/AssetsContext";

export type SelectedAssetIdsContext = {
  selectedAssetIds: Set<UUID>;
};

export type SelectedAssetsContext = {
  selectedAssets: Assets;
};

export const [component, controller] = pipe(
  AssetsController,
  composeController(
    defineCalculatedContext(
      { selectedAssets: [] },
      Eq.struct<SelectedAssetIdsContext & RootAssetsContext>({
        selectedAssetIds: S.getEq(eqUUID),
        assets: AD2.getEq(eqAssets),
      }),
      ({ selectedAssetIds, assets }): SelectedAssetsContext => ({
        selectedAssets: pipe(
          assets,
          fromAD2,
          getAssetsByIds(selectedAssetIds),
          NEA.fromArray,
          O.alt(() => pipe(assets, fromAD2, getCampuses, NEA.fromArray)),
          O.getOrElseW(() => [])
        ),
      })
    )
  )
);

export const SelectedAssetL = pipe(
  L.id<SelectedAssetsContext>(),
  L.prop("selectedAssets"),
  L.imap(A.head, A.fromOption)
);

export const SelectedAssetsL = pipe(
  L.id<SelectedAssetsContext>(),
  L.prop("selectedAssets")
);

export const SelectedAssetsController = controller;
export const SelectedAssetsControllerComponent = component;
