import React from "react";

export const UTKLogo = () => (
  <div>
    <svg
      width="135"
      height="126"
      viewBox="0 0 135 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M135 0H0V126H135V0Z" fill="#FF8200" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.875 16.8008H118.125V49.7288H105.3C102.625 33.9766 83.724 33.3471 77.9625 44.9787C77.9688 51.5934 77.9618 52.6094 77.9625 61.8248V81.8168C77.9625 90.5373 84.7125 96.0968 93.4875 96.0968V109.201L41.5125 109.201V96.0968C50.2875 96.0968 57.0375 90.5689 57.0375 81.8168C57.0375 74.4248 57.0375 70.2334 57.0375 61.8248V44.9576C50.0125 32.1748 31.8268 35.2244 29.7 49.7288H16.875V16.8008Z"
        fill="white"
      />
    </svg>
  </div>
);
