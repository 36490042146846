import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { PathL } from "controllers/UIStateCollector/models/UIStateModel";
import { LuciferPageUIState } from "controllers/UIStateCollector/models/LuciferPageUIStateModel";
import * as O from "fp-ts/Option";
import { Asset } from "lib/at-data/assets/Asset";
import * as A from "fp-ts/Array";
import { UIStateL } from "controllers/UIStateCollector/lens/UIStateL";
import { LuciferPageL } from "controllers/UIStateCollector/lens/luciferPageL2";
import { getId } from "lib/at-data/assets/getters";

export const BBoxL = pipe(
  L.id<LuciferPageUIState>(),
  L.prop("geographicalWorkspace"),
  L.prop("bbox")
);

export const SelectedAssetL = pipe(
  L.id<LuciferPageUIState>(),
  L.prop("selectedAssetIds")
);

export const SelectedWorkspaceL = pipe(
  L.id<LuciferPageUIState>(),
  L.prop("selectedWorkspace")
);

export const InitialGeoReferenceL = pipe(
  L.id<LuciferPageUIState>(),
  L.prop("localWorkSpace"),
  L.prop("initialGeoReference")
);
export const LocalWorkSpaceModeL = pipe(
  L.id<LuciferPageUIState>(),
  L.prop("localWorkSpace"),
  L.prop("mode")
);

export const LuciferPagePageUIStateL = pipe(
  UIStateL,
  L.composeLens(LuciferPageL)
);

export const navigateToEditBuilding = ({
  selectedAsset,
}: {
  selectedAsset: O.Option<Asset>; //needs to be list
}) =>
  flow(
    PathL.set("/lucifer"),
    pipe(LuciferPagePageUIStateL, L.composeLens(SelectedAssetL)).set(
      pipe(selectedAsset, O.map(getId), A.fromOption)
    )
  );
