import * as P from "fp-ts-routing";
import { RouteUnion } from "router/routeUnion";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { activityPageMatch } from "router/ActivityRoute";
import { assetsPageMatch } from "router/AssetsRoute";
import { settingsPageMatch } from "router/SettingsRoute";
import { reportsPageMatch } from "router/ReportsRoute";
import { reports2PageMatch } from "router/Reports2Route";
import { comparePageMatch } from "router/CompareRoute";
import { debugPageMatch } from "router/TestRoute";
import { luciferPageMatch } from "router/LuciferRoute";
import { deployPageMatch } from "router/DeployRoute";
import { designPageMatch } from "router/DesignRoute";

export const homeMatch: P.Match<{}> = P.end;

export const AssetsRoute = (): RouteUnion => ({ _type: "assets" });
export const ActivityRoute = (): RouteUnion => ({ _type: "activity" });
export const SettingsRoute = (): RouteUnion => ({ _type: "settings" });
export const ReportsRoute = (): RouteUnion => ({ _type: "reports" });
export const Reports2Route = (): RouteUnion => ({ _type: "reports2" });
export const CompareRoute = (): RouteUnion => ({ _type: "compare" });
export const DebugRoute = (): RouteUnion => ({ _type: "debug" });
export const LuciferRoute = (): RouteUnion => ({ _type: "lucifer" });
export const DeployRoute = (): RouteUnion => ({ _type: "deploy" });
export const DesignRoute = (): RouteUnion => ({ _type: "design" });

export const router: P.Parser<RouteUnion> = pipe(
  P.zero<RouteUnion>(),
  P.alt(() => homeMatch.parser.map(ActivityRoute)),
  P.alt(() => assetsPageMatch.parser.map(AssetsRoute)),
  P.alt(() => activityPageMatch.parser.map(ActivityRoute)),
  P.alt(() => reportsPageMatch.parser.map(ReportsRoute)),
  P.alt(() => reports2PageMatch.parser.map(Reports2Route)),
  P.alt(() => settingsPageMatch.parser.map(SettingsRoute)),
  P.alt(() => comparePageMatch.parser.map(CompareRoute)),
  P.alt(() => settingsPageMatch.parser.map(SettingsRoute)),
  P.alt(() => debugPageMatch.parser.map(DebugRoute)),
  P.alt(() => luciferPageMatch.parser.map(LuciferRoute)),
  P.alt(() => deployPageMatch.parser.map(DeployRoute)),
  P.alt(() => designPageMatch.parser.map(DesignRoute))
);

export const parseRoute = (path: string) => {
  const route = P.Route.parse(path);
  return P.parse(router.map(O.some), route, O.none);
};
