import React from "react";
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  styled,
} from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import CompareHeatmapBS from "./components/CompareHeatmapBS";
import CompareTimebarBS from "./components/CompareTimebarBS";
import CompareKeyMetricsBS from "./components/CompareKeyMetricsBS";
import CompareBookingsBS from "./components/CompareBookingsBS";
import CompareAssetInfoBS from "./components/CompareAssetInfoBS";

const ComparePage: React.FC<{}> = () => {
  return (
    <Page>
      <CompareHeatmapBS />
      <CompareTimebarBS />
      <CompareKeyMetricsBS />
      <CompareBookingsBS />
      <CompareAssetInfoBS />
    </Page>
  );
};

export default ComparePage;

const Page = styled("main")`
  background-color: ${palette.neutral[800]};
  min-height: calc(100vh - ${vars.navBar});
  color: ${palette.neutral[100]};
`;
const ActivityMap = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;
const TimeBar = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: flex-start;
`;

const Pane = styled(Stack)`
  width: 100%;
`;
