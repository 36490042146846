import { useStableEffect } from "fp-ts-react-stable-hooks";
import * as Eq from "fp-ts/Eq";
import * as IO from "fp-ts/IO";
import * as R from "fp-ts/Reader";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

export type CollectorIntakeComponent<IN, OUT> = React.FC<{
  context: IN;
  render: (value: OUT) => ReactElement;
}>;

export type CollectorPipe<IN, OUT> = {
  initialState: OUT;
  component: CollectorIntakeComponent<IN, OUT>;
};

export type CollectorOutputState<STATE> = {
  state: STATE;
};
export type CollectorOutputDispatch<STATE> = {
  dispatch: React.Dispatch<SetStateAction<STATE>>;
};

export const ask = <STATE>(
  initialState: STATE
): CollectorPipe<STATE, CollectorOutputState<STATE>> => ({
  initialState: { state: initialState },
  component: (props) => props.render({ state: props.context }),
});

export const andAsk =
  <CONTEXT>(initialState: CONTEXT) =>
  <IN, OUT, INTAKE_OUT>(
    intake: CollectorPipe<IN, INTAKE_OUT>
  ): CollectorPipe<
    IN & CONTEXT,
    INTAKE_OUT & CollectorOutputState<CONTEXT>
  > => ({
    initialState: {
      ...intake.initialState,
      state: { ...initialState },
    },
    component: ({ context, render }) => {
      return React.createElement(intake.component, {
        context: { ...context },
        render: (value) => render({ ...value, state: { ...context } }),
      });
    },
  });

export const fromCollector =
  <
    STATE extends IN,
    STATE_IN extends {},
    IN extends {},
    OUT,
    UPSTREAM extends CollectorOutputState<STATE>
  >(
    collector: Collector<STATE_IN, UPSTREAM>
  ) =>
  (
    intake: CollectorPipe<IN, OUT>
  ): CollectorPipe<Omit<IN, keyof STATE>, OUT> => ({
    initialState: intake.initialState,
    component: ({ context, render }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const collectorState = useContext(collector.Context);

      return React.createElement(intake.component, {
        context: { ...context, ...collectorState.state } as IN,
        render: (value) => render(value as OUT),
      });
    },
  });
// combinators
export const effect =
  <STATE, IN, EFFECTIN>(
    effectIO: (
      dispatch: Dispatch<SetStateAction<STATE>>
    ) => R.Reader<EFFECTIN & AbortingControllerContext, IO.IO<void>>
  ) =>
  (
    intake: CollectorPipe<
      IN,
      CollectorOutputState<STATE> & CollectorOutputDispatch<STATE>
    >
  ): CollectorPipe<IN & EFFECTIN, CollectorOutputState<STATE>> => ({
    initialState: { state: intake.initialState.state },
    component: (p) =>
      // eslint-disable-next-line react/no-children-prop
      React.createElement(intake.component, {
        context: p.context,
        render: ({ state, dispatch }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const lastEffectId = useRef<number>(0);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const abortControllerRef = useRef(new AbortController());

          const abortingDispatch: (
            requestId: number
            // eslint-disable-next-line react-hooks/rules-of-hooks
          ) => Dispatch<SetStateAction<STATE>> = useMemo(
            () => (requestId) => {
              return (reducer) => {
                if (requestId !== lastEffectId.current) {
                  console.log(
                    "Tubular Collector: not dispatching from stale effect",
                    state
                  );
                } else dispatch(reducer);
              };
            },
            [lastEffectId, dispatch]
          );

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            abortControllerRef.current.abort();
            abortControllerRef.current = new AbortController();
            lastEffectId.current += 1;
            effectIO(abortingDispatch(lastEffectId.current))({
              ...p.context,
              ...{ abortingController: abortControllerRef.current },
            })();
          }, [p.context]);

          return p.render({ state });
        },
      }),
  });
export const propToState =
  <STATE, INTAKE>(eq: Eq.Eq<STATE>) =>
  (
    intake: CollectorPipe<
      INTAKE,
      CollectorOutputState<STATE> & CollectorOutputDispatch<STATE>
    >
  ): CollectorPipe<
    INTAKE & { state: STATE },
    CollectorOutputState<STATE> & CollectorOutputDispatch<STATE>
  > => ({
    initialState: {
      state: intake.initialState.state,
      dispatch: () => {
        console.error(
          "Called dispatch of an initialized collector. This should not happen"
        );
      },
    },
    component: (p) =>
      // eslint-disable-next-line react/no-children-prop
      React.createElement(intake.component, {
        context: p.context,
        render: ({ state, dispatch }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useStableEffect(
            () => {
              dispatch(p.context.state);
            },
            [p.context.state],
            Eq.tuple(eq)
          );

          return p.render({ state, dispatch });
        },
      }),
  });

export const map =
  <IN, OUTA, OUTB, DISPATCH>(f: (a: OUTA) => OUTB) =>
  (
    intake: CollectorPipe<IN, CollectorOutputState<OUTA>>
  ): CollectorPipe<IN, CollectorOutputState<OUTB>> => ({
    initialState: { state: f(intake.initialState.state) },
    component: ({ context, render }) =>
      // eslint-disable-next-line react/no-children-prop
      React.createElement(intake.component, {
        context,
        render: (value) => render({ state: f(value.state) }),
      }),
  });

export type Collector<INTAKE, STATE> = {
  Component: React.FC<{
    context: INTAKE;
  }>;
  Context: React.Context<STATE>;
};

export const Collector = <INTAKE, STATE>(
  component: React.FC<{
    context: INTAKE;
  }>,
  context: React.Context<STATE>
): Collector<INTAKE, STATE> => ({
  Component: component,
  Context: context,
});

export const makeMemo =
  <INTAKE, CONTEXT>(eq: Eq.Eq<INTAKE>) =>
  (intake: CollectorPipe<INTAKE, CONTEXT>): Collector<INTAKE, CONTEXT> => {
    const reactContext = React.createContext<CONTEXT>(intake.initialState);

    return {
      Component: ({ context, children }) => {
        // eslint-disable-next-line react/no-children-prop
        return React.createElement(intake.component, {
          context,
          render: (value) =>
            React.createElement(reactContext.Provider, { value }, children),
        });
      },
      Context: reactContext,
    };
  };

export const make = <INTAKE, CONTEXT>(
  intake: CollectorPipe<INTAKE, CONTEXT>
): Collector<INTAKE, CONTEXT> => {
  const reactContext = React.createContext<CONTEXT>(intake.initialState);

  return {
    Component: ({ context, children }) => {
      // eslint-disable-next-line react/no-children-prop
      return React.createElement(intake.component, {
        context,
        render: (value) =>
          React.createElement(reactContext.Provider, { value }, children),
      });
    },
    Context: reactContext,
  };
};

export const composeCollector =
  <
    FROM_INTAKE,
    TO_INTAKE extends {},
    FROM_STATE extends TO_INTAKE,
    FROM_CONTEXT extends CollectorOutputState<FROM_STATE>,
    TO_CONTEXT
  >(
    to: Collector<TO_INTAKE, TO_CONTEXT>
  ) =>
  (
    from: Collector<FROM_INTAKE, FROM_CONTEXT>
  ): Collector<Omit<TO_INTAKE, keyof FROM_STATE>, TO_CONTEXT> => ({
    Context: to.Context,
    Component: ({ context, children }) => {
      const upperContext = useContext(from.Context);
      return React.createElement(
        to.Component,
        { context: { ...upperContext.state, ...context } as TO_INTAKE },
        children
      );
    },
  });
