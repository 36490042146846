import * as O from "fp-ts/Option";
import {
  DEFAULT_FILTERS,
  lastWeek,
} from "controllers/UIStateCollector/DEFAULT_FILTERS";
import { AssetsPageUIState } from "controllers/UIStateCollector/models/AssetsPageUIStateModel";
import { subDays } from "date-fns/fp";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { pipe } from "fp-ts/function";

export const assetsPageInitialState: AssetsPageUIState = {
  selectedSideItem: O.none,
  selectedAssetIds: [],
  selectedRange: lastWeek(new Date()),
  assetsFilter: DEFAULT_FILTERS,
};
