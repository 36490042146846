import { Lens } from "monocle-ts";
import * as D from "lib/at-data/DataSeries";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import * as Eq from "fp-ts/Eq";
import * as SR from "lib/at-data/requests/temporal/SeriesRequest";
import * as AD from "lib/at-data/AsyncData";

export type AssetsHeaderChartState = {
  rootSeries: AD.AsyncData<D.DataSeries<OccupancyEstimate>>;
};

export const AssetsHeaderChartStateEq = Eq.struct<AssetsHeaderChartState>({
  rootSeries: AD.getEq(SR.SeriesRequestEq),
});

export const RootSeriesL =
  Lens.fromProp<AssetsHeaderChartState>()("rootSeries");
