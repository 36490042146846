import React from "react";
import { Form } from "../styles/Styles";
import Icon, { AtEyeOpen, AtGlobe } from "components/ui/Icon";
import { InputField } from "../ui/InputField";

export const DeployFormCapacityOrganizations: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Capacity"}
          name={"cap"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormCapacityRegions: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Capacity"}
          name={"cap"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormCapacityBuildings: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Capacity"}
          name={"cap"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormCapacityFloors: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Capacity"}
          name={"cap"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};
