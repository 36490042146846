import {
  Button,
  Input,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { toCSV } from "components/navBar/modifications/lib";
import { ModificationRaw } from "components/navBar/modifications/ModificationRaw";
import { UpdateStatus } from "components/navBar/modifications/UpdateStatus";
import { AppController } from "controllers/AppController/AppController";
import {
  AssetModificationServiceResponse,
  LegacyAssetsModificationsController,
  postAssetModificationsToService,
} from "controllers/AssetsController/AssetsController";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import { useController } from "lib/at-react/defineController";
import { noop } from "lib/util";
import * as L from "monocle-ts/Lens";
import React, { useCallback, useState } from "react";
import { toMinimalAppState } from "views/authenticated/app/ReadyAppState";

/**
 * @deprecated
 */
export const LegacyLocalModificationsTab: React.FC<{}> = (props) => {
  const [modifications] = useController(
    LegacyAssetsModificationsController,
    flow((_) => _.modifications, NEA.fromArray)
  );
  const [appState] = useController(AppController, (_) => _);

  const [responses, setResponses] = useState<Array<E.Either<Error, unknown>>>(
    []
  );

  const handleSubmitAssetModifications = useCallback(
    pipe(
      appState,
      toMinimalAppState,
      TE.fromOption(() => new Error("Application State Not Ready")),
      TE.chain(({ accessToken }) =>
        postAssetModificationsToService(
          `${process.env.REACT_APP_API_URL}/spatial/import/asset/tasks`,
          // `http://localhost:8080/import/asset/tasks`,
          accessToken,
          pipe(
            modifications,
            O.getOrElseW(() => []),
            toCSV
          )
        )
      ),
      T.chainIOK(
        (r) => () =>
          pipe(
            L.id<Array<E.Either<Error, unknown>>>(),
            L.modify((rsps) => pipe(rsps, A.prepend(r))),
            setResponses
          )
      )
    ),
    [modifications]
  );

  return (
    <Stack>
      {pipe(
        modifications,
        O.foldW(
          () => <div>No Local Modifications</div>,
          (modificationsNEA) => (
            <ModificationRaw modifications={modificationsNEA} />
          )
        )
      )}
      <Stack>
        <div style={{ marginTop: "1em" }}>
          <Button color="warning" onClick={handleSubmitAssetModifications}>
            Send
          </Button>
          {pipe(
            responses,
            A.head,
            O.map(
              E.fold(
                (e) => <div>Error sending modifications</div>,
                (r) => <div>Successfully applied modifications</div>
              )
            ),
            O.getOrElseW(() => null)
          )}
        </div>
      </Stack>
    </Stack>
  );
};
