import { Duration } from "date-fns";
import { differenceInSeconds } from "date-fns/fp";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/function";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import { GranulatedSeriesRequest } from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { clog } from "lib/util";

export const durationToSeconds = (duration: Duration) =>
  duration.seconds ||
  0 +
    (duration.minutes || 0) * 60 +
    (duration.hours || 0) * 60 * 60 +
    (duration.days || 0) * 60 * 60 * 24 +
    (duration.weeks || 0) * 60 * 60 * 24 * 7 +
    (duration.months || 0) * 60 * 60 * 24 * 30 +
    (duration.years || 0) * 60 * 60 * 24 * 365;

export const RESOLUTION_GUIDE = pipe(
  [
    {
      minutes: 5,
    },
    {
      minutes: 15,
    },
    {
      hours: 1,
    },
    {
      hours: 3,
    },
    {
      hours: 6,
    },
    {
      hours: 12,
    },
    {
      days: 1,
    },
    // {
    //   days: 3,
    // },
    {
      weeks: 1,
    },
    {
      days: 30,
    },
  ],
  A.map(durationToSeconds)
) as NEA.NonEmptyArray<number>;

export type ResolutionGuide = {
  selectedRange: Duration;
  granularity: Duration;
};

export const resolutionGuideToSeconds = ({
  selectedRange,
  granularity,
}: ResolutionGuide) => ({
  granularity: pipe(granularity, durationToSeconds),
  range: pipe(selectedRange, durationToSeconds),
});

export const RESOLUTION_GUIDE2: NEA.NonEmptyArray<ResolutionGuide> = [
  {
    selectedRange: {
      days: 3,
    },
    granularity: {
      minutes: 15,
    },
  },
  {
    selectedRange: {
      weeks: 2,
    },
    granularity: {
      hours: 1,
    },
  },
  {
    selectedRange: {
      days: 30,
    },
    granularity: {
      hours: 6,
    },
  },
  {
    selectedRange: {
      months: 2,
    },
    granularity: {
      hours: 12,
    },
  },
  {
    selectedRange: {
      days: 100,
    },
    granularity: {
      days: 1,
    },
  },
  {
    selectedRange: {
      months: 6,
    },
    granularity: {
      weeks: 1,
    },
  },
];

export const byClosestRange =
  ({ start, end }: SeriesRequest) =>
  <T extends { selectedRange: Duration }>({ selectedRange }: T) =>
    durationToSeconds(selectedRange) >= pipe(end, differenceInSeconds(start));

export const calculateResolutionByNumberOfBuckets =
  (numberOfBuckets: number) =>
  ({ start, end }: SeriesRequest): GranulatedSeriesRequest => {
    const durationInSeconds = pipe(end, differenceInSeconds(start));
    const neededResolution = durationInSeconds / numberOfBuckets;
    const actual = pipe(
      RESOLUTION_GUIDE,
      A.findFirst((d) => d / 2 >= neededResolution),
      O.getOrElse(() => pipe(RESOLUTION_GUIDE, NEA.last))
    );

    // console.log("ACTUAL", actual);
    return {
      start,
      end,
      resolution: actual,
    };
  };

export const calculateResolution = ({
  start,
  end,
}: SeriesRequest): GranulatedSeriesRequest => {
  const durationInSeconds = pipe(end, differenceInSeconds(start));

  return {
    start,
    end,
    resolution: pipe(
      RESOLUTION_GUIDE2,
      A.findFirst(byClosestRange({ start, end })),
      O.getOrElse(() => pipe(RESOLUTION_GUIDE2, NEA.last)),
      (_) => _.granularity,
      durationToSeconds
    ),
  };
};
