import * as C from "io-ts/Codec";
import { optionFromUndefined } from "lib/codecs/optionFromNullable";
import { UUID } from "lib/at-data/UUID";

export const SelectedAssetPathParamsModel = C.struct({
  selectedAsset: optionFromUndefined(UUID),
});
export type SelectedAssetPathParams = C.TypeOf<
  typeof SelectedAssetPathParamsModel
>;
