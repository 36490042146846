import React from "react";
import ActivityModule from "views/authenticated/activity/ActivityModule";
import AssetsModule from "views/authenticated/assets/AssetsModule";
import ReportsPage from "views/authenticated/reports/ReportsPage";
import Reports2Page from "views/authenticated/reports2/Reports2Page";
import ComparePage from "views/authenticated/compare/ComparePage";
import SettingsRouter from "views/authenticated/settings/SettingsRouter";
import { parseRoute } from "router";
import { RouteUnion } from "router/routeUnion";
import { match } from "ts-adt";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { DebugModule } from "views/authenticated/debug/DebugModule";
import { useController } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { PathL } from "controllers/UIStateCollector/models/UIStateModel";
import { LuciferPage } from "views/authenticated/lucifer/LuciferPage";
import { DeployPage } from "views/authenticated/deploy/DeployPage";
import { DesignPage } from "views/authenticated/design/DesignPage";

export const route = (r: RouteUnion) =>
  pipe(
    r,
    match({
      assets: () => <AssetsModule />,
      activity: () => <ActivityModule />,
      settings: () => <SettingsRouter />,
      reports: () => <ReportsPage />,
      reports2: () => <Reports2Page />,
      debug: () => <DebugModule />,
      compare: () => <ComparePage />,
      lucifer: () => <LuciferPage />,
      deploy: () => <DeployPage />,
      design: () => <DesignPage />,
    })
  );

export const RootRouter: React.FC<{}> = (props) => {
  const [routeO] = useController(
    UIStateController,
    flow(PathL.get, parseRoute)
  );
  return pipe(
    routeO,
    O.fold(() => <ActivityModule />, route)
  );

  // return (
  // <Switch>
  //   <Route path="/activity" component={ActivityRouter} />
  //   <Route path="/assets" component={AssetsRouter} />
  //   <Route path="/alerts" component={AlertsPage} />
  //   <Route path="/reports/item" component={ReportsItemPage} />
  //   <Route path="/reports" component={ReportsPage} />
  //   <Route
  //     path="/settings/add-new-asset"
  //     component={SettingsAddNewAssetRouter}
  //   />
  //   <Route path="/settings" component={SettingsRouter} />
  //   <Route exact path="/">
  //     <Redirect to="/assets" />
  //   </Route>
  // </Switch>
  // );
};
