import * as M from "fp-ts/Map";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import * as D from "fp-ts/Date";
import {
  GranulatedSeriesRequest,
  makeTimestamps,
} from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import { UUID } from "lib/at-data/UUID";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { RESOLUTION, roundDown, roundUp } from "./Resolution";
import { HasTimestampDate } from "./DataSlice";
import { addSeconds, differenceInSeconds, getUnixTime } from "date-fns/fp";
import { fromUnixTime } from "date-fns";
import { ordNumber } from "fp-ts/Ord";
import { eqNumber } from "fp-ts/Eq";
import * as S from "fp-ts/Semigroup";
import * as n from "fp-ts/number";
import { Predicate } from "fp-ts/Predicate";
import * as Eq from "fp-ts/Eq";

/**
 * Data Series represents a time series of some data at specified resolution
 * It may also contain some specific samples timestamped data that should be mergeable to the time series
 * That data is mappable
 * Note: DataSeries is useful to be interpreted by functions/components as is even without data. For example,
 * a TimeSeries chart can be drawn on screen with zero data, but already with tickmarks on a scale
 */
export type DataSeries<T> = GranulatedSeriesRequest & {
  series: Map<number, T>;
};

export const toSeries =
  <T>({ start, end, resolution }: GranulatedSeriesRequest) =>
  (fs: DataSeries<T>): DataSeries<T> => ({
    series: fs.series,
    start,
    end,
    resolution,
  });

export const union =
  <A>(sa: DataSeries<A>) =>
  (sb: DataSeries<A>) => ({
    ...sa,
    series: pipe(sb.series, M.union(eqNumber, S.last<A>())(sa.series)),
  });

export const map: <A, B>(
  f: (a: A) => B
) => (fa: DataSeries<A>) => DataSeries<B> = (f) => (fa) => ({
  ...fa,
  series: pipe(fa.series, M.map(f)),
});

export const mapWithIndex: <A, B>(
  f: (k: Date, a: A) => B
) => (fa: DataSeries<A>) => DataSeries<B> = (f) => (fa) => ({
  ...fa,
  series: pipe(
    fa.series,
    M.mapWithIndex((keym, v) => f(fromUnixTime(keym), v))
  ),
});

export const filterWithIndex: <A>(
  p: (k: Date, a: A) => boolean
) => (fa: DataSeries<A>) => DataSeries<A> = (p) => (fa) => ({
  ...fa,
  series: pipe(
    fa.series,
    M.filterWithIndex((keym, v) => p(fromUnixTime(keym), v))
  ),
});

export const reduce: <B, A>(
  b: B,
  f: (b: B, a: A) => B
) => (series: DataSeries<A>) => B = (b, f) => (series) =>
  pipe(series.series, M.reduce(ordNumber)(b, f));

export const fromRecord =
  <T>({ start, end, resolution }: GranulatedSeriesRequest) =>
  (r: Record<string, T>): DataSeries<T> => ({
    series: new Map(
      pipe(
        r,
        Object.entries,
        A.map(([ts, val]) => [pipe(new Date(ts), getUnixTime), val])
      )
    ),
    start,
    end,
    resolution,
  });

export function of<T extends HasTimestampDate>(
  series: Array<T>,
  start: number,
  end: number,
  resolution: RESOLUTION
): DataSeries<T> {
  return {
    series: new Map(
      series.map((slice) => [pipe(slice.timestamp, getUnixTime), slice])
    ),
    start: new Date(start),
    end: new Date(end),
    resolution,
  };
}

export const of2 =
  <T extends HasTimestampDate>(
    start: Date,
    end: Date,
    resolution: RESOLUTION
  ) =>
  (series: Array<T>): DataSeries<T> => {
    return {
      series: new Map(
        series.map((slice) => [pipe(slice.timestamp, getUnixTime), slice])
      ),
      start,
      end,
      resolution,
    };
  };

export const of3 =
  <T extends HasTimestampDate>({
    start,
    end,
    resolution,
  }: GranulatedSeriesRequest) =>
  (series: Array<T>): DataSeries<T> => {
    return {
      series: new Map(
        series.map((slice) => [pipe(slice.timestamp, getUnixTime), slice])
      ),
      start,
      end,
      resolution,
    };
  };

export function ofObservations<T extends { timestamp: number }>(
  series: Array<T>,
  start: number,
  end: number,
  resolution: RESOLUTION
): DataSeries<T & HasTimestampDate> {
  return {
    series: new Map(
      series.map((slice) => [
        slice.timestamp,
        {
          ...slice,
          timestamp: new Date(slice.timestamp),
        },
      ])
    ),
    start: new Date(start),
    end: new Date(end),
    resolution,
  };
}

export const toArray = <T>(series: DataSeries<T>): Array<T> =>
  pipe(
    series.series,
    M.toArray(ordNumber),
    A.map((data) => data[1])
  );

export const fillMissing =
  <T>(misingVal: () => T) =>
  (series: DataSeries<T>): DataSeries<T> =>
    pipe(
      makeTimestamps(series),
      A.map((s) => ({
        ...s,
        ...pipe(series, getSlice(s.timestamp), O.getOrElse(misingVal)),
      })),
      of2(series.start, series.end, series.resolution)
    );

export const toPairArray = <T>(series: DataSeries<T>): Array<[Date, T]> =>
  pipe(
    series.series,
    M.toArray(ordNumber),
    A.map(([date, data]) => [pipe(date, fromUnixTime), data])
  );

export const getSlice: <T>(
  timestamp: Date
) => (series: DataSeries<T>) => O.Option<T> = (timestamp) => (series) =>
  pipe(series.series.get(pipe(timestamp, getUnixTime)), O.fromNullable);

export const lastSlice = <T>(series: DataSeries<T>): O.Option<T> =>
  pipe(
    series,
    toPairArray,
    A.last,
    O.map((_) => _[1])
  );

export const findLastSliceBy =
  <T>(p: Predicate<T>) =>
  (series: DataSeries<T>): O.Option<T> =>
    pipe(
      series,
      toPairArray,
      A.findLast((_) => p(_[1])),
      O.map((_) => _[1])
    );

export const findLastTimestampBy =
  <T>(p: Predicate<T>) =>
  (series: DataSeries<T>): O.Option<Date> =>
    pipe(
      series,
      toPairArray,
      A.findLast((_) => p(_[1])),
      O.map((_) => _[0])
    );

export const getLatestTimestamp: <T>(series: DataSeries<T>) => O.Option<Date> =
  (series) =>
    pipe(
      series,
      toPairArray,
      A.last,
      O.map((_) => _[0])
    );

const byTimestampInRange: (
  start: Date,
  end: Date
) => (timestamp: Date) => boolean = (start, end) => (timestamp) =>
  D.Ord.compare(timestamp, start) > -1 && D.Ord.compare(timestamp, end) < 1;

export const subSeries: <T>(
  start: Date,
  end: Date
) => (series: DataSeries<T>) => DataSeries<T> = (start, end) => (series) => {
  return pipe(
    series,
    filterWithIndex(byTimestampInRange(new Date(start), new Date(end))),
    (s) => ({
      ...s,
      start,
      end,
    })
  );
};

export const dataLength = <T>(series: DataSeries<T>) =>
  pipe(series.series, M.size);

export const seriesLength = <T>(series: DataSeries<T>) =>
  differenceInSeconds(series.start, series.end) / series.resolution;

/**
 * Backend expects start and end dates to match the resolution
 * e.g. start for RESOLUTION.FIVE minutes must be 20.30, 20.35, etc
 * @param resolution
 * @param start
 * @param end
 */
export const normalizeToResolution = ({
  resolution,
  start,
  end,
}: GranulatedSeriesRequest): GranulatedSeriesRequest => ({
  start: pipe(start, roundDown(resolution)),
  end: pipe(end, roundUp(resolution)),
  resolution,
});

export const empty: <T>(
  seriesRequest: GranulatedSeriesRequest
) => DataSeries<T> = ({ start, end, resolution }) => ({
  start,
  end,
  resolution,
  series: new Map(),
});

export const getEq = <T>(eq: Eq.Eq<T>): Eq.Eq<DataSeries<T>> =>
  Eq.struct({
    start: D.Eq,
    end: D.Eq,
    resolution: n.Eq,
    series: M.getEq(n.Eq, eq),
  });

export const withDataFrom =
  <T>({ start, end, resolution }: GranulatedSeriesRequest) =>
  (series: DataSeries<T>): DataSeries<T> => ({
    ...pipe(series, subSeries(start, end)),
    start,
    end,
    resolution,
  });
