import { Stack } from "@mui/material";
import React from "react";
import ReportsTableHeaderFilter from "./ReportsTableHeaderFilter";
import ReportsTableHeaderSearch from "./ReportsTableHeaderSearch";

const ReportsTableHeader: React.FC = () => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="stretch" pt={4} px={3}>
      <ReportsTableHeaderSearch />
      <ReportsTableHeaderFilter />
    </Stack>
  );
};

export default ReportsTableHeader;
