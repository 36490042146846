import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as O from "fp-ts/Option";
import {
  assetUtilizationD,
  toPredicate,
} from "views/authenticated/assets/page/components/AssetsTable/calculations";
import * as NS from "lib/at-data/NumberSeries";
import { dataLength } from "lib/at-data/DataSeries";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import { UtilizationCell } from "views/authenticated/assets/page/components/AssetsTable/cells";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import React, { useCallback, useState } from "react";
import {
  AssetsFormulaColumn,
  SeriesFilterL,
  SortByL,
} from "controllers/AssetsTableController/models/AssetsFormulaColumn";
import {
  AssetsTableHeaderContextMenu,
  ColumnFormulaSeriesFilter,
  ColumnSortingFilter,
} from "views/authenticated/assets/page/components/AssetsTable/AssetsTableHeaderContextMenu";
import { Divider } from "@mui/material";
import * as A from "fp-ts/Array";
import * as AA from "fp-ts-std/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import * as t from "io-ts";
import { option } from "io-ts-types";
import { AssetColumnContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetColumnContext";
import * as AD from "lib/at-data/AsyncData";
import { asyncData } from "lib/at-data/AsyncData";
import { isoUSD, USD } from "lib/at-data/units/currency";
import * as Ord from "fp-ts/Ord";
import * as n from "fp-ts/number";

export const UtilizationColumnHeader: React.FC<{
  value: O.Option<string>;
  column: AssetsFormulaColumn;
}> = (props) => {
  return (
    <>
      <TableHeaderKpi
        label="Utilization"
        hint="Average number of people in the space relative to the planned number of people during operating hours"
        value={props.value}
        icon={true}
      />
    </>
  );
};

export const mean = (data: Array<number>) =>
  pipe(data, NEA.fromArray, O.map(flow(AA.mean)));

export const max = (data: Array<number>) =>
  pipe(data, NEA.fromArray, O.map(AA.maximum(n.Ord)));

export const meanUSD = (data: Array<USD>) =>
  pipe(data, NEA.fromArray, O.map(flow(NEA.map(isoUSD.unwrap), AA.mean)));

export const sum = (data: Array<number>) =>
  pipe(data, NEA.fromArray, O.map(flow(AA.sum)));

export const sumUSD = (data: Array<USD>) =>
  pipe(data, NEA.fromArray, O.map(flow(NEA.map(isoUSD.unwrap), AA.sum)));

export const formatPercent = (numberToFormat: number) => `${numberToFormat}%`;

const UtilizationSortBy = AD.getOrd(O.getOrd(n.Ord));

export const sortByFunction = (sortBy: string) => {
  return (sortBy === "asc" ? Ord.reverse(UtilizationSortBy) : UtilizationSortBy)
    .compare;
};

export const UtilizationColumn = (afc: AssetsFormulaColumn) =>
  defineColumn(
    "utilization",
    pipe(
      R.ask<AssetRowDataContext>(),
      // R.map((ctx) =>
      //   ROR.singleton(
      //     "utilization",
      //     pipe(
      //       ctx.occupancySeries,
      //       AD.map(
      //         flow(
      //           assetUtilizationD(ctx.assets)(ctx.asset)(
      //             pipe(afc.seriesFilter, toPredicate)
      //           ),
      //           O.chain(flow(NS.mean(dataLength)))
      //         )
      //       )
      //     )
      //   )
      // )
      R.map((ctx) => ROR.singleton("utilization", AD.DoneData(O.none)))
    ),
    R.of(UtilizationCell),
    pipe(
      readColumnData,
      R.map(
        flow(
          A.filterMap(
            flow(
              ROR.lookup("utilization"),
              O.chain(
                flow(
                  asyncData(option(t.number)).decode,
                  O.fromEither,
                  O.chain(flow(AD.getData, O.flatten))
                )
              )
            )
          ),
          mean,
          O.chain(O.fromPredicate(Number.isFinite)),
          O.map(flow(toPercentage, Math.round, formatPercent))
        )
      ),
      R.chainW((value) =>
        pipe(
          R.ask<AssetColumnContext>(),
          R.map((ctx) => <UtilizationColumnHeader column={afc} value={value} />)
        )
      )
    ),
    R.of({
      defaultFlex: 0.5,
      sortable: false,
      sort: UtilizationSortBy.compare,
    })
  );
