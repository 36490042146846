import * as n from "fp-ts/number";
import * as s from "fp-ts/string";
import {
  isoUSDPerOneSqF,
  isoUSDPerOneSqM,
  toUSDPerOneSqF,
  USDPerOneSqM,
} from "lib/at-data/units/currency";
import React, { useCallback, useState } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import {
  useCollectorOld,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  LegacyAssetsModificationsController,
  modifyAssetShortCode,
  modifyAssetTimezone,
  modifyCostPerSqM,
} from "controllers/AssetsController/AssetsController";
import {
  Editable,
  OptionalProperty,
  Property,
  USDPerSquareFtProperty,
  USDPerSquareMeterProperty,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import {
  DEFAULT_TIMEZONE,
  NumberEditor,
  TimeZoneEditor,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/editors";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { flow, pipe } from "fp-ts/function";
import { ById, getAllChildren } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import {
  AssetShortCodeL,
  CostPerSqML,
  TimezoneL,
} from "lib/at-data/assets/lens";
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import * as O from "fp-ts/Option";
import * as o from "fp-ts/Ord";
import * as A from "fp-ts/Array";
import LabeledCheckbox from "components/ui/LabeledCheckbox";
import * as L from "monocle-ts/Lens";
import * as R from "fp-ts/Reader";
import * as b from "fp-ts/boolean";
import { noop } from "lib/util";
import timezones from "timezones-list";

export const TimezonePropertyRow: React.FC<{ asset: Asset }> = (props) => {
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const rootDispatch = useControllerDispatch(RawAssetsController);

  const allAssetsAD = pipe(
    useCollectorOld(AssetsController, flow(L.prop("assets"))),
    fromAD2
  );

  const handleSaveTimezone = useCallback(
    (timezoneID: string, descendantsChecked: boolean) => {
      const timezone = O.some(timezoneID);
      assetModificationsDispatch(modifyAssetTimezone(props.asset, timezone));
      rootDispatch(
        pipe(
          ById(props.asset.id),
          updateAsset,
          T.composeLens(TimezoneL),
          T.set(timezone)
        )
      );
      pipe(
        descendantsChecked,
        b.fold(
          () => {
            noop();
          },
          () => {
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a) =>
                  assetModificationsDispatch(modifyAssetTimezone(a, timezone))
                )
              )
            )({ assets: allAssetsAD });
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a2) =>
                  rootDispatch(
                    pipe(
                      ById(a2.id),
                      updateAsset,
                      T.composeLens(TimezoneL),
                      T.set(timezone)
                    )
                  )
                )
              )
            )({ assets: allAssetsAD });
          }
        )
      );
    },
    [
      props.asset,
      assetModificationsDispatch,
      rootDispatch,
      modifyAssetTimezone,
      updateAsset,
    ]
  );

  return (
    <>
      <TableRow
        key={"assetName"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{ borderBottom: 0, width: "100%" }}
        >
          Timezone
        </TableCell>
        <TableCell align="right" sx={{ borderBottom: 0 }}>
          <Stack direction={"column"} alignItems={"flex-end"}>
            <Editable
              onChange={handleSaveTimezone}
              value={pipe(
                props.asset.timezone,
                O.getOrElse(() => DEFAULT_TIMEZONE)
              )}
              eq={s.Eq}
              editor={TimeZoneEditor}
              inheritanceAsset={props.asset}
            >
              <OptionalProperty
                value={props.asset.timezone}
                component={Property}
                required={true}
              />
            </Editable>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
