import React from "react";

export const BSLogo = () => (
  <svg
    width="150"
    height="52"
    viewBox="0 0 150 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M104.341 48.9998H101.115V49.988H110.959V48.9998H108.312V31.7891L107.981 32.1185L107.567 32.3655L106.74 32.6125L105.83 32.7772H101.612V33.7654H104.341V48.9998"
      fill="white"
    />
    <path
      d="M41.8087 48.9998H38.5V49.988H48.3433V48.9998H45.6964V31.7891L45.3655 32.1185L44.9519 32.3655L44.1247 32.6125L43.2149 32.7772H38.9136V33.7654H41.8087V48.9998"
      fill="white"
    />
    <path
      d="M115.84 48.9993H112.697V49.9875H122.706V48.9993H119.81V33.7649H126.924V48.9993H124.277V49.9875H134.038V48.9993H130.812V31.7885L130.564 32.1179L130.15 32.3649L129.24 32.612L128.248 32.7767H119.81C120.238 29.2343 118.714 24.2427 121.796 21.4127L122.375 21.0009L122.871 20.6715L123.45 20.5068L124.112 20.4245L124.773 20.2598L125.601 20.1774H126.262L126.841 20.2598L127.338 20.5068L127.751 20.6715L128.165 20.9186L128.413 21.0832L128.578 21.4127L128.661 21.6597L128.578 21.9067L128.413 22.1538L127.917 22.4008L127.503 22.7302C126.315 23.7705 126.803 25.1677 128.082 25.7771C129.24 26.37 130.55 25.4834 130.977 24.3772C131.786 21.6803 129.141 19.5708 126.676 19.2716C118.046 18.2079 115.156 25.5163 115.84 32.7767H112.283V33.7649H115.84V48.9993"
      fill="white"
    />
    <path
      d="M10.8716 37.4707C18.7765 38.4328 19.4149 48.5205 11.2025 49.4936C5.43019 49.7928 2.38204 44.8862 1.93813 39.6942H0.862836V50.3171H1.93813L2.10359 49.7407L2.26902 49.3289L2.59987 49.0819L3.17888 48.9995C4.67882 49.1313 6.43244 49.9685 7.89378 50.3994C10.8936 50.9567 14.3553 50.7275 16.8272 48.7525C20.7038 45.7303 21.0072 38.0074 16.9926 35.0003C12.1813 31.167 -0.197333 32.8332 4.08878 23.2245C9.18966 17.8389 16.5584 22.9075 16.9926 29.5653H18.0679V20.8364H17.0753C16.3998 24.7191 8.70578 14.9471 2.10359 23.1422C0.381699 25.5948 0.596736 28.4125 1.11096 31.2123C2.82734 35.7305 6.54547 36.3632 10.8716 37.4707"
      fill="white"
    />
    <path
      d="M90.9415 46.6911L91.0242 47.7616L91.2723 48.585L91.6859 49.2439L92.265 49.738L92.844 50.1497L93.6712 50.4791L94.6638 50.5614C96.8723 51.0446 99.409 50.1057 100.289 47.8439L99.4613 47.3498L99.0478 48.0086L98.386 48.6674L98.1379 48.8321L97.7243 49.0791L97.3107 49.2439H96.4008L96.0699 49.1615L95.6564 49.0791L95.4909 48.8321L95.1601 48.585L95.0773 48.1733L94.9119 47.5969L94.8292 47.1028V33.7624H99.4613V32.7742H94.8292V27.5039H94.0847C93.5747 31.296 91.4461 32.9169 87.6328 32.7742V33.7624H90.9415V46.6911"
      fill="white"
    />
    <path
      d="M78.4504 48.9998H81.2628V35.4947L81.0974 34.9183L80.8492 34.3419L80.6011 34.0948L80.1875 33.7654L79.7739 33.5184L79.2776 33.3537C76.7133 33.1067 74.6965 35.0953 73.4047 37.0593V48.9998H76.3825V49.988H66.291V48.9998H69.3515V33.7654H66.291V32.7772H70.8404L71.833 32.6125L72.2466 32.5302L72.6602 32.4479L73.322 31.7891V35.3301C75.2038 32.8487 77.8149 31.3485 81.0146 31.9538L82.09 32.2008L83.1653 32.7772L83.827 33.436L84.4887 34.1772L84.9023 35.083L85.1505 36.0712L85.3159 37.1417V48.9998H88.3764V49.988H78.4504V48.9998"
      fill="white"
    />
    <path
      d="M36.2658 44.3035C35.3972 48.3207 30.8933 51.0835 27.4978 47.9268L27.0842 47.1033L26.6706 46.1151L26.5052 44.9623V38.2097L26.6706 37.0568C27.0773 35.4689 28.1471 33.4582 29.9793 33.2688C31.0918 32.8571 32.8013 33.1041 33.7016 33.9276L33.867 34.1747L34.1151 34.504V35.0805L33.9497 35.3275L33.5361 35.6569L33.288 36.2333C32.345 38.2344 35.2497 39.5136 36.5139 38.045C40.4292 31.6575 23.253 27.9752 22.2039 40.3507C20.7026 51.8411 35.2911 54.8166 37.2584 44.3035H36.2658"
      fill="white"
    />
    <path
      d="M148.099 44.3055C147.463 48.4806 142.435 50.9441 139.496 47.8465L138.917 47.023L138.669 46.1171L138.421 44.8819L138.338 43.5644V39.6116L138.421 38.2118L138.669 37.0588C138.819 35.2486 140.186 33.6469 141.895 33.1062C143.833 32.9758 143.882 33.0348 145.617 33.8473L145.865 34.1767L146.031 34.5061V35.0002L145.948 35.3296L145.535 35.659L145.204 36.2354C144.728 36.8859 145.168 38.0223 145.865 38.3764L146.114 38.4588L146.527 38.6235H146.941L147.768 38.4588L148.099 38.2118L148.43 38.047C150.997 34.2659 144.925 31.3714 141.812 32.118C137.419 32.468 134.446 36.0323 134.037 40.2705C132.683 50.9936 146.418 55.3594 149.257 44.3055H148.099"
      fill="white"
    />
    <path
      d="M63.8108 44.1405C63.5696 46.5053 61.2011 49.4945 58.5996 49.2461C53.3912 49.5851 53.9703 43.0837 54.1329 39.7761H65.217C65.7753 36.2694 62.7741 32.9054 59.4268 32.2C55.4578 31.2365 51.5122 34.1023 50.328 37.7997C48.9893 41.7318 49.7958 46.5657 53.1403 49.2461C58.0096 52.3904 64.0535 50.0709 64.8862 44.1405H63.8108V44.1405ZM61.2466 38.4585V38.6232L61.0812 38.7879H54.2984C53.2341 31.2187 62.2585 31.0087 61.2466 38.4585"
      fill="white"
    />
    <path
      d="M26.9182 1.73036C36.603 0.625521 36.2749 13.2111 26.9182 12.0239V1.73036ZM22.7824 24.8702H20.1354V25.9408C25.6196 25.7527 37.9141 28.3041 39.2431 19.6823C39.5588 15.1408 36.5175 13.0615 32.2121 12.6003V12.4356C36.2005 12.168 38.8033 9.07033 38.0023 5.18898L37.9196 4.69491L37.5887 4.03612L37.2579 3.62438L36.927 3.13027L36.5134 2.71853L35.5208 1.89505L34.9418 1.64803L34.1973 1.40098L33.4529 1.07157L31.7985 0.824549L29.9788 0.742188H20.0527V1.73036H22.7824V24.8702V24.8702ZM26.9182 12.9297C37.7831 11.2951 37.8217 26.7327 26.9182 24.8702V12.9297Z"
      fill="white"
    />
    <path
      d="M40.9805 18.3649C41.2438 29.1443 55.8737 29.1443 56.2831 18.3649C55.8778 7.3673 41.1018 7.60885 40.9805 18.3649ZM52.3127 19.2708C52.3651 22.5606 51.9501 25.7543 47.9287 25.3645C43.757 24.7194 45.0046 19.1294 44.7854 16.2239C44.8005 13.2319 45.9876 10.9069 49.2522 11.283L49.7484 11.4477L50.3275 11.53L50.741 11.8594L51.1546 12.0241L51.4028 12.4358L51.6509 12.7653C52.7538 14.9475 52.1528 16.9046 52.3127 19.2708Z"
      fill="white"
    />
    <path
      d="M80.2715 18.3645C80.5265 29.1467 95.2956 29.148 95.5741 18.3645C95.4225 7.50829 80.4011 7.49869 80.2715 18.3645ZM91.7691 19.2704C91.7871 22.3488 91.237 25.7772 87.3851 25.3641C83.0728 24.9277 84.4087 19.1908 84.1592 16.3058C84.0682 13.2768 85.2138 10.9244 88.5432 11.2826L89.1222 11.4473L89.6185 11.5297L90.0321 11.859L90.4456 12.0237L90.6938 12.4355L90.942 12.7649C92.0903 15.2971 91.6947 16.5899 91.7691 19.2704Z"
      fill="white"
    />
    <path
      d="M106.492 24.8699H108.726V13.6705L108.477 13.0117L108.312 12.6C107.748 12.1526 107.099 11.6146 106.327 11.6942L105.748 11.8589L105.086 12.0236L104.507 12.353L103.928 12.7647L103.432 13.1764L102.935 13.7529L102.522 14.2469L102.191 14.9057V24.8699H104.921V25.9404H95.6562V24.8699H98.5513V11.6942H95.6562V10.7883H99.6267L100.702 10.706L101.116 10.6236L101.529 10.4589L101.777 10.2943L102.108 9.96484V12.9294H102.191L102.77 12.353L103.349 11.6942L103.928 11.2824C105.409 10.1899 106.094 10.2778 107.733 9.96484L108.974 10.1295L110.049 10.3766L110.876 10.8707L111.455 11.5295L111.952 12.2706L112.282 12.9294L112.448 13.7529V24.8699H114.516V25.9404H106.492V24.8699"
      fill="white"
    />
    <path
      d="M63.5629 19.7634C66.5269 19.7332 67.8463 23.6831 65.0518 25.116C61.6329 26.332 59.0811 24.1745 58.6826 20.6692H57.8555L58.0209 26.2689H58.8481L59.0135 25.6101L59.2617 25.5278H59.758L60.0888 25.6101L60.8333 26.0218L61.9086 26.2689C63.6098 26.8467 66.3285 26.3732 67.4506 24.9513C70.1196 21.8893 69.0278 17.0102 64.6383 16.3871C53.3543 15.6171 64.7361 6.00846 66.7889 14.7401H67.6161L67.5333 10.3757H66.7889L66.7062 10.6227L66.6235 10.7874L66.5407 11.0344C65.7949 11.0866 64.2991 10.2165 63.5629 10.211C61.4343 10.2088 60.246 10.3386 58.8481 12.105C56.5816 15.9355 59.0411 19.5959 63.5629 19.7634"
      fill="white"
    />
    <path
      d="M79.8572 23.5532C79.2906 24.5029 78.0154 25.417 76.8794 24.9531L76.5485 24.8707L76.3004 24.7061L76.0522 24.3766L75.9695 24.0473L75.7213 23.6355V11.695H79.8572V10.7892H75.7213V5.68359H75.0596C74.8707 8.50539 72.454 11.065 69.5176 10.7892V11.695H72.0818V23.0591L72.1645 23.9649L72.3299 24.7061L72.7435 25.2825L73.9015 26.106L74.646 26.2707L75.4732 26.4353C77.4019 26.7153 79.3912 26.1197 80.4362 24.3766L79.8572 23.5532"
      fill="white"
    />
  </svg>
);
