import React from "react";
import * as O from "fp-ts/Option";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import ActivityContentTimebar from "views/authenticated/activity/page/components/navigator/ActivityContentTimebar";
import { Asset } from "lib/at-data/assets/Asset";
import {
  TimelineRangeCollector,
  TimelineWidthCollector,
} from "views/authenticated/activity/page/components/navigator/TimelineRangeCollector";

const ActivityTimebarContainer: React.FC<{
  selectedRange: SeriesRequest;
  selectedAsset: O.Option<Asset>;
  open: boolean;
  handleTimebarOpen: () => void;
  handleTimebarClose: () => void;
  onSelectedTimestampChange: (date: Date) => void;
  onSelectedRangeChange: (range: SeriesRequest) => void;
}> = ({
  selectedRange,
  open,
  handleTimebarOpen,
  handleTimebarClose,
  onSelectedTimestampChange,
  ...props
}) => {
  return (
    <TimelineWidthCollector.Component context={{}}>
      <TimelineRangeCollector.Component
        context={{
          state: { series: selectedRange },
        }}
      >
        <ActivityContentTimebar
          selectedFineRange={selectedRange}
          selectedAsset={props.selectedAsset}
          variant="permanent"
          anchor="bottom"
          open={open}
          handleTimebarOpen={handleTimebarOpen}
          handleTimebarClose={handleTimebarClose}
          handleSelectedTimestampChange={onSelectedTimestampChange}
          onSelectedRangeChange={props.onSelectedRangeChange}
        />
      </TimelineRangeCollector.Component>
    </TimelineWidthCollector.Component>
  );
};

export default ActivityTimebarContainer;
