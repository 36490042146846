import * as Tr from "fp-ts/Tree";
import { Asset, hasNoParentIn } from "lib/at-data/assets/Asset";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { Assets } from "lib/at-data/assets/assets";
import * as O from "fp-ts/Option";
import { UUID } from "lib/at-data/UUID";
import { noop } from "lib/util";
import { getParentId } from "lib/at-data/assets/getters";

export type AssetTree = Tr.Tree<Asset>;
export type AssetForest = Tr.Forest<Asset>;

export const toForest = (assets: Assets): AssetForest => {
  const byIdMap = new Set(
    pipe(
      assets,
      A.map((a) => a.id)
    )
  );
  const byParentIdMap = new Map<UUID, Array<Asset>>();
  assets.forEach((a) => {
    pipe(
      a,
      getParentId,
      O.fold(noop, (pid) => {
        if (byParentIdMap.has(pid)) {
          const peers = byParentIdMap.get(pid);
          peers!.push(a);
        } else {
          byParentIdMap.set(pid, [a]);
        }
      })
    );
  });

  const makeForest = (as: Assets): Tr.Forest<Asset> =>
    pipe(
      as,
      A.map((a) => {
        const children = byParentIdMap.get(a.id) || [];
        return Tr.make(a, makeForest(children));
      })
    );

  const rootAssets = pipe(assets, A.filter(hasNoParentIn(byIdMap)));
  return makeForest(rootAssets);
};

export const forestSize = (af: AssetForest) =>
  pipe(
    Tr.make(null, af),
    Tr.reduce(-1, (a, c) => a + 1)
  );
