import { DateRange } from "@mui/lab/DateRangePicker";
import { styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { lastWeek } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import { addDays, startOfDay, subDays, subMonths, subYears } from "date-fns/fp";
import { pipe } from "fp-ts/function";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import * as React from "react";
import palette from "theme/palette";
import theme from "theme/theme";

enum TimeIntervals {
  PAST_24_HOURS = "PAST_24_HOURS",
  PAST_WEEK = "PAST_WEEK",
  PAST_30_DAYS = "PAST_30_DAYS",
  PAST_3_MONTHS = "PAST_3_MONTHS",
  PAST_6_MONTHS = "PAST_6_MONTHS",
  PAST_YEAR = "PAST_YEAR",
  ALL_TIME = "ALL_TIME",
}
const calculateStartDate = (
  endDate: Date,
  pastInterval: TimeIntervals
): Date => {
  switch (pastInterval) {
    case TimeIntervals.PAST_WEEK:
      return lastWeek(endDate).start;
    case TimeIntervals.PAST_24_HOURS:
    case TimeIntervals.PAST_30_DAYS: {
      const daysToSubtracts = {
        [TimeIntervals.PAST_24_HOURS]: 1,
        [TimeIntervals.PAST_WEEK]: 7,
        [TimeIntervals.PAST_30_DAYS]: 30,
      };
      return pipe(endDate, subDays(daysToSubtracts[pastInterval]), startOfDay);
    }
    case TimeIntervals.PAST_3_MONTHS:
      return pipe(endDate, subMonths(3), startOfDay);
    case TimeIntervals.PAST_6_MONTHS:
      return pipe(endDate, subMonths(6), startOfDay);
    case TimeIntervals.PAST_YEAR:
      return pipe(endDate, subYears(1), startOfDay);
    case TimeIntervals.ALL_TIME:
    default:
      return new Date(2000, 0, 1);
  }
};

const PreDateSelectors: React.FC<{
  now: Date;
  onSelectorChange: (newDates: SeriesRequest) => void;
  onReset: () => void;
}> = ({ now, onSelectorChange, onReset }) => {
  const handleClick = (pastInterval: TimeIntervals) => {
    pipe(
      SeriesRequest(
        calculateStartDate(now, pastInterval),
        pipe(now, addDays(1), startOfDay)
      ),
      onSelectorChange
    );
  };

  return (
    <Box paddingLeft={4} paddingRight={4} paddingTop={4}>
      <PreDateButtonPicker
        onClick={() => handleClick(TimeIntervals.PAST_24_HOURS)}
        mb={1}
      >
        Past Day
      </PreDateButtonPicker>
      <PreDateButtonPicker
        onClick={() => handleClick(TimeIntervals.PAST_WEEK)}
        mb={1}
      >
        Past Week
      </PreDateButtonPicker>
      <PreDateButtonPicker
        onClick={() => handleClick(TimeIntervals.PAST_30_DAYS)}
        mb={1}
      >
        Past 30 days
      </PreDateButtonPicker>
      <PreDateButtonPicker
        onClick={() => handleClick(TimeIntervals.PAST_3_MONTHS)}
        mb={1}
      >
        Past 3 months
      </PreDateButtonPicker>
      <PreDateButtonPicker
        onClick={() => handleClick(TimeIntervals.PAST_6_MONTHS)}
        mb={1}
      >
        Past 6 months
      </PreDateButtonPicker>
      <PreDateButtonPicker
        onClick={() => handleClick(TimeIntervals.PAST_YEAR)}
        mb={1}
      >
        Past year
      </PreDateButtonPicker>
      {/*<PreDateButtonPicker*/}
      {/*  onClick={() => handleClick(TimeIntervals.ALL_TIME)}*/}
      {/*  mb={1}*/}
      {/*>*/}
      {/*  All time*/}
      {/*</PreDateButtonPicker>*/}

      {/*<Box mt={4}>*/}
      {/*  <Button*/}
      {/*    onClick={() => onReset()}*/}
      {/*    color="info"*/}
      {/*    size="large"*/}
      {/*    variant="text"*/}
      {/*  >*/}
      {/*    Reset*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </Box>
  );
};

export default PreDateSelectors;

const PreDateButtonPicker = styled(Typography)`
  background-color: ${palette.neutral[200]};
  transition: ${theme.transitions.create(["box-shadow"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};
  cursor: pointer;
  text-align: center;
  padding: 3px 12px 3px 12px;
  font-size: 14px;
  display: block;
  width: max-content;

  &:hover {
    box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
    background-color: ${palette.neutral[200]};
  }

  &:focus {
    box-shadow: inset 0 0 0 1.5px ${palette.primary[400]};
  }
`;
