import React from "react";
import { Stack, Typography } from "@mui/material";
import Icon, { AtHelp } from "components/ui/Icon";
import palette from "theme/palette";

export const NoDataFoundInsights: React.FC<{}> = () => {
  return (
    <Stack direction="column">
      <Stack direction="row" mb={3} spacing={2}>
        <Icon icon={AtHelp} size="36px" color={`${palette.neutral[100]}`} />
        <div>
          <Typography color={palette.neutral[400]}>
            Insights are not ready for this space yet, but you're almost there.
          </Typography>
          <Typography color={palette.neutral[400]} sx={{ marginTop: "16px" }}>
            Check with your customer success manager to see how we can get
            Insights going for you.
          </Typography>
          <Typography sx={{ marginTop: "16px" }}>
            <a
              href="mailto:success@lambentspaces.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: `${palette.neutral[400]}` }}
            >
              success@lambentspaces.com
            </a>
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export const NoDataFoundBookings: React.FC<{}> = () => {
  return (
    <Stack direction="column">
      <Stack direction="row" mb={3} spacing={2}>
        <Icon icon={AtHelp} size="36px" color={`${palette.neutral[100]}`} />
        <div>
          <Typography color={palette.neutral[400]}>
            Bookings are not ready for this space yet, but you're almost there.
          </Typography>
          <Typography color={palette.neutral[400]} sx={{ marginTop: "16px" }}>
            Check with your customer success manager to see how we can get
            Bookings going for you.
          </Typography>
          <Typography sx={{ marginTop: "16px" }}>
            <a
              href="mailto:success@lambentspaces.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: `${palette.neutral[400]}` }}
            >
              success@lambentspaces.com
            </a>
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
};

export const NoDataPlaceholderImage: React.FC<{}> = () => {
  return (
    <svg
      width="295"
      height="128"
      viewBox="0 0 295 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: "12px" }}
    >
      <rect
        x="0.5"
        y="0.5"
        width="294"
        height="126.568"
        rx="3.5"
        fill="#46495E"
        stroke="#5E6478"
      />
      <rect
        width="5.83983"
        height="177.377"
        transform="matrix(0.00367688 0.999993 -1 0.000505594 205.282 80.7266)"
        fill="#5E6478"
      />
      <rect
        width="13.9528"
        height="177.347"
        transform="matrix(0.00367688 0.999993 -1 0.000505594 205.253 17.9375)"
        fill="#5E6478"
      />
      <rect
        width="13.9528"
        height="110.603"
        transform="matrix(0.00367688 0.999993 -1 0.000505594 138.509 45.8789)"
        fill="#5E6478"
      />
      <rect
        width="5.83983"
        height="82.423"
        transform="matrix(0.00367688 0.999993 -1 0.000505594 110.343 99.6641)"
        fill="#5E6478"
      />
    </svg>
  );
};
