import { not } from "fp-ts/Predicate";
import { isZone } from "lib/at-data/assets/predicates";
import { useCollector } from "lib/at-react/hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import { Assets } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import * as R from "fp-ts/lib/Reader";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { Asset } from "lib/at-data/assets/Asset";
import { toGridColumns } from "views/authenticated/assets/page/components/AssetsTable/columns";
import { AssetsTableController } from "controllers/AssetsTableController/AssetsTableController";
import { createColumn } from "views/authenticated/assets/page/components/AssetsTable/dynamic";
import { ColumnsL } from "controllers/AssetsTableController/lens";
import {
  isShownFor,
  TableDataViews,
} from "controllers/AssetsTableController/state";
import { filterAssetsState } from "controllers/UIStateCollector/utils";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import {
  AssetsTableDataController,
  DataL,
} from "controllers/AssetsTableDataController/AssetsTableDataController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import { AssetsPageUIState } from "controllers/UIStateCollector/models/AssetsPageUIStateModel";
import {
  AssetsFilterL,
  AssetsPageSelectedAssetIdL,
  AssetsPageUIStateL,
  FilterByIdsL,
  FilterByNameL,
  FilterByTypeL,
  SelectedRangeL,
  SelectedSideItemL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { AssetPageTab } from "controllers/UIStateCollector/models/AssetsPageTab";
import { TypeOnSelectionChangeArg } from "@inovua/reactdatagrid-community/types/TypeDataGridProps";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { LegacyAssetsModificationsController } from "controllers/AssetsController/AssetsController";
import * as C from "io-ts/Codec";
import * as E from "fp-ts/Either";
import { getAllChildren, getParent } from "lib/at-data/assets/filters";
import { navigateToActivity } from "controllers/UIStateCollector/lens/activityPage";
import { AppController } from "controllers/AppController/AppController";
import {
  SelectedAssetL,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { FeatureFlagController } from "controllers/FeatureFlagController/FeatureFlagController";
import { hasFeatureFlag } from "controllers/AppController/IsFeatureFlagAvailable";
import { EDIT_FEATURE } from "constants/featureFlags";
import { sequenceT } from "fp-ts/Apply";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import * as b from "fp-ts/boolean";
import { noop } from "lib/util";
import {
  VisibleAssetsController,
  VisibleAssetsL,
} from "controllers/VisibleAssetsController/VisibleAssetsController";
import {
  FilterByIds,
  FilterByParentId,
} from "controllers/UIStateCollector/models/assetFilterModel";
import { getAssetType, getId } from "lib/at-data/assets/getters";
import {
  AssetsAnalyzeDataController,
  AssetsAnalyzeDataL,
} from "controllers/AssetsAnalayzeDataController/AssetsAnalyzeDataController";
import * as AD2 from "lib/at-data/AsyncData2";
import SpinnerModal from "components/ui/SpinnerModal";

const expandAssetsNodes = (
  initialExpandedNodes: any,
  assetsState: AssetsPageUIState,
  assets: Assets,
  ignoreFilter: boolean
) => {
  return pipe(
    O.fromNullable(assetsState.assetsFilter.byName),
    O.filter((byName) => byName.length > 0 || ignoreFilter),
    O.map((_byName) => {
      return assets.reduce((val: any, _asset, index) => {
        val[index.toString()] = true;
        return val;
      }, {});
    }),
    O.getOrElse(() => initialExpandedNodes)
  );
};

export const getAllSelectedAssets =
  (visibleAssets: Assets) => (selectionArg: TypeOnSelectionChangeArg) => {
    return pipe(
      sequenceT(E.Monad)(
        pipe(selectionArg.selected, C.boolean.decode),
        pipe(
          selectionArg.unselected,
          optionFromNullable(C.UnknownRecord).decode
        )
      ),
      E.map(([selected, unselected]) =>
        pipe(
          selected,
          b.fold(
            () => [],
            () =>
              pipe(
                visibleAssets,
                A.map(getId),
                A.difference(eqUUID)(
                  pipe(
                    unselected,
                    O.getOrElseW(() => ({})),
                    (s) => Object.keys(s)
                  ) as UUID[]
                )
              )
          )
        )
      )
    );
  };

export const getIndividualSelectedAssets = (
  selectionArg: TypeOnSelectionChangeArg
) =>
  pipe(
    selectionArg.selected,
    C.UnknownRecord.decode,
    E.map((selection) => Object.keys(selection) as UUID[])
  );

export const AssetsTable: React.FC<{}> = React.memo((props) => {
  const columns = useCollector(AssetsTableController, L.composeLens(ColumnsL));
  const uiStateDispatch = useHistoryControllerDispatch(UIStateController);
  const [assetsFilter] = useController(
    UIStateController,
    flow(pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).get)
  );

  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );

  const [selectedRange] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).get
  );
  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );

  const [visibleAssets] = useController(
    VisibleAssetsController,
    flow(VisibleAssetsL.get, filterAssetsState(assetsFilter))
  );

  const rootDispatch = useControllerDispatch(RawAssetsController);
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const user = useCollectorOld(AppController, L.prop("user"));

  const [tableData] = useController(AssetsTableDataController, DataL.get);

  const [analyzeDataDelayed] = useController(
    AssetsAnalyzeDataController,
    flow(AssetsAnalyzeDataL.get, AD2.isDelayed)
  );
  const [delayedModalForceClosed, setDelayedModalForceClosed] =
    useState<boolean>(false);
  useEffect(() => setDelayedModalForceClosed(false), [analyzeDataDelayed]);

  const featureFlags = useCollectorOld(
    FeatureFlagController,
    L.prop("featureFlags")
  );

  const handleTableSelectionChange = useCallback(
    (_: TypeOnSelectionChangeArg) => {
      pipe(
        _,
        getAllSelectedAssets(visibleAssets),
        E.alt(() => getIndividualSelectedAssets(_)),
        E.fold(noop, (selection) => {
          uiStateDispatch(
            flow(
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsPageSelectedAssetIdL)
              ).set(selection),
              pipe(
                AssetsPageUIStateL,
                L.composeLens(SelectedSideItemL),
                L.modify(
                  O.alt(() => O.some(AssetPageTab(ASSET_CONTEXT_TABS.ASSET)))
                )
              )
            )
          );
        })
      );
    },
    [visibleAssets, uiStateDispatch]
  );

  const handleViewAssetInMap = useCallback(
    () =>
      uiStateDispatch(
        navigateToActivity({ selectedAsset, selectedRange })(allAssets)
      ),
    [selectedAsset, selectedRange, allAssets, uiStateDispatch]
  );

  const handleFilterByParent = useCallback(
    (asset: Asset) => {
      return () => {
        uiStateDispatch(
          flow(
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByTypeL)
            ).set([AssetTypes.ZONE, AssetTypes.BUILDING, AssetTypes.FLOOR]),
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByNameL)
            ).set(""),
            pipe(
              asset,
              getId,
              FilterByParentId,
              O.some,
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsFilterL),
                L.composeLens(FilterByIdsL)
              ).set
            )
          )
        );
      };
    },
    [uiStateDispatch]
  );
  const handleViewAllZones = useCallback(
    (asset: Asset) => {
      return () => {
        uiStateDispatch(
          flow(
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByTypeL)
            ).set([AssetTypes.ZONE]),
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByNameL)
            ).set(""),
            pipe(
              asset,
              getAllChildren,
              R.map(
                flow(
                  A.map(getId),
                  FilterByIds,
                  O.some,
                  pipe(
                    AssetsPageUIStateL,
                    L.composeLens(AssetsFilterL),
                    L.composeLens(FilterByIdsL)
                  ).set
                )
              )
            )({ assets: allAssets })
          )
        );
      };
    },
    [uiStateDispatch, allAssets]
  );

  const handleGetParentFromChild = useCallback(
    (asset: Asset) => {
      return () => {
        uiStateDispatch(
          flow(
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByTypeL)
            ).set([AssetTypes.CAMPUS, AssetTypes.BUILDING, AssetTypes.FLOOR]),
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByNameL)
            ).set(""),
            pipe(
              allAssets,
              getParent(asset),
              O.map(flow(getId, A.of, FilterByIds)),
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsFilterL),
                L.composeLens(FilterByIdsL)
              ).set
            )
          )
        );
      };
    },
    [uiStateDispatch, allAssets]
  );

  const handleAssetNameClick = useCallback(
    (asset: Asset) => {
      pipe(
        asset,
        O.fromPredicate(not(isZone)),
        O.fold(noop, (sa) =>
          uiStateDispatch(
            flow(
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsFilterL),
                L.composeLens(FilterByTypeL)
              ).set([AssetTypes.ZONE, AssetTypes.BUILDING, AssetTypes.FLOOR]),
              pipe(
                sa,
                getId,
                FilterByParentId,
                O.some,
                pipe(
                  AssetsPageUIStateL,
                  L.composeLens(AssetsFilterL),
                  L.composeLens(FilterByIdsL)
                ).set
              ),
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsPageSelectedAssetIdL)
              ).set([asset.id]),
              pipe(
                AssetsPageUIStateL,
                L.composeLens(AssetsFilterL),
                L.composeLens(FilterByNameL)
              ).set("")
            )
          )
        )
      );
    },
    [uiStateDispatch]
  );

  const renderRowContextMenu = useCallback(
    (menuProps, { rowProps, cellProps }) => {
      menuProps.autoDismiss = true;
      pipe(
        cellProps.data.asset,
        getAssetType,
        O.some,
        O.foldW(
          () => null,
          (t) =>
            t === AssetTypes.ZONE
              ? (menuProps.items = [
                  {
                    label: "View Parent",
                    onClick: handleGetParentFromChild(cellProps.data.asset),
                  },
                ])
              : t === AssetTypes.CAMPUS
              ? (menuProps.items = [
                  {
                    label: "View All Descendants",
                    onClick: handleViewAllZones(cellProps.data.asset),
                  },
                  {
                    label: "View Direct Descendants",
                    onClick: handleFilterByParent(cellProps.data.asset),
                  },
                ])
              : (menuProps.items = [
                  {
                    label: "View All Descendants",
                    onClick: handleViewAllZones(cellProps.data.asset),
                  },
                  {
                    label: "View Direct Descendants",
                    onClick: handleFilterByParent(cellProps.data.asset),
                  },
                  {
                    label: "View Parent",
                    onClick: handleGetParentFromChild(cellProps.data.asset),
                  },
                ])
        )
      );
    },
    [handleFilterByParent, handleViewAllZones, handleGetParentFromChild]
  );

  const tableColumns = useMemo(
    () =>
      pipe(
        columns,
        A.filter(isShownFor(TableDataViews.BROWSER)),
        A.map(createColumn),
        toGridColumns,
        R.map(O.getOrElseW(() => []))
      )({
        data: tableData,
        assets: visibleAssets,
        allAssets,
        user,
        onAssetNameClick: handleAssetNameClick,
      }),
    [columns, tableData, visibleAssets, allAssets, user, handleAssetNameClick]
  );

  return (
    <>
      {analyzeDataDelayed && !delayedModalForceClosed ? (
        <SpinnerModal onClose={() => setDelayedModalForceClosed(true)} />
      ) : null}
      <ReactDataGrid
        enableSelection={true}
        multiSelect={true}
        onSelectionChange={handleTableSelectionChange}
        // onRowClick={handleRowClick}
        idProperty={"assetId"}
        licenseKey="AppName=ArmoredThingsApp,Company=ArmoredThings,ExpiryDate=2023-03-08,LicenseDeveloperCount=2,LicenseType=single_app,Ref=ArmoredThingsLicenseRef,Z=874177969-88290940832246787443234957-812671145-2050061877"
        // treeColumn="assets"
        // expandedNodes={expandedNodes}
        // onExpandedNodesChange={onExpandedNodesChange}
        columns={tableColumns as any}
        dataSource={tableData}
        showZebraRows={false}
        showCellBorders={false}
        pagination={true}
        defaultLimit={15}
        showColumnMenuTool={false}
        columnMinWidth={75}
        sortable={true}
        allowUnsort={false}
        defaultSortInfo={{ name: "assetMeanUtilization", dir: -1 }}
        renderRowContextMenu={renderRowContextMenu}
        checkboxColumn={pipe(featureFlags, hasFeatureFlag(EDIT_FEATURE))}
      />
    </>
  );
});
