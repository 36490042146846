import React, { useEffect, useRef, useState, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { palette } from "../styles/theme";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  type: string;
}

export const InputField: React.FC<InputProps> = ({
  name,
  label,
  type,
  value,
  onChange,
  ...rest
}) => {
  // const [inputValue, setInputValue] = useState("");

  return (
    <FormControl data-ui={"inputField"}>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </FormControl>
  );
};

const FormControl = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 50%;
  padding: 4px 24px;

  // Label
  & label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: ${palette.neutral[500]};
  }

  //Input
  & input[type="text"],
  & input[type="number"],
  & input[type="email"],
  & select {
    padding: 12px;
    font-size: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: ${palette.neutral[400]};
    border-radius: 2px;
    outline: none;
  }

  //Disabled
  & input:disabled {
    cursor: not-allowed;
  }

  //Error
  &[data-state="error"] {
    & > input.error {
      border-color: red !important;
    }
  }
`;
