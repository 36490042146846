import { Asset } from "lib/at-data/assets/Asset";
import { pipe } from "fp-ts/function";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import * as O from "fp-ts/Option";
import { eqUUID } from "lib/at-data/UUID";

export const isRegion = (asset: Asset) =>
  pipe(asset.type === AssetTypes.CAMPUS);

export const isFloor = (asset: Asset) => pipe(asset.type === AssetTypes.FLOOR);
export const isArea = (asset: Asset) => pipe(asset.type === AssetTypes.AREA);
export const isZone = (asset: Asset) => pipe(asset.type === AssetTypes.ZONE);
export const isBuilding = (asset: Asset) =>
  pipe(asset.type === AssetTypes.BUILDING);
export const parentIs = (b: Asset) => (e: Asset) =>
  O.getEq(eqUUID).equals(O.some(b.id), e.parent);
