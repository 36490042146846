import {
  SelectedAssetIdsContext,
  SelectedAssetsContext,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { pipe } from "fp-ts/function";
import { eqAssets } from "lib/at-data/assets/assets";
import {
  composeController,
  defineCalculatedContext,
} from "lib/at-react/defineController";
import * as Eq from "fp-ts/Eq";
import * as O from "fp-ts/Option";
import { SidePanelTab } from "controllers/UIStateCollector/models/assetContextTabs";
import * as b from "fp-ts/boolean";
import { AssetsPageTabEq } from "controllers/UIStateCollector/models/AssetsPageTab";

interface Context {
  selectedSideItem: O.Option<SidePanelTab>;
  disableSideTabs: boolean;
}

export const [component, controller] = defineCalculatedContext(
  {
    showTabs: false,
    showDrawer: false,
    sideWidth: "0px",
  },
  Eq.struct<Context>({
    disableSideTabs: b.Eq,
    selectedSideItem: O.getEq(AssetsPageTabEq),
  }),
  (p: Context) => {
    // console.log("Change", p);
    return {
      showDrawer: O.isSome(p.selectedSideItem),
      showTabs: O.isSome(p.selectedSideItem) && !p.disableSideTabs,
      sideWidth: O.isSome(p.selectedSideItem) ? "55px" : "12px",
    };
  }
);

export const UIStateContextComponent = component;
export const UIStateContext = controller;

export type ActivityPageUIContext = {
  sidebarOpen: boolean;
  timebarOpen: boolean;
  rightBarOpen: boolean;
};

export const [component2, controller2] = pipe(
  SelectedAssetsController,
  composeController(
    defineCalculatedContext(
      { leftNav: 0, rightNav: 0, bottomPanel: 0 },
      Eq.struct<ActivityPageUIContext & SelectedAssetsContext>({
        sidebarOpen: b.Eq,
        timebarOpen: b.Eq,
        rightBarOpen: b.Eq,
        selectedAssets: eqAssets,
      }),
      (ctx) => ({
        leftNav: ctx.sidebarOpen ? 240 : 0,
        rightNav: ctx.rightBarOpen && ctx.selectedAssets.length ? 328 : 0,
        bottomPanel: ctx.timebarOpen ? 196 : 50,
      })
    )
  )
);
export const ActivityPageUICalculationsControllerComponent = component2;
export const ActivityPageUICalculationsController = controller2;
