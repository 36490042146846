import * as C from "io-ts/Codec";
import { option } from "lib/codecs/option";
import { HoursOfOperationModel } from "lib/at-data/HoursOfOperation";
import { USDPeSqMModel } from "lib/at-data/units/currency";

export const AssetAnalyticsModel = C.struct({
  operationHours: option(HoursOfOperationModel),
  capacity: option(C.number),
  targetUtilization: option(C.number),
  costPerSqM: option(USDPeSqMModel),
  timezone: option(C.string),
});

export type AssetAnalytics = C.TypeOf<typeof AssetAnalyticsModel>;
