import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import * as n from "fp-ts/number";
import { contramap, geq } from "fp-ts/Ord";
import * as A from "fp-ts/Array";
import * as R from "fp-ts/Reader";
import * as ReaderT from "fp-ts/ReaderT";
import * as NEA from "fp-ts/NonEmptyArray";

export type Threshold = {
  threshold: O.Option<number>;
  legend: string;
  color: string;
};
export type UtilizationThresholds = Array<Threshold>;

export const Threshold = (
  legend: string,
  color: string,
  threshold?: number
): Threshold => ({
  legend,
  threshold: O.fromNullable(threshold),
  color,
});

export const ThresholdOrd = pipe(
  O.getOrd(n.Ord),
  contramap((t: Threshold) => t.threshold)
);

export const thresholdRange = (next: O.Option<Threshold>) => (t: Threshold) =>
  [
    t.threshold,
    pipe(
      next,
      O.chain((_) => _.threshold)
    ),
  ] as [O.Option<any>, O.Option<any>];

export const sort = (tt: UtilizationThresholds) =>
  pipe(tt, A.sort(ThresholdOrd));

export const getThreshold = (value: number) =>
  pipe(
    R.ask<UtilizationThresholds>(),
    R.map(
      flow(
        NEA.fromArray,
        O.chain(flow(sort, A.findLast(thresholdGreaterOrEqual(value))))
      )
    )
  );

export const getThresholdColor = (value: number) =>
  pipe(
    value,
    O.fromPredicate(Number.isFinite),
    R.of,
    ReaderT.chain(O.Monad)(flow(getThreshold, R.map(O.map((_) => _.color))))
  );

const thresholdGreaterOrEqual = (value: number) => (_: Threshold) =>
  geq(O.getOrd(n.Ord))(O.some(value), _.threshold);
