import {
  AssetsFormulaColumn,
  SeriesFilterEq,
} from "controllers/AssetsTableController/models/AssetsFormulaColumn";
import { TableColumnTypes } from "controllers/AssetsTableController/tableColumnTypes";
import * as Eq from "fp-ts/Eq";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";
import * as C from "io-ts/Codec";
import {
  TableColumnEq,
  TableColumnModel,
} from "controllers/AssetsTableController/models/TableColumn";
import * as D from "io-ts/Decoder";
import { pipe } from "fp-ts/function";

export enum AssetsCompareColumnTypes {
  ASSET_UNDERUTILIZATION_COST = "assetUnderutilization",
}

export const AssetsCompareColumnTypeModel = D.union(
  D.literal(AssetsCompareColumnTypes.ASSET_UNDERUTILIZATION_COST)
);

export type AssetsCompareColumnType = `${AssetsCompareColumnTypes}`;

export const AssetsCompareColumnModel = pipe(
  TableColumnModel,
  C.intersect(
    C.struct({
      name: C.string,
      type: C.fromDecoder(AssetsCompareColumnTypeModel),
      _type: C.fromDecoder(D.literal(TableColumnTypes.COMPARE)),
    })
  )
);

export type AssetsCompareColumn = C.TypeOf<typeof AssetsCompareColumnModel>;

export const AssetsCompareColumnEq = TableColumnEq;
