import {
  AtBuilding,
  AtCampus,
  AtEmpty,
  AtFloor,
  AtRoom,
} from "components/ui/Icon";
import { AssetType, AssetTypes } from "lib/at-data/assets/AssetTypes";
import { Assets } from "lib/at-data/assets/assets";
import { flow, getSemigroup, pipe } from "fp-ts/function";
import {
  ById,
  ByText,
  ByParentId,
  ByTag,
  ByType,
  filterAssets,
} from "lib/at-data/assets/filters";
import { concatAll } from "fp-ts/Magma";
import * as B from "fp-ts/boolean";
import * as A from "fp-ts/Array";
import { Asset } from "lib/at-data/assets/Asset";
import {
  AssetFilter,
  AssetsFilter,
  FilterById,
  FilterByIds,
} from "controllers/UIStateCollector/models/assetFilterModel";
import { AssetTag } from "lib/at-api/asset-tags/assetTags";
import { and } from "fp-ts/Predicate";
import { UUID } from "lib/at-data/UUID";
import * as O from "fp-ts/Option";
import { match } from "ts-adt";

export const AssetIcons = {
  [AssetTypes.CAMPUS]: AtCampus,
  [AssetTypes.FLOOR]: AtFloor,
  [AssetTypes.BUILDING]: AtBuilding,
  [AssetTypes.ZONE]: AtRoom,
  [AssetTypes.AREA]: AtRoom,
  [AssetTypes.UNKNOWN]: AtEmpty,
};
const ByTypes = (byTypes: AssetType[]) =>
  concatAll(getSemigroup(B.SemigroupAny)<Asset>())(() => false)(
    byTypes.map(ByType)
  );

export const ByAllAssetIds = (assetIds: Array<UUID>) =>
  concatAll(getSemigroup(B.SemigroupAny)<Asset>())(() => false)(
    assetIds.map(ById)
  );

export const ByAllParentIds = (parentIds: Array<UUID>) =>
  concatAll(getSemigroup(B.SemigroupAny)<Asset>())(() => false)(
    parentIds.map(flow(O.some, ByParentId))
  );

export const ByAllAssetIdsOnly = (assetIds: Array<UUID>) =>
  concatAll(getSemigroup(B.SemigroupAny)<Asset>())(() => false)(
    assetIds.map(ById)
  );

const ByTags = (byTags: AssetTag[]) =>
  pipe(
    concatAll(getSemigroup(B.SemigroupAll)<Asset>())(() => true)(
      byTags.map(ByTag)
    )
  );

const ByIds = (byIds: O.Option<FilterById>) =>
  pipe(
    byIds,
    O.map(
      match({
        byParentId: (_) => ByParentId(O.some(_.parentId)),
        byId: (_) => ByAllAssetIds(_.ids),
      })
    ),
    O.getOrElseW(() => () => true)
  );

const assetsPredicate = (filter: AssetFilter) =>
  pipe(
    ByIds(filter.byIds),
    and(
      concatAll(AssetsFilter)(() => true)([
        ByTypes(filter.byTypes),
        ByText(filter.byName),
        ByTags(filter.byTags),
      ])
    )
  );
export const filterAssetsState = (filter: AssetFilter) => (assets: Assets) =>
  pipe(assets, filterAssets(assetsPredicate(filter)));
