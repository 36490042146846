import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { AppContext } from "contexts/AppContext";
import * as D from "io-ts/Decoder";
import * as C from "io-ts/Codec";
import { UUID } from "lib/at-data/UUID";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import { dateFromISOString } from "lib/codecs/dateFromISOString";

export const InisghtsModel = C.struct({
  insightId: UUID,
  assetId: UUID,
  insightType: C.string,
  generatedTime: C.fromDecoder(dateFromISOString),
  startTime: C.fromDecoder(dateFromISOString),
  endTime: C.fromDecoder(dateFromISOString),
  message: C.string,
  data: C.UnknownRecord,
});

export type Insights = C.TypeOf<typeof InisghtsModel>;

export const InsightsResponseModel = C.array(InisghtsModel);

export const handleResponse = (errorType: string) => (r: Response) => {
  if (r.ok) return r.json();
  else {
    throw new Error(`${errorType}: ${r.status} ${r.statusText} `);
  }
};

export const fetchInsights = (assetId: UUID) => {
  return pipe(
    RTE.ask<AppContext & AbortingControllerContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            // If want to test real data can use this URL:
            // https://asset-insights-dev.azurewebsites.net/api/insights?assetId=d4f78854-b049-4a71-a215-b2071f7ffa71
            `${process.env.REACT_APP_API_URL}/asset-insights/insights?assetId=${assetId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ctx.appContext.accessToken}`,
              },
              signal: ctx.abortingController.signal,
            }
          ).then(handleResponse("Accessing insightsSample API")),
        E.toError
      )
    ),
    RTE.chainEitherK(
      flow(
        InsightsResponseModel.decode,
        E.mapLeft((e) => {
          console.log(D.draw(e));
          return new Error("error parsing insightsSample response");
        })
      )
    )
  );
};
