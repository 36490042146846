import * as AD2 from "lib/at-data/AsyncData2";
import * as DS from "lib/at-data/DataSeries";
import {
  OccupancyEstimate,
  OccupancyEstimateEq,
} from "lib/at-data/OccupancyEstimate";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { Lens } from "monocle-ts";
import * as E from "fp-ts/Eq";
import * as SR from "lib/at-data/requests/temporal/SeriesRequest";
import * as b from "fp-ts/boolean";

export interface TimebarState {
  timebarSeries: AD2.AsyncData2<DS.DataSeries<OccupancyEstimate>>;
}

export const TimebarStateEq = E.struct<TimebarState>({
  timebarSeries: AD2.getEq(DS.getEq(OccupancyEstimateEq)),
});

export const TimebarSeriesL = Lens.fromProp<TimebarState>()("timebarSeries");
