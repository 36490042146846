import { ArtifactTypes, LambentArtifact } from "lib/at-data/Artifact";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import * as Eq from "fp-ts/Eq";
import { getEq } from "lib/at-data/Slice";
import { eqUUID } from "lib/at-data/UUID";
import { ImageArtifactEqByTimestamp } from "controllers/LocalArtifactsController/state";

export type ImageArtifactsControllerState = {
  artifacts: Record<ArtifactTypes, UUIDSlice<LambentArtifact>>;
};

export const ImageArtifactsControllerStateEq =
  Eq.struct<ImageArtifactsControllerState>({
    artifacts: Eq.struct({
      [ArtifactTypes.BACKGROUND]: getEq(eqUUID, ImageArtifactEqByTimestamp),
    }),
  });
