import { ActivityPageUIState } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import * as O from "fp-ts/Option";
import {
  DEFAULT_FILTERS,
  lastWeek,
} from "controllers/UIStateCollector/DEFAULT_FILTERS";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { addDays, subDays } from "date-fns/fp";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { pipe } from "fp-ts/function";
import { ACTIVITY_MAP_LAYER } from "views/authenticated/activity/controller/state";

export const activityPageInitialState: ActivityPageUIState = {
  selectedSideItem: O.none,
  selectedAssetIds: [],
  selectedRange: lastWeek(new Date()),
  selectedSlice: O.none,
  timebarOpen: false,
  sidebarOpen: false,
  assetsFilter: {
    ...DEFAULT_FILTERS,
    byTypes: [
      AssetTypes.BUILDING,
      AssetTypes.CAMPUS,
      AssetTypes.FLOOR,
      AssetTypes.ZONE,
    ],
  },
  bbox: O.none,
  bearing: O.none,
  layers: new Set([
    ACTIVITY_MAP_LAYER.LABELS,
    ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION,
  ]),
};
