import React from "react";
import {
  Stack,
  Typography,
  styled,
  Button,
  Menu,
  MenuItem,
  alpha,
  Paper,
} from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import palette from "theme/palette";
import Icon, {
  AtBuilding,
  AtCampus,
  AtChevronDown,
  AtEmpty,
  AtFloor,
  AtRoom,
} from "components/ui/Icon";

const AssetList: React.FC<{}> = () => {
  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<AtChevronDown />}
      // defaultExpandIcon={<AtMetrics />}
      sx={{ height: "auto", flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      <StyledTreeItem
        nodeId="101"
        label="BRW - Fred D. Brown Hall"
        icon={<AtBuilding />}
      />
      <StyledTreeItem
        nodeId="102"
        label="LIB - Hodges Library"
        icon={<AtBuilding />}
      />
      <StyledTreeItem
        nodeId="103"
        label="AAB - Art & Architecture"
        icon={<AtBuilding />}
      />
      <StyledTreeItem
        nodeId="104"
        label="AMB - Alumni Memorial"
        icon={<AtBuilding />}
      />
      <StyledTreeItem
        nodeId="105"
        label="APB - Austin Peay Bldg"
        icon={<AtBuilding />}
      />
      <StyledTreeItem
        nodeId="106"
        label="BCC - Frieson Black Cultural Center"
        icon={<AtBuilding />}
      />
      <StyledTreeItem
        nodeId="1"
        label="SU - Student Union"
        icon={<AtBuilding />}
      >
        <StyledTreeItem
          nodeId="2"
          label="SU - Student Union - Floor 1"
          icon={<AtFloor />}
        />
        <StyledTreeItem
          nodeId="21"
          label="SU - Student Union - Floor 2"
          icon={<AtRoom />}
        />
        <StyledTreeItem
          nodeId="22"
          label="SU - Student Union - Floor 3"
          icon={<AtRoom />}
        />
        <StyledTreeItem
          nodeId="23"
          label="SU - Student Union - Floor G1"
          icon={<AtRoom />}
        />
        <StyledTreeItem
          nodeId="24"
          label="SU - Student Union - Floor G2"
          icon={<AtRoom />}
        />
        <StyledTreeItem
          nodeId="25"
          label="SU - Student Union - Floor G3"
          icon={<AtRoom />}
        />
      </StyledTreeItem>
    </TreeView>
  );
};

const AssetSelector: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="column"
      alignContent="flex-start"
      alignItems="center"
      mx={2}
    >
      <Button
        id="button-a"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={
          <Icon
            icon={AtChevronDown}
            size="24px"
            color={`${palette.neutral[100]}`}
          />
        }
      >
        SU - Student Union - Floor 2
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        color="secondary"
        disableScrollLock={true}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        mode="dark"
        elevation={24}
      >
        <AssetList />
      </StyledMenu>
      <Typography
        sx={{
          marginTop: "12px",
          marginLeft: "0px",
          textTransform: "uppercase",
          color: `${palette.neutral[400]}`,
          fontSize: "11px",
        }}
      >
        Est. Total Occupancy: 43
      </Typography>
    </Stack>
  );
};

const AssetSelectorMenu: React.FC<{}> = () => {
  return (
    <>
      <Stack direction="row" alignContent="flex-start" sx={{ width: "100%" }}>
        <LegendContainer>
          <img
            src={"/images/legend-placeholder.svg"}
            alt={"legend placeholder"}
            width={"31px"}
          />
        </LegendContainer>
        <AssetSelector />
      </Stack>
    </>
  );
};

export default AssetSelectorMenu;

const StyledMenu = styled(Menu)`
  & .MuiList-root {
    width: 380px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: ${palette.neutral[700]};
  }
`;
const StyledTreeItem = styled(TreeItem)`
  color: white;
  padding: 0;
  width: 100%;

  & .MuiTreeItem-label {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .MuiTreeItem-content {
    padding: 8px 16px;
    transition: all 0;

    &:hover,
    &:focus,
    &.Mui-focused {
      background-color: ${alpha(palette.neutral[600], 0.5)};
      box-shadow: -100px 0 0 ${alpha(palette.neutral[600], 0.5)};
    }

    &.Mui-selected {
      background-color: ${palette.neutral[600]};
      box-shadow: -100px 0 0 ${palette.neutral[600]};
    }

    &.Mui-selected.Mui-focused,
    &.Mui-selected:hover {
      background-color: ${alpha(palette.neutral[600], 0.75)};
      box-shadow: -100px 0 0 ${alpha(palette.neutral[600], 0.75)};
    }

    & svg {
      fill: ${palette.neutral[200]};
    }
  }
`;
const LegendContainer = styled("div")`
  width: 48px;
  display: block;
  position: relative;
  & img {
    position: absolute;
    left: 0;
  }
`;
