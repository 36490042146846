import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { TableCell, TableRow } from "@mui/material";
import {
  Editable,
  OptionalProperty,
  PercentProperty,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import * as n from "fp-ts/number";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { NumberEditor } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/editors";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";

export const TargetUtilizationPropertyRow: React.FC<{
  asset: Asset;
  onChange: (targetUtilization: number, inheritance: boolean) => void;
}> = (props) => (
  <>
    <TableRow key={"targetUtilizationRow"} sx={{ border: 0 }}>
      <TableCell
        component="th"
        scope="row"
        sx={{ borderBottom: 0, width: "30%" }}
      >
        Target Utilization
      </TableCell>
      <TableCell align="right" sx={{ borderBottom: 0 }}>
        {/*<Inheritable*/}
        {/*  asset={props.asset}*/}
        {/*  value={props.asset.targetUtilization}*/}
        {/*  eq={n.Eq}*/}
        {/*  lookup={getTargetUtilization}*/}
        {/*>*/}
        <Editable
          onChange={props.onChange}
          value={pipe(
            props.asset.targetUtilization,
            O.map(toPercentage),
            O.getOrElseW(() => 0)
          )}
          eq={n.Eq}
          editor={NumberEditor}
          inheritanceAsset={props.asset}
        >
          <OptionalProperty
            value={props.asset.targetUtilization}
            component={PercentProperty}
            required={true}
          />
        </Editable>
        {/*</Inheritable>*/}
      </TableCell>
    </TableRow>
  </>
);
