import * as Eq from "fp-ts/Eq";
import { getEq } from "lib/at-data/Slice";
import { eqUUID } from "lib/at-data/UUID";
import * as Rec from "fp-ts/Record";
import { pipe } from "fp-ts/function";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { LambentArtifact } from "lib/at-data/Artifact";
import * as d from "fp-ts/Date";
import * as n from "fp-ts/number";

export const ImageArtifactEqByTimestamp = pipe(
  d.Eq,
  Eq.contramap((artifact: LambentArtifact) => artifact.timestamp)
);

export const ImageArtifactEqByPlane = pipe(
  Eq.tuple(n.Eq, n.Eq),
  Eq.contramap(
    (artifact: LambentArtifact) =>
      [artifact.planeWidth, artifact.planeHeight] as [number, number]
  )
);

export type LocalArtifactsControllerState = {
  artifacts: UUIDSlice<Record<string, LambentArtifact>>;
};

export const LocalArtifactsControllerStateEq =
  Eq.struct<LocalArtifactsControllerState>({
    artifacts: getEq(eqUUID, Rec.getEq(ImageArtifactEqByTimestamp)),
  });
