import * as C from "io-ts/Codec";
import * as Eq from "fp-ts/Eq";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { option } from "lib/codecs/option";
import { AssPTSumC } from "controllers/UIStateCollector/models/assetContextTabs";
import {
  AssetFilterModelEq,
  UIAssetsFilterModel,
} from "controllers/UIStateCollector/models/assetFilterModel";
import { AssetsPageTabEq } from "controllers/UIStateCollector/models/AssetsPageTab";
import { eqUUID, UUID } from "lib/at-data/UUID";
import {
  SelectedRangeModelEq,
  UISelectedRangeModel,
} from "controllers/UIStateCollector/models/SelectedRangeModel";
import * as A from "fp-ts/Array";
import { SelectedAssetIdsUIStateModel } from "controllers/UIStateCollector/models/SelectedAssetIdsUIStateModel";

export const AssetsPageUIStateFilterModel = pipe(
  UISelectedRangeModel,
  C.intersect(UIAssetsFilterModel)
);

export type AssetsPageUIStateFilter = C.TypeOf<
  typeof AssetsPageUIStateFilterModel
>;

export const AssetsPageUIStateModel = pipe(
  C.struct({
    selectedSideItem: pipe(AssPTSumC, option),
  }),
  C.intersect(SelectedAssetIdsUIStateModel),
  C.intersect(AssetsPageUIStateFilterModel)
);

export const AssetsUIStateEq = Eq.struct<AssetsPageUIState>({
  selectedAssetIds: A.getEq(eqUUID),
  selectedSideItem: O.getEq(AssetsPageTabEq),
  selectedRange: SelectedRangeModelEq,
  assetsFilter: AssetFilterModelEq,
});

export const LuciferPageUIStateEq = Eq.struct<AssetsPageUIState>({
  selectedAssetIds: A.getEq(eqUUID),
  selectedSideItem: O.getEq(AssetsPageTabEq),
  selectedRange: SelectedRangeModelEq,
  assetsFilter: AssetFilterModelEq,
});

export type AssetsPageUIState = C.TypeOf<typeof AssetsPageUIStateModel>;
