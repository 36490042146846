import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import Icon, {
  AtInsights,
  AtSchedule,
  AtMetrics,
  AtArrowUp,
  AtOccupancy,
  AtUsers,
} from "components/ui/Icon";
import palette from "theme/palette";
import * as UT from "lib/at-data/UtilizationThresholds";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as Tpl from "fp-ts/Tuple";
// import AssetsUtilizationTrendline from "../AssetsCharts/AssetsUtilizationTrendline";
import GaugeChart from "react-gauge-chart";
import { formatPercent } from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
import * as AD from "lib/at-data/AsyncData";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import { UsdCell } from "views/authenticated/assets/page/components/AssetsTable/cells";
import { isoUSD } from "lib/at-data/units/currency";
import { clog } from "../../../../../../lib/util";
import {
  CommaNumber,
  formatNumber,
} from "../../../../../../lib/formatters/formatNumber";
import * as b from "fp-ts/boolean";

const UtilizationItem: React.FC<{
  color: string;
  text: string;
  threshold: [number, number];
  className?: string;
}> = ({ color, text, threshold, className }) => {
  return (
    <ListItem
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "6px",
        margin: "2px 0",
        borderRadius: "2px",

        color: `${palette.neutral[450]}`,
        "&.active": {
          backgroundColor: `${palette.neutral[600]}`,

          color: `${palette.neutral[100]}`,
        },
      }}
      className={`${className}`}
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          fontSize: "12px",
          lineHeight: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Swatch sx={{ backgroundColor: `${color}` }} /> {text}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          lineHeight: "100%",
        }}
      >
        {threshold[0]}-{threshold[1]}%
      </Typography>
    </ListItem>
  );
};

const UtilizationPatterns: React.FC<{ thresholds: UT.UtilizationThresholds }> =
  (props) => {
    return (
      <List sx={{ width: "100%", backgroundColor: "transparent" }}>
        {pipe(
          props.thresholds,
          UT.sort,
          A.mapWithIndex((i, t) => (
            <UtilizationItem
              key={i}
              color={t.color}
              text={t.legend}
              threshold={pipe(
                t,
                UT.thresholdRange(pipe(props.thresholds, A.lookup(i + 1))),
                Tpl.bimap(
                  O.getOrElse(() => 100),
                  O.getOrElse(() => 0)
                )
              )}
            />
          ))
        )}
      </List>
    );
  };

const UtilizationGaugeChart: React.FC<{
  thresholds: UT.UtilizationThresholds;
  percent: number;
}> = ({ thresholds, percent }) => {
  const sortedThresholds = pipe(thresholds, UT.sort);
  const colors = pipe(
    sortedThresholds,
    A.map((t) => t.color)
  );
  const arcsLength = pipe(
    sortedThresholds,
    A.mapWithIndex((i, t) => {
      const thresholdRange = pipe(
        t,
        UT.thresholdRange(pipe(sortedThresholds, A.lookup(i + 1))),
        Tpl.bimap(
          O.getOrElse(() => 100),
          O.getOrElse(() => 0)
        )
      );
      return thresholdRange[1] - thresholdRange[0];
    })
  );

  return (
    <GaugeChart
      id="utilization-gauge"
      percent={percent}
      arcsLength={arcsLength}
      colors={colors}
      hideText={true}
      needleColor={`${palette.neutral[100]}`}
      needleBaseColor={`${palette.neutral[500]}`}
      arcPadding={0.02}
      arcWidth={0.1}
      cornerRadius={1}
    />
  );
};

export const UtilizationMetrics: React.FC<{
  target: O.Option<number>;
  average: AD.AsyncData<O.Option<number>>;
  trend: O.Option<number>;
}> = ({ target, average, trend }) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      alignContent="center"
      justifyContent="space-around"
    >
      {/* <Metric>
        <Value>
          {pipe(
            target,
            O.map(flow(toPercentage, formatPercent)),
            O.getOrElseW(() => "N/A")
          )}
        </Value>
        <Label>Target</Label>
      </Metric> */}
      <Metric>
        <Value>
          {pipe(
            average,
            AD.fold(
              () => "~",
              flow(
                O.flatten,
                O.filter(Number.isFinite),
                O.map(flow(toPercentage, Math.round, formatPercent)),
                O.getOrElseW(() => "N/A")
              )
            )
          )}
        </Value>
        <Label>Utilization Rate</Label>
      </Metric>

      {/* <Metric>
        <Value>
          {pipe(
            trend,
            O.map((v) => (
              <>
                {" "}
                <Icon
                  icon={AtArrowUp}
                  size="24px"
                  color={`${palette.neutral[100]}`}
                />
                {v}
              </>
            )),
            O.getOrElseW(() => "N/A")
          )}
        </Value>
        <Label>Trend</Label>
      </Metric> */}
    </Stack>
  );
};

export const TargetUtilization: React.FC<{
  target: O.Option<number>;
  average: AD.AsyncData<O.Option<number>>;
  trend: O.Option<number>;
}> = ({ target, average, trend }) => {
  return (
    <Stack direction="column">
      <Label width="30px" />
      <Stack
        direction="row"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Label sx={{ fontSize: "20px" }}>Target</Label>
        <Label width="15px" />
        <Metric>
          <Value sx={{ fontSize: "25px" }}>
            {pipe(
              target,
              O.map(flow(toPercentage, formatPercent)),
              O.getOrElseW(() => "N/A")
            )}
          </Value>
        </Metric>
      </Stack>
    </Stack>
  );
};

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const CapacityMetrics: React.FC<{
  capacity: number | string;
  isDefault: boolean;
}> = ({ capacity, isDefault }) => {
  return (
    <Stack spacing={1} px={3} py={2}>
      <Heading>
        <Icon
          icon={AtUsers}
          size="16px"
          color={`${palette.neutral[100]}`}
          sx={{ marginRight: "8px" }}
        />
        <Typography variant="h4" color={palette.neutral[100]}>
          Capacity
        </Typography>
      </Heading>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        alignContent="center"
        justifyContent="space-around"
      >
        {pipe(
          isDefault,
          b.fold(
            () => (
              <Metric>
                <Value>{capacity}</Value>
              </Metric>
            ),
            () => (
              <Tooltip
                title="Value is autogenerated based on default density ratios."
                sx={{ cursor: "default" }}
              >
                <Metric>
                  <Value sx={{ color: `${palette.neutral[500]}` }}>
                    {capacity}*
                  </Value>
                </Metric>
              </Tooltip>
            )
          )
        )}
      </Stack>
    </Stack>
  );
};

export const UnderuseMetrics: React.FC<{
  perSqFM: AD.AsyncData<O.Option<number>>;
  timeSelected: AD.AsyncData<number>;
}> = ({ perSqFM, timeSelected }) => {
  return (
    <Stack spacing={1} px={3} py={2}>
      <Heading>
        <Icon
          icon={AtMetrics}
          size="16px"
          color={`${palette.neutral[100]}`}
          sx={{ marginRight: "8px" }}
        />
        <Typography variant="h4" color={palette.neutral[100]}>
          Underutilization Cost
        </Typography>
      </Heading>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        alignContent="center"
        justifyContent="space-around"
      >
        <Metric>
          <Value>
            {pipe(
              perSqFM,
              AD.fold(
                () => <span>~</span>,
                flow(
                  O.flatten,
                  O.map((_) => Math.max(_, 0)),
                  O.map(isoUSD.wrap),
                  O.fold(
                    () => <span>N/A</span>,
                    (_) => <UsdCell value={O.some(_)} />
                  )
                )
              )
            )}
          </Value>
          <Label>Per Year</Label>
        </Metric>
        {/* <Metric>
          <Value>
            {pipe(
              timeSelected,
              AD.getData,
              O.getOrElseW(() => "N/A")
            )}
          </Value>
          <Label>For Time Selected</Label>
        </Metric> */}
      </Stack>
    </Stack>
  );
};

export const OccupancyMetrics: React.FC<{
  peak: AD.AsyncData<O.Option<number>>;
  average: AD.AsyncData<O.Option<number>>;
}> = ({ peak, average }) => {
  return (
    <Stack direction="column" spacing={1} px={3} py={2}>
      <Heading>
        <Icon
          icon={AtOccupancy}
          size="16px"
          color={`${palette.neutral[100]}`}
          sx={{ marginRight: "8px" }}
        />
        <Typography variant="h4" color={palette.neutral[100]}>
          Occupancy
        </Typography>
      </Heading>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        alignContent="center"
        justifyContent="space-around"
      >
        <AsyncMetric value={peak} label={"Peak"} />
        {/*<Tooltip*/}
        {/*  title="Considering all hours, not only operating hours"*/}
        {/*  sx={{ cursor: "default" }}*/}
        {/*>*/}
        <Metric>
          <Value>
            {pipe(
              average,
              AD.fold(
                () => "~",
                flow(
                  O.flatten,
                  O.map((_) => pipe(_, Math.round, formatNumber(CommaNumber))),
                  O.getOrElseW(() => "N/A")
                )
              )
            )}
          </Value>
          <Label>Mean</Label>
        </Metric>
        {/*Switch back to AsyncMetric when switching to consistent Mean:*/}
        {/*<AsyncMetric*/}
        {/*  value={average}*/}
        {/*  label={"Average"}*/}
        {/*  // subtitle={"(Not considering operating hours)"}*/}
        {/*/>*/}
        {/*</Tooltip>*/}
      </Stack>
    </Stack>
  );
};

export const AsyncMetric: React.FC<{
  value: AD.AsyncData<O.Option<number>>;
  label: string;
  // subtitle: string;
}> = (props) => (
  <Metric>
    <Value>
      {pipe(
        props.value,
        AD.fold(
          () => "~",
          flow(
            O.flatten,
            O.map((_) => pipe(_, Math.round, formatNumber(CommaNumber))),
            O.getOrElseW(() => "N/A")
          )
        )
      )}
    </Value>
    <Label>{props.label}</Label>
    {/*<Subtitle>{props.subtitle}</Subtitle>*/}
  </Metric>
);

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
const Heading = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
`;
const Metric = styled(Stack)`
  direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
const Swatch = styled("span")`
  width: 12px;
  height: 12px;
  margin: auto 0;
  margin-right: 8px;
  display: inline-flex;
  padding: 0;
`;

const Value = styled("span")`
  font-size: 36px;
  color: ${palette.neutral[100]};
  text-align: center;
  margin-top: -12px;
`;

const Label = styled(Typography)`
  font-size: 12px;
  color: ${palette.neutral[450]};
  margin-bottom: 12px;
`;

const Subtitle = styled(Typography)`
  font-size: 10px;
  color: ${palette.neutral[450]};
  margin-top: 10px;
`;
