import { groupIn } from "controllers/AssetsTableDataController/groupIn";
import * as b from "fp-ts/boolean";
import React, { useCallback, useState } from "react";
import { useCollectorOld } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
} from "@mui/material";
import * as A from "fp-ts/Array";
import {
  AssetModification,
  getAddTask,
  toAssetModifications,
} from "controllers/AssetsController/AssetModification";
import * as NEA from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import {
  AssetModificationServiceResponse,
  postAssetModificationsToService,
} from "controllers/AssetsController/AssetsController";
import { toCSV } from "components/navBar/modifications/lib";
import * as T from "fp-ts/Task";
import * as TE from "fp-ts/TaskEither";
import * as E from "fp-ts/Either";
import { UpdateStatus } from "components/navBar/modifications/UpdateStatus";
import palette from "theme/palette";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import * as AD from "lib/at-data/AsyncData";
import { LoadingButton } from "@mui/lab";
import { FeatureFlag } from "components/featureFlag/FeatureFlag";
import { DEPLOY_TO_PROD } from "constants/featureFlags";
import * as IO from "fp-ts/IO";
import { format } from "date-fns/fp";

const columns = [
  { name: "legacyZoneId", header: "Legacy Zone Id", defaultFlex: 1 },
  { name: "property", header: "Property", defaultFlex: 1 },
  { name: "type", header: "Type", defaultFlex: 1 },
  { name: "value", header: "Value", defaultFlex: 1 },
  { name: "valueType", header: "Value Type", defaultFlex: 1 },
];

const BATCH_SIZE = 5000;

export const EnvironmentSnapshot: React.FC<{
  assetsModifications: Array<AssetModification>;
}> = ({ assetsModifications }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [responses, setResponses] = useState<
    AD.AsyncData<E.Either<Error, AssetModificationServiceResponse[]>>
  >(AD.DoneData(O.none));
  const [finished, setFinished] = useState(0);
  const total = assetsModifications.length / BATCH_SIZE;

  const handleCloseConfirmation = useCallback(
    () => setConfirmationOpen(false),
    []
  );
  const handleOpenConfirmation = useCallback(
    () => setConfirmationOpen(true),
    []
  );

  const handleSubmitAssetModifications = useCallback(() => {}, [
    assetsModifications,
  ]);

  const handleDownloadCSV = useCallback(
    () =>
      pipe(assetsModifications, toCSV, (csvFile) => {
        const url = window.URL.createObjectURL(new Blob([csvFile]));
        const formattedDate = pipe(new Date(), format("mm-dd-yyyy"));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `assets-snapshot-${formattedDate}.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        // @ts-ignore
        link.parentNode.removeChild(link);
      }),
    [assetsModifications]
  );

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <ReactDataGrid
            style={{ height: 400 }}
            idProperty={"asset"}
            licenseKey="AppName=ArmoredThingsApp,Company=ArmoredThings,ExpiryDate=2023-03-08,LicenseDeveloperCount=2,LicenseType=single_app,Ref=ArmoredThingsLicenseRef,Z=874177969-88290940832246787443234957-812671145-2050061877"
            columns={columns}
            dataSource={pipe(
              assetsModifications,
              A.filterMap(
                flow(
                  getAddTask,
                  O.map(([legacyZoneId, type, property, value, valueType]) => ({
                    legacyZoneId,
                    value,
                    type,
                    property,
                    valueType,
                  }))
                )
              )
            )}
            showZebraRows={false}
            showCellBorders={false}
            pagination={true}
            showColumnMenuTool={false}
            columnMinWidth={75}
            sortable={true}
            allowUnsort={false}
            // defaultSortInfo={{ name: "assetMeanUtilization", dir: -1 }}
            // onEditComplete={handleEditComplete as any}
          />
        </CardContent>
        <CardActions>
          <FeatureFlag flag={DEPLOY_TO_PROD}>
            <LoadingButton
              loading={AD.isLoading(responses)}
              variant="contained"
              color="primary"
              onClick={handleOpenConfirmation}
            >
              Send to production environment
            </LoadingButton>
          </FeatureFlag>
          <Button variant="contained" onClick={handleDownloadCSV}>
            Download CSV File
          </Button>
          {pipe(
            responses,
            AD.isLoading,
            b.foldW(
              () => null,
              () => (
                <Box sx={{ width: "200px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={(finished / total) * 100}
                  />
                </Box>
              )
            )
          )}
          {pipe(
            responses,
            AD.getData,
            O.map(
              E.fold(
                () => <div>Error sending modifications</div>,
                (r) => <UpdateStatus result={r} />
              )
            ),
            O.getOrElseW(() => null)
          )}
        </CardActions>
      </Card>
      <Dialog
        open={confirmationOpen}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Production Promotion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will promote development assets state to production
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmation} autoFocus>
            Stop
          </Button>
          <Button
            onClick={handleSubmitAssetModifications}
            variant={"text"}
            color={"warning"}
          >
            Promote to Production
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export const EnvironmentSnapshotTab: React.FC<{}> = (props) => {
  const assets = useCollectorOld(AssetsController, flow(L.prop("assets")));
  const assetsModifications = pipe(
    assets,
    fromAD2,
    A.chain(toAssetModifications),
    NEA.fromArray
  );

  return (
    <Stack>
      <Stack>
        {pipe(
          assetsModifications,
          O.foldW(
            () => <div>No Assets in this environment</div>,
            (am) => <EnvironmentSnapshot assetsModifications={am} />
          )
        )}
      </Stack>
    </Stack>
  );
};
