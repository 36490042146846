import { alpha, Box, styled } from "@mui/material";
import React, { useMemo } from "react";
import AssetsHeaderChart from "./AssetsHeaderChart";
import AssetsHeaderLiveCount from "./AssetsHeaderLiveCount";
import * as CD from "lib/at-data/ChartData";
import palette from "theme/palette";
import * as A from "fp-ts/lib/Array";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import { useController } from "lib/at-react/defineController";
import * as AD from "lib/at-data/AsyncData";
import * as D from "lib/at-data/DataSeries";
import { getOccupancyEstimate } from "lib/at-data/OccupancyEstimate";

import { AssetsHeaderChartController } from "controllers/AssetsHeaderChartController/AssetsHeaderChartController";
import { RootSeriesL } from "controllers/AssetsHeaderChartController/state";

const AssetsHeaderChartContainer: React.FC<{}> = () => {
  const [chartDataO] = useController(
    AssetsHeaderChartController,
    flow(RootSeriesL.get, AD.getData, O.map(D.map(getOccupancyEstimate)))
  );

  return (
    <StyledBox data-testid="assets-header-chart-container">
      {pipe(
        chartDataO,
        O.map(CD.fromNumberSeries(() => 0, "value")),
        O.map((cd) => (
          <div>
            <AssetsHeaderLiveContainer>
              {pipe(
                CD.lastSliceWithValueFromNumberSeries(cd),
                O.map((s) => <AssetsHeaderLiveCount slice={s} />),
                O.getOrElseW(() => null)
              )}
            </AssetsHeaderLiveContainer>
            <AssetsHeaderChart data={CD.numberSeriesWithLastValue(cd)} />
          </div>
        )),
        O.getOrElse(() => <div />)
      )}
    </StyledBox>
  );
};

export default AssetsHeaderChartContainer;

const StyledBox = styled(Box)`
  height: 100px;
  position: relative;
`;

const AssetsHeaderLiveContainer = styled(Box)`
  position: absolute;
  width: 350px;
  right: 0;
  z-index: 1;
  background-color: ${alpha(palette.neutral[800], 0.5)};
`;
