import { Box, keyframes, styled } from "@mui/material";
import { Duration, isFuture, min } from "date-fns";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import "chartjs-adapter-date-fns";
import { TIMEBAR_DRAG } from "constants/featureFlags";
import { SeriesL } from "contexts/SeriesContext";
import { hasFeatureFlag } from "controllers/AppController/IsFeatureFlagAvailable";
import { FeatureFlagController } from "controllers/FeatureFlagController/FeatureFlagController";
import {
  TimebarController,
  TimebarControllerComponent,
} from "controllers/TimebarController/TimebarController";
import { axisBottom, axisLeft } from "d3-axis";
import * as scale from "d3-scale";
import { select } from "d3-selection";
import {
  timeDay,
  timeHour,
  TimeInterval,
  timeMinute,
  timeMonth,
  timeSunday,
  timeYear,
} from "d3-time";
import { utcToZonedTime } from "date-fns-tz/fp";
import {
  differenceInSeconds,
  format,
  getHours,
  getMinutes,
  isBefore,
} from "date-fns/fp";
import {
  useStableCallback,
  useStableEffect,
  useStableMemo,
} from "fp-ts-react-stable-hooks";
import * as b from "fp-ts/boolean";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import * as NEA from "fp-ts/NonEmptyArray";
import * as n from "fp-ts/number";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Asset } from "lib/at-data/assets/Asset";
import * as AD2 from "lib/at-data/AsyncData2";
import * as D from "lib/at-data/DataSeries";
import { subSeries, toPairArray } from "lib/at-data/DataSeries";
import * as NS from "lib/at-data/NumberSeries";
import { NumberSeries } from "lib/at-data/NumberSeries";
import { getOccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import {
  byClosestRange,
  calculateResolution,
  durationToSeconds,
} from "lib/at-data/requests/temporal/FromNumberOfBuckets";
import {
  GranulatedSeriesRequest,
  GranulatedSeriesRequestEq,
  scaleAround,
  tripleRange,
} from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import {
  SeriesRequest,
  SeriesRequestEq,
  slideToNewStart,
} from "lib/at-data/requests/temporal/SeriesRequest";
import { roundDown } from "lib/at-data/Resolution";
import { useCollectorOld } from "lib/at-react/defineController";
import {
  useCollector,
  useCollectorDispatch,
  useCollectorWithDispatch,
} from "lib/at-react/hooks";
import { formatNumber, UINumber } from "lib/formatters/formatNumber";
import { clog, noop } from "lib/util";
import * as L from "monocle-ts/Lens";
import { isNonNegative } from "newtype-ts/lib/NonNegative";
import { isPositive } from "newtype-ts/lib/Positive";
import React, { useEffect, useRef } from "react";
import palette from "theme/palette";
import { throttle } from "throttle-debounce";
import {
  DESIRED_BAR_SIZE,
  TimelineGranulatedRangeCollector,
  TimelineRangeCollector,
  TimelineWidthCollector,
  WidthL,
} from "views/authenticated/activity/page/components/navigator/TimelineRangeCollector";
import { isOnOrAfter } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetBookings";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable);

const TimelineBox = styled("div")`
  margin-left: ${DESIRED_BAR_SIZE * 4.5}px;
  margin-right: ${DESIRED_BAR_SIZE * 3}px;
  position: relative;
  height: 105px;
  //overflow: hidden;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - ${DESIRED_BAR_SIZE * 2.5});
  //box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
  // background-color: ${palette.neutral[700]};
  //background-image: url("./images/temp-activity-timeline.svg");
  background-position: right;
  box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.5),
    inset 0 5px 5px -5px rgba(0, 0, 0, 0.5);
`;

const roundDateToSeconds =
  (periodInSeconds: number) =>
  (date: Date): Date =>
    pipe(
      date,
      differenceInSeconds(new Date(0)),
      (secondsSinceEpoch: number) =>
        1000 *
        Math.round(secondsSinceEpoch / periodInSeconds) *
        periodInSeconds,
      (_) => new Date(_)
    );
const floorDateToSeconds =
  (periodInSeconds: number) =>
  (date: Date): Date =>
    pipe(
      date,
      differenceInSeconds(new Date(0)),
      (secondsSinceEpoch: number) =>
        1000 *
        Math.floor(secondsSinceEpoch / periodInSeconds) *
        periodInSeconds,
      (_) => new Date(_)
    );

export const TOOLTIP_DATE_FORMAT = "MMM do h:mma";

export const formatIgnoringDST = (formatStr: string) => (date: Date) => {
  return pipe(date, format(formatStr));
};

export const TimelineTooltip: React.FC<{
  date: Date;
  nextDate: Date;
  value: number;
}> = (props) => {
  const now = new Date();
  const isPartialBar = props.nextDate.valueOf() > now.valueOf();

  return (
    <div>
      <div>
        {pipe(props.date, format(TOOLTIP_DATE_FORMAT))} -{" "}
        {pipe(
          isPartialBar,
          b.fold(
            () => pipe(props.nextDate, format(TOOLTIP_DATE_FORMAT)),
            () => "Now"
          )
        )}
      </div>
      <div>
        Mean Occupancy (unfiltered):{" "}
        <strong>
          {pipe(props.value, clog("TOOLTIP OCC"), formatNumber(UINumber))}
        </strong>
      </div>
    </div>
  );
};

export const Timeline: React.FC<{
  asset: Asset;
  selectedRange: SeriesRequest;
  onSelectedRangeChange: (range: SeriesRequest) => void;
  onSelectedSliceChange: (slice: SeriesRequest) => void;
  barSize: number;
}> = ({ barSize, ...props }) => {
  const featureFlags = useCollectorOld(
    FeatureFlagController,
    L.prop("featureFlags")
  );

  const timeline: any = React.useRef();
  const timelineBox: any = React.useRef();

  const [width, widthDispatch] = useCollectorWithDispatch(
    TimelineWidthCollector,
    L.composeLens(WidthL)
  );

  const granulatedTimelineRange = useCollector(
    TimelineGranulatedRangeCollector
  );
  const timelineRangeDispatch = useCollectorDispatch(TimelineRangeCollector);

  const timeAxisScale = useStableMemo(
    () =>
      scale
        .scaleTime()
        .domain(
          pipe(granulatedTimelineRange, tripleRange, ({ start, end }) => [
            start,
            end,
          ])
        )
        .range([0, -width * 3]),
    [granulatedTimelineRange, width],
    Eq.tuple(SeriesRequestEq, n.Eq)
  );

  const currentResolution = useRef(granulatedTimelineRange.resolution);
  currentResolution.current = granulatedTimelineRange.resolution;

  const handleTimelineZoomOut = useStableCallback(
    (ev: any) => {
      if (pipe(featureFlags, hasFeatureFlag(TIMEBAR_DRAG))) {
        pipe(
          barSize,
          O.fromPredicate(isPositive),
          O.map((bs) =>
            Math.round((ev.event.x - ev.target.getBoundingClientRect().x) / bs)
          ),
          O.fold(
            () => noop(),
            (offset) =>
              pipe(
                pipe(
                  SeriesL,
                  L.modify(scaleAround(offset, currentResolution.current, -1))
                ),

                timelineRangeDispatch
              )
          )
        );
      }
    },
    [barSize, timelineRangeDispatch],
    Eq.tuple(n.Eq, Eq.eqStrict)
  );

  const handleTimelineZoomIn = useStableCallback(
    (ev: any) => {
      if (pipe(featureFlags, hasFeatureFlag(TIMEBAR_DRAG))) {
        pipe(
          barSize,
          O.fromPredicate(isPositive),
          O.map((bs) =>
            Math.round((ev.event.x - ev.target.getBoundingClientRect().x) / bs)
          ),
          O.fold(
            () => noop(),
            (offset) =>
              pipe(
                pipe(
                  SeriesL,
                  L.modify(scaleAround(offset, currentResolution.current, 1))
                ),

                timelineRangeDispatch
              )
          )
        );
      }
    },
    [barSize, timelineRangeDispatch],
    Eq.tuple(n.Eq, Eq.eqStrict)
  );

  const throttledSelectedRangeChange = useRef(
    throttle(
      250,
      (range: SeriesRequest) => {
        props.onSelectedRangeChange(range);
      },
      { noLeading: true }
    )
  );

  const throttledWidthChange = useRef(
    throttle(
      250,
      (newWidth: number) => {
        pipe(newWidth, WidthL.set, widthDispatch);
      },
      { noLeading: true }
    )
  );

  useEffect(() => {
    if (timelineBox.current) {
      const resizeObserver = new ResizeObserver(() => {
        const elWidth = gsap.getProperty(
          timelineBox.current,
          "width"
        ) as number;
        throttledWidthChange.current(elWidth);
      });
      resizeObserver.observe(timelineBox.current);
      return () => resizeObserver.disconnect(); // clean up
    }
  }, [timelineBox, widthDispatch]);

  useStableEffect(
    () => {
      throttledSelectedRangeChange.current(granulatedTimelineRange);
    },
    [granulatedTimelineRange],
    Eq.tuple(GranulatedSeriesRequestEq)
  );

  useStableEffect(
    () => {
      gsap.to(timeline.current, {
        x: timeAxisScale(pipe(granulatedTimelineRange.start)),
        granularity: 0,
      });
    },
    [granulatedTimelineRange, width, timeline],
    Eq.tuple(SeriesRequestEq, n.Eq, Eq.eqStrict)
  );

  useStableEffect(
    () => {
      if (pipe(featureFlags, hasFeatureFlag(TIMEBAR_DRAG))) {
        Draggable.create(timeline.current, {
          bounds: {
            top: 0,
            left: 0,
            width,
            height: 0,
          },
          inertia: true,
          liveSnap: (val: number) =>
            pipe(
              val,
              timeAxisScale.invert,
              roundDateToSeconds(granulatedTimelineRange.resolution),
              timeAxisScale
            ),
          type: "x",
          onDrag() {
            const newX = gsap.getProperty(this, "x") as number;
            const newStart = pipe(newX, timeAxisScale.invert);

            // gsap.to(`#bar-${newStart.valueOf()}`, {
            //   fill: palette.primary[400],
            //   opacity: 1,
            // });
          },
          onDragEnd() {
            const newX = gsap.getProperty(this, "x") as number;
            const snapDate = pipe(
              newX,
              timeAxisScale.invert,
              roundDateToSeconds(granulatedTimelineRange.resolution)
            );
            pipe(
              pipe(SeriesL, L.modify(slideToNewStart(snapDate))),
              timelineRangeDispatch
            );
          },
        });
      }
    },
    [granulatedTimelineRange, timeline, timelineBox, timeAxisScale, width],
    Eq.tuple(SeriesRequestEq, Eq.eqStrict, Eq.eqStrict, Eq.eqStrict, n.Eq)
  );

  useStableEffect(
    () => {
      ScrollTrigger.observe({
        target: timelineBox.current,
        type: "wheel,touch",
        onUp: handleTimelineZoomIn,
        onDown: handleTimelineZoomOut,
      });
    },
    [timeline, timelineBox],
    Eq.tuple(Eq.eqStrict, Eq.eqStrict)
  );

  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <TimebarControllerComponent
        context={{
          asset: props.asset,
          series: pipe(
            props.selectedRange,
            calculateResolution
            // tripleRange
          ),
          // series: pipe(
          //   props.selectedRange,
          //
          //   calculateResolution(width / barSize)
          //   // tripleRange
          // ),
        }}
      >
        {/*<TooltipComp ref={tooltip}>*/}
        {/*  <TimelineTooltip />*/}
        {/*</TooltipComp>*/}
        <TimelineVerticalScale selectedRange={granulatedTimelineRange} />
        <TimelineBox ref={timelineBox}>
          <WholeTimeline ref={timeline} style={{ width: width * 3 }}>
            <TimelineChart
              selectedRange={granulatedTimelineRange}
              barSize={barSize}
              width={width * 3}
              onSelectedSliceChange={props.onSelectedSliceChange}
            />
            <LiveIndicatorLine
              liveposition={Math.abs(timeAxisScale(new Date()))}
            />
            <LiveIndicator liveposition={Math.abs(timeAxisScale(new Date()))} />
          </WholeTimeline>
        </TimelineBox>
      </TimebarControllerComponent>
    </div>
  );
};

export const TimelineVerticalScale: React.FC<{ selectedRange: SeriesRequest }> =
  (props) => {
    const chartData = useCollectorOld(
      TimebarController,
      L.prop("timebarSeries")
    );
    const maxValue = pipe(
      chartData,
      AD2.map(
        flow(
          D.map(getOccupancyEstimate),
          subSeries(props.selectedRange.start, props.selectedRange.end),
          NS.max
        )
      ),
      AD2.toOption,
      O.getOrElse(() => 0)
    );
    const valuesScale = scale
      .scaleLinear()
      .domain([0, maxValue])
      .range([CHART_HEIGHT - SERIES_CHART_MARGIN.bottom, 0]);
    return (
      <VerticalScale>
        <svg width={DESIRED_BAR_SIZE * 2.5} height={CHART_HEIGHT}>
          <CountsAxis
            transform={`translate(${DESIRED_BAR_SIZE * 2.5}, 0)`}
            scale={valuesScale}
          />
        </svg>
      </VerticalScale>
    );
  };

const VerticalScale = styled("div")`
  height: 105px;
  position: absolute;
  top: 0;
  width: ${DESIRED_BAR_SIZE * 2.5}px;
  background: ${palette.neutral[600]};
  opacity: 0.9;
  z-index: 10000;
`;

export const TimelineChart: React.FC<{
  selectedRange: GranulatedSeriesRequest;
  barSize: number;
  width: number;
  onSelectedSliceChange: (slice: SeriesRequest) => void;
}> = (props) => {
  const chartData = useCollectorOld(TimebarController, L.prop("timebarSeries"));
  return (
    <D3BarChart
      data={pipe(chartData, AD2.map(D.map(getOccupancyEstimate)))}
      barSize={props.barSize}
      width={props.width}
      selectedRange={props.selectedRange}
    />
  );
};

export enum AxisResolution {
  HALF_HOUR,
  HOUR,
  THREE_HOURS,
  SIX_HOURS,
  TWELVE_HOURS,
  DAY,
  THREE_DAYS,
  WEEK,
  MONTH,
}

export type LabelFormat = {
  frequency: TimeInterval;
  highlight: TimeInterval;
  formatFn: (val: Date) => string;
  highlightFormatFn: (val: Date) => string;
};

export const MINUTES = 60;
export const HOURS = 60 * MINUTES;

export const toAxisResolution = (resolution: number): AxisResolution => {
  if (resolution >= 24 * HOURS) return AxisResolution.MONTH;
  if (resolution > 12 * HOURS) return AxisResolution.WEEK;
  else if (resolution >= 6 * HOURS) return AxisResolution.DAY;
  else if (resolution >= 3 * HOURS) return AxisResolution.TWELVE_HOURS;
  else if (resolution >= 1 * HOURS) return AxisResolution.SIX_HOURS;
  else if (resolution >= 30 * MINUTES) return AxisResolution.THREE_HOURS;
  else if (resolution >= 15 * MINUTES) return AxisResolution.HOUR;
  else return AxisResolution.HALF_HOUR;
};
export const isMidnight = (date: Date) =>
  getHours(date) === 0 && getMinutes(date) === 0;

export const labelFormats: Record<AxisResolution, LabelFormat> = {
  [AxisResolution.HALF_HOUR]: {
    frequency: timeMinute.every(30) as TimeInterval,
    formatFn: format("hh:mm"),
    highlight: timeHour.every(1) as TimeInterval,
    highlightFormatFn: (date) =>
      pipe(
        date,
        isMidnight,
        b.fold(
          () => pipe(date, format("ha")),
          () => pipe(date, format("MMM do"))
        )
      ),
  },
  [AxisResolution.HOUR]: {
    frequency: timeHour.every(1) as TimeInterval,
    formatFn: format("hh:mm"),
    highlight: timeDay.every(1) as TimeInterval,
    highlightFormatFn: format("MMM do"),
  },
  [AxisResolution.THREE_HOURS]: {
    frequency: timeHour.every(3) as TimeInterval,
    formatFn: format("ha"),
    highlight: timeDay.every(1) as TimeInterval,
    highlightFormatFn: format("MMM do"),
  },
  [AxisResolution.SIX_HOURS]: {
    frequency: timeHour.every(6) as TimeInterval,
    formatFn: format("ha"),
    highlight: timeDay.every(1) as TimeInterval,
    highlightFormatFn: format("MMM do"),
  },
  [AxisResolution.TWELVE_HOURS]: {
    frequency: timeHour.every(12) as TimeInterval,
    formatFn: format("ha"),
    highlight: timeDay.every(1) as TimeInterval,
    highlightFormatFn: format("MMM do"),
  },
  [AxisResolution.DAY]: {
    frequency: timeDay.every(1) as TimeInterval,
    formatFn: format("eee"),
    highlight: timeSunday.every(1) as TimeInterval,
    highlightFormatFn: format("MMM do"),
  },
  [AxisResolution.THREE_DAYS]: {
    frequency: timeDay.every(3) as TimeInterval,
    formatFn: format("eee"),
    highlight: timeMonth.every(1) as TimeInterval,
    highlightFormatFn: format("MMM"),
  },
  [AxisResolution.WEEK]: {
    frequency: timeSunday.every(1) as TimeInterval,
    formatFn: format("do"),
    highlight: timeMonth.every(1) as TimeInterval,
    highlightFormatFn: format("MMM"),
  },
  [AxisResolution.MONTH]: {
    frequency: timeMonth.every(1) as TimeInterval,
    formatFn: format("MMM"),
    highlight: timeYear.every(1) as TimeInterval,
    highlightFormatFn: format("yyyy"),
  },
};

export const labelFormatsBySelectedRange: NEA.NonEmptyArray<{
  selectedRange: Duration;
  format: LabelFormat;
}> = [
  {
    selectedRange: { days: 1 },
    format: labelFormats[AxisResolution.THREE_HOURS],
  },
  {
    selectedRange: { days: 2 },
    format: labelFormats[AxisResolution.THREE_HOURS],
  },
  {
    selectedRange: { weeks: 1 },
    format: labelFormats[AxisResolution.SIX_HOURS],
  },
  {
    selectedRange: { weeks: 2 },
    format: labelFormats[AxisResolution.TWELVE_HOURS],
  },
  {
    selectedRange: { days: 32 },
    format: labelFormats[AxisResolution.DAY],
  },

  {
    selectedRange: { months: 2 },
    format: labelFormats[AxisResolution.WEEK],
  },
  {
    selectedRange: { months: 6 },
    format: labelFormats[AxisResolution.MONTH],
  },
];

export const isChangeOfInterval =
  (tickInterval: TimeInterval, highlightInterval: TimeInterval) =>
  (val: Date, i: number): boolean => {
    if (i === 0) {
      return true;
    } else {
      const prevDate = tickInterval.offset(val, -1);
      const isChange =
        highlightInterval.floor(val).getTime() !==
        highlightInterval.floor(prevDate).getTime();

      return isChange;
    }
  };

export const overlap = (
  boxA: { x: number; width: number },
  boxB: { x: number; width: number }
): boolean => {
  return boxA.x < boxB.x + boxB.width && boxA.x + boxA.width > boxB.x;
};

export const formatStartEnd = (date: Date) => pipe(date, format("MMM do"));

export const TimeLineAxis: React.FC<{
  scale: any;
  transform: string;
  selectedRange: GranulatedSeriesRequest;
}> = (props) => {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    if (ref.current) {
      const labelingStrategy = pipe(
        labelFormatsBySelectedRange,
        A.findFirst(byClosestRange(props.selectedRange)),
        O.getOrElse(() => pipe(labelFormatsBySelectedRange, NEA.last)),
        (_) => _.format
      );

      const axis = select(ref.current).call(
        axisBottom<Date>(props.scale)
          .ticks(labelingStrategy.frequency)
          // .tickValues(ticks)
          .tickFormat((val, i) =>
            pipe(
              isChangeOfInterval(
                labelingStrategy.frequency,
                labelingStrategy.highlight
              )(val, i),
              b.fold(
                () => labelingStrategy.formatFn(val),
                () => labelingStrategy.highlightFormatFn(val)
              )
            )
          )
      );

      axis
        .selectAll(".start-element")
        .data([props.selectedRange.start])
        .join(
          (enter) => {
            const g = enter
              .append("g")
              .attr("class", "start-element start-end")
              .attr("transform", (d) => `translate(${props.scale(d)},0)`);

            g.append("line")
              .attr("x1", `0`)
              .attr("y1", `0`)
              .attr("x2", `0`)
              .attr("y2", `10`)
              .style("stroke", palette.neutral[100]);

            g.append("text")
              .attr("y", 12)
              .attr("x", 0)
              .attr("dy", "0.71em")
              .attr("text-anchor", "middle")
              .attr("fill", palette.neutral[100])
              .attr("font-weight", "bold")
              .attr("stroke", "none")
              .text(formatStartEnd);
            return g;
          },

          // For the update selection, adjust the position of the group, and the text.
          (update) => {
            update.attr("transform", (d) => `translate(${props.scale(d)},0)`);
            update.select("text").text(formatStartEnd);
            return update;
          }
        );

      axis
        .selectAll(".end-element")
        .data([props.selectedRange.end])
        .join(
          (enter) => {
            const g = enter
              .append("g")
              .attr("class", "end-element start-end")
              .attr("transform", (d) => `translate(${props.scale(d) - 110},0)`);

            g.append("line")
              .attr("x1", 110)
              .attr("y1", 0)
              .attr("x2", 110)
              .attr("y2", 10)
              .style("stroke", palette.neutral[100]);

            g.append("text")
              .attr("y", 12)
              .attr("x", 110)
              .attr("dy", "0.71em")
              .attr("text-anchor", "middle")
              .attr("fill", palette.neutral[100])
              .attr("font-weight", "bold")
              .style("stroke", "none")
              .text(formatStartEnd);
            return g;
          },

          // For the update selection, adjust the position of the group, and the text.
          (update) => {
            update.attr(
              "transform",
              (d) => `translate(${props.scale(d) - 110},0)`
            );
            update.select("text").text(formatStartEnd);
            return update;
          }
        );

      axis.selectAll("g.tick > text").attr("fill", palette.neutral[100]);
      axis.selectAll("g.tick > text").attr("font-weight", (val, i) =>
        pipe(
          val as Date,
          isFuture,
          b.fold(
            () =>
              pipe(
                isChangeOfInterval(
                  labelingStrategy.frequency,
                  labelingStrategy.highlight
                )(val as Date, i),
                b.fold(
                  () => "normal",
                  () => "bold"
                )
              ),
            () => "normal"
          )
        )
      );

      const tickBoundingBoxes = axis
        .selectAll(".tick")
        .nodes()
        .map((node: any) => {
          const box = node.getBBox();
          const matrix = node.getScreenCTM();
          return {
            x: matrix.e + box.x,
            width: box.width,
          };
        });

      const customBoundingBoxes = axis
        .selectAll(".start-end")
        .nodes()
        .map((node: any) => {
          const box = node.getBBox();
          const matrix = node.getScreenCTM();
          return {
            x: matrix.e + box.x,
            width: box.width,
          };
        });

      const ticksToRemove = pipe(
        customBoundingBoxes,
        A.filterMap((cbb) =>
          pipe(
            tickBoundingBoxes,
            A.findIndex((tbb) => overlap(tbb, cbb))
          )
        )
      );

      axis
        .selectAll(".tick")
        .filter((d, i) => ticksToRemove.includes(i))
        .remove();

      axis.select(".domain").attr("stroke", palette.neutral[200]);

      axis.selectAll("line").attr("stroke", palette.neutral[200]);
    }
  }, [props.scale, props.selectedRange.resolution]);

  return (
    <>
      <g ref={ref} transform={props.transform} />
    </>
  );
};
const CHART_HEIGHT = 105;
export const CountsAxis: React.FC<{
  scale: any;
  transform: string;
}> = (props) => {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    if (ref.current) {
      const axis = select(ref.current).call(
        axisLeft<number>(props.scale)
          .ticks(3)
          .tickFormat(formatNumber(UINumber))
      );

      axis.select(".domain").attr("stroke", "none");
      axis.selectAll("text").attr("fill", palette.neutral[200]);
      axis.selectAll("line").attr("stroke", palette.neutral[200]);
    }
  }, [props.scale]);

  return (
    <>
      <g ref={ref} transform={`translate(35, 4)`} />
    </>
  );
};

export const isInRange = (range: GranulatedSeriesRequest) => (date: Date) =>
  pipe(date, isOnOrAfter(pipe(range.start, roundDown(range.resolution)))) &&
  pipe(date, isBefore(range.end));

export const SERIES_CHART_MARGIN = { top: 10, right: 0, bottom: 25, left: 0 };

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.neutral[900],
    opacity: 0.8,
    color: palette.neutral[100],
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const D3BarChart: React.FC<{
  selectedRange: GranulatedSeriesRequest;
  data: AD2.AsyncData2<NumberSeries>;
  width: number;
  barSize: number;
}> = (props) => {
  const width =
    props.width - SERIES_CHART_MARGIN.left - SERIES_CHART_MARGIN.right;
  const height =
    CHART_HEIGHT - SERIES_CHART_MARGIN.top - SERIES_CHART_MARGIN.bottom;

  const scaleSeries = pipe(props.selectedRange, tripleRange);

  const timeAxisScale = scale
    .scaleTime()
    .domain([scaleSeries.start, scaleSeries.end])
    .range([0, width]);

  const maxValue = pipe(
    props.data,
    AD2.map(
      flow(
        subSeries(props.selectedRange.start, props.selectedRange.end),
        NS.max
      )
    ),
    AD2.toOption,
    O.getOrElse(() => 0)
  );
  const scaleY = scale.scaleLinear().domain([0, maxValue]).range([height, 0]);

  return (
    <svg
      width={width + SERIES_CHART_MARGIN.left + SERIES_CHART_MARGIN.right}
      height={height + SERIES_CHART_MARGIN.top + SERIES_CHART_MARGIN.bottom}
    >
      <g
        transform={`translate(${SERIES_CHART_MARGIN.left}, ${SERIES_CHART_MARGIN.top})`}
      >
        {pipe(
          props.data,
          AD2.foldW(
            () => <div />,
            () => <div />,
            (data) =>
              pipe(
                data,
                toPairArray,
                (pairArray) =>
                  pipe(
                    pairArray,
                    A.zip(
                      pipe(
                        pairArray,
                        A.tail,
                        O.map(A.append([data.end, 0])),
                        O.getOrElse(() => [[data.end, 0]])
                      )
                    )
                  ),
                A.mapWithIndex(
                  (i, [[dateVal, value], [nextDateVal, nextValue]]) => {
                    const dateOffset =
                      new Date().getTimezoneOffset() -
                      dateVal.getTimezoneOffset();
                    const nextDateOffset: number =
                      new Date().getTimezoneOffset() -
                      // @ts-ignore
                      nextDateVal.getTimezoneOffset();

                    const date = pipe(
                      dateVal
                      // subMinutes(dateOffset)
                    );

                    const nextDate = pipe(
                      nextDateVal
                      // subMinutes(nextDateOffset)
                    );

                    const xPos = pipe(timeAxisScale(date)) + 1;

                    const barWidth =
                      pipe(min([nextDate, new Date()]), timeAxisScale) -
                      timeAxisScale(date) -
                      2;

                    return (
                      <g key={`bar-${date}`}>
                        <rect
                          id={`bar-${date.getTime()}`}
                          x={xPos}
                          y={scaleY(value)}
                          width={pipe(
                            barWidth,
                            isNonNegative,
                            b.fold(
                              () => 0,
                              () => barWidth
                            )
                          )}
                          height={height - scaleY(value)}
                          opacity={pipe(
                            date,
                            isInRange(props.selectedRange),
                            b.fold(
                              () => 0.2,
                              () => 1
                            )
                          )}
                          fill={pipe(
                            date,
                            isInRange(props.selectedRange),
                            b.fold(
                              () => palette.neutral[400],
                              () => palette.primary[400]
                            )
                          )}
                        />
                        <DarkTooltip
                          title={
                            <TimelineTooltip
                              value={value}
                              date={date}
                              nextDate={nextDate as Date}
                            />
                          }
                          placement={"top"}
                        >
                          <rect
                            key={`hover-${date}`}
                            x={xPos}
                            y={scaleY(maxValue)}
                            width={pipe(
                              barWidth,
                              isNonNegative,
                              b.fold(
                                () => 0,
                                () => barWidth
                              )
                            )}
                            height={height - scaleY(maxValue)}
                            opacity={0}
                          />
                        </DarkTooltip>
                      </g>
                    );
                  }
                )
              ),
            () => <div>error</div>
          )
        )}
      </g>
      <TimeLineAxis
        transform={`translate(0, ${height + 10})`}
        scale={timeAxisScale}
        selectedRange={props.selectedRange}
      />
    </svg>
  );
};

const WholeTimeline = styled(Box)`
  height: ${CHART_HEIGHT}px;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  background-color: ${palette.neutral[700]};
`;

const pulsing = keyframes`
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const LiveIndicator = styled("div")<{ liveposition: number }>`
  position: absolute;
  left: -2.5px;
  top: 20px;
  height: 8px;
  width: 8px;
  background-color: #2ac243;
  border-radius: 100%;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    background-color: inherit;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    opacity: 0.8;
    animation: ${pulsing} 4s ease-out infinite;
  }

  &::before {
    animation-delay: -2s;
  }

  transform: translate3d(${(props) => props.liveposition}px, 0px, 0px);
  z-index: 2000;
`;

const LiveIndicatorLine = styled("div")<{ liveposition: number }>`
  position: absolute;
  left: 0;
  height: 60px;
  bottom: 25px;
  width: 2.5px;
  background-color: ${palette.primary[200]};
  opacity: 0.6;

  transform: translate3d(${(props) => props.liveposition}px, 0px, 0px);
  z-index: 2000;
`;
