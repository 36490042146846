import * as S from "fp-ts/string";
import * as Ord from "fp-ts/Ord";
import { Eq } from "fp-ts/Eq";
import * as C from "io-ts/Codec";
import * as D from "io-ts/Decoder";
import * as En from "io-ts/Encoder";
import { pipe } from "fp-ts/function";

export interface UUIDBrand {
  readonly UUID: unique symbol;
}

export type UUID = string & UUIDBrand;

export const UUID = C.make(
  pipe(
    D.string,
    D.refine((_): _ is UUID => true, "UUID")
  ),
  pipe(En.id<string>())
);

/**
 * this is for test only clarity. strings should be admitted to UUID
 * brand only via UUID.decode
 */
export const of = (id: string) => id as UUID;

export const ordUUID = S.Ord as Ord.Ord<UUID>;

export const eqUUID: Eq<UUID> = { equals: ordUUID.equals };

export const getStringId = (uuid: UUID): string => uuid;
