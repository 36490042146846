import React from "react";

export const ETSULogo = () => (
  <div>
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_101_6098)">
        <path
          d="M85.3319 1.21101L82.2266 3.39387C80.0019 4.95688 77.2101 5.08757 74.3077 5.08757L9.32208 5.10037H7.34948L6.66099 6.96935L3.71005 14.9791L2.593 18.008L5.69835 18.7728C13.075 20.5876 14.6461 23.0429 14.666 24.7812L14.6674 39.8223V40.4835H14.6311V42.5437H10.519H8.65675L7.91312 44.2708L3.42602 54.7052L1.69922 58.7219H6.03191L14.6343 58.7233L14.6357 59.3941L14.6657 74.9255C14.6168 77.4525 10.2085 80.6551 6.23947 80.9865L2.49125 81.3004L3.81315 84.8599L6.69794 92.6238L7.39118 94.4864H9.35902L73.261 94.4913C79.5615 94.4913 84.2567 96.8526 84.3057 96.8782L87.2299 98.3711L88.2855 95.237L97.5069 67.5837L98.772 63.7932H94.8158H86.1566H84.3888L83.609 65.3944C79.771 73.2607 74.0423 77.3793 66.5839 77.6372L43.3448 77.6386V59.3885V58.7225H56.9114H58.4144L59.2604 57.4654C61.085 54.7536 64.0328 52.1405 67.5651 50.1094C68.772 49.4147 70.0198 48.806 71.266 48.3041L72.5028 47.807L72.9156 46.5244L73.6498 44.2427L74.8613 40.4809H70.9493H43.3443V39.8212V21.8582L67.1349 21.8611C74.4078 21.8611 77.7888 26.1694 81.8249 34.6503L82.5985 36.277H84.3836H93.0649H97.0195L95.7575 32.4882L86.5375 4.83268L85.3307 1.20953L85.3319 1.21101Z"
          fill="#FFC72C"
          stroke="#FFC72C"
          strokeWidth="2.67563"
        />
        <path
          d="M84.6398 5.64453C81.6748 7.7049 78.1489 7.85515 74.9803 7.85515L9.17389 7.86624L6.1876 15.888C9.00688 16.5744 17.3945 18.7201 17.4638 24.6669L17.466 39.7592L40.7546 39.7604V18.8946L67.7196 18.9002C77.3086 18.9002 81.3578 25.3887 85.1836 33.3354H93.9773L84.6373 5.64453H84.6398ZM17.4293 43.2967V45.3608H10.3876L5.84424 55.8088L57.3677 55.811C59.3734 52.856 62.628 49.8949 66.7322 47.5613C68.0979 46.7839 69.4751 46.1242 70.837 45.5817L71.5831 43.2967H17.4293V43.2967ZM17.4359 59.3515L17.4648 74.9021C17.3795 79.4022 11.3116 83.4309 6.29316 83.8442L9.21323 91.6175L73.9225 91.6232C81.1489 91.6232 86.4135 94.3268 86.4135 94.3268L95.7501 66.6382H86.982C84.2451 72.1843 78.5453 80.1365 67.2088 80.5004L40.7583 80.5038V59.3515H17.439L17.4359 59.3515Z"
          fill="#041E42"
        />
      </g>
      <defs>
        <clipPath id="clip0_101_6098">
          <rect width="100.421" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
