import { IconButton, Stack, Tooltip, Typography, styled } from "@mui/material";
import Icon, { AtSort, AtDotsMenu } from "components/ui/Icon";
import React from "react";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { AssetsTableHeaderContextMenu } from "views/authenticated/assets/page/components/AssetsTable/AssetsTableHeaderContextMenu";
import palette from "theme/palette";

interface ITableHeaderKpi {
  /**
   * Small label identifying the column name
   */
  label: string;
  /**
   * Small label identifying the unit measurements (i.e. avg, total, range, etc.)
   */
  hint?: string;
  /**
   * Custom value that supports the label with some generalized values based on the overall column data
   */
  value: O.Option<string | number>;
  /**
   * Optional icon to accompany the label
   */
  icon?: boolean;
  onContextClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const TableHeaderKpi: React.FC<ITableHeaderKpi> = (props) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ width: "100%", minHeight: "60px" }}
      >
        <Tooltip
          title={`${props.hint}`}
          sx={{ width: "100%" }}
          placement="top"
          arrow
        >
          <Stack
            direction="column"
            alignItems="flex-start"
            sx={{ width: "100%" }}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              noWrap
              sx={{ width: "100%" }}
            >
              {props.label}
            </Typography>

            {pipe(
              props.value,
              O.fold(
                () => null,
                (value) => (
                  <Typography
                    variant="h2"
                    color="textPrimary"
                    sx={{ width: "100%" }}
                  >
                    {value}
                  </Typography>
                )
              )
            )}
          </Stack>
        </Tooltip>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
          sx={{ width: "32px", minHeight: "60px" }}
        >
          <Icon icon={AtSort} size="12px" />
          {props.icon ? (
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation();
                props.onContextClick && props.onContextClick(ev);
              }}
              sx={{
                marginTop: "8px",
                zIndex: "500",
                padding: "0",
                borderRadius: "50%",
                "&.MuiButtonBase-root.MuiIconButton-root:hover": {
                  backgroundColor: `${palette.primary[200]}`,
                },
              }}
              aria-label="Filter Menu"
            >
              <Icon
                icon={AtDotsMenu}
                size="16px"
                sx={{ width: "32px", minHeight: "60px" }}
              />
            </IconButton>
          ) : null}
        </Stack>
      </Stack>
    </>
  );
};

export default TableHeaderKpi;

const Hint = styled("span")`
  font-size: 12px;
  color: #dddddd;
`;
