import * as AD2 from "lib/at-data/AsyncData2";
import { clog } from "lib/util";
import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as AD from "lib/at-data/AsyncData";
import * as D from "lib/at-data/DataSeries";
import { getOccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { OccupancyCell } from "views/authenticated/assets/page/components/AssetsTable/cells/OccupancyCell";
import * as A from "fp-ts/Array";
import * as NS from "lib/at-data/NumberSeries";
import { sum } from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
import { formatNumber, UINumber } from "lib/formatters/formatNumber";
import * as N from "fp-ts/number";
import * as Ord from "fp-ts/Ord";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";

const occupanciesMax = (
  adNumberSeriesArray: { mean: AD2.AsyncData2<O.Option<number>> }[]
): O.Option<number> =>
  pipe(
    adNumberSeriesArray,
    A.filterMap(flow((_) => _.mean, AD2.toOption, O.flatten)),
    sum,
    O.map(Math.round)
  );

export const getAssetPeakOccupancy = pipe(
  R.ask<AssetRowDataContext>(),
  R.map(({ asset, peakOccupancySlice }) =>
    pipe(peakOccupancySlice, AD.map(getSliceByUUID(asset.id)))
  )
);

export const getAssetOccupancySeries = pipe(
  R.ask<AssetRowDataContext>(),
  R.map(({ asset, occupancySpread }) =>
    pipe(
      occupancySpread,
      AD2.map(
        flow(getSliceByUUID(asset.id), O.map(D.map(flow(getOccupancyEstimate))))
      )
    )
  )
);

export const AssetOccupancyChartColumn = defineColumn(
  "assetOccupancyChart",
  pipe(
    R.Do,
    R.apSW("series", getAssetOccupancySeries),
    R.bindW("mean", ({ series }) =>
      pipe(series, AD2.map(O.chain(NS.mean(D.dataLength))), R.of)
    ),
    R.map(({ mean, series }) =>
      ROR.singleton("assetOccupancyChart", { series, mean })
    )
  ),
  R.of(OccupancyCell),
  flow(
    readColumnData,
    A.filterMap((r) => {
      return ROR.lookup("assetOccupancyChart")(r) as O.Option<{
        mean: AD2.AsyncData2<O.Option<number>>;
      }>;
    }),
    (value) => {
      return (
        <TableHeaderKpi
          label="Occupancy"
          hint="Average number of people in the space for the time period selected."
          value={pipe(value, occupanciesMax, O.map(formatNumber(UINumber)))}
        />
      );
    }
  ),
  R.of({
    defaultFlex: 0.7,
    sort: AD.getOrd(
      pipe(
        O.getOrd(N.Ord),
        Ord.contramap((_: { mean: number } | undefined) =>
          pipe(
            _,
            O.fromNullable,
            O.map((v) => v.mean)
          )
        )
      )
    ).compare,
  })
);
