import { pipe, flow } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Reader";
import { AssetContext } from "contexts/AssetContext";
import { Asset } from "lib/at-data/assets/Asset";
import * as AD from "lib/at-data/AsyncData";
import { calculateUnderUtilization } from "views/authenticated/assets/page/components/AssetsTable/columns/compare/UnderutilizationCostColumn";
import { calculateCost } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/CostColumn";

export const getUnderUtilizationCost = (
  utilizationO: AD.AsyncData<O.Option<number>>
) =>
  pipe(
    R.asks<AssetContext, Asset>((_) => _.asset),
    R.map(
      flow((asset) =>
        pipe(
          utilizationO,
          AD.map((utilization) =>
            pipe(
              O.Do,
              O.apS("costPerSqM", asset.costPerSqM),
              O.apS("area", asset.area),
              O.map(calculateCost),
              O.bindTo("cost"),
              O.apS("utilization", utilization),
              O.apS("targetUtilization", asset.targetUtilization),
              O.map(calculateUnderUtilization)
            )
          )
        )
      )
    )
  );
