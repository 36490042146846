import React from "react";
import { ColRight } from "../styles/Styles";
import Accordion from "../ui/Accordion";
import { DeployFormPresetBuildings } from "./DeployFormPresets";
import { DeployFormOperatingHoursBuildings } from "./DeployFormOperatingHours";
import { DeployFormUtilizationBuildings } from "./DeployFormUtilizations";
import { DeployFormCapacityBuildings } from "./DeployFormCapacities";
import { DeployFormTagsBuildings } from "./DeployFormTags";
import { DeployFormArtifactsBuildings } from "./DeployFormArtifacts";
import { DeploySideBarBuildings } from "./DeploySideBars";

export const Buildings: React.FC<{}> = (props) => {
  return (
    <>
      <DeploySideBarBuildings />
      <ColRight>
        <Accordion
          title={"Building Required Presets: r1-b1"}
          content={<DeployFormPresetBuildings />}
          active={true}
        />
        <Accordion
          title={"Hours of Operation"}
          content={<DeployFormOperatingHoursBuildings />}
          active={false}
        />
        <Accordion
          title={"Building Utilization Definitions"}
          content={<DeployFormUtilizationBuildings />}
          active={false}
        />
        <Accordion
          title={"Building Capacity Definitions"}
          content={<DeployFormCapacityBuildings />}
          active={false}
        />
        <Accordion
          title={"Building Tags"}
          content={<DeployFormTagsBuildings />}
          active={false}
        />
        <Accordion
          title={"Building Photos"}
          content={<DeployFormArtifactsBuildings />}
          active={false}
        />
      </ColRight>
    </>
  );
};
