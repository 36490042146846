import {
  AssetModification,
  getAddTask,
} from "controllers/AssetsController/AssetModification";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";

export const toCSV = (assetModifications: Array<AssetModification>) =>
  pipe(
    assetModifications,
    A.filterMap(getAddTask),
    A.map(
      flow(
        A.map((_) => `"${_}"`),
        (n) => n.join(",")
      )
    ),
    A.prepend("Legacy Zone ID,Task,Context,Value,Value Type,Description\n"),
    (_) => _.join("\n")
  );
