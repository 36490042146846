import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { BuildingAssetProperties } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/BuildingAssetProperties";
import { LevelAssetProperties } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/LevelAssetProperties";
import { ZoneAssetProperties } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/ZoneAssetProperties";

export const SpecificAssetProperties: React.FC<{ asset: Asset }> = (props) => {
  switch (props.asset.type) {
    case AssetTypes.BUILDING:
      return <BuildingAssetProperties asset={props.asset} />;
    case AssetTypes.FLOOR:
      return <LevelAssetProperties asset={props.asset} />;
    case AssetTypes.ZONE:
      return <ZoneAssetProperties asset={props.asset} />;
    default:
      return null;
  }
};
