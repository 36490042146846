import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { AppContext } from "contexts/AppContext";
import * as D from "io-ts/Decoder";
import * as C from "io-ts/Codec";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import { dateFromISOString } from "lib/codecs/dateFromISOString";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import * as n from "fp-ts/number";
import * as Ord from "fp-ts/Ord";
import { ReportType } from "views/authenticated/reports2/componenets/reportsTable/ReportType";
import { Predicate } from "fp-ts/Predicate";

export const ReportMetadataModel = C.struct({
  id: UUID,
  title: C.string,
  department: C.string,
  description: optionFromNullable(C.string),
  reportType: C.fromDecoder(
    D.union(
      D.literal(ReportType.RVO_REPORT),
      D.literal(ReportType.GRAPH_PACKS),
      D.literal(ReportType.HOURLY_EXPORT),
      D.literal(ReportType.OTHER_REPORT)
    )
  ),
  reportDate: C.fromDecoder(dateFromISOString),
});

export const ReportLinkResponseModel = C.struct({
  sasLink: C.string,
});

export const ByReportId =
  (id: UUID): Predicate<ReportMetadata> =>
  (r) =>
    eqUUID.equals(id, r.id);

export type ReportMetadata = C.TypeOf<typeof ReportMetadataModel>;
export type ReportLinkResponse = C.TypeOf<typeof ReportLinkResponseModel>;

export const OrdReportsByDate = pipe(
  n.Ord,
  Ord.contramap((r: ReportMetadata) => r.reportDate.valueOf())
);

export const ReportsResponseModel = C.struct({
  reports: C.array(ReportMetadataModel),
});

export const ReportLinksResponseModel = ReportLinkResponseModel;

export const handleResponse = (errorType: string) => (r: Response) => {
  if (r.ok) return r.json();
  else {
    throw new Error(`${errorType}: ${r.status} ${r.statusText} `);
  }
};

export const fetchReports = pipe(
  RTE.ask<AppContext & AbortingControllerContext>(),
  RTE.chainTaskEitherK((ctx) =>
    TE.tryCatch(
      () =>
        fetch(
          // `http://localhost:7071/api/archive/listReports`,
          `${process.env.REACT_APP_API_URL}/reports/archive/listReports`,
          {
            method: "POST",
            signal: ctx.abortingController.signal,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${ctx.appContext.accessToken}`,
            },
          }
        ).then(handleResponse("Accessing reportsSample API")),
      E.toError
    )
  ),
  RTE.chainEitherK(
    flow(
      ReportsResponseModel.decode,
      E.mapLeft((e) => {
        console.log(D.draw(e));
        return new Error("error parsing reports response");
      }),
      E.map((_) => _.reports)
    )
  )
);

export const fetchReportLinks = (reportId: UUID) =>
  pipe(
    RTE.ask<AppContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            `${process.env.REACT_APP_API_URL}/reports/archive/${reportId}/getReportLink`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ctx.appContext.accessToken}`,
              },
            }
          ).then(handleResponse("Accessing reportsSample API")),
        E.toError
      )
    ),
    RTE.chainEitherK(
      flow(
        ReportLinksResponseModel.decode,
        E.mapLeft((e) => {
          console.log(D.draw(e));
          return new Error("error parsing reportsSample response");
        })
      )
    )
  );

export type CreateReportRequest = {
  content: string;
  contentType: string;
  title: string;
  description: string;
  department: string;
  reportType: string;
  reportDate: string;
};

export const createReport = (reportRequest: CreateReportRequest) =>
  pipe(
    RTE.ask<AppContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            `${process.env.REACT_APP_API_URL}/reports/archive/createReport`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ctx.appContext.accessToken}`,
              },
              body: JSON.stringify(reportRequest),
            }
          ).then(handleResponse("Creating Reports")),
        E.toError
      )
    ),
    RTE.chainEitherK(
      flow(
        ReportMetadataModel.decode,
        E.mapLeft((e) => {
          console.log(D.draw(e));
          return new Error("Error Parsing Create Report Response");
        })
      )
    )
  );

export const deleteReport = (reportId: UUID) =>
  pipe(
    RTE.ask<AppContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            `${process.env.REACT_APP_API_URL}/reports/archive/${reportId}/delete`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ctx.appContext.accessToken}`,
              },
            }
          ).then(handleResponse("Deleting a Report")),
        E.toError
      )
    )
  );
