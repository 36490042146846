import React, { useCallback } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import palette from "theme/palette";
import {
  useCollectorOld,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { AppController } from "controllers/AppController/AppController";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import {
  hasFeatureFlag,
  isFeatureFlagAvailable,
} from "controllers/AppController/IsFeatureFlagAvailable";
import {
  COMPARE_FEATURE,
  DEPLOY_TO_PROD,
  EDIT_FEATURE,
  FLASH_INSIGHTS,
  REPORTS_FEATURE,
  TIMEBAR_DRAG,
  WIFI_OCCUPANCY,
} from "constants/featureFlags";
import { FeatureFlagController } from "controllers/FeatureFlagController/FeatureFlagController";
import * as S from "fp-ts/Set";
import * as s from "fp-ts/string";
import { not } from "fp-ts/Predicate";
import * as b from "fp-ts/boolean";
import { setItem } from "fp-ts-local-storage";
import { FeatureFlagL } from "controllers/FeatureFlagController/FeatureFlagL";
import { FeatureFlagsModel } from "controllers/FeatureFlagController/FeatureFlagsModel";

export const FeatureOption: React.FC<{
  flag: string;
  checked: boolean;
  label: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  const user = useCollectorOld(AppController, flow(L.prop("user")));
  return pipe(
    user,
    isFeatureFlagAvailable(props.flag),
    b.fold(
      () => null,
      () => (
        <FormLabel
          control={
            <Switch
              onChange={props.onChange}
              checked={props.checked}
              mode="light"
            />
          }
          label={props.label}
        />
      )
    )
  );
};

const SettingsPage: React.FC<{}> = (props) => {
  const featureFlags = useCollectorOld(
    FeatureFlagController,
    L.prop("featureFlags")
  );
  const dispatch = useControllerDispatch(FeatureFlagController);

  const handleChangeFeature = useCallback(
    (flag: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        pipe(
          FeatureFlagL,
          L.modify(
            flow(
              pipe(
                ev.target.checked,
                b.fold(
                  () => S.filter(not((_) => _ === flag)),
                  () => S.insert(s.Eq)(flag)
                )
              ),
              (_) => {
                const ls = pipe(
                  _,
                  S.toArray(s.Ord),
                  FeatureFlagsModel.encode,
                  JSON.stringify
                );
                setItem("featureFlags", ls)();
                return _;
              }
            )
          )
        )
      );
    },
    [dispatch]
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h1">Feature Settings</Typography>
      <Typography variant="body1">
        Select features for inclusion within the app.
      </Typography>
      <Form>
        <FeatureOption
          flag={REPORTS_FEATURE}
          checked={pipe(featureFlags, hasFeatureFlag(REPORTS_FEATURE))}
          label={"Show Reports Page"}
          onChange={handleChangeFeature(REPORTS_FEATURE)}
        />
        <FeatureOption
          flag={COMPARE_FEATURE}
          checked={pipe(featureFlags, hasFeatureFlag(COMPARE_FEATURE))}
          label={"Show Plan Page"}
          onChange={handleChangeFeature(COMPARE_FEATURE)}
        />
        <FeatureOption
          flag={EDIT_FEATURE}
          checked={pipe(featureFlags, hasFeatureFlag(EDIT_FEATURE))}
          label={"Asset Editing"}
          onChange={handleChangeFeature(EDIT_FEATURE)}
        />
        <FeatureOption
          flag={DEPLOY_TO_PROD}
          checked={pipe(featureFlags, hasFeatureFlag(DEPLOY_TO_PROD))}
          label={"Production Promotion"}
          onChange={handleChangeFeature(DEPLOY_TO_PROD)}
        />
        <FeatureOption
          flag={REPORTS_FEATURE}
          checked={pipe(featureFlags, hasFeatureFlag(WIFI_OCCUPANCY))}
          label={"Show WiFi Occupancy"}
          onChange={handleChangeFeature(WIFI_OCCUPANCY)}
        />
        <FeatureOption
          flag={FLASH_INSIGHTS}
          checked={pipe(featureFlags, hasFeatureFlag(FLASH_INSIGHTS))}
          label={"Flash Insights"}
          onChange={handleChangeFeature(FLASH_INSIGHTS)}
        />
        <FeatureOption
          flag={TIMEBAR_DRAG}
          checked={pipe(featureFlags, hasFeatureFlag(TIMEBAR_DRAG))}
          label={"Draggable Timebar"}
          onChange={handleChangeFeature(TIMEBAR_DRAG)}
        />
        {/*<Button*/}
        {/*  color="primary"*/}
        {/*  variant="contained"*/}
        {/*  sx={{ width: "260px", marginTop: "32px" }}*/}
        {/*>*/}
        {/*  Save Feature Settings*/}
        {/*</Button>*/}
      </Form>
    </Stack>
  );
};

export default SettingsPage;

const FormLabel = styled(FormControlLabel)`
  & > span:nth-of-type(2) {
    font-size: 1.6rem;
  }
  & > .Mui-checked + span {
    font-weight: 600;
  }
`;
const Form = styled(FormGroup)`
  background-color: ${palette.neutral[100]};
  padding: 24px;
  border-radius: 4px;
`;
