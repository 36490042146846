import { SeriesContext } from "contexts/SeriesContext";
import { VisibleAssetsContext } from "controllers/AssetsTableDataController/contexts/VisibleAssetsContext";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as NEA from "fp-ts/NonEmptyArray";
import * as n from "fp-ts/number";
import * as O from "fp-ts/Option";
import * as RTE from "fp-ts/ReaderTaskEither";
import { AnalyzeRow, fetchAssetsAnalyzeTE } from "lib/at-api/otter/analyze";
import { eqAssetsByUUIDOnly } from "lib/at-data/assets/assets";
import * as AD2 from "lib/at-data/AsyncData2";
import * as SR from "lib/at-data/requests/temporal/SeriesRequest";
import * as Sl from "lib/at-data/Slice";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import { defineAppController } from "lib/at-react/defineAppController";
import * as L from "monocle-ts/Lens";
import React from "react";
import { callIfDelayed, loggingAsyncDataEffect } from "../../lib/logging";

export type AssetsAnalyzeData2 = {
  analyzeData: AD2.AsyncData2<{
    meanOccupancySlice: UUIDSlice<number>;
    meanUtilizationSlice: UUIDSlice<O.Option<number>>;
    peakOccupancySlice: UUIDSlice<number>;
  }>;
};

export const eqAnalyzeAsyncData = AD2.getEq(
  Eq.struct({
    meanOccupancySlice: Sl.getEq(eqUUID, n.Eq),
    meanUtilizationSlice: Sl.getEq(eqUUID, O.getEq(n.Eq)),
    peakOccupancySlice: Sl.getEq(eqUUID, n.Eq),
  })
);

export const eqAssetsAnalyzeData2 = Eq.struct<AssetsAnalyzeData2>({
  analyzeData: eqAnalyzeAsyncData,
});

export const AnalyzeDataL = pipe(
  L.id<AssetsAnalyzeData2>(),
  L.prop("analyzeData")
);

export const prepareData = (response: UUIDSlice<AnalyzeRow>) => ({
  meanUtilizationSlice: pipe(
    response,
    Sl.map((_) => _.utilization)
  ),
  meanOccupancySlice: pipe(
    response,
    Sl.map((_) =>
      pipe(
        _.occupancyMean,
        O.getOrElse(() => 0)
      )
    )
  ),
  peakOccupancySlice: pipe(
    response,
    Sl.map((_) =>
      pipe(
        _.occupancyMax,
        O.getOrElse(() => 0)
      )
    )
  ),
});

export const AssetsAnalyzeDataL = pipe(
  L.id<AssetsAnalyzeData2>(),
  L.prop("analyzeData")
);

export const setAnalyzeData =
  (dispatch: React.Dispatch<React.SetStateAction<AssetsAnalyzeData2>>) =>
  (
    data: AD2.AsyncData2<{
      meanOccupancySlice: UUIDSlice<number>;
      meanUtilizationSlice: UUIDSlice<O.Option<number>>;
      peakOccupancySlice: UUIDSlice<number>;
    }>
  ) =>
    pipe(data, AssetsAnalyzeDataL.set, dispatch, IO.of);

export const getAssetsAnalyzeData = (
  dispatch: React.Dispatch<React.SetStateAction<AssetsAnalyzeData2>>
) =>
  pipe(
    RTE.ask<SeriesContext & AbortingControllerContext & VisibleAssetsContext>(),
    RTE.chainW(({ series, visibleAssets }) =>
      pipe(
        visibleAssets,
        NEA.fromArray,
        O.foldW(
          () => pipe(Sl.empty<AnalyzeRow, UUID>(), prepareData, RTE.of),
          (assetsToFetch) =>
            pipe(
              fetchAssetsAnalyzeTE(series, assetsToFetch),
              callIfDelayed(10000, () => {
                pipe(AssetsAnalyzeDataL, L.modify(AD2.toDelayedData), dispatch);
              }),

              RTE.map(prepareData)
            )
        )
      )
    ),
    RTE.mapLeft((_) => new Error("Error getting analyze data")),
    loggingAsyncDataEffect("Assets Analyze Data", setAnalyzeData(dispatch))
  );

export const initialState2: AssetsAnalyzeData2 = {
  analyzeData: AD2.none,
};

export const [component, controller] = defineAppController<
  SeriesContext & VisibleAssetsContext,
  AssetsAnalyzeData2
>(
  initialState2,
  eqAssetsAnalyzeData2,
  Eq.struct<SeriesContext & VisibleAssetsContext>({
    series: SR.SeriesRequestEq,
    visibleAssets: eqAssetsByUUIDOnly,
  }),
  getAssetsAnalyzeData
);

export const AssetsAnalyzeDataController = controller;
export const AssetsAnalyzeDataControllerComponent = component;
