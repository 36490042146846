import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { useCollectorOld } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { getAssetById, getParentLine } from "lib/at-data/assets/filters";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { TableCell, TableRow } from "@mui/material";
import * as NEA from "fp-ts/NonEmptyArray";
import { clog } from "lib/util";

export const HierarchyPropertyRow: React.FC<{ asset: Asset }> = (props) => {
  const allAssets = useCollectorOld(AssetsController, flow(L.prop("assets")));

  const parents = pipe(
    allAssets,
    fromAD2,
    getParentLine(props.asset),
    A.filterMap((a) => pipe(allAssets, fromAD2, getAssetById(a))),
    A.tail,
    O.chain(flow(NEA.fromArray, O.map(A.reverse)))
  );
  return (
    <TableRow
      key={"hierarchy"}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        Hierarchy
      </TableCell>
      <TableCell align="right">
        {pipe(
          parents,
          O.foldW(
            () => <div key={"nop"}>No Parents</div>,
            flow(
              A.map((pa) => <div key={pa.id}>{pa.name}</div>),
              A.append(
                <div>
                  <strong>This Asset</strong>
                </div>
              )
            )
          )
        )}
      </TableCell>
    </TableRow>
  );
};
