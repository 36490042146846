import { useStable } from "fp-ts-react-stable-hooks";
import * as Eq from "fp-ts/Eq";
import {
  CollectorPipe,
  CollectorOutputDispatch,
  CollectorOutputState,
} from "lib/at-react/collector";
import { createElement } from "react";

export const reactState = <STATE>(
  initialState: STATE,
  eq: Eq.Eq<STATE>
): CollectorPipe<
  {},
  CollectorOutputState<STATE> & CollectorOutputDispatch<STATE>
> => ({
  initialState: {
    state: initialState,
    dispatch: () => {},
  },
  component: (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, dispatch] = useStable(initialState, eq);

    return props.render({ state, dispatch });
  },
});
export const fromReactState =
  <STATE extends IN, IN>(eq: Eq.Eq<STATE>) =>
  (
    intake: CollectorPipe<IN, CollectorOutputState<STATE>>
  ): CollectorPipe<
    Omit<IN, keyof STATE>,
    CollectorOutputState<STATE> & CollectorOutputDispatch<STATE>
  > => ({
    initialState: {
      ...intake.initialState,
      dispatch: () => {},
    },
    component: (props) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [state, dispatch] = useStable(intake.initialState.state, eq);

      return createElement(intake.component, {
        context: { ...props.context } as IN,
        render: (value) => props.render({ state: { ...state }, dispatch }),
      });
    },
  });
