import React from "react";
import PageHeader from "components/ui/PageHeader";
import ReportsHeader from "./componenets/ReportsHeader";
import { styled } from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import ReportsRecentlyViewed from "./componenets/ReportsReventlyViewed";
import ReportsTable from "./componenets/reportsTable/ReportsTable";

const ReportsPage: React.FC<{}> = () => {
  return (
    <Page>
      <PageHeader header={<ReportsHeader />} />
      <ReportsRecentlyViewed />
      <ReportsTable />
    </Page>
  );
};

export default ReportsPage;

const Page = styled("main")`
  background-color: ${palette.neutral[200]};
  min-height: calc(100vh - ${vars.navBar});
`;
