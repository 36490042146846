import React, { useEffect, useRef, SelectHTMLAttributes } from "react";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label: string;
}

export const SelectField: React.FC<SelectProps> = ({
  name,
  label,
  ...rest
}) => {
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <select id={name} {...rest} />
    </div>
  );
};

export default SelectField;
