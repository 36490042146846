import {
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Icon, {
  AtArea,
  AtBuilding,
  AtCategory,
  AtFloor,
  AtZone,
  AtRoom,
  AtTagNames,
} from "components/ui/Icon";
import React from "react";
import { RiCheckLine } from "react-icons/ri";
import palette from "theme/palette";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import theme from "theme/theme";
import styled from "styled-components";
import { AssetType, AssetTypes } from "lib/at-data/assets/AssetTypes";
import { useController } from "lib/at-react/defineController";
import {
  AssetsController,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { TagsL } from "views/authenticated/root/controller/state";
import { AssetTag, AssetTagEq } from "lib/at-api/asset-tags/assetTags";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import {
  AssetsFilterL,
  AssetsPageUIStateL,
  FilterByTagL,
  FilterByTypeL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { isManagedAssetTag } from "controllers/UIStateCollector/DEFAULT_FILTERS";
import { not } from "fp-ts/Predicate";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import mixpanel from "mixpanel-browser";

const AssetTypeFilters = [
  // { label: "Filtered Assets", icon: AtAssets, filter: "filtered" },
  // { label: "Favorite Assets", icon: RiStarFill, filter: "favorite" },
  { label: "Regions", icon: AtArea, filter: AssetTypes.CAMPUS },
  { label: "Buildings", icon: AtBuilding, filter: AssetTypes.BUILDING },
  { label: "Floors", icon: AtFloor, filter: AssetTypes.FLOOR },
  // { label: "Rooms", icon: AtRoom, filter: "rooms" },

  { label: "Zones", icon: AtRoom, filter: AssetTypes.ZONE },
  // { label: "Points of Interest", icon: AtPointOfInterest, filter: "poi" },
];

type AssetFilterLabel = { filter: string; label: string };

const getLabel = (labels: AssetFilterLabel[]) => (as: AssetType) =>
  pipe(
    labels,
    A.findFirst((l) => l.filter === as.toString()),
    O.map((v) => v.label),
    O.getOrElse(() => as.toString())
  );

const friendlyNames = (aF: AssetFilterLabel[]) => (filters: AssetType[]) =>
  pipe(filters, A.map(flow(getLabel(aF)))).join(", ");

interface ITableHeader {
  mode?: "light";
}

const AssetsTableHeaderFilter: React.FC<ITableHeader> = (props) => {
  const dispatch = useHistoryControllerDispatch(UIStateController);
  const [assetsFilter] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).get
  );

  const [assetTags] = useController(
    RawAssetsController,
    flow(TagsL.get, A.filter(not(isManagedAssetTag)))
  );

  const handleSelectedAssetTypeChange = (type: AssetTypes) => {
    dispatch(
      pipe(
        AssetsPageUIStateL,
        L.composeLens(AssetsFilterL),
        L.composeLens(FilterByTypeL)
      ).set(A.of(type))
    );
    mixpanel.track(`Show ${type} Clicked`);
  };

  const handleTagChange = (tag: AssetTag) => {
    // dispatch(AssetsFilterL.compose(FilterByTagL).set([tag]));
    //TODO: convert this garbage to optics
    const hasTag = pipe(
      assetsFilter.byTags,
      A.some((_) => AssetTagEq.equals(_, tag))
    );
    const newTags = hasTag
      ? pipe(
          assetsFilter.byTags,
          A.filter((_) => !AssetTagEq.equals(_, tag))
        )
      : pipe(assetsFilter.byTags, A.append(tag));

    dispatch(
      pipe(
        AssetsPageUIStateL,
        L.composeLens(AssetsFilterL),
        L.composeLens(FilterByTagL)
      ).set(newTags)
    );
  };

  return (
    <AssetFilterInput
      sx={{ maxWidth: 250, width: "100%" }}
      $mode={props.mode || false}
    >
      <InputLabel id="search-filter-label">Show:</InputLabel>
      <Select
        multiple
        value={assetsFilter.byTypes}
        renderValue={friendlyNames(AssetTypeFilters)}
        MenuProps={{
          disableScrollLock: true,
          // PaperProps: {
          //   style: {
          //     maxHeight: 600,
          //     // width: 250,
          //     overflowY: "auto",
          //     // overflowX: "hidden",
          //   },
          // },
        }}
      >
        <MenuItemContainer>
          {AssetTypeFilters.map((asset) => (
            <MenuItem
              key={asset.label}
              value={asset.filter}
              onClick={() => handleSelectedAssetTypeChange(asset.filter)}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Icon
                  icon={asset.icon}
                  size="18px"
                  sx={{
                    color: palette.neutral[500],
                    ".Mui-selected &": {
                      color: palette.neutral[700],
                    },
                  }}
                />
                <ListItemText primary={asset.label} />
              </Stack>
              {assetsFilter.byTypes
                .map((_) => _.toString())
                .indexOf(asset.filter) > -1 && (
                <Icon icon={RiCheckLine} size="16px" />
              )}
            </MenuItem>
          ))}
          {/*<Divider />*/}
          {/*<TagList>*/}
          {/*  <MenuItem key={"managed asset"} disabled>*/}
          {/*    <Stack*/}
          {/*      direction="row"*/}
          {/*      spacing={1}*/}
          {/*      alignItems="center"*/}
          {/*      sx={{ width: "100%" }}*/}
          {/*    >*/}
          {/*      <Icon*/}
          {/*        icon={AtTagNames}*/}
          {/*        size="18px"*/}
          {/*        sx={{*/}
          {/*          color: palette.neutral[500],*/}
          {/*          ".Mui-selected &": {*/}
          {/*            color: palette.neutral[700],*/}
          {/*          },*/}
          {/*        }}*/}
          {/*      />*/}
          {/*      <ListItemText primary={"Managed Assets"} />*/}
          {/*    </Stack>*/}
          {/*    <Icon icon={RiCheckLine} size="16px" />*/}
          {/*  </MenuItem>*/}
          {/*  {pipe(*/}
          {/*    assetTags,*/}
          {/*    A.sort(S.Ord),*/}
          {/*    A.mapWithIndex((i, t) => (*/}
          {/*      <MenuItem key={i} value={t} onClick={() => handleTagChange(t)}>*/}
          {/*        <Stack*/}
          {/*          direction="row"*/}
          {/*          spacing={1}*/}
          {/*          alignItems="center"*/}
          {/*          sx={{ width: "100%" }}*/}
          {/*        >*/}
          {/*          <Icon*/}
          {/*            icon={AtTagNames}*/}
          {/*            size="18px"*/}
          {/*            sx={{*/}
          {/*              color: palette.neutral[500],*/}
          {/*              ".Mui-selected &": {*/}
          {/*                color: palette.neutral[700],*/}
          {/*              },*/}
          {/*            }}*/}
          {/*          />*/}
          {/*          <ListItemText primary={t} />*/}
          {/*        </Stack>*/}
          {/*        {assetsFilter.byTags.map((_) => _).indexOf(t) > -1 && (*/}
          {/*          <Icon icon={RiCheckLine} size="16px" />*/}
          {/*        )}*/}
          {/*      </MenuItem>*/}
          {/*    ))*/}
          {/*  )}*/}
          {/*</TagList>*/}
        </MenuItemContainer>
      </Select>
    </AssetFilterInput>
  );
};

export default AssetsTableHeaderFilter;

export const MenuItemContainer = styled("div")`
  // height: 400px;
  // min-height: 400px;
  max-height: 800px;
  overflow: hidden;

  // padding-right: 12px;
  padding: 0;
`;

export const TagList = styled("div")`
  overflow-y: auto;
  min-height: 400px;
  max-height: 400px;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: #bbbfc9;
    border-radius: 4px;
  }
`;

export const AssetFilterInput = styled(FormControl)<{ $mode: "light" | false }>`
  padding: 0;

  & .MuiInputLabel-root {
    transform: none !important;
    line-height: 40px;
    left: ${theme.spacing(2)};
  }

  & .MuiInputBase-root {
    padding: 0;
    background: ${(props) =>
      props.$mode === "light" ? palette.neutral[100] : palette.neutral[200]};
    box-shadow: inset 0 0 0 0px ${palette.neutral[500]};
    transition: ${theme.transitions.create(["box-shadow", "color"], {
      duration: theme.transitions.duration.shortest,
      easing: "ease-out",
    })};

    &.Mui-disabled,
    &.Mui-disabled:hover {
      background: ${palette.neutral[300]};
      box-shadow: inset 0 0 0 1px ${palette.neutral[400]};
    }

    &:hover {
      box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
    }

    &.Mui-focused {
      box-shadow: inset 0 0 0 1.5px ${palette.primary[400]};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  && .MuiSelect-select {
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    line-height: 40px;
    padding-left: 60px;
  }
`;

const AssetFilterLabel = styled(InputLabel)`
  transform: none;
  background: lime;
  line-height: 40px;
`;
