import { UUID, eqUUID } from "lib/at-data/UUID";
import * as O from "fp-ts/Option";
import * as Set from "fp-ts/Set";
import * as Ord from "fp-ts/Ord";
import { pipe } from "fp-ts/function";
import * as M from "fp-ts/Map";
import * as Sl from "lib/at-data/Slice";
import * as S from "fp-ts/Semigroup";
import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import { Semigroup } from "fp-ts/Semigroup";
import { Slice } from "lib/at-data/Slice";

export type UUIDSlice<T> = Sl.Slice<UUID, T>;

export const fromRecord = <T>(r: Record<string, T>): UUIDSlice<T> => ({
  slices: new Map(Object.entries(r) as [UUID, T][]),
});

export const ordUUIDSet: Ord.Ord<Set<UUID>> = Ord.fromCompare((a, b) => {
  if (a.size === b.size && Set.getEq(eqUUID).equals(a, b)) return 0;
  if (a.size < b.size && pipe(a, Set.isSubset(eqUUID)(b))) return -1;
  return 1;
});

export const getSliceByUUID: <T>(
  id: UUID
) => (slice: UUIDSlice<T>) => O.Option<T> = (id) => (slice) =>
  pipe(slice.slices, M.lookup(eqUUID)(id));

export const getSliceByUUID2: <T>(
  slice: UUIDSlice<T>
) => (id: UUID) => O.Option<T> = (slice) => (id) =>
  pipe(
    slice.slices,
    M.lookupWithKey(eqUUID)(id),
    O.map(([key, item]) => item)
  );

export const union =
  <T>(second: UUIDSlice<T>) =>
  (first: UUIDSlice<T>): UUIDSlice<T> => ({
    slices: pipe(
      first.slices,
      M.union<UUID, T>(eqUUID, S.last())(second.slices)
    ),
  });

export const getEq = <T>(eq: Eq.Eq<T>) => Sl.getEq(eqUUID, eq);

export const getUnionSemiGroup = <T>(
  s: Semigroup<T>
): Semigroup<UUIDSlice<T>> => Sl.getUnionSemiGroup(eqUUID, s);
