import React from "react";
import { Form } from "../styles/Styles";
import Icon, { AtEyeOpen, AtGlobe } from "components/ui/Icon";
import { InputField } from "../ui/InputField";

export const DeployFormTagsOrganizations: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Tags"}
          name={"tags"}
          type={"text"}
          value={"Collaboration"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormTagsRegions: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Tags"}
          name={"tags"}
          type={"text"}
          value={"Collaboration"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormTagsBuildings: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Tags"}
          name={"tags"}
          type={"text"}
          value={"Collaboration"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormTagsFloors: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Tags"}
          name={"tags"}
          type={"text"}
          value={"Collaboration"}
        />
      </fieldset>
    </Form>
  );
};
