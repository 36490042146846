import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import { Button, Stack } from "@mui/material";
import { AppContext } from "contexts/AppContext";
import { AppController } from "controllers/AppController/AppController";
import {
  AssetActionsController,
  clearAssetActions,
} from "controllers/AssetsController/AssetActionsController";
import {
  AssetAction,
  matchActionP,
  toServerActions,
  toTasks,
} from "controllers/AssetsController/Modification";
import { toISOString } from "fp-ts-std/Date";
import * as A from "fp-ts/Array";
import { log, warn } from "fp-ts/Console";
import { flow, pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as O from "fp-ts/Option";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as T from "fp-ts/Task";
import * as RT from "fp-ts/ReaderTask";
import * as TE from "fp-ts/TaskEither";
import { useController } from "lib/at-react/defineController";
import {
  useCollector,
  useCollectorDispatch,
  useCollectorWithDispatch,
} from "lib/at-react/hooks";
import { clog, noop } from "lib/util";
import * as L from "monocle-ts/Lens";
import React, { useCallback } from "react";
import { toMinimalAppState } from "views/authenticated/app/ReadyAppState";

const columns = [
  { name: "action", header: "Action", minWidth: 50, defaultFlex: 1 },
  {
    name: "value",
    minWidth: 50,
    defaultFlex: 3,
    render: ({ data }: { data: AssetAction }) => (
      <div>
        <div>
          assetId: <strong>{data.id}</strong>
        </div>
        {pipe(
          data,
          matchActionP(
            {
              modifyProperty: (_) => (
                <div>
                  {_.modification.property}:
                  <strong>{JSON.stringify(_.modification.value)}</strong>
                </div>
              ),
            },
            () => null
          )
        )}
      </div>
    ),
  },
];

export const LocalModificaitonsTab: React.FC<{}> = (props) => {
  const [assetActions, assetActionsDispatch] = useCollectorWithDispatch(
    AssetActionsController,
    L.prop("actions")
  );
  const [appState] = useController(AppController, (_) => _);

  const handleSubmitModifications = useCallback(
    () =>
      pipe(
        pipe(
          appState,
          toMinimalAppState,
          O.foldW(
            () => TE.of(noop),
            (appContext) =>
              pipe(
                assetActions,
                clog("actions"),
                toServerActions,
                clog("server actions"),
                toTasks,
                RTE.foldW(
                  flow(warn, RT.fromIO),
                  flow(
                    log,
                    RT.fromIO,
                    RT.chainIOK(() =>
                      IO.of(assetActionsDispatch(clearAssetActions))
                    )
                  )
                )
              )({ appContext })
          )
        )
      )(),
    [assetActions]
  );

  return (
    <Stack>
      <Stack>
        <ReactDataGrid
          columns={columns}
          dataSource={assetActions}
          licenseKey="AppName=ArmoredThingsApp,Company=ArmoredThings,ExpiryDate=2023-03-08,LicenseDeveloperCount=2,LicenseType=single_app,Ref=ArmoredThingsLicenseRef,Z=874177969-88290940832246787443234957-812671145-2050061877"
        />
      </Stack>
      <Stack>
        <Button onClick={handleSubmitModifications}>Send to Server</Button>
      </Stack>
    </Stack>
  );
};
