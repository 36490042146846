import { Box, Stack, styled, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import React from "react";
import palette from "theme/palette";
import { ChartDataSlice } from "lib/at-data/ChartDataSlice";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import {
  CommaNumber,
  formatNumber,
} from "../../../../../../lib/formatters/formatNumber";

interface IAssetsHeaderLiveCount {
  slice: ChartDataSlice;
}

const liveCountDateFormatter = new Intl.DateTimeFormat("en-us", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

const isSliceDelayed = (
  liveTimestamp: Date,
  slice: ChartDataSlice
): boolean => {
  const diffInHours =
    Math.abs(liveTimestamp.getTime() - slice.timestamp) / 3600000;
  return diffInHours > 1;
};

const AssetsHeaderLiveCount: React.FC<IAssetsHeaderLiveCount> = (props) => {
  const noDataPinProps = () => ({
    color: palette.status.error.default,
    title: "Live",
  });

  const pinProps = pipe(props.slice, (slice) => {
    if (isSliceDelayed(new Date(slice.timestam), slice)) {
      return {
        color: palette.status.warning.default,
        title: "Delayed",
      };
    }

    return {
      color: palette.status.success.default,
      title: "Updated:",
    };
  });

  return (
    <Stack direction="row" spacing={1.5}>
      <PinBox>
        <PinHead
          color={pinProps.color}
          data-testid="assets-live-count-pin-head"
        />
        <Pin />
      </PinBox>
      {/*<StyledBox data-testid="assets-header-live-count">*/}
      {/*  <LiveAt>{pinProps.title}</LiveAt>*/}
      {/*  <Typography variant="h4" color={palette.neutral[100]}>*/}
      {/*    {liveCountDateFormatter.format(new Date(props.slice.timestamp))}*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body2" color="textSecondary">*/}
      {/*    Est. Occupancy:{" "}*/}
      {/*    <strong>*/}
      {/*      {pipe(Math.floor(props.slice.value), formatNumber(CommaNumber))}*/}
      {/*    </strong>*/}
      {/*  </Typography>*/}
      {/*</StyledBox>*/}
    </Stack>
  );
};

export default AssetsHeaderLiveCount;

const PinBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -4px;
  margin-top: 22px;
`;

const pulsing = keyframes`
100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const PinHead = styled("div")`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  position: relative;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    background-color: inherit;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    opacity: 0.8;
    animation: ${pulsing} 2s ease-out infinite;
  }

  &::before {
    animation-delay: -1s;
  }
`;

const Pin = styled("div")`
  width: 2px;
  background-color: ${palette.neutral[100]};
  height: 100%;
  min-height: 70px;
`;

const StyledBox = styled(Box)`
  height: 100px;
  width: 100%;
`;
const LiveAt = styled(Typography)`
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${palette.neutral[400]};
`;
