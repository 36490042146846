import React from "react";

export const GMULogo = () => (
  <div>
    <svg
      width="135"
      height="88"
      viewBox="0 0 135 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_380_27471)">
        <path
          d="M97.4896 47.6895C89.7276 47.6895 85.0898 54.1293 85.0898 60.3544C85.0898 66.5805 89.7276 73.0212 97.4896 73.0212C105.286 73.0212 109.946 66.5805 109.946 60.3544C109.946 54.1293 105.286 47.6895 97.4896 47.6895ZM102.611 60.3544C102.611 63.8423 101.945 69.6936 97.4896 69.6936C93.3035 69.6936 92.4255 64.6145 92.4255 60.3544C92.4255 56.0925 93.3035 51.0125 97.4896 51.0125C101.945 51.0125 102.611 56.8656 102.611 60.3544Z"
          fill="white"
        />
        <path
          d="M134.748 48.3747H124.36L127.677 51.6911V61.212C127.314 60.7547 117.489 48.3747 117.489 48.3747H109.057C109.057 48.3747 112.31 51.6302 112.371 51.6911V69.0171C112.311 69.0781 109.057 72.3354 109.057 72.3354H119.646C119.646 72.3354 116.39 69.0781 116.33 69.0171V56.3213C116.694 56.7627 131.669 74.9321 131.669 74.9321C131.669 74.9321 131.687 51.7782 131.687 51.6911C131.748 51.6311 135.002 48.3747 135.002 48.3747H134.748Z"
          fill="white"
        />
        <path
          d="M62.6632 10.421L68.416 -0.00330052C67.0488 1.27772 66.361 1.68067 65.0921 2.55124C58.137 7.32577 49.5765 8.46154 40.9758 15.5367C35.1405 20.3374 32.4473 27.2954 32.4473 27.2954C32.4473 27.2954 40.2786 18.4988 52.1217 14.1113C56.8991 12.3411 60.2305 11.3694 62.6632 10.421Z"
          fill="#FFCC33"
        />
        <path
          d="M59.5968 15.9836C53.3632 17.9712 45.9386 19.8632 38.3312 24.9967C32.4059 28.9953 28.9648 35.8174 28.9648 35.8174C28.9648 35.8174 37.3191 27.4622 49.4639 24.6106C51.9079 24.0371 53.9592 23.5582 55.6779 23.159L59.5968 15.9836Z"
          fill="white"
        />
        <path
          d="M80.4053 59.5223L74.1239 56.145C72.4924 55.1976 71.9104 54.0449 71.9104 53.2821C71.8889 52.9195 72.1063 52.4509 72.4811 52.0527C73.0706 51.4248 73.9393 51.065 74.867 51.065C75.3534 51.065 75.9259 51.2168 76.6175 51.5307C76.6175 51.5401 79.8608 54.7281 79.8608 54.7281L79.9527 54.8199L83.4855 49.2957L77.0467 48.3764C76.1949 48.2171 75.2053 48.2171 74.5493 48.2171C68.7121 48.2171 66.0526 51.6919 66.0526 54.9193C66.0526 56.8103 67.1903 59.4492 70.382 61.1847C70.383 61.1847 76.6644 64.5648 76.6644 64.5648C77.2116 64.9631 77.9801 65.5198 77.9801 66.7933C77.9801 69.0779 75.8969 69.9082 74.4968 69.9082C73.6816 69.9082 72.9563 69.7002 72.3396 69.2916C72.3396 69.2888 68.7665 65.7681 68.7665 65.7681L68.6634 65.6669L65.7462 71.3495L63.7455 68.8399C63.7455 68.8268 55.6864 48.442 55.6864 48.442L55.6602 48.3745H51.6662L43.4076 68.8652C43.4048 68.8643 42.2437 70.3421 41.4753 71.3195L38.8429 68.3029V36.9241C47.2394 30.2697 52.6671 28.9194 52.6539 28.9203C49.6693 29.1902 43.4357 29.7515 36.1469 33.812C31.3508 36.4827 27.5659 40.1346 27.5659 40.1346L27.5574 40.159L27.5518 40.1524C26.1293 42.8241 24.1548 47.2688 22.2225 52.0442L12.6069 34.4904H0.00195312L4.03149 38.5228V68.2158L0.00195312 72.2463H13.7886L9.69252 68.2158V45.179L18.6999 61.2972C17.3561 65.0849 16.2991 68.4688 15.8586 70.6438L18.7562 67.2778C18.7562 67.2778 21.1083 56.0747 30.379 45.0797V68.5719L26.3476 72.3334H40.6797L40.6787 72.3353H50.7263C50.7263 72.3353 47.6292 69.2382 47.5336 69.1435C47.5824 69.0217 48.749 66.1242 48.7996 66.0005H56.0434C56.0894 66.1317 57.1155 69.0226 57.1577 69.1407C57.0668 69.2326 53.964 72.3353 53.964 72.3353H65.9027C67.5773 72.3784 71.9011 72.4899 71.9058 72.4909C72.7632 72.6333 73.6966 72.7026 74.7583 72.7026C81.5785 72.7026 83.9972 68.6656 83.9972 65.2086C83.9972 61.7498 81.4848 60.0799 80.4053 59.5223ZM50.0994 62.7272C50.2053 62.462 52.296 57.2405 52.4759 56.7897C52.6502 57.2423 54.65 62.4648 54.7512 62.7272H50.0994Z"
          fill="white"
        />
        <path
          d="M61.4159 44.2505C59.5088 44.735 58.0882 44.7668 57.0536 44.7668C54.0492 44.7668 50.5762 43.2487 50.5762 38.9838C50.5762 34.7349 53.5647 33.1034 57.7002 33.1034C58.4433 33.1034 60.0102 33.184 61.1085 33.6038L60.8667 36.0591C59.9296 35.5099 58.8313 35.3328 58.12 35.3328C55.6807 35.3328 53.6771 36.3505 53.6771 38.9838C53.6771 41.1964 55.2449 42.5374 57.4415 42.5374C57.8455 42.5374 58.2334 42.4728 58.508 42.3434V40.115H56.7471V37.9821H61.4159V44.2505Z"
          fill="white"
        />
        <path
          d="M67.084 33.2973H75.0317V35.4292H70.0893V37.7552H74.7572V39.8881H70.0893V42.4408H75.2107V44.5728H67.084V33.2973Z"
          fill="white"
        />
        <path
          d="M85.2103 33.1034C88.926 33.1034 90.864 35.3159 90.864 38.9351C90.864 42.5215 88.8932 44.7668 85.2103 44.7668C81.5265 44.7668 79.5566 42.5215 79.5566 38.9351C79.5566 35.3159 81.4946 33.1034 85.2103 33.1034ZM85.2103 42.5374C87.0358 42.5374 87.7621 40.7934 87.7621 38.9351C87.7621 36.9803 87.0358 35.3328 85.2103 35.3328C83.3839 35.3328 82.6576 36.9803 82.6576 38.9351C82.6576 40.7934 83.3839 42.5374 85.2103 42.5374Z"
          fill="white"
        />
        <path
          d="M95.5586 33.2973H100.923C102.926 33.2973 104.557 34.2016 104.557 36.1405C104.557 37.6427 103.847 38.6445 102.393 38.9837V39.0156C103.362 39.2742 103.427 40.1308 103.993 41.5037L105.09 44.5728H101.956L101.31 42.5373C100.697 40.5993 100.309 40.1149 99.1937 40.1149H98.564V44.5728H95.5586V33.2973ZM98.564 37.982H99.3549C100.325 37.982 101.552 37.9492 101.552 36.6737C101.552 35.6232 100.503 35.4292 99.3549 35.4292H98.564V37.982Z"
          fill="white"
        />
        <path
          d="M119.925 44.2505C118.018 44.735 116.596 44.7668 115.561 44.7668C112.557 44.7668 109.084 43.2487 109.084 38.9838C109.084 34.7349 112.073 33.1034 116.209 33.1034C116.952 33.1034 118.519 33.184 119.616 33.6038L119.375 36.0591C118.438 35.5099 117.339 35.3328 116.628 35.3328C114.189 35.3328 112.186 36.3505 112.186 38.9838C112.186 41.1964 113.753 42.5374 115.949 42.5374C116.353 42.5374 116.741 42.4728 117.016 42.3434V40.115H115.255V37.9821H119.925V44.2505Z"
          fill="white"
        />
        <path
          d="M125.256 33.2973H133.204V35.4292H128.26V37.7552H132.93V39.8881H128.26V42.4408H133.382V44.5728H125.256V33.2973Z"
          fill="white"
        />
        <path
          d="M8.99259 84.5813C8.99259 86.7657 7.57472 87.7488 5.49337 87.7488C3.41201 87.7488 1.99414 86.7657 1.99414 84.5813V79.6295H4.12141V84.2271C4.12141 85.2785 4.40724 86.1707 5.49337 86.1707C6.58043 86.1707 6.86625 85.2785 6.86625 84.2271V79.6295H8.99259V84.5813Z"
          fill="white"
        />
        <path
          d="M17.2949 79.6296H20.0051L22.7031 85.05H22.7256L22.6918 79.6296H24.6364V87.6121H21.9946L19.2038 82.1571H19.1814L19.2498 87.6121H17.2949V79.6296Z"
          fill="white"
        />
        <path
          d="M33.7832 79.6296H35.9105V87.6121H33.7832V79.6296Z"
          fill="white"
        />
        <path
          d="M44.5449 79.6296H46.809L48.5811 85.8962H48.6045L50.3888 79.6296H52.5723L49.8509 87.6121H47.2204L44.5449 79.6296Z"
          fill="white"
        />
        <path
          d="M60.4961 79.6296H66.1226V81.1393H62.6224V82.7859H65.9277V84.2956H62.6224V86.1024H66.2472V87.6121H60.4961V79.6296Z"
          fill="white"
        />
        <path
          d="M74.6543 79.6296H78.4506C79.8694 79.6296 81.0239 80.2706 81.0239 81.6426C81.0239 82.7062 80.5207 83.4147 79.4908 83.6546V83.678C80.1777 83.8607 80.2227 84.4671 80.6228 85.4389L81.4007 87.6121H79.1825L78.7252 86.1708C78.2903 84.7988 78.0158 84.4549 77.2267 84.4549H76.7816V87.6121H74.6543V79.6296ZM76.7816 82.9461H77.341C78.0279 82.9461 78.8957 82.9236 78.8957 82.0193C78.8957 81.2762 78.1526 81.1393 77.341 81.1393H76.7816V82.9461Z"
          fill="white"
        />
        <path
          d="M89.1296 85.644C89.6787 85.942 90.4453 86.1707 91.3253 86.1707C92 86.1707 92.6401 86.0329 92.6401 85.3928C92.6401 83.9066 88.8672 84.8437 88.8672 81.9395C88.8672 80.0184 90.7424 79.4918 92.343 79.4918C93.1096 79.4918 93.8639 79.607 94.4018 79.7898L94.265 81.4363C93.7159 81.1852 93.0983 81.0708 92.492 81.0708C91.9541 81.0708 91.0629 81.1158 91.0629 81.814C91.0629 83.1053 94.8357 82.2366 94.8357 85.1183C94.8357 87.1884 93.0983 87.7488 91.3253 87.7488C90.5821 87.7488 89.6562 87.6345 88.9468 87.4058L89.1296 85.644Z"
          fill="white"
        />
        <path
          d="M102.83 79.6296H104.957V87.6121H102.83V79.6296Z"
          fill="white"
        />
        <path
          d="M115.257 81.2077H113.289V79.6296H119.35V81.2077H117.384V87.6121H115.257V81.2077Z"
          fill="white"
        />
        <path
          d="M128.387 84.6039L125.436 79.6296H127.883L129.45 82.8318L130.994 79.6296H133.44L130.513 84.6039V87.6121H128.387V84.6039Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_380_27471">
          <rect width="135" height="87.75" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
