import { LocalModificaitonsTab } from "components/navBar/modifications/LocalModificaitonsTab";
import React, { useState } from "react";
import { Button, Modal, Stack, Tab } from "@mui/material";
import ModalHeader from "components/modal/ModalHeader";
import { FeatureFlag } from "components/featureFlag/FeatureFlag";
import { EDIT_FEATURE } from "constants/featureFlags";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { LegacyLocalModificationsTab } from "components/navBar/modifications/LegacyLocalModificationsTab";
import { EnvironmentSnapshotTab } from "components/navBar/modifications/EnvironmentSnapshotTab";

export const NavViewModifications: React.FC = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mode, setMode] = useState<"snapshot" | "modifications" | "actions">(
    "modifications"
  );

  return (
    <FeatureFlag flag={EDIT_FEATURE}>
      <Button
        color="primary"
        size="small"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Modification Management
      </Button>
      <div>
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <div>
            <ModalHeader
              label={"Local Asset Modifications"}
              onClose={() => {
                setModalOpen(false);
              }}
            >
              <Stack spacing={3}>
                <TabContext value={mode}>
                  <TabList
                    value={mode}
                    onChange={(ev, val) => setMode(val)}
                    aria-label="Building Editor Tabs"
                  >
                    <Tab label="Legacy Local Changes" value={"modifications"} />
                    {/*<Tab label="Local Changes" value={"actions"} />*/}
                    {/*<Tab*/}
                    {/*  label="Entire Environment Snapshot"*/}
                    {/*  value={"snapshot"}*/}
                    {/*/>*/}
                  </TabList>
                  <TabPanel value={"snapshot"}>
                    <EnvironmentSnapshotTab />
                  </TabPanel>
                  <TabPanel value={"modifications"}>
                    <LegacyLocalModificationsTab />
                  </TabPanel>
                  <TabPanel value={"actions"}>
                    <LocalModificaitonsTab />
                  </TabPanel>
                </TabContext>
              </Stack>
            </ModalHeader>
          </div>
        </Modal>
      </div>
    </FeatureFlag>
  );
};
