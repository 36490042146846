import * as Tr from "fp-ts/Tree";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as b from "fp-ts/boolean";
import { Monoid } from "fp-ts/Monoid";

export const toDataGridData = <T>(f: Tr.Forest<T>): any =>
  pipe(
    f,
    A.map(({ value, forest }) =>
      pipe(
        forest.length > 0,
        b.fold(
          () => value,
          () => ({
            ...value,
            nodes: toDataGridData(forest),
          })
        )
      )
    )
  );
export const toPercentage = (fractional: number) => fractional * 100;
export const fromPercentage = (percentage: number) => percentage / 100;
export const roundToTwoDigits = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;
