import React from "react";
import { Form } from "../styles/Styles";
import { InputField } from "../ui/InputField";
import { SelectField } from "../ui/SelectField";

export const DeployFormPresetOrganizations: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Display Name"}
          name={"dn"}
          type={"text"}
          value={"IniTech"}
        />
        <InputField
          label={"Size (Sq Ft)"}
          name={"ssf"}
          type={"number"}
          value={"500000"}
          disabled
        />
        <InputField
          label={"Price (per Sq Ft)"}
          name={"ppsf"}
          type={"number"}
          value={"35"}
          disabled
        />
        <InputField
          label={"Space Contact/Owner"}
          name={"poc"}
          type={"email"}
          value={"workplace@initech.com"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormPresetRegions: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Display Name"}
          name={"dn"}
          type={"text"}
          value={"Austin HQ"}
        />
        <InputField
          label={"Space Code"}
          name={"sc"}
          type={"text"}
          value={"r1"}
          disabled
        />
        <InputField
          label={"Size (Sq Ft)"}
          name={"ssf"}
          type={"number"}
          value={"100000"}
          disabled
        />
        <InputField
          label={"Space Type"}
          name={"st"}
          type={"text"}
          value={"Region"}
          disabled
        />
        <InputField
          label={"Price (per Sq Ft)"}
          name={"ppsf"}
          type={"number"}
          value={"35"}
          disabled
        />
        {/*<SelectField label={"Category"} name={"cat"} />*/}
        <InputField
          label={"Space Contact/Owner"}
          name={"poc"}
          type={"email"}
          value={"workplace@initech.com"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormPresetBuildings: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Display Name"}
          name={"dn"}
          type={"text"}
          value={"Building 1"}
        />
        <InputField
          label={"Space Code"}
          name={"sc"}
          type={"text"}
          value={"r1-b1"}
          disabled
        />
        <InputField
          label={"Size (Sq Ft)"}
          name={"ssf"}
          type={"number"}
          value={"20000"}
          disabled
        />
        <InputField
          label={"Space Type"}
          name={"st"}
          type={"text"}
          value={"Building"}
          disabled
        />
        <InputField
          label={"Price (per Sq Ft)"}
          name={"ppsf"}
          type={"number"}
          value={"35"}
          disabled
        />
        <SelectField label={"Category"} name={"cat"} />
        <InputField
          label={"Space Contact/Owner"}
          name={"poc"}
          type={"email"}
          value={"workplace@initech.com"}
        />
      </fieldset>
    </Form>
  );
};
