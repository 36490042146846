import { pipe, flow } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import { initialState } from "./initialState";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as Eq from "fp-ts/Eq";
import { AssetsBookingsState, AssetsBookingsStateEq, BookingsL } from "./state";
import { AssetContext } from "contexts/AssetContext";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import * as AD from "lib/at-data/AsyncData";
import { fetchBookings, OrdBookingsByDate } from "lib/at-api/assets/bookings";
import { UUID } from "lib/at-data/UUID";
import * as A from "fp-ts/Array";
import React from "react";
import * as NEA from "fp-ts/NonEmptyArray";
import { Bookings } from "lib/at-api/assets/BookingsModel";
import { defineAppController } from "lib/at-react/defineAppController";
import {
  loggingAsyncDataEffect,
  loggingAsyncDataEffectLegacy,
} from "lib/logging";
import * as AD2 from "lib/at-data/AsyncData2";
import { PendingData } from "lib/at-data/AsyncData2";

export const getBookings = pipe(
  RTE.asks<AssetContext, Asset>((_) => _.asset),
  RTE.chainW(fetchBookings)
);

export const setBookingsIO =
  (dispatch: React.Dispatch<React.SetStateAction<AssetsBookingsState>>) =>
  (bookings: AD2.AsyncData2<Array<Bookings>>) =>
  () =>
    pipe(bookings, BookingsL.set, dispatch);

const [component, controller] = defineAppController<
  AssetContext,
  AssetsBookingsState
>(
  initialState,
  AssetsBookingsStateEq,
  Eq.struct({ asset: eqAsset }),
  (dispatch) =>
    pipe(
      RTE.fromIO(pipe(AD2.pending(O.none), setBookingsIO(dispatch))),
      RTE.chainW(() => getBookings),
      RTE.map(flow(A.sort(OrdBookingsByDate))),
      RTE.mapLeft((_) => new Error("Error getting bookings data")),
      loggingAsyncDataEffect("Asset Bookings", flow(setBookingsIO(dispatch)))
    )
);

export const AssetsBookingsControllerComponent = component;
export const AssetsBookingsController = controller;
