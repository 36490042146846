// Global variables
export const vars = {
  defaultFontSize: 10,
  spacing: 8,
  navBar: "56px",
  contextualSidebar: "56px",
  contextualSidebarDrawer: "400px",
};

// Site Breakpoints
export const breakpoints: {
  [id: string]: number;
} = {
  xs: 0, // Mobile
  sm: 550, // Mobile-lg
  md: 768, // Tablet
  lg: 1024, // Laptop
  xl: 1200, // Desktop
};

export const mediaQuery = (id: string): string =>
  `@media (min-width: ${breakpoints[id]}px)`;

// Responsive Typography
export const responsiveSize = (
  minFontSize: number,
  maxFontSize: number
): string => {
  // Convert breakpoint to rem
  const minWidth = (breakpoints.sm / vars.defaultFontSize) as number;
  const maxWidth = (breakpoints.lg / vars.defaultFontSize) as number;

  // Calculate slope and intersection
  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  // Return css clamp(min, val, max)
  return `clamp( ${
    minFontSize > maxFontSize ? maxFontSize : minFontSize
  }rem, calc(${yAxisIntersection}rem + ${slope * 100}vw), ${
    minFontSize > maxFontSize ? minFontSize : maxFontSize
  }rem)`;
};
