import * as D from "io-ts/Decoder";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";

export const AssetTypeModel = D.union(
  D.literal(AssetTypes.CAMPUS),
  D.literal(AssetTypes.BUILDING),
  D.literal(AssetTypes.FLOOR),
  D.literal(AssetTypes.ZONE),
  D.literal(AssetTypes.AREA),
  D.literal(AssetTypes.UNKNOWN)
);
