import * as R from "fp-ts/Reader";
import * as IO from "fp-ts/IO";
import { flow, pipe } from "fp-ts/function";

type ReaderIO<R, T> = R.Reader<R, IO.IO<T>>;

export const apFirstIO =
  <A, B, R>(secondR: ReaderIO<R, A>) =>
  (firstR: ReaderIO<R, B>): ReaderIO<R, B> =>
    pipe(
      R.Do,
      R.apS("first", firstR),
      R.apS("second", secondR),
      R.map(({ first, second }) => pipe(first, IO.apFirst(second)))
    );

export const apSecondIO =
  <A, B, R>(secondR: ReaderIO<R, A>) =>
  (firstR: ReaderIO<R, B>): ReaderIO<R, A> =>
    pipe(
      R.Do,
      R.apS("first", firstR),
      R.apS("second", secondR),
      R.map(({ first, second }) => pipe(first, IO.apSecond(second)))
    );

export const chainFirstIO =
  <A, B, R1, R2>(secondR: ReaderIO<R1, A>) =>
  (firstR: ReaderIO<R2, B>): ReaderIO<R1 & R2, B> =>
    pipe(
      firstR,
      R.chainW((firstIO) =>
        pipe(
          secondR,
          R.chainW((secondIO) =>
            pipe(
              firstIO,
              IO.chainFirst(() => secondIO),
              R.of
            )
          )
        )
      )
    );
// pipe(
//   R.Do,
//   R.apS("first", firstR),
//   R.apS("second", secondR),
//   R.map(({ first, second }) =>
//     pipe(
//       first,
//       IO.chainFirst(() => second)
//     )
//   )
// );
