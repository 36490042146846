import * as IO from "fp-ts/IO";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as Sl from "lib/at-data/Slice";
import * as N from "lib/at-data/NumberSlice";
import {
  getOccupancyEstimate,
  OccupancyEstimate,
} from "lib/at-data/OccupancyEstimate";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { Expression } from "mapbox-gl";
import palette from "theme/palette";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { clog } from "../../../../lib/util";

export interface IHeatmapColor {
  multiplier: number;
  color: string;
}

export const heatpmapColors: Array<IHeatmapColor> = [
  {
    color: palette.data.sequential[0],
    multiplier: 0,
  },
  {
    color: palette.data.sequential[200],
    multiplier: 0.2,
  },
  {
    color: palette.data.sequential[400],
    multiplier: 0.4,
  },
  {
    color: palette.data.sequential[600],
    multiplier: 0.6,
  },
  {
    color: palette.data.sequential[800],
    multiplier: 0.8,
  },
  {
    color: palette.data.sequential[1000],
    multiplier: 1,
  },
];

const heatmapColor = [
  "interpolate",
  ["linear"],
  ["heatmap-density"],
  heatpmapColors[0].multiplier,
  heatpmapColors[0].color,
  heatpmapColors[1].multiplier,
  heatpmapColors[1].color,
  heatpmapColors[2].multiplier,
  heatpmapColors[2].color,
  heatpmapColors[3].multiplier,
  heatpmapColors[3].color,
  heatpmapColors[4].multiplier,
  heatpmapColors[4].color,
  heatpmapColors[5].multiplier,
  heatpmapColors[5].color,
] as Expression;

export const getMaxOccupancy = (assetsSlice: UUIDSlice<OccupancyEstimate>) => {
  const max = pipe(assetsSlice, Sl.map(getOccupancyEstimate), N.max);
  return max > 0 ? max : 1;
};

export const renderDetailedHeatmap =
  (sourceId: string, maxOccupancy: number) =>
  (ctx: MapboxContext): IO.IO<void> =>
  () => {
    pipe(
      ctx.map.getLayer(`${sourceId}_layer`),
      O.fromNullable,
      O.fold(
        () => {
          ctx.map.addLayer(
            {
              id: `${sourceId}_layer`,
              type: "heatmap",
              // minzoom: 18,
              source: sourceId,
              paint: {
                "heatmap-weight": ["get", "occupancyEstimate"],
                "heatmap-opacity": 0.5,
                // increase intensity as zoom level increases
                // "heatmap-opacity": {
                //   stops: [
                //     [0, 1],
                //     [18, 0.6],
                //     [20, 0.4],
                //   ],
                // },
                // "heatmap-intensity": {
                //   stops: [
                //     [0, 1],
                //     [10, 1],
                //     [15, 1],
                //     [20, 1],
                //   ],
                // },
                // assign color values be applied to points depending on their density
                "heatmap-color": heatmapColor,
                // increase radius as zoom increases
                "heatmap-radius": 200,
                //   {
                //   stops: [
                //     [17, 100],
                //     [18, 110],
                //     [19, 120],
                //     [20, 130],
                //     [21, 140],
                //   ],
                // },
              },
            },
            "all_floor_assets_fill_layer"
          );
        },
        (l) => {
          if (l.type === "heatmap") {
            ctx.map.setPaintProperty(l.id, "heatmap-weight", [
              "interpolate",
              ["linear"],
              ["get", "occupancyEstimate"],
              0,
              0,
              Math.max(maxOccupancy, 1),
              1,
            ]);
          }
        }
      )
    );
  };
