import * as C from "io-ts/Codec";
import * as Eq from "fp-ts/Eq";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import { eqGeoReference } from "lib/at-data/assets/models/GeoReferenceModel";
import { AssetModel } from "lib/at-data/assets/models/AssetModel";
import * as n from "fp-ts/number";
import { eqUSDPerOneSqM } from "lib/at-data/units/currency";
import { AssetTagsEq } from "lib/at-api/asset-tags/assetTags";
import { FloorMetaEq } from "lib/at-data/assets/models/FloorMetaModel";
import { HoursOfOperationEq } from "lib/at-data/HoursOfOperation";
import { eqGeometry } from "lib/at-data/Geometry";
import { eqCoord } from "lib/at-data/assets/models/Coord";

export type Asset = C.TypeOf<typeof AssetModel>;

export const eqAsset: Eq.Eq<Asset> = Eq.struct({
  id: eqUUID,
  name: Eq.eqString,
  parent: O.getEq(eqUUID),
  geoReference: O.getEq(eqGeoReference),
  capacity: O.getEq(n.Eq),
  costPerSqM: O.getEq(eqUSDPerOneSqM),
  targetUtilization: O.getEq(n.Eq),
  tags: AssetTagsEq,
  shortCode: O.getEq(Eq.eqString),
  floorMeta: O.getEq(FloorMetaEq),
  operationHours: O.getEq(HoursOfOperationEq),
  geometry: O.getEq(eqGeometry),
  poly: O.getEq(A.getEq(eqCoord)),
  timezone: O.getEq(Eq.eqString),
});

export const hasNoParentIn = (assetsIds: Set<UUID>) => (a: Asset) =>
  pipe(
    a.parent,
    O.map((v) => !assetsIds.has(v)),
    O.getOrElse(() => true)
  );
