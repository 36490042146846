import { SelectedAssetsControllerComponent } from "controllers/SelectedAssetsController/SelectedAssetsController";
import * as S from "fp-ts/Set";
import { eqUUID } from "lib/at-data/UUID";
import * as L from "monocle-ts/Lens";
import React from "react";
import { ShareLinkMenu } from "components/navBar/ShareLinkMenu";
import ActivityPage from "views/authenticated/activity/page/ActivityPage";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { flow, pipe } from "fp-ts/function";
import { activityPageMatch } from "controllers/UIStateCollector/lens/UIStateL";
import * as FTR from "fp-ts-routing";
import {
  ActivityPageUIStateL,
  SelectedRangeL,
  SelectedSliceL,
} from "controllers/UIStateCollector/lens/activityPage";
import { ToggleMenu } from "../../../components/navBar/ToggleMenu";
import { SelectedSliceCollector } from "controllers/SelectedSliceCollector/SelectedSliceCollector";

const ActivityModule: React.FC<{}> = () => {
  const selectedAssetIds = useCollectorOld(
    UIStateController,
    flow(L.prop("state"), L.prop("activityPage"), L.prop("selectedAssetIds"))
  );

  const [selectedRange] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(SelectedRangeL)).get
  );

  const [selectedSlice] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(SelectedSliceL)).get
  );

  return (
    <SelectedAssetsControllerComponent
      context={{
        selectedAssetIds: pipe(selectedAssetIds, S.fromArray(eqUUID)),
      }}
    >
      <SelectedSliceCollector.Component
        context={{
          selectedRange,
          selectedSlice,
        }}
      >
        <ToggleMenu />
        <ActivityModuleShareLink />
        <ActivityPage />
      </SelectedSliceCollector.Component>
    </SelectedAssetsControllerComponent>
  );
};

export const ActivityModuleShareLink = () => {
  const [activityStatePath] = useController(
    UIStateController,
    flow(ActivityPageUIStateL.get, (_) =>
      activityPageMatch.formatter.run(FTR.Route.empty, _).toString()
    )
  );
  return <ShareLinkMenu link={window.location.origin + activityStatePath} />;
};

export default ActivityModule;
