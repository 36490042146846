import * as AA from "fp-ts-std/Array";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as C from "io-ts/Codec";
import { Assets } from "lib/at-data/assets/assets";
import { getId, getLegacyZoneId } from "lib/at-data/assets/getters";
import { UUID } from "lib/at-data/UUID";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import * as RTE from "fp-ts/ReaderTaskEither";
import { AppContext } from "contexts/AppContext";
import { flow, pipe } from "fp-ts/function";
import { RESOLUTION } from "lib/at-data/Resolution";
import { handleResponse } from "lib/at-api/assets/getAssets";
import * as E from "fp-ts/Either";
import * as R from "fp-ts/Record";
import * as TE from "fp-ts/TaskEither";
import * as D from "io-ts/Decoder";

import { fromRecord } from "lib/at-data/UUIDSlice";
import { clog } from "lib/util";
import { fromNullable } from "lib/codecs/fromNullable";
import { OccupancySource } from "views/authenticated/app/RunningAppState";

export const AnalyzeRowModel = C.struct({
  occupancyMean: optionFromNullable(C.number),
  utilization: optionFromNullable(C.number),
  occupancyMax: optionFromNullable(C.number),
});
export type AnalyzeRow = C.TypeOf<typeof AnalyzeRowModel>;

export const Analyze = C.record(AnalyzeRowModel);

export type AnalyzeResponse = C.TypeOf<typeof Analyze>;

export const fetchAnalyzeData = (
  token: string,
  apiURL: string | undefined,
  start: Date,
  end: Date,
  assetIds: Array<UUID>,
  withinHoO: boolean,
  signal: AbortSignal,
  wifiAPI: OccupancySource,
  tenantId: UUID,
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
) =>
  fetch(
    // `http://localhost:7072/api/analyzeV3`,
    `${apiURL}/all-assets/analyzeV3`,
    {
      signal,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        assetIds,
        start,
        end,
        withinHoO,
      }),
    }
  ).then(handleResponse("Occupancy Schedule Mean API Error"));

export const fetchAssetsAnalyzeTE = (
  series: SeriesRequest,
  assets: Assets,
  withinHoO: boolean = true
) =>
  pipe(
    RTE.ask<AppContext & AbortingControllerContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetchAnalyzeData(
            ctx.appContext.accessToken,
            process.env.REACT_APP_API_URL,
            series.start,
            series.end,
            pipe(assets, A.map(getId)),
            withinHoO,
            ctx.abortingController.signal,
            //timezone info
            ctx.appContext.wifiAPI,
            ctx.appContext.dataProvider
          ),
        E.toError
      )
    ),
    RTE.chainEitherK(
      flow(
        Analyze.decode,
        E.mapLeft((e) => new Error(`Error analyze data response${D.draw(e)}`)),
        E.map(fromRecord)
      )
    )
  );
