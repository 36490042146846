import * as C from "io-ts/Codec";

export const FloorDiagramModel = C.struct({
  url: C.string,
  planImage: C.string,
  imageWidth: C.number,
  imageHeight: C.number,
  width: C.number,
  height: C.number,
});
export type FloorDiagram = C.TypeOf<typeof FloorDiagramModel>;
