import React from "react";
import { Button } from "@mui/material";

interface IAuth {
  onSignIn: () => void;
}

const WelcomeAuth: React.FC<IAuth> = (props) => {
  return (
    <Button variant="contained" color="secondary" onClick={props.onSignIn}>
      Sign In
    </Button>
  );
};

export default WelcomeAuth;
