import * as C from "io-ts/Codec";
import {
  UIStatePathParams,
  UIStateSpatialPathParams,
} from "controllers/UIStateCollector/models/UIStatePathParamsModel";
import { AssetsPageUIStateFilter } from "controllers/UIStateCollector/models/AssetsPageUIStateModel";
import { pipe } from "fp-ts/function";
import * as D from "io-ts/Decoder";
import * as En from "io-ts/Encoder";
import { ActivityPageUIStateFilter } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import { BBox } from "lib/at-data/BBox";
import * as O from "fp-ts/Option";
import { SelectedAssetIdsUIState } from "controllers/UIStateCollector/models/SelectedAssetIdsUIStateModel";
import * as A from "fp-ts/Array";
import { SelectedAssetPathParams } from "controllers/UIStateCollector/lens/SelectedAssetPathParamsModel";

export const AssetPageUIStatePathCodec = C.make<
  UIStatePathParams & SelectedAssetPathParams,
  UIStatePathParams & SelectedAssetPathParams,
  AssetsPageUIStateFilter & SelectedAssetIdsUIState
>(
  pipe(
    D.id<UIStatePathParams & SelectedAssetPathParams>(),
    D.map(({ start, end, byTags, byTypes, byName, selectedAsset }) => ({
      selectedRange: {
        start,
        end,
      },
      selectedAssetIds: pipe(selectedAsset, A.fromOption),
      assetsFilter: {
        byTags,
        byTypes,
        byName,
        byIds: O.none,
      },
    }))
  ),
  pipe(
    En.id<UIStatePathParams & SelectedAssetPathParams>(),
    En.contramap(
      ({
        assetsFilter: { byTags, byTypes, byName },
        selectedRange: { start, end },
        selectedAssetIds,
      }) => ({
        start,
        end,
        byTags,
        byTypes,
        byName,
        selectedAsset: pipe(selectedAssetIds, A.head),
      })
    )
  )
);

export const ActivityPageUIStatePathCodec = C.make<
  UIStatePathParams & UIStateSpatialPathParams & SelectedAssetPathParams,
  UIStatePathParams & UIStateSpatialPathParams & SelectedAssetPathParams,
  ActivityPageUIStateFilter & SelectedAssetIdsUIState
>(
  pipe(
    D.id<
      UIStatePathParams & UIStateSpatialPathParams & SelectedAssetPathParams
    >(),
    D.map(
      ({
        start,
        end,
        byTags,
        byTypes,
        byName,
        bbox,
        bearing,
        selectedAsset,
      }) => ({
        selectedRange: {
          start,
          end,
        },
        selectedSlice: O.none,
        selectedAssetIds: pipe(selectedAsset, A.fromOption),
        assetsFilter: {
          byTags,
          byTypes,
          byName,
          byParentIds: [],
          byIds: O.none,
        },
        bbox: bbox as O.Option<BBox>,
        bearing,
      })
    )
  ),
  pipe(
    En.id<
      UIStatePathParams & UIStateSpatialPathParams & SelectedAssetPathParams
    >(),
    En.contramap(
      ({
        assetsFilter: { byTags, byTypes, byName },
        selectedRange: { start, end },
        selectedAssetIds,
        bbox,
        bearing,
      }) => ({
        start,
        end,
        byTags,
        byTypes,
        byName,
        bbox,
        bearing,
        selectedAsset: pipe(selectedAssetIds, A.head),
      })
    )
  )
);
