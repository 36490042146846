import { Button, styled, TextField } from "@mui/material";
import palette from "theme/palette";
import { Asset } from "lib/at-data/assets/Asset";
import React, { useCallback, useState } from "react";
import Icon from "components/ui/Icon";
import { AssetIcons } from "controllers/UIStateCollector/utils";
import { pipe } from "fp-ts/function";
import * as b from "fp-ts/boolean";
import { Assets } from "lib/at-data/assets/assets";

export const AssetBreadcrumbs: React.FC<{
  allAssets: Assets;
  asset: Asset;
  onClick: (asset: Asset) => void;
}> = (props) => {
  const handleAssetNameClick = useCallback(
    (ev: any) => {
      ev.stopPropagation();
      props.onClick(props.asset);
    },
    [props.asset, props.onClick]
  );

  return (
    <AssetNameButton
      onClick={handleAssetNameClick}
      aria-label={props.asset.name}
      variant="text"
      startIcon={<Icon icon={AssetIcons[props.asset.type]} />}
    >
      {props.asset.name}
    </AssetNameButton>
  );
};

export const AssetNameCell = (
  allAssets: Assets,
  onClick: (asset: Asset) => void
): React.FC<{ value: Asset }> => {
  return ({ value }, { cellProps }) => {
    return pipe(
      cellProps.editProps.inEdit,
      b.fold(
        () => (
          <AssetBreadcrumbs
            allAssets={allAssets}
            asset={value}
            onClick={onClick}
          />
        ),
        () => (
          <span>
            <TextField
              type="text"
              defaultValue={value.name}
              onBlur={(e) => {
                cellProps.editProps.onComplete();
              }}
              onChange={cellProps.editProps.onChange}
              onFocus={() => cellProps.editProps.startEdit()}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  cellProps.editProps.onCancel(e);
                }
                if (e.key === "Enter") {
                  cellProps.editProps.onComplete(e);
                }
                if (e.key === "Tab") {
                  e.preventDefault();
                  cellProps.editProps.onTabNavigation(
                    true,
                    e.shiftKey ? -1 : 1
                  );
                }
              }}
            />
          </span>
        )
      )
    );
  };
};

export const AssetNameButton = styled(Button)`
  color: ${palette.neutral[700]};
`;
