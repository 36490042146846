import { AppContext } from "contexts/AppContext";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { flow, pipe } from "fp-ts/function";
import * as RT from "fp-ts/ReaderTask";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import * as C from "io-ts/Codec";
import * as D from "io-ts/Decoder";
import { handleResponse } from "lib/at-api/assets/bookings";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import { defineAppController } from "lib/at-react/defineAppController";

export const UserPreferencesModel = C.UnknownRecord;

export type UserPreferences = C.TypeOf<typeof UserPreferencesModel>;

export const UserPreferencesEq = Eq.eqStrict;

export const initialState: UserPreferences = {
  favoriteAssets: [],
  assetsTableColumns: [],
};

export const fetchPreferences = pipe(
  RTE.ask<AppContext & AbortingControllerContext>(),
  RTE.chainTaskEitherK((ctx) =>
    TE.tryCatch(
      () =>
        fetch(
          // If want to test real data can use this URL:
          `${process.env.REACT_APP_API_URL}/preferences/getPreferences`,
          {
            method: "POST",
            signal: ctx.abortingController.signal,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${ctx.appContext.accessToken}`,
            },
          }
        ).then(handleResponse("Accessing user preferences")),
      E.toError
    )
  ),
  RTE.chainEitherK(
    flow(
      C.struct({ preferences: UserPreferencesModel }).decode,
      E.mapLeft((e) => {
        console.log(D.draw(e));
        return new Error("error parsing user preferences response");
      })
    )
  )
);

export enum PreferenceTypes {
  FAVORITES = "favoriteAssets",
  COLUMNS = "columnPreferences",
}

type JsonPrimitive = string | number | boolean | null;
type JsonArray = Json[];

type JsonObject = { [key: string]: Json };

export type Json = JsonPrimitive | JsonArray | JsonObject;

export const setPreferences =
  (preferenceType: PreferenceTypes) => (value: unknown) =>
    pipe(
      RTE.ask<AppContext>(),
      RTE.chainTaskEitherK((ctx) =>
        TE.tryCatch(
          () =>
            fetch(
              // If want to test real data can use this URL:
              //https://at-pd-dev.azure-api.net/bookings/bookings?assetId=09a91777-893b-41a8-865d-efd1e230c7be'
              `${process.env.REACT_APP_API_URL}/preferences/setPreferences`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${ctx.appContext.accessToken}`,
                },
                body: JSON.stringify({
                  preferenceType,
                  value,
                }),
              }
            ).then(handleResponse("Accessing user preferences")),
          E.toError
        )
      ),
      RTE.map(() => ({ [preferenceType]: value }))
    );

export const [UserPreferencesControllerComponent, UserPreferencesController] =
  defineAppController<{}, UserPreferences>(
    initialState,
    UserPreferencesEq,
    Eq.struct({}),
    (dispatch) =>
      pipe(
        fetchPreferences,
        RTE.foldW(
          () => RT.of(dispatch(() => ({}))),
          (v) => RT.of(dispatch(() => v.preferences))
        )
      )
  );
