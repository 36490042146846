import React, { useEffect } from "react";
import AssetsPage from "views/authenticated/assets/page/AssetsPage";
import { ShareLinkMenu } from "components/navBar/ShareLinkMenu";
import { useController } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { AssetsPageUIStateL } from "controllers/UIStateCollector/lens/assetsPage";
import { flow, pipe } from "fp-ts/function";
import { assetsPageMatch } from "controllers/UIStateCollector/lens/UIStateL";
import * as FTR from "fp-ts-routing";
import { ToggleMenu } from "../../../components/navBar/ToggleMenu";

const AssetsModule: React.FC<{}> = (props) => {
  const [assetsStatePath] = useController(
    UIStateController,
    flow(AssetsPageUIStateL.get, (_) =>
      assetsPageMatch.formatter.run(FTR.Route.empty, _).toString()
    )
  );

  return (
    <>
      <ToggleMenu />
      <ShareLinkMenu link={window.location.origin + assetsStatePath} />
      <AssetsPage />
    </>
  );
};

export default AssetsModule;
