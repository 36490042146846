import { createGlobalStyle } from "styled-components";
import { breakpoints, responsiveSize, vars } from "./helpers";

// Import fonts
import FiraSansRegularWoff from "../assets/fonts/firasans-regular.woff";
import FiraSansRegularWoff2 from "../assets/fonts/firasans-regular.woff2";
import FiraSansMediumWoff from "../assets/fonts/firasans-medium.woff";
import FiraSansMediumWoff2 from "../assets/fonts/firasans-medium.woff2";
import FiraSansSemiboldWoff from "../assets/fonts/firasans-semibold.woff";
import FiraSansSemiboldWoff2 from "../assets/fonts/firasans-semibold.woff2";

export const FontStyles = createGlobalStyle`
  @font-face {
    font-weight: 400;
    font-family: 'Fira Sans';
    src:
      url(${FiraSansRegularWoff2}) format('woff2'),
      url(${FiraSansRegularWoff}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-weight: 500;
    font-family: 'Fira Sans';
    src:
      url(${FiraSansMediumWoff2}) format('woff2'),
      url(${FiraSansMediumWoff}) format('woff');
    font-display: swap;
  }

  @font-face {
    font-weight: 600;
    font-family: 'Fira Sans';
    src:
      url(${FiraSansSemiboldWoff2}) format('woff2'),
      url(${FiraSansSemiboldWoff}) format('woff');
    font-display: swap;
  }
`;

const typography = {
  fontFamily: ["Fira Sans", "sans-serif"].join(","),
  fontWeightBold: 600,
  htmlFontSize: vars.defaultFontSize,
  fontVariantLigatures: "no-common-ligatures",
  h1: {
    fontSize: responsiveSize(2.2, 3.8),
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: responsiveSize(2, 3),
    fontWeight: 500,
    lineHeight: 1.25,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontWeight: 400,
    },
  },
  h3: {
    fontSize: responsiveSize(1.6, 2.2),
    fontWeight: 600,
    lineHeight: 1.25,
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      fontWeight: 500,
    },
  },
  h4: {
    fontSize: responsiveSize(1.6, 1.8),
    fontWeight: 400,
    lineHeight: 1.32,
  },
  subtitle1: {
    fontSize: responsiveSize(1.3, 1.1),
    fontWeight: 600,
    lineHeight: 1.4,
    letterSpacing: "0.75px",
    // uppercase is applied in theme due to typescript rule
  },
  body1: {
    fontSize: responsiveSize(1.6, 1.4),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: responsiveSize(1.4, 1.2),
    fontWeight: 400,
    lineHeight: 1.5,
  },
};

export default typography;
