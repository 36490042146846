import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DateRange } from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Box, Divider, styled } from "@mui/material";
import Popover from "@mui/material/Popover";
import Icon, { AtAlertWarning } from "components/ui/Icon";
import InputField from "components/ui/InputField";
import {
  differenceInDays,
  isSameDay,
  subMinutes,
  subSeconds,
} from "date-fns/fp";
import * as b from "fp-ts/boolean";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import mixpanel from "mixpanel-browser";
import * as React from "react";
import { useState } from "react";
import { RiCalendarEventFill } from "react-icons/ri";
import palette from "theme/palette";
import theme from "theme/theme";
import InternalDateRangePicker from "./InternalDateRangePicker";

const dateRangePickerFormatter = new Intl.DateTimeFormat("en-us", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

const formatRangeDate = (date: Date): string => {
  return pipe(date, dateRangePickerFormatter.format);
};

const formatRangeEndDate = (date: Date): string =>
  pipe(date, subMinutes(1), dateRangePickerFormatter.format);
export const formatDateRange = (series: SeriesRequest) =>
  pipe(
    series.start,
    isSameDay(pipe(series.end, subSeconds(1))),
    b.fold(
      () =>
        `${formatRangeDate(series.start)} - ${formatRangeEndDate(series.end)}`,
      () => formatRangeDate(series.start)
    )
  );

const DateRangePicker: React.FC<{
  series: SeriesRequest;
  nowDate: Date;
  maxDate?: Date;
  minDate?: Date;
  onChanged: (series: SeriesRequest) => void;
}> = ({ series, onChanged, nowDate, maxDate, minDate }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    mixpanel.track("Date Picker Opened");
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleRangeError(false);
  };

  const handleApply = (newRange: SeriesRequest) => {
    if (pipe(newRange.end, differenceInDays(newRange.start)) <= 367) {
      onChanged(newRange);
      handleClose();
    } else {
      handleRangeError(true);
    }
    mixpanel.track("Date selected from date picker", { date: newRange });
  };

  const [rangeError, setError] = useState(false);
  const handleRangeError = (bb: boolean) => {
    setError(bb);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-popover" : undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box minWidth={230} data-testid="date-range-picker-container">
        <StyledDateInput
          aria-describedby={id}
          type="text"
          mode="dark"
          value={pipe(series, formatDateRange)}
          fullWidth
          InputProps={{
            readOnly: true,
            startAdornment: (
              <>
                <Icon icon={RiCalendarEventFill} />
                <Divider orientation="vertical" />
              </>
            ),
          }}
          onClick={handleClick}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          disableScrollLock={true}
        >
          <InternalDateRangePicker
            nowDate={nowDate}
            onCancel={handleClose}
            onApply={handleApply}
            maxDate={maxDate}
            minDate={minDate}
            series={series}
          />
          <span>
            {rangeError && (
              <RangeErrorMessage>
                <Icon
                  icon={AtAlertWarning}
                  // color={`${palette.primary[500]}`}
                  color={"red"}
                  sx={{ margin: "0px 5px -5px 0px" }}
                />
                <b>Date Range Too Large:</b> Please select a range of up to 12
                months.
              </RangeErrorMessage>
            )}
          </span>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;

const StyledDateInput = styled(InputField)`
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacing(1)} 0 0;
  min-width: 0;
  padding: 0;

  .MuiInputBase-input {
    cursor: inherit;
    text-align: center;
  }

  .MuiInputBase-root {
    cursor: pointer;
    height: 28px;
    border-radius: 4px;
    padding: 0 9px;
    box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
    background-color: ${palette.neutral[800]};

    .MuiDivider-root {
      background-color: ${palette.neutral[500]};
      transition: ${theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.shortest,
        easing: "ease-out",
      })};
    }

    &:hover {
      box-shadow: inset 0 0 0 1px ${palette.neutral[400]};

      .MuiDivider-root {
        background-color: ${palette.neutral[400]};
      }
    }
  }
`;

export const RangeErrorMessage = styled("div")`
  padding: ${theme.spacing(2)};
  white-space: initial;
  font-size: 15px;
`;
