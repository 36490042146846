import * as C from "io-ts/Codec";
import { CoordModel } from "lib/at-data/assets/models/Coord";
import { GeoCoordModel } from "lib/at-data/GeoCoord";
import { GeoReferenceModel } from "lib/at-data/assets/models/GeoReferenceModel";
import { FloorDiagramModel } from "lib/at-data/assets/models/FloorDiagramModel";
import { FloorMetaModel } from "lib/at-data/assets/models/FloorMetaModel";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as s from "fp-ts/string";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { AssetTypeModel } from "lib/at-data/assets/AssetTypeModel";
import { option } from "lib/codecs/option";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import * as Ord from "fp-ts/Ord";
import { HoursOfOperationModel } from "lib/at-data/HoursOfOperation";
import { SqMModel } from "lib/at-data/units/area";
import { USDPeSqMModel } from "lib/at-data/units/currency";
import { AssetAnalyticsModel } from "lib/at-data/assets/models/AssetAnalyticsModel";
import { AssetSpatialModel } from "lib/at-data/assets/models/AssetSpatialModel";
import { AssetBaseModel } from "lib/at-data/assets/models/AssetBase";

export const AssetModel = pipe(
  AssetBaseModel,
  C.intersect(AssetAnalyticsModel),
  C.intersect(AssetSpatialModel),
  C.intersect(
    C.struct({
      floorMeta: option(FloorMetaModel),
    })
  )
);

export type Asset = C.TypeOf<typeof AssetModel>;

export const eqAssetByUUID = Eq.contramap((a: Asset) => pipe(a, (_) => _.id))(
  eqUUID
);

export const ordAssetByName = pipe(
  s.Ord,
  Ord.contramap((a: Asset) => a.name)
);
