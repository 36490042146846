import {
  AssetActionsController,
  AssetActionsControllerState,
} from "controllers/AssetsController/AssetActionsController";
import { applyAll } from "controllers/AssetsController/Modification";
import * as O from "fp-ts/Option";
import { ByParentId, filterAssets } from "lib/at-data/assets/filters";
import { parentIs } from "lib/at-data/assets/predicates";
import * as AD2 from "lib/at-data/AsyncData2";
import { of } from "lib/at-data/UUID";
import { loggingAsyncDataEffect } from "lib/logging";
import React from "react";
import { initialState } from "views/authenticated/root/controller/initialState";
import { flow, pipe } from "fp-ts/function";
import * as Eq from "fp-ts/Eq";
import {
  AssetsL,
  TagsL,
  RootState,
  RootStateEq,
  AssetsL2,
  RootAssetsContext,
} from "views/authenticated/root/controller/state";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import * as E from "fp-ts/Either";
import { Assets, eqAssets, getByParent } from "lib/at-data/assets/assets";
import * as IO from "fp-ts/IO";
import { collectTags } from "views/authenticated/root/actions/collectTags";
import Worker from "workers/assetWorker";
import { AppContext } from "contexts/AppContext";
import { defineAppController } from "lib/at-react/defineAppController";
import {
  composeController,
  defineCalculatedContext,
  defineController,
} from "lib/at-react/defineController";
import {
  calculateAssetFieldsFastAndUgly,
  geoProjectZoneAssets,
} from "lib/at-api/assets/legacy/ZoneResponseModel";
import * as R from "fp-ts/Reader";

const assetWorker = new Worker();

export const loadAssetsFromAPIinWorkerThread = pipe(
  RTE.ask<AppContext>(),
  RTE.chainTaskEitherK(
    (ctx) => () => assetWorker.loadAssetsFromServer(ctx.appContext)
  )
);

export const setAssetsIO =
  (dispatch: React.Dispatch<React.SetStateAction<RootState>>) =>
  (assets: AD2.AsyncData2<Assets>): IO.IO<void> =>
  () => {
    const assetTags = pipe(
      assets,
      AD2.map(collectTags),
      AD2.toOption,
      O.getOrElseW(() => [])
    );
    pipe(flow(AssetsL2.set(assets), TagsL.set(assetTags)), dispatch);
  };
const [component, controller] = defineAppController<{}, RootState>(
  initialState,
  RootStateEq,
  Eq.struct({}),
  (dispatch) =>
    pipe(
      loadAssetsFromAPIinWorkerThread,
      loggingAsyncDataEffect("Assets Controller", setAssetsIO(dispatch))
    )
);

export const RawAssetsControllerComponent = component;
export const RawAssetsController = controller;

export const processAssets = pipe(
  R.ask<RootState & AssetActionsControllerState>(),
  R.map(({ assets, actions }) => ({
    assets: pipe(
      assets,
      AD2.map(
        flow(
          applyAll(actions),
          geoProjectZoneAssets,
          calculateAssetFieldsFastAndUgly
        )
      )
    ),
  }))
);

export const [component2, controller2] = pipe(
  controller,
  composeController(
    defineCalculatedContext(
      initialState,
      Eq.struct<RootAssetsContext & AssetActionsControllerState>({
        assets: AD2.getEq(eqAssets),
        actions: Eq.eqStrict,
      }),
      processAssets
    )
  )
);

export const AssetsControllerComponent = component2;
export const AssetsController = controller2;

export const fromAD2 = (assets: AD2.AsyncData2<Assets>) =>
  pipe(
    assets,
    AD2.toOption,
    O.getOrElse(() => [] as Assets)
  );
