import { Lens } from "monocle-ts";
import * as Eq from "fp-ts/Eq";
import * as AD from "lib/at-data/AsyncData";
import { Insights } from "lib/at-api/assets/insights";

export type AssetsInsightsState = {
  insights: AD.AsyncData<Array<Insights>>;
};

export const AssetsInsightsStateEq = Eq.struct<AssetsInsightsState>({
  insights: AD.getEq(Eq.eqStrict),
});

export const InsightsL = Lens.fromProp<AssetsInsightsState>()("insights");
