import { Container, Divider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon, { AtAlertWarning } from "components/ui/Icon";
import Logo from "components/ui/Logo";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import React from "react";
import palette from "theme/palette";
import { ErrorDisplay } from "views/public/welcome/ErrorDisplay";
import WelcomeAuth from "./WelcomeAuth";

interface IWelcome {
  onSignIn: () => void;
}

export const getAuth0Error = (url: string) =>
  pipe(new URLSearchParams(url), (_) =>
    pipe(
      _.get("error"),
      O.fromNullable,
      O.map((error) => ({
        error,
        errorDescription: _.get("error_description") as string | undefined,
      }))
    )
  );

// TODO:
// 1) style to match specs
// 2) ensure that if the error message is too long, it doesn't mess up the page(should be wrapped)
// 3) Maybe tooltip to see full message, if its wrapped
// to test, use this url: http://localhost:3000/?error=invalid_request&error_description=parameter%20organization%20is%20invalid%3A%20org_CjjuvFL63hnnJF5u&state=QkhIUUxrUF9VVk5lU1ktc2p4bWNQVVZZbEFROGRMMlQ3UzNSSFgwWEk2OQ%3D%3D
export const FoyerError: React.FC<{
  error: string;
  errorDescription?: string;
}> = (props) => (
  <ErrorDisplay>
    <Stack direction={"row"} spacing={1}>
      <Icon icon={AtAlertWarning} color={"white"} size={"16px"} />
      <p>
        <strong>{props.error}</strong>: {props.errorDescription}
      </p>
    </Stack>
  </ErrorDisplay>
);

const Welcome: React.FC<IWelcome> = (props) => (
  <StyledContainer>
    <Stack>
      <LogoBox>
        <Logo />
      </LogoBox>
      <Divider color={`${palette.neutral[700]}`} />
      <Stack>
        <WelcomeText mt={4}>
          Transforming how we <em>see</em> shared spaces.
        </WelcomeText>
        <WelcomeAuth onSignIn={props.onSignIn} />
        {pipe(
          window.location.search,
          getAuth0Error,
          O.fold(
            () => null,
            ({ error, errorDescription }) => (
              <FoyerError error={error} errorDescription={errorDescription} />
            )
          )
        )}
      </Stack>
    </Stack>
  </StyledContainer>
);

export default Welcome;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  flex-direction: column;
  max-width: 400px;
`;

const LogoBox = styled("div")`
  display: inline;
  width: auto;
  margin: auto auto 48px;
  color: ${palette.neutral[700]};
`;

const WelcomeText = styled(Typography)`
  color: ${palette.neutral[400]};
  font-size: 20px;
  margin-bottom: 32px;
  text-align: center;
`;
