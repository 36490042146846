import { pipe } from "fp-ts/function";
import * as C from "io-ts/Codec";
import { option } from "lib/codecs/option";
import { AssPTSumC } from "controllers/UIStateCollector/models/assetContextTabs";
import {
  AssetFilterModelEq,
  UIAssetsFilterModel,
} from "controllers/UIStateCollector/models/assetFilterModel";
import * as Eq from "fp-ts/Eq";
import * as O from "fp-ts/Option";
import { AssetsPageTabEq } from "controllers/UIStateCollector/models/AssetsPageTab";
import {
  UISelectedRangeModel,
  SelectedRangeModelEq,
} from "controllers/UIStateCollector/models/SelectedRangeModel";
import { AssetModel } from "lib/at-data/assets/models/AssetModel";
import { BBoxEq, BBoxModel } from "lib/at-data/BBox";
import * as n from "fp-ts/number";
import * as b from "fp-ts/boolean";
import { eqAsset } from "lib/at-data/assets/Asset";
import * as A from "fp-ts/Array";
import * as s from "fp-ts/string";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as D from "io-ts/Decoder";
import {
  ACTIVITY_MAP_LAYER,
  mapLayersEq,
} from "views/authenticated/activity/controller/state";
import { set } from "lib/codecs/optionFromNullable";
import * as S from "fp-ts/Set";
import { SelectedAssetIdsUIStateModel } from "controllers/UIStateCollector/models/SelectedAssetIdsUIStateModel";
import {
  SelectedSliceModelEq,
  UISelectedSliceModel,
} from "controllers/UIStateCollector/models/SelectedSliceModel";

export const ActivityPageUIStateFilterModel = pipe(
  UISelectedRangeModel,
  C.intersect(UISelectedSliceModel),
  C.intersect(UIAssetsFilterModel),
  C.intersect(C.struct({ bbox: option(BBoxModel), bearing: option(C.number) }))
);

export type ActivityPageUIStateFilter = C.TypeOf<
  typeof ActivityPageUIStateFilterModel
>;

export const LayerModel = D.union(
  D.literal(ACTIVITY_MAP_LAYER.LABELS),
  D.literal(ACTIVITY_MAP_LAYER.LABELS_DETAILED),
  D.literal(ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION),
  D.literal(ACTIVITY_MAP_LAYER.OCCUPANCY_HEATMAP),
  D.literal(ACTIVITY_MAP_LAYER.ZONE_OUTLINES),
  D.literal(ACTIVITY_MAP_LAYER.ZONE_FILL),
  D.literal(ACTIVITY_MAP_LAYER.ZONE_LABELS)
);

export const ActivityPageUIStateModel = pipe(
  C.struct({
    selectedSideItem: pipe(AssPTSumC, option),
    sidebarOpen: C.boolean,
    timebarOpen: C.boolean,
    layers: pipe(LayerModel, C.fromDecoder, set(mapLayersEq)),
  }),
  C.intersect(ActivityPageUIStateFilterModel),
  C.intersect(SelectedAssetIdsUIStateModel)
);

export const ActivityPageUIStateEq = Eq.struct<ActivityPageUIState>({
  assetsFilter: AssetFilterModelEq,
  selectedAssetIds: A.getEq(eqUUID),
  selectedSideItem: O.getEq(AssetsPageTabEq),
  sidebarOpen: b.Eq,
  timebarOpen: b.Eq,
  selectedRange: SelectedRangeModelEq,
  selectedSlice: O.getEq(SelectedSliceModelEq),
  bbox: O.getEq(BBoxEq),
  bearing: O.getEq(n.Eq),
  layers: S.getEq(mapLayersEq),
});

export type ActivityPageUIState = C.TypeOf<typeof ActivityPageUIStateModel>;
