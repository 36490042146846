import React, { useCallback, useState } from "react";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { pipe } from "fp-ts/function";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import {
  SelectedAssetL,
  SelectedAssetsController,
  SelectedAssetsL,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import * as O from "fp-ts/Option";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import { navigateToActivityNoRange } from "controllers/UIStateCollector/lens/activityPage";
import ContextualSidebar from "components/contextualSidebar/ContextualSidebar";
import Icon, {
  AtActivityMap,
  AtAdmin,
  AtArrowCaretDoubleRight,
  AtTagNames,
} from "components/ui/Icon";
import { EDIT_FEATURE } from "constants/featureFlags";
import ContextualSidebarDrawer from "components/contextualSidebar/ContextualSidebarDrawer";
import { IconButton, Stack, styled, Typography } from "@mui/material";
import AssetActionsMenu, {
  AssetAction,
} from "components/contextualSidebar/AssetActionsMenu";
import palette from "theme/palette";
import { ADT, match } from "ts-adt";
import {
  AssetAdminTab,
  AssetMultipleSelectionTab,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetsDetailPreviewTab";
import { alpha } from "@mui/system";
import { getName } from "lib/at-data/assets/getters";

export type LucisferSidePanelTab = ADT<{
  [ASSET_CONTEXT_TABS.ADMIN]: {};
  [ASSET_CONTEXT_TABS.SELECTION]: {};
}>;

export const LuciferPageSidepanel: React.FC<{}> = () => {
  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );
  const dispatch = useHistoryControllerDispatch(UIStateController);
  const [selectedSideItem, setSelectedSideItem] = useState<
    O.Option<LucisferSidePanelTab>
  >(O.none);

  const selectedAssetO = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );

  const selectedAssets = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetsL)
  );

  const handleClose = useCallback(() => setSelectedSideItem(O.none), []);

  const handleTabClick = useCallback(
    (selectedTab: string) =>
      setSelectedSideItem(O.some({ _type: selectedTab }) as any),
    []
  );

  const handleViewAssetInMap = useCallback(
    () =>
      dispatch(
        navigateToActivityNoRange({ selectedAsset: selectedAssetO })(allAssets)
      ),
    [selectedAssetO, allAssets, dispatch]
  );

  return (
    <ContextualSidebar
      selectedItem={pipe(
        selectedSideItem,
        O.map((_) => _._type)
      )}
      open={O.isSome(selectedSideItem)}
      onTabClick={handleTabClick}
      tabs={[
        {
          id: "admin",
          label: "Admin",
          icon: AtAdmin,
          featureFlag: EDIT_FEATURE,
        },
        {
          id: "selection",
          label: "Asset Selection",
          featureFlag: EDIT_FEATURE,
          hidden: selectedAssets.length < 1,
          icon: AtTagNames,
        },
      ]}
    >
      {
        <ContextualSidebarDrawer
          open={O.isSome(selectedSideItem)}
          hideBackdrop={true}
        >
          <StyledPanelHeader
            py={2}
            px={3}
            spacing={0}
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={1}
              >
                <AssetActionsMenu>
                  <AssetAction
                    onClick={handleViewAssetInMap}
                    icon={AtActivityMap}
                  >
                    View Asset In Explore Map
                  </AssetAction>
                </AssetActionsMenu>
                <Typography
                  variant="h3"
                  color={palette.neutral[100]}
                  sx={{ marginTop: "2px" }}
                >
                  {pipe(
                    selectedAssetO,
                    O.map(getName),
                    O.getOrElseW(() => null)
                  )}
                </Typography>
              </Stack>
              <IconButton
                color="primary"
                onClick={handleClose}
                aria-label="Close Side Panel"
              >
                <Icon icon={AtArrowCaretDoubleRight} size="16px" />
              </IconButton>
            </Stack>
          </StyledPanelHeader>
          {pipe(
            selectedSideItem,
            O.map(
              match({
                [ASSET_CONTEXT_TABS.ADMIN]: () =>
                  pipe(
                    selectedAssetO,
                    O.map((sa) => (
                      <AssetAdminTab onClose={handleClose} asset={sa} />
                    )),
                    O.getOrElseW(() => <div>No Asset Selected</div>)
                  ),
                [ASSET_CONTEXT_TABS.SELECTION]: () => (
                  <AssetMultipleSelectionTab />
                ),
              })
            ),
            O.getOrElseW(() => null)
          )}
          {/*<StyledPanelFooter spacing={1} mt="auto" p={2}>*/}
          {/*  <Button*/}
          {/*    component={Link}*/}
          {/*    variant="contained"*/}
          {/*    fullWidth={true}*/}
          {/*    to={{*/}
          {/*      pathname: `/activity`,*/}
          {/*      // state: {*/}
          {/*      //   initialAsset: O.of(selectedAssetO),*/}
          {/*      //   initialRange: range,*/}
          {/*      // },*/}
          {/*    }}*/}
          {/*    color="secondary"*/}
          {/*    sx={{ backgroundColor: palette.neutral[600] }}*/}
          {/*    startIcon={<Icon size="16px" icon={AtActivityMap} />}*/}
          {/*  >*/}
          {/*    View in Activity Map*/}
          {/*  </Button>*/}
          {/*</StyledPanelFooter>*/}
        </ContextualSidebarDrawer>
      }
    </ContextualSidebar>
  );
};

const StyledPanelHeader = styled(Stack)`
  z-index: 5;
  // width: 299px;
  box-shadow: 0 2px 24px ${alpha(palette.neutral[800], 0.5)};
`;
