import {
  addAssetActions,
  AssetActionsController,
} from "controllers/AssetsController/AssetActionsController";
import {
  capacity,
  modifyAsset,
  tags,
} from "controllers/AssetsController/Modification";
import { useCollectorDispatch } from "lib/at-react/hooks";
import React, { useCallback, useState } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { ZoneAssetCapacityPropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/ZoneAssetCapacityPropertyRow";
import { ZonePricePerAreaPropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/ZonePricePerAreaPropertyRow";
import { HoursOfOperationPropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/HoursOfOperationPropertyRow";
import { TargetUtilizationPropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/TargetUtilizationPropertyRow";
import {
  useCollectorOld,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  addAssetTags,
  LegacyAssetsModificationsController,
  deleteAssetTags,
  modifyAssetCapacity,
  modifyAssetTargetUtilization,
  modifyCostPerSqM,
  modifyHoursOfOperation,
} from "controllers/AssetsController/AssetsController";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isPositive } from "newtype-ts/lib/Positive";
import { ById, getAllChildren } from "lib/at-data/assets/filters";
import { updateAsset } from "lib/at-data/assets/modify";
import * as T from "monocle-ts/Traversal";
import {
  AssetTagsL,
  CapacityL,
  CostPerSqML,
  HoursOfOperationL,
  TargetUtilizationL,
} from "lib/at-data/assets/lens";
import { USDPerOneSqM } from "lib/at-data/units/currency";
import { fromPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import { HoursOfOperation } from "lib/at-data/HoursOfOperation";
import * as A from "fp-ts/Array";
import { AssetTagEq } from "lib/at-api/asset-tags/assetTags";
import {
  DefaultCapacityRatioTags,
  DefaultCapacityRatioType,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/DefaultCapacityRatioType";
import LabeledCheckbox from "components/ui/LabeledCheckbox";
import {
  AssetsFilterL,
  AssetsPageUIStateL,
  FilterByIdsL,
  FilterByNameL,
  FilterByTypeL,
} from "controllers/UIStateCollector/lens/assetsPage";
import * as L from "monocle-ts/Lens";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import * as R from "fp-ts/Reader";
import { getId } from "lib/at-data/assets/getters";
import { FilterByIds } from "controllers/UIStateCollector/models/assetFilterModel";
import * as b from "fp-ts/boolean";
import { clog, noop } from "lib/util";
import { Box, Stack } from "@mui/material";
import { fromLegacyAsyncData, toLegacyAsyncData } from "lib/at-data/AsyncData2";
import { Assets, getAllChildsWithParent } from "lib/at-data/assets/assets";
import * as AD2 from "lib/at-data/AsyncData2";
import palette from "theme/palette";
import { TimezonePropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/TimezonePropertyRow";

export const AnalyticsAssetProperties: React.FC<{ asset: Asset }> = (props) => {
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const assetActionsDispatch = useCollectorDispatch(AssetActionsController);

  const rootDispatch = useControllerDispatch(RawAssetsController);

  const removeTag = (newTags: string[]) => {
    return A.difference(AssetTagEq)(newTags);
  };

  const [hooChecked, setHooChecked] = useState(false);
  const handleSetChildHOO = () => {
    setHooChecked(!hooChecked);
  };
  const allAssetsAD = pipe(
    useCollectorOld(AssetsController, flow(L.prop("assets"))),
    fromAD2
  );
  const numChildrenAssets = pipe(
    props.asset,
    getAllChildren,
    R.map((children) => pipe(children, A.size))
  )({ assets: allAssetsAD });

  const handleSaveAssetCapacity = useCallback(
    (newCapacity: number) => {
      const capacityO = pipe(newCapacity, O.fromPredicate(isPositive));
      assetModificationsDispatch(
        flow(
          modifyAssetCapacity(props.asset, capacityO),
          deleteAssetTags([
            DefaultCapacityRatioTags[DefaultCapacityRatioType.DEFAULT],
          ])(props.asset)
        )
      );
      pipe(
        addAssetActions([
          pipe(capacity(capacityO), modifyAsset(props.asset.id)),
          pipe(
            props.asset.tags,
            removeTag([
              DefaultCapacityRatioTags[DefaultCapacityRatioType.DEFAULT],
            ]),
            tags,
            modifyAsset(props.asset.id)
          ),
        ]),
        assetActionsDispatch
      );
    },
    [props.asset, assetModificationsDispatch]
  );
  const handleSaveAssetPrice = useCallback(
    (pricePerSqM: O.Option<USDPerOneSqM>, priceChecked: boolean) => {
      assetModificationsDispatch(modifyCostPerSqM(props.asset, pricePerSqM));
      rootDispatch(
        pipe(
          ById(props.asset.id),
          updateAsset,
          T.composeLens(CostPerSqML),
          T.set(pricePerSqM)
        )
      );
      pipe(
        priceChecked,
        b.fold(
          () => {
            noop();
          },
          () => {
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a) =>
                  assetModificationsDispatch(modifyCostPerSqM(a, pricePerSqM))
                )
              )
            )({ assets: allAssetsAD });
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a2) =>
                  rootDispatch(
                    pipe(
                      ById(a2.id),
                      updateAsset,
                      T.composeLens(CostPerSqML),
                      T.set(pricePerSqM)
                    )
                  )
                )
              )
            )({ assets: allAssetsAD });
          }
        )
      );
    },
    [props.asset, assetModificationsDispatch, rootDispatch, modifyCostPerSqM]
  );

  const handleSaveAssetTargetUtilization = useCallback(
    (targetUtilization: number, targetUtilChecked: boolean) => {
      const targetUtilizationO = pipe(
        targetUtilization,
        O.fromPredicate(isPositive),
        O.map(fromPercentage)
      );
      assetModificationsDispatch(
        modifyAssetTargetUtilization(props.asset, targetUtilizationO)
      );
      rootDispatch(
        pipe(
          ById(props.asset.id),
          updateAsset,
          T.composeLens(TargetUtilizationL),
          T.set(targetUtilizationO)
        )
      );
      pipe(
        targetUtilChecked,
        b.fold(
          () => {
            noop();
          },
          () => {
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a) =>
                  assetModificationsDispatch(
                    modifyAssetTargetUtilization(a, targetUtilizationO)
                  )
                )
              )
            )({ assets: allAssetsAD });
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a2) =>
                  rootDispatch(
                    pipe(
                      ById(a2.id),
                      updateAsset,
                      T.composeLens(TargetUtilizationL),
                      T.set(targetUtilizationO)
                    )
                  )
                )
              )
            )({ assets: allAssetsAD });
          }
        )
      );
    },
    [
      props.asset,
      assetModificationsDispatch,
      rootDispatch,
      modifyAssetTargetUtilization,
    ]
  );

  const handleSaveHoursOfOperation = useCallback(
    (ho: HoursOfOperation) => {
      assetModificationsDispatch(
        modifyHoursOfOperation(props.asset, O.some(ho))
      );
      rootDispatch(
        pipe(
          ById(props.asset.id),
          updateAsset,
          T.composeLens(HoursOfOperationL),
          T.set(O.some(ho))
        )
      );
      pipe(
        hooChecked,
        b.fold(
          () => {
            noop();
          },
          () => {
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a) =>
                  assetModificationsDispatch(
                    modifyHoursOfOperation(a, O.some(ho))
                  )
                )
              )
            )({ assets: allAssetsAD });
            pipe(
              props.asset,
              getAllChildren,
              R.map(
                A.map((a2) =>
                  rootDispatch(
                    pipe(
                      ById(a2.id),
                      updateAsset,
                      T.composeLens(HoursOfOperationL),
                      T.set(O.some(ho))
                    )
                  )
                )
              )
            )({ assets: allAssetsAD });
          }
        )
      );
    },
    [
      props.asset,
      hooChecked,
      assetModificationsDispatch,
      rootDispatch,
      modifyHoursOfOperation,
    ]
  );

  return (
    <>
      <ZoneAssetCapacityPropertyRow
        asset={props.asset}
        onChange={handleSaveAssetCapacity}
      />
      <HoursOfOperationPropertyRow
        asset={props.asset}
        onChange={handleSaveHoursOfOperation}
      />

      <TimezonePropertyRow asset={props.asset} />

      <ZonePricePerAreaPropertyRow
        asset={props.asset}
        onChange={handleSaveAssetPrice}
      />
      <TargetUtilizationPropertyRow
        asset={props.asset}
        onChange={handleSaveAssetTargetUtilization}
      />
    </>
  );
};
