import * as Eq from "fp-ts/Eq";
import React, { Context, useEffect, useMemo, useRef } from "react";
import { RouteContext } from "contexts/RouteContext";
import { useStable } from "fp-ts-react-stable-hooks";
import { pipe } from "fp-ts/function";
import {
  ControllerProps,
  ControllerReactContext,
  useController,
} from "lib/at-react/defineController";
import * as s from "fp-ts/string";
import { History } from "history";

export type HistoryDispatch<T> = (fn: (a: T) => T, replace?: boolean) => void;
export type HistoryContext<T> = { history: History<T> };
export const HistoryContext = <T>(history: History<T>) => ({ history });

export type BrowserHistoryState<T> = {
  pathname: string;
  state: T;
};

export function useHistoryControllerDispatch<STATE, CONTEXT>(
  context: Context<ControllerReactContext<STATE, CONTEXT>>
): HistoryDispatch<STATE> {
  return useController(context, (state) => state)[1];
}

export const defineHistoryController = <STATE>(
  initialState: BrowserHistoryState<STATE>,
  stateEq: Eq.Eq<STATE>
): [
  React.FC<ControllerProps<HistoryContext<STATE> & RouteContext>>,
  React.Context<
    ControllerReactContext<
      BrowserHistoryState<STATE>,
      HistoryContext<STATE> & RouteContext
    >
  >
] => {
  const context = React.createContext<
    ControllerReactContext<
      BrowserHistoryState<STATE>,
      HistoryContext<STATE> & RouteContext
    >
  >([initialState, (d) => d]);

  return [
    (p) => {
      const [state, dispatch] = useStable<BrowserHistoryState<STATE>>(
        {
          pathname: p.context.history.location.pathname,
          state: p.context.history.location.state,
        },
        Eq.struct({
          pathname: s.Eq,
          state: stateEq,
        })
      );
      // the historyDispatch is behaving like react's dispatch, so it needs reference to state to avoid stale closures
      const stateRef = useRef(state);

      const historyDispatch: HistoryDispatch<BrowserHistoryState<STATE>> =
        useMemo(
          () =>
            (
              fn: (
                newState: BrowserHistoryState<STATE>
              ) => BrowserHistoryState<STATE>,
              replace?: boolean
            ) => {
              if (replace) {
                p.context.history.replace(pipe(stateRef.current, fn));
              } else {
                p.context.history.push(pipe(stateRef.current, fn));
              }
            },
          [stateRef]
        );

      useEffect(() => {
        // p.context.history.replace({ state: state.state });

        return p.context.history.listen((l) => {
          dispatch(l);
          stateRef.current = l;
        });
      }, []);

      return React.createElement(
        context.Provider,
        {
          value: [state, historyDispatch] as ControllerReactContext<
            BrowserHistoryState<STATE>,
            HistoryContext<STATE> & RouteContext
          >,
        },
        p.children
      );
    },
    context,
  ];
};
