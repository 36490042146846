import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import * as O from "fp-ts/Option";
import * as NEA from "fp-ts/NonEmptyArray";
import * as IO from "fp-ts/IO";
import { noop } from "lib/util";
import { AnyLayer, EventData, MapLayerEventType } from "mapbox-gl";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import { getApplySemigroup } from "fp-ts/Apply";

export const addLayer = (layer: AnyLayer, before?: string) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      // make sure our added layers are below any map labels
      // @ts-ignore
      const symbolLayer = ctx.map
        .getStyle()
        .layers?.find((l) => l.type === "symbol").id;
      ctx.map.addLayer(layer, before || symbolLayer);
      return layer.id;
    })
  );

export const hideMapLayer = (layer: AnyLayer) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.setLayoutProperty(layer.id, "visibility", "none");
    })
  );

export const showMapLayer = (layer: AnyLayer) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      ctx.map.setLayoutProperty(layer.id, "visibility", "visible");
      return layer.id;
    })
  );

export const getLayer = (layerId: string) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => pipe(ctx.map.getLayer(layerId), O.fromNullable))
  );

export const hideLayer = (id: string) =>
  pipe(id, getLayer, R.chain(O.fold(() => R.of(noop), hideMapLayer)));

export const hideLayers = (layers: Array<string>) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      layers.forEach((l) => {
        ctx.map.setLayoutProperty(l, "visibility", "none");
      });
    })
  );

export const showLayers = (layers: Array<string>) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      layers.forEach((l) => {
        ctx.map.setLayoutProperty(l, "visibility", "visible");
      });
    })
  );

export const showLayer = (id: string) =>
  pipe(id, getLayer, R.chain(O.fold(() => R.of(noop), showMapLayer)));

// maybe in the future this will update layer props dynamically
export const addOrUpdateLayer = (
  id: string,
  layer: AnyLayer,
  before?: string
) =>
  pipe(
    id,
    getLayer,
    R.chain(
      O.fold(
        () => addLayer(layer, before),
        flow((_) => _.id, IO.of, R.of)
      )
    )
  );

export const unbindMapEvents = pipe(
  R.ask<MapboxContext>(),
  R.map((ctx) => () => {
    const { layers } = ctx.map.getStyle();
    // See https://github.com/mapbox/mapbox-gl-js/issues/9802
    // @ts-ignore
    const existingListeners = ctx.map._listeners.click || [];
    existingListeners.forEach((handler: any) => {
      ctx.map.off("click", handler);
    });
  })
);

export const handleLayerEvent =
  <T extends keyof MapLayerEventType>(
    event: T,
    handler: (
      ctx: MapboxContext
    ) => (ev: MapLayerEventType[T] & EventData) => void
  ) =>
  (layerId: string) =>
    pipe(
      R.ask<MapboxContext>(),
      R.map((ctx) => () => {
        ctx.map.on(event, handler(ctx));
      })
    );
