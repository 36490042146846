import * as TE from "fp-ts/TaskEither";
import { handleResponse } from "lib/at-api/assets/getAssets";
import * as E from "fp-ts/Either";
import { BBox } from "lib/at-data/BBox";

export const generateOverpassquery = (bbox: string) => `
  [bbox:${bbox}][out:json][timeout:25];
    (
      // get all buildings
      way[building](${bbox});

    
    );
    out meta;
    >;
    out meta qt;
  `;
export const makeBBoxString = ([west, north, east, south]: BBox) => {
  return [north, west, south, east].join(",");
};
export const fetchOSMData = (query: string) =>
  TE.tryCatch(
    () =>
      fetch("https://overpass-api.de/api/interpreter", {
        method: "POST",
        body: query,
      }).then(handleResponse("OSM overpass API error")),
    E.toError
  );
