import { TableColumnTypes } from "controllers/AssetsTableController/tableColumnTypes";
import * as C from "io-ts/Codec";
import {
  TableColumnEq,
  TableColumnModel,
} from "controllers/AssetsTableController/models/TableColumn";
import { AssetsMetricColumnTypeModel } from "controllers/AssetsTableController/models/AssetsMetricColumnTypes";
import { pipe } from "fp-ts/function";
import * as D from "io-ts/Decoder";

export const AssetsMetricColumnModel = pipe(
  TableColumnModel,
  C.intersect(
    C.struct({
      type: C.fromDecoder(AssetsMetricColumnTypeModel),
      _type: C.fromDecoder(D.literal(TableColumnTypes.METRIC)),
    })
  )
);

export type AssetsMetricColumn = C.TypeOf<typeof AssetsMetricColumnModel>;

export const AssetsMetricColumnEq = TableColumnEq;
