import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tooltip,
} from "@mui/material";
import Icon from "components/ui/Icon";
import React from "react";
import { RiFilter3Fill } from "react-icons/ri";
import palette from "theme/palette";
import theme from "theme/theme";

const ReportsTableHeaderFilter: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Filter table" placement="top-end" arrow={true}>
        <StyledIconButton onClick={handleClick}>
          <Icon icon={RiFilter3Fill} />
        </StyledIconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClose}>Option 1</MenuItem>
        <MenuItem onClick={handleClose}>Option 2</MenuItem>
        <MenuItem onClick={handleClose}>Option 3</MenuItem>
        <Divider light />
        <MenuItem onClick={handleClose}>Option 4</MenuItem>
      </Menu>
    </>
  );
};

export default ReportsTableHeaderFilter;

const StyledIconButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  background-color: ${palette.neutral[200]};
  color: ${palette.neutral[700]};
  box-shadow: inset 0 0 0 0 ${palette.neutral[400]};
  transition: ${theme.transitions.create(["box-shadow"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};

  &:hover {
    box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
  }

  &:focus {
    box-shadow: inset 0 0 0 1.5px ${palette.primary[400]};
  }
`;
