import {
  addAssetActions,
  AssetActionsController,
} from "controllers/AssetsController/AssetActionsController";
import { modifyAsset, name } from "controllers/AssetsController/Modification";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { useCollectorDispatch } from "lib/at-react/hooks";
import { isNonEmptyString } from "newtype-ts/es6/NonEmptyString";
import React, { useCallback } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { useControllerDispatch } from "lib/at-react/defineController";
import {
  LegacyAssetsModificationsController,
  modifyAssetName,
} from "controllers/AssetsController/AssetsController";
import { RawAssetsController } from "views/authenticated/root/AssetsController";
import { flow, pipe } from "fp-ts/function";
import { TableCell, TableRow } from "@mui/material";
import * as s from "fp-ts/string";
import {
  Editable,
  OptionalProperty,
  Property,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import { TextEditor } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/editors";

export const AssetNamePropertyRow: React.FC<{ asset: Asset }> = (props) => {
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const assetActionsDispatch = useCollectorDispatch(AssetActionsController);

  const handleSaveAssetName = useCallback(
    (newName: string) => {
      pipe(
        newName,
        name,
        modifyAsset(props.asset.id),
        A.of,
        addAssetActions,
        assetActionsDispatch
      );
      assetModificationsDispatch(modifyAssetName(props.asset, newName));
    },
    [props.asset, assetModificationsDispatch, rootDispatch]
  );

  return (
    <TableRow
      key={"assetName"}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        name
      </TableCell>
      <TableCell align="right">
        <Editable
          onChange={handleSaveAssetName}
          value={props.asset.name}
          eq={s.Eq}
          editor={TextEditor}
        >
          <OptionalProperty
            value={pipe(props.asset.name, O.fromPredicate(isNonEmptyString))}
            component={Property}
          />
        </Editable>
      </TableCell>
    </TableRow>
  );
};
