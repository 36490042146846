import * as O from "fp-ts/Option";
import { GeoReference } from "lib/at-data/assets/models/GeoReferenceModel";
import * as E from "fp-ts/Either";
import { getSemigroup, NonEmptyArray } from "fp-ts/NonEmptyArray";
import { pipe } from "fp-ts/function";
import { Asset } from "lib/at-data/assets/Asset";
import { sequenceS } from "fp-ts/Apply";
import { ByTag } from "lib/at-data/assets/filters";
import { FloorMeta } from "lib/at-data/assets/models/FloorMetaModel";
import { FloorDiagram } from "lib/at-data/assets/models/FloorDiagramModel";
import { GeoCoord } from "lib/at-data/GeoCoord";

export const validateFloorGeoReference = (
  geoReference: O.Option<GeoReference>
): E.Either<NonEmptyArray<string>, GeoReference> =>
  pipe(
    geoReference,
    E.fromOption(() => ["Floor Georeference is Required"])
  );

export const validateManagedAssetTag = (
  asset: Asset
): E.Either<NonEmptyArray<string>, Asset> =>
  pipe(
    asset,
    E.fromPredicate(ByTag("Managed Assets"), () => [
      "Must have Managed Asset Tag",
    ])
  );

export const validateFloorMeta = (
  floorMeta: O.Option<FloorMeta>
): E.Either<NonEmptyArray<string>, FloorMeta> =>
  pipe(
    floorMeta,
    E.fromOption(() => ["Level Order is Required"])
  );

export const validateFloorDiagram = (
  floorDiagram: O.Option<FloorDiagram>
): E.Either<NonEmptyArray<string>, FloorDiagram> =>
  pipe(
    floorDiagram,
    E.fromOption(() => ["Floorplan image is required"])
  );

export const validateLevel = (level: Asset) =>
  pipe(
    sequenceS(E.getApplicativeValidation(getSemigroup<string>()))({
      floorMeta: validateFloorMeta(level.floorMeta),
      geoReference: validateFloorGeoReference(level.geoReference),
    }),
    E.map(() => level)
  );

export const validateGeometry = (
  geometry: O.Option<Array<GeoCoord>>
): E.Either<NonEmptyArray<string>, Array<GeoCoord>> =>
  pipe(
    geometry,
    E.fromOption(() => ["ESPG3857 geometry is required"])
  );

export const validateBuilding = (building: Asset) =>
  pipe(
    sequenceS(E.getApplicativeValidation(getSemigroup<string>()))({
      geometry: validateGeometry(building.geometry),
    }),
    E.map(() => building)
  );
