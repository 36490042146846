import * as C from "io-ts/Codec";
import { SeriesRequestModel } from "lib/at-data/requests/temporal/SeriesRequest";
import * as Eq from "fp-ts/Eq";
import * as D from "fp-ts/Date";
import * as N from "fp-ts/number";

export const UISelectedRangeModel = C.struct({
  selectedRange: SeriesRequestModel,
});
export const SelectedRangeModelEq = Eq.struct({
  start: D.Eq,
  end: D.Eq,
});
