import { and, not, or, Predicate } from "fp-ts/Predicate";
import { Assets, getByParent } from "lib/at-data/assets/assets";
import { flow, getSemigroup, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as ROA from "fp-ts/ReadonlyArray";
import * as O from "fp-ts/Option";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { concatAll } from "fp-ts/Magma";
import * as B from "fp-ts/boolean";
import * as S from "fp-ts/Set";
import { BBox } from "lib/at-data/BBox";
import {
  bboxPolygon,
  booleanPointInPolygon,
  Feature,
  Point,
  point,
  Polygon,
} from "@turf/turf";
import { GeoCoord } from "lib/at-data/GeoCoord";
import * as R from "fp-ts/Reader";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetType } from "lib/at-data/assets/AssetTypes";
import { AssetsContext } from "contexts/AssetsContext";
import { AssetTag } from "lib/at-api/asset-tags/assetTags";
import {
  getGeometry,
  getId,
  getParentId,
  getTags,
} from "lib/at-data/assets/getters";

export const ByType =
  (type: AssetType): Predicate<Asset> =>
  (a: Asset) =>
    a.type === type;

export const ByTag =
  (tag: AssetTag): Predicate<Asset> =>
  (a: Asset) =>
    pipe(
      a,
      getTags,
      A.some((_) => _ === tag)
    );

export const ById =
  (id: UUID): Predicate<Asset> =>
  (a: Asset) =>
    eqUUID.equals(id, a.id);

export const ByParentId =
  (id: O.Option<UUID>): Predicate<Asset> =>
  (a: Asset) =>
    O.getEq(eqUUID).equals(id, a.parent);

export const ByLegacyId =
  (legacyId: UUID): Predicate<Asset> =>
  (a: Asset) =>
    O.getEq(eqUUID).equals(O.some(legacyId), a.legacyZoneId);

export const ByText = (name: string): Predicate<Asset> =>
  pipe(
    (a: Asset) => UsingAllWords(name)(a.name),
    or(UsingAssetID(name)),
    or(UsingAssetLegacyID(name)),
    or(UsingAssetShortCode(name))
  );

export const isPointInsidePolygon =
  (poly: Feature<Polygon>) => (p: Feature<Point>) =>
    booleanPointInPolygon(p, poly);

export const isInside = (bbox: BBox) => (coords: Array<GeoCoord>) => {
  const bboxP = pipe(bbox, bboxPolygon);
  return pipe(coords, A.some(flow(point, isPointInsidePolygon(bboxP))));
};

export const ByBBox = (bbox: BBox): Predicate<Asset> =>
  flow(
    getGeometry,
    O.map(isInside(bbox)),
    O.getOrElse(() => false as boolean)
  );

export const byTypeAndBBox = (bbox: BBox) => (at: AssetType) =>
  pipe(ByBBox(bbox), and(ByType(at)));

export const ByWord =
  (word: string): Predicate<string> =>
  (subject: string) =>
    subject.toLocaleLowerCase().includes(word.toLocaleLowerCase());

export const UsingAllWords = (searchString: string) =>
  concatAll(getSemigroup(B.SemigroupAll)<string>())(() => true)(
    searchString.split(" ").map(ByWord)
  );

export const UsingAssetID =
  (id: string): Predicate<Asset> =>
  (asset: Asset) =>
    eqUUID.equals(id as UUID, asset.id);

export const UsingAssetShortCode =
  (id: string): Predicate<Asset> =>
  (asset: Asset) =>
    pipe(
      asset.shortCode,
      O.filter((sc) => sc.startsWith(id.toUpperCase())),
      O.isSome
    );

export const UsingAssetLegacyID =
  (id: string): Predicate<Asset> =>
  (asset: Asset) =>
    O.getEq(eqUUID).equals(O.some(id as UUID), asset.legacyZoneId);

export const filterAssets = (p: Predicate<Asset>) => (assets: Assets) =>
  pipe(assets, A.filter(p));

export const getAssetsByType = (type: AssetType) => filterAssets(ByType(type));

export const getBuildings = getAssetsByType("building");
export const getZones = getAssetsByType("zone");
export const getZonesAndAreas = filterAssets(
  pipe(ByType("area"), or(ByType("zone")))
);
export const getFloors = getAssetsByType("floor");
export const getCampuses = getAssetsByType("campus");
export const getAreas = getAssetsByType("area");
export const getAssetById = (id: UUID) => flow(filterAssets(ById(id)), A.head);

export const getAssetsByIds = (ids: Set<UUID>) => (assets: Assets) =>
  pipe(
    assets,
    A.filter((asset) => pipe(ids, S.elem(eqUUID)(asset.id)))
  );

export const getAssetByLegacyId = (id: UUID) =>
  flow(filterAssets(ByLegacyId(id)), A.head);

export const getParent =
  (a: Asset) =>
  (assets: Assets): O.Option<Asset> =>
    pipe(
      a.parent,
      O.chain((parentId) => getAssetById(parentId)(assets))
    );

export const getPeers = (a: Asset) => (assets: Assets) =>
  pipe(assets, filterAssets(ByParentId(a.parent)));

//Direct offspring assets.
export const getDirectChildren = (a: Asset) =>
  pipe(
    R.asks<AssetsContext, Assets>((_) => _.assets),
    R.map(getByParent(a))
  );

//All assets in the hierarchy under the current asset
export const getAllChildren = (a: Asset): R.Reader<AssetsContext, Assets> =>
  pipe(
    a,
    getDirectChildren,
    R.chain((children) =>
      pipe(
        R.traverseArray(getAllChildren)(children),
        R.map(flow(ROA.flatten, ROA.concat(children)))
      )
    ),
    R.map(ROA.toArray)
  );

export const getParentAssetOf =
  (type: Predicate<Asset>) =>
  (asset: Asset): R.Reader<Assets, O.Option<Asset>> =>
    pipe(
      asset,
      type,
      B.fold(
        () =>
          pipe(
            R.ask<Assets>(),
            R.map(getParent(asset)),
            R.chain(O.fold(() => R.of(O.none), getParentAssetOf(type)))
          ),
        () => pipe(asset, O.some, R.of)
      )
    );

export const getParentLine =
  (asset: Asset) =>
  (assets: Assets): Array<UUID> =>
    pipe(
      getParent(asset)(assets),
      O.fold(
        () => [asset.id],
        (parent) =>
          pipe(A.of(asset.id), A.concat(getParentLine(parent)(assets)))
      )
    );
export const getParentLineById =
  (assetId: UUID) =>
  (assets: Assets): Array<Asset> =>
    pipe(
      getAssetById(assetId)(assets),
      O.fold(
        () => [],
        (a) =>
          pipe(
            A.of(a),
            A.concat(
              pipe(
                a.parent,
                O.fold(
                  () => [],
                  (parentId) => getParentLineById(parentId)(assets)
                )
              )
            )
          )
      )
    );

export const filterChildren = (assets: Assets): Assets => {
  const allIds = new Set(pipe(assets, A.map(getId)));
  const presentParents = new Set(
    pipe(
      assets,
      A.filterMap(
        flow(
          getParentId,
          O.filter((pid) => allIds.has(pid))
        )
      )
    )
  );
  return pipe(
    assets,
    A.filter((a) =>
      pipe(
        a.parent,
        O.map((pid) => !presentParents.has(pid)),
        O.getOrElseW(() => true)
      )
    )
  );
};
