import { FeatureCollection } from "@turf/turf";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as AD from "lib/at-data/AsyncData";
import * as Cl from "lib/at-react/collector";
import { reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";

export type OSMBuildingsCollectorState = {
  osmBuildings: AD.AsyncData<FeatureCollection>;
};

export const OsmBuildingsL = pipe(
  L.id<OSMBuildingsCollectorState>(),
  L.prop("osmBuildings")
);

export const OSMBuildingsCollector = pipe(
  reactState<OSMBuildingsCollectorState>(
    { osmBuildings: AD.DoneData(O.none) },
    Eq.struct({
      osmBuildings: AD.getEq(Eq.eqStrict),
    })
  ),
  Cl.makeMemo(Eq.struct({}))
);
