import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/function";
import { differenceInSeconds } from "date-fns/fp";
import * as O from "fp-ts/Option";
import * as t from "io-ts";
import * as C from "io-ts/Codec";
import * as D from "io-ts/Decoder";

export enum RESOLUTION {
  MINUTES = 60,
  FIVE_MINUTES = 300,
  HALF_HOUR = 1800,
  HOUR = 3600,
  TWELVE_HOURS = 43200,
  DAY = 86400,
}

export const ResolutionModel = D.union(
  D.literal(RESOLUTION.MINUTES),
  D.literal(RESOLUTION.FIVE_MINUTES),
  D.literal(RESOLUTION.HALF_HOUR),
  D.literal(RESOLUTION.HOUR),
  D.literal(RESOLUTION.TWELVE_HOURS),
  D.literal(RESOLUTION.DAY)
);

export const roundDown =
  (resolution: RESOLUTION) =>
  (timestamp: Date): Date => {
    return new Date(
      Math.floor(+timestamp.valueOf() / (+resolution * 1000)) *
        (+resolution * 1000)
    );
  };

export const roundUp =
  (resolution: RESOLUTION) =>
  (timestamp: Date): Date => {
    return new Date(
      Math.ceil(+timestamp.valueOf() / (+resolution * 1000)) *
        (+resolution * 1000)
    );
  };
