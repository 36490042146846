import { subHours } from "date-fns/fp";
import { calculateResolutionByNumberOfBuckets } from "lib/at-data/requests/temporal/FromNumberOfBuckets";
import { GranulatedSeriesRequest } from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { SliceRequest } from "lib/at-data/requests/spatial/SliceRequest";
import { UUID } from "lib/at-data/UUID";
import * as TE from "fp-ts/TaskEither";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Assets } from "lib/at-data/assets/assets";
import * as D from "lib/at-data/DataSeries";
import { normalizeToResolution } from "lib/at-data/DataSeries";
import * as S from "lib/at-data/Slice";
import Worker from "workers/occupancyWorker";
import { Asset } from "lib/at-data/assets/Asset";
import { AppContext } from "contexts/AppContext";
import { sequenceT } from "fp-ts/Apply";

const occupancyWorker = new Worker();

export const getMultipleOccupancyCountsSeriesMT: (
  assets: Assets,
  request: GranulatedSeriesRequest
) => (
  context: AppContext
) => TE.TaskEither<Error, S.Slice<UUID, D.DataSeries<OccupancyEstimate>>> =
  (assets, request) => (context: AppContext) => () =>
    occupancyWorker.occupancyCountsMultipleSeriesFromBackendMT(
      assets,
      request,
      context.appContext
    );

export const getSingleOccupancyCountsSeriesMT2 =
  (asset: Asset) => (request: GranulatedSeriesRequest) =>
    getSingleOccupancyCountsSeriesMT(asset, request);

export const getSingleOccupancyCountsSeriesMT: (
  asset: Asset,
  request: GranulatedSeriesRequest
) => (
  context: AppContext
) => TE.TaskEither<Error, D.DataSeries<OccupancyEstimate>> =
  (asset, request) => (context: AppContext) => () =>
    occupancyWorker.occupancyCountsSingleSeriesFromBackendMT(
      asset,
      request,
      context.appContext
    );

export const getAssetsMultipleOccupancyCountsSeries = (
  assets: Assets,
  range: GranulatedSeriesRequest
) => {
  return getMultipleOccupancyCountsSeriesMT(assets, range);
};

export const NUMBER_OF_SERIES_DATA_POINTS = 50;

export const getRootSeries: (
  rootAssetO: O.Option<Asset>,
  liveTimestampO: O.Option<Date>
) => (
  context: AppContext
) => TE.TaskEither<Error, D.DataSeries<OccupancyEstimate>> =
  (rootAssetO: O.Option<Asset>, liveTimestampO: O.Option<Date>) =>
  (context: AppContext) => {
    return pipe(
      sequenceT(O.Monad)(
        rootAssetO,
        pipe(
          liveTimestampO,
          O.map(
            flow(
              (end) =>
                pipe(
                  SeriesRequest(pipe(end, subHours(24)), end),
                  calculateResolutionByNumberOfBuckets(
                    NUMBER_OF_SERIES_DATA_POINTS
                  )
                ),
              normalizeToResolution
            )
          )
        )
      ),
      TE.fromOption(() => new Error("Data not ready")),
      TE.chain(
        ([rootAsset, request]) =>
          () =>
            occupancyWorker.occupancyCountsSingleSeriesFromBackendMT(
              rootAsset,
              request,
              context.appContext
            )
      )
    );
  };
