import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import * as A from "fp-ts/Array";
import React from "react";
import { UsdCell } from "views/authenticated/assets/page/components/AssetsTable/cells";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as t from "io-ts";
import * as E from "fp-ts/Either";
import { sequenceS, sequenceT } from "fp-ts/Apply";
import {
  mean,
  meanUSD,
  sumUSD,
} from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
import { getAreaCost } from "views/authenticated/assets/page/components/AssetsTable/columns/metric/PricePerAreaUnitColumn";
import { formatNumber, UINumber } from "lib/formatters/formatNumber";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { Asset } from "lib/at-data/assets/Asset";
import { toSqFt } from "lib/at-data/assets/spatial";
import { isoSqFt, isoSqM, SqM } from "lib/at-data/units/area";
import { option } from "lib/codecs/option";
import {
  isoUSD,
  isoUSDPerOneSqM,
  USD,
  USDModel,
  USDPerOneSqM,
} from "lib/at-data/units/currency";
import { getArea } from "lib/at-data/assets/getters";

export const calculateCost = ({
  costPerSqM,
  area,
}: {
  costPerSqM: USDPerOneSqM;
  area: SqM;
}): USD =>
  isoUSD.wrap(isoUSDPerOneSqM.unwrap(costPerSqM) * isoSqM.unwrap(area));

export const getCost = pipe(
  sequenceS(R.Monad)({
    area: pipe(
      R.asks<AssetRowDataContext, Asset>((_) => _.asset),
      R.map(flow(getArea, O.map(isoSqM.modify(Math.round))))
    ),
    costPerSqM: getAreaCost,
  }),
  R.map(flow(sequenceS(O.Monad))),
  R.map((cost) => ROR.singleton("cost", pipe(cost, O.map(calculateCost))))
);

export const CostColumn = defineColumn(
  "cost",
  getCost,
  R.of(UsdCell),
  flow(
    readColumnData,
    A.filterMap(
      flow(
        ROR.lookup("cost"),
        O.chain(flow(option(USDModel).decode, O.fromEither, O.flatten))
      )
    ),
    sumUSD,
    O.map(flow(Math.round, formatNumber(UINumber))),
    O.map((value) => `$${value}`),
    (value) => (
      <TableHeaderKpi
        label="Cost"
        hint="The annualized cost of a space calculated as price multiplied by size."
        value={value}
      />
    )
  ),
  R.of({
    defaultFlex: 0.5,
    sort: O.getOrd(N.Ord).compare,
  })
);
