import React from "react";
import {
  Stack,
  Typography,
  styled,
  Button,
  Menu,
  MenuItem,
  alpha,
  Paper,
} from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import palette from "theme/palette";
import Icon, {
  AtBuilding,
  AtCampus,
  AtChevronDown,
  AtEmpty,
  AtFloor,
  AtRoom,
} from "components/ui/Icon";

const AssetList: React.FC<{}> = () => {
  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<AtChevronDown />}
      // defaultExpandIcon={<AtMetrics />}
      sx={{ height: "auto", flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      <StyledTreeItem nodeId="1" label="Building 50" icon={<AtBuilding />}>
        <StyledTreeItem
          nodeId="2"
          label="Building 50 - 1st Floor"
          icon={<AtFloor />}
        >
          <StyledTreeItem
            nodeId="21"
            label="Huddle Room B (Room 110)"
            icon={<AtRoom />}
          />
          <StyledTreeItem
            nodeId="22"
            label="Huddle Room A (Room 109)"
            icon={<AtRoom />}
          />
          <StyledTreeItem
            nodeId="23"
            label="Collab Space R"
            icon={<AtRoom />}
          />
          <StyledTreeItem nodeId="24" label="Board Room" icon={<AtRoom />} />
          <StyledTreeItem nodeId="25" label="Lab B" icon={<AtRoom />} />
          <StyledTreeItem nodeId="26" label="Lab A" icon={<AtRoom />} />
          <StyledTreeItem nodeId="27" label="Cafe" icon={<AtRoom />} />
          <StyledTreeItem
            nodeId="28"
            label="Neighborhood 2A (Venus)"
            icon={<AtRoom />}
          />
          <StyledTreeItem
            nodeId="29"
            label="Conf Room B (Room 106)"
            icon={<AtRoom />}
          />
          <StyledTreeItem nodeId="210" label="Gym" icon={<AtRoom />} />
          <StyledTreeItem
            nodeId="211"
            label="Agile Space 1"
            icon={<AtRoom />}
          />
        </StyledTreeItem>
        <StyledTreeItem
          nodeId="3"
          label="Building 50 - 2nd Floor"
          icon={<AtFloor />}
        />
      </StyledTreeItem>
      <StyledTreeItem nodeId="4" label="Quincy" icon={<AtBuilding />}>
        <StyledTreeItem
          nodeId="5"
          label="Quincy - 1st Floor"
          icon={<AtFloor />}
        />
        <StyledTreeItem
          nodeId="6"
          label="Quincy - 2nd Floor"
          icon={<AtFloor />}
        />
      </StyledTreeItem>
    </TreeView>
  );
};

const AssetSelector: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="column"
      alignContent="flex-start"
      alignItems="center"
      mx={2}
    >
      <Button
        id="button-a"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={
          <Icon
            icon={AtChevronDown}
            size="24px"
            color={`${palette.neutral[100]}`}
          />
        }
      >
        Building 50 - Floor 1
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        color="secondary"
        disableScrollLock={true}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        mode="dark"
        elevation={24}
      >
        {/*<MenuItem onClick={handleClose}>Profile</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
        {/*<MenuItem onClick={handleClose}>Logout</MenuItem>*/}
        <AssetList />
      </StyledMenu>
      <Typography
        sx={{
          marginTop: "12px",
          marginLeft: "0px",
          textTransform: "uppercase",
          color: `${palette.neutral[400]}`,
          fontSize: "11px",
        }}
      >
        Est. Total Occupancy: 67
      </Typography>
    </Stack>
  );
};

const AssetSelectorMenu: React.FC<{}> = () => {
  return (
    <>
      <Stack direction="row" alignContent="flex-start" sx={{ width: "100%" }}>
        <LegendContainer>
          <img
            src={"/images/legend-placeholder.svg"}
            alt={"legend placeholder"}
            width={"31px"}
          />
        </LegendContainer>
        <AssetSelector />
      </Stack>
    </>
  );
};

export default AssetSelectorMenu;

const StyledMenu = styled(Menu)`
  & .MuiList-root {
    width: 380px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: ${palette.neutral[700]};
  }
`;
const StyledTreeItem = styled(TreeItem)`
  color: white;
  padding: 0;
  width: 100%;

  & .MuiTreeItem-label {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .MuiTreeItem-content {
    padding: 8px 16px;
    transition: all 0;

    &:hover,
    &:focus,
    &.Mui-focused {
      background-color: ${alpha(palette.neutral[600], 0.5)};
      box-shadow: -100px 0 0 ${alpha(palette.neutral[600], 0.5)};
    }

    &.Mui-selected {
      background-color: ${palette.neutral[600]};
      box-shadow: -100px 0 0 ${palette.neutral[600]};
    }

    &.Mui-selected.Mui-focused,
    &.Mui-selected:hover {
      background-color: ${alpha(palette.neutral[600], 0.75)};
      box-shadow: -100px 0 0 ${alpha(palette.neutral[600], 0.75)};
    }

    & svg {
      fill: ${palette.neutral[200]};
    }
  }
`;
const LegendContainer = styled("div")`
  width: 48px;
  display: block;
  position: relative;
  & img {
    position: absolute;
    left: 0;
  }
`;
