import { makeMemo } from "lib/at-react/collector";
import { pipe } from "fp-ts/function";
import * as Eq from "fp-ts/Eq";
import * as A from "fp-ts/Array";
import * as D from "fp-ts/Date";
import * as s from "fp-ts/string";
import { reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";

export type LambentDashboardNotificationType =
  | "error"
  | "warning"
  | "info"
  | "success";

export type LambentDashboardNotification = {
  timestamp: Date;
  type: LambentDashboardNotificationType;
  message: string;
};
export const LambentDashboardNotificationEq = Eq.struct({
  timestamp: D.Eq,
  type: s.Eq,
  message: s.Eq,
});

export type NotificationsControllerState = {
  notifications: Array<LambentDashboardNotification>;
};

export const NotificationsControllerStateEq =
  Eq.struct<NotificationsControllerState>({
    notifications: A.getEq(LambentDashboardNotificationEq),
  });

export const NotificationL = pipe(
  L.id<NotificationsControllerState>(),
  L.prop("notifications")
);

export const LambentDashboardNotification = (
  type: LambentDashboardNotificationType,
  message: string
): LambentDashboardNotification => ({
  type,
  message,
  timestamp: new Date(),
});

export const successNotification = (message: string) =>
  pipe(
    NotificationL,
    L.modify((notifications) =>
      pipe(
        notifications,
        A.append(LambentDashboardNotification("success", message))
      )
    )
  );

export const errorNotification = (message: string) =>
  pipe(
    NotificationL,
    L.modify((notifications) =>
      pipe(
        notifications,
        A.append(LambentDashboardNotification("error", message))
      )
    )
  );

export const initalState: NotificationsControllerState = {
  notifications: [],
};

export const NotificationsController = pipe(
  reactState<NotificationsControllerState>(
    initalState,
    NotificationsControllerStateEq
  ),
  makeMemo(Eq.struct({}))
);
