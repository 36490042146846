import React from "react";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import {
  SelectedAssetL,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import * as L from "monocle-ts/Lens";
import { AssetsController } from "views/authenticated/root/AssetsController";
import { AssetsL } from "views/authenticated/root/controller/state";
import { useStable, useStableEffect } from "fp-ts-react-stable-hooks";
import { pipe } from "fp-ts/function";
import { assetsToShow } from "views/authenticated/activity/controller/assetsToShow";
import {
  Assets,
  eqAssets,
  eqAssetsByUUIDOnly,
  getByParent,
} from "lib/at-data/assets/assets";
import * as O from "fp-ts/Option";
import { isBuilding, isFloor, isZone } from "lib/at-data/assets/predicates";
import { or } from "fp-ts/Predicate";
import { getParentAssetOf } from "lib/at-data/assets/filters";
import Page from "components/ui/Page";
import PageHeader from "components/ui/PageHeader";
import { LuciferHeader } from "views/authenticated/lucifer/components/LuciferHeader";
import { LevelManagement } from "views/authenticated/lucifer/LevelManagement";
import { eqAsset } from "lib/at-data/assets/Asset";
import * as Eq from "fp-ts/Eq";
import { SelectedAssetHierarchyCollector } from "controllers/SelectedAssetHierarchyCollector/SelectedAssetHierarchyCollector";
import { sequenceT } from "fp-ts/Apply";
import { useCollector } from "lib/at-react/hooks";

export const BuildingEditor: React.FC<{}> = (props) => {
  const {
    selectedBuildingLevels,
    selectedBuilding,
    selectedLevel,
    selectedLevelAssets,
  } = useCollector(
    SelectedAssetHierarchyCollector,
    L.props(
      "selectedBuildingLevels",
      "selectedBuilding",
      "selectedLevel",
      "selectedLevelAssets"
    )
  );

  return (
    <Page>
      {/*<PageHeader header={<LuciferHeader building={selectedBuilding} />} />*/}
      {pipe(
        sequenceT(O.Monad)(
          selectedBuilding,
          selectedLevel,
          selectedBuildingLevels,
          selectedLevelAssets
        ),
        O.fold(
          () => <div>Please select a level</div>,
          ([
            selectedBuilding2,
            selectedLevel2,
            selectedBuildingLevels2,
            selectedLevelAssets2,
          ]) => (
            <LevelManagement
              selectedBuilding={selectedBuilding2}
              selectedLevel={selectedLevel2}
              selectedBuildingLevels={selectedBuildingLevels2}
              selectedLevelAssets={selectedLevelAssets2}
            />
          )
        )
      )}
    </Page>
  );
};
