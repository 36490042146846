import {
  alpha,
  styled,
  Tab,
  TabProps,
  Tabs,
  Tooltip,
  Stack,
} from "@mui/material";
import Icon, { AtActivityMap, AtAssets, AtCompare } from "components/ui/Icon";
import React, { HTMLAttributeAnchorTarget, useCallback, useState } from "react";
import { RiBarChartFill } from "react-icons/ri";
import { LinkProps } from "react-router-dom";
import palette from "theme/palette";
import theme from "theme/theme";
import { flow, pipe } from "fp-ts/function";
import { clog, removeStartSlash } from "lib/util";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import {
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { PathL } from "controllers/UIStateCollector/models/UIStateModel";
import {
  FeatureFlag,
  NeedsPermission,
} from "components/featureFlag/FeatureFlag";
import { COMPARE_FEATURE, REPORTS_FEATURE } from "constants/featureFlags";
import mixpanel from "mixpanel-browser";
import {
  AssetProperty,
  ValueType,
} from "controllers/AssetsController/AssetModification";

const LinkTab: React.ComponentType<
  TabProps & LinkProps & { tooltip?: string }
> = Tab as React.ComponentType<TabProps & LinkProps>;

export const NewLink = React.forwardRef<
  HTMLAnchorElement,
  { to: string; target?: HTMLAttributeAnchorTarget; tooltip?: string }
>(function LinkWithRef({ to, target, tooltip, ...rest }, ref) {
  const dispatch = useControllerDispatch(UIStateController);

  const handleClick = useCallback(
    (ev: any) => {
      mixpanel.track("Navigate", { page: to });
      pipe(to, PathL.set, dispatch);
      ev.stopPropagation();
    },
    [dispatch, to]
  );

  const startTime = performance.now();

  window.addEventListener("beforeunload", () => {
    const endTime = performance.now();
    const timeSpentSeconds = Math.round((endTime - startTime) / 1000);
    if (to === "/assets") {
      mixpanel.track("On Analyze Page", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (to === "/activity") {
      mixpanel.track("On Explore View", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (to === "/reports_archive") {
      mixpanel.track("On Reports Page", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
  });

  return (
    <Tooltip disableFocusListener title={<Stack>{tooltip}</Stack>}>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a {...rest} onClick={handleClick} ref={ref} target={target} />
    </Tooltip>
  );
});

export const NewLink2 = React.forwardRef<
  HTMLAnchorElement,
  { to: string; target?: HTMLAttributeAnchorTarget }
>(function LinkWithRef({ to, target, ...rest }, ref) {
  const dispatch = useControllerDispatch(UIStateController);
  const href = "";
  const handleClick = useCallback(
    (ev: any) => {
      pipe(to, PathL.set, dispatch);
      ev.stopPropagation();
    },
    [dispatch, to]
  );

  return (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a {...rest} onClick={handleClick} ref={ref} target={target} />
    </span>
  );
});

const tabs = ["assets", "activity", "reports_archive", "reports", "compare"];

const TabsRecord: Record<string, string | number> = {
  assets: "assets",
  activity: "activity",
  reports_archive: 2,
  reports: 3,
  compare: 4,
};

const NavTabs: React.FC = () => {
  const [selectedTab] = useController(
    UIStateController,
    flow(PathL.get, removeStartSlash)
  );

  return (
    <Tabs
      value={
        TabsRecord[
          pipe(
            tabs,
            A.findFirst((_) => _ === selectedTab),
            O.getOrElse(() => tabs[1])
          )
        ]
      }
      aria-label="navigation tabs"
    >
      <StyledTab
        aria-label="Explore"
        component={NewLink}
        to={"/activity"}
        value="activity"
        icon={<Icon icon={AtActivityMap} size="24px" />}
        tooltip={"Explore"}
      />
      <StyledTab
        aria-label="Analyze"
        component={NewLink}
        to={"/assets"}
        value="assets"
        icon={<Icon icon={AtAssets} size="24px" />}
        tooltip={"Analyze"}
      />
      <NeedsPermission permission={"reports:read"}>
        <StyledTab
          aria-label="Reports"
          component={NewLink}
          to={"/reports_archive"}
          value="reports_archive"
          icon={<Icon icon={RiBarChartFill} size="24px" />}
          tooltip={"Report"}
        />
      </NeedsPermission>
      <FeatureFlag flag={REPORTS_FEATURE}>
        <StyledTab
          aria-label="Report"
          component={NewLink}
          to={"/reports"}
          value="reports"
          icon={<Icon icon={RiBarChartFill} size="24px" />}
          tooltip={"Report"}
        />
      </FeatureFlag>
      <FeatureFlag flag={COMPARE_FEATURE}>
        <StyledTab
          aria-label="Plan"
          component={NewLink}
          to="/compare"
          value="compare"
          icon={<Icon icon={AtCompare} size="24px" />}
          tooltip={"Plan"}
        />
      </FeatureFlag>
      {/*<FeatureFlag flag={EDIT_FEATURE}>*/}
      {/*  <StyledTab*/}
      {/*    aria-label="Building Editor"*/}
      {/*    component={NewLink}*/}
      {/*    to="/lucifer"*/}
      {/*    value="Building Editor"*/}
      {/*    icon={<Icon icon={GiEvilBook} size="24px" />}*/}
      {/*    tooltip={"Management"}*/}
      {/*  />*/}
      {/*</FeatureFlag>*/}
    </Tabs>
  );
};

const StyledTab = styled(LinkTab)`
  z-index: 1;
  min-width: 0;
  padding: ${theme.spacing(2)} ${theme.spacing(2.5)};
  color: ${palette.neutral[500]};

  &.Mui-disabled {
    color: ${alpha(palette.neutral[500], 0.5)};
  }

  &.Mui-selected {
    color: ${palette.neutral[100]};
  }

  & svg {
    pointer-events: none;
  }

  &:hover {
    background-color: ${alpha(palette.neutral[600], 0.6)};
    opacity: 1;
    transition: "opacity 0.3s";
  }
  '&:not(:hover)': {
    opacity: 0.7;
  },
`;

const StyledTooltip = styled(Tooltip)`
  margin-top: -50px;
`;

export default NavTabs;
