import { pipe } from "fp-ts/function";
import * as C from "io-ts/Codec";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as Eq from "fp-ts/Eq";
import * as A from "fp-ts/Array";
import { BBoxEq, BBoxModel } from "lib/at-data/BBox";
import { option } from "lib/codecs/option";
import * as O from "fp-ts/Option";
import * as D from "io-ts/Decoder";
import * as s from "fp-ts/string";
import {
  eqGeoReference,
  GeoReferenceModel,
} from "lib/at-data/assets/models/GeoReferenceModel";

export enum Workspaces {
  LOCAL = "local",
  GEO = "geo",
  WORKSHEET = "worksheet",
}

export enum LocalWorkspaceMode {
  EDIT = "edit",
  MOVE = "move",
  ADD_POLYGON = "add_polygon",
}

export const LuciferPageUIStateModel = pipe(
  C.struct({
    selectedAssetIds: C.array(UUID),
    selectedWorkspace: C.fromDecoder(
      D.union(
        D.literal(Workspaces.GEO),
        D.literal(Workspaces.LOCAL),
        D.literal(Workspaces.WORKSHEET)
      )
    ),
    geographicalWorkspace: C.struct({
      bbox: option(BBoxModel),
    }),
    localWorkSpace: C.struct({
      initialGeoReference: option(GeoReferenceModel),
      mode: C.fromDecoder(
        D.union(
          D.literal(LocalWorkspaceMode.EDIT),
          D.literal(LocalWorkspaceMode.MOVE),
          D.literal(LocalWorkspaceMode.ADD_POLYGON)
        )
      ),
    }),
  })
);

export type LuciferPageUIState = C.TypeOf<typeof LuciferPageUIStateModel>;

export const LuciferPageUIStateEq = Eq.struct<LuciferPageUIState>({
  selectedAssetIds: A.getEq(eqUUID),
  selectedWorkspace: s.Eq,
  geographicalWorkspace: Eq.struct({
    bbox: O.getEq(BBoxEq),
  }),
  localWorkSpace: Eq.struct({
    initialGeoReference: O.getEq(eqGeoReference),
    mode: s.Eq,
  }),
});
