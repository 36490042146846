import React from "react";

export const UTCLogo = () => (
  <svg
    width="125"
    height="111"
    viewBox="0 0 125 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.223 0.841243H61.892H61.7871H61.6822C49.3054 1.11692 33.902 1.45638 23.3834 10.8252C15.86 17.4827 13.6689 26.2797 9.69004 42.2476L9.68387 42.2723L9.6777 42.2991L3.67235 67.0163L3.63532 67.183L3.60035 67.3496C2.63136 72.0669 1.93805 76.8708 1.52659 80.072C0.648121 86.896 0.771559 87.828 0.888825 88.7332C1.58831 94.0144 4.77926 103.426 18.3199 107.316C25.1173 109.269 32.4783 109.269 37.3459 109.269H92.6988H100.364L101.959 101.768L105.929 83.0792L108.363 71.6201L96.653 71.6406L65.5005 71.6983H56.7219C58.8431 60.5477 61.3714 50.2447 64.8751 38.4062H65.8461L63.4906 48.6782H75.3736H105.46H112.969L114.678 41.3687L121.437 12.4677L124.158 0.839844H112.222L112.223 0.841243Z"
      fill="#00386B"
    />
    <path
      d="M19.9806 101.855C26.0106 103.587 32.8902 103.587 37.443 103.587H92.7909H95.8542L96.492 100.589L100.469 81.8992L101.444 77.3155L96.7594 77.3216L65.6069 77.3833H50.0188C52.6541 61.9947 55.802 48.9234 60.7477 32.7251H73.071L71.7769 38.3662L70.7133 42.9973H75.4678H105.554H108.555L109.241 40.0759L116.001 11.1749L117.089 6.52344H112.314H61.9844H61.9433H61.9001C50.445 6.77649 36.1854 7.09125 27.2288 15.092C21.0342 20.5727 19.1168 28.2772 15.2964 43.6177L15.2944 43.6301V43.6383L9.28507 68.3572L9.26861 68.421L9.25421 68.4909C7.78324 75.6566 6.40493 86.396 6.61264 87.9941C7.24217 92.7526 10.0705 99.0072 19.9806 101.855Z"
      fill="white"
    />
    <path
      d="M18.9834 44.5438L12.9735 69.2578C11.3379 77.2381 10.375 86.4091 10.375 87.4344C10.375 87.4674 10.3771 87.4941 10.3771 87.5085C11.9879 99.6754 28.167 99.8049 37.4528 99.8049H92.707H92.7996L96.7743 81.1164L65.6251 81.174H65.5284H55.0567H54.9312H49.3971H49.3477H45.5603C48.5166 62.7965 51.975 48.2094 57.9637 28.9432H77.7385H77.8352L76.9876 32.6362L76.9649 32.7308L75.4775 39.2174H105.468H105.563L112.323 10.3164H112.228H61.9939C50.3312 10.5756 37.5696 10.9501 29.7601 17.9222C24.6067 22.4831 22.8656 28.9432 18.9834 44.5438Z"
      fill="#E0AA0F"
    />
  </svg>
);
