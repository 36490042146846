import { TabContext } from "@mui/lab";
import { Box, Stack, Typography, Button, styled } from "@mui/material";
import PageTable from "components/table/PageTable";
import React from "react";
import ReportsTableAll from "./ReportsTableAll";

const ReportsTable: React.FC = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <PageTableContainer>
        <TabContext value={value}>
          {/*<TableHeader>*/}
          {/*  <ReportsTableTabs handleChange={handleChange} />*/}
          {/*  <ReportUploadController.Component context={{}}>*/}
          {/*    <ReportsUploadDialog />*/}
          {/*  </ReportUploadController.Component>*/}
          {/*</TableHeader>*/}

          <PageTable>
            <ReportsTableAll />
          </PageTable>
        </TabContext>
      </PageTableContainer>
    </Box>
  );
};

export default ReportsTable;

const TableHeader = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  padding: 24px 12px 0;
`;

const PageTableContainer = styled("div")`
  padding-top: 24px;
  background-color: white;
`;
