import React from "react";

const AssetsTableColumnsSelectorController: React.FC<{}> = () => {
  return null;
  // <AssetsTableColumnsSelector
  //   columns={tableState.columns}
  //   onSave={updateTableSettingsColumns}
  // />
};

export default AssetsTableColumnsSelectorController;
