import React from "react";
import * as E from "fp-ts/Either";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { pipe } from "fp-ts/function";
import Icon, { AtAlertWarning } from "components/ui/Icon";

export const InvalidAssetIndicator: React.FC<{
  validation: E.Either<NonEmptyArray<string>, any>;
}> = (props) =>
  pipe(
    props.validation,
    E.fold(
      (e) => (
        <span>
          <Icon icon={AtAlertWarning} color={"red"} />
          <span>{e.length} issues</span>
          <ul style={{ color: "red" }}>
            {e.map((_) => (
              <li>{_}</li>
            ))}
          </ul>
        </span>
      ),
      () => null
    )
  );
