/* eslint-disable object-shorthand,prefer-destructuring */
// This implements `StyleImageInterface`
// to draw a pulsing dot icon on the map.
import { Map } from "mapbox-gl";

export const MapLoadingSpinner = (map: Map, size: number) => ({
  width: size,
  height: size,
  data: new Uint8Array(size * size * 4),

  // When the layer is added to the map,
  // get the rendering context for the map canvas.
  onAdd: function () {
    const canvas = document.createElement("canvas");
    // @ts-ignore
    canvas.width = size;
    // @ts-ignore
    canvas.height = size;
    // @ts-ignore
    this.context = canvas.getContext("2d");
  },

  // Call once before every frame where the icon will be used.
  render: function () {
    const duration = 2000;
    const duration2 = duration * 2;

    const t = (performance.now() % duration) / duration;

    const t2 = (performance.now() % duration2) / duration2;

    const radius = (size / 2) * 0.3;
    // @ts-ignore
    const context = this.context;
    // @ts-ignore

    // Draw the outer circle.
    // @ts-ignore
    context.clearRect(0, 0, this.width, this.height);

    // Draw the inner circle.
    context.beginPath();
    // @ts-ignore
    context.arc(
      this.width / 2,
      this.height / 2,
      radius,
      0 + Math.PI * 2 * t2,
      Math.PI * 2 * t + Math.PI * 2 * t2
    );
    context.strokeStyle = "gray";
    context.lineWidth = 16 * (1 - t);
    context.stroke();

    // Update this image's data with data from the canvas.
    // @ts-ignore
    this.data = context.getImageData(0, 0, this.width, this.height).data;

    // Continuously repaint the map, resulting
    // in the smooth animation of the dot.
    map.triggerRepaint();

    // Return `true` to let the map know that the image was updated.
    return true;
  },
});
