import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import styled from "styled-components";
import { palette } from "../../views/authenticated/design/styles/theme";

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  containerClass?: string;
  buttonClass?: string;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

export const Button: React.FC<ButtonProps> = ({
  containerClass,
  buttonClass,
  children,
  isDisabled = false,
  onClick,
  ...rest
}) => {
  return (
    <ButtonContainer className={containerClass}>
      <button
        className={buttonClass}
        disabled={isDisabled}
        type={"button"}
        onClick={onClick}
        {...rest}
      >
        {children}
      </button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 8px 18px;
  width: 100%;

  // Contained button & container
  &.contained {
    width: auto;
    & button {
      width: auto;
    }
  }

  // Space Between
  & .between {
    justify-content: space-between;
  }

  // Icon button
  & .icon {
    border-radius: 50%;
    padding: 8px;
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    outline: none;
    border: 0;
    padding: 8px 12px;
    border-radius: 4px;
    font-family: Fira Sans, sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    background-position: center;

    transition: background 0.5s;

    width: 100%;
    background-color: ${palette.neutral[200]};
    color: ${palette.neutral[800]};

    // Hover
    &:hover {
      background: ${palette.neutral[300]}
        radial-gradient(circle, transparent 1%, ${palette.neutral[300]} 1%)
        center/15000%;
    }

    // Click
    &:active {
      background-color: ${palette.neutral[400]};
      background-size: 100%;
      transition: background 0s;
    }
  }

  // No pointer events for SVGs inside buttons
  & button svg {
    pointer-events: none;
  }

  // Mrgin for text
  & button span {
    margin: 0 8px;
  }

  // Theme: Primary
  & button.primary {
    background-color: ${palette.primary[600]};
    color: ${palette.neutral[100]};
    &:hover {
      background: ${palette.primary[500]}
        radial-gradient(circle, transparent 1%, ${palette.primary[500]} 1%)
        center/15000%;
      transition: background 0.5s;
    }
    &:active {
      background-color: ${palette.primary[700]};
      background-size: 100%;
      transition: background 0s;
    }
  }

  // Theme: Secondary
  & button.secondary {
    background-color: ${palette.neutral[200]};
    color: ${palette.primary[700]};
    &:hover {
      background: ${palette.neutral[300]}
        radial-gradient(circle, transparent 1%, ${palette.neutral[300]} 1%)
        center/15000%;
      transition: background 0.5s;
    }
    &:active {
      background-color: ${palette.neutral[400]};
      background-size: 100%;
      transition: background 0s;
    }
  }

  // Theme: Text
  & button.text {
    background-color: transparent;
    color: ${palette.primary[700]};
    &:hover {
      color: ${palette.primary[600]};
    }
    &:active {
      color: ${palette.primary[700]};
    }
  }

  // Theme: Dark
  & button.dark {
    background-color: ${palette.neutral[700]};
    color: ${palette.neutral[100]};
    &:hover {
      background: ${palette.neutral[500]}
        radial-gradient(circle, transparent 1%, ${palette.neutral[500]} 1%)
        center/15000%;
      transition: background 0.5s;
    }
    &:active {
      background-color: ${palette.neutral[600]};
      background-size: 100%;
      transition: background 0s;
    }
  }

  //Disabled Button
  & button[disabled],
  & button.primary[disabled],
  & button.secondary[disabled] {
    background-color: ${palette.neutral[200]};
    color: ${palette.neutral[500]};
    cursor: not-allowed;
    opacity: 0.65;

    &:hover {
      background: ${palette.neutral[200]}
        radial-gradient(circle, transparent 1%, ${palette.neutral[200]} 1%)
        center/15000%;
      transition: background 0.5s;
      opacity: 0.65;
    }
    &:active {
      background-color: ${palette.neutral[200]};
      background-size: 100%;
      transition: background 0s;
      opacity: 0.65;
    }
  }
  & button.text[disabled] {
    background-color: transparent;
    color: ${palette.neutral[300]};
    cursor: not-allowed;

    &:hover {
      color: ${palette.neutral[300]};
    }
    &:active {
      color: ${palette.neutral[300]};
    }
  }

  // Icon button
  & .rounded {
    border-radius: 50%;
    padding: 8px;
  }

  // No pointer events for SVGs inside buttons
  & button svg {
    pointer-events: none;
  }

  // Margin for button text
  & button span {
    margin: 0 8px;
  }

  // Space Between
  & .between {
    justify-content: space-between;
  }
`;
