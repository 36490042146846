import React, { useCallback } from "react";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import Icon, {
  AtActivityMap,
  AtAdmin,
  AtArrowCaretDoubleRight,
  AtAssets,
  AtInfo,
  AtInsights,
  AtSchedule,
  AtUtilization,
} from "components/ui/Icon";
import ContextualSidebarDrawer from "components/contextualSidebar/ContextualSidebarDrawer";
import { IconButton, Stack, styled, Typography } from "@mui/material";
import palette from "theme/palette";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import AssetsDetailPreviewTab, {
  AssetAdminTab,
  AssetInsightsTab,
  AssetScheduleTab,
  AssetUtilizationTab,
  AssetWeatherTab,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetsDetailPreviewTab";
import ContextualSidebar from "components/contextualSidebar/ContextualSidebar";
import { match } from "ts-adt";
import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import { alpha } from "@mui/system";
import { Asset } from "lib/at-data/assets/Asset";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import { AssetPageTab } from "controllers/UIStateCollector/models/AssetsPageTab";
import {
  ActivityPageSelectedAssetIdL,
  ActivityPageUIStateL,
  getActivitySetSelectedAssetLens,
  SelectedRangeL,
  SelectedSideItemL,
} from "controllers/UIStateCollector/lens/activityPage";
import AssetActionsMenu, {
  AssetAction,
} from "components/contextualSidebar/AssetActionsMenu";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { AssetsL } from "views/authenticated/root/controller/state";
import { getCampuses, getParentAssetOf } from "lib/at-data/assets/filters";
import * as A from "fp-ts/Array";
import {
  navigateToAnalyze,
  navigateToAnalyzeChildren,
} from "controllers/UIStateCollector/lens/assetsPage";
import { EDIT_FEATURE, FLASH_INSIGHTS } from "constants/featureFlags";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { isBuilding } from "lib/at-data/assets/predicates";
import {
  SelectedAssetL,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import { isEmpty } from "fp-ts/Array";
import * as b from "fp-ts/boolean";
import { clog } from "../../../../../../lib/util";
import { getAssetType, getId, getName } from "lib/at-data/assets/getters";
import mixpanel from "mixpanel-browser";

export const ActivityPageSidepanel: React.FC<{}> = (props) => {
  const dispatch = useControllerDispatch(UIStateController);
  const [selectedSideItem] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(SelectedSideItemL)).get
  );
  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );
  const [selectedRange] = useController(
    UIStateController,
    pipe(ActivityPageUIStateL, L.composeLens(SelectedRangeL)).get
  );

  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );

  const handleSelectAsset = useCallback(
    (asset: Asset) => {
      pipe(
        flow(
          getActivitySetSelectedAssetLens(
            O.some(asset),
            pipe(
              O.some(asset),
              O.chain((a) => getParentAssetOf(isBuilding)(a)(allAssets))
            ),
            allAssets
          )
        ),
        dispatch
      );
    },
    [dispatch, allAssets]
  );

  const handleClose = useCallback(
    () =>
      dispatch(
        pipe(
          O.none,
          pipe(ActivityPageUIStateL, L.composeLens(SelectedSideItemL)).set
        )
      ),
    [dispatch]
  );
  const handleTabClick = useCallback(
    (selectedTab) => {
      mixpanel.track("Tab Selected from Explore Page", {
        tab: selectedTab,
      });
      dispatch(
        flow(
          pipe(ActivityPageUIStateL, L.composeLens(SelectedSideItemL)).set(
            pipe(
              selectedTab as ASSET_CONTEXT_TABS | null,
              O.fromNullable,
              O.map(AssetPageTab)
            )
          )
        )
      );
    },
    [dispatch]
  );

  const startTime = performance.now();

  window.addEventListener("beforeunload", () => {
    const endTime = performance.now();
    const timeSpentSeconds = Math.round((endTime - startTime) / 1000);

    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "asset"
    ) {
      mixpanel.track("Info (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "admin"
    ) {
      mixpanel.track("Admin (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "utilization"
    ) {
      mixpanel.track("KPI (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "insights"
    ) {
      mixpanel.track("Insights (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "schedule"
    ) {
      mixpanel.track("Bookings (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "weather"
    ) {
      mixpanel.track("Weather (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "selection"
    ) {
      mixpanel.track("Tags (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }
    if (
      pipe(
        selectedSideItem,
        O.map((_) => _._type),
        O.getOrElseW(() => O.none)
      ) === "default-capacity"
    ) {
      mixpanel.track("Default Capacity (Explore)", {
        "Time Spent (seconds)": timeSpentSeconds,
      });
    }

    // mixpanel.track("Time Spent on Tab", {
    //   "Tab Name": selectedSideItem,
    //   "Time Spent (seconds)": timeSpentSeconds,
    // });
  });

  const handleViewAssetInTable = useCallback(
    () => dispatch(navigateToAnalyze({ selectedAsset, selectedRange })),
    [selectedAsset, selectedRange]
  );

  const handleGetChildren = useCallback(
    () => dispatch(navigateToAnalyzeChildren({ selectedAsset, selectedRange })),
    [selectedAsset, selectedRange]
  );

  const getChildText = (asset: Asset) => {
    const type = pipe(asset, getAssetType);
    switch (type) {
      case AssetTypes.CAMPUS: {
        return O.of("View Campus Regions in Analyze Table");
      }
      case AssetTypes.BUILDING: {
        return O.of("View Building Floors in Analyze Table");
      }
      case AssetTypes.FLOOR: {
        return O.of("View Floor Zones in Analyze Table");
      }
      case AssetTypes.ZONE: {
        return O.none;
      }
      default: {
        return O.none;
      }
    }
  };

  const handleBookingClick = useCallback(
    flow(
      pipe(ActivityPageUIStateL, L.composeLens(SelectedRangeL)).set,
      dispatch
    ),
    [dispatch]
  );

  return (
    <ContextualSidebar
      selectedItem={pipe(
        selectedSideItem,
        O.map((_) => _._type)
      )}
      open={O.isSome(selectedSideItem) && O.isSome(selectedAsset)}
      onTabClick={handleTabClick}
      tabs={[
        {
          id: ASSET_CONTEXT_TABS.ASSET,
          label: "Info",
          icon: AtInfo,
          tooltipTitle: "View Asset Info",
        },
        {
          id: ASSET_CONTEXT_TABS.UTILIZATION,
          label: "Metrics",
          icon: AtUtilization,
          tooltipTitle: "View Asset KPIs",
        },
        {
          id: ASSET_CONTEXT_TABS.INSIGHTS,
          label: "Insights",
          icon: AtInsights,
          featureFlag: FLASH_INSIGHTS,
          tooltipTitle: "View Asset Insights",
        },
        {
          id: ASSET_CONTEXT_TABS.SCHEDULE,
          label: "Schedule",
          icon: AtSchedule,
          tooltipTitle: "View Asset's Scheduled Events",
        },
        {
          id: ASSET_CONTEXT_TABS.ADMIN,
          label: "Admin",
          icon: AtAdmin,
          featureFlag: EDIT_FEATURE,
          tooltipTitle: "Configure the Asset",
        },
      ]}
    >
      {
        <ContextualSidebarDrawer
          open={O.isSome(selectedSideItem) && O.isSome(selectedAsset)}
          hideBackdrop={true}
        >
          <StyledPanelHeader
            py={2}
            px={3}
            spacing={0}
            direction="column"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={1}
              >
                <AssetActionsMenu>
                  <AssetAction
                    onClick={handleViewAssetInTable}
                    icon={AtActivityMap}
                  >
                    View Asset In Analyze Table
                  </AssetAction>
                  {pipe(
                    selectedAsset,
                    O.chain(getChildText),
                    O.fold(
                      () => null,
                      (text) => (
                        <AssetAction
                          onClick={handleGetChildren}
                          icon={AtAssets}
                        >
                          {text}
                        </AssetAction>
                      )
                    )
                  )}
                </AssetActionsMenu>
                <Typography
                  variant="h3"
                  color={palette.neutral[100]}
                  sx={{ marginTop: "2px" }}
                >
                  {pipe(
                    selectedAsset,
                    O.map(getName),
                    O.getOrElseW(() => null)
                  )}
                </Typography>
              </Stack>
              <IconButton
                color="primary"
                onClick={handleClose}
                aria-label="Close Side Panel"
              >
                <Icon icon={AtArrowCaretDoubleRight} size="16px" />
              </IconButton>
            </Stack>
          </StyledPanelHeader>
          {pipe(
            selectedSideItem,
            O.map(
              flow(
                match({
                  [ASSET_CONTEXT_TABS.ADMIN]: () =>
                    pipe(
                      selectedAsset,
                      O.map((sa) => (
                        <AssetAdminTab onClose={handleClose} asset={sa} />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.ASSET]: () =>
                    pipe(
                      selectedAsset,
                      O.map((sa) => (
                        <AssetsDetailPreviewTab
                          onAssetClick={handleSelectAsset}
                          onClose={handleClose}
                          selectedAsset={sa}
                        />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.UTILIZATION]: () =>
                    pipe(
                      selectedAsset,
                      O.map((sa) => (
                        <AssetUtilizationTab
                          onClose={handleClose}
                          asset={sa}
                          range={selectedRange}
                        />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.INSIGHTS]: () =>
                    pipe(
                      selectedAsset,
                      O.map((sa) => (
                        <AssetInsightsTab onClose={handleClose} asset={sa} />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.SCHEDULE]: () =>
                    pipe(
                      selectedAsset,
                      O.map((sa) => (
                        <AssetScheduleTab
                          selectedRange={selectedRange}
                          onClose={handleClose}
                          asset={sa}
                          onBookingClick={handleBookingClick}
                        />
                      )),
                      O.getOrElseW(() => null)
                    ),
                  [ASSET_CONTEXT_TABS.WEATHER]: () => (
                    <AssetWeatherTab onClose={handleClose} />
                  ),
                  [ASSET_CONTEXT_TABS.SELECTION]: () => <div>test</div>,
                  [ASSET_CONTEXT_TABS.DEFAULT_CAPACITY]: () => <div>test</div>,
                })
              )
            ),
            O.getOrElseW(() => null)
          )}
        </ContextualSidebarDrawer>
      }
    </ContextualSidebar>
  );
};

const StyledPanelHeader = styled(Stack)`
  z-index: 5;
  // width: 299px;
  box-shadow: 0 2px 24px ${alpha(palette.neutral[800], 0.5)};
`;
