import React from "react";

export const UNTLogo = () => (
  <div>
    <svg
      width="151"
      height="70"
      viewBox="0 0 151 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.3522 39.6472L6.20734 4.04963C5.16227 3.00456 4.70506 1.43696 5.09696 0L2.09239 6.85824C1.24328 9.079 2.48429 10.3853 3.46404 11.1038C4.24784 11.8223 33.7709 35.7282 33.7709 35.7282V36.7079L4.50911 14.1737C3.65999 13.4552 3.20278 12.3448 3.26809 11.2344L1.17796 15.6107C0.198214 18.0274 2.61493 19.6603 3.72531 20.3788C4.83569 21.0972 33.8362 41.476 33.8362 41.476L34.0975 42.717C34.0975 42.717 5.81544 23.8405 4.70506 23.1874C3.46404 22.4689 3.46404 20.64 3.46404 20.64L1.37391 25.0162C0.609237 26.6632 2.49314 27.8335 3.68636 28.5747C3.8181 28.6565 3.94149 28.7332 4.05189 28.8046C4.90101 29.2618 20.6423 38.6674 33.7056 46.5054H16.9193C14.5025 46.5054 13.6534 45.7869 13.6534 45.7869L15.9395 47.877C15.9657 47.9032 15.9948 47.9329 16.0267 47.9655C16.4731 48.4208 17.4768 49.4446 18.8788 49.4446H25.0838L26.3248 49.9019L20.8382 50.2938C19.8585 50.4244 18.8788 50.2284 18.0296 49.7059L20.4463 51.992C20.5063 52.041 20.5649 52.0896 20.6226 52.1375L20.6231 52.138C21.2569 52.6636 21.7858 53.1024 22.8631 53.1024C24.1041 52.9717 30.897 51.8614 30.897 51.8614L32.0074 52.3186L24.8226 53.8862C23.8428 54.0821 22.8631 53.9515 22.0139 53.429L24.2347 55.6497C25.2145 56.4988 26.0636 57.0867 29.0028 56.2376C30.2438 55.8457 35.9264 54.0168 35.9264 54.0168L39.7147 55.5844C42.0661 58.7849 44.9401 61.5282 48.2712 63.6183C47.7487 64.2715 47.7487 65.1859 48.1406 65.9044C48.8591 64.4021 50.4267 63.553 52.0596 63.6836C53.0515 63.8003 52.9488 64.9596 52.9156 65.1124L52.9087 65.1206C52.9087 65.1345 52.9117 65.1306 52.9156 65.1124C53.6314 64.2643 54.2778 63.158 53.4965 62.1814C52.6474 61.071 50.4267 62.0507 50.4267 62.0507L48.2059 58.1317L51.1451 56.4335C52.5168 46.4401 44.3522 39.6472 44.3522 39.6472Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.3593 56.9397L58.6611 55.6987C59.1836 54.3924 58.8571 52.9554 57.812 52.041C55.0687 49.4936 49.974 44.8562 49.974 44.8562C51.6069 48.5792 52.0641 52.6941 51.3457 56.6784L54.0236 57.1357L54.4155 57.5929C54.4155 57.5929 57.4854 57.9848 57.9426 58.442C57.9426 58.442 59.053 59.1605 57.812 60.1402C58.7917 59.9443 59.7062 59.4871 60.49 58.7686C60.49 58.7686 61.3391 58.0501 60.3593 56.9397ZM58.6611 55.6987C58.2039 55.4374 57.2895 55.96 57.2895 55.96C56.8976 54.8496 56.375 53.8045 55.7219 52.8901C55.7219 52.8901 58.5305 55.5028 58.6611 55.6987Z"
        fill="white"
      />
      <path
        d="M24.1041 4.37621C24.1041 4.37621 41.8049 24.2324 42.2621 24.8856C42.346 24.9779 42.4404 25.0806 42.5441 25.1934L42.5448 25.1942C43.1284 25.8288 44.0068 26.7841 44.9646 28.0102C44.963 27.891 44.9623 27.7709 44.9623 27.6498V12.0391H40.5861L42.6762 6.48724H52.8656V27.5845C52.8656 30.8503 53.5841 33.1364 54.9557 34.508C56.5886 36.0103 58.6787 36.7941 60.8995 36.7288C62.7937 36.7288 64.8838 36.0103 65.9942 34.7693C67.6271 33.3323 68.0843 31.6341 68.0843 28.5642V12.1045H64.5572L66.6474 6.48724L79.6454 6.35661L77.5552 11.9738H75.0079V28.4989C75.0079 37.6432 69.1294 43.5217 59.9851 43.5217C58.0277 43.5217 53.7433 43.1921 50.2489 40.5041C50.3408 41.903 50.2654 43.3631 49.9694 44.8725C48.7284 42.1945 47.0302 39.7125 44.8747 37.6877C43.2418 36.2507 23.6469 17.9621 22.6671 16.9823C22.5124 16.8276 22.3403 16.6729 22.1647 16.515C21.3767 15.8064 20.5178 15.0341 20.8382 13.9124L22.6671 9.66685C22.6671 9.68151 22.6614 9.70851 22.6532 9.74693C22.5883 10.0508 22.3712 11.0691 23.6469 12.3448L41.217 30.0456L41.3476 28.9352C41.3476 28.9352 22.7977 9.53622 22.0793 8.6871C22.0292 8.62595 21.9773 8.56384 21.9244 8.50063C21.3563 7.82123 20.6812 7.01387 21.0995 5.87849L23.1243 1.1757C23.1243 1.19042 23.1169 1.2209 23.1059 1.26583C23.0192 1.61962 22.7131 2.86935 24.1041 4.37621Z"
        fill="white"
      />
      <path
        d="M122.885 6.48724L119.619 15.6316H124.648L126.02 12.1045H130.919V37.2513H128.11L126.02 42.8686H140.063L142.153 37.2513H138.234V12.0391H144.113V15.5662H147.509L150.906 6.29129L122.885 6.48724Z"
        fill="white"
      />
      <path
        d="M116.419 12.0391V42.7379H112.173C112.173 42.7379 92.1862 21.6407 90.8145 20.3344V37.186H94.7335L92.6434 42.9339H79.7107L81.8008 37.186H84.0216V12.0391H79.3841L81.4742 6.42192H89.3775C89.3775 6.42192 104.596 22.4245 109.952 27.9764V12.1698H106.033L108.123 6.42192H121.317L119.227 12.1698L116.419 12.0391Z"
        fill="white"
      />
      <path
        d="M113.378 53.9421C113.335 53.5757 113.159 53.2913 112.85 53.0888C112.542 52.8863 112.163 52.7851 111.715 52.7851C111.387 52.7851 111.1 52.8381 110.854 52.9441C110.611 53.0502 110.421 53.196 110.283 53.3816C110.148 53.5673 110.081 53.7782 110.081 54.0144C110.081 54.2121 110.128 54.382 110.222 54.5242C110.318 54.664 110.441 54.7809 110.591 54.8749C110.74 54.9665 110.897 55.0425 111.061 55.1027C111.225 55.1606 111.375 55.2076 111.513 55.2437L112.265 55.4462C112.457 55.4968 112.672 55.5667 112.908 55.6559C113.147 55.7451 113.375 55.8668 113.592 56.0211C113.811 56.173 113.992 56.3682 114.134 56.6069C114.276 56.8455 114.347 57.1384 114.347 57.4855C114.347 57.8856 114.242 58.2472 114.033 58.5702C113.825 58.8932 113.522 59.1499 113.122 59.3403C112.724 59.5308 112.241 59.626 111.672 59.626C111.141 59.626 110.682 59.5404 110.294 59.3693C109.908 59.1981 109.605 58.9595 109.383 58.6534C109.164 58.3472 109.039 57.9917 109.01 57.5867H109.936C109.96 57.8663 110.054 58.0977 110.218 58.2809C110.384 58.4617 110.594 58.5967 110.847 58.6859C111.103 58.7727 111.378 58.8161 111.672 58.8161C112.014 58.8161 112.321 58.7606 112.594 58.6497C112.866 58.5364 113.082 58.3798 113.241 58.1797C113.4 57.9772 113.479 57.741 113.479 57.471C113.479 57.2251 113.411 57.0251 113.273 56.8708C113.136 56.7165 112.955 56.5912 112.731 56.4948C112.507 56.3984 112.265 56.314 112.004 56.2417L111.093 55.9813C110.515 55.815 110.057 55.5776 109.719 55.269C109.382 54.9605 109.213 54.5568 109.213 54.0578C109.213 53.6432 109.325 53.2816 109.549 52.9731C109.776 52.6621 110.08 52.4211 110.46 52.2499C110.844 52.0764 111.272 51.9896 111.744 51.9896C112.221 51.9896 112.645 52.0752 113.017 52.2463C113.388 52.415 113.682 52.6465 113.899 52.9405C114.118 53.2346 114.234 53.5685 114.246 53.9421H113.378Z"
        fill="white"
      />
      <path
        d="M77.6984 52.0908H76.8017V56.9214C76.8017 57.283 76.7222 57.6048 76.5631 57.8868C76.4064 58.1664 76.1798 58.387 75.8833 58.5485C75.5868 58.7076 75.2325 58.7871 74.8203 58.7871C74.4081 58.7871 74.0538 58.7076 73.7573 58.5485C73.4632 58.387 73.2366 58.1664 73.0775 57.8868C72.9184 57.6048 72.8389 57.283 72.8389 56.9214V52.0908H71.9422V56.9937C71.9422 57.4999 72.0603 57.9519 72.2965 58.3496C72.5352 58.745 72.8702 59.0571 73.3017 59.2861C73.7332 59.5127 74.2394 59.626 74.8203 59.626C75.4012 59.626 75.9074 59.5127 76.3389 59.2861C76.7704 59.0571 77.1042 58.745 77.3405 58.3496C77.5791 57.9519 77.6984 57.4999 77.6984 56.9937V52.0908Z"
        fill="white"
      />
      <path
        d="M85.361 59.4958V52.0908H84.4788V57.9194H84.4065L80.3569 52.0908H79.4891V59.4958H80.3858V53.6818H80.4581L84.4932 59.4958H85.361Z"
        fill="white"
      />
      <path d="M88.052 52.0908V59.4958H87.1553V52.0908H88.052Z" fill="white" />
      <path
        d="M92.352 58.3243L90.1536 52.0908H89.2135L91.9326 59.4958H92.8582L95.5772 52.0908H94.6371L92.4388 58.3243H92.352Z"
        fill="white"
      />
      <path
        d="M96.7306 59.4958V52.0908H101.2V52.8863H97.6273V55.3884H100.968V56.1838H97.6273V58.7004H101.257V59.4958H96.7306Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.816 52.0908V59.4958H103.713V56.6032H105.332C105.397 56.6032 105.459 56.6021 105.521 56.5997L107.082 59.4958H108.124L106.451 56.4424C106.556 56.4067 106.654 56.3651 106.746 56.3176C107.115 56.1248 107.389 55.8596 107.567 55.5221C107.745 55.1847 107.834 54.7978 107.834 54.3615C107.834 53.9252 107.745 53.5359 107.567 53.1936C107.389 52.8513 107.114 52.5826 106.743 52.3873C106.371 52.1897 105.896 52.0908 105.318 52.0908H102.816ZM103.713 55.7933H105.303C105.699 55.7933 106.017 55.7355 106.258 55.6198C106.501 55.5041 106.677 55.3402 106.786 55.128C106.897 54.9135 106.952 54.658 106.952 54.3615C106.952 54.065 106.897 53.8059 106.786 53.5841C106.675 53.3624 106.498 53.1912 106.254 53.0707C106.011 52.9478 105.689 52.8863 105.289 52.8863H103.713V55.7933Z"
        fill="white"
      />
      <path
        d="M115.822 59.4958H116.718V52.0908H115.822V59.4958Z"
        fill="white"
      />
      <path
        d="M118.111 52.8863V52.0908H123.665V52.8863H121.336V59.4958H120.44V52.8863H118.111Z"
        fill="white"
      />
      <path
        d="M125.439 52.0908H124.413L127.088 56.4442V59.4958H127.985V56.4442L130.66 52.0908H129.634L127.58 55.5475H127.493L125.439 52.0908Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.9159 65.5908C65.9159 66.3718 65.7748 67.0467 65.4928 67.6156C65.2108 68.1845 64.8239 68.6232 64.3322 68.9317C63.8404 69.2403 63.2788 69.3945 62.6473 69.3945C62.0157 69.3945 61.4541 69.2403 60.9623 68.9317C60.4706 68.6232 60.0837 68.1845 59.8017 67.6156C59.5197 67.0467 59.3787 66.3718 59.3787 65.5908C59.3787 64.8098 59.5197 64.1349 59.8017 63.566C60.0837 62.9971 60.4706 62.5584 60.9623 62.2499C61.4541 61.9414 62.0157 61.7871 62.6473 61.7871C63.2788 61.7871 63.8404 61.9414 64.3322 62.2499C64.8239 62.5584 65.2108 62.9971 65.4928 63.566C65.7748 64.1349 65.9159 64.8098 65.9159 65.5908ZM65.0481 65.5908C65.0481 64.9496 64.9408 64.4085 64.7263 63.9674C64.5142 63.5262 64.2261 63.1924 63.8621 62.9658C63.5006 62.7392 63.0956 62.6259 62.6473 62.6259C62.1989 62.6259 61.7927 62.7392 61.4288 62.9658C61.0672 63.1924 60.7791 63.5262 60.5646 63.9674C60.3525 64.4085 60.2464 64.9496 60.2464 65.5908C60.2464 66.232 60.3525 66.7732 60.5646 67.2143C60.7791 67.6554 61.0672 67.9892 61.4288 68.2158C61.7927 68.4424 62.1989 68.5557 62.6473 68.5557C63.0956 68.5557 63.5006 68.4424 63.8621 68.2158C64.2261 67.9892 64.5142 67.6554 64.7263 67.2143C64.9408 66.7732 65.0481 66.232 65.0481 65.5908Z"
        fill="white"
      />
      <path
        d="M71.8637 61.8883H67.4236V69.2933H68.3203V65.9813H71.5311V65.1859H68.3203V62.6838H71.8637V61.8883Z"
        fill="white"
      />
      <path
        d="M82.135 69.2933H81.2673L77.2321 63.4792H77.1598V69.2933H76.2631V61.8883H77.1309L81.1805 67.7168H81.2528V61.8883H82.135V69.2933Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.7543 67.6156C90.0363 67.0467 90.1773 66.3718 90.1773 65.5908C90.1773 64.8098 90.0363 64.1349 89.7543 63.566C89.4722 62.9971 89.0853 62.5584 88.5936 62.2499C88.1019 61.9414 87.5402 61.7871 86.9087 61.7871C86.2771 61.7871 85.7155 61.9414 85.2238 62.2499C84.732 62.5584 84.3451 62.9971 84.0631 63.566C83.7811 64.1349 83.6401 64.8098 83.6401 65.5908C83.6401 66.3718 83.7811 67.0467 84.0631 67.6156C84.3451 68.1845 84.732 68.6232 85.2238 68.9317C85.7155 69.2403 86.2771 69.3945 86.9087 69.3945C87.5402 69.3945 88.1019 69.2403 88.5936 68.9317C89.0853 68.6232 89.4722 68.1845 89.7543 67.6156ZM88.9877 63.9674C89.2023 64.4085 89.3095 64.9496 89.3095 65.5908C89.3095 66.232 89.2023 66.7732 88.9877 67.2143C88.7756 67.6554 88.4875 67.9892 88.1236 68.2158C87.762 68.4424 87.357 68.5557 86.9087 68.5557C86.4603 68.5557 86.0542 68.4424 85.6902 68.2158C85.3286 67.9892 85.0406 67.6554 84.826 67.2143C84.6139 66.7732 84.5078 66.232 84.5078 65.5908C84.5078 64.9496 84.6139 64.4085 84.826 63.9674C85.0406 63.5262 85.3286 63.1924 85.6902 62.9658C86.0542 62.7392 86.4603 62.6259 86.9087 62.6259C87.357 62.6259 87.762 62.7392 88.1236 62.9658C88.4875 63.1924 88.7756 63.5262 88.9877 63.9674Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.685 61.8883H94.1871C94.7656 61.8883 95.2405 61.9871 95.6117 62.1848C95.9829 62.3801 96.2577 62.6488 96.4361 62.9911C96.6145 63.3334 96.7036 63.7227 96.7036 64.159C96.7036 64.5953 96.6145 64.9822 96.4361 65.3196C96.2577 65.6571 95.9841 65.9222 95.6153 66.1151C95.5233 66.1626 95.4249 66.2042 95.3201 66.2398L96.9929 69.2933H95.9516L94.3902 66.3972C94.3286 66.3995 94.2657 66.4007 94.2016 66.4007H92.5817V69.2933H91.685V61.8883ZM94.1726 65.5908H92.5817V62.6838H94.1582C94.5583 62.6838 94.8801 62.7452 95.1236 62.8682C95.367 62.9887 95.5442 63.1598 95.6551 63.3816C95.766 63.6034 95.8214 63.8625 95.8214 64.159C95.8214 64.4555 95.766 64.711 95.6551 64.9255C95.5466 65.1376 95.3707 65.3016 95.1272 65.4173C94.8862 65.533 94.568 65.5908 94.1726 65.5908Z"
        fill="white"
      />
      <path
        d="M97.7929 61.8883V62.6838H100.121V69.2933H101.018V62.6838H103.347V61.8883H97.7929Z"
        fill="white"
      />
      <path
        d="M104.731 61.8883H105.627V65.1859H109.576V61.8883H110.472V69.2933H109.576V65.9813H105.627V69.2933H104.731V61.8883Z"
        fill="white"
      />
      <path
        d="M114.726 61.8883V62.6838H117.055V69.2933H117.951V62.6838H120.28V61.8883H114.726Z"
        fill="white"
      />
      <path
        d="M121.664 61.8883H126.133V62.6838H122.561V65.1859H125.901V65.9813H122.561V68.4978H126.191V69.2933H121.664V61.8883Z"
        fill="white"
      />
      <path
        d="M130.092 64.9689L128.183 61.8883H127.127L129.514 65.5908L127.127 69.2933H128.183L130.092 66.2706H130.15L132.059 69.2933H133.115L130.786 65.5908L133.115 61.8883H132.059L130.15 64.9689H130.092Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.585 69.2933H133.645L136.364 61.8883H137.29L140.009 69.2933H139.069L138.324 67.1962H135.33L134.585 69.2933ZM136.856 63.0598L138.042 66.4007H135.612L136.798 63.0598H136.856Z"
        fill="white"
      />
      <path
        d="M144.684 62.8863C144.993 63.0887 145.169 63.3732 145.212 63.7396H146.08C146.068 63.3659 145.952 63.0321 145.733 62.738C145.516 62.4439 145.222 62.2125 144.85 62.0438C144.479 61.8727 144.055 61.7871 143.578 61.7871C143.105 61.7871 142.677 61.8739 142.294 62.0474C141.913 62.2186 141.61 62.4596 141.383 62.7706C141.159 63.0791 141.047 63.4407 141.047 63.8553C141.047 64.3542 141.215 64.758 141.553 65.0665C141.89 65.3751 142.348 65.6125 142.927 65.7788L143.838 66.0392C144.098 66.1115 144.341 66.1958 144.565 66.2923C144.789 66.3887 144.97 66.514 145.107 66.6683C145.245 66.8226 145.313 67.0226 145.313 67.2685C145.313 67.5385 145.234 67.7747 145.075 67.9772C144.916 68.1772 144.7 68.3339 144.427 68.4472C144.155 68.5581 143.848 68.6135 143.505 68.6135C143.211 68.6135 142.937 68.5702 142.681 68.4834C142.428 68.3942 142.218 68.2592 142.052 68.0784C141.888 67.8952 141.794 67.6638 141.77 67.3842H140.844C140.873 67.7892 140.997 68.1447 141.217 68.4508C141.438 68.757 141.742 68.9956 142.128 69.1667C142.516 69.3379 142.975 69.4235 143.505 69.4235C144.074 69.4235 144.558 69.3283 144.955 69.1378C145.355 68.9474 145.659 68.6907 145.866 68.3677C146.076 68.0447 146.181 67.6831 146.181 67.283C146.181 66.9359 146.11 66.643 145.968 66.4043C145.826 66.1657 145.645 65.9705 145.425 65.8186C145.208 65.6643 144.981 65.5426 144.742 65.4534C144.506 65.3642 144.291 65.2943 144.098 65.2437L143.346 65.0412C143.209 65.0051 143.058 64.9581 142.894 64.9002C142.73 64.8399 142.574 64.764 142.424 64.6724C142.275 64.5784 142.152 64.4615 142.056 64.3217C141.962 64.1795 141.915 64.0095 141.915 63.8119C141.915 63.5757 141.982 63.3647 142.117 63.1791C142.254 62.9935 142.445 62.8477 142.688 62.7416C142.934 62.6356 143.221 62.5825 143.549 62.5825C143.997 62.5825 144.376 62.6838 144.684 62.8863Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.12 19.3337C150.13 19.3337 150.949 18.5149 150.949 17.5048C150.949 16.4948 150.13 15.676 149.12 15.676C148.11 15.676 147.291 16.4948 147.291 17.5048C147.291 18.5149 148.11 19.3337 149.12 19.3337ZM150.181 18.5691C149.909 18.8398 149.534 19.0071 149.12 19.0071C148.291 19.0071 147.618 18.3345 147.618 17.5048C147.618 16.6752 148.291 16.0026 149.12 16.0026C149.95 16.0026 150.623 16.6752 150.623 17.5048C150.623 17.8906 150.477 18.2423 150.238 18.5083L149.735 17.5897C149.769 17.5782 149.8 17.5649 149.83 17.5497C149.948 17.4878 150.036 17.4028 150.093 17.2945C150.15 17.1863 150.179 17.0622 150.179 16.9222C150.179 16.7823 150.15 16.6574 150.093 16.5476C150.036 16.4378 149.948 16.3516 149.829 16.289C149.71 16.2256 149.557 16.1939 149.372 16.1939H148.569V18.5691H148.857V17.6413H149.376C149.397 17.6413 149.417 17.6409 149.437 17.6401L149.938 18.5691H150.181ZM148.857 17.3815V16.4491H149.362C149.491 16.4491 149.594 16.4688 149.672 16.5082C149.75 16.5469 149.807 16.6018 149.843 16.6729C149.878 16.744 149.896 16.8271 149.896 16.9222C149.896 17.0173 149.878 17.0993 149.843 17.1681C149.808 17.2361 149.751 17.2887 149.673 17.3258C149.596 17.3629 149.494 17.3815 149.367 17.3815H148.857Z"
        fill="white"
      />
    </svg>
  </div>
);
