import { addDays, startOfDay, subDays, subWeeks } from "date-fns/fp";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { AssetFilter } from "controllers/UIStateCollector/models/assetFilterModel";
import { AssetTag } from "lib/at-api/asset-tags/assetTags";
import { Predicate } from "fp-ts/Predicate";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";
import { pipe } from "fp-ts/function";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";

export const DEFAULT_FILTERS: AssetFilter = {
  byTypes: [AssetTypes.BUILDING],
  byName: "",
  byTags: [],
  byIds: O.none,
};

// Managed Assets is a special tag to denote assets that should apppear in the ButtonGuide
export const isManagedAssetTag: Predicate<AssetTag> = (tag) =>
  pipe(tag, s.includes("Managed Assets"));

export const lastWeek = (now: Date) =>
  SeriesRequest(
    pipe(now, subWeeks(1), startOfDay),
    pipe(now, addDays(1), startOfDay)
  );
