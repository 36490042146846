import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/Array";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetNameCell } from "views/authenticated/assets/page/components/AssetsTable/cells/AssetNameCell";
import { AssetContext } from "contexts/AssetContext";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { AssetRowContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowContext";
import { ordAssetByName } from "lib/at-data/assets/models/AssetModel";
import { UserContext } from "contexts/UserContext";
import { isFeatureFlagAvailable } from "controllers/AppController/IsFeatureFlagAvailable";
import { EDIT_FEATURE } from "constants/featureFlags";
import { OptionalTextCell } from "views/authenticated/assets/page/components/AssetsTable/cells/OptionalTextCell";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as s from "fp-ts/string";
import { getZoneCode } from "lib/at-data/assets/getZoneCode";

export const AssetShortCodeColumn = defineColumn(
  "assetShortCode",
  pipe(
    R.ask<AssetRowDataContext>(),
    R.map((_) =>
      ROR.singleton(
        "assetShortCode",
        pipe(
          _.asset,
          getZoneCode,
          O.alt(() => _.asset.shortCode)
        )
      )
    )
  ),
  R.of(OptionalTextCell),
  //@ts-ignore
  R.of(
    <TableHeaderKpi label="Short Code" hint="Asset Short Code" value={O.none} />
  ),

  R.of({
    defaultFlex: 1,
    minWidth: 50,
    sort: O.getOrd(s.Ord).compare,
  })
);
