import {
  Threshold,
  UtilizationThresholds,
} from "lib/at-data/UtilizationThresholds";
import palette from "theme/palette";

export const UTILIZATION_THRESHOLDS: UtilizationThresholds = [
  Threshold("", palette.data.meter.first),
  Threshold("", palette.data.meter.second, 10),
  Threshold("", palette.data.meter.third, 35),
  Threshold("", palette.data.meter.fourth, 65),
  Threshold("", palette.data.meter.fifth, 90),
];
