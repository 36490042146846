export enum ReportType {
  RVO_REPORT = "RVO",
  GRAPH_PACKS = "GraphPacks",
  HOURLY_EXPORT = "HourlyExport",
  OTHER_REPORT = "OTHER",
}

export const DefaultReportDescriptions: Record<ReportType, string> = {
  [ReportType.RVO_REPORT]:
    "RVO reports provide a readout of registered vs occupancy for the specified time period.",
  [ReportType.GRAPH_PACKS]:
    "This report shows a set of occupancy and utilization graphs using Hourly Export data to provide historical insights into space usage at building, floor, and zone levels.",
  [ReportType.HOURLY_EXPORT]:
    "This view shows hourly data also used in Graph Packs in its raw CSV format. It is useful for constructing your own data visualizations",
  [ReportType.OTHER_REPORT]: "",
};
