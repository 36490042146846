import { Assets } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { featureCollection, polygon } from "@turf/turf";
import { clog } from "lib/util";
import uniqolor from "uniqolor";
import { getGeometry } from "lib/at-data/assets/getters";
import { getZoneCode } from "lib/at-data/assets/getZoneCode";

export const toPolygonsGeoJSON = (assets: Assets, selectedAssetId?: string) => {
  return pipe(
    assets,
    A.filterMap((asset) =>
      pipe(
        asset,
        getGeometry,
        O.map(
          flow(A.of, (_) =>
            polygon(_, {
              asset,
              name: asset.name,
              assetId: asset.id,
              selected: asset.id === selectedAssetId,
              color: uniqolor(asset.id, {}).color,
              code: pipe(
                asset,
                getZoneCode,
                O.getOrElse(() => "")
              ),
            })
          )
        )
      )
    ),
    featureCollection
  );
};
