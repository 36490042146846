import { User } from "@auth0/auth0-spa-js";
import { Auth0Profile } from "lib/auth0/Auth0Profile";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { eqUUID, UUID } from "lib/at-data/UUID";
import * as C from "io-ts/Codec";
import * as Eq from "fp-ts/Eq";
import * as s from "fp-ts/string";
import * as A from "fp-ts/Array";
import * as L from "monocle-ts/Lens";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import * as O from "fp-ts/Option";
import { clog } from "lib/util";

export const DashboardUserModel = C.struct({
  dataProvider: UUID,
  authOrganization: C.string,
  featureFlags: C.array(C.string),
  email: optionFromNullable(C.string),
  family_name: optionFromNullable(C.string),
  given_name: optionFromNullable(C.string),
  nickname: optionFromNullable(C.string),
  picture: optionFromNullable(C.string),
});

export type DashboardUser = C.TypeOf<typeof DashboardUserModel>;

export const FeatureFlagsL = pipe(
  L.id<DashboardUser>(),
  L.prop("featureFlags")
);

export const DashboardUserEq = Eq.struct<DashboardUser>({
  authOrganization: s.Eq,
  dataProvider: eqUUID,
  featureFlags: A.getEq(s.Eq),
  email: O.getEq(s.Eq),
  family_name: O.getEq(s.Eq),
  given_name: O.getEq(s.Eq),
  nickname: O.getEq(s.Eq),
  picture: O.getEq(s.Eq),
});

export const UserNotAuthenticated = Error("Not Authenticated");

export const fromAuth0Profile = (profile: Auth0Profile) => {
  return pipe(
    profile,
    E.fromNullable(UserNotAuthenticated),
    E.chain(
      flow(
        DashboardUserModel.decode,

        E.mapLeft(() => Error("Invalid User Profile"))
      )
    )
  );
};

export const getAuthOrganization = (u: DashboardUser) => u.authOrganization;
