import { pipe } from "fp-ts/function";
import { numbroFormat, numerable } from "lib/formatters/numerable";

export type NumberFormatterStrategy = (n: number) => string;

export const UINumber: NumberFormatterStrategy = (n) =>
  pipe(
    n,
    numbroFormat({
      average: true,
      mantissa: 2,
      totalLength: 2,
      trimMantissa: true,
    })
  );

export const CommaNumber: NumberFormatterStrategy = (n) =>
  pipe(
    n,
    numbroFormat({
      thousandSeparated: true,
    })
  );

export const CommaNumberNoDecimal: NumberFormatterStrategy = (n) =>
  pipe(
    n,
    numbroFormat({
      thousandSeparated: true,
      mantissa: 0,
    })
  );

export const OneDecimal: NumberFormatterStrategy = (n: number) =>
  pipe(
    n,
    numbroFormat({
      mantissa: 1,
      trimMantissa: true,
    })
  );

export const TwoDecimal: NumberFormatterStrategy = (n: number) =>
  pipe(
    n,
    numbroFormat({
      mantissa: 2,
      thousandSeparated: true,
      // trimMantissa: true,
    })
  );

export const USD = (n: number) => pipe(n, TwoDecimal, (_) => `$${_}`);

export const formatNumber = (f: NumberFormatterStrategy) => (n: number) =>
  pipe(n, f, (_) => _.toLocaleUpperCase());
