import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import { isBuilding, isZone } from "lib/at-data/assets/predicates";
import { getId, lastFourCharacters } from "lib/at-data/assets/getters";
import { toUpperCase } from "fp-ts/string";
import { sequenceT } from "fp-ts/Apply";
import * as A from "fp-ts/Array";
import * as AA from "fp-ts-std/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import { not } from "fp-ts/Predicate";

export const getZoneCode = (asset: Asset): O.Option<string> =>
  pipe(
    [
      asset.shortCode,
      pipe(
        asset,
        O.fromPredicate(not(isBuilding)),
        O.map(flow(getId, lastFourCharacters, toUpperCase))
      ),
    ],
    A.compact,
    NEA.fromArray,
    O.map(AA.join("-"))
  );
