import * as O from "fp-ts/lib/Option";
import * as s from "fp-ts/lib/string";
import { List, styled, Tooltip } from "@mui/material";
import palette from "theme/palette";
import React, { useCallback } from "react";
import theme from "theme/theme";
import { vars } from "theme/helpers";
import ContextualSidebarListItem from "./ContextualSidebarListItem";
import ContextualSidebarDrawer from "./ContextualSidebarDrawer";
import * as A from "fp-ts/Array";
import { flow, pipe } from "fp-ts/function";
import { useCollectorOld } from "lib/at-react/defineController";
import { AppController } from "controllers/AppController/AppController";
import * as L from "monocle-ts/Lens";
import { DashboardUser } from "views/authenticated/app/DashboardUser";
import {
  hasFeatureFlag,
  isFeatureFlagAvailable,
} from "controllers/AppController/IsFeatureFlagAvailable";
import { and, not } from "fp-ts/Predicate";
import { FeatureFlagController } from "controllers/FeatureFlagController/FeatureFlagController";

type SidebarTab = {
  id: string;
  label: string;
  icon: React.ElementType;
  featureFlag?: string;
  hidden?: boolean;
  tooltipTitle?: string;
};

export const isVisibleFor = (featureFlags: Set<string>) => (t: SidebarTab) =>
  pipe(
    t.featureFlag,
    O.fromNullable,
    O.map((ff) => pipe(featureFlags, hasFeatureFlag(ff))),
    O.alt(() => O.some(true)),
    O.getOrElseW(() => false)
  );
export const isHidden = (t: SidebarTab) => !!t.hidden;

const ContextualSidebar: React.FC<{
  tabs: Array<SidebarTab>;
  open?: boolean;
  selectedItem: O.Option<string>;
  onTabClick: (id: string) => void;
}> = ({ onTabClick, selectedItem, children, tabs, open = false }) => {
  const handleItemClick = useCallback(
    (itemId: string) => () => onTabClick(itemId),
    [onTabClick]
  );
  const featureFlags = useCollectorOld(
    FeatureFlagController,
    L.prop("featureFlags")
  );

  return (
    <>
      <Sidebar open={open} className="mui-fixed">
        <List>
          {pipe(
            tabs,
            A.filter(pipe(isVisibleFor(featureFlags), and(not(isHidden)))),

            A.mapWithIndex((index, item) => (
              <ContextualSidebarListItem
                key={`${item.id}-${index}`}
                icon={item.icon}
                label={item.label}
                aria-label={item.label}
                selected={O.getEq(s.Eq).equals(selectedItem, O.some(item.id))}
                onClick={handleItemClick(item.id)}
                tooltipTitle={item.tooltipTitle}
              />
            ))
          )}
        </List>
      </Sidebar>
      <ContextualSidebarDrawer open={open} hideBackdrop={true}>
        {children}
      </ContextualSidebarDrawer>
    </>
  );
};

export default ContextualSidebar;

const Sidebar = styled("nav")<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  min-width: ${vars.contextualSidebar};
  max-width: 0;
  padding-top: ${vars.navBar};
  background: ${palette.neutral[700]};
  box-shadow: ${(props) =>
    props.open ? `-1px 0 ${palette.neutral[500]}` : null};
  transition: ${theme.transitions.create(["box-shadow"])};

  .MuiListItemButton-root {
    height: ${vars.navBar};
    color: ${palette.neutral[400]};
    transition: ${theme.transitions.create(["background", "color"], {
      duration: 150,
    })};

    &[data-selected="true"] {
      background-color: ${palette.neutral[600]};
    }

    &:hover {
      color: ${palette.neutral[100]};
      background-color: ${palette.neutral[600]};
    }
  }

  .MuiListItemIcon-root {
    min-width: 0;
    color: inherit;
  }
`;
