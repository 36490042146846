import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import { GeoReference } from "lib/at-data/assets/models/GeoReferenceModel";
import { pipe } from "fp-ts/function";
import { sequenceT } from "fp-ts/Apply";
import * as A from "fp-ts/Array";
import {
  applyToPoint,
  compose,
  inverse,
  scale,
  translate,
} from "transformation-matrix";
import * as AA from "fp-ts-std/Array";
import { toMatrix } from "views/authenticated/lucifer/lib";
import palette from "theme/palette";
import { toMercator } from "@turf/turf";
import {
  WORKSPACE_HEIGHT,
  WORKSPACE_WIDTH,
} from "views/authenticated/lucifer/consts";
import { useCollectorOld } from "lib/at-react/defineController";
import { ImageArtifactsController } from "controllers/FloorBackgroundImagesController/FloorBackgroundImagesController";
import * as L from "monocle-ts/Lens";
import { BackgroundArtifactsL } from "controllers/FloorBackgroundImagesController/lens";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import { getGeometry } from "lib/at-data/assets/getters";

export const PolygonReferenceLayer: React.FC<{
  asset: Asset;
  level: Asset;
  geoReference: O.Option<GeoReference>;
}> = (props) => {
  const backgroundImageArtifacts = useCollectorOld(
    ImageArtifactsController,
    L.composeLens(BackgroundArtifactsL)
  );
  const image = pipe(backgroundImageArtifacts, getSliceByUUID(props.level.id));
  const localAssetGeometryPoints = pipe(
    sequenceT(O.Monad)(
      pipe(props.asset, getGeometry, O.map(A.map(toMercator))),
      pipe(props.geoReference, O.map(toMatrix)),
      image
    ),
    O.map(([polygon, matrix, fp]) =>
      pipe(
        polygon,
        A.map((point) =>
          applyToPoint(
            compose(
              translate(
                WORKSPACE_WIDTH,
                (WORKSPACE_WIDTH * 3 - WORKSPACE_HEIGHT) / 2
              ),
              scale(WORKSPACE_WIDTH / fp.planeWidth),
              inverse(matrix)
            ),
            point
          )
        )
      )
    )
  );

  return pipe(
    localAssetGeometryPoints,
    O.map(A.map((p: [number, number]) => `${p[0]},${p[1]}`)),
    O.map(AA.join(" ")),
    O.bindTo("points"),
    O.apS("fp", image),

    O.foldW(
      () => null,
      ({ points, fp }) => (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={WORKSPACE_WIDTH * 3}
            height={WORKSPACE_WIDTH * 3}
            // viewBox={`0 0 ${WORKSPACE_WIDTH * 3} ${WORKSPACE_HEIGHT * 3}`}
          >
            <polygon
              points={points}
              fill="none"
              stroke={palette.primary["400"]}
              strokeWidth={"5px"}
            />
          </svg>
        </div>
      )
    )
  );
};
