import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import Icon, { AtArrowCaretDoubleDown, AtSnapshots } from "components/ui/Icon";
import LabeledCheckbox from "components/ui/LabeledCheckbox";
import React from "react";
import { RiPauseFill, RiPlayFill } from "react-icons/ri";
import palette from "theme/palette";
import theme from "theme/theme";

interface IActivityContentTimebarGraph {
  handleTimebarClose: () => void;
}

const ActivityContentTimebarControls: React.FC<IActivityContentTimebarGraph> =
  ({ handleTimebarClose }) => {
    const [isPlaying, setIsPlaying] = React.useState(false);

    return (
      <StyledBox>
        <Stack direction="row" sx={{ width: "33.333%" }}>
          {/*<LabeledCheckbox label="Snapshots" mode="dark" defaultChecked />*/}
          {/*<LabeledCheckbox label="Events" mode="dark" />*/}
          {/*<LabeledCheckbox label="Full History" mode="dark" />*/}
        </Stack>
        <Stack direction="row">
          {/*<IconButton*/}
          {/*  color="primary"*/}
          {/*  size="small"*/}
          {/*  sx={{ backgroundColor: "transparent" }}*/}
          {/*  onClick={() => setIsPlaying(!isPlaying)}*/}
          {/*  aria-label="Play"*/}
          {/*>*/}
          {/*  <Icon icon={isPlaying ? RiPauseFill : RiPlayFill} />*/}
          {/*</IconButton>*/}
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{ width: "33.333%" }} // same width as left checkboxes to center play btn
          justifyContent="flex-end"
        >
          {/*<IconButton color="primary" size="small">*/}
          {/*  <Icon icon={AtSnapshots} />*/}
          {/*</IconButton>*/}
          <IconButton
            onClick={handleTimebarClose}
            color="primary"
            size="small"
            aria-label="Close Time Bar"
          >
            <Icon icon={AtArrowCaretDoubleDown} />
          </IconButton>
        </Stack>
      </StyledBox>
    );
  };

export default ActivityContentTimebarControls;

const StyledBox = styled(Box)`
  height: 40px;
  padding: 0 ${theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
