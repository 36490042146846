import { TabContext } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import PageTable from "components/table/PageTable";
import React from "react";
import ReportsTableAll from "./ReportsTableAll";
import ReportsTableDrafts from "./ReportsTableDrafts";
import ReportsTableSnapshots from "./ReportTableSnapshots";
import ReportsTableBookedActual from "./ReportsTableBookedActual";
import ReportsTableTabs from "./ReportsTableTabs";
import ReportsTableHeader from "./ReportsTableHeader";

const ReportsTable: React.FC = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    // <Box sx={{ width: "100%" }}>
    //   <TabContext value={value}>
    //     <Stack p={4} py={2}>
    //       <Typography variant="h4">All Reports</Typography>
    //     </Stack>
    //     <PageTable>
    //       <ReportsTableAll />
    //       <ReportsTableSnapshots />
    //       <ReportsTableDrafts />
    //     </PageTable>
    //   </TabContext>
    // </Box>

    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <ReportsTableTabs handleChange={handleChange} />
        <PageTable>
          <ReportsTableHeader />
          <ReportsTableAll />
          <ReportsTableBookedActual />
          {/*<ReportsTableSnapshots />*/}
          {/*<ReportsTableDrafts />*/}
        </PageTable>
      </TabContext>
    </Box>
  );
};

export default ReportsTable;
