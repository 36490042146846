import { SeriesContext } from "contexts/SeriesContext";
import { subDays } from "date-fns/fp";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as n from "fp-ts/number";
import { calculateResolution } from "lib/at-data/requests/temporal/FromNumberOfBuckets";
import {
  SeriesRequest,
  SeriesRequestEq,
} from "lib/at-data/requests/temporal/SeriesRequest";
import * as Cl from "lib/at-react/collector";
import { reactState } from "lib/at-react/state/reactState";
import * as L from "monocle-ts/Lens";

export const TimelineWidthCollector = pipe(
  reactState<WidthContext>({ width: 1720 }, Eq.struct({ width: n.Eq })),
  Cl.makeMemo(Eq.struct({}))
);

export const WidthL = pipe(L.id<WidthContext>(), L.prop("width"));

export type WidthContext = {
  width: number;
};

export const EmptySeries = SeriesRequest(
  pipe(new Date(), subDays(1)),
  new Date()
);

export const DESIRED_BAR_SIZE = 14;

export const TimelineRangeCollector = pipe(
  reactState<SeriesContext>(
    { series: EmptySeries },
    Eq.struct({ series: SeriesRequestEq })
  ),
  Cl.propToState(Eq.struct({ series: SeriesRequestEq })),
  Cl.make
);

export const TimelineGranulatedRangeCollector = pipe(
  Cl.ask<WidthContext & SeriesContext>({
    width: 1720,
    series: EmptySeries,
  }),
  Cl.map(({ width, series }) => pipe(series, calculateResolution)),
  Cl.make
);
