import * as C from "io-ts/Codec";
import { UUID } from "lib/at-data/UUID";
import { option } from "lib/codecs/option";
import { AssetTypeModel } from "lib/at-data/assets/AssetTypeModel";

export const AssetBaseModel = C.struct({
  id: UUID,
  legacyZoneId: option(UUID),
  name: C.string,
  parent: option(UUID),
  type: C.fromDecoder(AssetTypeModel),
  tags: C.array(C.string),
  shortCode: option(C.string),
});

export type AssetBase = C.TypeOf<typeof AssetBaseModel>;

export const getAssetBase = <T extends AssetBase>({
  id,
  name,
  parent,
  type,
  tags,
  shortCode,
  legacyZoneId,
}: T) => ({ id, name, parent, type, tags, legacyZoneId });
