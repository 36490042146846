import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { BrowserHistoryState } from "lib/at-react/defineHistoryController";
import * as C from "io-ts/Codec";
import { AssetsPageUIStateModel } from "controllers/UIStateCollector/models/AssetsPageUIStateModel";
import { ActivityPageUIStateModel } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import { LuciferPageUIStateModel } from "controllers/UIStateCollector/models/LuciferPageUIStateModel";

export const UIStateModel = C.struct({
  assetsPage: AssetsPageUIStateModel,
  activityPage: ActivityPageUIStateModel,
  luciferPage: LuciferPageUIStateModel,
});

export type UIState = C.TypeOf<typeof UIStateModel>;

export const PathL = pipe(
  L.id<BrowserHistoryState<UIState>>(),
  L.prop("pathname")
);
