import { Map, PaddingOptions } from "mapbox-gl";

export type MapboxContext = { map: Map };

export type MapBoxPaddingOptionsContext = {
  padding: PaddingOptions;
};

export const withPaddingOptions =
  <T>(padding: PaddingOptions) =>
  (ctx: T) => ({
    ...ctx,
    padding,
  });
