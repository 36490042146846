import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { TableCell, TableRow } from "@mui/material";
import { InvalidAssetIndicator } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/InvalidAssetIndicator";
import { pipe } from "fp-ts/function";
import {
  validateBuilding,
  validateLevel,
} from "lib/at-data/assets/validations/validateLevel";
import { ClipboardCopy, Property } from "./components";
import { LevelFloorMetaPropertiesRow } from "./properties/LevelFloorMetaPropertiesRow";
import { AssetShortCodePropertyRow } from "./properties/AssetShotCodePropertyRow";

export const BuildingAssetProperties: React.FC<{ asset: Asset }> = (props) => {
  const validBuilding = pipe(props.asset, validateBuilding);
  return (
    <>
      <TableRow
        key={"buildingAsset"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          Asset Class
        </TableCell>
        <TableCell align="right">
          <strong>Building </strong>
          <InvalidAssetIndicator validation={validBuilding} />
        </TableCell>
      </TableRow>
      <AssetShortCodePropertyRow asset={props.asset} />
    </>
  );
};
