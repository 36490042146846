import { Box, Stack, styled, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import DateRangePicker from "components/ui/range/DateRangePicker";
import { AssetOContext } from "contexts/AssetContext";
import {
  ActivityLiveOccupancyController,
  ActivityLiveOccupancyControllerComponent,
} from "controllers/AssetLiveOccupancyController/ActivityLiveOccupancyController";
import { LastOccupancyEstimateL } from "controllers/AssetLiveOccupancyController/state";
import {
  AssetsMeanOccupancyController,
  AssetsMeanOccupancyControllerComponent,
} from "controllers/AssetsMeanOccupancyController/AssetsMeanOccupancyController";
import { ActivityPageUIState } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import * as A from "fp-ts/Array";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { calculateResolution } from "lib/at-data/requests/temporal/FromNumberOfBuckets";
import { getActiveBucket } from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import * as Cl from "lib/at-react/collector";

export const SelectedSliceCollector = pipe(
  Cl.ask<Pick<ActivityPageUIState, "selectedSlice" | "selectedRange">>({
    selectedSlice: O.none,
    selectedRange: { start: new Date(), end: new Date() },
  }),
  Cl.map(({ selectedSlice, selectedRange }) =>
    pipe(
      selectedSlice,
      O.getOrElse(() =>
        pipe(selectedRange, calculateResolution, getActiveBucket(new Date()))
      )
    )
  ),
  Cl.make
);
