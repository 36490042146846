import * as AD2 from "lib/at-data/AsyncData2";
import React from "react";
import * as O from "fp-ts/Option";
import AssetsTableCellOccupancy from "views/authenticated/assets/page/components/AssetsTable/AssetsTableCellOccupancy";
import { flow, pipe } from "fp-ts/function";
import * as AD from "lib/at-data/AsyncData";
import { Skeleton } from "@mui/material";
import * as NS from "lib/at-data/NumberSeries";
import { Pending } from "./AssetAreaCell";

export const OccupancyCell: React.FC<{
  value: {
    series: AD2.AsyncData2<O.Option<NS.NumberSeries>>;
  };
}> = ({ value }) =>
  pipe(
    value.series,
    AD2.fold(
      () => null,
      () => (
        <Skeleton
          variant={"text"}
          width={100}
          height={24}
          sx={{ borderRadius: 50 }}
        />
      ),
      O.foldW(
        () => null,
        (series) => <AssetsTableCellOccupancy series={series} />
      ),
      () => null
    )
  );
