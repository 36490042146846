import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  FilterByIdsL,
  FilterByTypeL,
} from "controllers/UIStateCollector/lens/assetsPage";
import { FilterByParentId } from "controllers/UIStateCollector/models/assetFilterModel";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { getId } from "lib/at-data/assets/getters";
import { UUID } from "lib/at-data/UUID";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { RiArrowGoBackFill } from "react-icons/ri";
import Icon, { AtLayers } from "components/ui/Icon";
import theme from "theme/theme";
import palette from "theme/palette";
import styled from "styled-components";
import ActivityControlsLayersMenu from "./ActivityControlsLayersMenu";
import { ActivityDataLegend } from "./ActivityControlsHeatmapKey";
import ActivityControlsFloorPickerMenu from "./ActivityControlsFloorPickerMenu";
import * as Ds from "lib/at-data/DataSlice";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import * as O from "fp-ts/Option";
import * as IO from "fp-ts/IO";
import { Assets, getByParent } from "lib/at-data/assets/assets";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import {
  getParentAssetOf,
  getParentLineById,
} from "lib/at-data/assets/filters";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { Asset, eqAsset } from "lib/at-data/assets/Asset";
import { isBuilding, isFloor, isZone } from "lib/at-data/assets/predicates";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import {
  ActivityPageSelectedAssetIdL,
  ActivityPageUIStateL,
  AssetsFilterL,
  getActivitySetSelectedAssetLens,
} from "controllers/UIStateCollector/lens/activityPage";
import * as L from "monocle-ts/Lens";
import { or } from "fp-ts/Predicate";
import {
  BrowserHistoryState,
  useHistoryControllerDispatch,
} from "lib/at-react/defineHistoryController";
import { UIState } from "controllers/UIStateCollector/models/UIStateModel";
import {
  SelectedAssetL,
  SelectedAssetsController,
} from "controllers/SelectedAssetsController/SelectedAssetsController";
import {
  // BreadcrumbButton,
  HeaderBreadcrumbs,
} from "views/authenticated/assets/page/components/AssetsTable/HeaderBreadcrumbs";
import { match } from "ts-adt";

export const isBuildingDetailMode = (selectedAsset: Asset) =>
  pipe(selectedAsset, getParentAssetOf(isFloor), R.map(O.isSome));

const buildingExitIO =
  (dispatch: Dispatch<SetStateAction<BrowserHistoryState<UIState>>>) =>
  (selectedAsset: Asset): R.Reader<Assets, IO.IO<void>> =>
    pipe(
      selectedAsset,
      getParentAssetOf(isBuilding),
      R.chainW((assetO) =>
        pipe(
          R.ask<Assets>(),
          R.map((allAssets) => () => {
            pipe(
              getActivitySetSelectedAssetLens(assetO, assetO, allAssets),
              dispatch
            );
          })
        )
      )
    );

export const ActivityBreadCrumbs: React.FC<{ assetId: O.Option<UUID> }> = (
  props
) => {
  const allAssets = useCollectorOld(AssetsController, L.prop("assets"));

  const parentLine = pipe(
    props.assetId,
    O.map((id) => getParentLineById(id)(pipe(allAssets, fromAD2)))
  );

  const dispatch = useHistoryControllerDispatch(UIStateController);

  const handleClickBreadCrumb = useCallback(
    (asset: Asset) => {
      dispatch(
        getActivitySetSelectedAssetLens(
          O.some(asset),
          pipe(asset, getParentAssetOf(isBuilding))(pipe(allAssets, fromAD2)),
          pipe(allAssets, fromAD2)
        )
      );
    },
    [allAssets]
  );
  return (
    <Breadcrumbs>
      {pipe(
        parentLine,
        O.map(A.reverse),
        O.map((reversedParentline) =>
          pipe(
            reversedParentline,
            A.zip(
              pipe(
                reversedParentline,
                A.map((asset) =>
                  pipe(
                    pipe(asset, getByParent)(pipe(allAssets, fromAD2)),
                    NEA.fromArray
                  )
                )
              )
            ),
            A.mapWithIndex((i, [breadCrumbAsset, children]) =>
              pipe(
                children,
                O.filter(
                  () =>
                    i < reversedParentline.length - 1 ||
                    reversedParentline.length === 1
                ),
                O.fold(
                  () => <Crumb>{breadCrumbAsset.name}</Crumb>,
                  () => (
                    <BreadcrumbButton
                      onClick={() => handleClickBreadCrumb(breadCrumbAsset)}
                    >
                      {breadCrumbAsset.name}
                    </BreadcrumbButton>
                  )
                )
              )
            )
          )
        ),

        O.getOrElseW(() => null)
      )}
    </Breadcrumbs>
  );
};

const ActivityControls: React.FC<{
  leftOpen: boolean;
  bottomOpen: boolean;
}> = ({ leftOpen, bottomOpen, ...props }) => {
  const dispatch = useControllerDispatch(UIStateController);
  const selectedAsset = useCollectorOld(
    SelectedAssetsController,
    L.composeLens(SelectedAssetL)
  );

  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [heatmap, setHeatmap] = React.useState(true);
  const [alerts, setAlerts] = React.useState(true);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAsset = useCallback(
    (asset: Asset) => {
      pipe(
        getActivitySetSelectedAssetLens(
          O.some(asset),
          pipe(
            O.some(asset),
            O.chain((a) => getParentAssetOf(isBuilding)(a)(allAssets))
          ),
          allAssets
        ),
        dispatch
      );
    },
    [dispatch, allAssets]
  );

  return (
    <>
      <LeftControls open={leftOpen} spacing={3}>
        {/* {pipe(
          selectedAsset,
          O.map(
            (sa) =>
              isBuildingDetailMode(sa)(allAssets) && (
                <Button
                  onClick={pipe(sa, buildingExitIO(dispatch))(allAssets)}
                  color="secondary"
                  size="small"
                  startIcon={<Icon icon={RiArrowGoBackFill} />}
                  variant="contained"
                >
                  Exit Building
                </Button>
              )
          ),
          O.getOrElseW(() => null)
        )} */}

        <ActivityBreadCrumbs assetId={pipe(selectedAsset, O.map(getId))} />

        <ActivityDataLegend
          enabled={pipe(
            selectedAsset,
            O.filter(pipe(isFloor, or(isZone))),
            O.isSome
          )}
        />
      </LeftControls>
      <RightControls spacing={1} direction="row">
        <Tooltip title="Layers">
          <IconButton color="secondary" onClick={handleClick}>
            <Icon icon={AtLayers} />
          </IconButton>
        </Tooltip>
        <ActivityControlsLayersMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          heatmap={heatmap}
          alerts={alerts}
          setHeatmap={setHeatmap}
          setAlerts={setAlerts}
        />
      </RightControls>
      <BottomControls open={bottomOpen}>
        {pipe(
          selectedAsset,
          O.map(flow(getParentAssetOf(isBuilding))),
          O.sequence(R.Monad),
          R.map(O.flatten),
          R.chain((floorO) =>
            pipe(O.of(getByParent), O.ap(floorO), O.sequence(R.Monad))
          ),
          R.map(
            flow(
              O.fold(
                () => null,
                (floors) => (
                  <ActivityControlsFloorPickerMenu
                    selectedFloor={pipe(
                      selectedAsset,
                      O.map(getParentAssetOf(isFloor)),
                      O.sequence(R.Monad),
                      R.map(O.flatten)
                    )(allAssets)}
                    floors={floors}
                    onFloorClick={handleSelectAsset}
                  />
                )
              )
            )
          )
        )(allAssets)}
      </BottomControls>
    </>
  );
};

export default ActivityControls;

const LeftControls = styled(Stack)<{ open: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  margin: ${theme.spacing(2)};
  transform: translateX(${({ open }) => (open ? "0" : "48px")});
  transition: ${({ open }) =>
    open ? "transform 250ms ease" : "transform 250ms ease 150ms"};
  z-index: 5;
  pointer-events: none;
`;

const Breadcrumbs = styled("nav")`
  padding: 10px 0;
  flex-direction: row;
  pointer-events: all;
`;

const BreadcrumbButton = styled("button")`
  background-color: transparent;
  padding: 8px;
  color: ${palette.primary[300]};
  outline: none;
  border: none;
  font-size: clamp(
    1.4rem,
    calc(1.8320675105485233rem + -0.4219409282700425vw),
    1.6rem
  );
  font-weight: 400;
  line-height: 1.5;
  font-family: Fira Sans, sans-serif;
  color: ${palette.primary[300]};
  transition: color 0.2s ease;
  margin-left: -8px;
  display: inline-block;

  &:hover {
    cursor: pointer;
    color: ${palette.neutral[100]};
    transition: color 0.2s ease;
    text-decoration: underline;
  }

  &::after {
    content: "/";
    display: inline-block;
    margin: 0 0 0 12px;
    color: ${palette.neutral[500]};
    padding: 0;
  }

  &:last-child::after {
    content: "";
  }
`;

const Crumb = styled("span")`
  padding: 8px;
  font-size: clamp(
    1.4rem,
    calc(1.8320675105485233rem + -0.4219409282700425vw),
    1.6rem
  );
  font-weight: 400;
  line-height: 1.5;
  font-family: Fira Sans, sans-serif;
  color: ${palette.neutral[200]};
  transition: color 0.2s ease;
  margin-left: -8px;
  display: inline-block;
`;

const RightControls = styled(Stack)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${theme.spacing(2)};
  z-index: 5;
`;
const BottomControls = styled(Stack)<{ open: boolean }>`
  position: absolute;
  bottom: 76px;
  right: 0;
  margin: ${theme.spacing(2)};
  z-index: 5;

  transform: translateY(${({ open }) => (open ? "-176px" : "0px")});
  transition: ${({ open }) =>
    open ? "transform 250ms ease-out" : "transform 250ms ease-out"};
`;
