import { EDIT_FEATURE } from "constants/featureFlags";
import {
  addAssetTags,
  deleteAssetTags,
  LegacyAssetsModificationsController,
} from "controllers/AssetsController/AssetsController";
import { ByAllAssetIdsOnly } from "controllers/UIStateCollector/utils";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import * as S from "fp-ts/Set";
import {
  addAssetTag,
  AssetTag,
  AssetTagEq,
  AssetTagOrd,
  AssetTags,
} from "lib/at-api/asset-tags/assetTags";
import { Asset } from "lib/at-data/assets/Asset";
import { ByTag, filterAssets } from "lib/at-data/assets/filters";
import { getId } from "lib/at-data/assets/getters";
import { AssetTagsL } from "lib/at-data/assets/lens";
import { updateAsset } from "lib/at-data/assets/modify";
import { noop } from "lib/util";
import * as T from "monocle-ts/Traversal";
import {
  validateNewTagIsNotADuplicate,
  validateNewTagIsNotADuplicateIgnoringCase,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetsDetailPreviewTab";
import { defineColumn } from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import * as ROR from "fp-ts/ReadonlyRecord";
import { AssetTagsCell } from "views/authenticated/assets/page/components/AssetsTable/cells/AssetTagsCell";
import {
  AssetsTableHeaderContextMenu,
  ColumnSortingFilter,
} from "views/authenticated/assets/page/components/AssetsTable/AssetsTableHeaderContextMenu";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Divider,
  InputBase,
  Stack,
  styled,
  Typography,
  ListItem,
  Checkbox,
  IconButton,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import {
  AssetsFilterL,
  AssetsPageUIStateL,
  FilterByIdsL,
  FilterByNameL,
  FilterByTagL,
  FilterByTypeL,
} from "controllers/UIStateCollector/lens/assetsPage";
import * as L from "monocle-ts/Lens";
import {
  AssetsController,
  fromAD2,
  RawAssetsController,
} from "views/authenticated/root/AssetsController";
import { TagsL } from "views/authenticated/root/controller/state";
import {
  StyledMenuListCheckbox,
  CheckboxStack,
} from "views/authenticated/assets/page/components/AssetsTable/AssetsTableHeaderTagsMenu";
import { useHistoryControllerDispatch } from "lib/at-react/defineHistoryController";
import Icon from "../../../../../../../../components/ui/Icon";
import { RiCloseCircleLine, RiPencilLine, RiSearchLine } from "react-icons/ri";
import theme from "../../../../../../../../theme/theme";
import palette from "../../../../../../../../theme/palette";
import { DEFAULT_FILTERS } from "../../../../../../../../controllers/UIStateCollector/DEFAULT_FILTERS";
import { FeatureFlag } from "components/featureFlag/FeatureFlag";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import EditIcon from "@mui/icons-material/Edit";

export const AssetTagsColumnHeader: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checkedTags, setCheckedTags] = React.useState<Set<string>>(new Set());
  const [editedTag, setEditedTag] = React.useState<O.Option<AssetTag>>(O.none);
  const [tags] = useController(RawAssetsController, TagsL.get);

  const dispatch = useHistoryControllerDispatch(UIStateController);

  const [_] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).get
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickClear = useCallback(() => {
    dispatch(
      pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).set({
        ...DEFAULT_FILTERS,
      })
    );
  }, [dispatch]);

  const [filteredTags, setFilteredTags] = useState(tags);

  const filterBySearch: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const query = event.target.value;
    let updatedTags = [...tags];
    updatedTags = updatedTags.filter((tag) => {
      return tag.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setFilteredTags(updatedTags);
  };

  const handleRenameTag = useCallback(() => {
    setEditedTag(O.none);
  }, []);

  useEffect(() => {
    setFilteredTags(tags);
  }, [tags]);

  return (
    <div>
      <TableHeaderKpi
        label="Custom"
        hint="User defined descriptors that help identify and group space characteristics."
        value={O.of("Tags")}
        onContextClick={handleClick}
        icon={true}
      />
      <AssetsTableHeaderContextMenu
        onClose={handleClose}
        onApply={() => {
          const selectedTags = pipe(checkedTags, S.toArray(AssetTagOrd));

          dispatch(
            pipe(
              AssetsPageUIStateL,
              L.composeLens(AssetsFilterL),
              L.composeLens(FilterByTagL)
            ).set(selectedTags)
          );

          handleClose();
        }}
        anchorEl={anchorEl}
        title={"Tags"}
      >
        <Divider />

        <TagSearchBox>
          <input
            type={"text"}
            placeholder={"Search tags..."}
            onChange={filterBySearch}
          />
          <Icon icon={RiSearchLine} size="16px" />
        </TagSearchBox>
        <Stack direction="row">
          <ClearButton
            onClick={() => {
              setCheckedTags(new Set());
              setFilteredTags(tags);
              dispatch(
                pipe(AssetsPageUIStateL, L.composeLens(AssetsFilterL)).set({
                  ...DEFAULT_FILTERS,
                })
              );
            }}
          >
            Clear Selection
          </ClearButton>
        </Stack>
        <div style={{ height: 400 }}>
          <VirtualizedTagList
            tags={pipe(
              filteredTags,
              A.sort(AssetTagOrd),
              A.map((text) => ({ text, checked: checkedTags.has(text) }))
            )}
            onTagCheckChange={(tag, value) =>
              value && !checkedTags.has(tag)
                ? pipe(checkedTags, S.insert(AssetTagOrd)(tag), setCheckedTags)
                : pipe(checkedTags, S.remove(AssetTagOrd)(tag), setCheckedTags)
            }
            onTagPencilClick={(tag) => pipe(tag, O.some, setEditedTag)}
          />
          {pipe(
            editedTag,
            O.fold(noop, (tag) => (
              <RenameAssetTagDialog
                tag={tag}
                open={true}
                onClose={() => setEditedTag(O.none)}
                onRename={handleRenameTag}
              />
            ))
          )}
        </div>

        {/*{filteredTags.sort().map((tag, index) => {*/}
        {/*  return (*/}
        {/*    <Stack*/}
        {/*      direction={"row"}*/}
        {/*      style={{ justifyContent: "space-between", width: "100%" }}*/}
        {/*    >*/}
        {/*      <StyledMenuListCheckbox*/}
        {/*        key={`tag-check-box-${index}`}*/}
        {/*        label={tag}*/}
        {/*        // label={<HighlightingLabel label={tag} />}*/}
        {/*        checked={tagChecks[tag]}*/}
        {/*        onChange={(isChecked) => {*/}
        {/*          setTagChecks({*/}
        {/*            ...tagChecks,*/}
        {/*            [tag]: isChecked,*/}
        {/*          });*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <FeatureFlag flag={EDIT_FEATURE}>*/}
        {/*        <IconButton onClick={() => {}} aria-label={`Edit ${tag} tag`}>*/}
        {/*          <Icon icon={RiPencilLine} />*/}
        {/*        </IconButton>*/}
        {/*      </FeatureFlag>*/}
        {/*    </Stack>*/}
        {/*  );*/}
        {/*})}*/}
      </AssetsTableHeaderContextMenu>
    </div>
  );
};

const Row: React.FC<{
  index: number;
  style: React.CSSProperties;
  data: {
    items: { text: string; checked: boolean }[];
    onTagCheckChange: (tag: string, value: boolean) => void;
    onTagPencilClick: (tag: string) => void;
  };
}> = ({ index, style, data }) => {
  const item = data.items[index];

  return (
    <ListItem style={style} key={index} button sx={{ pl: 0 }}>
      <Checkbox
        checked={item.checked}
        onChange={(ev, val) => data.onTagCheckChange(item.text, val)}
      />
      <ListItemText
        primary={item.text}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      />
      <FeatureFlag flag={EDIT_FEATURE}>
        <IconButton
          edge="end"
          aria-label="edit"
          onClick={() => data.onTagPencilClick(item.text)}
        >
          <EditIcon />
        </IconButton>
      </FeatureFlag>
    </ListItem>
  );
};

export const RenameAssetTagDialog: React.FC<{
  tag: string;
  open: boolean;
  onClose: () => void;
  onRename: () => void;
}> = (props) => {
  const [localTag, setLocalTag] = useState(props.tag);
  const tags = useCollectorOld(RawAssetsController, L.composeLens(TagsL));
  const [allAssets] = useController(AssetsController, (rs) =>
    pipe(rs.assets, fromAD2)
  );
  const rootDispatch = useControllerDispatch(RawAssetsController);
  const assetModificationsDispatch = useControllerDispatch(
    LegacyAssetsModificationsController
  );

  useEffect(() => setLocalTag(props.tag), [props.tag]);

  const validTag = pipe(
    tags,
    validateNewTagIsNotADuplicateIgnoringCase(localTag)
  );
  const isValid = E.isRight(validTag);
  const affectedAssets = pipe(allAssets, filterAssets(ByTag(props.tag)));

  const handleTagRename = useCallback(
    (oldTag: string, newTag: string) => {
      pipe(TagsL, L.modify(addAssetTag(newTag)), rootDispatch);
      pipe(
        affectedAssets,
        A.map((asset) =>
          assetModificationsDispatch(deleteAssetTags(A.of(oldTag))(asset))
        )
      );
      pipe(
        affectedAssets,
        A.map((asset) =>
          assetModificationsDispatch(addAssetTags(A.of(newTag))(asset))
        )
      );

      rootDispatch(
        pipe(
          affectedAssets,
          A.map(getId),
          ByAllAssetIdsOnly,
          updateAsset,
          T.composeLens(AssetTagsL),
          T.modify((existingTags) =>
            pipe(existingTags, A.union(AssetTagEq)(A.of(newTag)))
          )
        )
      );
      rootDispatch(
        pipe(
          affectedAssets,
          A.map(getId),
          ByAllAssetIdsOnly,
          updateAsset,
          T.composeLens(AssetTagsL),
          T.modify((existingTags) =>
            pipe(existingTags, A.difference(AssetTagEq)(A.of(oldTag)))
          )
        )
      );
      props.onRename();
    },
    [affectedAssets]
  );

  return (
    <Dialog disableEscapeKeyDown open={props.open} onClose={props.onClose}>
      <DialogTitle>Rename Tag</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <TextField
              error={!isValid}
              helperText={pipe(
                validTag,
                E.swap,
                E.getOrElseW(() => null)
              )}
              autoFocus
              variant="outlined"
              value={localTag}
              onChange={(ev) => {
                setLocalTag(ev.target.value);
              }}
              onKeyPress={(event) => {
                event.stopPropagation();

                if (event.key === "Enter") {
                  event.preventDefault();
                  if (localTag.length && isValid) {
                    setLocalTag("");
                    handleTagRename(props.tag, localTag);
                  }
                }
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          disabled={localTag.length < 1 || !isValid}
          onClick={() => {
            handleTagRename(props.tag, localTag);
          }}
        >
          Rename for {affectedAssets.length} Assets
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const VirtualizedTagList: React.FC<{
  tags: { text: string; checked: boolean }[];
  onTagCheckChange: (tag: string, value: boolean) => void;
  onTagPencilClick: (tag: string) => void;
}> = ({ tags, onTagCheckChange, onTagPencilClick }) => {
  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <List
          height={height}
          width={width}
          itemSize={36} // Adjust based on your styling
          itemCount={tags.length}
          itemData={{ items: tags, onTagCheckChange, onTagPencilClick }}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
};

export default VirtualizedTagList;

// export const HighlightingLabel: React.FC<{
//   label: string;
// }> = (props) => {
//   // const [searchText] = useController(
//   //   UIStateController,
//   //   pipe(ActivityPageUIStateL, L.composeLens(AssetsFilterL)).get
//   // );
//
//   const subArray =
//     searchText.byName.length === 0 ? [] : searchText.byName.split(" ");
//
//   return (
//     <Highlighter
//       highlightClassName="highlight"
//       searchWords={subArray}
//       autoEscape={true}
//       textToHighlight={props.label}
//       highlightStyle={{ backgroundColor: palette.primary[300] }}
//     />
//   );
// };

export const AssetTagsColumn = defineColumn(
  "assetTags",
  pipe(
    R.ask<AssetRowDataContext>(),
    R.map((_) => ROR.singleton("assetTags", _.asset.tags))
  ),
  R.of(AssetTagsCell),
  R.of(<AssetTagsColumnHeader />),
  R.of({
    defaultFlex: 1,
    sort: O.getOrd(N.Ord).compare,
  })
);

const TagSearchBox = styled("div")`
  position: relative;
  width: 100%;

  & input {
    width: 100%;
    padding: 6px 8px;
    margin-top: 12px;
    padding-left: 28px;
    outline: none;
    border: ${palette.neutral[300]} solid 2px;
    border-radius: 2px;
    box-shadow: none;
    transition: border 0.35s ease-out;

    &:focus {
      border-color: ${palette.primary[500]};
      transition: border 0.35s ease-out;
    }
  }
  & svg {
    top: 19px;
    left: 8px;
    position: absolute;
    pointer-events: none;

    & path {
      stroke: ${palette.neutral[400]};
    }
  }
`;

const SearchInput = styled("input")`
  height: 32px;
  padding: 0 ${theme.spacing(1)} 0 calc(16px + ${theme.spacing(2.25)});
  border-radius: ${theme.shape.borderRadius}px;

  &::placeholder {
    color: ${palette.neutral[400]};
  }

  input {
    padding: 0;
  }
`;

const ClearButton = styled(Typography)`
  color: ${palette.neutral[600]};
  background-color: ${palette.neutral[200]};
  transition: ${theme.transitions.create(["box-shadow"], {
    duration: theme.transitions.duration.shortest,
    easing: "ease-out",
  })};
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 3px 12px 3px 12px;
  font-size: 10px;
  display: flex;
  flex: 1;
  width: max-content;
  margin-top: 10px;

  &:hover {
    box-shadow: inset 0 0 0 1px ${palette.neutral[500]};
    background-color: ${palette.neutral[200]};
  }

  &:focus {
    box-shadow: inset 0 0 0 1.5px ${palette.primary[400]};
  }
`;
