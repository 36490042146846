import { Collapse, Stack, Typography, styled } from "@mui/material";
import palette from "theme/palette";
import React, { useState } from "react";
import * as O from "fp-ts/Option";
import {
  BasicDaySchedule,
  foldToSinglePeriod,
  HoursOfOperation,
} from "lib/at-data/HoursOfOperation";
import { pipe } from "fp-ts/function";
import { AssetDetailPreviewKpiIcon } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetDetailPreviewKpiIcon";
import { formatOperatingHour } from "views/authenticated/assets/page/components/AssetsDetailPreview/formatOperatingHour";
import Icon from "components/ui/Icon";
import { MdAccessTimeFilled } from "react-icons/md";

export const DayOperatingHours: React.FC<{
  label: string;
  do: O.Option<BasicDaySchedule>;
}> = (props) => (
  <Day>
    <Symbol>{props.label}</Symbol>
    <Hours>
      {pipe(
        props.do,
        O.fold(
          () => (
            <Stack direction="row" spacing={1} alignItems="center">
              <Icon icon={MdAccessTimeFilled} size="16px" color={"#babec8"} />
              <b>Closed</b>
            </Stack>
          ),
          (oo) => (
            <>
              {formatOperatingHour(navigator.language === "en_US")(oo.open)} -{" "}
              {formatOperatingHour(navigator.language === "en_US")(oo.close)}
            </>
          )
        )
      )}
    </Hours>
  </Day>
);

// TODO No Hours Of operation Set
// TODO wire up expaned hours of oepration view
export const AssetOperatingHours: React.FC<{
  hours: O.Option<HoursOfOperation>;
}> = (props) => {
  const [hours, setHours] = useState(false);
  return (
    <Stack spacing={1} px={3} py={2}>
      {pipe(
        props.hours,
        O.fold(
          () => (
            <HoONotSet>
              Operating hours for this asset are not set. Please contact Lambent
              for assistance.
            </HoONotSet>
          ),
          (ho) =>
            pipe(
              ho,
              foldToSinglePeriod,
              O.fold(
                () => (
                  <>
                    <AssetDetailPreviewKpiIcon
                      label="Operating Hours"
                      value="Varies"
                      icon
                    />
                    <OperatingHours>
                      <ViewAll onClick={() => setHours(!hours)}>
                        View All{" "}
                      </ViewAll>
                      <Collapse in={hours}>
                        <Stack>
                          <DayOperatingHours
                            label={"S"}
                            do={ho.schedule.sunday}
                          />
                          <DayOperatingHours
                            label={"M"}
                            do={ho.schedule.monday}
                          />
                          <DayOperatingHours
                            label={"T"}
                            do={ho.schedule.tuesday}
                          />
                          <DayOperatingHours
                            label={"W"}
                            do={ho.schedule.wednesday}
                          />
                          <DayOperatingHours
                            label={"T"}
                            do={ho.schedule.thursday}
                          />
                          <DayOperatingHours
                            label={"F"}
                            do={ho.schedule.friday}
                          />
                          <DayOperatingHours
                            label={"S"}
                            do={ho.schedule.saturday}
                          />
                        </Stack>
                      </Collapse>
                    </OperatingHours>
                  </>
                ),
                ({ open, close }) => (
                  <AssetDetailPreviewKpiIcon
                    label="Operating Hours"
                    value={`${formatOperatingHour(
                      navigator.language === "en_US"
                    )(open)} - ${formatOperatingHour(
                      navigator.language === "en_US"
                    )(close)}`}
                    icon
                  />
                )
              )
            )
        )
      )}
    </Stack>
  );
};

const HoONotSet = styled(Typography)`
  color: ${palette.neutral[100]};
`;
const OperatingHours = styled("div")`
  margin: 0 !important;
`;

const ViewAll = styled("b")`
  color: ${palette.neutral[450]};
  text-align: right;
  display: block;
  padding: 0;
  margin: -22px 0 0 0;
  text-decoration: underline;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;
const Day = styled("p")`
  color: #8e919f;
  padding: 6px 0 6px 2px;
  border-bottom: 1px solid rgb(106 110 128 / 20%);
  background-color: transparent;
  margin: 0;
  line-height: 100%;

  &:first-of-type {
    border-top: 1px solid rgb(106 110 128 / 20%);
    margin-top: 20px;
  }

  & span {
    transition: color 0.35s;
  }

  &:hover span {
    color: #ffffff;
    transition: color 0s;
  }

  &:first-of-type span:first-of-type,
  &:last-of-type span:first-of-type {
    background-color: ${palette.neutral[450]};
  }
`;
const Symbol = styled("span")`
  color: white;
  background-color: ${palette.status.success.default};
  border-radius: 50%;
  light-height: 100%;
  width: 14px;
  height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 10px;
  font-weight: bold;
  margin-right: 8px;
`;
const Hours = styled("span")`
  color: #8e919f;
  font-size: 12px;

  & b {
    font-weight: 200;
    margin: 0 8px 0 2px;
  }
`;
