import { ASSET_CONTEXT_TABS } from "views/authenticated/activity/page/ASSET_CONTEXT_TABS";
import * as C from "io-ts/Codec";
import * as En from "io-ts/Encoder";
import * as D from "io-ts/Decoder";
import { pipe } from "fp-ts/function";

export const AssetContextTabs = C.fromDecoder(
  D.union(
    D.literal(ASSET_CONTEXT_TABS.ASSET),
    D.literal(ASSET_CONTEXT_TABS.UTILIZATION),
    D.literal(ASSET_CONTEXT_TABS.INSIGHTS),
    D.literal(ASSET_CONTEXT_TABS.SCHEDULE),
    D.literal(ASSET_CONTEXT_TABS.WEATHER),
    D.literal(ASSET_CONTEXT_TABS.ADMIN)
  )
);

export const AssPTSumC = C.sum("_type")({
  [ASSET_CONTEXT_TABS.ASSET]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.ASSET),
  }),
  [ASSET_CONTEXT_TABS.UTILIZATION]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.UTILIZATION),
  }),
  [ASSET_CONTEXT_TABS.INSIGHTS]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.INSIGHTS),
  }),
  [ASSET_CONTEXT_TABS.SCHEDULE]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.SCHEDULE),
  }),
  [ASSET_CONTEXT_TABS.WEATHER]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.WEATHER),
  }),
  [ASSET_CONTEXT_TABS.ADMIN]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.ADMIN),
  }),
  [ASSET_CONTEXT_TABS.SELECTION]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.SELECTION),
  }),
  [ASSET_CONTEXT_TABS.DEFAULT_CAPACITY]: C.struct({
    _type: C.literal(ASSET_CONTEXT_TABS.DEFAULT_CAPACITY),
  }),
});

export type SidePanelTab = C.TypeOf<typeof AssPTSumC>;

// export const AssetsPageTabModel = C.make(
//   pipe(
//     AssetContextTabs,
//     D.map((_) => ({ _type: _ })),
//     D.compose(AssPTSumC)
//   ),
//   pipe(
//     AssPTSumC,
//     En.compose(
//       pipe(
//         AssetContextTabs,
//         En.contramap((_) => _._type)
//       )
//     )
//   )
// );
