import { pipe } from "fp-ts/function";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import * as L from "monocle-ts/Lens";

export type SeriesContext = {
  series: SeriesRequest;
};

export const SeriesContext = (series: SeriesRequest) => ({ series });

export const SeriesL = pipe(L.id<SeriesContext>(), L.prop("series"));
