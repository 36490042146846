import { LoadingButton, TabPanel } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import Icon, { AtDownloadPDF, AtDelete } from "components/ui/Icon";
import React, { useCallback, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import PageTableCellText from "components/table/cells/PageTableCellText";
import ReportsPlaceholderImage from "../ReportsPlaceholderImage";
import PageTableCellThumbnail from "components/table/cells/PageTableCellThumbnail";
import * as O from "fp-ts/Option";
import { useController } from "lib/at-react/defineController";
import { AppController } from "controllers/AppController/AppController";
import { flow } from "fp-ts/lib/function";
import { ReportsController } from "../../../../../controllers/ReportsController/ReportsController";
import { ReportsL } from "../../../../../controllers/ReportsController/state";
import { pipe } from "fp-ts/function";
import * as AD from "../../../../../lib/at-data/AsyncData";
import {
  ByReportId,
  deleteReport,
  fetchReportLinks,
  OrdReportsByDate,
  ReportMetadata,
} from "lib/at-api/reports/reportMetadata";
import * as A from "fp-ts/Array";
import { noop } from "lib/util";
import { toMinimalAppState } from "views/authenticated/app/ReadyAppState";
import * as TE from "fp-ts/TaskEither";
import * as E from "fp-ts/Either";
import * as RTE from "fp-ts/ReaderTaskEither";
import { NeedsPermission } from "components/featureFlag/FeatureFlag";
import { format } from "date-fns/fp";
import * as RT from "fp-ts/ReaderTask";
import { not } from "fp-ts/lib/Predicate";
import { ordDate, reverse } from "fp-ts/Ord";
import * as AA from "fp-ts-std/Array";
import { replace } from "fp-ts/string";
import { isNonEmptyString } from "newtype-ts/es6/NonEmptyString";
import * as D from "fp-ts/Date";
import * as Ord from "fp-ts/Ord";
import { HasTimestampDate } from "lib/at-data/DataSlice";
import { ReportType } from "views/authenticated/reports2/componenets/reportsTable/ReportType";
import { useCollectorWithDispatch } from "lib/at-react/hooks";
import {
  errorNotification,
  NotificationsController,
} from "controllers/NotificationsController/NotificationsController";
import * as L from "monocle-ts/Lens";
import mixpanel from "mixpanel-browser";

export const formatReportDate = format("MMM do yyyy");

export const makeReportFileName = (reportMetadata: ReportMetadata) =>
  pipe(
    [
      O.some(reportMetadata.reportType),
      pipe(
        reportMetadata.department,
        O.fromNullable,
        O.filter(isNonEmptyString),
        O.map(replace(" ", "_"))
      ),
      O.some(pipe(reportMetadata.reportDate, format("MMM-dd-yyyy"))),
    ],
    A.compact,
    AA.join("-")
  );

export const fetchFileAndSendToUser =
  (metadata: ReportMetadata) => (sasUrl: string) =>
    TE.tryCatch(
      () =>
        fetch(sasUrl, {
          method: "GET",
        }).then((response) =>
          response.blob().then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
              new Blob([blob], {
                type: response.headers.get("Content-Type") as string,
              })
            );

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", makeReportFileName(metadata));

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            // @ts-ignore
            link.parentNode.removeChild(link);
          })
        ),
      E.toError
    );

const Download: React.FC<{ report: ReportMetadata }> = ({ report }) => {
  // add link prop

  const [appState] = useController(AppController, (_) => _);
  const [isPreparingFile, setIsPreparingFile] = useState(false);

  const handleClickReportDownload = useCallback(() => {
    pipe(
      appState,
      toMinimalAppState,
      O.foldW(
        () => noop,
        (_) =>
          pipe(
            fetchReportLinks(report.id),
            RTE.apFirstW(pipe(setIsPreparingFile(true), RTE.of)),
            RTE.map((f) => f.sasLink),
            RTE.chainTaskEitherKW(fetchFileAndSendToUser(report)),
            RTE.foldW(
              () => pipe(setIsPreparingFile(false), RT.of),
              () => pipe(setIsPreparingFile(false), RT.of)
            )
          )({ appContext: _ })
      )
    )();
    mixpanel.track("Report Downloaded", {
      report_id: report.id,
      report_type: report.reportType,
    });
  }, [report]);

  return (
    <LoadingButton
      loading={isPreparingFile}
      size="small"
      variant="text"
      color="primary"
      component={Link}
      onClick={handleClickReportDownload}
    >
      <Icon icon={AtDownloadPDF} size="24" sx={{ marginRight: "8px" }} />
      Download
    </LoadingButton>
  );
};

const Delete: React.FC<{ report: ReportMetadata }> = (props) => {
  const [appState] = useController(AppController, (_) => _);
  const [notifications, notificationsDispatch] = useCollectorWithDispatch(
    NotificationsController,
    L.prop("notifications")
  );
  const [reports, reportsDispatch] = useController(
    ReportsController,
    ReportsL.get
  );
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteReportDownload = useCallback(() => {
    setOpen(false);
    pipe(
      appState,
      toMinimalAppState,
      O.foldW(
        () => noop,
        (_) =>
          pipe(
            deleteReport(props.report.id),
            RTE.foldW(
              () =>
                pipe(
                  () =>
                    pipe(
                      errorNotification("Error deleting report"),
                      notificationsDispatch
                    ),
                  RT.fromIO
                ),
              () =>
                pipe(
                  pipe(
                    ReportsL.modify(
                      AD.map(A.filter(not(ByReportId(props.report.id))))
                    ),
                    reportsDispatch
                  ),
                  RT.of,
                  RT.chainIOK(
                    () => () =>
                      pipe(
                        errorNotification("Report sucessfully deleted"),
                        notificationsDispatch
                      )
                  )
                )
            )
          )({ appContext: _ })
      )
    )();
  }, [props.report.id]);

  return (
    <>
      <Button
        size="small"
        variant="text"
        color="warning"
        onClick={handleClickOpen}
      >
        <Icon icon={AtDelete} size="24" sx={{ marginRight: "8px" }} />
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Report?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete{" "}
            {pipe(props.report, makeReportFileName)} report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteReportDownload} color={"error"}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const columns = [
  {
    name: "reportType",
    sortable: false,
    draggable: false,
    header: "",
    minWidth: 60,
    render: ({ value }: { value: ReportType }) => (
      <PageTableCellThumbnail
        image={
          <div style={{ margin: "1.5em" }}>
            <ReportsPlaceholderImage reportType={value} />
          </div>
        }
      />
    ),
  },
  {
    name: "title",
    draggable: false,
    header: "Title",
    render: ({ value }: { value: string }) => (
      <PageTableCellText text={value} textStyles={{ fontWeight: 500 }} />
    ),
  },
  {
    name: "department",
    draggable: false,
    header: "Department",
    render: ({ value }: { value: string }) => (
      <PageTableCellText text={value} />
    ),
  },
  {
    name: "description",
    draggable: false,
    header: "Description",
    defaultFlex: 3,
    minWidth: 300,
    enableColumnAutosize: true,
    render: ({ value }: { value: O.Option<string> }) => (
      <PageTableCellText
        text={pipe(
          value,
          O.getOrElse(() => "")
        )}
        multiline
      />
    ),
  },
  {
    name: "reportDate",
    draggable: false,
    header: "Report Date",
    sortable: true,
    sort: D.Ord.compare,
    defaultFlex: 1,
    render: ({ value }: { value: Date }) => (
      <PageTableCellText text={pipe(value, formatReportDate)} />
    ),
  },
  {
    name: "more",
    sortable: false,
    draggable: false,
    header: "",
    defaultWidth: 150,
    render: ({ data }: { data: ReportMetadata }) => <Download report={data} />,
  },
  {
    name: "delete",
    sortable: false,
    draggable: false,
    header: "",
    defaultWidth: 150,
    render: ({ data }: { data: ReportMetadata }) => {
      return (
        <NeedsPermission permission={"reports:delete"}>
          <Delete report={data} />
        </NeedsPermission>
      );
    },
  },
];

const ReportsTableAll: React.FC = () => {
  const [reports] = useController(
    ReportsController,
    flow(
      ReportsL.get,
      AD.fold(
        () => <div style={{ textAlign: "center" }}>Loading</div>,
        O.foldW(
          () => <span>No Reports Available</span>,
          flow((rl) => (
            <ReactDataGrid
              emptyText={<span>No Reports Available</span>}
              licenseKey="AppName=ArmoredThingsApp,Company=ArmoredThings,ExpiryDate=2023-03-08,LicenseDeveloperCount=2,LicenseType=single_app,Ref=ArmoredThingsLicenseRef,Z=874177969-88290940832246787443234957-812671145-2050061877"
              showColumnMenuTool={false}
              columns={columns}
              dataSource={rl}
              showZebraRows={false}
              showCellBorders={false}
              pagination={true}
              showHoverRows={false}
              columnMinWidth={75}
              rowHeight={80}
              sortable={true}
              defaultSortInfo={{ name: "reportDate", dir: -1 }}
            />
          ))
        )
      )
    )
  );

  // alternative to table: break this function into separate functions to return each field

  return (
    <TabPanel value="1" sx={{ padding: 0, height: "100%" }}>
      {reports}
    </TabPanel>
  );
};

export default ReportsTableAll;
