import React from "react";
import {
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  styled,
} from "@mui/material";
import palette from "theme/palette";
import { vars } from "theme/helpers";
import Icon, { AtChevronDown, AtLink } from "../../../../components/ui/Icon";

const CompareTimebar: React.FC<{}> = () => {
  return (
    <>
      <Timebar>
        <Pane>
          <img
            src={"/images/timebar-a.svg"}
            alt={"timebar a"}
            width={"645px"}
          />
        </Pane>
        {/*<Button color="secondary" sx={{ width: "48px" }}>*/}
        {/*  <Icon icon={AtLink} size="24px" color={`${palette.neutral[100]}`} />*/}
        {/*</Button>*/}
        <Pane>
          <img
            src={"/images/timebar-b.svg"}
            alt={"timebar b"}
            width={"645px"}
          />
        </Pane>
      </Timebar>
    </>
  );
};

export default CompareTimebar;

const Timebar = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
`;

const Pane = styled(Stack)`
  width: 100%;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
`;
