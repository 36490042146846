import { IconButton, IconButtonProps, styled, Tooltip } from "@mui/material";
import React from "react";
import palette from "theme/palette";
import theme from "theme/theme";

interface INavIconButton extends IconButtonProps {
  label: string;
  component?: React.ReactNode;
  to?: string;
}
const NavIconButton: React.FC<INavIconButton> = (props) => {
  return (
    <Tooltip disableFocusListener title={props.label}>
      <StyledIconButton aria-label={props.label} {...props}>
        {props.children}
      </StyledIconButton>
    </Tooltip>
  );
};

export default NavIconButton;

const StyledIconButton = styled(IconButton)`
  color: ${palette.neutral[500]};
  transition: ${theme.transitions.create(["background-color", "color"])};

  &:hover,
  &:focus {
    color: ${palette.neutral[100]};
    background-color: ${palette.neutral[700]};
  }
`;
