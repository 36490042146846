import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";

export const whenOccupied = <T extends OccupancyEstimate>(v: T) =>
  v.occupancyEstimate > 0;

export const utilizationRate =
  (targetOccupancy: number) => (o: OccupancyEstimate) =>
    o.occupancyEstimate / targetOccupancy;

export const utilizationRate2 =
  (targetOccupancy: number) => (utilization: number) =>
    utilization / targetOccupancy;
export const utilizationRate3 =
  (utilization: number) => (targetOccupancy: number) =>
    utilization / targetOccupancy;

export const actualUtil = (peakOccupancy: number) => (capacity: number) =>
  peakOccupancy / capacity;

// coverage is how much each present person is getting out of the total area
//
export const coverage = (area: number) => (o: OccupancyEstimate) =>
  Math.min(area / o.occupancyEstimate, area);

export const density = (area: number) => (o: OccupancyEstimate) =>
  (o.occupancyEstimate / area) * 100;

// const mergeAsset = (real: Asset) => (demo: Asset) =>
//   pipe(real, ROR.union(ROR.getUnionSemigroup<Asset>()));
