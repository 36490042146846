import { hideLayer } from "lib/fp-mapbox/layers";
import { addLayerToMap } from "components/spatial/base/layers/assetPolygon";
import { Asset } from "lib/at-data/assets/Asset";
import { pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { getOrAddGeoJSONSource } from "lib/fp-mapbox/sources/geojson";
import {
  apFirstIO,
  apSecondIO,
} from "views/authenticated/activity/page/components/ReaderIO";
import { toPolygonsGeoJSON } from "views/authenticated/activity/page/components/ToPolygonsGeoJSON";
import { featureCollection } from "@turf/turf";
import palette from "theme/palette";

export const updateLoadingLayer = (asset: Asset) =>
  pipe(
    R.of(toPolygonsGeoJSON([asset])),
    R.chain(getOrAddGeoJSONSource("loading_layer_source"))
  );
export const setupLoadingIndicatorLayer = pipe(
  R.of(featureCollection([])),
  R.chain(getOrAddGeoJSONSource("loading_layer_source")),
  apFirstIO(
    addLayerToMap("loading_layer", {
      id: "loading_layer",
      type: "symbol",
      source: "loading_layer_source",
      layout: {
        "icon-image": "loading-spinner",
      },
    })
  )
);

export const hideLoading = hideLayer("loading_layer");
