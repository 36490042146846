import * as RTE from "fp-ts/ReaderTaskEither";
import * as ROR from "fp-ts/ReadonlyRecord";
import * as TE from "fp-ts/TaskEither";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { AppContext } from "contexts/AppContext";
import {
  OtterAsset,
  OtterAssetClass,
  OtterAssetClasses,
  OtterAssetModel,
} from "lib/at-api/assets/otter";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import {
  convertOtterGeoReference,
  GeoReference,
} from "lib/at-data/assets/models/GeoReferenceModel";
import { fromLevelProperties } from "lib/at-data/assets/models/FloorMetaModel";
import * as D from "io-ts/Decoder";
import { Assets } from "lib/at-data/assets/assets";
import * as Sep from "fp-ts/Separated";
import { isZone } from "lib/at-data/assets/predicates";
import { not } from "fp-ts/Predicate";
import { prismUSDPerOneSqM, USDPerOneSqM } from "lib/at-data/units/currency";
import { getGeoReference, getParentId } from "lib/at-data/assets/getters";

import {
  capDelay,
  exponentialBackoff,
  limitRetries,
  Monoid,
  RetryStatus,
} from "retry-ts";
import { retrying } from "retry-ts/Task";

export interface OtterContext {}

export const handleResponse = (errorType: string) => (r: Response) => {
  if (r.ok) return r.json();
  else {
    throw new Error(`${errorType}: ${r.status} ${r.statusText} `);
  }
};

export const otterToAsset = (otter: OtterAsset): Asset => ({
  id: otter.id,
  legacyZoneId: otter.properties.legacyZoneId,
  name: pipe(
    otter.properties.name,
    O.getOrElseW(() => "")
  ),
  shortCode: pipe(
    otter.subclassProperties,
    O.chain((_) => _.shortCode)
  ),
  type: matchTypes(otter.classes),
  parent: otter.properties.locatedIn,
  targetUtilization: pipe(otter.properties.utilizationGoal),
  capacity: otter.properties.capacity,
  costPerSqM: pipe(
    otter.properties.netPricePerSqMt,
    O.chain(prismUSDPerOneSqM.getOption)
  ),
  operationHours: otter.properties.hoursOfOperation,
  floorMeta: pipe(otter.levelProperties, O.chain(fromLevelProperties)),
  // subclass: pipe(otter.subclassProperties, O.map(fromSubclassProperties)),
  center: O.none,
  poly: pipe(
    otter.properties.localGeometry,
    O.map(flow((_) => _.coordinates)),
    O.map(A.map((_) => ({ x: _[0], y: _[1] })))
  ),
  diagram: O.none,
  geometry: pipe(otter.properties.geography, O.map(flow((_) => _.coordinates))),
  geoReference: pipe(
    otter.properties.georeference,
    O.map(convertOtterGeoReference)
  ),
  tags: pipe(otter.properties.userDefinedTags),
  area: O.none,
  bearing: O.none,
  timezone: pipe(otter.properties.timeZoneId),
});

// local storage has size limits(around 5mb) and with big org like utk we exceed it
// we remove some record properties and recalculctae them dynamically
export const removeGeoreferenceFromZones = (assets: Assets): Assets =>
  pipe(
    assets,
    A.map((_) => ({
      ..._,
      geoReference: pipe(
        _,
        O.fromPredicate(not(isZone)),
        O.chain(getGeoReference)
      ),
    }))
  );

const policy = capDelay(
  20000,
  Monoid.concat(exponentialBackoff(500), limitRetries(5))
);

// @ts-ignore
export const fetchFromAssetsAPI = pipe(
  RTE.ask<AppContext>(),
  RTE.chainTaskEitherK((ctx) =>
    retrying(
      policy,
      (status) =>
        TE.tryCatch(
          () =>
            fetch(
              // `${process.env.REACT_APP_API_URL}/all-assets/assets?tenantId=${ctx.appContext.dataProvider}`,
              // `https://at-otter-spatial-sand.azurewebsites.net/assets`,
              // `http://localhost:8080/assets`,
              // `${process.env.REACT_APP_API_URL}/spatial/api/v1/assets?tenantId=${ctx.appContext.dataProvider}`,
              `${process.env.REACT_APP_API_URL}/spatial/assets?tenantId=${ctx.appContext.dataProvider}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${ctx.appContext.accessToken}`,
                },
              }
            ).then(handleResponse("Accessing Assets API")),
          E.toError
        ),
      E.isLeft
    )
  ),
  RTE.chainEitherK(
    flow(
      D.UnknownArray.decode,
      E.mapLeft((e) => {
        console.error(D.draw(e));
        return new Error("Invalid Asset Response");
      }),
      E.map(
        flow(
          A.map(OtterAssetModel.decode),
          A.separate,
          Sep.mapLeft((e) => {
            const badAssets = e.length;
            console.warn(
              `Ignoring ${badAssets} Assets that are missing required properties`
            );
            console.warn(pipe(e, A.map(D.draw)));
            return new Error(`Ignoring ${badAssets} Assets`);
          }),
          Sep.right
        )
      )
    )
  ),
  RTE.map(A.map(otterToAsset))
);

export const crossReference = {
  [OtterAssetClasses.ZONE]: AssetTypes.ZONE,
  [OtterAssetClasses.CAMPUS]: AssetTypes.CAMPUS,
  [OtterAssetClasses.REGION]: AssetTypes.CAMPUS,
  [OtterAssetClasses.BUILDING]: AssetTypes.BUILDING,
  [OtterAssetClasses.LEVEL]: AssetTypes.FLOOR,
};

export const matchTypes = (otterClasses: Array<OtterAssetClass>): AssetTypes =>
  pipe(
    otterClasses,
    A.head,
    O.chain((r) => ROR.lookup(r, crossReference)),
    O.getOrElseW(() => AssetTypes.UNKNOWN)
  );

//https://at-all-assets-dev.azurewebsites.net/api/assets/
