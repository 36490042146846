import { pipe } from "fp-ts/function";
import * as n from "fp-ts/number";
import * as Eq from "fp-ts/Eq";

export type Utilization = {
  utilization: number;
};

export const Utilization = (utilization: number): Utilization => ({
  utilization,
});

export const eqUtilization = pipe(
  n.Eq,
  Eq.contramap((u: Utilization) => u.utilization)
);

export const getUtilization: <T extends Utilization>(o: T) => number = (o) =>
  o.utilization;
