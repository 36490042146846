import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { getSemigroup, NonEmptyArray } from "fp-ts/NonEmptyArray";
import { pipe } from "fp-ts/function";
import { Asset } from "lib/at-data/assets/Asset";
import { sequenceS } from "fp-ts/Apply";
import { HoursOfOperation } from "lib/at-data/HoursOfOperation";
import {
  validateFloorGeoReference,
  validateLevel,
} from "lib/at-data/assets/validations/validateLevel";
import { AssetTypes } from "lib/at-data/assets/AssetTypes";
import { USDPerOneSqM } from "lib/at-data/units/currency";

export const validateCapacity = (
  capacity: O.Option<number>
): E.Either<NonEmptyArray<string>, number> =>
  pipe(
    capacity,
    E.fromOption(() => ["Capacity is Required"])
  );
export const validateHoursOfoperation = (
  hoursOfOperation: O.Option<HoursOfOperation>
): E.Either<NonEmptyArray<string>, HoursOfOperation> =>
  pipe(
    hoursOfOperation,
    E.fromOption(() => ["Hours Of Operation must be set"])
  );

export const validateTargetUtilization = (
  targetUtilization: O.Option<number>
): E.Either<NonEmptyArray<string>, number> =>
  pipe(
    targetUtilization,
    E.fromOption(() => ["Target Utilization is Required"])
  );

export const validatecostPerSqM = (
  costPerSqM: O.Option<USDPerOneSqM>
): E.Either<NonEmptyArray<string>, USDPerOneSqM> =>
  pipe(
    costPerSqM,
    E.fromOption(() => ["Target Utilization is Required"])
  );

export const validateZone = (zone: Asset) =>
  pipe(
    sequenceS(E.getApplicativeValidation(getSemigroup<string>()))({
      capacity: validateCapacity(zone.capacity),
      hoursOfOperation: validateHoursOfoperation(zone.operationHours),
      geoReference: validateFloorGeoReference(zone.geoReference),
    }),
    E.map(() => zone)
  );

export const alwaysValid = (
  asset: Asset
): E.Either<NonEmptyArray<string>, Asset> => E.right(asset);
