import { FeatureCollection } from "@turf/turf";
import { AnySourceData, AnySourceImpl, GeoJSONSource } from "mapbox-gl";
import { pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { getOrAddSource, getSource } from "lib/fp-mapbox/sources/index";
import * as IO from "fp-ts/IO";
import * as RT from "fp-ts/ReaderT";
import { MapboxContext } from "lib/fp-mapbox/MapboxContext";
import * as O from "fp-ts/Option";

export const updateData = (data?: FeatureCollection) => (s: AnySourceImpl) => {
  // bad library types
  // @ts-ignore
  s.setData(data);
};

export const getOrAddGeoJSONSource =
  (id: string, source?: Partial<AnySourceData>) => (data: FeatureCollection) =>
    addOrUpdateGeoJSONSourceOld(id, data, source);

export const updateGeoJSONSource = (id: string, data: FeatureCollection) =>
  pipe(
    R.ask<MapboxContext>(),
    R.map((ctx) => () => {
      const sourceI = ctx.map.getSource(id);
      if (sourceI && sourceI.type === "geojson") {
        sourceI.setData(data as any);
      }
    })
  );

export const addOrUpdateGeoJSONSourceOld = (
  id: string,
  data?: FeatureCollection,
  source?: Partial<AnySourceData>
) => {
  return pipe(
    getOrAddSource(id, {
      ...source,
      type: "geojson",
      data: data as any,
    }),
    RT.chain(IO.Monad)((s) =>
      R.of(() => {
        updateData(data)(s);
      })
    )
  );
};
