import { addSeconds, differenceInSeconds } from "date-fns/fp";
import * as D from "fp-ts/Date";
import { pipe } from "fp-ts/function";
import * as E from "fp-ts/lib/Eq";
import * as C from "io-ts/Codec";
import { MultipleUUIDSliceRequest } from "lib/at-data/requests/spatial/SliceRequest";
import { UUID } from "lib/at-data/UUID";
import { date } from "lib/codecs/dateFromISOString";
import * as L from "monocle-ts/Lens";

export const SeriesRequestModel = C.struct({
  start: C.fromDecoder(date),
  end: C.fromDecoder(date),
});

export type SeriesRequest = C.TypeOf<typeof SeriesRequestModel>;

export const SeriesRequest = (start: Date, end: Date): SeriesRequest => ({
  start,
  end,
});

export const SeriesRequestEq = E.struct<SeriesRequest>({
  start: D.Eq,
  end: D.Eq,
  // resolution: N.Eq,
});

export type MultipleUUIDSeriesRequest = SeriesRequest &
  MultipleUUIDSliceRequest;

export const UUIDSeriesSliceRequest =
  <T>(sliceR: T) =>
  (seriesR: SeriesRequest) => ({
    ...sliceR,
    ...seriesR,
  });

export const MultipleUUIDSeriesRequest =
  (ids: Set<UUID>) =>
  (seriesR: SeriesRequest): MultipleUUIDSeriesRequest => ({
    ids,
    ...seriesR,
  });

export const changeStart = <T extends SeriesRequest>(
  fn: (start: Date) => Date
) => pipe(L.id<T>(), L.prop("start"), L.modify(fn));
export const changeEnd = <T extends SeriesRequest>(
  fn: (resolution: Date) => Date
) => pipe(L.id<T>(), L.prop("end"), L.modify(fn));
export const lengthInSeconds = ({ start, end }: SeriesRequest) =>
  pipe(end, differenceInSeconds(start));

export const snapDown = (n: number) => (v: number) => Math.floor(v / n) * n;
export const snapUp = (n: number) => (v: number) => Math.ceil(v / n) * n;

export const slideToNewStart =
  <T extends SeriesRequest>(newStart: Date) =>
  ({ start, end, ...rest }: T) => ({
    ...rest,
    start: newStart,
    end: pipe(newStart, addSeconds(pipe(end, differenceInSeconds(start)))),
  });
