import React from "react";
import { FloorList, palette } from "../styles/Styles";
import Icon, {
  AtAdd,
  AtBuilding,
  AtCampus,
  AtEyeOpen,
  AtGlobe,
  AtComplete,
  AtIncomplete,
} from "components/ui/Icon";
import styled from "styled-components";
import { Button } from "../ui/Button";

export const DeploySideBarOrganizations: React.FC<{}> = (props) => {
  return (
    <DeploySideBar>
      <ul>
        <li>
          <div className={"space region"}>
            <Icon icon={AtCampus} size={"16"} />
            <span>Region 1</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 1</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 2</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 3</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
      </ul>
      <div className={"button-container buildings"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Building
        </button>
      </div>
      <ul>
        <li>
          <div className={"space region"}>
            <Icon icon={AtCampus} size={"16"} />
            <span>Region 2</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 1</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 2</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 3</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
      </ul>
      <div className={"button-container buildings"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Building
        </button>
      </div>

      <div className={"button-container regions"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Region
        </button>
      </div>
    </DeploySideBar>
  );
};

export const DeploySideBarRegions: React.FC<{}> = (props) => {
  return (
    <DeploySideBar>
      <ul>
        <li className={"selected"}>
          <div className={"space region"}>
            <Icon icon={AtCampus} size={"16"} />
            <span>Region 1</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 1</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 2</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 3</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
      </ul>
      <div className={"button-container buildings"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Building
        </button>
      </div>
      <ul>
        <li>
          <div className={"space region"}>
            <Icon icon={AtCampus} size={"16"} />
            <span>Region 2</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 1</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 2</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 3</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
      </ul>
      <div className={"button-container buildings"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Building
        </button>
      </div>

      <div className={"button-container regions"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Region
        </button>
      </div>
    </DeploySideBar>
  );
};

export const DeploySideBarBuildings: React.FC<{}> = (props) => {
  return (
    <DeploySideBar>
      <ul>
        <li>
          <div className={"space region"}>
            <Icon icon={AtCampus} size={"16"} />
            <span>Region 1</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li className={"selected"}>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 1</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 2</span>
            <Icon icon={AtComplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 3</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
      </ul>
      <div className={"button-container buildings"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Building
        </button>
      </div>
      <ul>
        <li>
          <div className={"space region"}>
            <Icon icon={AtCampus} size={"16"} />
            <span>Region 2</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 1</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 2</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
        <li>
          <div className={"space building"}>
            <Icon icon={AtBuilding} size={"16"} />
            <span>Building 3</span>
            <Icon icon={AtIncomplete} size={"16"} />
          </div>
        </li>
      </ul>
      <div className={"button-container buildings"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Building
        </button>
      </div>

      <div className={"button-container regions"}>
        <button type={"button"}>
          <Icon icon={AtAdd} size={"16"} />
          Add Region
        </button>
      </div>
    </DeploySideBar>
  );
};

export const DeploySideBarFloors: React.FC<{}> = (props) => {
  return (
    <DeploySideBar>
      <FloorList data-component={"FloorList"}>
        {/*<li data-component={"FloorItem"}>Building Outline</li>*/}
        <li>
          <span className={"avatar"}>
            <Icon icon={AtGlobe} />
          </span>
          <span className={"text"}>
            <strong>Building Outline</strong>
            {/*<small>Abbreviation: G</small>*/}
          </span>
          <button type="button">
            <Icon icon={AtEyeOpen} />
          </button>
        </li>
        <li data-state={"selected"}>
          <span className={"avatar"}>G</span>
          <span className={"text"}>
            <strong>Hodges Lib - Floor G</strong>
            <small>Abbreviation: G</small>
          </span>
          <button type="button">
            <Icon icon={AtEyeOpen} />
          </button>
        </li>
        <li>
          <span className={"avatar"}>1</span>
          <span className={"text"}>
            <strong>Hodges Lib - Floor 1</strong>
            <small>Abbreviation: 1</small>
          </span>
          <button type="button">
            <Icon icon={AtEyeOpen} />
          </button>
        </li>
        <li>
          <span className={"avatar"}>2</span>
          <span className={"text"}>
            <strong>Hodges Lib - Floor 2</strong>
            <small>Abbreviation: 2</small>
          </span>
          <button type="button">
            <Icon icon={AtEyeOpen} />
          </button>
        </li>
        <li>
          <span className={"avatar"}>3</span>
          <span className={"text"}>
            <strong>Hodges Lib - Floor 3</strong>
            <small>Abbreviation: 3</small>
          </span>
          <button type="button">
            <Icon icon={AtEyeOpen} />
          </button>
        </li>
        <li>
          <span className={"avatar"}>4</span>
          <span className={"text"}>
            <strong>Hodges Lib - Floor 4</strong>
            <small>Abbreviation: 4</small>
          </span>
          <button type="button">
            <Icon icon={AtEyeOpen} />
          </button>
        </li>
        <li>
          <span className={"avatar"}>5</span>
          <span className={"text"}>
            <strong>Hodges Lib - Floor 5</strong>
            <small>Abbreviation: 5</small>
          </span>
          <button type="button">
            <Icon icon={AtEyeOpen} />
          </button>
        </li>
        <li>
          <span className={"avatar"}>6</span>
          <span className={"text"}>
            <strong>Hodges Lib - Floor 6</strong>
            <small>Abbreviation: 6</small>
          </span>
          {/*<button type="button">*/}
          {/*  <Icon icon={AtEyeOpen} />*/}
          {/*</button>*/}
        </li>
      </FloorList>
    </DeploySideBar>
  );
};

export const DeploySideBar = styled("nav")`
  display: flex;
  flex-direction: column;
  background-color: ${palette.neutral[100]};
  min-width: 300px;
  border-radius: 4px;

  @media (max-width: 767px) {
    // transform: translateX(-370px);
    // transition: transform 0.5s ease-out;
    position: absolute;
    left: -370px;
    z-index: 5;
  }

  & ul {
    margin: 0;
    padding: 12px 0 0 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    & li {
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 100%;
      cursor: pointer;
    }
    & li:hover,
    & li.selected {
      background-color: ${palette.neutral[200]};
    }
  }

  & .space {
    padding: 8px 16px 8px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;

    & span {
      width: 100%;
      padding-left: 8px;
    }
  }
  & .space.region {
    padding-left: 24px;
  }
  & .space.building {
    padding-left: 48px;
  }
  & .space:hover {
  }

  & .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    width: 100%;
    & button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      align-items: center;
      background-color: transparent;

      &.primary {
        background-color: ${palette.primary[500]};
      }

      &:hover {
        background-color: ${palette.neutral[200]};
      }

      & svg {
        margin-right: 8px;
      }
    }
  }
  & .button-container.regions {
    padding-left: 12px;
  }
  & .button-container.buildings {
    padding-left: 36px;
  }
`;
