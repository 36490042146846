import { pipe } from "fp-ts/function";
import { DashboardUser } from "views/authenticated/app/DashboardUser";
import * as A from "fp-ts/Array";
import * as S from "fp-ts/Set";
import * as O from "fp-ts/Option";
import * as s from "fp-ts/string";

export const hasFeatureFlag = (flag: string) => (featureFlags: Set<string>) =>
  pipe(featureFlags, S.elem(s.Eq)(flag));

export const isFeatureFlagAvailable =
  (flag: string) => (user: O.Option<DashboardUser>) =>
    pipe(
      user,
      O.chain(({ featureFlags }) =>
        pipe(
          featureFlags,
          A.findFirst((_) => _ === flag)
        )
      ),
      O.isSome
    );
