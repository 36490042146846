import { Assets } from "lib/at-data/assets/assets";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { Utilization } from "lib/at-data/Utilization";
import { ACTIVITY_MAP_LAYER } from "views/authenticated/activity/controller/state";
import { UUID } from "lib/at-data/UUID";
import * as O from "fp-ts/lib/Option";
import { AssetFilter } from "controllers/UIStateCollector/models/assetFilterModel";
import { FloorPlanForMap } from "controllers/ActivityMapFloorDiagramController/FloorPlanForMap";
import { AsyncData } from "lib/at-data/AsyncData";
import * as AD from "lib/at-data/AsyncData";
import { AssetFeature } from "components/spatial/base/layers/assets";

export const withAssets =
  <T>(assets: Assets) =>
  (ctx: T) => ({
    ...ctx,
    assets,
  });

export const withAssetFilter =
  <T>(assetFilter: AssetFilter) =>
  (ctx: T) => ({
    ...ctx,
    assetFilter,
  });

export interface MapLayersContext {
  layers: Set<ACTIVITY_MAP_LAYER>;
}

export const withLayersConfig =
  <T>(layers: Set<ACTIVITY_MAP_LAYER>) =>
  (ctx: T): T & MapLayersContext => ({
    ...ctx,
    layers,
  });

export const withOccupancyHeatmapGeoJSON =
  <T>(
    slice: AD.AsyncData<Array<AssetFeature<{ occupancyEstimate: number }>>>,
    maxOccupancy: AD.AsyncData<number>
  ) =>
  (ctx: T) => ({
    ...ctx,
    slice,
    maxOccupancy,
  });

export const withUtilizationSlice =
  <T>(utilizationSlice: UUIDSlice<Utilization>) =>
  (ctx: T) => ({
    ...ctx,
    utilizationSlice,
  });

export const withMapEventHandlers =
  <T>(onAssetClick: (assetId: O.Option<UUID>) => void) =>
  (ctx: T) => ({
    ...ctx,
    onAssetClick,
  });

export interface MapEventContext {
  onAssetClick: (assetId: O.Option<UUID>) => void;
}

export interface FloorDiagramContext {
  floorPlan: AsyncData<FloorPlanForMap>;
}

export const withFloorDiagramContext =
  <T>(floorPlan: AsyncData<FloorPlanForMap>) =>
  (ctx: T): T & FloorDiagramContext => ({
    ...ctx,
    floorPlan,
  });
