import React from "react";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material";
import theme from "theme/theme";
import palette from "theme/palette";

export const PinHead: React.FC<{}> = () => <Pin />;

export default PinHead;

const pulsing = keyframes`
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const Pin = styled("div")`
  height: 8px;
  width: 8px;
  background-color: ${palette.status.success.default};
  border-radius: 100%;
  position: relative;
  display: inline-block;
  margin-left: 4px;
  margin-right: ${theme.spacing(0.5)};

  &::before,
  &::after {
    content: " ";
    position: absolute;
    background-color: inherit;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    opacity: 0.8;
    animation: ${pulsing} 2s ease-out infinite;
  }

  &::before {
    animation-delay: -1s;
  }
`;
