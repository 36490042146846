import React, { MouseEventHandler } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Paper,
  Divider,
  Stack,
} from "@mui/material";
import Icon, { AtFloor } from "components/ui/Icon";
import { PinHead } from "./PinHead";
import palette from "theme/palette";

// const FloorItem: React.FC<{
//   onClick: MouseEventHandler;
// }> = ({ onClick }) => (
//   <Floor>
//     <FloorButton onClick={onClick}>Floor</FloorButton>
//   </Floor>
// );

const AssetContextFloorPickerMenu: React.FC<{
  // onClick: MouseEventHandler;
}> = () => (
  <nav aria-label="floor menu">
    <FloorList>
      <FloorItem disablePadding>
        <FloorButton>
          <Icon
            size="16px"
            color={"white"}
            icon={AtFloor}
            sx={{ marginTop: "4px", marginRight: "12px" }}
          />
          <Stack sx={{ width: "230px" }}>
            <FloorLevel primary="Floor 2" />
            <FloorOccupancy>Live Occupancy: NN</FloorOccupancy>
          </Stack>
        </FloorButton>
      </FloorItem>
      <FloorItem disablePadding sx={{ borderWidth: "1px 0" }}>
        <FloorButton>
          <Icon
            size="16px"
            color={"white"}
            icon={AtFloor}
            sx={{ marginTop: "4px", marginRight: "12px" }}
          />
          <Stack sx={{ width: "230px" }}>
            <FloorLevel primary="Floor 1" />
            <FloorOccupancy>Live Occupancy: NN</FloorOccupancy>
          </Stack>
          {/*<Icon size="16px" icon={PinHead} sx={{ paddingTop: "12px" }} />*/}
        </FloorButton>
      </FloorItem>
      <FloorItem disablePadding sx={{ borderWidth: "1px 0" }}>
        <FloorButton>
          <Icon
            size="16px"
            color={"white"}
            icon={AtFloor}
            sx={{ marginTop: "4px", marginRight: "12px" }}
          />
          <Stack sx={{ width: "230px" }}>
            <FloorLevel primary="Basement" />
            <FloorOccupancy>Live Occupancy: NN</FloorOccupancy>
          </Stack>
        </FloorButton>
      </FloorItem>
    </FloorList>
  </nav>
);

export default AssetContextFloorPickerMenu;
export const FloorList = styled(List)`
  background-color: transparent;
`;
export const FloorItem = styled(ListItem)`
  background-color: transparent;
  width: 100%;
`;
export const FloorButton = styled(ListItemButton)`
  background-color: ${palette.neutral[700]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  padding: 8px 30px 12px 24px;
  width: 100%;
  &:hover {
    background-color: ${palette.neutral[600]};
    border-color: ${palette.neutral[400]};
  }
  &.MuiListItemButton-root {
    border-color: ${palette.neutral[600]};
    border-width: 1px 0 !important;
  }
`;
export const FloorLevel = styled(ListItemText)`
  color: ${palette.neutral[100]};
  font-size: 14px;
  line-height: 100%;
  margin: 0;
`;
export const FloorOccupancy = styled("span")`
  color: ${palette.neutral[400]};
  font-size: 11px;
  line-height: 100%;
  margin: 0;
`;
export const FloorIcon = styled(ListItemIcon)`
  color: white;
  width: 32px;
`;
export const Indicator = styled(ListItemIcon)`
  width: 32px;
`;
