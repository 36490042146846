import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import * as ROR from "fp-ts/ReadonlyRecord";
import { UtilizationCell } from "views/authenticated/assets/page/components/AssetsTable/cells";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as AD from "lib/at-data/AsyncData";
import { asyncData } from "lib/at-data/AsyncData";
import { option } from "io-ts-types";
import * as t from "io-ts";
import {
  formatPercent,
  mean,
} from "views/authenticated/assets/page/components/AssetsTable/columns/formula/UtilizationColumn";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import React from "react";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";

export const getAssetMeanUtilization = pipe(
  R.ask<AssetRowDataContext>(),
  R.map(({ asset, meanUtilizationSlice }) =>
    pipe(
      meanUtilizationSlice,
      AD.map(getSliceByUUID(asset.id)),
      AD.map(O.flatten)
    )
  )
);
export const AssetMeanUtilizationColumn = defineColumn(
  "assetMeanUtilization",
  pipe(
    getAssetMeanUtilization,
    R.map((_) => ROR.singleton("assetMeanUtilization", _))
  ),
  R.of(UtilizationCell),
  pipe(
    readColumnData,
    R.map(
      flow(
        A.filterMap(
          flow(
            ROR.lookup("assetMeanUtilization"),
            O.chain(
              flow(
                asyncData(option(t.number)).decode,
                O.fromEither,
                O.chain(flow(AD.getData, O.flatten))
              )
            )
          )
        ),
        mean,
        O.chain(O.fromPredicate(Number.isFinite)),
        O.map(flow(toPercentage, Math.round, formatPercent))
      )
    ),
    R.map((value) => (
      <TableHeaderKpi
        label="Utilization"
        hint="Average number of occupants divided by capacity for the time period selected, filtered to only include specified operating hours."
        value={value}
        icon={true}
      />
    ))
  ),
  R.of({
    defaultFlex: 0.5,
    sort: AD.getOrd(O.getOrd(N.Ord)).compare,
  })
);
