import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import palette from "theme/palette";

const ReportsHeader: React.FC = () => (
  <Box p={3} pt={6}>
    <Stack
      direction={{ xs: "column", lg: "row" }}
      spacing={2}
      justifyContent="space-between"
      alignItems={{ xs: "initial", lg: "flex-start" }}
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="h3" color={palette.neutral[100]}>
          Reports
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export default ReportsHeader;
