import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { ActivityPageUIState } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import {
  getAssetBBox,
  getAssetsBBox,
} from "views/authenticated/assets/page/components/AssetPreviewMap/actions/asset";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { UIStateL } from "controllers/UIStateCollector/lens/UIStateL";
import { PathL } from "controllers/UIStateCollector/models/UIStateModel";
import { ActivityPageL } from "controllers/UIStateCollector/lens/ActivityPageL";
import { sequenceT } from "fp-ts/Apply";
import { area, bboxPolygon, polygon } from "@turf/turf";
import * as R from "fp-ts/Reader";
import { AssetsContext } from "contexts/AssetsContext";
import { Assets } from "lib/at-data/assets/assets";
import {
  getAllChildren,
  getParentAssetOf,
  getPeers,
} from "lib/at-data/assets/filters";
import { isBuilding, isFloor } from "lib/at-data/assets/predicates";
import { BBox } from "lib/at-data/BBox";
import * as A from "fp-ts/Array";
import { getId } from "lib/at-data/assets/getters";

export const SelectedSideItemL = pipe(
  L.id<ActivityPageUIState>(),
  L.prop("selectedSideItem")
);

export const SelectedRangeL = pipe(
  L.id<ActivityPageUIState>(),
  L.prop("selectedRange")
);

export const SelectedSliceL = pipe(
  L.id<ActivityPageUIState>(),
  L.prop("selectedSlice")
);

export const ActivityPageSelectedAssetIdL = pipe(
  L.id<ActivityPageUIState>(),
  L.prop("selectedAssetIds")
);

export const SidePanelOpenL = pipe(
  L.id<ActivityPageUIState>(),
  L.props("selectedAssetIds", "selectedSideItem")
);

export const AssetsFilterL = pipe(
  L.id<ActivityPageUIState>(),
  L.prop("assetsFilter")
);

export const SidebarOpenL = pipe(
  L.id<ActivityPageUIState>(),
  L.prop("sidebarOpen")
);

export const TimebarOpenL = pipe(
  L.id<ActivityPageUIState>(),
  L.prop("timebarOpen")
);

export const ActivityPageUIStateL = pipe(
  UIStateL,
  L.composeLens(ActivityPageL)
);

export const BboxL = pipe(L.id<ActivityPageUIState>(), L.prop("bbox"));
export const BearingL = pipe(L.id<ActivityPageUIState>(), L.prop("bearing"));

export const getAssetMapBBox = (
  asset: O.Option<Asset>,
  desiredContainer: O.Option<Asset>
) => pipe(R.ask<AssetsContext>());

export const bboxArea = (bbox: BBox) => pipe(bbox, bboxPolygon, area);

export const bboxAreaRatio = (bboxA: BBox) => (bboxB: BBox) =>
  bboxArea(bboxA) / bboxArea(bboxB);

export const getActivitySetSelectedAssetLens = (
  assetO: O.Option<Asset>,
  bboxAssetO: O.Option<Asset>,
  allAssets: Assets
) =>
  flow(
    pipe(ActivityPageUIStateL, L.composeLens(ActivityPageSelectedAssetIdL)).set(
      pipe(assetO, O.map(getId), A.fromOption)
    ),
    pipe(ActivityPageUIStateL, L.composeLens(BearingL)).set(
      pipe(
        assetO,
        O.chain((_) => _.bearing)
      )
    ),
    pipe(
      ActivityPageUIStateL,
      L.composeLens(BboxL),
      L.modify((_) =>
        pipe(
          sequenceT(O.Monad)(
            pipe(assetO, O.chain(getAssetBBox)),
            pipe(bboxAssetO, O.chain(getAssetBBox)),
            pipe(
              assetO,
              O.chain(flow((a) => getPeers(a)(allAssets), getAssetsBBox))
            )
          ),
          O.chain(([assetBBox, containerBBox, peersBBox]) => {
            const assetBBoxArea = pipe(assetBBox, bboxPolygon, area);
            const containerBBoxBBoxArea = pipe(
              containerBBox,
              bboxPolygon,
              area
            );
            const peerBBoxArea = pipe(peersBBox, bboxPolygon, area);

            const ratio = peerBBoxArea / containerBBoxBBoxArea;
            if (ratio < 0.5) return O.some(peersBBox);
            else return O.some(containerBBox);
            // return pipe(
            //   assetBBoxArea / containerBBoxBBoxArea <= 0.1,
            //   b.fold(
            //     () => containerBBox,
            //     () => pipe(assetBBox, scaleBBox(4))
            //   )
            // );
          }),
          O.altW(() =>
            pipe(
              sequenceT(O.Monad)(
                pipe(
                  assetO,
                  O.filter(isFloor),
                  O.map((a) => getAllChildren(a)({ assets: allAssets })),
                  O.chain(getAssetsBBox)
                ),
                pipe(bboxAssetO, O.chain(getAssetBBox))
              ),
              O.map(([childrenBBox, containerBBox]) => {
                const ratio = pipe(childrenBBox, bboxAreaRatio(containerBBox));
                if (ratio > 5) return childrenBBox;
                else return containerBBox;
              })
            )
          ),
          O.altW(() => pipe(bboxAssetO, O.chain(getAssetBBox)))
        )
      )
    )
  );

export const navigateToActivity =
  ({
    selectedAsset,
    selectedRange,
  }: {
    selectedAsset: O.Option<Asset>;
    selectedRange: SeriesRequest;
  }) =>
  (allAssets: Assets) =>
    flow(
      PathL.set("/activity"),
      getActivitySetSelectedAssetLens(
        selectedAsset,
        pipe(
          selectedAsset,
          O.chain((a) => getParentAssetOf(isBuilding)(a)(allAssets))
        ),
        allAssets
      ),
      pipe(ActivityPageUIStateL, L.composeLens(SelectedRangeL)).set(
        selectedRange
      )
    );

export const navigateToActivityNoRange =
  ({ selectedAsset }: { selectedAsset: O.Option<Asset> }) =>
  (allAssets: Assets) =>
    flow(
      PathL.set("/activity"),
      getActivitySetSelectedAssetLens(
        selectedAsset,
        pipe(
          selectedAsset,
          O.chain((a) => getParentAssetOf(isBuilding)(a)(allAssets))
        ),
        allAssets
      )
    );
