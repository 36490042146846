import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";

export type AssetContext = {
  asset: Asset;
};

export const AssetContext = (asset: Asset) => ({ asset });

export type AssetOContext = {
  asset: O.Option<Asset>;
};

export const AssetOContext = (asset: O.Option<Asset>) => ({ asset });
