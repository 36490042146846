import { Chip, styled } from "@mui/material";
import React from "react";

interface ChipData {
  id: number;
  name: string;
}

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const AssetTag = styled(Chip)`
  text-transform: uppercase;
`;

export const AssetTags: React.FC<{}> = (props) => {
  return (
    <AssetTagList>
      {/*<ListItem>Fav</ListItem>*/}
      {/*{props.tags.map((data, i) => {*/}
      {/*  return (*/}
      {/*<ListItem key={i}>*/}
      <ListItem>
        <AssetTag
          color="primary"
          size="small"
          // label={data}
          label="Office"
          onDelete={() => {}}
          sx={{
            backgroundColor: "#46495E",
            fontWeight: 400,
            textTransform: "capitalize",
            padding: "0 4px 0 12px",
            marginRight: "4px",
          }}
        />
        <AssetTag
          color="primary"
          size="small"
          // label={data}
          label="Multi-Use"
          onDelete={() => {}}
          sx={{
            backgroundColor: "#46495E",
            fontWeight: 400,
            textTransform: "capitalize",
            padding: "0 4px 0 12px",
            marginRight: "4px",
          }}
        />
        <AssetTag
          color="primary"
          size="small"
          // label={data}
          label="Classroom"
          onDelete={() => {}}
          sx={{
            backgroundColor: "#46495E",
            fontWeight: 400,
            textTransform: "capitalize",
            padding: "0 4px 0 12px",
            marginRight: "4px",
          }}
        />
        <AssetTag
          color="primary"
          size="small"
          // label={data}
          label="Retail"
          onDelete={() => {}}
          sx={{
            backgroundColor: "#46495E",
            fontWeight: 400,
            textTransform: "capitalize",
            padding: "0 4px 0 12px",
            marginRight: "4px",
          }}
        />
      </ListItem>
      {/*);*/}
      {/*})}*/}
    </AssetTagList>
  );
};

const AssetTagList = styled("ul")`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
`;
