import { CollectorPipe } from "lib/at-react/collector";
import React, { useContext } from "react";

export const withUpstreamLegacyController =
  <STATE extends {}, IN extends STATE, OUT, DISP>(
    controller: React.Context<[STATE, DISP]>
  ) =>
  (
    intake: CollectorPipe<IN, OUT>
  ): CollectorPipe<Omit<IN, keyof STATE>, OUT> => ({
    initialState: intake.initialState,
    component: ({ context, render }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const collectorState = useContext(controller);

      return React.createElement(intake.component, {
        context: { ...context, ...collectorState[0] } as IN,
        render: (value) => render(value as OUT),
      });
    },
  });
