import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { AppContext } from "contexts/AppContext";
import * as D from "io-ts/Decoder";
import * as C from "io-ts/Codec";
import { UUID } from "lib/at-data/UUID";
import { AbortingControllerContext } from "lib/at-react/contexts/AbortingControllerContext";
import { dateFromISOString } from "lib/codecs/dateFromISOString";
import { optionFromNullable } from "lib/codecs/optionFromNullable";
import * as A from "fp-ts/Array";
import * as n from "fp-ts/number";
import * as Ord from "fp-ts/Ord";
import { getMonth, setMonth } from "date-fns/fp";
import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import { LoggerContext } from "controllers/AppController/AppController";
import { getLegacyZoneId } from "lib/at-data/assets/getters";

export const BookingsModel = C.struct({
  context: C.struct({
    id: C.string,
  }),
  title: C.string,
  description: optionFromNullable(C.string),
  schedule: C.struct({
    type: C.string,
    start: C.fromDecoder(dateFromISOString),
    end: C.fromDecoder(dateFromISOString),
  }),
  occupancy: optionFromNullable(
    C.struct({
      expected: optionFromNullable(
        C.struct({
          total: optionFromNullable(C.number),
          physical: optionFromNullable(C.number),
          virtual: optionFromNullable(C.number),
        })
      ),
    })
  ),
});

export type Bookings = C.TypeOf<typeof BookingsModel>;

export const OrdBookingsByDate = pipe(
  n.Ord,
  Ord.contramap((b: Bookings) => b.schedule.start.valueOf())
);

export const BookingsResponseModel = C.array(BookingsModel);

export const handleResponse = (errorType: string) => (r: Response) => {
  if (r.ok) return r.json();
  else {
    throw new Error(`${errorType}: ${r.status} ${r.statusText} `);
  }
};

export const fetchBookings = (asset: Asset) =>
  pipe(
    RTE.ask<AppContext & AbortingControllerContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            `${process.env.REACT_APP_API_URL}/bookings/bookings?assetId=${
              asset.id
            }&assetExternalId=${pipe(
              asset,
              getLegacyZoneId,
              O.getOrElseW(() => "")
            )}`,
            {
              method: "GET",
              signal: ctx.abortingController.signal,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ctx.appContext.accessToken}`,
              },
            }
          ).then(handleResponse("Accessing bookingsSample API")),
        E.toError
      )
    ),
    RTE.chainEitherK(
      flow(
        BookingsResponseModel.decode,
        E.mapLeft((e) => {
          console.log(D.draw(e));
          return new Error("error parsing bookingsSample response");
        })
      )
    )
  );

export const groupBookingsByDate = (
  bookings: Array<Bookings>,
  groupByFn: (a: Date) => string
) => {
  return pipe(
    bookings,
    A.reduce({} as any, (groups, booking) => {
      const startDate = booking.schedule.start;
      const date = groupByFn(startDate);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(booking);
      return groups;
    }),
    (groupedBookings) => {
      return Object.keys(groupedBookings).map((date) => {
        return groupedBookings[date] as Array<Bookings>;
      });
    }
  );
};
