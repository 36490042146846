import { useStable } from "fp-ts-react-stable-hooks";
import * as E from "fp-ts/Either";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as C from "io-ts/Codec";
import {
  CollectorPipe,
  CollectorOutputDispatch,
  CollectorOutputState,
} from "lib/at-react/collector";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from "react";

export type LocalStorageStateContext = {
  window: Window;
  localStorageKey: string;
};
export const localStorageState = <STATE, LOCAL_STORAGE>(
  initialState: STATE,
  model: C.Codec<unknown, LOCAL_STORAGE, STATE>,
  eq: Eq.Eq<STATE>
): CollectorPipe<
  LocalStorageStateContext,
  CollectorOutputState<STATE> & CollectorOutputDispatch<STATE>
> => ({
  initialState: {
    state: initialState,
    dispatch: () => {},
  },
  component: (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, dispatch] = useStable<STATE>(initialState, eq);
    // the dispatch is behaving like react's dispatch, so it needs reference to state to avoid stale closures
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const stateRef = useRef(state);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      stateRef.current = state;
    }, [state]);

    const localStorageDispatch: Dispatch<SetStateAction<STATE>> =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMemo(
        () => (fn: STATE | ((newState: STATE) => STATE)) => {
          const newState =
            typeof fn === "function"
              ? pipe(stateRef.current, fn as (newState: STATE) => STATE)
              : fn;

          props.context.window.localStorage.setItem(
            props.context.localStorageKey,
            pipe(newState, model.encode, JSON.stringify)
          );
          dispatch(newState);
        },
        [stateRef]
      );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const parseState = (newSateValue: string | null) =>
        pipe(
          E.tryCatch(() => JSON.parse(newSateValue || ""), E.toError),
          E.chainW(model.decode)
        );

      pipe(
        E.tryCatch(
          () =>
            props.context.window.localStorage.getItem(
              props.context.localStorageKey
            ),
          E.toError
        ),
        E.chain(parseState),
        E.fold(
          (e) => {
            dispatch(initialState);
          },
          (newState) => {
            dispatch(newState);
          }
        )
      );

      return props.context.window.addEventListener(
        "storage",
        ({ key, newValue }) => {
          if (key === props.context.localStorageKey) {
            pipe(
              E.tryCatch(
                () =>
                  props.context.window.localStorage.getItem(
                    props.context.localStorageKey
                  ),
                E.toError
              ),
              E.chain(parseState),
              E.fold(
                (e) => {
                  dispatch(initialState);
                },
                (newState) => {
                  dispatch(newState);
                }
              )
            );
          }
        }
      );
    }, []);

    return props.render({ state, dispatch: localStorageDispatch });
  },
});
