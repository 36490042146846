import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { Asset } from "lib/at-data/assets/Asset";

export const CapacityL = pipe(L.id<Asset>(), L.prop("capacity"));
export const TargetUtilizationL = pipe(
  L.id<Asset>(),
  L.prop("targetUtilization")
);
export const FloorMetaL = pipe(L.id<Asset>(), L.prop("floorMeta"));
// export const SubclassL = pipe(L.id<Asset>(), L.prop("subclass"));
export const CostPerSqML = pipe(L.id<Asset>(), L.prop("costPerSqM"));
export const AssetNameL = pipe(L.id<Asset>(), L.prop("name"));
export const AssetShortCodeL = pipe(L.id<Asset>(), L.prop("shortCode"));
export const AssetTagsL = pipe(L.id<Asset>(), L.prop("tags"));
export const AssetGeoReferenceL = pipe(L.id<Asset>(), L.prop("geoReference"));
export const HoursOfOperationL = pipe(L.id<Asset>(), L.prop("operationHours"));
export const GeographyL = pipe(L.id<Asset>(), L.prop("geometry"));
export const PolyL = pipe(L.id<Asset>(), L.prop("poly"));
export const TimezoneL = pipe(L.id<Asset>(), L.prop("timezone"));
