import React from "react";
import ComparePageUTK from "./ComparePageUTK";
import ComparePageBS from "./ComparePageBS";
import { useController } from "lib/at-react/defineController";
import * as O from "fp-ts/Option";
import { dataProviderL, UserL } from "views/authenticated/app/controller/state";
import { AppControllerContext } from "views/authenticated/app/controller/AppControllerContext";
import { flow } from "fp-ts/lib/function";

const ComparePage: React.FC<{}> = () => {
  const [isUTK] = useController(
    AppControllerContext,
    flow(
      UserL.get,
      O.map(dataProviderL.get),
      O.filter((_) => _ === "88bae34f-f09a-4e2c-b89c-d2f7fe6bb576"),
      O.isSome
    )
  );

  return <>{isUTK ? <ComparePageUTK /> : <ComparePageBS />}</>;
};

export default ComparePage;
