import { AppController } from "controllers/AppController/AppController";
import { useController } from "lib/at-react/defineController";
import React from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { flow, pipe } from "fp-ts/function";
import {
  TenantL,
  toMinimalAppState,
} from "views/authenticated/app/ReadyAppState";
import { AssetNamePropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/AssetNamePropertyRow";
import { TableCell, TableRow } from "@mui/material";
import * as O from "fp-ts/Option";
import {
  ClipboardCopy,
  OptionalProperty,
  Property,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import { HierarchyPropertyRow } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/properties/HierarchyPropertyRow";

export const GeneralAssetProperties: React.FC<{ asset: Asset }> = (props) => {
  const [tenantIdO] = useController(
    AppController,
    flow(toMinimalAppState, O.map(TenantL.get))
  );
  return (
    <>
      <AssetNamePropertyRow asset={props.asset} />

      <TableRow
        key={"assetId"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          id
        </TableCell>
        <TableCell align="right">
          <Property value={props.asset.id} />
          <ClipboardCopy value={props.asset.id} />
        </TableCell>
      </TableRow>
      {pipe(
        tenantIdO,
        O.foldW(
          () => null,
          (tenantId) => (
            <TableRow
              key={"ownedBy"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                Tenant Id
              </TableCell>
              <TableCell align="right">
                <Property value={tenantId} />
                <ClipboardCopy value={tenantId} />
              </TableCell>
            </TableRow>
          )
        )
      )}

      <TableRow
        key={"legacyZoneId"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          legacyZoneId
        </TableCell>
        <TableCell align="right">
          <OptionalProperty
            value={props.asset.legacyZoneId}
            component={Property}
          />
          <ClipboardCopy
            value={pipe(
              props.asset.legacyZoneId,
              O.getOrElseW(() => "")
            )}
          />
        </TableCell>
      </TableRow>
      <TableRow
        key={"parentId"}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          parentId
        </TableCell>
        <TableCell align="right">
          <OptionalProperty value={props.asset.parent} component={Property} />
        </TableCell>
      </TableRow>
      <HierarchyPropertyRow asset={props.asset} />
    </>
  );
};
