import {
  Stack,
  styled,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Divider,
} from "@mui/material";
import Icon, { AtAssets, AtHelp, AtInsights } from "components/ui/Icon";
import palette from "theme/palette";
import React from "react";
import { Insights } from "lib/at-api/assets/insights";
import { pipe, flow } from "fp-ts/function";
import { AssetsInsightsController } from "controllers/AssetsInsightsController/AssetsInsightsController";
import * as AD from "lib/at-data/AsyncData";
import { InsightsL } from "controllers/AssetsInsightsController/state";
import { useController } from "lib/at-react/defineController";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/Array";
import { NoDataFoundInsights, NoDataPlaceholderImage } from "./NoDataFound";

const InsightItem: React.FC<{}> = ({ children }) => {
  return (
    <ListItem sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <ListItemIcon sx={{ minWidth: "32px" }}>
        <Icon icon={AtInsights} size="16px" color={`${palette.neutral[450]}`} />
      </ListItemIcon>
      <Item>{children}</Item>
    </ListItem>
  );
};

const InsightPatterns: React.FC<{
  insightsData: AD.AsyncData<Array<Insights>>;
}> = ({ insightsData }) => {
  return (
    <List sx={{ width: "100%", backgroundColor: "transparent" }}>
      {pipe(
        insightsData,
        AD.fold(
          () => <NoDataPlaceholderImage />,
          O.foldW(
            () => <NoDataFoundInsights />,
            A.mapWithIndex((i, ins) => (
              <InsightItem key={i}>{ins.message}</InsightItem>
            ))
          )
        )
      )}
    </List>
  );
};

export const AssetInsights: React.FC<{}> = () => {
  const [insightsData] = useController(AssetsInsightsController, InsightsL.get);

  return (
    <StyledPanelContent>
      <Stack spacing={1} px={3} py={2}>
        <Typography
          component={"span"}
          variant="h4"
          color={palette.neutral[100]}
        >
          Insights
        </Typography>
      </Stack>
      <Divider
        sx={{
          backgroundColor: `${palette.neutral[500]}`,
        }}
      />
      <Stack spacing={1} px={3} py={2}>
        <InsightPatterns insightsData={insightsData} />
      </Stack>
      {/*<Divider*/}
      {/*  sx={{*/}
      {/*    backgroundColor: `${palette.neutral[500]}`,*/}
      {/*  }}*/}
      {/*/>*/}
    </StyledPanelContent>
  );
};

export default AssetInsights;

const StyledPanelContent = styled(Stack)`
  width: 100%;
  overflow-y: auto;
  scrollbar-color: #685bc7 #323548;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #685bc7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #323548;
  }
`;
const Item = styled(Typography)`
  font-size: 12px;
  color: ${palette.neutral[450]};
`;

const HtmlLink = styled("a")`
color: ${palette.neutral[400]}
&:hover {
color: ${palette.neutral[100]}
}
`;
