import { Lens } from "monocle-ts";
import * as S from "lib/at-data/Slice";
import { UUID } from "lib/at-data/UUID";
import * as D from "lib/at-data/DataSeries";
import * as Ds from "lib/at-data/DataSlice";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import * as O from "fp-ts/Option";
import { Assets } from "lib/at-data/assets/assets";
import { Asset } from "lib/at-data/assets/Asset";
import { AssetType } from "lib/at-data/assets/AssetTypes";
import * as s from "fp-ts/string";
import * as E from "fp-ts/Eq";
import { AssetFilter } from "controllers/UIStateCollector/models/assetFilterModel";
import { UUIDSlice } from "lib/at-data/UUIDSlice";
import { Utilization } from "lib/at-data/Utilization";

export enum ACTIVITY_MAP_LAYER {
  OCCUPANCY_HEATMAP = "occupancyHeatmap",
  ROOM_BY_ROOM_UTILIZATION = "roomByRoomUtilization",
  LABELS = "labels",
  LABELS_DETAILED = "labelsDetailed",
  ZONE_OUTLINES = "zoneOutlines",
  ZONE_FILL = "zoneFill",
  ZONE_LABELS = "zoneLabels",
}

export const mapLayersEq: E.Eq<ACTIVITY_MAP_LAYER> = s.Eq;

export type ActivityState = {
  assetsSlice: Ds.DataSlice<OccupancyEstimate>;
  fineNavigatorSeries: D.DataSeries<OccupancyEstimate>;
};

export const AssetsSliceL = Lens.fromProp<ActivityState>()("assetsSlice");
