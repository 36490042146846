import { pipe } from "fp-ts/function";
import { compose, inverse, Matrix } from "transformation-matrix";
import {
  calculateAffineTransform,
  GeoReference,
} from "lib/at-data/assets/models/GeoReferenceModel";
import { BBox, degrees2radians } from "@turf/turf";

export const difference = (source: Matrix) => (reference: Matrix) =>
  compose(inverse(reference), source);

export const toMatrix = (geoReference: GeoReference) =>
  pipe(geoReference, calculateAffineTransform);

const getBiggestSide = (bb: BBox) => {
  const [topX, topY, bottomX, bottomY] = bb;
  const sidea = bottomX - topX;
  const sideb = bottomY - topY;
  return Math.max(sidea, sideb);
};

const getBBboxCenter = ([topX, topY, bottomX, bottomY]: BBox): [
  number,
  number
] => [topX + (bottomX - topX) / 2, topY + (bottomY - topY) / 2];

const pointAtAngleAndDistance =
  (angleInRad: number, distance: number) =>
  ([centerX, centerY]: [number, number]) => ({
    x: centerX + distance * Math.cos(angleInRad),
    y: centerY + distance * Math.sin(angleInRad),
  });

export const getThreePlanePoints = (bb: BBox) => {
  const center = pipe(bb, getBBboxCenter);
  const size = pipe(bb, getBiggestSide);

  return [
    pipe(center, pointAtAngleAndDistance(degrees2radians(0), size)),
    pipe(center, pointAtAngleAndDistance(degrees2radians(120), size)),
    pipe(center, pointAtAngleAndDistance(degrees2radians(240), size)),
  ];
};
