import { styled, Typography } from "@mui/material";
import {
  differenceInSeconds,
  formatDuration,
  intervalToDuration,
} from "date-fns/fp";
import { pipe } from "fp-ts/function";
import { GranulatedSeriesRequest } from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import React from "react";
import palette from "theme/palette";

export const Resolution = styled(Typography)`
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${palette.neutral[400]};
`;
export const formatResolution = (resolution: number) =>
  pipe(
    { start: 0, end: resolution * 1000 },
    intervalToDuration,
    formatDuration
  );
export const TimelineResolution: React.FC<{
  selectedRange: GranulatedSeriesRequest;
}> = (props) => (
  <Resolution>
    Interval: {pipe(props.selectedRange.resolution, formatResolution)}
  </Resolution>
);
