import React, { useState } from "react";
// import { Form } from "../styles/Styles";
import { palette } from "./styles/Styles";
import styled from "styled-components";
import {
  BasicDaySchedule,
  closeHourL,
  getAMPMHour,
  getHourOnly,
  getMinuteOnly,
  isValidTimeRange,
  NineToFiveGrind,
  openHourL,
  setHour,
  setMinute,
  setPM,
  setToClosed,
} from "lib/at-data/HoursOfOperation";
import * as O from "fp-ts/Option";
import { flow, pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as n from "fp-ts/number";
import * as C from "io-ts/Codec";
import * as E from "fp-ts/Either";
import { clog, noop } from "lib/util";
import * as L from "monocle-ts/Lens";
import { numberFromString } from "lib/codecs/numberFromString";
import * as b from "fp-ts/boolean";

const addOne = (v: number) => v + 1;
const toQuarterHour = (i: number) => i * 15;

const setToPM = (isAM: boolean) => (hour: number) =>
  pipe(
    isAM,
    b.fold(
      () => hour + 12,
      () => hour
    )
  );

export const HourPicker: React.FC<{
  selectedHour: O.Option<number>;
  isElDisabled: boolean;
  onChange: (hour: number) => void;
  isAM: boolean;
}> = ({ isElDisabled, selectedHour, onChange, isAM }) => (
  <select
    name={"hours"}
    disabled={isElDisabled}
    onChange={(ev) =>
      pipe(
        ev.target.value,
        numberFromString.decode,
        E.map(setToPM(isAM)),
        E.fold(() => noop(), onChange)
      )
    }
  >
    {pipe(
      A.makeBy(12, addOne),
      A.map((hour) => (
        <option
          value={hour}
          key={hour}
          selected={O.getEq(n.Eq).equals(selectedHour, O.some(hour))}
        >
          {hour}
        </option>
      ))
    )}
  </select>
);

export const MinutePicker: React.FC<{
  selectedMinute: O.Option<number>;
  isElDisabled: boolean;
  onChange: (minute: number) => void;
}> = ({ isElDisabled, selectedMinute, onChange }) => (
  <select
    name={"hours"}
    disabled={isElDisabled}
    onChange={(ev) =>
      pipe(ev.target.value, numberFromString.decode, E.fold(noop, onChange))
    }
  >
    {pipe(
      A.makeBy(4, toQuarterHour),
      A.map((hour) => (
        <option
          value={hour}
          key={hour}
          selected={O.getEq(n.Eq).equals(selectedMinute, O.some(hour))}
        >
          {hour}
        </option>
      ))
    )}
  </select>
);

export const isAM = (hour: number) => hour < 12;

export const DayHoursEditor: React.FC<{
  day: string;
  daySchedule: O.Option<BasicDaySchedule>;
  onChange: (ds: O.Option<BasicDaySchedule>) => void;
  // isError: boolean;
}> = ({ day, daySchedule, onChange }) => {
  const [lastDaySchedule, setDaySchedule] = useState(daySchedule);
  const isElDisabled = pipe(daySchedule, O.isNone);
  const selectedOpenHour = pipe(
    daySchedule,
    O.map(flow(openHourL.get, getHourOnly, getAMPMHour))
  );
  const isOpenHourAM = pipe(
    daySchedule,
    O.map(flow(openHourL.get, getHourOnly)),
    O.filter(isAM)
  );

  const selectedCloseHour = pipe(
    daySchedule,
    O.map(flow(closeHourL.get, getHourOnly, getAMPMHour))
  );
  const isCloseHourAM = pipe(
    daySchedule,
    O.map(flow(closeHourL.get, getHourOnly)),
    O.filter(isAM)
  );

  const selectedOpenMinutes = pipe(
    daySchedule,
    O.map(openHourL.get),
    O.chain(getMinuteOnly)
  );
  const selectedCloseMinutes = pipe(
    daySchedule,
    O.map(closeHourL.get),
    O.chain(getMinuteOnly)
  );

  return (
    <HooItem className={isElDisabled ? "container disabled" : "container"}>
      <legend>{day}</legend>
      <div className={"hoo"}>
        <div className={"time"}>
          {/*<span>Opens</span>*/}
          <div>
            <HourPicker
              selectedHour={selectedOpenHour}
              onChange={(hour) =>
                pipe(
                  daySchedule,
                  O.map(pipe(openHourL, L.modify(setHour(hour)))),
                  O.map(clog("mon")),
                  onChange
                )
              }
              isElDisabled={isElDisabled}
              isAM={O.isSome(isOpenHourAM)}
            />
            <span> : </span>
            <MinutePicker
              selectedMinute={selectedOpenMinutes}
              isElDisabled={isElDisabled}
              onChange={(minute) =>
                pipe(
                  daySchedule,
                  O.map(pipe(openHourL, L.modify(setMinute(minute)))),
                  onChange
                )
              }
            />
          </div>
        </div>
        <div className={"switch"}>
          <label>
            <input
              type={"radio"}
              name={`${day}am`}
              checked={O.isSome(isOpenHourAM)}
              disabled={isElDisabled}
              onChange={() => {
                pipe(
                  daySchedule,
                  O.map(pipe(openHourL, L.modify(setPM(false)))),
                  onChange
                );
              }}
            />
            <span>AM</span>
          </label>
          <label className={"switch"}>
            <input
              type={"radio"}
              name={`${day}am`}
              disabled={isElDisabled}
              checked={O.isNone(isOpenHourAM)}
              onChange={() => {
                pipe(
                  daySchedule,
                  O.map(pipe(openHourL, L.modify(setPM(true)))),
                  onChange
                );
              }}
            />
            <span>PM</span>
          </label>
        </div>
      </div>
      <div className={"hoo"}>
        <div className={"time"}>
          {/*<span>Closes</span>*/}
          <div>
            <HourPicker
              selectedHour={selectedCloseHour}
              isElDisabled={isElDisabled}
              onChange={(hour) =>
                pipe(
                  daySchedule,
                  O.map(pipe(closeHourL, L.modify(setHour(hour)))),
                  onChange
                )
              }
              isAM={O.isSome(isCloseHourAM)}
            />
            <span> : </span>
            <MinutePicker
              selectedMinute={selectedCloseMinutes}
              isElDisabled={isElDisabled}
              onChange={(minute) =>
                pipe(
                  daySchedule,
                  O.map(pipe(closeHourL, L.modify(setMinute(minute)))),
                  onChange
                )
              }
            />
          </div>
        </div>
        <div className={"switch"}>
          <label>
            <input
              type={"radio"}
              name={`${day}pm`}
              disabled={isElDisabled}
              checked={O.isSome(isCloseHourAM)}
              onChange={() => {
                pipe(
                  daySchedule,
                  O.map(pipe(closeHourL, L.modify(setPM(false)))),
                  onChange
                );
              }}
            />
            <span>AM</span>
          </label>
          <label className={"switch"}>
            <input
              type={"radio"}
              name={`${day}pm`}
              checked={O.isNone(isCloseHourAM)}
              disabled={isElDisabled}
              onChange={() => {
                pipe(
                  daySchedule,
                  O.map(pipe(closeHourL, L.modify(setPM(true)))),
                  onChange
                );
              }}
            />
            <span>PM</span>
          </label>
        </div>
      </div>
      <div className={"closed"}>
        <label>
          <input
            type={"checkbox"}
            onChange={() => {
              pipe(
                isElDisabled,
                b.fold(
                  () => pipe(daySchedule, setToClosed),
                  () =>
                    pipe(
                      lastDaySchedule,
                      O.alt(() => NineToFiveGrind.schedule.monday)
                    )
                ),
                onChange
              );
            }}
            checked={isElDisabled}
          />
          <span>Closed</span>
        </label>
      </div>
      {!isValidTimeRange(daySchedule) && (
        <div className={"errorMsg"}>
          Opening hours cannot be later than closing hours.
        </div>
      )}
    </HooItem>
  );
};

export default DayHoursEditor;

export const HooItem = styled("div")`
  &.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0 24px;

    legend {
      width: 10%;
      font-size: 12px;
      border-top: none;
      padding: 0;
      margin: 0;
      transition: opacity 0.2s ease-out;
    }
    .closed {
      width: 10%;
      padding: 0;
      margin: 0;

      & label {
        cursor: pointer;
      }
    }

    & .hoo {
      width: 40%;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      margin: 0;
      padding: 0 16px;
      transition: opacity 0.2s ease-out;

      & .time {
        margin-bottom: 0;
        padding: 0;
        width: 100%;
        div {
          display: flex;
          flex-direction: row;
          padding: 0;
          margin: 0;
          width: 100%;
        }
        & > span {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px;
          color: #6a6e80;
        }
        select {
          width: 100%;
          margin: 0 4px;
        }
        select + span {
          padding: 8px 2px;
        }
      }
    }

    // Switch

    .switch {
      display: flex;
      flex-direction: row;
      width: auto !important;
      padding: 0;
      border: 0.5px solid ${palette.neutral[400]};
      border-radius: 4px;
      margin-bottom: 0;
      margin-left: 4px;

      & > label {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        border: 0;

        &:first-of-type > span {
          border-radius: 4px 0 0 4px;
        }

        &:last-of-type > span {
          border-radius: 0 4px 4px 0;
        }

        & > span {
          padding: 4px 8px;
          background-color: ${palette.neutral[100]};
          color: ${palette.neutral[600]};
          transition: background-color 0.2s ease-out;
          font-size: 12px;

          &:hover {
            background-color: ${palette.neutral[200]};
          }
        }
        & > input {
          position: absolute;
          z-index: -1;
        }
        & > input:checked + span {
          background-color: ${palette.primary[400]};
          color: white;
          transition: background-color 0.2s ease-out;
        }
      }
    }

    @media (max-width: 1023px) {
      flex-direction: column;

      legend {
        width: 100%;

        padding: 0 0 8px 8px;
      }

      .closed {
        width: 100%;
        padding: 12px 0 24px 8px;
      }

      & div.hoo {
        width: 100%;
        padding: 0;
      }
    }
  }
  &.container.disabled {
    & .hoo,
    & legend {
      opacity: 0.5;
      transition: opacity 0.2s ease-out;
    }

    & .switch label > input:checked + span {
      background-color: ${palette.neutral[300]};
    }
  }

  & .errorMsg {
    width: 100%;
    color: red;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 0;
  }
`;
