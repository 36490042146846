import { AppContext } from "contexts/AppContext";
import * as E from "fp-ts/Either";
import { flow, pipe } from "fp-ts/function";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as TE from "fp-ts/TaskEither";
import { createGrpcWebTransport } from "@bufbuild/connect-web";
import { of, UUID } from "lib/at-data/UUID";

const transport = createGrpcWebTransport({
  // baseUrl: "https://internal-digitaltwin-management-dev.armoredthings.us/",
  baseUrl: "https://at-pd-dev.azure-api.net/asset-management",
});
export const otterCreateAssetGrpcJSON = <T>(
  assetClass: string,
  properties: T
) =>
  pipe(
    RTE.ask<AppContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            `${process.env.REACT_APP_API_URL}/asset-management/v1/assets/`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${ctx.appContext.accessToken}`,
              },
              body: JSON.stringify({
                classTag: assetClass,
                properties,
              }),
            }
          ),
        E.toError
      )
    )
  );
//
// export type ModifiableOtterProperties = Pick<
//   AssetProperties,
//   "name" | "capacity" | "geometryLocal" | "locatedIn"
// >;

export type ModifiableOtterProperties = {
  name?: string;
  capacity?: number;
  locatedIn?: Object;
  geometryLocal?: Object;
};

export const otterModifyAssetGrpcJSON = (
  id: UUID,
  properties: ModifiableOtterProperties
) =>
  pipe(
    RTE.ask<AppContext>(),
    RTE.chainTaskEitherK((ctx) =>
      TE.tryCatch(
        () =>
          fetch(
            `${process.env.REACT_APP_API_URL}/asset-management/v1/assets/${id}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ctx.appContext.accessToken}`,
              },
              body: JSON.stringify({
                properties,
              }),
            }
          ),
        E.toError
      )
    )
  );
