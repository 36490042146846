import React from "react";
import { Form } from "../styles/Styles";
import Icon, { AtEyeOpen, AtGlobe } from "components/ui/Icon";
import { InputField } from "../ui/InputField";

export const DeployFormUtilizationOrganizations: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Target Utilization"}
          name={"tu"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormUtilizationRegions: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Target Utilization"}
          name={"tu"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormUtilizationBuildings: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Target Utilization"}
          name={"tu"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};

export const DeployFormUtilizationFloors: React.FC<{}> = (props) => {
  return (
    <Form>
      <fieldset>
        <InputField
          label={"Target Utilization"}
          name={"tu"}
          type={"number"}
          value={"50"}
        />
      </fieldset>
    </Form>
  );
};
