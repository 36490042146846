import { styled } from "@mui/material";
import { responsiveSize } from "theme/helpers";
import React from "react";
import palette from "theme/palette";
import theme from "theme/theme";
import "@inovua/reactdatagrid-enterprise/index.css";
import { alpha } from "@mui/system";

/**
 * This component provides the core rules and styles that generate basic tables across our platform. We are leveraging the fantastic [React Data Grid](https://reactdatagrid.io/docs/) library which has some incredibly powerful options we can leverage.
 *
 * In addition to that, we've created some helper components which provide an extensible styling framework for the cells we need.
 *
 * Helper components include the following:
 *
 * - Table Header KPI
 * - Dwell Cell
 * - Occupancy Cell
 * - Chip Cell
 * - Views Cell
 */

const PageTable: React.FC<{}> = (props) => {
  return <Table>{props.children}</Table>;
};

export default PageTable;

const Table = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(4)};
  height: calc(100vh - 56px);
  min-height: 400px;
  background: ${palette.neutral[100]};

  /* Custom Table Styles go here */
  .InovuaReactDataGrid {
    height: 100%;
  }

  .InovuaReactDataGrid__column-header {
    display: flex;
  }

  .InovuaReactDataGrid__column-header__content {
    display: flex;
  }

  .InovuaReactDataGrid--theme-default-light {
    color: ${palette.neutral[800]};
    font-size: ${responsiveSize(1.6, 1.4)};
    fill: ${palette.neutral[600]};
  }

  /* Temp style for hiding header border -- Needs more work for resized cols */
  .InovuaReactDataGrid,
  .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header,
  .InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--show-border-right,
  .InovuaReactDataGrid--theme-default-light
    .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
    border: none;
  }

  .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header {
    box-shadow: 0 2px 2px 0 ${alpha(palette.neutral[800], 0.1)};
  }

  /* Handles padding first col */
  .InovuaReactDataGrid__header > div:nth-of-type(1),
  .InovuaReactDataGrid__row .InovuaReactDataGrid__cell:nth-of-type(1) {
    padding-left: ${theme.spacing(3)};
  }

  .InovuaReactDataGrid__header .InovuaReactDataGrid__sort-icon-wrapper {
    display: none;
  }

  .AtSort {
    margin-top: 4px;
  }
  .AtSort path {
    fill: white;
  }
  .InovuaReactDataGrid__column-header--sort-desc .AtSort path:first-of-type {
    fill: ${palette.neutral[300]};
  }
  .InovuaReactDataGrid__column-header--sort-desc .AtSort path:last-of-type {
    fill: ${palette.neutral[800]};
  }
  .InovuaReactDataGrid__column-header--sort-asc .AtSort path:first-of-type {
    fill: ${palette.neutral[800]};
  }
  .InovuaReactDataGrid__column-header--sort-asc .AtSort path:last-of-type {
    fill: ${palette.neutral[300]};
  }

  .inovua-react-toolkit-menu__table {
    .MuiTypography-h2,
    .MuiSvgIcon-root {
      display: none;
    }
  }
`;
