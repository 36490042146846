import * as A from "fp-ts/Array";
import * as Eq from "fp-ts/Eq";
import * as Ord from "fp-ts/Ord";
import * as s from "fp-ts/string";
import * as C from "io-ts/Codec";
import { pipe } from "fp-ts/function";
import { toLowerCase } from "fp-ts/string";

export const AssetTagModel = C.string;

export type AssetTag = C.TypeOf<typeof AssetTagModel>;

export const AssetTagEq = s.Eq;
export const AssetTagOrd = s.Ord;

export const AssetTagCaseInsensitiveOrd = pipe(
  AssetTagOrd,
  Ord.contramap((tag: AssetTag) => toLowerCase(tag))
);

export const AssetTagCaseInsensitiveEq = Eq.fromEquals(
  AssetTagCaseInsensitiveOrd.equals
);

export type AssetTags = Array<AssetTag>;

export const addAssetTag =
  (tag: AssetTag) =>
  (tags: AssetTags): AssetTags =>
    pipe(tags, A.prepend(tag));

export const deleteAssetTag =
  (tag: AssetTag) =>
  (tags: AssetTags): AssetTags =>
    pipe(
      tags,
      A.filter((_) => _ !== tag)
    );

export const AssetTagsEq = A.getEq(AssetTagEq);

// export const getByAsset = (asset: Asset) => (assetTags: AssetTags) =>
//   pipe(assetTags, A.filterMap(hasAsset(asset)));
