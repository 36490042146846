import { useCollector } from "lib/at-react/hooks";
import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon, {
  AtChevronDown,
  AtExport,
  AtExportCSV,
} from "../../../../../../components/ui/Icon";
import { useCollectorOld, useController } from "lib/at-react/defineController";
import {
  AssetsTableDataController,
  DataL,
} from "controllers/AssetsTableDataController/AssetsTableDataController";
import { downloadCsv } from "lib/csvUtil";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import * as ROR from "fp-ts/ReadonlyRecord";
import { flow } from "fp-ts/es6/function";
import * as t from "io-ts";
import * as AD from "lib/at-data/AsyncData";
import { asyncData } from "lib/at-data/AsyncData";
import { toPercentage } from "views/authenticated/assets/page/components/AssetsTable/util";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import { Asset, AssetModel } from "lib/at-data/assets/models/AssetModel";
import {
  AssetsPageUIStateL,
  SelectedRangeL,
} from "controllers/UIStateCollector/lens/assetsPage";
import {
  CommaNumber,
  formatNumber,
  TwoDecimal,
} from "lib/formatters/formatNumber";
import { SQUARE_FT_PER_SQ_METER } from "../../../../../../lib/at-data/assets/spatial";
import { getStringId } from "../../../../../../lib/at-data/UUID";
import { option as newOption } from "../../../../../../lib/codecs/option";
import { option } from "io-ts-types";
import {
  AssetsTableController,
  ByColumnName,
} from "controllers/AssetsTableController/AssetsTableController";
import { ColumnsL } from "controllers/AssetsTableController/lens";
import {
  isShownFor,
  TableColumnInfo,
  TableDataViews,
} from "controllers/AssetsTableController/state";
import * as C from "io-ts/Codec";
import { clog, noop } from "lib/util";
import mixpanel from "mixpanel-browser";

const mapToCsvData =
  (columns: TableColumnInfo[]) =>
  (assetData: ROR.ReadonlyRecord<string, unknown>) => {
    const asset = pipe(ROR.lookup("asset", assetData)) as O.Option<Asset>;
    // console.log(assetData);
    // check if legacy id (and each column name) is in columns:
    const allColumns = {
      "Legacy ID":
        pipe(
          columns,
          A.findFirst((_) => _.column.name === "assetLegacyId")
        ) === O.none
          ? noop()
          : pipe(
              asset,
              O.map((_) =>
                pipe(
                  _.legacyZoneId,
                  O.fold(() => "", getStringId)
                )
              ),
              O.getOrElseW(() => "")
            ),
      Name: pipe(
        asset,
        O.map((_) => _.name),
        O.getOrElse(() => "")
      ),
      ShortCode: pipe(
        ROR.lookup("assetShortCode", assetData),
        O.chain(flow(newOption(C.string).decode, O.fromEither)),
        O.flatten,
        O.getOrElse(() => "")
      ),
      Type: pipe(
        asset,
        O.map((_) => _.type),
        O.getOrElse(() => "")
      ),
      "Parent Building": pipe(
        ROR.lookup("assetParentBuilding", assetData) as O.Option<
          O.Option<Asset>
        >,
        O.flatten,
        O.map((a) => a.name),
        O.getOrElse(() => "")
      ),
      Utilization: pipe(
        ROR.lookup("assetMeanUtilization", assetData),
        O.chain(
          flow(
            asyncData(option(t.number)).decode,
            O.fromEither,
            O.chain(flow(AD.getData, O.flatten))
          )
        ),
        O.map(flow(toPercentage, (_) => `${Math.round(_)}%`)),
        O.getOrElse(() => "")
      ),
      "Mean Occupancy": pipe(
        ROR.lookup("assetMeanOccupancy", assetData),
        O.chain(
          flow(
            asyncData(option(t.number)).decode,
            O.fromEither,
            O.chain(flow(AD.getData, O.flatten))
          )
        ),
        O.map((_) => `${Math.round(_)}`),
        O.getOrElse(() => "")
      ),
      "Peak Utilization":
        pipe(
          columns,
          A.findFirst((_) => _.column.name === "assetPeakUtilization")
        ) === O.none
          ? noop()
          : pipe(
              ROR.lookup("assetPeakUtilization", assetData),
              O.chain(
                flow(
                  asyncData(option(t.number)).decode,
                  O.fromEither,
                  O.chain(flow(AD.getData, O.flatten))
                )
              ),
              O.map(flow(toPercentage, (_) => `${Math.round(_)}%`)),
              O.getOrElse(() => "")
            ),
      "Peak Occupancy":
        pipe(
          columns,
          A.findFirst((_) => _.column.name === "assetPeakOccupancy")
        ) === O.none
          ? noop()
          : pipe(
              ROR.lookup("assetPeakOccupancy", assetData),
              O.chain(
                flow(
                  asyncData(option(t.number)).decode,
                  O.fromEither,
                  O.chain(flow(AD.getData, O.flatten))
                )
              ),
              O.map((_) => `${Math.round(_)}`),
              O.getOrElse(() => "")
            ),

      Size: pipe(
        ROR.lookup("assetArea", assetData),
        O.chain(flow(option(t.number).decode, O.fromEither, O.flatten)),
        O.map(
          (_) =>
            `${pipe(
              Math.round(_ * SQUARE_FT_PER_SQ_METER),
              formatNumber(CommaNumber)
            )} ft²`
        ),
        O.getOrElse(() => "")
      ),
      Capacity: pipe(
        asset,
        O.chain((_) => _.capacity),
        O.getOrElse(() => 0)
      ),
      Cost: pipe(
        ROR.lookup("cost", assetData),
        O.chain(flow(option(t.number).decode, O.fromEither, O.flatten)),
        O.map((_) =>
          _ === 0 ? "$0" : `$${pipe(_, formatNumber(TwoDecimal))}`
        ),
        O.getOrElse(() => "")
      ),
      "Underutilization Cost": pipe(
        ROR.lookup("assetUnderutilizationCost", assetData),
        O.chain(flow(option(t.number).decode, O.fromEither, O.flatten)),
        O.map((_) =>
          _ === 0 ? "$0" : `$${pipe(_, formatNumber(TwoDecimal))}`
        ),
        O.getOrElse(() => "")
      ),
      "Price per sq ft": pipe(
        ROR.lookup("assetPricePerArea", assetData),
        O.chain(flow(option(t.number).decode, O.fromEither, O.flatten)),
        O.map(
          (_) =>
            `$${pipe(_ / SQUARE_FT_PER_SQ_METER, formatNumber(TwoDecimal))}`
        ),
        O.getOrElse(() => "")
      ),
      "Target Utilization": pipe(
        ROR.lookup("assetTargetUtilization", assetData),
        O.chain(flow(option(t.number).decode, O.fromEither, O.flatten)),
        O.map((_) => `${_ * 100}%`),
        O.getOrElse(() => "")
      ),
      Tags: pipe(
        ROR.lookup("assetTags", assetData),
        O.map((el: any) => {
          // TODO: How to decode properly the asset Tags, so we do not need to use "any" here.
          return el.join(",");
        }),
        O.getOrElse(() => "")
      ),
    };
    // Will be refactored with new export view approach:
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetTags")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns.Tags;
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetMeanUtilization")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns.Utilization;
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetMeanOccupancy")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns["Mean Occupancy"];
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetPeakOccupancy")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns["Peak Occupancy"];
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetPeakUtilization")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns["Peak Utilization"];
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetArea")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns.Size;
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetPricePerArea")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns["Price per sq ft"];
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetUnderutilizationCost")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns["Underutilization Cost"];
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetTargetUtilization")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns["Target Utilization"];
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetCapacity")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns.Capacity;
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("cost")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns.Cost;
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetShortCode")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns.ShortCode;
    }
    if (
      pipe(
        columns,
        A.findFirst(ByColumnName("assetParentBuilding")),
        O.map((_) => !_.views.has(TableDataViews.CSV)),
        O.getOrElse(() => true)
      )
    ) {
      // @ts-ignore
      delete allColumns["Parent Building"];
    }

    return allColumns;
  };

const getFileName = (range: SeriesRequest) => {
  const fileDateNameFormatter = new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return `assets-${fileDateNameFormatter.format(
    range.start
  )}-${fileDateNameFormatter.format(range.end)}`;
};

// TODO: make this typed React Functional Component
export const AssetsTableHeaderExportView: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [data] = useController(AssetsTableDataController, DataL.get);
  const [selectedRange] = useController(
    UIStateController,
    pipe(AssetsPageUIStateL, L.composeLens(SelectedRangeL)).get
  );
  const columns = useCollector(AssetsTableController, L.composeLens(ColumnsL));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    mixpanel.track("Export View Clicked");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadCsvData = (csvData: Array<unknown>) => {
    if (csvData.length > 0) {
      downloadCsv(csvData, getFileName(selectedRange));
    }
  };

  const handleCsvClick = () => {
    pipe(
      data,
      A.map(
        flow(
          ROR.filterMapWithIndex((key, value) =>
            pipe(
              value,
              O.fromPredicate(() =>
                pipe(
                  columns,
                  A.findFirst((_) => _.column.name === key),
                  O.fold(() => false, isShownFor(TableDataViews.CSV))
                )
              )
            )
          ),
          mapToCsvData(columns)
        )
      ),
      downloadCsvData
    );
    handleClose();
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleClick}
        startIcon={<Icon icon={AtExport} size="16px" />}
        endIcon={<Icon icon={AtChevronDown} size="24px" />}
        size="small"
        sx={{ whiteSpace: "nowrap" }}
      >
        Export View
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <span>
          <MenuItem onClick={handleCsvClick}>
            <Icon icon={AtExportCSV} size="16px" />
            Export to CSV
          </MenuItem>
        </span>
      </StyledMenu>
    </div>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    disableScrollLock={true}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    // boxShadow:
    //   "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    // "& .MuiMenu-list": {
    //   padding: "4px 12px",
    // },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
