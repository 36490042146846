import React, { useRef } from "react";
import palette from "theme/palette";
import { Box, Button, Paper, Stack } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { Asset } from "lib/at-data/assets/Asset";
import {
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import {
  AssetsController,
  fromAD2,
} from "views/authenticated/root/AssetsController";
import { AssetsL } from "views/authenticated/root/controller/state";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import { flow, pipe } from "fp-ts/function";
import { getByParent } from "lib/at-data/assets/assets";
import { LevelDiagram } from "views/authenticated/lucifer/modules/LocalGeometryWorkspace/LevelDiagram";

export const PrintWorkspace: React.FC<{ building: Asset }> = (props) => {
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [allAssets] = useController(
    AssetsController,
    flow(AssetsL.get, fromAD2)
  );
  const levels = pipe(props.building, getByParent)(allAssets);

  return (
    <div
      style={{
        width: "1500px",
        height: "650px",
        overflow: "scroll",
      }}
    >
      <Button onClick={handlePrint}>Print</Button>
      <Box
        sx={{
          backgroundColor: palette.neutral["300"],
        }}
      >
        <div
          ref={componentRef}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexWrap: "nowrap",
            padding: 2,
          }}
        >
          <Paper style={{ width: "8.5in", height: "11in", padding: 20 }}>
            <h1>Lambent Spacial Annotation Worksheet</h1>
            <h2>{props.building.name}</h2>
            <p>Please provide capacity data</p>
          </Paper>
          {levels.map((level) => (
            <Paper
              style={{ width: "8.5in", height: "11in", padding: 20 }}
              key={level.id}
            >
              <h2>Floor1</h2>
              <div style={{ width: "7.5in", margin: "0.5in" }}>
                <LevelDiagram level={level} inverted={true} />
              </div>
            </Paper>
          ))}
        </div>
      </Box>
    </div>
  );
};
