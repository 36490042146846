import { UUIDSlice } from "lib/at-data/UUIDSlice";
import * as IO from "fp-ts/IO";
import { Assets } from "lib/at-data/assets/assets";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/Array";
import * as NEA from "fp-ts/NonEmptyArray";
import * as RTE from "fp-ts/ReaderTaskEither";
import { groupIn } from "controllers/AssetsTableDataController/groupIn";
import { fetchSeriesTE } from "lib/at-api/otter/series";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import * as Ds from "lib/at-data/DataSeries";
import { fetchPeakTE } from "lib/at-api/otter/peak";
import { empty } from "lib/at-data/Slice";
import { UUID } from "lib/at-data/UUID";
import { last } from "fp-ts/Semigroup";
import * as USL from "lib/at-data/UUIDSlice";

export const batchPeakOccupancyScheduleRequests =
  (partialDoneIO: (slice: UUIDSlice<number>) => IO.IO<void>) =>
  (assets: Assets, series: SeriesRequest) =>
    pipe(
      assets,
      groupIn(10),
      A.filterMap(NEA.fromArray),
      A.map((batchAssets) => fetchPeakTE(batchAssets, series)),
      A.traverse(RTE.Monad)(RTE.chainFirstIOK(partialDoneIO)),
      RTE.map(
        A.match(
          () => empty<number, UUID>(),
          NEA.concatAll(pipe(last<number>(), USL.getUnionSemiGroup))
        )
      )
    );
