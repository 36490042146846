import { Menu, Stack, Switch, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import {
  ToggleMenuController,
  ToggleMenuModalEl,
} from "controllers/AppController/ToggleMenuController";
import {
  useCollectorOld,
  useController,
  useControllerDispatch,
} from "lib/at-react/defineController";
import * as L from "monocle-ts/Lens";
import * as b from "fp-ts/boolean";
import { FeatureFlagController } from "../../controllers/FeatureFlagController/FeatureFlagController";
import palette from "../../theme/palette";
import { AppController } from "../../controllers/AppController/AppController";
import { OccupancySource } from "views/authenticated/app/RunningAppState";
import { WiFiAPIL } from "views/authenticated/app/controller/state";
import { clog, removeStartSlash } from "../../lib/util";
import { UIStateController } from "../../controllers/UIStateCollector/UIStateController";
import { PathL } from "../../controllers/UIStateCollector/models/UIStateModel";

export const ToggleMenu: React.FC = () => {
  const [anchorEl, dispatch] = useController(
    ToggleMenuController,
    ToggleMenuModalEl.get
  );
  // console.log(anchorEl);

  const handleClose = useCallback(() => {
    pipe(O.none, ToggleMenuModalEl.set, dispatch);
  }, [anchorEl]);

  const appDispatch = useControllerDispatch(AppController);
  const handleChangeWiFi = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) =>
      pipe(
        ev.target.checked,
        b.fold(
          () => OccupancySource.LEGACY,
          () => OccupancySource.NEW_PROVIDER
        ),
        WiFiAPIL.set,
        appDispatch
      ),
    [appDispatch]
  );
  const [selectedWiFi] = useController(AppController, WiFiAPIL.get);
  return (
    <Menu
      anchorEl={O.toNullable(anchorEl)}
      open={O.isSome(anchorEl)}
      onClose={handleClose}
      mode="dark"
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      disableScrollLock={true}
    >
      <Typography pb={1} pt={1}>
        Data Settings
      </Typography>
      <Stack direction={"row"} sx={{ display: "flex", alignItems: "center" }}>
        <Switch
          // defaultChecked={false}
          onChange={handleChangeWiFi}
          checked={selectedWiFi !== OccupancySource.LEGACY}
          mode="light"
        />
        <Typography sx={{ color: palette.neutral["100"] }}>
          Show WiFi Occupancy from API
        </Typography>
      </Stack>
    </Menu>
  );
};
