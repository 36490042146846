import { AssetsContext } from "contexts/AssetsContext";
import { SeriesContext } from "contexts/SeriesContext";
import * as Eq from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import * as IO from "fp-ts/IO";
import * as O from "fp-ts/Option";
import * as RTE from "fp-ts/ReaderTaskEither";
import { fetchAssetsAnalyzeTE } from "lib/at-api/otter/analyze";
import { eqAssets } from "lib/at-data/assets/assets";
import * as AD2 from "lib/at-data/AsyncData2";
import {
  OccupancyEstimate,
  OccupancyEstimateEq,
  OcupancyEstimate,
} from "lib/at-data/OccupancyEstimate";
import { SeriesRequestEq } from "lib/at-data/requests/temporal/SeriesRequest";
import * as S from "lib/at-data/Slice";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { defineAppController } from "lib/at-react/defineAppController";
import { loggingAsyncDataEffect } from "lib/logging";
import * as L from "monocle-ts/Lens";
import React from "react";

export interface AssetsMeanOccupancyControllerState {
  meanOccupancies: AD2.AsyncData2<S.Slice<UUID, O.Option<OccupancyEstimate>>>;
}

export const AssetsMeanOccupancyControllerStateEq =
  Eq.struct<AssetsMeanOccupancyControllerState>({
    meanOccupancies: AD2.getEq(S.getEq(eqUUID, O.getEq(OccupancyEstimateEq))),
  });

export const initialState: AssetsMeanOccupancyControllerState = {
  meanOccupancies: AD2.none,
};

export const MeanOccupanciesL = pipe(
  L.id<AssetsMeanOccupancyControllerState>(),
  L.prop("meanOccupancies")
);

export const getMeanOccupancyRTE = (
  dispatch: React.Dispatch<
    React.SetStateAction<AssetsMeanOccupancyControllerState>
  >
) =>
  pipe(
    RTE.ask<AssetsContext & SeriesContext>(),
    RTE.chainW(({ assets, series }) =>
      fetchAssetsAnalyzeTE(series, assets, false)
    ),
    loggingAsyncDataEffect("AssetMeanOccupancyController", (analyzeData) =>
      pipe(
        analyzeData,
        AD2.map(S.map((_) => pipe(_.occupancyMean, O.map(OcupancyEstimate)))),
        MeanOccupanciesL.set,
        dispatch,
        IO.of
      )
    )
  );

export const [component, controller] = defineAppController<
  AssetsContext & SeriesContext,
  AssetsMeanOccupancyControllerState
>(
  initialState,
  AssetsMeanOccupancyControllerStateEq,
  Eq.struct({ series: SeriesRequestEq, assets: eqAssets }),
  getMeanOccupancyRTE
);

export const AssetsMeanOccupancyController = controller;
export const AssetsMeanOccupancyControllerComponent = component;

// export const TempCollector = pipe(
//   reactState<AssetsMeanOccupancyControllerState>(
//     initialState,
//     AssetsMeanOccupancyControllerStateEq
//   ),
//   effect(getMeanOccupancyRTE2),
//   make2
// );
// export const AssetMeanOccupancyController = pipe(
//   AppCollector,
//   composeCollector(TempCollector)
// );
