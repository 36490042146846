import {
  defineColumn,
  readColumnData,
} from "views/authenticated/assets/page/components/AssetsTable/columns/index";
import { flow, pipe } from "fp-ts/function";
import * as R from "fp-ts/Reader";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import { Asset } from "lib/at-data/assets/Asset";
import * as O from "fp-ts/Option";
import TableHeaderKpi from "components/table/TableHeaderKpi";
import * as N from "fp-ts/number";
import * as A from "fp-ts/Array";
import * as AA from "fp-ts-std/Array";
import React from "react";
import * as ROR from "fp-ts/ReadonlyRecord";
import { range } from "views/authenticated/assets/page/components/AssetsTable/Range";
import {
  isoUSDPerOneSqF,
  toUSDPerOneSqF,
  USDPerOneSqM,
  USDPeSqMModel,
} from "lib/at-data/units/currency";
import { AssetSqMPriceCell } from "views/authenticated/assets/page/components/AssetsTable/cells/AssetSqMPriceCell";
import { USD } from "lib/formatters/formatNumber";
import { option } from "lib/codecs/option";
import { clog } from "../../../../../../../../lib/util";
import { getAssetCostPerSqM } from "lib/at-data/assets/getters";

export const getAreaCost = pipe(
  R.asks<AssetRowDataContext, Asset>((_) => _.asset),
  R.map(getAssetCostPerSqM)
);

export const formatRange =
  (formatFn: (v: number) => string) => (r: Array<number>) =>
    pipe(r, A.map(formatFn), AA.join("-"));

export const PricePerAreaUnitHeader: React.FC<{ values: Array<USDPerOneSqM> }> =
  (props) =>
    pipe(
      props.values,
      A.map(flow(toUSDPerOneSqF, isoUSDPerOneSqF.unwrap)),
      range,
      O.map(formatRange(USD)),
      (value) => (
        <TableHeaderKpi
          label="Price"
          hint="Price per sq. ft. for the space, as specified by the organization."
          value={value}
        />
      )
    );

export const PricePerAreaUnitColumn = defineColumn(
  "assetPricePerArea",
  pipe(
    getAreaCost,
    R.map((cpa) => ROR.singleton("assetPricePerArea", cpa))
  ),
  R.of(AssetSqMPriceCell),
  flow(
    readColumnData,
    A.filterMap(
      flow(
        ROR.lookup("assetPricePerArea"),
        O.chain(flow(option(USDPeSqMModel).decode, O.fromEither, O.flatten))
      )
    ),
    (v) => <PricePerAreaUnitHeader values={v} />
  ),
  R.of({
    defaultFlex: 0.5,
    sort: O.getOrd(N.Ord).compare,
  })
);
