import React, { useCallback } from "react";
import { Asset } from "lib/at-data/assets/Asset";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { TableCell, TableRow } from "@mui/material";
import * as n from "fp-ts/number";
import {
  Editable,
  OptionalProperty,
  Property,
} from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components";
import { NumberEditor } from "views/authenticated/assets/page/components/AssetsDetailPreview/AssetEditTab/components/editors";

export const ZoneAssetCapacityPropertyRow: React.FC<{
  asset: Asset;
  onChange: (capacity: number) => void;
}> = (props) => (
  <TableRow
    key={"capacityRow"}
    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  >
    <TableCell component="th" scope="row" sx={{ borderBottom: 0 }}>
      Capacity
    </TableCell>
    <TableCell align="right" sx={{ borderBottom: 0 }} width={270}>
      <Editable
        onChange={props.onChange}
        value={pipe(
          props.asset.capacity,
          O.getOrElseW(() => 0)
        )}
        eq={n.Eq}
        editor={NumberEditor}
      >
        <OptionalProperty
          value={props.asset.capacity}
          component={Property}
          required={true}
        />
      </Editable>
    </TableCell>
  </TableRow>
);
