import * as AA from "fp-ts-std/Array";
import * as A from "fp-ts/Array";
import React from "react";
import { AssetModificationServiceResponse } from "controllers/AssetsController/AssetsController";
import { pipe } from "fp-ts/function";
import { formatDistanceToNow } from "date-fns";

export const UpdateStatus: React.FC<{
  result: AssetModificationServiceResponse[];
}> = (props) => {
  return (
    <div>
      <span>Result:</span>
      <p>
        Tasks Completed:{" "}
        <strong>
          {pipe(
            props.result,
            A.map((_) => _.assetTaskImport.taskCountComplete),
            AA.sum
          )}
        </strong>
      </p>
    </div>
  );
};
