import { UIState } from "controllers/UIStateCollector/models/UIStateModel";
import { ActivityPageUIStateFilter } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import { pipe } from "fp-ts/function";
import { ActivityPageL } from "controllers/UIStateCollector/lens/ActivityPageL";
import * as L from "monocle-ts/Lens";

export const activityStateFromPathParams =
  (uiState: UIState) => (stateFilter: ActivityPageUIStateFilter) =>
    pipe(
      uiState,
      pipe(
        ActivityPageL,
        L.props("selectedRange", "assetsFilter", "bbox", "bearing")
      ).set(stateFilter)
    );
