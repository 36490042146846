import { AppState } from "views/authenticated/app/controller/state";
import * as O from "fp-ts/Option";
import { OccupancySource } from "views/authenticated/app/RunningAppState";

export const initialState: AppState = {
  authClient: O.none,
  user: O.none,
  accessToken: O.none,
  wifiAPI: OccupancySource.LEGACY,
  logger: () => {},
};
