import { TypeColumn } from "@inovua/reactdatagrid-enterprise/types";
import * as O from "fp-ts/lib/Option";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import React, { ReactNode } from "react";
import { flow, pipe } from "fp-ts/function";
import { first } from "fp-ts/es6/Semigroup";
import { AssetRowDataContext } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetRowDataContext";
import * as R from "fp-ts/Reader";
import { AssetTableData } from "views/authenticated/assets/page/components/AssetsTable/contexts/AssetColumnContext";
import * as ROR from "fp-ts/ReadonlyRecord";
import { Reader, second } from "fp-ts/Reader";
import { last } from "fp-ts/lib/Semigroup";
import * as T from "monocle-ts/Traversal";
import { AssetsCompareColumn } from "controllers/AssetsTableController/models/AssetCompareColumn";
import { ReadonlyRecord } from "fp-ts/ReadonlyRecord";
import * as A from "fp-ts/Array";

export interface DataAgg {
  dataAgg: R.Reader<
    ROR.ReadonlyRecord<string, unknown>,
    ROR.ReadonlyRecord<string, unknown>
  >;
}

export type ColumnData<T, DATA> = {
  data: R.Reader<DATA, ROR.ReadonlyRecord<string, T>>;
};

export type Column<T, COLUMN_CONTEXT, ROW_CONTEXT, GRIDPROPS_CONTEXT, DATA> = {
  name: string;
  dataAgg: R.Reader<
    ROR.ReadonlyRecord<string, unknown>,
    ROR.ReadonlyRecord<string, unknown>
  >;
  render: R.Reader<ROW_CONTEXT, React.FC<{ value: T }>>;
  header: R.Reader<COLUMN_CONTEXT, ReactNode>;
  gridProps: R.Reader<GRIDPROPS_CONTEXT, TypeColumn>;
} & DataAgg &
  ColumnData<T, DATA>;

export const passThrough = pipe(
  R.asks<
    ROR.ReadonlyRecord<string, unknown>,
    ROR.ReadonlyRecord<string, unknown>
  >((_) => _)
);

export const defineColumn = <
  K extends string,
  T,
  COLUMN_CONTEXT,
  ROW_CONTEXT,
  GRIDPROPS_CONTEXT,
  DATA
>(
  name: K,
  data: R.Reader<DATA, ROR.ReadonlyRecord<string, T>>,
  render: R.Reader<ROW_CONTEXT, React.FC<{ value: T }>>,
  header: R.Reader<COLUMN_CONTEXT, ReactNode>,
  gridProps: R.Reader<GRIDPROPS_CONTEXT, TypeColumn>
): Column<T, COLUMN_CONTEXT, ROW_CONTEXT, GRIDPROPS_CONTEXT, DATA> => ({
  gridProps,
  name,
  data,
  dataAgg: passThrough,
  render,
  header,
});

export const withDataAgg =
  (
    dataAgg: R.Reader<
      ROR.ReadonlyRecord<string, unknown>,
      ROR.ReadonlyRecord<string, unknown>
    >
  ) =>
  <K extends string, T, HR, RR, GRIDPROPS_CONTEXT, DATA>(
    c: Column<T, HR, RR, GRIDPROPS_CONTEXT, DATA>
  ) => ({
    ...c,
    dataAgg,
  });

export const toGridDefinition = <T, HR, RR, GRIDPROPS_CONTEXT>(
  c: Column<T, HR, RR, GRIDPROPS_CONTEXT, never>
) =>
  pipe(
    R.Do,
    R.apSW("header", c.header),
    R.apSW("render", c.render),
    R.apSW("gridProps", c.gridProps),
    R.map(({ header, render, gridProps }) => ({
      ...gridProps,
      name: c.name,
      header,
      render,
    }))
  );

// using any here because column value types can be anything and the data grid erases types anyways
export const toGridColumns = <HR, RR, GRIDPROPS_CONTEXT>(
  c: Array<Column<any, HR, RR, GRIDPROPS_CONTEXT, never>>
) =>
  pipe(
    c,
    NEA.fromArray,
    O.map(flow(NEA.map(toGridDefinition), R.sequenceArray)),
    O.sequence(R.Monad)
  );

export const toDataFn = (
  c: Array<R.Reader<AssetRowDataContext, ROR.ReadonlyRecord<string, unknown>>>
) =>
  pipe(
    c,
    NEA.fromArray,
    O.map(NEA.concatAll(R.getSemigroup(ROR.getUnionSemigroup(first()))))
  );

export const toDataAggFn = (
  c: Array<
    R.Reader<
      ROR.ReadonlyRecord<string, unknown>,
      ROR.ReadonlyRecord<string, unknown>
    >
  >
) =>
  pipe(
    c,
    NEA.fromArray,
    O.map(NEA.concatAll(R.getSemigroup(ROR.getUnionSemigroup(first())))),
    O.getOrElse(() => passThrough)
  );

export const toTableDataAggFn = <
  A,
  T extends ColumnData<A, AssetRowDataContext>
>(
  columns: Array<T>
) =>
  pipe(
    columns,
    A.map((_) => _.data),
    toDataFn,
    O.sequence(R.Monad),
    R.map(O.getOrElseW(() => ROR.empty))
  );

export const readColumnData = pipe(
  R.asks<AssetTableData, Array<ROR.ReadonlyRecord<string, unknown>>>(
    (_) => _.data
  )
);
