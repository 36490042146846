import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  styled,
  Tooltip,
} from "@mui/material";
import Icon from "components/ui/Icon";
import React from "react";

interface ISidebarListItem {
  label: string;
  icon: React.ElementType;
  selected: boolean;
  onClick: () => void;
  tooltipTitle?: string;
}

const ContextualSidebarListItem: React.FC<ISidebarListItem> = (props) => {
  return (
    <Tooltip
      title={`${props.tooltipTitle}`}
      sx={{ width: "100%" }}
      placement="left"
      arrow
    >
      <StyledListItem>
        <ListItemButton
          aria-label={props.label}
          onClick={props.onClick}
          data-selected={props.selected}
        >
          <ListItemIcon>
            <Icon icon={props.icon} />
          </ListItemIcon>
        </ListItemButton>
      </StyledListItem>
    </Tooltip>
  );
};
export default ContextualSidebarListItem;

const StyledListItem = styled(ListItem)`
  padding: 0;
  margin: 0;
`;
