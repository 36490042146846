import { Stack, styled, Typography } from "@mui/material";
import React from "react";
import palette from "theme/palette";
import { UTILIZATION_THRESHOLDS } from "constants/UTILIZATION_THRESHOLDS";
import { getThresholdColor } from "lib/at-data/UtilizationThresholds";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";

const AssetsTableCellView: React.FC<{
  percent: number;
}> = (props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <UtilizationBar
        percent={props.percent}
        style={{
          ["--bar-color" as any]: pipe(
            UTILIZATION_THRESHOLDS,
            getThresholdColor(props.percent),
            O.getOrElse(() => palette.neutral[100])
          ),
        }}
      />{" "}
      <Typography variant="body1">{props.percent}%</Typography>
    </Stack>
  );
};
export default AssetsTableCellView;

export const UtilizationBar = styled("div")<{ percent: number }>`
  --bar-color: ${palette.neutral[500]};

  position: relative;
  display: block;
  width: 64px;
  height: 6px;
  overflow: hidden;
  background-color: ${palette.neutral[400]};
  border-radius: 50px;

  &::after {
    display: block;
    width: ${(props) => props.percent}%;
    height: 100%;
    background-color: var(--bar-color);
    content: " ";
  }
`;
