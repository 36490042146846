import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { ArtifactTypes } from "lib/at-data/Artifact";
import { Asset } from "lib/at-data/assets/Asset";
import { UUID } from "lib/at-data/UUID";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import { ImageArtifactsControllerState } from "controllers/FloorBackgroundImagesController/state";

export const BackgroundArtifactsL = pipe(
  L.id<ImageArtifactsControllerState>(),
  L.prop("artifacts"),
  L.prop(ArtifactTypes.BACKGROUND)
);
