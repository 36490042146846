import { empty } from "lib/at-data/Slice";
import { defineController } from "lib/at-react/defineController";
import * as Eq from "fp-ts/Eq";
import {
  LocalArtifactsControllerState,
  LocalArtifactsControllerStateEq,
} from "controllers/LocalArtifactsController/state";

export const initialState: LocalArtifactsControllerState = {
  artifacts: empty(),
};

const [component, controller] = defineController(
  initialState,
  LocalArtifactsControllerStateEq,
  Eq.struct({}),
  (dispatch) => () => () => {}
);

export const LocalArtifactsController = controller;
export const LocalArtifactsControllerComponent = component;
