import * as C from "io-ts/Codec";
import { HoursOfOperationModel } from "lib/at-data/HoursOfOperation";
import { USDPeSqMModel } from "lib/at-data/units/currency";
import * as E from "fp-ts/Either";
import { getSemigroup, NonEmptyArray } from "fp-ts/NonEmptyArray";
import { pipe } from "fp-ts/function";
import { sequenceS } from "fp-ts/Apply";
import {
  validateCapacity,
  validatecostPerSqM,
  validateHoursOfoperation,
  validateTargetUtilization,
} from "lib/at-data/assets/validations/validateZone";
import { AssetAnalytics } from "lib/at-data/assets/models/AssetAnalyticsModel";
import { Asset } from "lib/at-data/assets/models/AssetModel";

export const AnalyzableAssetModel = C.struct({
  operationHours: HoursOfOperationModel,
  capacity: C.number,
  targetUtilization: C.number,
  costPerSqM: USDPeSqMModel,
});

export type AnalyzableAsset = C.TypeOf<typeof AnalyzableAssetModel>;

export const validateAssetAnalytics = (
  asset: Asset
): E.Either<NonEmptyArray<string>, Asset> =>
  pipe(
    sequenceS(E.getApplicativeValidation(getSemigroup<string>()))({
      capacity: validateCapacity(asset.capacity),
      operationHours: validateHoursOfoperation(asset.operationHours),
      targetUtilization: validateTargetUtilization(asset.targetUtilization),
      costPerSqM: validatecostPerSqM(asset.costPerSqM),
    }),
    E.map(() => asset)
  );
