import { Box, Stack, styled, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import DateRangePicker from "components/ui/range/DateRangePicker";
import { AssetOContext } from "contexts/AssetContext";
import {
  ActivityLiveOccupancyController,
  ActivityLiveOccupancyControllerComponent,
} from "controllers/AssetLiveOccupancyController/ActivityLiveOccupancyController";
import { LastOccupancyEstimateL } from "controllers/AssetLiveOccupancyController/state";
import {
  AssetsMeanOccupancyController,
  AssetsMeanOccupancyControllerComponent,
} from "controllers/AssetsMeanOccupancyController/AssetsMeanOccupancyController";
import { ActivityPageUIState } from "controllers/UIStateCollector/models/ActivityPageUIStateModel";
import * as A from "fp-ts/Array";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Asset } from "lib/at-data/assets/Asset";
import { getId, getName } from "lib/at-data/assets/getters";
import * as AD from "lib/at-data/AsyncData";
import * as AD2 from "lib/at-data/AsyncData2";
import { getOccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { calculateResolution } from "lib/at-data/requests/temporal/FromNumberOfBuckets";
import {
  getActiveBucket,
  getFirstSlice,
  getLastSlice,
} from "lib/at-data/requests/temporal/GranulatedSeriesRequest";
import { SeriesRequest } from "lib/at-data/requests/temporal/SeriesRequest";
import { getSliceByUUID } from "lib/at-data/UUIDSlice";
import * as Cl from "lib/at-react/collector";
import { useController } from "lib/at-react/defineController";
import { useCollector } from "lib/at-react/hooks";
import React from "react";
import palette from "theme/palette";
import theme from "theme/theme";
import { TimelineResolution } from "views/authenticated/activity/page/components/navigator/TimelineResolution";
import {
  CommaNumber,
  CommaNumberNoDecimal,
  formatNumber,
  TwoDecimal,
  UINumber,
} from "lib/formatters/formatNumber";
import { TimelineGranulatedRangeCollector } from "views/authenticated/activity/page/components/navigator/TimelineRangeCollector";
import { format, subSeconds } from "date-fns/fp";
import { SelectedSliceCollector } from "controllers/SelectedSliceCollector/SelectedSliceCollector";

const SelectedSliceMeanOccupancy: React.FC<{
  selectedAsset: O.Option<Asset>;
}> = (props) => {
  const [meanOccupancies] = useController(
    AssetsMeanOccupancyController,
    (_) => _.meanOccupancies
  );

  const selectedSlice = useCollector(SelectedSliceCollector);

  return (
    <EstOccupancy>
      Mean Occupancy ({pipe(selectedSlice.start, format("MMM dd, h:mm a"))} -{" "}
      {pipe(selectedSlice.end, format("MMM dd, h:mm a"))}):{" "}
      {pipe(
        props.selectedAsset,
        O.map(getId),
        O.map((assetId) =>
          pipe(
            meanOccupancies,
            AD2.map(getSliceByUUID(assetId)),
            AD2.fold(
              () => "0",
              () => "Pending",
              flow(
                O.flatten,
                O.fold(
                  () => "0",
                  flow(getOccupancyEstimate, formatNumber(UINumber))
                )
              ),
              () => "Error"
            )
          )
        ),
        O.getOrElseW(() => null)
      )}
    </EstOccupancy>
  );
};

const ActivityContentTimebarHeader: React.FC<{
  selectedAsset: O.Option<Asset>;
  onSelectedRangeChange: (range: SeriesRequest) => void;
}> = (props) => {
  const selectedRange = useCollector(TimelineGranulatedRangeCollector);
  const selectedSlice = useCollector(SelectedSliceCollector);

  console.log("selectedAse", props.selectedAsset);

  return (
    <StyledBox>
      <Stack spacing={0.5}>
        <Typography
          variant="body1"
          color={palette.neutral[100]}
          fontWeight="500"
        >
          {pipe(
            props.selectedAsset,
            O.map(getName),
            O.getOrElse(() => "~")
          )}
        </Typography>
        <AssetsMeanOccupancyControllerComponent
          context={{
            assets: pipe(props.selectedAsset, A.fromOption),
            series: selectedSlice,
          }}
        >
          <SelectedSliceMeanOccupancy selectedAsset={props.selectedAsset} />
        </AssetsMeanOccupancyControllerComponent>
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        sx={{ width: "auto", alignItems: "center" }}
      >
        <TimelineResolution selectedRange={selectedRange} />
        <DateRangePicker
          nowDate={new Date()}
          series={selectedRange}
          onChanged={props.onSelectedRangeChange}
          maxDate={new Date()}
          minDate={new Date(2020, 0, 1)}
        />
      </Stack>
    </StyledBox>
  );
};

export default ActivityContentTimebarHeader;

const StyledBox = styled(Box)`
  height: 50px;
  padding: 0 ${theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const pulsing = keyframes`
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const EstOccupancy = styled(Typography)`
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${palette.neutral[400]};
`;
