import { Lens } from "monocle-ts";
import * as Eq from "fp-ts/Eq";
import * as O from "fp-ts/Option";
import * as AD from "lib/at-data/AsyncData";
import { ChartDataSlice } from "lib/at-data/ChartDataSlice";
import { eqStrict } from "fp-ts/Eq";
import * as S from "lib/at-data/Slice";
import * as d from "fp-ts/Date";
import { eqUUID, UUID } from "lib/at-data/UUID";
import { OccupancyEstimate } from "lib/at-data/OccupancyEstimate";
import { pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";

export type AssetsLiveOccupancyState = {
  lastOccupancyEstimates: AD.AsyncData<
    S.Slice<UUID, O.Option<OccupancyEstimate>>
  >;
  lastOccupancyTimestamp: AD.AsyncData<S.Slice<UUID, O.Option<Date>>>;
};

export const AssetsLiveOccupancyStateEq = Eq.struct<AssetsLiveOccupancyState>({
  lastOccupancyEstimates: AD.getEq(eqStrict),
  lastOccupancyTimestamp: AD.getEq(S.getEq(eqUUID, O.getEq(d.Eq))),
});

export const LastOccupancyEstimatesL = pipe(
  L.id<AssetsLiveOccupancyState>(),
  L.prop("lastOccupancyEstimates")
);

export const LastOccupancyTimestampL = pipe(
  L.id<AssetsLiveOccupancyState>(),
  L.prop("lastOccupancyTimestamp")
);
