import { pipe, flow } from "fp-ts/function";
import * as O from "fp-ts/lib/Option";
import { initialState } from "./initialState";
import * as RTE from "fp-ts/ReaderTaskEither";
import * as RT from "fp-ts/ReaderTask";
import * as Eq from "fp-ts/Eq";
import { AssetsInsightsState, AssetsInsightsStateEq, InsightsL } from "./state";
import { AssetContext } from "contexts/AssetContext";
import { eqAsset } from "lib/at-data/assets/Asset";
import * as AD from "lib/at-data/AsyncData";
import { fetchInsights, Insights } from "lib/at-api/assets/insights";
import { UUID } from "lib/at-data/UUID";
import * as NEA from "fp-ts/NonEmptyArray";
import React from "react";
import { defineAppController } from "lib/at-react/defineAppController";

export const getInsights = pipe(
  RTE.asks<AssetContext, UUID>((_) => _.asset.id),
  RTE.chainW(fetchInsights)
);

export const setInsightsIO =
  (dispatch: React.Dispatch<React.SetStateAction<AssetsInsightsState>>) =>
  (insights: AD.AsyncData<Array<Insights>>) =>
  () =>
    pipe(insights, InsightsL.set, dispatch);

const [component, controller] = defineAppController<
  AssetContext,
  AssetsInsightsState
>(
  initialState,
  AssetsInsightsStateEq,
  Eq.struct({ asset: eqAsset }),
  (dispatch) =>
    pipe(
      RTE.fromIO(pipe(AD.PendingData(O.none), setInsightsIO(dispatch))),
      RTE.chainW(() => getInsights),
      RTE.chainFirstIOK(
        flow(NEA.fromArray, AD.DoneData, setInsightsIO(dispatch))
      ),
      RTE.altW(() =>
        RTE.fromIO(pipe(AD.DoneData(O.none), setInsightsIO(dispatch)))
      )
    )
);

export const AssetsInsightsControllerComponent = component;
export const AssetsInsightsController = controller;
