import React from "react";
import theme from "theme/theme";
import palette from "theme/palette";
import styled, { css } from "styled-components";
import * as Ds from "lib/at-data/DataSlice";
import {
  getOccupancyEstimate,
  OccupancyEstimate,
} from "lib/at-data/OccupancyEstimate";
import * as Sl from "lib/at-data/Slice";
import * as N from "lib/at-data/NumberSlice";
import { flow, pipe } from "fp-ts/function";
import { IHeatmapColor } from "components/spatial/base/layers/heatmap";
import { UtilizationThresholds } from "lib/at-data/UtilizationThresholds";
import { UTILIZATION_THRESHOLDS } from "constants/UTILIZATION_THRESHOLDS";
import * as UT from "../../../../../../lib/at-data/UtilizationThresholds";
import { Box, List, ListItem, Typography } from "@mui/material";
import * as A from "fp-ts/Array";
import * as Tpl from "fp-ts/Tuple";
import * as O from "fp-ts/Option";
import {
  ACTIVITY_MAP_LAYER,
  mapLayersEq,
} from "views/authenticated/activity/controller/state";
import { useCollectorOld } from "lib/at-react/defineController";
import { UIStateController } from "controllers/UIStateCollector/UIStateController";
import * as L from "monocle-ts/Lens";
import { ActivityPageUIStateL } from "controllers/UIStateCollector/lens/activityPage";
import * as S from "fp-ts/Set";

export const ActivityDataLegend: React.FC<{ enabled: boolean }> = (props) => {
  const layers = useCollectorOld(
    UIStateController,
    flow(L.composeLens(ActivityPageUIStateL), L.prop("layers"))
  );

  return (
    <>
      <ActivityControlsRoomByRoomUtilizationKey
        thresholds={UTILIZATION_THRESHOLDS}
        enabled={
          props.enabled &&
          pipe(
            layers,
            S.elem(mapLayersEq)(ACTIVITY_MAP_LAYER.ROOM_BY_ROOM_UTILIZATION)
          )
        }
      />
    </>
  );
};

const UtilizationItem: React.FC<{
  color: string;
  text?: string;
  threshold: [number, number];
  className?: string;
}> = ({ color, text, threshold, className }) => {
  return (
    <ListItem
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "0",
        margin: "0 0 12px",
        borderRadius: "2px",

        color: `${palette.neutral[100]}`,
        "&.active": {
          backgroundColor: `${palette.neutral[600]}`,

          color: `${palette.neutral[100]}`,
        },
      }}
      className={`${className}`}
    >
      <Typography
        component={"div"}
        sx={{
          whiteSpace: "nowrap",
          fontSize: "14px",
          lineHeight: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Swatch sx={{ backgroundColor: `${color}` }} /> {text}
      </Typography>
      <Typography
        component={"div"}
        sx={{
          fontSize: "14px",
          lineHeight: "100%",
        }}
      >
        {threshold[0]}-{threshold[1]}
        {threshold[1] === 100 ? "+" : ""}%
      </Typography>
    </ListItem>
  );
};

const UtilizationPatterns: React.FC<{ thresholds: UT.UtilizationThresholds }> =
  (props) => {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: palette.neutral["650"],
          borderRadius: "0.25em",
          margin: "-24px 0 0 0",
          padding: "1em",
        }}
      >
        <p
          style={{
            margin: 0,
            color: `${palette.neutral[100]}`,
            fontSize: "13px",
          }}
        >
          Utilization %
        </p>
        <List sx={{ paddingTop: "1em", paddingBottom: "0" }}>
          {pipe(
            props.thresholds,
            UT.sort,
            A.mapWithIndex((i, t) => (
              <UtilizationItem
                key={i}
                color={t.color}
                // text={t.legend}
                threshold={pipe(
                  t,
                  UT.thresholdRange(pipe(props.thresholds, A.lookup(i + 1))),
                  Tpl.bimap(
                    O.getOrElse(() => 100),
                    O.getOrElse(() => 0)
                  )
                )}
              />
            ))
          )}
        </List>
      </div>
    );
  };

export const ActivityControlsRoomByRoomUtilizationKey: React.FC<{
  enabled: boolean;
  thresholds: UtilizationThresholds;
}> = (props) => {
  return (
    <UtilizationKey $isVisible={props.enabled}>
      <UtilizationPatterns thresholds={UTILIZATION_THRESHOLDS} />
    </UtilizationKey>
  );
};

export const UtilizationKey = styled("div")<{ $isVisible: boolean }>`
  display: flex;
  max-height: 300px;
  max-width: 120px;
  opacity: 0;
  // position: absolute;
  top: 30px;
  visibility: hidden;
  transition: ${theme.transitions.create(["all"], {
    easing: "ease",
  })};
  pointer-events: all;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const ActivityControlsHeatmapKey: React.FC<{
  enabled: boolean;
  assetsSlice: Ds.DataSlice<OccupancyEstimate>;
  colors: Array<IHeatmapColor>;
}> = ({ enabled, assetsSlice, colors }) => {
  const max = pipe(assetsSlice, Sl.map(getOccupancyEstimate), N.max, Math.ceil);

  const gradientColorString = colors.reduce((acc, color, index) => {
    return `${acc}\n${color.color} ${Math.floor(color.multiplier * 100)}%${
      colors.length > index + 1 ? "," : ""
    }`;
  }, "");

  const isVisibe = enabled && max > 0;

  return (
    <Key $isVisible={isVisibe}>
      <Bar gradientString={gradientColorString} />
      <KeyLabels>
        {colors
          .slice()
          .sort((a, b) => b.multiplier - a.multiplier)
          .map((color, index) => (
            <KeyLabel key={color.color + index}>
              {Math.floor(max * color.multiplier)}
            </KeyLabel>
          ))}
      </KeyLabels>
    </Key>
  );
};

export default ActivityControlsHeatmapKey;

const Key = styled("div")<{ $isVisible: boolean }>`
  display: flex;
  height: 0;
  min-height: 175px;
  max-height: 300px;
  opacity: 0;
  position: absolute;
  top: 30px;
  visibility: hidden;
  transition: ${theme.transitions.create(["all"], {
    easing: "ease",
  })};

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
      height: 25vh;
      visibility: visible;
    `}
`;

const Bar = styled("div")<{ gradientString: string }>`
  height: 100%;
  width: 12px;
  background: linear-gradient(
    to top,
    ${({ gradientString }) => gradientString}
  );
  border-radius: 1px;
`;

const KeyLabels = styled("ul")`
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0 0 0 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${theme.spacing(0.5)};
`;

const KeyLabel = styled("li")`
  color: ${palette.neutral[100]};
  line-height: 1;
  font-size: 1.4rem;
`;
const Swatch = styled(Box)`
  width: 12px;
  height: 12px;
  margin: auto 0;
  margin-right: 8px;
  display: inline-flex;
  padding: 0;
  border-radius: 1px;
`;
