import * as React from "react";
import { ChangeEvent, useCallback } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
} from "@mui/material";
import { ReportType } from "views/authenticated/reports2/componenets/reportsTable/ReportType";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/function";
import {
  getDate,
  getDay,
  getMonth,
  getYear,
  setDate,
  setMonth,
  setYear,
  subYears,
} from "date-fns/fp";
import * as E from "fp-ts/Either";
import { dateFromISOString } from "lib/codecs/dateFromISOString";
import { toISOString } from "fp-ts-std/Date";

export const SelectReportType: React.FC<{
  reportType: string;
  onChange: (reportType: string) => void;
}> = (props) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    props.onChange(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id="report"
          variant="standard"
          htmlFor="uncontrolled-report-type"
          sx={{ fontSize: "15px" }}
        >
          Report Type
        </InputLabel>
        <NativeSelect
          inputProps={{
            name: "report-type-select",
            id: "uncontrolled-report-type-native",
          }}
          id="report-type-select"
          value={props.reportType}
          onChange={handleChange}
        >
          <option value={ReportType.RVO_REPORT}>RVO Report</option>
          <option value={ReportType.GRAPH_PACKS}>Graph Packs</option>
          <option value={ReportType.HOURLY_EXPORT}>Hourly Export</option>
          <option value={ReportType.OTHER_REPORT}>Other</option>
        </NativeSelect>
      </FormControl>
    </Box>
  );
};
export const SelectMonth: React.FC<{
  month: number;
  onChange: (month: number) => void;
}> = (props) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // @ts-ignore
    props.onChange(event.target.value as number);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id="month"
          variant="standard"
          htmlFor="report-month"
          sx={{ fontSize: "15px" }}
        >
          Month
        </InputLabel>
        <NativeSelect
          inputProps={{
            id: "report-month",
          }}
          id="month-select"
          // @ts-ignore
          value={props.month}
          onChange={handleChange}
        >
          <option value={0}>Jan</option>
          <option value={1}>Feb</option>
          <option value={2}>Mar</option>
          <option value={3}>April</option>
          <option value={4}>May</option>
          <option value={5}>Jun</option>
          <option value={6}>Jul</option>
          <option value={7}>Aug</option>
          <option value={8}>Sep</option>
          <option value={9}>Oct</option>
          <option value={10}>Nov</option>
          <option value={11}>Dec</option>
        </NativeSelect>
      </FormControl>
    </Box>
  );
};
export const SelectDay: React.FC<{
  value: number;
  onChange: (day: number) => void;
}> = (props) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // @ts-ignore
    props.onChange(event.target.value as number);
  };
  const days = A.makeBy(31, (_) => _ + 1);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id="day"
          variant="standard"
          htmlFor="report-day"
          sx={{ fontSize: "15px" }}
        >
          Day
        </InputLabel>
        <NativeSelect
          id="day-select"
          // @ts-ignore
          value={props.value}
          inputProps={{
            id: "report-day",
          }}
          onChange={handleChange}
        >
          {pipe(
            days,
            A.map((dn) => (
              <option value={dn} key={dn}>
                {dn}
              </option>
            ))
          )}
        </NativeSelect>
      </FormControl>
    </Box>
  );
};
export const SelectYear: React.FC<{
  value: number;
  onChange: (day: number) => void;
}> = (props) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // @ts-ignore
    props.onChange(event.target.value as number);
  };
  const years = A.makeBy(5, (_) => pipe(new Date(), subYears(_), getYear));

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id="year"
          variant="standard"
          htmlFor="report-year"
          sx={{ fontSize: "15px" }}
        >
          Year
        </InputLabel>
        <NativeSelect
          inputProps={{
            id: "report-year",
          }}
          id="day-select"
          // @ts-ignore
          value={props.value}
          onChange={handleChange}
        >
          {pipe(
            years,
            A.map((yearNumber) => (
              <option value={yearNumber} key={yearNumber}>
                {yearNumber}
              </option>
            ))
          )}
        </NativeSelect>
      </FormControl>
    </Box>
  );
};
export const SelectReportDate: React.FC<{
  value: E.Either<Error, string>;
  onChange: (date: E.Either<Error, string>) => void;
}> = (props) => {
  const date = pipe(
    props.value,
    E.chainW(dateFromISOString.decode),
    E.mapLeft(() => new Error("Invalid Date")),
    E.getOrElse(() => new Date())
  );

  const year = pipe(date, getYear);
  const month = pipe(date, getMonth);
  const day = pipe(date, getDate);

  const handleMonthChange = useCallback(
    (newMonth: number) =>
      pipe(
        date,
        setMonth(newMonth),
        setYear(year),
        setDate(day),
        toISOString,
        E.right,
        props.onChange
      ),
    [date, year, day]
  );
  const handleYearChange = useCallback(
    (newYear: number) =>
      pipe(
        date,
        setYear(newYear),
        setDate(day),
        setMonth(month),
        toISOString,
        E.right,
        props.onChange
      ),
    [date, month, day]
  );
  const handleDayChange = useCallback(
    (newDay: number) =>
      pipe(
        date,
        setDate(newDay),
        setYear(year),
        setMonth(month),
        toISOString,
        E.right,
        props.onChange
      ),
    [date, year, month]
  );

  return (
    <>
      <SelectMonth month={month} onChange={handleMonthChange} />
      <SelectDay value={day} onChange={handleDayChange} />
      <SelectYear value={year} onChange={handleYearChange} />
    </>
  );
};
