import { flow, pipe } from "fp-ts/function";
import * as L from "monocle-ts/Lens";
import { BrowserHistoryState } from "lib/at-react/defineHistoryController";
import { UIState } from "controllers/UIStateCollector/models/UIStateModel";
import * as FTR from "fp-ts-routing";
import { QueryValues } from "fp-ts-routing";
import * as O from "fp-ts/Option";
import * as Tu from "fp-ts/Tuple";
import * as C from "io-ts/Codec";
import { Codec } from "io-ts/Codec";
import { Type } from "io-ts";
import {
  LuciferStatePathParamsModel,
  UIStatePathParamsModel,
  UIStateSpatialPathParamsModel,
} from "controllers/UIStateCollector/models/UIStatePathParamsModel";
import { assetsStateFromPathParams } from "controllers/UIStateCollector/lens/assetsStateFromPathParams";
import { uiState } from "controllers/UIStateCollector/initialState/uiState";
import {
  ActivityPageUIStatePathCodec,
  AssetPageUIStatePathCodec,
} from "controllers/UIStateCollector/models/AssetPageUIStatePathCodec";
import { activityStateFromPathParams } from "controllers/UIStateCollector/lens/activityStateFromPathParams";
import { luciferStateFromPathParams } from "controllers/UIStateCollector/lens/luciferStateFromPathParams";
import { SelectedAssetPathParamsModel } from "controllers/UIStateCollector/lens/SelectedAssetPathParamsModel";

export const UIStateL = pipe(
  L.id<BrowserHistoryState<UIState>>(),
  L.prop("state")
);

/*
 fp-ts routing doesn't support Codec, but types line up with the older Type
 */
export const CodecQuery = <A>(
  codec: Codec<unknown, Record<string, QueryValues>, A>
) => FTR.query(codec as Type<A, Record<string, QueryValues>, unknown>);

export const assetsPageMatch = pipe(
  FTR.lit("assets"),
  FTR.then(
    CodecQuery(
      pipe(
        UIStatePathParamsModel,
        C.intersect(SelectedAssetPathParamsModel),
        C.compose(AssetPageUIStatePathCodec)
      )
    )
  ),
  FTR.then(FTR.end)
);
export const activityPageMatch = pipe(
  FTR.lit("activity"),
  FTR.then(
    CodecQuery(
      pipe(
        UIStatePathParamsModel,
        C.intersect(UIStateSpatialPathParamsModel),
        C.intersect(SelectedAssetPathParamsModel),
        C.compose(ActivityPageUIStatePathCodec)
      )
    )
  ),
  FTR.then(FTR.end)
);

export const luciferPageMatch = pipe(
  FTR.lit("lucifer"),
  FTR.then(CodecQuery(LuciferStatePathParamsModel)),
  FTR.then(FTR.end)
);

export const luciferPageStateFromUrl = (url: string) =>
  pipe(
    url,
    FTR.Route.parse,
    luciferPageMatch.parser.map(luciferStateFromPathParams(uiState)).run,
    O.map(
      flow(Tu.fst, (state) => {
        return {
          pathname: "lucifer",
          state,
        };
      })
    )
  );

export const assetPageStateFromUrl = (url: string) =>
  pipe(
    url,
    FTR.Route.parse,
    assetsPageMatch.parser.map(assetsStateFromPathParams(uiState)).run,
    O.map(
      flow(Tu.fst, (state) => {
        return {
          pathname: "assets",
          state,
        };
      })
    )
  );

export const activityPageStateFromUrl = (url: string) =>
  pipe(
    url,
    FTR.Route.parse,
    activityPageMatch.parser.map(activityStateFromPathParams(uiState)).run,
    O.map(
      flow(Tu.fst, (state) => {
        return {
          pathname: "activity",
          state,
        };
      })
    )
  );

export const historyStateFromURL = (url: string) =>
  pipe(
    assetPageStateFromUrl(url),
    O.alt(() => activityPageStateFromUrl(url)),
    O.alt(() => luciferPageStateFromUrl(url)),
    O.getOrElseW(() => ({
      pathname: url,
      state: uiState,
    }))
  );
